import React from 'react';
import { MissingPropertyInfo, WizardStepProps } from 'src/domain';
import { useDisclosuresStore } from '../store';
import { SpqTdsDisclosureSteps } from './constants';

export const SpqTdsMissingPropertyInfo = (props: WizardStepProps) => {
  const store = useDisclosuresStore();

  return (
    <MissingPropertyInfo
      defaultProperty={store.property!}
      onSubmit={(form) => {
        store.update({
          property: { ...store.property, ...form.property },
        });
        props.goToStep(SpqTdsDisclosureSteps.pickForms);
      }}
    />
  );
};

import { useMutation } from 'react-query';
import axios from 'axios';
import { SklosuresFlag } from 'src/pages/FilePackages/types';
import { getApiToken } from 'src/lib';

interface SklosuresIntegratorResolveSignerFlagParams {
  url: string;
  flagId: number;
}

interface SklosuresIntegratorResolveSignerFlagResponse {
  flag: SklosuresFlag;
}

export const sklosuresIntegratorResolveSignerFlagKey = 'sklosures-resolve-flag';

const resolveFlag = async (url: string, flagId: number) => {
  const token = await getApiToken();
  const body = {
    Status: 'RESOLVED',
  };
  const resolveSignerFlagUrl = `${url}/${flagId}`;
  const sklosuresResponse = (
    await axios.put<SklosuresIntegratorResolveSignerFlagResponse>(resolveSignerFlagUrl, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
  ).data;

  return sklosuresResponse.flag;
};

export const useSklosuresResolveIntegratorSignerFlag = () =>
  useMutation(async (payload: SklosuresIntegratorResolveSignerFlagParams) => resolveFlag(payload.url, payload.flagId));

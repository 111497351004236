import { DisclosureDefinition } from 'src/hooks';
import { SpqTdsDisclosureSteps } from '../steps';

export interface NavigationSteps {
  nextStep: SpqTdsDisclosureSteps | null;
  prevStep: SpqTdsDisclosureSteps;
}

const additionalAnswerNameToStep: Record<string, SpqTdsDisclosureSteps> = {
  propertyAPN: SpqTdsDisclosureSteps.apn,
  yearBuilt: SpqTdsDisclosureSteps.yearBuilt,
  fhdsHighFireHazardZone: SpqTdsDisclosureSteps.fireHazard,
  skZoning: SpqTdsDisclosureSteps.zoning,
};

export const useDisclosuresConditionalSteps = (
  currentStepKey: SpqTdsDisclosureSteps,
  selectedDisclosures: DisclosureDefinition[]
): NavigationSteps => {
  const conditionStepsToShowWithDups = selectedDisclosures.map((d) => Object.keys(d.additionalAnswers)).flat(2);
  const conditionalSteps = Array.from(new Set(conditionStepsToShowWithDups))
    .map((sklosuresAddAnswerName) => additionalAnswerNameToStep[sklosuresAddAnswerName])
    .filter((x) => x);

  conditionalSteps.sort((a, b) => a.localeCompare(b));

  const currentStepIndex = conditionalSteps.indexOf(currentStepKey);

  // Sellers is always the page after the conditional pages, so if we're on the
  // Sellers page, we know that going back shuld be the final conditional step.
  if (currentStepKey === SpqTdsDisclosureSteps.sellers) {
    return {
      prevStep: conditionalSteps[conditionalSteps.length - 1] ?? SpqTdsDisclosureSteps.pickForms,
      nextStep: null,
    };
  }

  // PickForms is always the page BEFORE the conditional steps, and Sellers is
  // always the page AFTER the conditional steps.
  return {
    prevStep: conditionalSteps[currentStepIndex - 1] ?? SpqTdsDisclosureSteps.pickForms,
    nextStep: conditionalSteps[currentStepIndex + 1] ?? SpqTdsDisclosureSteps.sellers,
  };
};

import React from 'react';
import { Grid, IconButton, InputAdornment } from '@material-ui/core';
import { MailOutline as MailIcon, Visibility, VisibilityOff } from '@material-ui/icons';
import { useForm } from 'react-hook-form';
import { TextInput, Navigation } from 'src/common';
import { emailRegex, buildAuthStoreFromAuthState, login, useAuthStore } from 'src/lib';

interface IProps {
  handleOnPreviousStep: () => void;
  handleNextStep: () => void;
}

type Inputs = {
  email: string;
  password: string;
};

export const LoginForm = (props: IProps) => {
  const authStore = useAuthStore();
  const { register, handleSubmit, errors } = useForm({ mode: 'onTouched' });
  const [loggingIn, setLoggingIn] = React.useState(false);
  const [loginError, setLoginError] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const onSubmit = async (data: Inputs) => {
    setLoggingIn(true);
    try {
      await login(data.email, data.password!);
      authStore.update(await buildAuthStoreFromAuthState());
      props.handleNextStep();
    } catch (err) {
      setLoggingIn(false);
      setLoginError(true);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="column" justifyContent="center" alignItems="center" spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextInput
              label="Your Email"
              name="email"
              inputRef={register({
                required: 'Please enter this field.',
                pattern: { value: emailRegex, message: 'Please enter a valid email.' },
              })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MailIcon />
                  </InputAdornment>
                ),
              }}
              error={errors.agentEmail}
              defaultValue={authStore.userInfo.email}
              disabled={loggingIn}
              singleField
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              autoFocus
              label="Password"
              name="password"
              inputRef={register({
                required: 'Please enter this field.',
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={loginError ? 'Invalid login.' : errors.password}
              type={showPassword ? 'text' : 'password'}
              disabled={loggingIn}
              singleField
            />
          </Grid>
        </Grid>
        <Navigation
          isLoading={loggingIn}
          PrevButtonProps={{ onClick: props.handleOnPreviousStep }}
          NextButtonProps={{
            children: 'Login',
            disabled: Object.keys(errors).length > 0,
          }}
        />
      </form>
    </div>
  );
};

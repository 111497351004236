import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles';
import { IconButton, Paper, PaperProps, TypographyProps } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import RemoveIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import AddIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import Typography from '@material-ui/core/Typography';
import { IconButtonProps } from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme: Theme) => ({
  value: {
    color: theme.colors.mainColor.primary,
  },
}));

export interface NumberControlProps {
  PaperProps?: PaperProps;
  IncrementIconButtonProps?: IconButtonProps;
  DecrementIconButtonProps?: IconButtonProps;
  ValueTypographyProps?: TypographyProps;
  value: number;
  onChange: (value: number) => void;
  max?: number;
  min?: number;
}

export const NumberControl: React.FC<NumberControlProps> = ({
  PaperProps = {},
  DecrementIconButtonProps = {},
  IncrementIconButtonProps = {},
  ValueTypographyProps = {},
  value,
  onChange,
  max,
  min,
}) => {
  const classes = useStyles();
  return (
    <Paper {...PaperProps}>
      <Grid container justifyContent="space-evenly" alignItems="center">
        <Grid item>
          <IconButton
            disabled={min != null && value <= min}
            onClick={() => onChange(value - 1)}
            color="primary"
            {...DecrementIconButtonProps}
            data-testid="decrease-count"
          >
            <RemoveIcon />
          </IconButton>
        </Grid>
        <Grid
          item
          component={Typography}
          className={classes.value}
          {...ValueTypographyProps}
          data-testid="current-count"
        >
          {value}
        </Grid>
        <Grid item>
          <IconButton
            disabled={max != null && value >= max}
            onClick={() => onChange(value + 1)}
            color="primary"
            {...IncrementIconButtonProps}
            data-testid="increase-count"
          >
            <AddIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

import React, { useState } from 'react';
import {
  Grid,
  InputAdornment,
  TextField,
  MenuItem,
  IconButton,
  Typography,
  FormControlLabel,
  Checkbox,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Visibility, VisibilityOff, Mail as MailIcon } from '@material-ui/icons';
import { Controller, useForm, UseFormMethods, useWatch } from 'react-hook-form';
import { emailRegex, UserMetaRole } from 'src/lib';
import { PhoneNumberInput, TextInput } from 'src/common';

const useStyles = makeStyles(
  (theme: Theme) => ({
    form: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
    },
    loginText: {
      textAlign: 'center',
      marginBottom: 24,
    },
    input: {
      marginTop: 24,
    },
    pageError: {
      marginTop: 24,
      marginBottom: -24,
      textAlign: 'center',
      fontSize: 18,
      color: theme.colors.error,
      fontWeight: 700,
    },
    loginWrapper: {
      display: 'flex',
      justifyContent: 'center',
      margin: '40px 0 -60px 0',
    },
    terms: {
      marginTop: 16,
    },
    select: {
      color: theme.colors.grey.bodyHeader,
    },
    error: {
      marginTop: 4,
      fontSize: 16,
      fontWeight: 700,
      color: theme.colors.error,
    },
  }),
  { classNamePrefix: 'RegistrationForm' }
);

export interface RegistrationFormInputs {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  role: UserMetaRole;
}

interface RegisterFormRenderProps extends UseFormMethods {
  termsChecked: boolean;
}

export interface RegistrationFormProps {
  onSubmit(inputs: RegistrationFormInputs): void;
  isSubmitting?: boolean;
  defaultValues?: {
    email?: string;
    phoneNumber?: string;
  };
  passwordErrors?: string[];
  pageError?: string;
  children?: React.ReactNode | ((props: RegisterFormRenderProps) => React.ReactNode);
}

/**
 * A form allowing users to create a Breeze account
 * Children render props are passed the methods returned from useForm()
 * in addition to a boolean `termsChecked` to indicate whether or not
 * the user has accepted the terms and conditions.
 * Children are rendered at the end of the form.
 */
export const RegistrationForm = (props: RegistrationFormProps) => {
  const { onSubmit, isSubmitting, defaultValues = {}, passwordErrors = [], pageError, children } = props;
  const classes = useStyles();

  const formMethods = useForm({ mode: 'onTouched' });
  const { register, handleSubmit, errors, control } = formMethods;
  const firstName = useWatch<string>({ control, name: 'firstName' });
  const lastName = useWatch<string>({ control, name: 'lastName' });

  const [showPassword, setShowPassword] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);

  function validatePassword(password: string) {
    // Don't validate password until name is filled out
    if (!firstName || !lastName) return true;

    const pass = password.toLowerCase();
    const first = firstName.toLowerCase();
    const last = lastName.toLowerCase();
    const passContainsName = pass.includes(first) || pass.includes(last);

    return passContainsName ? 'Your password cannot contain your name' : true;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextInput
            autoFocus
            label="First Name"
            name="firstName"
            inputRef={register({
              required: 'Please enter this field.',
              minLength: { value: 2, message: 'Please enter at least 2 characters' },
            })}
            error={errors.firstName}
            className={classes.input}
            disabled={isSubmitting}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            label="Last Name"
            name="lastName"
            inputRef={register({
              required: 'Please enter this field.',
              minLength: { value: 2, message: 'Please enter at least 2 characters' },
            })}
            error={errors.lastName}
            className={classes.input}
            disabled={isSubmitting}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label="Your Email"
            name="email"
            inputRef={register({
              required: 'Please enter this field.',
              pattern: { value: emailRegex, message: 'Please enter a valid email.' },
            })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailIcon />
                </InputAdornment>
              ),
            }}
            error={errors.email}
            className={classes.input}
            defaultValue={defaultValues.email ?? ''}
            disabled={isSubmitting}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <PhoneNumberInput
            required
            name="phoneNumber"
            errors={errors}
            autoComplete="off"
            control={control}
            defaultValue={defaultValues.phoneNumber ?? ''}
            className={classes.input}
            disabled={isSubmitting}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="role"
            rules={{
              required: 'Please choose your role.',
            }}
            render={({ onChange, value, onBlur }, state) => (
              <>
                <TextField
                  select
                  variant="outlined"
                  inputProps={{
                    id: 'user-role',
                    name: 'role',
                  }}
                  SelectProps={{
                    classes: { root: classes.select },
                  }}
                  inputRef={
                    register({
                      required: 'Please choose your role.',
                      name: 'role',
                    }) as never
                  }
                  onChange={onChange}
                  value={value}
                  onBlur={onBlur}
                  label="role"
                  fullWidth
                  error={errors.role}
                  className={classes.input}
                >
                  <MenuItem value={UserMetaRole.AGENT}>Agent</MenuItem>
                  <MenuItem value={UserMetaRole.TC}>Transaction Coordinator</MenuItem>
                  <MenuItem value={UserMetaRole.ASSISTANT}>Assistant</MenuItem>
                  <MenuItem value={UserMetaRole.BROKER}>Broker</MenuItem>
                  <MenuItem value={UserMetaRole.AUDITOR}>Auditor</MenuItem>
                </TextField>
                {state.invalid ? (
                  <Typography variant="body1" className={classes.error}>
                    {errors.role.message}
                  </Typography>
                ) : null}
              </>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            label="Password"
            name="password"
            disabled={isSubmitting}
            inputRef={register({
              required: 'Please enter this field.',
              minLength: { value: 8, message: 'Password must be at least 8 characters.' },
              validate: validatePassword,
            })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            type={showPassword ? 'text' : 'password'}
            error={errors.password}
            className={classes.input}
          />
        </Grid>
      </Grid>
      {passwordErrors?.map((pp) => (
        <Typography key={pp}>{pp}</Typography>
      ))}

      <FormControlLabel
        className={classes.terms}
        control={
          <Checkbox
            color="primary"
            name="termsAgreed"
            inputRef={register({
              validate: {
                checked: (val) => val || 'You must agree to the terms and conditions in order to register.',
              },
            })}
            checked={termsChecked}
            onChange={(e) => setTermsChecked(e.target.checked)}
          />
        }
        label={
          <Typography variant="body2">
            I agree to the{' '}
            <a href="https://skyslope.com/terms-conditions/" target="_blank" rel="noreferrer">
              terms and conditions
            </a>
          </Typography>
        }
      />
      {errors.termsAgreed && (
        <Typography variant="body1" className={classes.pageError}>
          {errors.termsAgreed.message}
        </Typography>
      )}
      {pageError && (
        <Typography variant="body1" className={classes.pageError}>
          {pageError}
        </Typography>
      )}

      {children && typeof children === 'function' ? children({ ...formMethods, termsChecked }) : children}
    </form>
  );
};

import React from 'react';
import { Send as SendIcon, Delete as StevieTrash, Mail as MailIcon, GetApp as GetAppIcon } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import { ReactComponent as Pen } from 'src/images/pen.svg';
import { ReactComponent as FormsIcon } from 'src/images/formsIcon.svg';
import { AddProps } from 'src/common';
import { useFlags } from 'src/hooks/useFlags';
import { ActionButton } from './ActionButton';
import { FilePackageItemActionsProps } from './FilePackageItemActions';
import { useFilePackageItemActions } from './useFilePackageItemActions';

export interface DesktopActionButtonsProps extends FilePackageItemActionsProps {
  setIsCancelInterviewsOpen(open: boolean): void;
  openDownloadMenu(e: React.MouseEvent<HTMLButtonElement>): void;
}

const MakeFirstButtonContained: React.FC = (props) => (
  <>
    {React.Children.toArray(props.children)
      .filter((x) => x)
      .map((child, index) => (index === 0 ? <AddProps key={index} to={child} variant="contained" /> : child))}
  </>
);

export const DesktopActionButtons = (props: DesktopActionButtonsProps) => {
  const { packageStatus, actions, setIsCancelInterviewsOpen, openDownloadMenu, hideSignNowOption = false } = props;

  const { isContinueInFormsEnabled } = useFlags();
  const {
    onSendDisclosuresEnvelopes,
    onResendEnvelopes,
    isResendingEnvelopes,
    onResendPackage,
    onSendForms,
    onCancelEnvelopes,
    onEditPackage,
    isFetchingSignerToken,
    onGetSignerToken,
    onSendDisclosuresToForms,
    isSendingDisclosuresToForms,
  } = useFilePackageItemActions(props);

  return (
    <MakeFirstButtonContained>
      {actions.ds3Resend && !hideSignNowOption && (
        <ActionButton
          startIcon={<Pen />}
          onClick={() => onGetSignerToken()}
          isLoading={isFetchingSignerToken}
          text="Sign Now"
        />
      )}

      {actions.formsSend && isContinueInFormsEnabled && (
        <ActionButton
          startIcon={<FormsIcon viewBox="0 0 93 95" width={16} height={16} aria-label="Forms" />}
          onClick={() => onSendDisclosuresToForms()}
          isLoading={isSendingDisclosuresToForms}
          text="Add to Forms"
        />
      )}
      {actions.ds3Send && (
        <ActionButton
          startIcon={<MailIcon />}
          onClick={() => onSendDisclosuresEnvelopes()}
          text="Send for Signatures"
        />
      )}
      {actions.sendForms && (
        <ActionButton startIcon={<MailIcon />} onClick={() => onSendForms()} text="Send for Signatures" />
      )}

      {(actions.sklosuresResend || actions.ds3Send) && (
        <ActionButton startIcon={<SendIcon />} onClick={() => onResendPackage()} text="Resend" />
      )}

      {actions.ds3Resend && (
        <ActionButton
          startIcon={<SendIcon />}
          onClick={() => onResendEnvelopes()}
          isLoading={isResendingEnvelopes}
          text="Resend"
        />
      )}
      {actions.packageEdit && <ActionButton startIcon={<EditIcon />} onClick={onEditPackage} text="Edit" />}

      {actions.sklosuresCancel && (
        <ActionButton startIcon={<StevieTrash />} onClick={() => setIsCancelInterviewsOpen(true)} text="Cancel" />
      )}
      {actions.ds3Cancel && (
        <ActionButton startIcon={<StevieTrash />} onClick={() => onCancelEnvelopes()} text="Cancel" />
      )}
      {packageStatus?.status !== 'Draft' && (
        <ActionButton startIcon={<GetAppIcon />} onClick={openDownloadMenu} text="Download" />
      )}
    </MakeFirstButtonContained>
  );
};

import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';
import * as Sentry from '@sentry/react';
import { FileResult, getFile } from '../../../lib/api/files';

export const getFilesByIdQueryKey = 'get-files-by-id';

export const useGetFileById = (
  fileId: number | undefined | null,
  options: UseQueryOptions<FileResult> = {}
): QueryObserverResult<FileResult> =>
  useQuery([getFilesByIdQueryKey, fileId], () => getFile(fileId!), {
    ...options,
    enabled: !!fileId && (options.enabled == null || options.enabled),
    onError(e) {
      console.error(`An error occurred while getting file by id (id=${fileId})`);
      Sentry.captureException(e);
      options?.onError?.(e);
    },
  });

import React from 'react';
import { InputAdornment } from '@material-ui/core';
import { Phone as PhoneIcon } from '@material-ui/icons';
import { Controller, UseFormMethods } from 'react-hook-form';
import { TextInput, TextInputProps } from './TextInput';
import { PhoneFieldFormat } from './PhoneFieldFormat';

export interface PhoneNumberInputProps extends TextInputProps {
  errors: UseFormMethods['errors'];
  control: UseFormMethods['control'];
}

/** A TextInput for collecting a phone number */
export const PhoneNumberInput: React.FC<PhoneNumberInputProps> = (props) => {
  const { errors, control, label, required = true, name = 'phoneNumber', defaultValue, ...rest } = props;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={{
        required: required ? 'Please enter this field.' : undefined,
        minLength: { message: 'Must be a 10-digit phone number.', value: 10 },
      }}
      render={({ onChange, value, onBlur }) => (
        <TextInput
          error={errors[name]}
          name={name}
          value={value}
          type="tel"
          onBlur={onBlur}
          onChange={(e) => onChange(e.target.value)}
          label={label || 'Phone Number'}
          {...rest}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PhoneIcon />
              </InputAdornment>
            ),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            inputComponent: PhoneFieldFormat as any,
          }}
        />
      )}
    />
  );
};

import React from 'react';
import clsx from 'clsx';
import { Button, IconButton, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { Clear as ClearIcon } from '@material-ui/icons';
import { Spacer } from 'src/common';
import { ContainerWithGutters } from './ContainerWithGutters';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    childrenWrapper: {
      padding: theme.spacing(4),
      border: '2px solid',
      borderColor: theme.colors.grey.offWhite3,
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
      },
    },
    mobileRemoveButton: {
      border: '2px solid',
      borderColor: theme.colors.grey.offWhite3,
      width: '100%',
      padding: theme.spacing(2),
      marginTop: theme.spacing(-4),
      marginBottom: theme.spacing(4),
    },
    desktopRemoveBtnWrapper: {
      paddingLeft: theme.spacing(2),
    },
  }),
  { classNamePrefix: 'ContactRow' }
);

export interface ContactRowProps {
  onRemove(): void;
  allowRemove?: boolean;
  className?: string;
  titleOfContact: string;
}

export const ContactRow: React.FC<ContactRowProps> = (props) => {
  const { children, onRemove, allowRemove, className, titleOfContact } = props;
  const classes = useStyles(props);

  const theme = useTheme();
  const isSmallAndDown = useMediaQuery(theme.breakpoints.down('sm'));

  const removeButton = isSmallAndDown ? (
    <>
      <Spacer axis="vertical" size={2} />
      <Button
        color="primary"
        onClick={onRemove}
        startIcon={<ClearIcon />}
        aria-label={`Remove ${titleOfContact}`}
        className={classes.mobileRemoveButton}
        size="small"
      >
        Remove {titleOfContact}
      </Button>
    </>
  ) : (
    <div className={classes.desktopRemoveBtnWrapper}>
      <IconButton aria-label={`Remove ${titleOfContact}`} onClick={onRemove}>
        <ClearIcon />
      </IconButton>
    </div>
  );

  return (
    <ContainerWithGutters
      classes={{ root: clsx(classes.root, className), children: classes.childrenWrapper }}
      rightGutterContent={!!allowRemove && removeButton}
    >
      {children}
    </ContainerWithGutters>
  );
};

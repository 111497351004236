import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';
import { gql } from 'graphql-request';
import { useRecoilValue } from 'recoil';
import { selectedAgentAtom } from 'src/state';
import { getGraphQLClient, getApiToken, WithOnBehalfOf } from '../../../lib';
import { RepresentationType } from '../../../lib/api/files';
import { AddressInput } from '../../FilePackages/types';
import { AvidPackageDTO } from '../types';

export interface UseCreateAvidPackagePayload {
  existingFileId?: number;
  property?: AddressInput;
  representationType?: RepresentationType;
  propertyType: string;
}

const mutationQuery = gql`
  mutation CreateAvidPackage($payload: CreateAvidPackagePayload) {
    createAvidPackage(payload: $payload) {
      packageId
      fileId
    }
  }
`;

export const createAvidPackageMutationKey = 'create-avid-mutation';

export const useCreateAvidPackage = (
  options: UseMutationOptions<AvidPackageDTO, AxiosError, UseCreateAvidPackagePayload> = {}
) => {
  const selectedAgent = useRecoilValue(selectedAgentAtom);

  return useMutation<AvidPackageDTO, AxiosError, UseCreateAvidPackagePayload>(
    async (payload: UseCreateAvidPackagePayload) => {
      const client = getGraphQLClient(await getApiToken());
      return (
        await client.request<
          { createAvidPackage: AvidPackageDTO },
          { payload: WithOnBehalfOf<UseCreateAvidPackagePayload> }
        >(mutationQuery, { payload: { ...payload, onBehalfOf: selectedAgent?.userId } })
      ).createAvidPackage;
    },
    {
      mutationKey: createAvidPackageMutationKey,
      ...options,
    }
  );
};

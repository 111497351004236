import React from 'react';
import clsx from 'clsx';
import { ButtonBase, ButtonBaseProps, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme, SelectableButtonProps>(
  (theme: Theme) => ({
    root: {
      position: 'relative',
    },
    // Can't use a real border on the root because that will shift the content
    // because the "selected" border is wider than the standard border
    fakeSelectionBorder: {
      display: (p) => (p.checked ? 'block' : 'none'),
      '--border-width': '6px',
      borderLeft: 'var(--border-width) solid',
      borderLeftColor: (p) => p.fakeSelectionBorderColor || theme.colors.mainColor.primary,
      borderRadius: 'inherit',
      width: 'var(--border-width)',
      position: 'absolute',
      top: (p) => p.selectBorderPosition?.y || 0,
      bottom: (p) => p.selectBorderPosition?.y || 0,
      left: (p) => p.selectBorderPosition?.x || 0,
    },
  }),
  { classNamePrefix: 'SelectableButton' }
);

export interface SelectableButtonProps extends ButtonBaseProps {
  checked?: boolean;
  // This is one of the MUI props, not sure why the typing doesn't exist
  component?: string;
  /** Adjust the absolute position of the fake border used to show selection */
  selectBorderPosition?: {
    x?: number;
    y?: number;
  };
  fakeSelectionBorderColor?: string;
}

/**
 * A button that has a "selected" state of showing an orange border
 * on the left side.  Uses MUI's ButtonBase component as the root.
 */
export const SelectableButton: React.FC<SelectableButtonProps> = (props) => {
  const classes = useStyles(props);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { children, className, selectBorderPosition, fakeSelectionBorderColor, ...rest } = props;

  return (
    <ButtonBase {...rest} className={clsx(className, classes.root)}>
      <span className={classes.fakeSelectionBorder} />
      {children}
    </ButtonBase>
  );
};

import React from 'react';
import clsx from 'clsx';
import {
  makeStyles,
  Theme,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  RadioGroupProps,
} from '@material-ui/core';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      minWidth: 300,
    },
    labelRoot: {
      backgroundColor: theme.colors.grey.offWhite3,
      borderColor: 'transparent',
      border: '2px solid',
      borderRadius: 8,
      marginLeft: 0,
      marginRight: 0,
      padding: theme.spacing(1),
      paddingRight: theme.spacing(5),
      flexDirection: 'row',

      '& + &': {
        marginTop: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          marginTop: theme.spacing(3),
        },
      },
    },
    labelRootChecked: {
      borderColor: theme.colors.grey.grey1,
    },
    label: {
      color: theme.colors.grey.bodyHeader,
      letterSpacing: 0,
      lineHeight: '22px',
      fontSize: 18,
      fontWeight: 600,
      fontFamily: 'Avenir Next Demi Bold',
      [theme.breakpoints.up('sm')]: {
        fontSize: '1.5rem',
      },
      whiteSpace: 'nowrap',
    },
    checked: {
      '&:hover': {
        backgroundColor: 'inherit',
      },
      '& svg': {
        fill: `${theme.colors.mainColor.primary} !important`,
      },
      '&.Mui-focusVisible svg': {
        fill: `${theme.colors.grey.grey1} !important`,
      },
    },
    checkboxRoot: {
      marginLeft: 16,
      marginRight: 16,

      '& .MuiIconButton-label': {
        height: 24,
        width: 24,
        [theme.breakpoints.up('sm')]: {
          width: 28,
          height: 28,
        },
      },
      '&:hover': {
        backgroundColor: 'inherit',
      },
      '&:hover svg': {
        fill: theme.colors.mainColor.primary,
      },
      '&.Mui-focusVisible svg': {
        fill: theme.colors.mainColor.primary,
      },
      '& svg': {
        width: 32,
        height: 32,
        [theme.breakpoints.up('sm')]: {
          width: 36,
          height: 36,
        },
      },
    },
  }),
  { classNamePrefix: 'RadioOptions' }
);

export interface RadioOptionItem {
  checked: boolean;
  label: string;
  value: string;
}

type RootElProps = Omit<React.HtmlHTMLAttributes<HTMLFieldSetElement>, 'color' | 'onChange'>;

export interface RadioOptionsProps extends RootElProps {
  RadioGroupProps?: RadioGroupProps;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  options: RadioOptionItem[];
  name: string;
}

export const RadioOptions: React.FC<RadioOptionsProps> = (props) => {
  const classes = useStyles();
  const { RadioGroupProps = {}, onChange, options, name, 'aria-labelledby': ariaLabelledBy, ...rest } = props;

  return (
    <FormControl {...rest} required component="fieldset" classes={{ root: classes.root }}>
      <RadioGroup onChange={onChange} aria-labelledby={ariaLabelledBy} {...RadioGroupProps}>
        {options.map((o) => (
          <FormControlLabel
            key={o.value}
            classes={{
              root: clsx(classes.labelRoot, { [classes.labelRootChecked]: o.checked }),
              label: classes.label,
            }}
            control={
              <Radio
                disableRipple
                classes={{ root: classes.checkboxRoot, checked: classes.checked }}
                checked={o.checked}
                value={o.value}
                name={name}
                color="default"
                required
              />
            }
            label={o.label}
            labelPlacement="start"
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

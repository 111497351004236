import React from 'react';
import { Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { SnackbarProvider as NotistackProvider } from 'notistack';
import { CheckCircle as SuccessIcon, Info as InfoIcon } from '@material-ui/icons';
import { colors } from '@skyslope/mache';

export const DEFAULT_SNACKBAR_DURATION = 3000;

const useStyles = makeStyles(
  (theme: Theme) => ({
    anchorTopRight: {
      right: theme.spacing(1),
    },
    successSnackbar: {
      backgroundColor: theme.colors.success,
      borderRadius: 0,
      right: 0,
    },
    infoSnackbar: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.grey[800],
      borderRadius: 0,
      right: 0,
      ...theme.typography.body1,
    },
    icon: {
      marginRight: theme.spacing(1),
      height: 18,
      width: 18,
      alignSelf: 'baseline',
      marginTop: theme.spacing(0.5),
    },
    containerRoot: {
      '& > *': {
        width: '100%',
      },
      [theme.breakpoints.up('sm')]: {
        maxWidth: 600,
      },
      pointerEvents: 'all',
      isolation: 'isolate',
    },
    root: {
      marginBottom: theme.spacing(0.5),
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(1),
      },
    },
  }),
  { classNamePrefix: 'SnackbarProvider' }
);

export const SnackbarProvider: React.FC = (props) => {
  const classes = useStyles();

  return (
    <NotistackProvider
      iconVariant={{
        success: <SuccessIcon className={classes.icon} />,
        info: <InfoIcon className={classes.icon} htmlColor={colors.blue[800]} />,
      }}
      classes={{
        root: classes.root,
        containerRoot: classes.containerRoot,
        variantSuccess: classes.successSnackbar,
        variantInfo: classes.infoSnackbar,
        containerAnchorOriginTopRight: classes.anchorTopRight,
      }}
      autoHideDuration={DEFAULT_SNACKBAR_DURATION}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {props.children}
    </NotistackProvider>
  );
};

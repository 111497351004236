import React, { useCallback, useMemo, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles';
import { Checkbox, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { PackageForm } from '../types';

const useStyles = makeStyles((theme: Theme) => ({
  listItemIconRoot: {
    minWidth: theme.spacing(5),
  },
}));

export type PackageInterviewSelection = { [key: number]: boolean };

export interface SelectPackageFormListProps {
  forms: PackageForm[];
  selection: PackageInterviewSelection;
  onSelect: (form: PackageForm) => void;
}

export const usePackageInterviewSelection = (
  forms: PackageForm[]
): { selection: PackageInterviewSelection; onSelect: (form: PackageForm) => void } => {
  // currently this doesn't handle when forms changes, not valid for any of our use cases yet
  const [selectedInterviewIds, setSelectedInterviewIds] = useState<{ [key: number]: boolean }>(
    Object.assign({}, ...forms.map((f) => ({ [f.interviewId]: true })))
  );

  const onSelect = useCallback((form: PackageForm) => {
    setSelectedInterviewIds((current) => ({
      ...current,
      [form.interviewId]: !current[form.interviewId],
    }));
  }, []);

  return useMemo(
    () => ({
      selection: selectedInterviewIds,
      onSelect,
    }),
    [selectedInterviewIds, onSelect]
  );
};

export const SelectPackageFormList: React.FC<SelectPackageFormListProps> = ({ forms, selection, onSelect }) => {
  const classes = useStyles();
  return (
    <List disablePadding>
      {forms.map((form) => (
        <ListItem key={form.interviewId} disableGutters>
          <ListItemIcon classes={{ root: classes.listItemIconRoot }}>
            <Checkbox
              edge="start"
              color="primary"
              checked={selection[form.interviewId]}
              tabIndex={-1}
              onClick={() => onSelect(form)}
            />
          </ListItemIcon>
          <ListItemText>{form.name}</ListItemText>
        </ListItem>
      ))}
    </List>
  );
};

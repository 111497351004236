import React, { useMemo } from 'react';
import { Grid, GridProps } from '@material-ui/core';
import { UseFormMethods } from 'react-hook-form';
import { useGetUserProfile } from 'src/hooks';
import { PhoneNumberInput, TextInput } from 'src/common';

export interface AgentProfileFormInputs {
  brokerageName: string;
  phoneNumber: string;
}

export interface AgentProfileInputsProps extends GridProps {
  heading?: React.ReactNode;
  control: UseFormMethods['control'];
  register: UseFormMethods['register'];
  errors: UseFormMethods['errors'];
}

/**
 * Inputs for each of the CAR Credential fields, intended to be rendered inside
 * of a form that is controlled via `useForm()`
 */
export const AgentProfileInputs = (props: AgentProfileInputsProps) => {
  const { register, errors, heading, control, ...rest } = props;
  const { data: userProfile, isFetched } = useGetUserProfile();

  /* eslint-disable react-hooks/exhaustive-deps */
  const brokerageName = useMemo(() => userProfile?.brokerageName, [isFetched]);
  const phoneNumber = useMemo(() => userProfile?.primaryPhoneNumber, [isFetched]);
  /* eslint-enable react-hooks/exhaustive-deps */

  if (brokerageName && phoneNumber) {
    return null;
  }

  return (
    <Grid {...rest} container spacing={3}>
      {heading}

      {!brokerageName && (
        <Grid item xs={12} style={{ overflow: 'hidden' }}>
          <TextInput
            label="Brokerage Name"
            inputRef={register({
              required: 'Please enter this field.',
            })}
            error={errors.brokerageName}
            name="brokerageName"
          />
        </Grid>
      )}

      {!phoneNumber && (
        <Grid item xs={12} style={{ overflow: 'hidden' }}>
          <PhoneNumberInput errors={errors} label="Your Phone Number" control={control} defaultValue={phoneNumber} />
        </Grid>
      )}
    </Grid>
  );
};

import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import * as Sentry from '@sentry/react';
import { useGetUserProfile, getUserProfileKey } from '../queries/useGetUserProfile';
import { updateUserLibraries } from '../../../lib/api/user';

export const updateUserLibrariesMutationKey = 'update-user-libraries-mutation';

export const useUpdateUserLibraries = (options: Omit<UseMutationOptions, 'onMutate'> = {}) => {
  const queryClient = useQueryClient();
  const { data: userProfile } = useGetUserProfile();
  const queryKey = [getUserProfileKey, userProfile?.id];

  return useMutation(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (data: any) => {
      if (userProfile == null) {
        throw new Error('User Profile Not Loaded');
      }

      return await updateUserLibraries(userProfile.id, data);
    },
    {
      mutationKey: updateUserLibrariesMutationKey,
      ...options,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: async (e, data, context: { previousUserProfile: any }) => {
        console.error('An error occurred while updating user libraires');
        Sentry.captureException(e);

        if (options?.onError) {
          await options.onError(e, data, context);
        }
      },
      onSettled: (data, error, variables, context) => {
        if (options?.onSettled) {
          options.onSettled(data, error, variables, context);
        }
        queryClient.invalidateQueries(queryKey);
      },
    }
  );
};

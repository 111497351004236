import { useEffect, useRef, useState } from 'react';
import { EffectiveConnectionType, useNetworkStatus } from 'react-adaptive-hooks';
import { useIsMutating } from 'react-query';

export interface UseLoadingStatusesOptions {
  mutationKey: string;
  loadingStatuses: string[];
  onDone: () => void;
}

const computeStatusInterval = (effectiveConnectionType: EffectiveConnectionType | null) => {
  switch (effectiveConnectionType) {
    case '2g':
      return 3000;
    case 'slow-2g':
      return 4000;
    case '3g':
      return 2500;
    default:
      return 2000;
  }
};

export const useLoadingStatuses = ({ mutationKey, loadingStatuses, onDone }: UseLoadingStatusesOptions) => {
  const isLoading = useIsMutating({ mutationKey: mutationKey });
  const intervalRef = useRef<number>();
  const [currentStatusIndex, setCurrentStatusIndex] = useState(0);
  const { effectiveConnectionType } = useNetworkStatus('4g');
  const currentStatus = loadingStatuses[Math.min(currentStatusIndex, loadingStatuses.length - 1)];

  const statusInterval = computeStatusInterval(effectiveConnectionType);

  const clearStatusInterval = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = undefined;
  };

  useEffect(() => {
    // redirect after api call finishes and we show all statuses
    if (!isLoading && currentStatusIndex === loadingStatuses.length) {
      onDone();
    }
  }, [isLoading, currentStatusIndex, onDone, loadingStatuses]);

  useEffect(() => {
    // set up status change interval
    intervalRef.current = window.setInterval(() => {
      setCurrentStatusIndex((current) => ++current);
    }, statusInterval);

    return clearStatusInterval;
  }, [statusInterval]);

  useEffect(() => {
    // clear status change interval on final status
    if (currentStatusIndex > loadingStatuses.length - 1) {
      clearStatusInterval();
    }
  }, [currentStatusIndex, loadingStatuses]);

  return { currentStatus, isLoading };
};

import { AxiosError } from 'axios';
import { gql } from 'graphql-request';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { DelegatedUsersDto, getGraphQLClient, getApiToken, UserDelegateWithDelegateId } from '../../lib';
import { UserPermissionRequestData, useGetUserPermissionsRequestsKey } from './useGetUserPermissionRequests';

export interface ApproveDelegateServerPayload {
  delegateIds: string[];
}

export interface ApproveDelegatePayload {
  delegates: UserDelegateWithDelegateId[];
}

export interface ApproveDelegateResult {
  ownerId: string;
  fullAccessDelegateRequests: DelegatedUsersDto[];
  createdAt: string;
  updatedAt: string;
}

const mutationQuery = gql`
  mutation approveFullAccessDelegates($delegateIds: [String]!) {
    approveFullAccessDelegates(delegateIds: $delegateIds) {
      ownerId
    }
  }
`;

interface MutationContext {
  previousInvites: UserPermissionRequestData;
}

export const approveDelegateAccessKey = 'approve-delegate-mutation';

/**
 * This hook allows an Agent to approve the request of a TC to join their team.
 */
export const useApproveDelegate = (
  options: UseMutationOptions<ApproveDelegateResult, AxiosError, ApproveDelegatePayload> = {}
) => {
  const queryClient = useQueryClient();

  return useMutation<ApproveDelegateResult, AxiosError, ApproveDelegatePayload>(
    async (payload: ApproveDelegatePayload) => {
      const client = getGraphQLClient(await getApiToken());
      return (
        await client.request<{ res: ApproveDelegateResult }, ApproveDelegateServerPayload>(mutationQuery, {
          delegateIds: payload.delegates.map((d) => d.delegateId),
        })
      ).res;
    },
    {
      mutationKey: approveDelegateAccessKey,
      async onMutate(payload: ApproveDelegatePayload): Promise<MutationContext> {
        await queryClient.cancelQueries(useGetUserPermissionsRequestsKey);

        const previousInvites: UserPermissionRequestData = queryClient.getQueryData(useGetUserPermissionsRequestsKey)!;

        queryClient.setQueryData(useGetUserPermissionsRequestsKey, (old: UserPermissionRequestData) => {
          const delegateIds = payload.delegates.map((d) => d.delegateId);

          const approvedRequests = old.delegatedToUserRequests.reduce((accum: UserDelegateWithDelegateId[], curr) => {
            if (delegateIds.includes(curr.delegateId)) {
              accum.push({ ...curr, isApproved: true });
            } else {
              accum.push(curr);
            }
            return accum;
          }, []);

          return {
            ...old,
            delegatedToUserRequests: approvedRequests,
          };
        });

        return { previousInvites };
      },
      onError(err, variables, context: MutationContext) {
        queryClient.setQueryData(useGetUserPermissionsRequestsKey, () => context.previousInvites);
        options?.onError?.(err, variables, context);
      },
      ...options,
    }
  );
};

import React from 'react';
import { RegisterWithLibraryAuth, WizardStepProps } from 'src/domain';
import { useGetMissingAuthInfos } from '../hooks/useGetMissingAuthInfos';
import { useDisclosuresStore } from '../store';
import { SpqTdsDisclosureSteps } from './constants';

export const SpqTdsRegister = (props: WizardStepProps) => {
  // NOTE: We don't use this anymore since we enforce account creation
  // upfront, but if we ever need this again, a thorough retest is required
  const store = useDisclosuresStore();
  const { data: missingAuthInfos } = useGetMissingAuthInfos(store?.forms ?? []);
  return (
    <RegisterWithLibraryAuth
      onRegister={() => {}}
      onBack={() => props.goToStep(SpqTdsDisclosureSteps.review)}
      onFullyAuthorized={() => props.goToStep(SpqTdsDisclosureSteps.review)}
      onAuthorizedWithoutLicenseNumber={() => props.goToStep(SpqTdsDisclosureSteps.licenseNumber)}
      onNotAuthorized={() =>
        props.goToStep(SpqTdsDisclosureSteps.credentials, {
          state: { authInfos: missingAuthInfos, currentIndex: 0 },
        })
      }
      nextButtonText="REGISTER & SEND"
      isAuthenticated={missingAuthInfos && missingAuthInfos.length === 0}
    />
  );
};

import React from 'react';
import { EditFileTeamMembersStep, WizardStepProps } from 'src/domain';
import { contactTypes, contactActions, pickKeysFromObject } from 'src/lib';
import { AvidSteps } from 'src/pages';
import { useDisclosuresStore } from '../store';
import { SpqTdsDisclosureSteps } from './constants';

/**
 * The "edit my team" step.  Here, the user can add RECEIVESCOPY contacts to a
 * file by choosing from a list of the user's current team members.
 */
export const SpqTdsEditFileTeamMembers: React.FC<WizardStepProps> = (props) => {
  const { goToStep } = props;

  const store = useDisclosuresStore();
  const teamMembers = store.teamMembers;

  return (
    <EditFileTeamMembersStep
      title="Who would you like to include on your team for this disclosure package?"
      onNextClick={(selectedTeamMembers) => {
        store.update({
          teamMembers: selectedTeamMembers.map((c) => ({
            ...pickKeysFromObject(c, ['email', 'firstName', 'lastName']),
            actionAttribute: contactActions.RECEIVESCOPY,
            type: contactTypes.OTHER,
          })),
        });

        goToStep(AvidSteps.review);
      }}
      previousStepName={AvidSteps.review}
      onAddTeamMember={() => goToStep(SpqTdsDisclosureSteps.addContactTeamMember)}
      contactsOnFile={teamMembers}
      goToStep={goToStep}
    />
  );
};

import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { config } from '../../../lib/constants';
import { getApiToken } from '../../../lib/auth/okta';
import { base64ToUrl } from '../../../lib/utils';
import { useQueriesTyped } from '../../useQueriesTyped';

export interface SklosuresBlock {
  xCoordinate: number;
  yCoordinate: number;
  width: number;
  height: number;
  pageNumber: number;
  answerKey: string;
  isSkipped: boolean;
  isFlagged: boolean;
}

export interface SklosuresSignerPdfForReviewUrlParams {
  interviewId: number;
  signerId: number;
}

export interface SklosuresSignerPdfForReviewUrlResponse {
  base64Content: string;
  skippedBlockInfo: SklosuresBlock[];
  flaggedBlockInfo: SklosuresBlock[];
}

export const sklosuresSignerPdfForReviewUrlKey = 'sklosures-signer-pdf';

const createPdfUrlGetter =
  (url: string): (() => Promise<SklosuresSignerPdfForReviewUrlResponse>) =>
  async () => {
    const token = await getApiToken();

    const sklosuresResponse = (
      await axios.get<SklosuresSignerPdfForReviewUrlResponse>(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    ).data;

    const response: SklosuresSignerPdfForReviewUrlResponse = {
      base64Content: await base64ToUrl(sklosuresResponse.base64Content),
      skippedBlockInfo: sklosuresResponse.skippedBlockInfo,
      flaggedBlockInfo: sklosuresResponse.flaggedBlockInfo,
    };

    return response;
  };

export const useGetSklosuresSignerPdfForReviewUrls = (
  params: SklosuresSignerPdfForReviewUrlParams[],
  options: UseQueryOptions<string> = {}
): QueryObserverResult<SklosuresSignerPdfForReviewUrlResponse>[] =>
  useQueriesTyped(
    params.map((parmObject) => {
      const url = `${config.sklosuresServiceUrl}/integrators/interviews/${parmObject.interviewId}/signers/${parmObject.signerId}/review`;

      return {
        ...options,
        queryKey: [sklosuresSignerPdfForReviewUrlKey, url],
        queryFn: createPdfUrlGetter(url),
      };
    })
  );

export const useGetSklosuresSignerPdfForReviewUrl = (
  params: SklosuresSignerPdfForReviewUrlParams,
  options: UseQueryOptions<SklosuresSignerPdfForReviewUrlResponse> = {}
): QueryObserverResult<SklosuresSignerPdfForReviewUrlResponse> => {
  const url = `${config.sklosuresServiceUrl}/integrators/interviews/${params.interviewId}/signers/${params.signerId}/review`;

  return useQuery([sklosuresSignerPdfForReviewUrlKey, url], createPdfUrlGetter(url), {
    ...options,
    onError(e) {
      console.error('An error occurred while getting sklosures signer PDF urls');
      Sentry.captureException(e);
      options?.onError?.(e);
    },
  });
};

import React, { useEffect, useRef } from 'react';
import { Divider, IconButton, InputAdornment, makeStyles, MenuItem, Theme, Typography } from '@material-ui/core';
import {
  Close as CloseIcon,
  Search as SearchIcon,
  Description as DescriptionIcon,
  Home as HomeIcon,
  Group as GroupIcon,
} from '@material-ui/icons';
import { useRecoilValue } from 'recoil';
import { Spacer, TextInput } from 'src/common';
import { PackageTypes, RepresentationType } from 'src/lib';
import { selectedAgentAtom } from 'src/state';
import { useTeamMembers } from 'src/hooks';
import { DashboardFilterTypes, FilterProps } from './useDashboardFileFilters';
import { DashboardFileFilter } from './DashboardFileFilter';
import { DashboardFilterChip } from './DashboardFilterChip';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      marginBottom: theme.spacing(3),

      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(2),
      },
    },
    searchSection: {
      display: 'flex',
      gap: theme.spacing(2),
      alignItems: 'center',
    },
    searchInputRoot: {
      flexGrow: 1,
    },
    searchInput: {
      borderRadius: 999, // An arbitrarily large value
      fontSize: '1rem',
    },
    chooseFiltersSectionTitle: {
      fontWeight: 700,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    chooseFiltersSection: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: theme.spacing(2),

      [theme.breakpoints.down('xs')]: {
        padding: 0,
        gap: theme.spacing(1),
      },
    },
    displayFiltersSection: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: theme.spacing(2),
    },
    filterDivider: {
      backgroundColor: theme.colors.grey.grey1,
    },
  }),
  { classNamePrefix: 'DashboardFileFilters' }
);

export interface DashboardFileFiltersProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  isOpen: boolean;
  onClose(): void;
  dashboardFiltering: FilterProps;
}

export const DashboardFileFilters: React.FC<DashboardFileFiltersProps> = (props) => {
  const classes = useStyles();
  const { isOpen, onClose, dashboardFiltering, ...rest } = props;
  const { filters, addFilter, removeFilter, search, setSearch } = dashboardFiltering;
  const selectedAgent = useRecoilValue(selectedAgentAtom);
  const { data: teamMembers } = useTeamMembers();
  const searchInputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (isOpen) searchInputRef.current?.focus();
  }, [isOpen]);

  return (
    <div {...rest} className={classes.root}>
      <div className={classes.searchSection}>
        <TextInput
          size="small"
          className={classes.searchInputRoot}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          inputRef={searchInputRef}
          InputProps={{
            className: classes.searchInput,
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          placeholder="Search..."
        />
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.chooseFiltersSection}>
        <Typography className={classes.chooseFiltersSectionTitle}>Filter by:</Typography>
        <DashboardFileFilter label="Form type" icon={<DescriptionIcon />}>
          <MenuItem onClick={() => addFilter([DashboardFilterTypes.PackageType, PackageTypes.avid])}>AVID</MenuItem>
          <MenuItem onClick={() => addFilter([DashboardFilterTypes.PackageType, PackageTypes.disclosures])}>
            Disclosures
          </MenuItem>
        </DashboardFileFilter>

        <DashboardFileFilter label="Representation" icon={<HomeIcon />}>
          <MenuItem onClick={() => addFilter([DashboardFilterTypes.Representation, RepresentationType.BUYER])}>
            Buyer
          </MenuItem>
          <MenuItem onClick={() => addFilter([DashboardFilterTypes.Representation, RepresentationType.SELLER])}>
            Seller
          </MenuItem>
        </DashboardFileFilter>

        {/*
         * The selected agent dropdown also acts as a filter when the TC is acting on
         * behalf of a particular agent, so avoid showing both and creating confusion
         */}
        {!selectedAgent && (
          <DashboardFileFilter label="Shared with" icon={<GroupIcon />}>
            {teamMembers.map((teamMember) => (
              <MenuItem
                key={teamMember.userId}
                onClick={() => addFilter([DashboardFilterTypes.SharedWithTeamMember, teamMember.userId])}
              >
                {teamMember.firstName} {teamMember.lastName}
              </MenuItem>
            ))}
          </DashboardFileFilter>
        )}
      </div>
      <div>
        {filters.length > 0 && (
          <>
            <div className={classes.displayFiltersSection}>
              {filters.map((filter) => (
                <DashboardFilterChip key={filter[1]} filter={filter} onDelete={() => removeFilter(filter)} />
              ))}
            </div>
            <Spacer axis="vertical" size={4} />
            <Divider className={classes.filterDivider} />
          </>
        )}
      </div>
    </div>
  );
};

import { EscrowOfficer, SklosureIdentity } from 'src/lib';
import { AvidWorkflowData } from '../Avid/types';

export enum PackageActions {
  sklosuresResend = 'sklosuresResend',
  sklosuresCancel = 'sklosuresCancel',
  formsSend = 'formsSend',
  ds3Send = 'ds3Send',
  ds3Resend = 'ds3Resend',
  ds3Cancel = 'ds3Cancel',
  packageEdit = 'packageEdit',
  sendForms = 'sendForms',
}

export type PackageActionMap = { [key in PackageActions]?: boolean };

export enum PackageStatusType {
  waitingForClientPackage = 'waitingForClientPackage',
  waitingForClientEnvelope = 'waitingForClientEnvelope',
  terminated = 'terminated',
  waitingForAgent = 'waitingForAgent',
  complete = 'complete',
  uxLoading = 'uxLoading',
}

export interface PackageStatus {
  status: string;
  progress: number;
  actions: PackageActionMap;
  type: PackageStatusType;
}

export interface PackageForm {
  name: string;
  interviewId: number;
  ds3DocumentId: string;
  formsDocumentId?: number;
  associationName?: string;
}

export enum PackageInterviewStatus {
  CREATED = 'CREATED',
  SENT = 'SENT',
  RESENT = 'RESENT',
  IN_PROGRESS = 'IN_PROGRESS',
  REVIEWED = 'REVIEWED',
  REVIEWED_INCOMPLETE = 'REVIEWED_INCOMPLETE',
  SENT_FOR_SIGNATURE = 'SENT_FOR_SIGNATURE',
  SENT_TO_FORMS = 'SENT_TO_FORMS',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
  BOUNCED = 'BOUNCED',
}

export enum FlagMessageStatus {
  CREATED = 'CREATED',
  RESOLVED = 'RESOLVED',
  REOPENED = 'REOPENED',
}

export interface SklosureSigner extends SklosureIdentity {
  id: number;
  status: string;
  flags: SklosuresFlag[];
}
export interface SklosuresFlag {
  id: number;
  answer: string;
  questionText: string;
  questionSection: string;
  status: string;
  updatedIdentityId: string;
  updatedAt: string;
  createdAt: string;
  messages: SklosuresFlagMessage[];
}

export interface SklosuresFlagMessage {
  id: number;
  message: string;
  createdIdentityId: string;
  createdAt: string;
  firstName: string;
  lastName: string;
}

export interface PackageInterview {
  id: number;
  status: PackageInterviewStatus;
  signers: SklosureSigner[];
  resendCount: number;
  packageConfig: SklosuresPackageConfig;
}

export interface SklosuresPackageConfig {
  signerQuestionFlaggingEnabled: boolean;
  signerResolveFlagEnabled: boolean;
}

export interface Ds3Document {
  id: string;
  url: string;
  // there is more to this model
}

export interface Ds3Envelope {
  documents: Ds3Document[];
  envelopeId: string;
  signers: Ds3Signer[];
  userEmail: string;
  // TODO there is more to this model, this is just what we needed now
}

export interface Ds3Signer {
  signerId: string;
  email: string;
  signingStatus: string;
}

export interface PackageContact {
  type: string;
  firstName: string;
  lastName: string;
  email: string;
  actionAttribute: string;
  companyName?: string;
  isEntity?: boolean;
  sklosuresSigners?: { interviewId: number; signerId: number }[];
  formsContactId?: number;
  phone?: string;
  agentLicenseNumber?: string;
}

export interface AddressInput {
  streetAddress: string;
  city: string;
  state: string;
  postalCode: string;
  unitNumber?: string;
  county?: string;
}

export interface DisclosuresWorkflowData {
  sellers: PackageContact[];
  apn: string;
  sellerAgent: PackageContact;
  property: AddressInput;
  isAvidFlowCompleted: boolean;
  isAttachingAvid: boolean;
  escrowOfficer: EscrowOfficer;
}

export interface FilePackage<WorkflowData = DisclosuresWorkflowData | AvidWorkflowData> {
  id: string;
  name: string;
  status: PackageStatus;
  type: string;
  forms: PackageForm[];
  lastUpdated: string;
  fileId: number;
  interviews: PackageInterview[];
  envelope?: Ds3Envelope;
  workflowData?: WorkflowData;
}

import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';
import { gql } from 'graphql-request';
import { UserDelegateWithDelegateId, getGraphQLClient, UserDelegateWithOwnerId, getApiToken } from '../../lib';

export const useGetUserPermissionsRequestsKey = 'use-get-user-permission-requests';

export interface UserPermissionRequestData {
  ownerId: string;
  delegatedToUserRequests: UserDelegateWithDelegateId[];
  delegatedFromUserRequests: UserDelegateWithOwnerId[];
  delegatedToUserGroupRequests: UserDelegateWithDelegateId[];
  delegatedFromUserGroupRequests: UserDelegateWithOwnerId[];
}

const query = gql`
  {
    userPermissionRequests {
      ownerId
      delegatedToUserRequests {
        delegateId
        firstName
        middleName
        lastName
        email
        isApproved
        isRevoked
        createdAt
        updatedAt
        delayedActionId
      }
      delegatedFromUserRequests {
        ownerId
        isApproved
        isRevoked
        updatedAt
        firstName
        middleName
        lastName
        email
        createdAt
        updatedAt
        delayedActionId
      }
      delegatedFromUserGroupRequests {
        ownerId
        isApproved
        isRevoked
        updatedAt
        firstName
        middleName
        lastName
        email
        createdAt
        updatedAt
        delayedActionId
      }
      delegatedToUserGroupRequests {
        delegateId
        firstName
        middleName
        lastName
        email
        isApproved
        isRevoked
        createdAt
        updatedAt
        delayedActionId
      }
    }
  }
`;

export const useGetUserPermissionRequests = (
  options: UseQueryOptions<UserPermissionRequestData> = {}
): QueryObserverResult<UserPermissionRequestData> =>
  useQuery<UserPermissionRequestData>(
    useGetUserPermissionsRequestsKey,
    async () => {
      const client = getGraphQLClient(await getApiToken());
      const result = await client.request<{ userPermissionRequests: UserPermissionRequestData }>(query);
      return result.userPermissionRequests;
    },
    options
  ) as QueryObserverResult<UserPermissionRequestData>;

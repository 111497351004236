import { useMemo } from 'react';
import { useQueryParams } from './useQueryParams';

/**
 * This hook fetches the query params on the first render, and continues to
 * return those same initial query params, even if the query params later
 * change.
 */
export const useInitialQueryParams = () => {
  const queryParams = useQueryParams();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(() => queryParams, []);
};

import { Button, makeStyles, Theme, Typography, Box, Grid, Container, useTheme } from '@material-ui/core';
import React, { lazy } from 'react';
import { Redirect } from 'react-router';
import { useAuth } from '@skyslope/auth-react';
import { useFlags, useIsMobile } from 'src/hooks';
import Clouds from 'src/images/breezeClouds.svg';
import CloudsMobile from 'src/images/breezeCloudsMobile.svg';
import { useHideZendeskWidget } from 'src/common';
import { LandingFrame } from 'src/domain';
import TreeClouds from '../../images/treeCloudsAlt.svg';
import {
  LandingMarketing,
  LandingVideoMarketing,
  LandingRegion,
  LandingTestimonials,
  LandingEscrow,
} from './components';
import { useIsCoMarketing } from './utils';
import { LandingMainTextArea } from './components/LandingMainTextArea';

// Lazy loading this because it has videos in it
const LandingMobileOptimized = lazy(() => import('./components/LandingMobileOptimized'));

const useStyles = makeStyles((theme: Theme) => ({
  cloudWrapper: {
    position: 'relative',
    backgroundImage: `url(${Clouds})`,
    backgroundRepeat: 'no-repeat',
    backgroundOrigin: 'content-box',
    backgroundSize: 'cover',
    zIndex: 1,
    minHeight: 416,
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2.5),
      minHeight: 300,
    },
    [theme.breakpoints.down('xs')]: {
      backgroundImage: `url(${CloudsMobile})`,
    },
  },
  cloudContent: {
    textAlign: 'center',
  },
  cloud: {
    position: 'relative',
    top: 0,
    left: 0,
    width: '100%',
    marginBottom: theme.spacing(-1),
  },
  surfText: {
    marginTop: theme.spacing(10),
    fontSize: theme.spacing(7),
    lineHeight: `${theme.spacing(8)}px`,
    color: '#fff',
    whiteSpace: 'nowrap',
    fontFamily: 'inherit',
  },
  surfTextMobile: {
    marginTop: theme.spacing(5),
    fontSize: theme.spacing(4),
    lineHeight: `${theme.spacing(4)}px`,
    color: '#fff',
    fontFamily: 'inherit',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2.5),
    },
  },
  letsGo: {
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(2.5),
    padding: '16px 32px',
    fontWeight: 'bold',
    color: '#fff',
  },
  mainContainer: {
    fontFamily: 'Avenir Next Demi Bold !important',
    [theme.breakpoints.down('xs')]: {
      margin: '25px 25px 0px 25px',
    },
  },
  gridContainer: {
    backgroundImage: `url(${TreeClouds})`,
    backgroundRepeat: 'no-repeat',
    backgroundOrigin: 'border-box',
    backgroundPositionY: '-150px',
    paddingTop: '130px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '40px',
      backgroundPositionY: '-80px',
      backgroundSize: '230%',
    },
  },
}));

export const Landing = () => {
  const { authState } = useAuth();
  const classes = useStyles();
  const isMobile = useIsMobile();
  const { isMarketingVideoEnabled, preOpenEscrowLocation, isTestimonialEnabled } = useFlags();
  const theme = useTheme();
  const { logo, favIcon, brand, isCoMarketing } = useIsCoMarketing();

  useHideZendeskWidget();

  if (authState.isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <LandingFrame isCoMarketing={isCoMarketing} companyLogo={logo} companyFavIcon={favIcon}>
      <Grid container className={classes.gridContainer}>
        <Container maxWidth="lg">
          <LandingMainTextArea />
        </Container>
        <LandingMobileOptimized />
        {preOpenEscrowLocation ? (
          <React.Fragment>
            {isCoMarketing && <LandingEscrow brand={brand} companyLogo={logo} />}
            {isMarketingVideoEnabled && <LandingVideoMarketing />}
          </React.Fragment>
        ) : (
          <React.Fragment>
            {isMarketingVideoEnabled && <LandingVideoMarketing />}
            {isCoMarketing && <LandingEscrow brand={brand} companyLogo={logo} />}
          </React.Fragment>
        )}
        <LandingRegion />
        {isTestimonialEnabled && <LandingTestimonials />}
        <LandingMarketing />
        <Grid item xs={12}>
          <Box width="100%" bgcolor={theme.colors.grey.bodyHeader}>
            <div id="cloudWrapperBottom" className={classes.cloudWrapper}>
              <div className={classes.cloudContent}>
                <Typography
                  className={isMobile ? classes.surfTextMobile : classes.surfText}
                  align="center"
                  variant="h2"
                >
                  Try Breeze for free today
                </Typography>
                <Button
                  className={classes.letsGo}
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    const loginWidget = document.getElementById('login-widget');
                    e.preventDefault();
                    loginWidget && loginWidget.scrollIntoView({ behavior: 'smooth', block: 'start' });
                  }}
                >
                  {"Let's Go"}
                </Button>
              </div>
            </div>
          </Box>
        </Grid>
      </Grid>
    </LandingFrame>
  );
};

import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import { ReactComponent as SurfDude } from '../../../images/surfdude.svg';
import Clouds from '../../../images/clouds.svg';
import { ReactComponent as Zap } from '../../../images/zap.svg';
import { ReactComponent as HappySun } from '../../../images/happysun.svg';
import { ReactComponent as Flows } from '../../../images/flows.svg';
import { ReactComponent as Signature } from '../../../images/signature.svg';

const useStyles = makeStyles((theme: Theme) => ({
  marketingContainer: {
    backgroundColor: theme.colors.grey.bodyHeader,
    paddingTop: theme.spacing(10),
  },
  textWrapper: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  mainText: {
    fontSize: theme.typography.pxToRem(56),
    color: '#fff',
    fontFamily: 'inherit',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(48),
      lineHeight: `${theme.spacing(6)}px`,
      textAlign: 'left',
    },
  },
  secondaryText: {
    fontSize: theme.typography.pxToRem(24),
    color: '#fff',
    fontFamily: 'Avenir Next',
    [theme.breakpoints.down('md')]: {
      textAlign: 'left',
    },
  },
  cloud: {
    position: 'relative',
    top: 0,
    left: 0,
    width: '100%',
    marginBottom: theme.spacing(-4),
  },
  cloudWrapper: {
    padding: theme.spacing(8, 0),
    backgroundImage: `url(${Clouds})`,
    backgroundRepeat: 'no-repeat',
    backgroundOrigin: 'content-box',
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
      backgroundImage: `none`,
    },
  },
  cardIcon: {
    maxWidth: 122,
  },
  cardTitle: {
    fontSize: theme.typography.pxToRem(24),
    color: '#fff',
  },
  cardText: {
    fontSize: theme.typography.pxToRem(19),
    color: '#B9CDD7',
  },
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const LandingMarketing: React.FC = () => {
  const classes = useStyles();

  const infoBox = (icon: any, header: string, body: string) => (
    <Grid item xs={12} md={6}>
      <Box bgcolor="#3B4B59" p={4} borderRadius={8}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm className={classes.cardIcon}>
            <Box>{icon}</Box>
          </Grid>
          <Grid item xs={12} sm>
            <Typography className={classes.cardTitle}>{header}</Typography>
            <Typography className={classes.cardText}>{body}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );

  return (
    <Grid container className={classes.marketingContainer}>
      <Container maxWidth="lg">
        <Grid container spacing={6} alignItems="center">
          <Grid item md={6} className={classes.textWrapper}>
            <Box>
              <Typography className={classes.mainText} variant="h2">
                Breeze was made for agents and clients
              </Typography>
            </Box>
          </Grid>
          <Grid item md={6} className={classes.textWrapper}>
            <Box>
              <Typography className={classes.secondaryText} variant="body1">
                A guided workflow helps your clients complete disclosure forms with more confidence than ever. Fast.
                Easy. Simple.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Grid item xs={12} id="cloudWrapper" className={classes.cloudWrapper}>
        <Container maxWidth="lg">
          <Grid container spacing={5}>
            {infoBox(
              <Zap />,
              'Simple Form Creation',
              'Quickly prepare disclosure forms using MLS data and digitally send to clients.'
            )}
            {infoBox(
              <Flows />,
              'Guided Disclosures',
              'Send clients simple questions and receive perfectly completed disclosure forms.'
            )}
            {infoBox(<HappySun />, 'Peace of Mind', 'Easily review and request revisions along the way.')}
            {infoBox(<Signature />, 'Free Digital Signatures', 'Once complete, get disclosures signed effortlessly.')}
          </Grid>
        </Container>
      </Grid>
      <Grid item xs={12}>
        <SurfDude className={classes.cloud} />
      </Grid>
    </Grid>
  );
};

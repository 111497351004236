import { useQuery, UseQueryOptions } from 'react-query';
import * as Sentry from '@sentry/react';
import { downloadDocumentById } from '../../../lib/api/files';
import { fileToUrl } from '../../../lib/utils';

export const useGetFormsDocumentPdfKey = 'forms-document-pdf';

export const getFormsDocumentUrl = async (documentId: number, fileId: number) => {
  if (documentId <= 0) return null;
  const data = await downloadDocumentById(documentId, fileId);
  return fileToUrl(data);
};

export const useGetFormsDocumentPdf = (
  documentId: number,
  fileId: number,
  options: UseQueryOptions<string | undefined> = {}
) =>
  useQuery(useGetFormsDocumentPdfKey, async () => await getFormsDocumentUrl(documentId, fileId), {
    enabled: fileId > 0 && documentId > 0 && (options.enabled == null || options.enabled),
    ...options,
    onError(e) {
      console.error(`An error occurred while getting forms document PDFs (fileId=${fileId}, documentId=${documentId})`);
      Sentry.captureException(e);
      options?.onError?.(e);
    },
  });

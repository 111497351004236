export const getPlacesAddressById = async (placeId: string) =>
  new Promise((resolve, reject) => {
    if (!placeId) reject('placeId not provided');
    try {
      new window.google.maps.places.PlacesService(document.createElement('div')).getDetails(
        {
          placeId,
          fields: ['address_components'],
        },
        (details) => {
          const address = {
            city: '',
            state: '',
            streetAddress: '',
            postalCode: '',
            unitNumber: '',
            county: '',
          };
          details?.address_components?.forEach((entry) => {
            const componentType = entry.types[0];
            switch (componentType) {
              case 'street_number':
                address.streetAddress = `${entry.long_name} `;
                break;
              case 'route':
                address.streetAddress = `${address.streetAddress || ''}${entry.short_name}`;
                break;
              case 'locality':
                address.city = entry.long_name;
                break;
              case 'administrative_area_level_1':
                address.state = entry.short_name;
                break;
              case 'postal_code':
                address.postalCode = entry.long_name;
                break;
              case 'administrative_area_level_2':
                address.county = entry.long_name?.replace(/county/gi, '').trim();
                break;
              default:
                break;
            }
          });
          return resolve(address);
        }
      );
    } catch (e) {
      reject(e);
    }
  });

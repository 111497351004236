import { useMemo } from 'react';
import { DisclosureDefinition } from 'src/hooks/domain/queries';
import { useGetUserProfileMeta } from 'src/hooks/domain/queries/useGetUserProfileMeta';
import { getAuthTypeInfo, isUserAuthenticatedForType } from 'src/lib';

/**
 * This will determine whether or not the user has authenticated with the
 * required authProfiles to use the given forms
 */
export const useGetMissingAuthInfos = (forms: DisclosureDefinition[]) => {
  const { data: meta, ...rest } = useGetUserProfileMeta();

  const missingAuthInfos = useMemo(
    () =>
      meta &&
      forms
        .map((form) => form.authType)
        // Get only the unique authTypes
        .filter((v, i, a) => a.indexOf(v) === i)
        .filter((authType) => !isUserAuthenticatedForType(meta, authType))
        .map((authType) => getAuthTypeInfo(authType)!),
    [forms, meta]
  );

  return {
    data: missingAuthInfos,
    ...rest,
  };
};

import React from 'react';
import { FormControlLabel, Checkbox, Divider, makeStyles, Theme } from '@material-ui/core';
import { useHistory } from 'react-router';
import { Navigation, Spacer, Step } from 'src/common';
import { WizardStepProps } from 'src/domain';
import { useDisclosuresStore } from 'src/pages/SpqTdsDisclosures/store';
import { SpqTdsDisclosureSteps } from '../../constants';
import { EscrowOfficerFilterList } from './EscrowOfficerFilterList';
import { useChooseEscrowOfficer } from './useChooseEscrowOfficer';

const useStyles = makeStyles(
  (theme: Theme) => ({
    checkboxWrapper: {
      display: 'flex',
      justifyContent: 'center',

      '& > label': {
        margin: 0,
      },
    },
    checkboxRoot: {
      paddingTop: 4,
    },
    checkboxLabel: {
      fontSize: theme.typography.pxToRem(22),
      alignItems: 'flex-start',
    },
    checkboxLabelText: {
      fontSize: `${22 / 16}rem`,
      [theme.breakpoints.down('xs')]: {
        fontSize: '1rem',
      },
    },
  }),
  { classNamePrefix: 'EscrowChooseOfficerStep' }
);

export interface EscrowChooseOfficerStepProps extends WizardStepProps {}

export const EscrowChooseOfficerStep: React.FC<EscrowChooseOfficerStepProps> = (props) => {
  const classes = useStyles();
  const { goToStep } = props;

  const history = useHistory();
  const store = useDisclosuresStore();

  const {
    activeEscrowOfficer,
    setActiveEscrowOfficer,
    saveAsDefaultEscrow,
    setSaveAsDefaultEscrow,
    filterText,
    setFilterText,
    debouncedFilterText,
    isDebouncedFilterValid,
    isFetching,
    isLoading,
    escrowOfficers,
    hasNextPage,
    fetchNextPage,
    handleSubmit,
    recentlyUsedEscrowOfficers,
  } = useChooseEscrowOfficer({ goToStep });

  const weHaveSearchResults = !!(debouncedFilterText && isDebouncedFilterValid && escrowOfficers != null);
  return (
    <Step title="Choose your escrow or title company contact">
      <EscrowOfficerFilterList
        searchResultOfficers={escrowOfficers}
        activeEscrowOfficer={activeEscrowOfficer}
        onChange={(officer) => setActiveEscrowOfficer(officer)}
        filterText={filterText}
        debouncedFilterText={debouncedFilterText}
        isDebouncedFilterValid={isDebouncedFilterValid}
        setFilterText={setFilterText}
        isFetching={isFetching}
        isLoading={isLoading}
        onAddEscrowOfficerClick={() => goToStep(SpqTdsDisclosureSteps.escrowAddOfficer)}
        recentlyUsedEscrowOfficers={recentlyUsedEscrowOfficers}
        hasNextPage={hasNextPage as boolean}
        fetchNextPage={fetchNextPage}
      />

      <Divider style={{ width: '100%' }} />
      <Spacer axis="vertical" size={4} />

      {(weHaveSearchResults || activeEscrowOfficer) && (
        <div className={classes.checkboxWrapper}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={saveAsDefaultEscrow}
                onChange={(e) => setSaveAsDefaultEscrow(e.target.checked)}
                classes={{ root: classes.checkboxRoot }}
              />
            }
            label="Default this contact for future listings"
            classes={{ root: classes.checkboxLabel, label: classes.checkboxLabelText }}
          />
        </div>
      )}

      <Navigation
        PrevButtonProps={{
          onClick: () => history.push(`/file/${store.existingFileId}/packages`),
          startIcon: null,
          children: 'Cancel',
        }}
        NextButtonProps={{
          disabled: !activeEscrowOfficer,
          endIcon: null,
          onClick: () => {
            handleSubmit();
          },
        }}
      />
    </Step>
  );
};

import React from 'react';
import { WizardStepProps, Login } from 'src/domain';
import { AvidSteps } from './constants';

export const AvidLogin = (props: WizardStepProps) => (
  <Login
    onLogin={() => props.goToStep(AvidSteps.property)}
    onBack={() => props.goToStep(AvidSteps.email)}
    onForgotPassword={() => props.goToStep(AvidSteps.recoverPassword)}
  />
);

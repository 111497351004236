import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { noOp, appEnvironments, ENV } from 'src/lib';

export interface DashboardState {
  search?: string;
}

export interface DashboardApi extends DashboardState {
  setSearch: (query: string) => void;
}

const defaultApi: DashboardApi = {
  setSearch: noOp,
};

export const dashboardContext = React.createContext<DashboardApi>(defaultApi);

export const useCreateDashboardApi = (): DashboardApi => {
  const [state, setState] = useState<DashboardState>({});

  useEffect(() => {
    if (ENV !== appEnvironments.PROD) {
      // eslint-disable-next-line no-console
      console.log('AUTH STORE UPDATED: ', state);
    }
  }, [state]);

  const update = useCallback(
    (obj: Partial<DashboardState>) => {
      setState((curState) => ({ ...curState, ...obj }));
    },
    [setState]
  );

  const setSearch = useCallback((search: string) => update({ search }), [update]);

  return useMemo<DashboardApi>(
    () => ({
      ...state,
      setSearch,
    }),
    [state, setSearch]
  );
};

export const DashboardProvider = dashboardContext.Provider;

export const useDashboardApi = (): DashboardApi => useContext<DashboardApi>(dashboardContext);

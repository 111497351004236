import { useGetFilePackages } from '../../../hooks';
import { PackageTypes } from '../../../lib';

export const useFileHasAvid = (fileId: number | undefined): boolean | undefined => {
  const { data: packages } = useGetFilePackages(fileId, {
    enabled: !fileId,
  });

  return packages?.some((p) => p.type === PackageTypes.avid);
};

import React from 'react';
import { useHistory } from 'react-router';
import { BrokerageName, WizardStepProps } from 'src/domain';
import { useAvidUserProfileBoundary } from '../useAvidUserProfileBoundary';
import { AvidSteps } from '../../AvidCreation';

export const AvidBrokerageName = ({ goToStep }: WizardStepProps) => {
  const history = useHistory();
  const { boundedAction } = useAvidUserProfileBoundary({
    goToStep,
    targetAction: () => goToStep(AvidSteps.review),
  });
  return <BrokerageName onNext={boundedAction} onBack={() => history.goBack} />;
};

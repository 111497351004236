import { useCallback, useMemo } from 'react';
import { UserRegion, usStates, GetEnumKeyByValue } from 'src/lib';
import { useGetLiveRegions } from 'src/hooks';
import { useGetUserProfile } from './queries';
import { useUpdateUserProfile } from './mutations';

/**
 * This hook is both a query and a mutation for getting/setting the user's
 * regions in their Forms user profile.
 */
export const useSelectRegion = () => {
  const { data: userProfile, isFetched } = useGetUserProfile();
  const { mutate: updateUserProfile } = useUpdateUserProfile();
  const liveRegions = useGetLiveRegions();
  const isAllFetched = Boolean(isFetched && liveRegions);

  const allRegions = userProfile?.regions;
  const [regions, otherRegions] = useMemo(() => {
    // Within Breeze we only support a subset of forms' regions, so we start
    // by splitting them up into ones we care about and ones we need to preserve
    const regions: usStates[] = [];
    const otherRegions: UserRegion[] = [];
    allRegions?.forEach((formsRegion) => {
      const region = usStates[formsRegion.region] as usStates;
      const isLiveRegion = liveRegions?.find((element) => element === formsRegion.region);
      if (formsRegion.country === 'US' && isLiveRegion) {
        regions.push(region);
      } else {
        otherRegions.push(formsRegion);
      }
    });
    return [regions, otherRegions];
  }, [liveRegions, allRegions]);

  const updateRegions = useCallback(
    (newRegions: usStates[] | undefined) => {
      if (!newRegions) return;
      updateUserProfile({
        regions: [
          ...newRegions.map((region) => ({
            Country: 'US',
            Region: GetEnumKeyByValue(usStates, region),
          })),
          ...otherRegions,
        ],
      });
    },
    [otherRegions, updateUserProfile]
  );

  return {
    regions: isAllFetched ? regions : undefined,
    isFetched: isAllFetched,
    updateRegions,
  };
};

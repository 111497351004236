import { useMemo } from 'react';
import { useGetUserPermissionRequests, useIsLoginProcessComplete, useGetUserProfile } from 'src/hooks';
import { getInitials, combineNames, TeamMember } from 'src/lib';

interface UseTeamMembersConfig {
  includePending: boolean;
}

/**
 * Fetch the other users that have been approved to be on this users team.
 * Additional fields are added to each user of `fullName` (first, middle, last
 * name), and `firstLastName`, and `initials`.
 *
 * @returns An object that matches what useQuery() returns.
 */
export const useTeamMembers = (config?: UseTeamMembersConfig) => {
  const { includePending } = config || {};
  const isLoggedIn = useIsLoginProcessComplete();
  const { data: requests, ...restUseQuery } = useGetUserPermissionRequests({ enabled: isLoggedIn });
  const { data: userProfile } = useGetUserProfile();

  const teamMembers: TeamMember[] = useMemo(() => {
    const from = (requests?.delegatedFromUserRequests ?? []).map((tm) => ({ ...tm, userId: tm.ownerId }));
    const fromUserGroup = (requests?.delegatedFromUserGroupRequests ?? []).map((tm) => ({ ...tm, userId: tm.ownerId }));
    const delegateRequests: TeamMember[] = from;

    const team = delegateRequests
      .filter((req) => !req.isRevoked)
      .filter((req) => includePending || req.isApproved)
      .map((tm) => ({
        ...tm,
        firstAndLastName: combineNames(tm.firstName, tm.lastName),
        fullName: combineNames(tm.firstName, tm.middleName, tm.lastName),
        initials: getInitials(tm),
      }));

    // Not filtering out approved accounts (like with team variable) because if they're assigned a group, they're all approved.
    const groupMembers = fromUserGroup.map((tm) => ({
      ...tm,
      firstAndLastName: combineNames(tm.firstName, tm.lastName),
      fullName: combineNames(tm.firstName, tm.middleName, tm.lastName),
      initials: getInitials(tm),
    }));

    // Filter out any duplicate group members from the team list.
    // And filter out the active user from the group to remove duplicates from team lists.
    const filteredGroupMembers = groupMembers
      .filter((g) => team.findIndex((t) => t.email === g.email) === -1)
      .filter((g) => g.email !== userProfile?.email);

    return team.concat(filteredGroupMembers);
  }, [requests, includePending, userProfile]);

  return {
    ...restUseQuery,
    data: teamMembers,
  };
};

import React from 'react';
import { Chip, Typography, makeStyles, Theme, ChipProps } from '@material-ui/core';
import { Label as LabelIcon } from '@material-ui/icons';

const useStyles = makeStyles<Theme, { color?: string | null }>(
  (theme) => ({
    icon: {
      color: (p) => p.color || theme.colors.mainColor.primary,
      fontSize: '1rem',
    },
    label: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(14),
    },
  }),
  { classNamePrefix: 'status-chip' }
);

export interface StatusChipProps extends Omit<ChipProps, 'children' | 'color'> {
  children: string;
  color?: string | null;
}

/**
 * A customized MUI chip intended to show a status like "Pending" or "Approved".
 * Often used together with <ListItemCard>.  Pass `color` to change the
 * color of the icon.
 */
export const StatusChip: React.FC<StatusChipProps> = (props) => {
  const { children, color, className, ...rest } = props;
  const classes = useStyles({ color });

  return (
    <Chip
      {...rest}
      label={
        <Typography variant="body1" className={classes.label}>
          {children}
        </Typography>
      }
      icon={<LabelIcon className={classes.icon} />}
      classes={{
        icon: classes.icon,
      }}
      className={className}
    />
  );
};

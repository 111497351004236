import React, { useEffect, useRef, useState } from 'react';
import { Container, makeStyles, Collapse, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useRecoilValue } from 'recoil';
import { useTdsSectionThreeMutation } from 'src/hooks';
import { Step, Navigation, TextInput, Spacer, RadioOptions, StdConfirmDialog } from 'src/common';
import { WizardStepProps } from 'src/domain';
import { useTayTip } from 'src/domain/wizard/useTayTip';
import { TdsSect3CheckboxMapping, config } from 'src/lib';
import { sendingDisclosuresToFormsAtom } from 'src/state';
import { useSendDisclosuresToFormsMutation } from 'src/hooks/domain/mutations/useSendDisclosuresToFormsMutation';
import { useDisclosuresStore } from '../store';
import { SpqTdsDisclosureSteps } from './constants';

const useStyles = makeStyles(
  () => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    radioOptions: {
      width: 'auto',
    },
    fullWidth: {
      width: '100%',
    },
  }),
  { classNamePrefix: 'SpqTdsVisualInspection' }
);

export const TdsSection3 = ({ goToStep }: WizardStepProps) => {
  const classes = useStyles();
  const history = useHistory();
  const store = useDisclosuresStore();
  const isFromDownload = !!store.downloadType;
  const observationsInputRef = useRef<HTMLInputElement>(null);

  const { mutate: updateTdsSectionThree } = useTdsSectionThreeMutation({
    fileId: store.existingFileId,
    packageId: store.existingPackageId,
    hasMhtds: store.hasMhtds,
  });

  const { mutate: sendDisclosuresToForms } = useSendDisclosuresToFormsMutation();

  const [sendOption, setSendOption] = useState<TdsSect3CheckboxMapping | ''>('');
  const [observations, setObservations] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const onConfirmChangeRef = useRef(() => {});
  const isSendingDisclosuresToForms = useRecoilValue(sendingDisclosuresToFormsAtom);

  useTayTip(`This will help fill out section 3 of the ${store.hasMhtds ? 'MHTDS' : 'TDS'}.`);

  useEffect(() => {
    if (sendOption === TdsSect3CheckboxMapping.AGENT_HAS_NOTES) {
      observationsInputRef.current?.focus();
    }
  }, [sendOption]);

  const updateSectionThreeCheckbox = (checkBoxMapping: TdsSect3CheckboxMapping) => {
    updateTdsSectionThree(
      {
        packageId: store.existingPackageId,
        fileId: store.existingFileId,
        checkboxMappingName: checkBoxMapping,
        text: observations || store.sectionThreeNotes || '',
      },
      {
        onSuccess() {
          const packageId = store.existingPackageId!;
          if (isFromDownload) {
            const downloadType = store.downloadType;
            store.update({ downloadType: '' });
            history.push(`/file/${store.existingFileId}/packages?downloadType=${downloadType}`);
          } else if (isSendingDisclosuresToForms) {
            sendDisclosuresToForms(
              { packageId },
              {
                onSuccess: () => {
                  window.open(`${config.formsApiUrl}/file-details/${store.existingFileId}/documents`, '_self');
                },
              }
            );
          } else {
            goToStep(SpqTdsDisclosureSteps.requestSignaturesEmail);
          }
        },
      }
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newOption = e.target.value as TdsSect3CheckboxMapping;

    if (
      sendOption === TdsSect3CheckboxMapping.AGENT_HAS_NOTES &&
      newOption !== TdsSect3CheckboxMapping.AGENT_HAS_NOTES &&
      observations
    ) {
      setIsDialogOpen(true);
      onConfirmChangeRef.current = () => {
        setSendOption(newOption);
        setIsDialogOpen(false);
        setObservations('');
      };
    } else {
      setSendOption(newOption);
    }
  };

  const handleNext = () => {
    updateSectionThreeCheckbox(sendOption as TdsSect3CheckboxMapping);
  };

  const titleId = 'visual-inspection-title';

  return (
    <Step titleProps={{ id: titleId }} title="Would you like to add notes from your visual inspection?">
      <Container maxWidth={false} className={classes.container} disableGutters>
        <RadioOptions
          onChange={handleChange}
          className={classes.radioOptions}
          name="visual-inspection"
          aria-labelledby={titleId}
          options={[
            {
              checked: sendOption === TdsSect3CheckboxMapping.AGENT_NO_NOTES,
              value: TdsSect3CheckboxMapping.AGENT_NO_NOTES,
              label: 'There was nothing to note',
            },
            {
              checked: sendOption === TdsSect3CheckboxMapping.ATTACH_AVID,
              value: TdsSect3CheckboxMapping.ATTACH_AVID,
              label: "I'll fill out an AVID form later",
            },
            {
              checked: sendOption === TdsSect3CheckboxMapping.AGENT_HAS_NOTES,
              value: TdsSect3CheckboxMapping.AGENT_HAS_NOTES,
              label: 'Yes, include these notes',
            },
          ]}
        />

        <Collapse
          in={sendOption === TdsSect3CheckboxMapping.AGENT_HAS_NOTES}
          appear={false}
          className={classes.fullWidth}
        >
          <div className={classes.fullWidth}>
            <Spacer axis="vertical" size={4} />
            <TextInput
              placeholder="Add your observations from your inspection here."
              autoFocus
              variant="filled"
              InputProps={{ disableUnderline: true }}
              inputRef={observationsInputRef}
              multiline
              minRows={7}
              className={classes.fullWidth}
              value={observations}
              onChange={(e) => setObservations(e.target.value)}
              style={{ width: '100%' }}
            />
          </div>
        </Collapse>
      </Container>
      <Navigation
        PrevButtonProps={{ onClick: () => history.go(-2), children: 'Back' }}
        NextButtonProps={{
          onClick: handleNext,
          disabled: !sendOption || (sendOption === TdsSect3CheckboxMapping.AGENT_HAS_NOTES && !observations),
        }}
      />
      <StdConfirmDialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        onConfirm={onConfirmChangeRef.current}
      >
        <Typography>
          Any observations you have entered will not be saved. Are you sure you want to change selections?
        </Typography>
      </StdConfirmDialog>
    </Step>
  );
};

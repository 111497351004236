import React from 'react';
import { RegisterWithLibraryAuth, WizardStepProps } from 'src/domain';
import { useIsUserAuthenticatedForType } from 'src/hooks';
import { AuthType } from 'src/lib';
import { AvidSteps } from './constants';

export const AvidRegister = (props: WizardStepProps) => {
  const { isAuthenticated: isCarAuthenticated } = useIsUserAuthenticatedForType(AuthType.CaNrds);
  return (
    <RegisterWithLibraryAuth
      onRegister={() => props.goToStep(AvidSteps.property)}
      onBack={() => props.goToStep(AvidSteps.email)}
      onFullyAuthorized={() => props.goToStep(AvidSteps.property)}
      onAuthorizedWithoutLicenseNumber={() => props.goToStep(AvidSteps.property)}
      onNotAuthorized={() => props.goToStep(AvidSteps.property)}
      isAuthenticated={Boolean(isCarAuthenticated)}
    />
  );
};

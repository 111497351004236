import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Container, FormControl, RadioGroup, FormControlLabel, Radio, makeStyles, Theme } from '@material-ui/core';
import { Navigation, Step } from 'src/common';
import { WizardStepProps } from 'src/domain';
import { useTayTip } from 'src/domain/wizard/useTayTip';
import { useDisclosuresStore } from '../store';
import { useDisclosuresConditionalSteps } from '../hooks/useDisclosuresConditionalSteps';
import { SpqTdsDisclosureSteps } from './constants';

interface Form {
  isFireHazard: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 300,
  },
  labelRoot: {
    backgroundColor: theme.colors.grey.offWhite3,
    borderRadius: theme.spacing(0.5),
    marginLeft: theme.spacing(-2.5),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    flexDirection: 'row',
    [theme.breakpoints.up('sm')]: {
      marginBottom: theme.spacing(3),
    },
  },
  label: {
    color: theme.colors.grey.bodyHeader,
    letterSpacing: 0,
    lineHeight: '22px',
    fontSize: theme.spacing(2.25),
    fontWeight: 600,
    fontFamily: 'Avenir Next Demi Bold',
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.spacing(3),
    },
    whiteSpace: 'nowrap',
  },
  formControlRoot: {
    width: '100%',
  },
  checked: {
    '&:hover': {
      backgroundColor: 'inherit',
    },
    '& svg': {
      fill: `${theme.colors.mainColor.primary} !important`,
    },
    '&.Mui-focusVisible svg': {
      fill: `${theme.colors.grey.grey1} !important`,
    },
  },
  checkboxRoot: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),

    '& .MuiIconButton-label': {
      height: theme.spacing(3),
      width: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(5),
        height: theme.spacing(5),
      },
    },
    '&:hover': {
      backgroundColor: 'inherit',
    },
    '&:hover svg': {
      fill: theme.colors.mainColor.primary,
    },
    '&.Mui-focusVisible svg': {
      fill: theme.colors.mainColor.primary,
    },
    '& svg': {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
  },
}));

export const FireHazard = (props: WizardStepProps) => {
  const classes = useStyles();
  const { goToStep } = props;

  const store = useDisclosuresStore();
  const navigationSteps = useDisclosuresConditionalSteps(SpqTdsDisclosureSteps.fireHazard, store.forms! ?? []);
  const labelClasses = {
    root: classes.labelRoot,
    label: classes.label,
  };

  const [isFireHazard, setIsFireHazard] = useState<boolean>(true);

  const formProps = useForm<Form>({
    mode: 'onTouched',
  });

  useTayTip(
    'As you know, if a property is located in a high or very high fire ' +
      'hazard severity zone, a disclosure needs to be made to the prospective ' +
      'buyer. Depending on which selection you choose the seller is required to fill ' +
      'different sections of the FHDS.'
  );

  return (
    <Step title="Is this property in a high or very high fire hazard severity zone?">
      <form
        onSubmit={formProps.handleSubmit(() => {
          store.update({ isFireHazard });
          goToStep(navigationSteps.nextStep!);
        })}
      >
        <Container maxWidth={false} className={classes.root} disableGutters>
          <FormControl required component="fieldset" classes={{ root: classes.formControlRoot }}>
            <RadioGroup value={isFireHazard} onChange={(event) => setIsFireHazard(event.target.value === 'true')}>
              <FormControlLabel
                classes={labelClasses}
                control={
                  <Radio
                    disableRipple
                    classes={{ root: classes.checkboxRoot, checked: classes.checked }}
                    value="true"
                    checked={isFireHazard === true}
                    name="fire-hazard-yes"
                    color="default"
                  />
                }
                label="Yes"
                labelPlacement="start"
              />
              <FormControlLabel
                classes={labelClasses}
                className={classes.labelRoot}
                control={
                  <Radio
                    disableRipple
                    classes={{ root: classes.checkboxRoot, checked: classes.checked }}
                    value="false"
                    checked={isFireHazard === false}
                    name="fire-hazard-no"
                    color="default"
                  />
                }
                label="No"
                labelPlacement="start"
              />
            </RadioGroup>
          </FormControl>
        </Container>
        <Navigation
          PrevButtonProps={{ onClick: () => goToStep(navigationSteps.prevStep) }}
          NextButtonProps={{
            disabled: Object.keys(formProps.errors).length > 0,
          }}
        />
      </form>
    </Step>
  );
};

import { useMutation, useQueryClient } from 'react-query';
import { gql } from 'graphql-request';
import * as Sentry from '@sentry/react';
import { ErrorCodes, getGraphQLClient, getApiToken } from '../../../lib';
import { FilePackage } from '../../../pages/FilePackages/types';
import { useCustomSnackbar } from '../../useCustomSnackbar';
import { useGetFilePackages, getPackagesByFileIdKey } from '../queries/useGetFilePackages';

export interface UseSectionThreePayload {
  checkboxMappingName: string;
  text: string;
  fileId?: number;
  packageId?: string;
  interviewId?: number;
  signerId?: number;
}

interface TdsSectionThreeParams {
  fileId?: number;
  packageId?: string;
  hasMhtds?: boolean;
}

const mutationQuery = gql`
  mutation updateUploadedForm($payload: UpdateUploadedFormPayload) {
    updateUploadedForm(payload: $payload)
  }
`;

export const useTdsSectionThreeMutationKey = 'update-tds-section-three-mutation';

export const useTdsSectionThreeMutation = (params: TdsSectionThreeParams) => {
  const { addErrorSnackbar } = useCustomSnackbar();
  const { data: packages } = useGetFilePackages<Partial<FilePackage>[]>(params.fileId);
  const filePackage = packages?.find((p) => p.id === params.packageId);
  const queryClient = useQueryClient();
  const interview = filePackage?.forms?.find((f) => f.name.includes(params.hasMhtds ? 'MHTDS' : 'TDS'));

  return useMutation(
    async (payload: UseSectionThreePayload) => {
      const client = getGraphQLClient(await getApiToken());
      return (
        await client.request<
          { updatedUploadForm: boolean },
          {
            payload: {
              packageId?: string;
              fileId?: number;
              documentId?: number;
              interviewId?: number;
              signerId?: number;
              checkboxMappingName: string;
              text: string;
            };
          }
        >(mutationQuery, {
          payload: {
            packageId: payload.packageId,
            fileId: payload.fileId,
            // TODO: stevie was right for now, but we need to
            // give forms a 'type' in our database to distinguish
            // them instead of searching on a name.
            // this is the way.
            documentId: interview?.formsDocumentId,
            interviewId: interview?.interviewId,
            signerId: filePackage?.interviews?.find((i) => i.id === interview?.interviewId)?.signers[0].id,
            checkboxMappingName: payload.checkboxMappingName,
            text: payload.text,
          },
        })
      ).updatedUploadForm;
    },
    {
      mutationKey: useTdsSectionThreeMutationKey,
      onError(e) {
        console.error('An error occurred while modifying TDS section three');
        const eventId = Sentry.captureException(e);

        addErrorSnackbar({
          errorCode: ErrorCodes.TdsSection3Mutation,
          eventId,
        });
      },
      onSettled() {
        queryClient.invalidateQueries([getPackagesByFileIdKey, params.fileId]);
      },
    }
  );
};

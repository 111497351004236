import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useIsMobile, useIsAvidAvailable } from 'src/hooks';
import { ReactComponent as StartDisclosuresSvg } from 'src/images/StartDisclosures.svg';
import { ReactComponent as StartAvidSvg } from 'src/images/StartAvid.svg';
import { ReactComponent as MyTeamSvg } from 'src/images/teamIcon.svg';
import { defaultWizardRoutes, routes } from 'src/lib';
import { SpqTdsDisclosureSteps } from 'src/pages/SpqTdsDisclosures/steps';
import { AvidSteps } from 'src/pages';
import { FlowNavigationCard } from './FlowNavigationCard';

const useStyles = makeStyles<Theme>(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      flexGrow: 1,
      gap: theme.spacing(2),

      [theme.breakpoints.up('sm')]: {
        gap: theme.spacing(6),
      },
    },
  }),
  { classNamePrefix: 'FlowNavigationCards' }
);

export interface FlowNavigationCardsProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  queryParams?: string;
  hasNoPackages?: boolean;
}

export const FlowNavigationCards: React.FC<FlowNavigationCardsProps> = (props) => {
  const classes = useStyles();
  const { queryParams, className, hasNoPackages, ...rest } = props;

  const history = useHistory();
  const navigate = (route: string, defaultParam?: string) =>
    history.push(route + (queryParams ?? '') + (defaultParam ?? ''));
  const isMobile = useIsMobile();
  const { data: showAvid } = useIsAvidAvailable();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <FlowNavigationCard
        id="invite-your-team-card"
        onClick={() => navigate(routes.team)}
        isFullWidth={isMobile}
        gradientStartColor="#FF9011"
        gradientEndColor="#DB7A0B"
        data-testid="invite-team"
        svg={
          isMobile ? (
            <MyTeamSvg viewBox="0 0 100 100" width={89} height={55} />
          ) : (
            <MyTeamSvg viewBox="0 0 100 100" style={{ marginTop: 22, transform: 'translate3d(10px, 5px, 0)' }} />
          )
        }
      >
        Invite Your Team
      </FlowNavigationCard>
      <FlowNavigationCard
        id="start-new-disclosures-card"
        onClick={() =>
          hasNoPackages
            ? navigate(`/disclosures/property`, `&default=${SpqTdsDisclosureSteps.property}`)
            : navigate(defaultWizardRoutes.disclosures)
        }
        isFullWidth={isMobile}
        gradientStartColor="#11CDFF"
        gradientEndColor="#079EFF"
        data-testid="start-disclosure"
        svg={
          isMobile ? (
            <StartDisclosuresSvg viewBox="0 0 100 100" width={89} height={55} />
          ) : (
            // This icon is 6px shorter than the avid one
            <StartDisclosuresSvg viewBox="0 0 100 100" style={{ marginTop: 6, marginLeft: 20 }} />
          )
        }
      >
        Start New Disclosures
      </FlowNavigationCard>
      {showAvid && (
        <FlowNavigationCard
          id="start-new-avid-card"
          onClick={() =>
            hasNoPackages
              ? navigate('/create-avid/property', `&default=${AvidSteps.property}`)
              : navigate(defaultWizardRoutes.avidCreation)
          }
          isFullWidth={isMobile}
          gradientStartColor="#C191F3"
          gradientEndColor="#8D58E2"
          data-testid="start-avid"
          svg={
            isMobile ? (
              <StartAvidSvg viewBox="0 0 100 110" width={89} height={55} style={{ marginLeft: 4, marginRight: -4 }} />
            ) : (
              <StartAvidSvg viewBox="0 0 100 110" style={{ marginLeft: 44 }} />
            )
          }
        >
          Start New AVID
        </FlowNavigationCard>
      )}
    </div>
  );
};

import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import * as Sentry from '@sentry/react';
import { useGetUserProfile, getUserProfileKey } from '../queries/useGetUserProfile';
import { updateUserProfile } from '../../../lib/api/user';
import { FormsUserProfile } from '../../../lib/types';

export const updateUserProfileMutationKey = 'update-user-profile-mutation';

export const useUpdateUserProfile = (options: Omit<UseMutationOptions, 'onMutate'> = {}) => {
  const queryClient = useQueryClient();
  const { data: userProfile } = useGetUserProfile();
  const queryKey = [getUserProfileKey, userProfile?.id];

  return useMutation(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (data: any) => {
      if (userProfile == null) {
        throw new Error('User Profile Not Loaded');
      }
      return await updateUserProfile({ ...userProfile, ...data }, userProfile.id);
    },
    {
      mutationKey: updateUserProfileMutationKey,
      ...options,
      onMutate: async (data) => {
        await queryClient.cancelQueries(queryKey);

        const previousUserProfile = await queryClient.getQueryData<FormsUserProfile>(queryKey);

        if (previousUserProfile) {
          queryClient.setQueryData(queryKey, {
            ...previousUserProfile,
            ...data,
          });
        }

        return { previousUserProfile };
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: async (e, data, context: { previousUserProfile: any }) => {
        console.error('An error occurred while updating user profile');
        Sentry.captureException(e);

        if (options?.onError) {
          await options.onError(e, data, context);
        }
        if (context?.previousUserProfile) {
          queryClient.setQueryData(queryKey, context.previousUserProfile);
        }
      },
      onSettled: (data, error, variables, context) => {
        if (options?.onSettled) {
          options.onSettled(data, error, variables, context);
        }
        queryClient.invalidateQueries(queryKey);
      },
    }
  );
};

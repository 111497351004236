import React, { useCallback, useMemo } from 'react';
import { Button } from '@material-ui/core';
import {
  OptionsObject,
  SnackbarKey,
  SnackbarMessage,
  useSnackbar as useNotistackSnackbar,
  VariantType,
} from 'notistack';
import { ErrorCodes } from 'src/lib/errorCodes';

type CustomOptions = OptionsObject & {
  noAutoDismiss?: boolean;
};

type ErrorSnackbarArgs = {
  message?: SnackbarMessage;
  errorCode: ErrorCodes;
  eventId?: string;
  options?: CustomOptions;
};

export interface UseSnackbarResponse {
  addErrorSnackbar: (args: ErrorSnackbarArgs) => void;
  addSuccessSnackbar: (message: SnackbarMessage, options?: CustomOptions) => void;
  addInfoSnackbar: (message: SnackbarMessage, options?: CustomOptions) => void;
  closeSnackbar: (key?: SnackbarKey) => void;
}

export const useCustomSnackbar = (defaultOptions?: CustomOptions): UseSnackbarResponse => {
  const { enqueueSnackbar, closeSnackbar } = useNotistackSnackbar();

  const SnackbarDismissAction = (key: SnackbarKey) => (
    <Button size="small" onClick={() => closeSnackbar(key)} style={{ color: 'inherit' }}>
      Dismiss
    </Button>
  );

  const addSnackbar = useCallback(
    (message: SnackbarMessage, variant: VariantType, options?: CustomOptions) => {
      const { noAutoDismiss, ...restOptions } = {
        ...defaultOptions,
        ...options,
      };

      return enqueueSnackbar(message, {
        variant,
        persist: noAutoDismiss,
        action: noAutoDismiss ? SnackbarDismissAction : null,
        ...restOptions,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enqueueSnackbar]
  );

  const addErrorSnackbar = useCallback(
    (args: ErrorSnackbarArgs) => {
      const {
        message = "Oops! That's not supposed to happen. Please try again in a few seconds.",
        errorCode,
        eventId = '',
        options,
      } = args;

      if (eventId) {
        console.error(`Sentry event ID: ${eventId}`);
      }

      const messageWithCode = `${message} Code: ${errorCode}`;

      addSnackbar(messageWithCode, 'error', {
        autoHideDuration: 6000,
        ...options,
      });
    },
    [addSnackbar]
  );

  const addSuccessSnackbar = useCallback(
    (message: SnackbarMessage, options?: CustomOptions) => addSnackbar(message, 'success', options),
    [addSnackbar]
  );

  const addInfoSnackbar = useCallback(
    (message: SnackbarMessage, options?: CustomOptions) => addSnackbar(message, 'info', options),
    [addSnackbar]
  );

  return useMemo(
    () => ({
      addErrorSnackbar,
      addSuccessSnackbar,
      addInfoSnackbar,
      closeSnackbar,
    }),
    [addErrorSnackbar, addInfoSnackbar, addSuccessSnackbar, closeSnackbar]
  );
};

import React from 'react';
import { Grid, GridProps } from '@material-ui/core';
import { DashboardFilePackage } from '../types';
import { PackageTypes } from '../../../lib';
import { StatusChip } from '../../../common';

export interface DashboardFileActivityProps extends GridProps {
  packages?: DashboardFilePackage[];
}

export const DashboardFileActivity: React.FC<DashboardFileActivityProps> = (props) => {
  const { packages, ...rest } = props;

  if (!packages?.length) return null;

  const filteredPackages = packages.filter(
    (filePackage, i, array) => array.findIndex((p) => filePackage.type === p.type) === i
  );

  return (
    <Grid {...rest} container spacing={2} justifyContent="flex-end">
      {filteredPackages.map((filePackage) => (
        <React.Fragment key={filePackage.id}>
          {filePackage.flagCount > 0 && (
            <Grid item>
              <StatusChip color="#FA4614">{`(${filePackage.flagCount}) Flagged`}</StatusChip>
            </Grid>
          )}
          <Grid item>
            <StatusChip
              color={
                filePackage.type === PackageTypes.disclosures
                  ? '#008DFF'
                  : filePackage.type === PackageTypes.avid
                  ? '#8D58E2'
                  : null
              }
            >
              {filePackage.type}
            </StatusChip>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};

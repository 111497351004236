import { useQuery, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import { gql } from 'graphql-request';
import { getGraphQLClient, getApiToken } from '../../../lib';

export type PackageCountByStatusResponse = {
  disclosuresSentCount: number;
  disclosuresCompletedCount: number;
  avidCount: number;
  avidCompletedCount: number;
};

export const getPackageCountByStatusKey = 'get-package-count-by-status-key';

const query = gql`
  {
    getPackageCountByStatus {
      disclosuresSentCount
      disclosuresCompletedCount
      avidCount
      avidCompletedCount
    }
  }
`;

export const useGetPackageCountByStatus = <T = PackageCountByStatusResponse>(
  options: UseQueryOptions<PackageCountByStatusResponse, AxiosError, T> = {}
) =>
  useQuery<PackageCountByStatusResponse, AxiosError, T>(
    [getPackageCountByStatusKey],
    async () => {
      const client = getGraphQLClient(await getApiToken());
      const result = await client.request(query);
      return result.getPackageCountByStatus;
    },
    {
      staleTime: Infinity,
      ...options,
    }
  );

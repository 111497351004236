import { useQuery, UseQueryOptions } from 'react-query';
import * as Sentry from '@sentry/react';
import { Contact, getContacts } from '../../../lib/api/files';

const getContactsKey = 'get-contacts';

export const useGetContacts = (fileId: number | undefined | null, options: UseQueryOptions<Contact[]> = {}) =>
  useQuery([getContactsKey, fileId], () => getContacts(fileId!), {
    ...options,
    enabled: fileId != null && (options.enabled == null || options.enabled),
    onError(e) {
      Sentry.captureException(e);
      options?.onError?.(e);
      console.error('An error occurred while getting contacts');
    },
  });

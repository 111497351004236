import React from 'react';
import clsx from 'clsx';
import { makeStyles, Button, ButtonProps, Typography, useTheme, Theme, Tooltip } from '@material-ui/core';
import { Spacer } from 'src/common';
import { Sortable } from './sort-util';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'inline-flex',
      cursor: 'pointer',
      alignItems: 'center',
      userSelect: 'none',
      paddingLeft: 16,
      paddingRight: 16,
      transform: 'translateX(-16px)',
      textTransform: 'none',
    },
    activeLabel: {
      color: theme.colors.mainColor.primary,
    },
  }),
  { classNamePrefix: 'SortableColTitle' }
);

export interface SortableColTitleProps extends ButtonProps, Sortable {
  label: string;
}

export const SortableColTitle: React.FC<SortableColTitleProps> = (props) => {
  const classes = useStyles();
  const { className, label, sort, title, ...rest } = props;

  return (
    <Tooltip title={title ?? ''}>
      <Button {...rest} className={clsx(className, classes.root)} endIcon={<SortArrows sort={sort} size={5} />}>
        <Typography variant="body2" className={clsx({ [classes.activeLabel]: !!sort })}>
          {label}
        </Typography>
      </Button>
    </Tooltip>
  );
};

interface SortArrowsProps extends Sortable {
  size: number;
}

function SortArrows(props: SortArrowsProps) {
  const { sort, size } = props;

  return (
    <div style={{ display: 'inline-flex', flexDirection: 'column' }}>
      <SortArrow size={size} direction="up" active={sort === 'asc'} />
      <Spacer axis="vertical" size={`${size * 0.75}px`} />
      <SortArrow size={size} direction="down" active={sort === 'desc'} />
    </div>
  );
}

interface SortArrowProps {
  direction: 'up' | 'down';
  active: boolean;
  size: number;
}

function SortArrow(props: SortArrowProps) {
  const { direction, active, size } = props;
  const theme = useTheme();

  return (
    <span
      style={{
        color: active ? theme.colors.mainColor.primary : theme.colors.grey.grey1,
        width: 0,
        height: 0,
        borderLeft: `${size}px solid transparent`,
        borderRight: `${size}px solid transparent`,
        borderTop: direction === 'down' ? `${size}px solid currentcolor` : undefined,
        borderBottom: direction === 'up' ? `${size}px solid currentcolor` : undefined,
      }}
    />
  );
}

import React, { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useZoomContext } from '@skyslope/react-document-viewer';
import { FlaggedQuestionsDrawerContext } from '../FlaggedQuestionsDrawerContext';
import { SklosuresFlag } from '../types';

const useStyles = makeStyles(() => ({
  '@keyframes blink': {
    '50%': {
      outline: '3px solid yellow',
    },
  },
  root: (Props: { props: AnswerReviewBlockViewProps; zoom: number; flashAnswerBlock: boolean }) => ({
    position: 'absolute',
    top: `${Props.props.yCoordinate * Props.zoom}px`,
    left: `${Props.props.xCoordinate * Props.zoom}px`,
    width: Props.props.width * Props.zoom - 1,
    height: Props.props.height * Props.zoom - 1,
    backgroundColor: Props.props.isSkipped ? '#FCE984' : '',
    outline: Props.props.isFlagged ? '2px solid #F77721' : '',
    '&:hover': {
      cursor: Props.props.isFlagged ? 'pointer' : 'default',
      backgroundColor: Props.props.isFlagged && Props.props.isSkipped ? '#E2D06F' : '',
      outline: Props.props.isFlagged && !Props.props.isSkipped ? '3px solid blue' : '',
    },
  }),
  animation: {
    animationName: '$blink',
    animationDuration: '.4s',
    animationIterationCount: 5,
  },
}));

export interface AnswerReviewBlockViewProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  height: number;
  width: number;
  xCoordinate: number;
  yCoordinate: number;
  isFlagged: boolean;
  isSkipped: boolean;
  flag?: SklosuresFlag;
}

export const AnswerReviewBlock: React.FC<AnswerReviewBlockViewProps> = (props) => {
  const { zoom } = useZoomContext();
  const { setIsDrawerOpen, setOpenMessages, setSelectedFlag, selectedFlag } = useContext(FlaggedQuestionsDrawerContext);
  const blockRef = useRef<any>(null);
  const [flashAnswerBlock, setFlashAnswerBlock] = useState(false);

  useEffect(() => {
    if (selectedFlag?.answer && selectedFlag?.answer === props.flag?.answer) {
      blockRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      setFlashAnswerBlock(true);
    } else {
      setFlashAnswerBlock(false);
    }
  }, [props.flag?.answer, selectedFlag?.answer]);

  const { className, height, width, xCoordinate, yCoordinate, ...rest } = props;
  const onAnswerClick = () => {
    if (props.isFlagged) {
      setIsDrawerOpen(true);
      setSelectedFlag(props.flag);
      setOpenMessages(true);
    }
  };
  const classes = useStyles({ props, zoom, flashAnswerBlock });
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus
    <div
      ref={blockRef}
      {...rest}
      onClick={onAnswerClick}
      className={`${classes.root} ${flashAnswerBlock ? classes.animation : ''}`}
      title="This question still needs an answer"
      role="button"
    ></div>
  );
};

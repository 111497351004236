import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, Box, BoxProps, Divider } from '@material-ui/core';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      gap: theme.spacing(4),

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: theme.spacing(2),
      },
    },
    column: {
      [theme.breakpoints.up('md')]: {
        flex: '1 1 0',
      },
    },
    rightColumn: {
      flexGrow: 1.25,
      display: 'flex',
      justifyContent: 'space-between',
      gap: theme.spacing(2),

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        width: '100%',
      },
    },
    rightColumnNoChildren: {
      flexGrow: 0,
      flexBasis: 'fit-content',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    rightMain: {
      flex: `1 1 fit-content`,

      display: 'flex',
      alignItems: 'center',
    },
    rightEndAdornment: {
      flex: `0 1 fit-content`,

      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    divider: {
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'initial',
      },
    },
  }),
  { classNamePrefix: 'DashboardColLayout' }
);

/**
 * The two or three column layout used on the dashboard page
 * to display existing files.
 */
export const DashboardColLayout = (props: BoxProps) => {
  const classes = useStyles();
  const { className, ...rest } = props;

  return <Box {...rest} className={clsx(classes.root, className)} />;
};

const LeftColumn = (props: BoxProps) => {
  const classes = useStyles();
  const { className, overflow = 'true', ...rest } = props;

  return <Box {...rest} className={clsx(className, classes.column)} overflow={overflow} />;
};

export interface RightColumnProps extends BoxProps {
  endAdornment: React.ReactNode;
}

const RightColumn = (props: RightColumnProps) => {
  const classes = useStyles();
  const { children, className, endAdornment, overflow = 'true', ...rest } = props;
  const childCount = React.Children.toArray(children).filter((c) => c).length;

  if (childCount == 0 && !endAdornment) return null;

  return (
    <Box
      {...rest}
      className={clsx(className, classes.column, classes.rightColumn, {
        [classes.rightColumnNoChildren]: childCount === 0,
      })}
      overflow={overflow}
    >
      {childCount > 0 && (
        <>
          <Divider className={classes.divider} />
          <div className={classes.rightMain}>{children}</div>
        </>
      )}
      {endAdornment && <div className={classes.rightEndAdornment}>{endAdornment}</div>}
    </Box>
  );
};

DashboardColLayout.LeftColumn = LeftColumn;
DashboardColLayout.RightColumn = RightColumn;

import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme, { childCount: number; index: number }>(
  () => ({
    root: {
      height: '100%',
      overflow: 'hidden',
    },
    scroller: {
      width: (p) => `${p.childCount * 100}%`,
      height: '100%',
      display: 'flex',
      transform: (p) => `translateX(${(p.index / p.childCount) * -100}%`,
      transition: 'transform 200ms ease',

      '& > *': {
        height: '100%',
        flex: 1,
      },
    },
  }),
  { classNamePrefix: 'SlideView' }
);

export interface SlideViewProps {
  /** The index of the child element that is currently displayed */
  index: number;
}

/**
 * This component allows programmatic sliding between two side by side views
 */
export const SlideView: React.FC<SlideViewProps> = (props) => {
  const { children, index, ...rest } = props;

  const childCount = React.Children.count(children);
  const classes = useStyles({ childCount, index });

  return (
    <div {...rest} className={classes.root}>
      <div className={classes.scroller}>{children}</div>
    </div>
  );
};

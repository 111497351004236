// The only reason these start at 800 is so that they can't be
// confused with HTTP status codes.
export enum ErrorCodes {
  CancelInterview = 800,
  CreateUserProfile = 801,
  CreateContacts = 802,
  CreateDisclosurePackage = 803,
  DeleteContacts = 804,
  CreateUserMeta = 805,
  DownloadPackageMerged = 806,
  DownloadPackageZip = 807,
  SendInvite = 808,
  CancelEnvelope = 809,
  FetchAgentSignerToken = 810,
  ResendEnvelope = 811,
  TdsSection3Mutation = 812,
  SendDisclosuresEnvelope = 813,
  SendForms = 814,
  AssignFullAccess = 815,
  CreateAvidPackage = 816,
  UpdateUserProfile = 817,
  UpdateAvidPackage = 818,
  CancelDisclosurePackageInterviews = 819,
  ResendDisclosurePackageInterviews = 820,
  DocumentPreviewGetSklosuresSignerPdfUrl = 822,
  DocumentPreviewGetDs3DocumentPdfs = 824,
  DocumentPreviewGetFormsDocumentPdfs = 826,
  TeamPageApproveDelegateInvite = 827,
  TeamPageDeclineDelegateInvite = 828,
  TeamPageCancelInvite = 829,
  TeamPageRemoveCollaborator = 830,
  RequestFullAccess = 831,
  OnBehalfOfAgentMissingLicenseNumber = 832,
  SendDisclosuresToForms = 833,
  OnBehalfOfAgentMissingLibraryCreds = 834,
}

import { Button, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import skyslopeLogo from '../images/skyslope.svg';
import formsIcon from '../images/formsIcon.svg';
import { config } from '../lib/constants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh',
  },
  header: {
    width: '100%',
    height: 70,
    borderBottom: `1px solid ${theme.colors.grey.disable}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#ffffff',
  },
  headerLeft: {
    display: 'flex',
    marginLeft: 32,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      width: '100%',
      // Bring this back if we bring back the contact/about links
      // justifyContent: 'space-around',
    },
  },
  headerButton: {
    marginRight: 40,
    fontWeight: 'bold',
    color: theme.colors.grey.bodyHeader,
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  headerButtonRight: {
    marginRight: 32,
    fontWeight: 'bold',
    color: theme.colors.grey.bodyHeader,
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 'auto',
    height: 70,
    // position: 'fixed',
    // bottom: 0
  },
  footerText: {
    fontSize: 16,
    display: 'inline-block',
    '&:first-child': {
      marginLeft: 36,
      [theme.breakpoints.down('xs')]: {
        marginLeft: 16,
      },
    },
    '&:last-child': {
      marginRight: 36,
      [theme.breakpoints.down('xs')]: {
        marginRight: 16,
      },
    },
  },
  poweredBy: {
    marginRight: 8,
    display: 'inline-block',
  },
  skyslopeLogo: {
    height: 16,
    verticalAlign: 'sub',
  },
  formsIcon: {
    height: 18,
  },
  formsText: {
    marginLeft: 8,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

export const LandingFrameDeprecated: React.FC = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <div className={classes.headerLeft}>
          {/* <Button variant="text" className={classes.headerButton} onClick={() => props.setPage('landing')}>
            PEAD?
          </Button> */}
          {/* <Button variant="text" className={classes.headerButton} onClick={() => props.setPage('about')}>
            About
          </Button> */}
          {/* <Button
            variant="text"
            className={classes.headerButton}
            onClick={() => window.open('https://support.skyslope.com/', '_blank')}
          >
            Contact
          </Button> */}
        </div>
        <Button
          variant="text"
          className={classes.headerButtonRight}
          onClick={() => window.open(config.formsApiUrl, '_blank')}
        >
          <img src={formsIcon} className={classes.formsIcon} alt="Forms" />
          <span className={classes.formsText}>SkySlope Forms</span>
        </Button>
      </header>
      {props.children}
      <footer className={classes.footer}>
        <Typography variant="body2" component="div" className={classes.footerText}>
          <div className={classes.poweredBy}>Powered by</div>
          <a href="https://skyslope.com" target="_blank" rel="noreferrer">
            <img src={skyslopeLogo} className={classes.skyslopeLogo} alt="SkySlope" />
          </a>
        </Typography>
        <Typography variant="body2" className={classes.footerText}>
          PEAD form provided by C.A.R.
        </Typography>
      </footer>
    </div>
  );
};

import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core';
import { EmailInputChip } from './EmailInputChip';
import { Setter } from './useComposeEmail';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
  }),
  { classNamePrefix: 'EmailChipsInput' }
);

export interface EmailChipsInputProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  values: Array<string | null | undefined>;
  setValues: Setter;
  gridArea?: string;
  inputRef?: React.RefObject<HTMLTextAreaElement>;
  disabled?: boolean;
}

/**
 * An input (actually a series of inputs, but you can think of it as just one)
 * that allows a user to input text, and once they blur the text field (or hit
 * some delimiter key), the email will turn into a chip and they will be able
 * to input the next email.
 */
export const EmailChipsInput: React.FC<EmailChipsInputProps> = (props) => {
  const classes = useStyles();
  const { className, style = {}, values, setValues, gridArea, inputRef, disabled, ...rest } = props;

  function deleteAtIndex(index: number) {
    if (disabled || index < 0 || index >= values.length) return;

    const copy = [...values];

    // If the current item has already been "deleted", look at the previous value
    while (copy[index] == null && index > 0) index--;

    // Replace it with null instead of removing it to preserve the integrity
    // of the indices, which is what we're using as React keys.
    copy[index] = null;
    setValues(copy);
  }

  return (
    // This element is allowed a click handler without keyboard handlers
    // because keyboard users will tab directly to the input.  This click
    // handler is just for mouse user who click in the slightly wrong spot.
    // eslint-disable-next-line
    <div
      {...rest}
      className={clsx(className, classes.root)}
      style={{ ...style, gridArea }}
      onClick={() => inputRef?.current?.focus()}
    >
      {values?.map((email, index) => {
        if (email == null) return null;
        const isLast = index === values.length - 1;

        return (
          <EmailInputChip
            key={index}
            value={email}
            onChange={(modifiedRecipient) => {
              const copy = [...values];
              copy[index] = modifiedRecipient;
              setValues(copy);
            }}
            // When the last chip in the list is validated, add another text field
            onValidate={() => {
              if (isLast && !disabled) {
                setValues((values) => [...values, '']);
              }
            }}
            onDeletePrevious={() => deleteAtIndex(index - 1)}
            onDelete={() => deleteAtIndex(index)}
            // This ref will end up pointing to the last one of these
            // EmailChipsInput that is in input mode (instead of chip mode)
            inputRef={inputRef}
            onBlurredWhileEmpty={() => {
              if (!isLast) deleteAtIndex(index);
            }}
            disabled={disabled}
          />
        );
      })}
    </div>
  );
};

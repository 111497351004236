import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Avatar, Chip, makeStyles, Theme } from '@material-ui/core';
import { AccountCircle as UserIcon } from '@material-ui/icons';
import { mergeRefs } from 'src/lib';
import { resemblesEmail } from './emailUtil';
import { EmailMetaInput, EmailMetaInputProps } from './EmailMetaInput';

const useStyles = makeStyles<Theme, EmailInputChipProps>(
  (theme: Theme) => ({
    inputAndChip: {
      fontSize: 'inherit',
      '--space': `${theme.spacing(1)}px`,
    },
    input: {
      flexGrow: (p) => (p.value?.length ? 0.01 : 1),
      paddingLeft: 'var(--space)',
      maxWidth: '100%',
      background: 'transparent',

      '$chip + &': {
        marginLeft: 'calc(-1 * var(--space))',
      },

      width: (p) => `${p.value?.length}ch`,
      [theme.breakpoints.down('xs')]: {
        // Due to a quirk of the styling system, the previous
        // width takes precedence without this precedence boost.
        '&&': {
          width: '100%',
        },
      },
    },
    chipWrapper: {
      overflow: 'hidden',
      maxWidth: '100%',

      [theme.breakpoints.down('xs')]: {
        padding: `6px 0`,
      },
    },
    chip: {
      textTransform: 'none',
      borderRadius: 999,
      marginRight: 'var(--space)',
      maxWidth: '100%',
      background: theme.colors.grey.offWhite2,
      borderColor: theme.colors.grey.offWhite2,

      '&&:hover': {
        backgroundColor: theme.colors.grey.disable, // #f3f3f3
      },
    },
    chipIcon: {
      // "precedence boost" to increase specificity
      '&&': {
        color: theme.colors.grey.grey1,
        background: 'transparent',
      },
    },
  }),
  { classNamePrefix: 'EmailInputChip' }
);

export interface EmailInputChipProps extends EmailMetaInputProps {
  value: string | undefined;
  inputRef?: React.Ref<HTMLTextAreaElement>;
  /** Called when an email address is turned from a text input to a chip */
  onValidate?(): void;
  /** Called when backspace is pressed from an empty chip input */
  onDeletePrevious?(): void;
  /** Called when the 'x' on a chip is clicked */
  onDelete?(): void;
  /** Called when the user clicks away from the input while it is empty */
  onBlurredWhileEmpty?(): void;
  disabled?: boolean;
}

/**
 * This component renders either a single chip or a single input, depending
 * on if the value passed to it is a valid email.
 */
export const EmailInputChip: React.FC<EmailInputChipProps> = (props) => {
  const {
    value,
    onChange,
    inputRef: userInputRef,
    onValidate,
    onDeletePrevious,
    onDelete,
    onBlurredWhileEmpty,
    disabled,
    ...rest
  } = props;
  const classes = useStyles(props);

  const previousValueRef = useRef(value);
  const inputRef = useRef<HTMLInputElement>(null);

  const [allowTyping, setAllowTyping] = useState(!resemblesEmail(value));
  const timeOfLastPasteRef = useRef<number>(0);
  const isFirstRenderRef = useRef(true);

  // If the value changed from an empty string to a valid email all at
  // once, the user can't be typing.  This had to have happened either
  // programmatically or by pasting.  It the change was programmatic,
  // close the chip.
  // These values are changed programmatically in the Avid and Disclosures
  // flows when the default recipients need to be fetched from the backend.
  useEffect(() => {
    const pasteCausedChange = Date.now() - timeOfLastPasteRef.current < 1000;
    if (pasteCausedChange) return;

    if (!previousValueRef.current && resemblesEmail(value)) {
      setAllowTyping(false);
    }
    previousValueRef.current = value;
  }, [previousValueRef, value]);

  // Focus on first render
  useEffect(() => {
    // Put this in a timer because it was causing renders to stutter.
    setTimeout(() => inputRef.current?.focus(), 400);
  }, []);

  // Focus when changing to allow typing
  useEffect(() => {
    if (isFirstRenderRef.current) {
      isFirstRenderRef.current = false;
      return;
    }

    if (allowTyping) inputRef.current?.focus();
  }, [allowTyping]);

  function handleBlur() {
    if (resemblesEmail(value)) {
      setAllowTyping(false);
      onValidate?.();
    }

    if (value === '') onBlurredWhileEmpty?.();
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (disabled) return;

    if (['Enter', ',', ' '].includes(e.key)) {
      e.preventDefault();
      handleBlur();
    }

    // The user still needs to be able to tab to the next field
    // as a keyboard user typically would
    if (e.key === 'Tab' && resemblesEmail(value)) {
      e.preventDefault();
      handleBlur();
    }

    if (e.key === 'Backspace' && value === '') {
      e.preventDefault();
      onDeletePrevious?.();
    }
  }

  if (disabled && !value) {
    // Pretend to be a chip, without actually being a chip
    return <div className={classes.chipWrapper} style={{ width: 5, height: 27 }} />;
  }

  if (allowTyping) {
    return (
      <EmailMetaInput
        {...rest}
        value={value}
        onChange={onChange}
        className={clsx(classes.inputAndChip, classes.input)}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        inputRef={mergeRefs(inputRef, userInputRef)}
        disabled={disabled}
        // Prevent from bubbling up to EmailChipsInput where focus will be stolen
        onClick={(e) => e.stopPropagation()}
        onPaste={() => (timeOfLastPasteRef.current = Date.now())}
      />
    );
  }

  return (
    <div className={classes.chipWrapper}>
      <Chip
        onClick={disabled ? undefined : () => setAllowTyping(true)}
        className={clsx(classes.inputAndChip, classes.chip)}
        label={value}
        variant="outlined"
        onDelete={disabled ? undefined : onDelete}
        avatar={
          <Avatar className={classes.chipIcon}>
            <UserIcon className={classes.chipIcon} />
          </Avatar>
        }
      />
    </div>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  makeStyles,
  Theme,
  Dialog,
  DialogProps,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { StdDialog } from 'src/common/StdDialog';
import { DialogFormProps, SubmitEvent } from 'src/pages/Team/AuthDialog/Interfaces/DialogFormProps';
import { useIsMobile } from '../../hooks';
import { DialogCloseButton } from '../../common/DialogCloseButton';

const useStyles = makeStyles((theme: Theme) => ({
  dialogTitleRoot: {
    padding: theme.spacing(5, 3, 1, 3),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5, 6, 2, 6),
    },
  },
  dialogContentRoot: {
    padding: theme.spacing(1, 3, 2, 3),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 6, 4, 6),
    },
  },
  title: {
    textAlign: 'left',
    fontSize: '2rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
    },
  },
  subtitle: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  paperWidthMd: {
    maxWidth: 720,
  },
  actionsRoot: {
    border: `1px solid ${theme.colors.grey.disable}`,
    backgroundColor: theme.colors.grey.offWhite3,
    padding: theme.spacing(2, 6),
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      bottom: 0,
      zIndex: 2,
      left: 0,
      right: 0,
      flexDirection: 'column-reverse',
      marginBottom: 0,
      padding: theme.spacing(1, 3),
    },
  },
  actionButton: {
    fontSize: 16,
    lineHeight: '22px',
    letterSpacing: 'normal',
    textAlign: 'center',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    padding: theme.spacing(0.5, 1.5),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1, 3),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
      justifyContent: 'center',
      width: '100%',
      fontSize: '1.1875rem',
    },
    '&$disabled': {
      color: theme.colors.grey.label,
    },
  },
  secondaryActionButton: {
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
    },
  },
  stepNumberLabel: {
    color: theme.palette.primary.main,
    marginLeft: 'auto',
  },
  skipButton: {
    background: 'none',
  },
  backIcon: {
    border: '2px solid',
    borderColor: theme.colors.grey.label,
    borderRadius: '100px',
    color: theme.colors.grey.label,
    padding: 10,
    marginRight: 10,
  },
}));

export interface WizardDialogStepProps extends DialogFormProps {
  onNext: () => void;
}

export interface WizardDialogStepOptions {
  steps: WizardDialogStep[];
  customSkipHandler?: () => void;
}

export interface WizardDialogStep {
  stepNumber: number;
  stepName: string;
  component: (props: WizardDialogStepProps & any) => JSX.Element;
  additionalProps?: any;
  isSkippable: boolean;
  isCompleted: boolean;
}

export class WizardDialogStep implements WizardDialogStep {
  stepNumber: number;
  stepName: string;
  component: (props: WizardDialogStepProps & any) => JSX.Element;
  additionalProps?: any;
  isSkippable: boolean;
  isCompleted: boolean;

  public constructor(init?: Partial<WizardDialogStep>) {
    Object.assign(this, init);
  }

  setIsSkippable(value: boolean) {
    this.isSkippable = value;
  }

  setIsCompleted(value: boolean) {
    this.isCompleted = value;
  }
}

export interface WizardDialogProps extends DialogProps {
  stepOptions: WizardDialogStepOptions;
  title?: string;
  subtitle?: string;
  onClose: () => void;
  onFinish: () => void;
  classes?: DialogProps['classes'] & {
    titleRoot?: string;
    title?: string;
    subtitle?: string;
    content?: string;
    actions?: string;
  };
}

export const WizardDialog = (props: WizardDialogProps) => {
  const { title, subtitle, onClose, classes: userClasses = {}, ...rest } = props;
  const classes = useStyles();
  const isMobile = useIsMobile();

  const [stepOptions] = useState(props.stepOptions);
  const [currentStep, setCurrentStep] = useState<WizardDialogStep>();
  const [isLoading, setIsLoading] = useState(false);
  const [submitEvent, setSubmitEvent] = useState<SubmitEvent>({ readyToSubmit: false });

  const skipHandler = () => {
    if (stepOptions.customSkipHandler) stepOptions.customSkipHandler();
  };

  // Init currentStep with first step from props and set last step to not be skippable
  useEffect(() => {
    if (stepOptions.steps && !currentStep) {
      setCurrentStep(stepOptions.steps[0]);

      // Default skip logic
      stepOptions.steps[stepOptions.steps.length - 1].setIsSkippable(false);

      // Override with custom skip logic if exists
      skipHandler();
    }
  }, [stepOptions, currentStep]);

  const isLastStep = () => currentStep?.stepNumber === stepOptions.steps.length;

  const goToNextStep = () => {
    if (isLastStep()) return props.onFinish();
    skipHandler();
    setSubmitEvent({ readyToSubmit: false });
    if (currentStep && currentStep?.stepNumber < stepOptions.steps.length) {
      setCurrentStep(stepOptions.steps.find((x) => x.stepNumber === currentStep.stepNumber + 1));
    }
  };

  const onNext = () => {
    stepOptions.steps[currentStep!.stepNumber - 1].setIsCompleted(true);
    goToNextStep();
  };

  return (
    <Dialog
      {...rest}
      onClose={onClose}
      classes={{ ...userClasses, paperWidthMd: clsx(classes.paperWidthMd, userClasses.paperWidthMd) }}
      fullWidth
      maxWidth="md"
      fullScreen={isMobile}
    >
      <DialogCloseButton onClick={onClose} />
      <DialogTitle disableTypography classes={{ root: clsx(classes.dialogTitleRoot, userClasses.titleRoot) }}>
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          {/* Phase 2 implements back button logic 
          {currentStep && currentStep?.stepNumber > 1 && <ArrowBack fontSize="small" className={classes.backIcon} />} */}
          {title && (
            <Typography variant="h1" className={clsx(classes.title, userClasses.title)}>
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography variant="h3" className={clsx(classes.subtitle, userClasses.subtitle)}>
              {subtitle}
            </Typography>
          )}
          <Typography variant="h3" className={classes.stepNumberLabel}>
            {currentStep?.stepNumber} of {stepOptions.steps.length}
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent classes={{ root: clsx(classes.dialogContentRoot, userClasses.content) }}>
        {currentStep && (
          <currentStep.component
            key={currentStep.stepNumber}
            onNext={onNext}
            setLoading={setIsLoading}
            submitEvent={submitEvent}
            {...currentStep.additionalProps}
          />
        )}
      </DialogContent>
      <DialogActions classes={{ root: clsx(classes.actionsRoot, userClasses.actions) }}>
        {currentStep?.isSkippable && (
          <StdDialog.SecondaryActionButton onClick={goToNextStep} variant="outlined" style={{ marginRight: 'auto' }}>
            Skip
          </StdDialog.SecondaryActionButton>
        )}
        <StdDialog.SecondaryActionButton onClick={() => onClose()} color="secondary">
          Cancel
        </StdDialog.SecondaryActionButton>
        <StdDialog.PrimaryActionButton
          type="button"
          onClick={() => setSubmitEvent({ readyToSubmit: true })}
          isLoading={isLoading}
        >
          {isLastStep() ? 'Finish' : 'Next'}
        </StdDialog.PrimaryActionButton>
      </DialogActions>
    </Dialog>
  );
};

import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { AxiosError } from 'axios';
import { getOrCreateUser } from 'src/lib/api/user';
import { useAuthStore } from 'src/lib/auth/store';
import { FormsUserProfile } from 'src/lib/types';

export const getUserProfileKey = 'user-profile';

export const useGetUserProfile = (
  options: UseQueryOptions<FormsUserProfile, AxiosError> = {}
): UseQueryResult<FormsUserProfile, AxiosError> => {
  const authStore = useAuthStore();

  return useQuery<FormsUserProfile, AxiosError, FormsUserProfile>(
    [getUserProfileKey, authStore.userId],
    () =>
      getOrCreateUser(
        authStore.userId!,
        authStore.userInfo.firstName,
        authStore.userInfo.lastName,
        authStore.userInfo.email
      ),
    {
      ...options,
      enabled: Boolean(
        authStore.userId! &&
          authStore.userInfo.firstName &&
          authStore.userInfo.lastName &&
          authStore.userInfo.email &&
          (options.enabled == null || options.enabled)
      ),
    }
  );
};

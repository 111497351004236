export interface ParcelNumberLanguage {
  parcelNumberTayTip: string | null;
  parcelNumberStepTitle: string;
  parcelNumberPlaceholder: string;
  parcelNumberLabel: string;
}

export const useParcelNumberLanguageForStateStep = (state: string): ParcelNumberLanguage => {
  switch (state) {
    case 'AZ':
      return {
        parcelNumberTayTip: 'The tax parcel number can be found on public websites or other listing services.',
        parcelNumberStepTitle: 'Please enter the Tax Parcel Number for this property.',
        parcelNumberPlaceholder: '',
        parcelNumberLabel: 'Tax Parcel Number',
      };
    default:
      return {
        parcelNumberTayTip:
          'The assessor parcel number (APN) can be found on public websites or other listing services.',
        parcelNumberStepTitle: 'Please add the APN for this property.',
        parcelNumberPlaceholder: 'APN',
        parcelNumberLabel: 'APN',
      };
  }
};

import { useMemo } from 'react';
import { TemplateStatus } from 'src/lib/constants';
import { useGetDisclosuresByRegion } from 'src/hooks';

export const useGetLiveRegions = () => {
  const { data: disclosureList } = useGetDisclosuresByRegion();

  // Filter the templates by LIVE status, grab the states, flatten and filter for unique values
  return useMemo(
    () =>
      disclosureList
        ?.filter((template) => template.tags.status.includes(TemplateStatus.LIVE))
        .map((template) => template.tags.state)
        .sort()
        .flat()
        .filter((v, i, a) => a.indexOf(v) === i),
    [disclosureList]
  );
};

import React, { useRef } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core';
import { useId } from 'src/hooks';
import { EmailLineLabel } from './EmailLineLabel';
import { composeEmailVisualInputStyles } from './ComposeEmail';

const visualInputStyles = (theme: Theme) => ({
  ...composeEmailVisualInputStyles(theme),
  padding: theme.spacing(1, 3),
  paddingRight: 0,

  [theme.breakpoints.down('xs')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
});

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',

      // On desktop screens the root is used as the "input box"
      [theme.breakpoints.up('sm')]: {
        ...visualInputStyles(theme),
        flexDirection: 'row',
      },
    },
    chipInput: {
      flexGrow: 1,

      // On mobile screens, the inner chips input element is used as
      // the "input box" and the label is therefore rendered outside
      // the "input box"
      [theme.breakpoints.down('xs')]: visualInputStyles(theme),
    },
    bodyTextArea: {
      width: '100%',
      border: '0px',
      fontSize: 'inherit',
      fontFamily: 'inherit',
    },
  }),
  { classNamePrefix: 'EmailMetaRecipientField' }
);

export interface EmailMetaRecipientFieldProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  disabled?: boolean;
  value: string;
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const EmailMetaSubjectField: React.FC<EmailMetaRecipientFieldProps> = (props) => {
  const classes = useStyles();
  const { className, disabled, value, label, onChange, ...rest } = props;
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const labelId = useId(`email-recipient-${label}`);

  return (
    <div {...rest} className={clsx(className, classes.root)}>
      <EmailLineLabel inputRef={inputRef} id={labelId}>
        {label}
      </EmailLineLabel>
      <input
        className={classes.bodyTextArea}
        placeholder="Compose email message..."
        value={value}
        onChange={(e) => {
          onChange(e);
        }}
        disabled={!!disabled}
      />
    </div>
  );
};

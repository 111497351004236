import { atom } from 'recoil';
import { createSessionStorageEffect } from 'src/state/utils';

const storageKey = 'is-nav-open';

const writeToSessionStorageEffect = createSessionStorageEffect<boolean>(storageKey);

export const isNavMenuOpenAtom = atom<boolean>({
  key: 'is-nav-menu-open',
  default: true,
  effects_UNSTABLE: [writeToSessionStorageEffect],
});

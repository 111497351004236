import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(
  {
    root: {
      width: '1px !important',
      height: '1px !important',
      border: '0 !important',
      clip: 'rect(0, 0, 0, 0) !important',
      margin: '-1px !important',
      overflow: 'hidden !important',
      padding: '0 !important',
      position: 'absolute',
    },
  },
  { classNamePrefix: 'SrOnly' }
);

/**
 * This component allows rendering something that is only visible
 * to screen readers or other automated processes that are reading
 * the site (e.g. search engine crawlers).
 *
 * It also has a bonus feature in development mode: if you press
 * the Alt key (Option on Mac), the hidden contents will be revealed.
 * This allows sighted developers to quickly and easily check what
 * the hidden contents is.
 */
export const SrOnly: React.FC<React.HtmlHTMLAttributes<HTMLSpanElement>> = (props) => {
  const { children, className, ...rest } = props;
  const classes = useStyles(props);

  const [forceShow, setForceShow] = useState(false);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') return;

    function handleKeyDown(ev: KeyboardEvent) {
      if (ev.key === 'Alt') setForceShow(true);
    }
    function handleKeyUp(ev: KeyboardEvent) {
      if (ev.key === 'Alt') setForceShow(false);
    }

    window.addEventListener('keyup', handleKeyUp);
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keyup', handleKeyUp);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <span {...rest} className={clsx(className, { [classes.root]: !forceShow })}>
      {children}
    </span>
  );
};

import React, { useMemo, useRef, useState } from 'react';
import { Button, Theme, makeStyles, InputAdornment, IconButton } from '@material-ui/core';
import { Search as SearchIcon, Close as CloseIcon } from '@material-ui/icons';
import { NothingHereCard, TextInput } from 'src/common';
import { useTeamPagePermissionRequests, useCancelOrRevokeDelegateInvite } from 'src/hooks';
import { ErrorCodes, sortPendingFirst, UserDelegateWithDelegateId, UserDelegateWithOwnerId } from 'src/lib';
import {
  useInviteTeamMember,
  useApproveDelegate,
  useCustomSnackbar,
  useGetActiveUserProfile,
  UserPermissionRequestData,
} from '../../hooks';
import { UserDelegateWithMetadata } from '../../lib/types';
import { Collaborator } from './Collaborator';
import { TeamPageUI } from './TeamPageUI';
import { AddCollaboratorsModal } from './AddCollaboratorsModal';
import { ActionType } from './types';
import { ChooseMyTeamAuthDialog } from './AuthDialog/ChooseMyTeamAuthDialog';

/**
 * An interface to extend the shape of `UserDelegateWithMetadata` that enables us to
 * maintain a reference to a given collaborator's `delegateId` and `ownerId` within a single object.
 * Also introduced pseudo-state properties to help track share/request states for a given collaborator.
 */
export interface UserDelegate extends UserDelegateWithDelegateId, UserDelegateWithOwnerId {
  isShareApproved: boolean;
  isShareRevoked: boolean;
  isRequestApproved: boolean;
  isRequestRevoked: boolean;
  isGroupUser: boolean;
}

/**
 * A helper that copies `delegatedFromUserRequests` and `delegatedToUserRequests`, casts them as `UserDelegate[]`,
 * then concats & checks to see if elements in that array contains any given collaborator's email twice.
 * If so, it merges the objects together - resulting in an array with distinct collaborator objects that have
 * references to their own `delegateId` & `ownerId`, plus pseudo-state properties for tracking share/requests states.
 */
function mergeToAndFromRequests(requests: UserPermissionRequestData | undefined) {
  let requestsToUsers = (requests?.delegatedFromUserRequests || []) as UserDelegate[];
  requestsToUsers = requestsToUsers.map((r) => {
    const copy = { ...r };
    copy.isRequestRevoked = r.isRevoked;
    copy.isRequestApproved = r.isApproved;
    return copy;
  });

  let requestsFromUsers = (requests?.delegatedToUserRequests || []) as UserDelegate[];
  requestsFromUsers = requestsFromUsers.map((r) => {
    const copy = { ...r };
    copy.isShareRevoked = r.isRevoked;
    copy.isShareApproved = r.isApproved;
    return copy;
  });

  let requestsToUsersGroup = (requests?.delegatedToUserGroupRequests || []) as UserDelegate[];
  requestsToUsersGroup = requestsToUsersGroup.map((r) => ({
    ...r,
    isRequestRevoked: r.isRevoked,
    isRequestApproved: true,
    isGroupUser: true,
  }));

  let requestsFromUsersGroup = (requests?.delegatedFromUserGroupRequests || []) as UserDelegate[];
  requestsFromUsersGroup = requestsFromUsersGroup.map((r) => {
    const copy = { ...r };
    copy.isShareRevoked = r.isRevoked;
    copy.isShareApproved = r.isApproved;
    copy.isGroupUser = true;
    return copy;
  });

  const allRequests = requestsToUsers
    .concat(requestsFromUsers)
    .concat(requestsFromUsersGroup)
    .concat(requestsToUsersGroup);
  return allRequests?.reduce((acc: UserDelegate[], curr) => {
    const index = acc.findIndex((o) => o.email === curr.email);
    if (index === -1) {
      acc.push(curr);
    } else {
      acc[index] = { ...acc[index], ...curr };
    }
    return acc;
  }, []);
}

const useStyles = makeStyles((theme: Theme) => ({
  searchSection: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
  },
  searchInputRoot: {
    flexGrow: 1,
  },
  searchInput: {
    margin: '16px 0',
    borderRadius: 999, // An arbitrarily large value
    fontSize: '1rem',
  },
}));

export const TeamPage = () => {
  const classes = useStyles();
  const [carCredsDialogSubmitText, setCarCredsDialogSubmitText] = useState<string | null>(null);
  const [isEmailCollectionDialogOpen, setIsEmailCollectionDialogOpen] = useState(false);
  const [isAddCollaboratorsDialogOpen, setAddCollaboratorsDialogOpen] = useState(false);
  const [actionType, setActionType] = useState<ActionType | null>(null);

  const { mutateAsync: approveDelegate } = useApproveDelegate();
  const { mutateAsync: cancelOrRevokeInvite } = useCancelOrRevokeDelegateInvite();
  const { mutateAsync: inviteTeamMember, isLoading: isInviteLoading } = useInviteTeamMember(actionType);

  const postCredsApprovedAction = useRef<() => void>();

  const { data: activeUserProfile } = useGetActiveUserProfile();
  const hasBrokerageNameAndLicenseNumber = !!activeUserProfile?.brokerageName && !!activeUserProfile?.licenseNumber;

  const { data: requests, isLoading } = useTeamPagePermissionRequests();

  const filterActiveUserFromGroups = (requests: UserPermissionRequestData | undefined) => {
    if (!requests) {
      return undefined;
    }
    const { delegatedToUserGroupRequests, delegatedFromUserGroupRequests } = requests;

    const updatedDelegatedToUserGroupRequests = delegatedToUserGroupRequests
      ? delegatedToUserGroupRequests.filter((g) => g.email !== activeUserProfile?.email)
      : [];

    const updatedDelegatedFromUserGroupRequests = delegatedFromUserGroupRequests
      ? delegatedFromUserGroupRequests.filter((g) => g.email !== activeUserProfile?.email)
      : [];

    const updatedRequests = {
      ...requests,
      delegatedToUserGroupRequests: updatedDelegatedToUserGroupRequests,
      delegatedFromUserGroupRequests: updatedDelegatedFromUserGroupRequests,
    };

    return updatedRequests;
  };

  const updatedRequests = filterActiveUserFromGroups(requests);

  const collaborators = useMemo(() => mergeToAndFromRequests(updatedRequests), [updatedRequests]);

  const hasCollaborators = collaborators?.length;

  const snackbarController = useCustomSnackbar();

  const handleSendInvitations = async (emails: string[]) => {
    await inviteTeamMember({ delegateEmails: emails }, { onSuccess: () => setIsEmailCollectionDialogOpen(false) });
    setActionType(null);
  };

  const approveDelegateInvite = async (delegate: UserDelegateWithDelegateId) => {
    await approveDelegate(
      { delegates: [delegate] },
      {
        onSuccess: () => snackbarController.addSuccessSnackbar(`${delegate.email} was added to your team`),
        onError: (e) => {
          console.error(e);
          snackbarController.addErrorSnackbar({
            message: `Failed to approve ${delegate.email}`,
            errorCode: ErrorCodes.TeamPageApproveDelegateInvite,
          });
        },
      }
    );
  };

  const handleApproveDelegate = async (delegate: UserDelegateWithDelegateId) => {
    setCarCredsDialogSubmitText('Save');
    postCredsApprovedAction.current = () => approveDelegateInvite(delegate);
    approveDelegateInvite(delegate);
  };

  const handleCancelInvite = (delegate: UserDelegate, action: ActionType) => {
    cancelOrRevokeInvite({ delegate: delegate, action: action, status: 'pending' });
  };

  const handleRevokeInvite = (delegate: UserDelegate, action: ActionType) => {
    cancelOrRevokeInvite({ delegate: delegate, action: action, status: 'approved' });
  };

  const handlePrimaryCTAClick = (actionType: ActionType) => {
    setActionType(actionType);
    if (actionType === ActionType.SHARE) {
      setCarCredsDialogSubmitText('Next');
      postCredsApprovedAction.current = () => setIsEmailCollectionDialogOpen(true);
    } else {
      setIsEmailCollectionDialogOpen(true);
    }
  };

  const handleCredsAreValid = () => {
    setCarCredsDialogSubmitText(null);
    postCredsApprovedAction.current?.();
  };

  const onAddCollaboratorsNext = (action: ActionType) => {
    setAddCollaboratorsDialogOpen(false);
    setActionType(action);
    handlePrimaryCTAClick(action);
  };

  const searchInputRef = useRef<HTMLInputElement>();
  const [searchCollaboratorQuery, setSearchCollaboratorQuery] = useState('');
  const filterCollaboratorQuery = (collaboratorMetadata: UserDelegateWithMetadata) => {
    const { firstName, lastName, email } = collaboratorMetadata;
    if (searchCollaboratorQuery === '') return true;
    return (
      firstName?.toLowerCase().indexOf(searchCollaboratorQuery.toLowerCase()) === 0 ||
      lastName?.toLowerCase().indexOf(searchCollaboratorQuery.toLowerCase()) === 0 ||
      email?.toLowerCase().indexOf(searchCollaboratorQuery.toLowerCase()) === 0
    );
  };
  const filteredCollaborators = collaborators?.sort(sortPendingFirst).filter((c) => filterCollaboratorQuery(c));
  return (
    <TeamPageUI
      showCTA={Boolean(hasCollaborators)}
      onSendInvitations={handleSendInvitations}
      isLoading={isLoading}
      onPrimaryCTAClick={handlePrimaryCTAClick}
      isEmailCollectionDialogOpen={isEmailCollectionDialogOpen}
      setIsEmailCollectionDialogOpen={setIsEmailCollectionDialogOpen}
      isSendingEmails={isInviteLoading}
      actionType={actionType}
      collaboratorCount={filteredCollaborators.length}
    >
      {!isLoading && Boolean(!hasCollaborators) && (
        <NothingHereCard
          title={
            <>
              Add collaborators to share or request access
              <br />
              to create and send disclosures for others.
            </>
          }
          subtitle={
            <Button variant="contained" onClick={() => setAddCollaboratorsDialogOpen(true)} color="primary">
              Start Sharing
            </Button>
          }
        />
      )}

      {Boolean(hasCollaborators) && (
        <TextInput
          size="small"
          className={classes.searchInputRoot}
          value={searchCollaboratorQuery}
          onChange={(e) => setSearchCollaboratorQuery(e.target.value)}
          inputRef={searchInputRef}
          InputProps={{
            className: classes.searchInput,
            endAdornment: (
              <InputAdornment position="end">
                {searchCollaboratorQuery.length > 0 ? (
                  <IconButton onClick={() => setSearchCollaboratorQuery('')}>
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <SearchIcon />
                )}
              </InputAdornment>
            ),
          }}
          placeholder="Search Collaborators"
        />
      )}

      {filteredCollaborators.map((c) => (
        <Collaborator
          key={c.email}
          shareVariant={c.delayedActionId && c.isShareApproved !== undefined ? 'pending' : 'approval'}
          requestVariant={c.isRequestApproved === false ? 'pending' : undefined}
          userDelegate={c}
          onRemoveDelegate={(actionType) => handleRevokeInvite(c, actionType)}
          onCancelInvite={(actionType) => handleCancelInvite(c, actionType)}
          onApproveInvite={() => handleApproveDelegate(c)}
        />
      ))}

      {filteredCollaborators.length === 0 && searchCollaboratorQuery.length > 0 && (
        <NothingHereCard title="No results found" subtitle="Try adjusting your search terms and try again" />
      )}

      {actionType !== ActionType.REQUEST && Boolean(carCredsDialogSubmitText) && (
        <ChooseMyTeamAuthDialog
          onCredsAreValid={handleCredsAreValid}
          submitBtnText={carCredsDialogSubmitText!}
          onClose={() => setCarCredsDialogSubmitText(null)}
          hasBrokerageNameAndLicenseNumber={hasBrokerageNameAndLicenseNumber}
        />
      )}
      <AddCollaboratorsModal
        open={isAddCollaboratorsDialogOpen}
        onClose={() => setAddCollaboratorsDialogOpen(false)}
        onNext={onAddCollaboratorsNext}
      />
    </TeamPageUI>
  );
};

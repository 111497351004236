import React, { useState, useContext } from 'react';
import {
  appEnvironments,
  ENV,
  Contact,
  UploadedDocument,
  Address,
  LookupType,
  WizardStoreApi,
  MultiSearchResult,
  EscrowOfficer,
} from 'src/lib';
import { DisclosureDefinition } from 'src/hooks';
export interface SpqTdsStore {
  forms?: DisclosureDefinition[];
  apn: string;
  yearBuilt: string;
  isFireHazard: boolean;
  property?: Address;
  propertyTypes: LookupType[];
  existingFileId?: number;
  existingPackageId?: string;
  sellers: Contact[];
  teamMembers: Contact[];
  selectedProperty?: MultiSearchResult;
  sectionThreeNotes?: string;
  uploadedDocuments: UploadedDocument[];
  downloadType: string;
  mlsUuid?: string;
  hasMhtds?: boolean;
  escrowOfficer?: EscrowOfficer;
  zoning: string;
}

const getDefaultStore = (): WizardStoreApi<SpqTdsStore> => ({
  sellers: [],
  teamMembers: [],
  forms: undefined,
  apn: '',
  yearBuilt: '',
  isFireHazard: false,
  propertyTypes: ['multi'],
  sectionThreeNotes: '',
  update: () => null,
  reset: () => null,
  uploadedDocuments: [],
  downloadType: '',
  mlsUuid: '',
  escrowOfficer: undefined,
  zoning: '',
});

export const SpqTdsStoreContext = React.createContext<WizardStoreApi<SpqTdsStore>>(getDefaultStore());

export const useCreateDisclosuresStore = (defaultOverride: Partial<SpqTdsStore> = {}): WizardStoreApi<SpqTdsStore> => {
  const [store, setStore] = useState<SpqTdsStore>({
    ...getDefaultStore(),
    ...defaultOverride,
    ...(defaultOverride.existingFileId ? { propertyTypes: ['existing'] } : {}),
  });

  const update = (obj: Partial<SpqTdsStore>) => {
    const newStore = { ...store, ...obj };
    setStore(newStore);
    if (ENV !== appEnvironments.PROD) {
      // eslint-disable-next-line no-console
      console.info('STORE UPDATED: ', newStore);
    }
  };

  const reset = () => {
    setStore(getDefaultStore());
  };

  return { ...store, update, reset };
};

export const SpqTdsStoreProvider = SpqTdsStoreContext.Provider;

export const useDisclosuresStore = () => useContext(SpqTdsStoreContext);

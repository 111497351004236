import { AxiosError } from 'axios';
import { gql } from 'graphql-request';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import * as Sentry from '@sentry/react';
import { getApiToken, getGraphQLClient } from '../../../lib';
import { useGetUserPermissionsRequestsKey, UserPermissionRequestData } from '../../../hooks';

const mutationQuery = gql`
  mutation cancelDelayedUserAction($id: String!) {
    cancelDelayedUserAction(id: $id)
  }
`;

interface MutationContext {
  previousInvites: UserPermissionRequestData;
}

export const cancelDelayedUserActionMutationKey = 'cancel-delayed-user-action-mutation';

export const useCancelDelayedUserAction = (options: UseMutationOptions<boolean, AxiosError, string> = {}) => {
  const queryClient = useQueryClient();
  return useMutation<boolean, AxiosError, string>(
    async (payload: string) => {
      const client = getGraphQLClient(await getApiToken());
      return (await client.request<{ res: boolean }, { id: string }>(mutationQuery, { id: payload })).res;
    },
    {
      mutationKey: cancelDelayedUserActionMutationKey,
      // When mutate is called:
      async onMutate(payload: string): Promise<MutationContext> {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(useGetUserPermissionsRequestsKey);

        // Snapshot the previous value
        const previousInvites: UserPermissionRequestData = queryClient.getQueryData(useGetUserPermissionsRequestsKey)!;

        // Optimistically update to the new value
        queryClient.setQueryData(
          useGetUserPermissionsRequestsKey,
          (userPermissionRequest: UserPermissionRequestData) => {
            const delayedActionIdToCancel = payload;

            return {
              ...userPermissionRequest,
              delegatedFromUserRequests: userPermissionRequest.delegatedFromUserRequests.filter(
                (delegatingAgent) => delegatingAgent.delayedActionId !== delayedActionIdToCancel
              ),
              delegatedToUserRequests: userPermissionRequest.delegatedToUserRequests.filter(
                (delegatingAgent) => delegatingAgent.delayedActionId !== delayedActionIdToCancel
              ),
            };
          }
        );

        // Return a context object with the snapshotted value
        return { previousInvites };
      },
      // If the mutation fails, use the context we returned above
      onError(e, variables, context: MutationContext) {
        console.error('An error occurred while cancelling delayed user action');
        Sentry.captureException(e);
        queryClient.setQueryData(useGetUserPermissionsRequestsKey, () => context.previousInvites);
        options?.onError?.(e, variables, context);
      },
      // Always refetch after error or success:
      onSettled() {
        queryClient.invalidateQueries(useGetUserPermissionsRequestsKey);
      },
      ...options,
    }
  );
};

import React from 'react';
import { Menu, MenuProps, MenuItem } from '@material-ui/core';
import { CenteredLoader } from 'src/common';
import { useFlags } from 'src/hooks/useFlags';
import { FilePackageItemActionsProps } from './FilePackageItemActions';
import { useFilePackageItemActions } from './useFilePackageItemActions';

export interface MobilePopupMenuProps extends MenuProps, FilePackageItemActionsProps {
  onMenuItemClick(): void;
  setIsCancelInterviewsOpen(open: boolean): void;
}

export const MobilePopupMenu = (props: MobilePopupMenuProps) => {
  const {
    children,
    open,
    onClose,
    anchorEl,
    actions,
    onMenuItemClick,
    setIsCancelInterviewsOpen,
    hideSignNowOption = false,
  } = props;

  const { isContinueInFormsEnabled } = useFlags();
  const {
    onEditPackage,
    onResendEnvelopes,
    onResendPackage,
    onCancelEnvelopes,
    onSendForms,
    onSendDisclosuresEnvelopes,
    onGetSignerToken,
    isFetchingSignerToken,
    onSendDisclosuresToForms,
    isSendingDisclosuresToForms,
  } = useFilePackageItemActions(props);

  return (
    <>
      <Menu open={open} onClose={onClose} anchorEl={anchorEl}>
        {actions.ds3Resend && !hideSignNowOption && (
          <MenuItem
            onClick={() => {
              onGetSignerToken();
              onMenuItemClick();
            }}
          >
            Sign Now
          </MenuItem>
        )}
        {actions.formsSend && isContinueInFormsEnabled && (
          <MenuItem
            onClick={() => {
              onSendDisclosuresToForms();
              onMenuItemClick();
            }}
          >
            Add to Forms
          </MenuItem>
        )}
        {actions.ds3Send && (
          <MenuItem
            onClick={() => {
              onSendDisclosuresEnvelopes();
              onMenuItemClick();
            }}
          >
            Send for Signatures
          </MenuItem>
        )}
        {actions.sendForms && (
          <MenuItem
            onClick={() => {
              onSendForms();
              onMenuItemClick();
            }}
          >
            Send for Signatures
          </MenuItem>
        )}

        {(actions.sklosuresResend || actions.ds3Send) && (
          <MenuItem
            onClick={() => {
              onResendPackage();
              onMenuItemClick();
            }}
          >
            Resend
          </MenuItem>
        )}
        {actions.ds3Resend && (
          <MenuItem
            onClick={() => {
              onResendEnvelopes();
              onMenuItemClick();
            }}
          >
            Resend
          </MenuItem>
        )}

        {actions.packageEdit && (
          <MenuItem
            onClick={() => {
              onEditPackage();
              onMenuItemClick();
            }}
          >
            Edit
          </MenuItem>
        )}
        {actions.sklosuresCancel && (
          <MenuItem
            onClick={() => {
              setIsCancelInterviewsOpen(true);
              onMenuItemClick();
            }}
          >
            Cancel
          </MenuItem>
        )}
        {actions.ds3Cancel && (
          <MenuItem
            onClick={() => {
              onCancelEnvelopes();
              onMenuItemClick();
            }}
          >
            Cancel
          </MenuItem>
        )}

        {children}
      </Menu>
      {(isFetchingSignerToken || isSendingDisclosuresToForms) && <CenteredLoader />}
    </>
  );
};

import React from 'react';
import { makeStyles, Theme, Button } from '@material-ui/core';
import { AddCircleOutline as AddCircleOutlineIcon } from '@material-ui/icons';
import DoorImg from '../../../../../images/door.svg';

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    border: `2px solid ${theme.colors.grey.offWhite3}`,
    borderRadius: 4,
    margin: theme.spacing(3),
    padding: `${theme.spacing(8)}px 0`,
  },
  button: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
      marginTop: theme.spacing(3),
    },
  },
}));

export interface NoRoomsStateProps {
  onAddRooms(): void;
}

export const NoRoomsState: React.FC<NoRoomsStateProps> = ({ onAddRooms }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img src={DoorImg} width={122} height={122} alt="Rooms" />

      <Button
        variant="contained"
        color="primary"
        endIcon={<AddCircleOutlineIcon />}
        className={classes.button}
        onClick={onAddRooms}
      >
        Add Rooms
      </Button>
    </div>
  );
};

import React from 'react';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { Skeleton as Skellie } from '@material-ui/lab';
import { useAuth } from '@skyslope/auth-react';
import pluralize from 'pluralize';
import { useRecoilValue } from 'recoil';
import { useIsMobile, useCustomSnackbar } from 'src/hooks';
import { emailStateAtom, selectedAgentAtom } from 'src/state';
import { ReviewRow, ReviewStep, WizardStepProps } from 'src/domain';
import DisclosuresIcon from 'src/images/disclosuresIcon.svg';
import { Navigation, Step } from 'src/common';
import { ExpandableReviewRow } from 'src/domain/wizard/generic-steps/ReviewStep/ExpandableReviewRow';
import { formatAddress, ErrorCodes } from 'src/lib';
import { useParcelNumberLanguageForStateStep } from 'src/pages/SpqTdsDisclosures/hooks/useParcelNumberLanguageForStateStep';
import { useDisclosuresStore } from '../store';
import { DisclosuresAssociationName } from '../../../common/DisclosuresAssociationName';
import { useGetMissingAuthInfos } from '../hooks/useGetMissingAuthInfos';
import { SpqTdsDisclosureSteps } from './constants';

const useStyles = makeStyles(
  (theme: Theme) => ({
    disclosureItem: {
      '&:not(:last-child)': {
        paddingBottom: theme.spacing(2),
      },
      fontWeight: 600,
      fontSize: '18px',
    },
  }),
  { classNamePrefix: 'SpqTdsReview' }
);

export const SpqTdsReview = ({ goToStep, ...props }: WizardStepProps) => {
  const classes = useStyles(props);
  const store = useDisclosuresStore();
  const isMobile = useIsMobile();
  const { addErrorSnackbar } = useCustomSnackbar();
  const { authState } = useAuth();
  const { data: missingAuthInfos, isLoading: isAuthLoading } = useGetMissingAuthInfos(store?.forms ?? []);
  const selectedAgent = useRecoilValue(selectedAgentAtom);
  const emailState = useRecoilValue(emailStateAtom);

  const onEdit = (step: SpqTdsDisclosureSteps) => {
    goToStep(step);
  };

  const { parcelNumberLabel } = useParcelNumberLanguageForStateStep(store?.property?.state ?? '');

  // Go to SpqTdsDisclsoureSteps.editFileTeamMembers once the long term solution described
  // in INNO2-926 is implemented
  const onEditTeam = () => {
    goToStep(SpqTdsDisclosureSteps.teamMembersDeprecated);
  };

  const handleNextClick = () => {
    const isLibraryAuthenticated = missingAuthInfos!.length === 0;

    if (!authState.isAuthenticated) {
      goToStep(SpqTdsDisclosureSteps.register);
    } else if (!isLibraryAuthenticated && selectedAgent == null) {
      goToStep(SpqTdsDisclosureSteps.credentials, {
        state: { authInfos: missingAuthInfos, currentIndex: 0 },
      });
    } else if (!isLibraryAuthenticated && selectedAgent) {
      addErrorSnackbar({
        message: `The agent you are working on behalf of does not 
        have ${missingAuthInfos!.map((x) => x.libraryName).join(', ')} credentials saved.`,
        errorCode: ErrorCodes.OnBehalfOfAgentMissingLibraryCreds,
      });
    } else {
      goToStep(SpqTdsDisclosureSteps.sending);
    }
  };

  if (isAuthLoading) {
    return (
      <Step title={<Skellie style={{ margin: 'auto' }} width={500} height={60} />}>
        <Grid container spacing={!isMobile ? 2 : undefined}>
          {Array.from({ length: !isMobile ? 15 : 6 }).map((_, i) => (
            <Grid item xs={12} sm={4} key={i}>
              <Skellie height={60} />
            </Grid>
          ))}
        </Grid>
      </Step>
    );
  }

  return (
    <ReviewStep
      icon={<img src={DisclosuresIcon} alt="Disclosures" />}
      navigation={
        <Navigation
          isLoading={isAuthLoading}
          PrevButtonProps={{ children: 'Cancel', onClick: () => goToStep(SpqTdsDisclosureSteps.customEmail) }}
          NextButtonProps={{
            children: 'Send',
            onClick: handleNextClick,
          }}
        />
      }
      teamMembers={store.teamMembers}
      recipients={store.sellers}
      onEditTeam={onEditTeam}
      onEditRecipients={() => onEdit(SpqTdsDisclosureSteps.sellers)}
    >
      <ExpandableReviewRow
        label="Property"
        value={formatAddress(store.property!)}
        onEdit={() => onEdit(SpqTdsDisclosureSteps.property)}
      >
        <>
          {store.apn && (
            <ExpandableReviewRow.Item
              value={`${parcelNumberLabel}: ${store.apn}`}
              onEdit={() => onEdit(SpqTdsDisclosureSteps.apn)}
            />
          )}
          {store.zoning && (
            <ExpandableReviewRow.Item
              value={`Zoning: ${store.zoning}`}
              onEdit={() => onEdit(SpqTdsDisclosureSteps.zoning)}
            />
          )}
          {store.yearBuilt && (
            <ExpandableReviewRow.Item
              value={`Year Built: ${store.yearBuilt}`}
              onEdit={() => onEdit(SpqTdsDisclosureSteps.yearBuilt)}
            />
          )}
          {store.forms?.some((f) => f.name.includes('FHDS')) && (
            <ExpandableReviewRow.Item
              value={`In a Fire Hazard Zone: ${store.isFireHazard ? 'Yes' : 'No'}`}
              onEdit={() => onEdit(SpqTdsDisclosureSteps.fireHazard)}
            />
          )}
        </>
      </ExpandableReviewRow>
      <ReviewRow
        label={`${pluralize('Disclosure', store.forms?.length ?? 0)}`}
        rightSideContent={
          <ReviewRow.Button onClick={() => onEdit(SpqTdsDisclosureSteps.pickForms)}>Edit</ReviewRow.Button>
        }
      >
        {store.forms?.map((form) => (
          <div key={form.name} className={classes.disclosureItem}>
            {form.name} <DisclosuresAssociationName associationName={form.associationName} />
          </div>
        ))}
      </ReviewRow>
      <ReviewRow
        label="Email"
        rightSideContent={
          <ReviewRow.Button onClick={() => onEdit(SpqTdsDisclosureSteps.customEmail)}>Edit</ReviewRow.Button>
        }
      >
        {emailState && `${emailState.body} ${emailState.signature}`}
      </ReviewRow>
    </ReviewStep>
  );
};

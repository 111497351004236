import React from 'react';
import { Send as SendIcon } from '@material-ui/icons';
import { Step, Navigation, ComposeEmail, EmailStateProps, NavigationProps } from 'src/common';

export interface EmailInputs {
  subject: string;
  body: string;
}

export interface EmailFormProps {
  onBack: () => void;
  onSend: (data: EmailInputs) => Promise<void>;
  title: string;
  emailState: EmailStateProps;
  hideCc?: boolean;
  disableEditRecipients?: boolean;
  NavigationProps?: NavigationProps;
}

export const EmailForm = (props: EmailFormProps) => {
  const { onSend, emailState, hideCc, disableEditRecipients, NavigationProps = {} } = props;
  const { PrevButtonProps = {}, NextButtonProps = {}, ...restNavProps } = NavigationProps;

  return (
    <Step title={props.title}>
      <ComposeEmail emailState={emailState} hideCc={hideCc} disableEditRecipients={disableEditRecipients} />
      <Navigation
        {...restNavProps}
        PrevButtonProps={{ children: 'Cancel', onClick: props.onBack, startIcon: null, ...PrevButtonProps }}
        NextButtonProps={{
          children: 'Send',
          endIcon: <SendIcon />,
          disabled: emailState.hasError,
          onClick: () =>
            onSend({
              subject: emailState.subject,
              body: emailState.body,
            }),
          ...NextButtonProps,
        }}
      />
    </Step>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { CenteredLoader } from 'src/common/CenteredLoader';
import {
  WizardDialog,
  WizardDialogStep,
  WizardDialogStepOptions,
  WizardDialogStepProps,
} from 'src/domain/wizard/WizardDialog';
import { useValidateAuths } from 'src/hooks/libraryAuth/useValidateAuths';
import { AuthType, usStates } from 'src/lib';
import { EnterCarCredsForm } from './EnterCarCredsForm';
import { EnterNrdsCredsForm } from './EnterNrdsCredsForm';
import { EnterNwmlsCredsForm } from './EnterNwmlsCredsForm';
import { EnterOrefCredsForm } from './EnterOrefCredsForm';

export interface EnterAuthCredsDialogProps {
  onClose(): void;
  onCredsAreValid(): void;
  submitBtnText?: string;
  hasBrokerageNameAndLicenseNumber: boolean;
}

const mapAuthTypeToComponent = (
  authType: AuthType
): ((props: WizardDialogStepProps & any) => JSX.Element) | undefined => {
  switch (authType) {
    case AuthType.AzNrds:
      return EnterNrdsCredsForm;
    case AuthType.CaNrds:
      return EnterCarCredsForm;
    case AuthType.Ca0808Nrds:
      return EnterNrdsCredsForm;
    case AuthType.NWMLS:
      return EnterNwmlsCredsForm;
    case AuthType.Oref:
      return EnterOrefCredsForm;
    case AuthType.RSARNrds:
      return EnterNrdsCredsForm;
    case AuthType.TxNrds:
      return EnterNrdsCredsForm;
    default:
      return undefined;
  }
};

const generateAdditionalNrdsAuthProps = (authType: AuthType) => {
  switch (authType) {
    case AuthType.AzNrds:
      return {
        libraryName: 'A.A.R',
        licenseLabel: 'ADRE License Number',
        authType: AuthType.AzNrds,
        region: usStates.AZ,
      };
    case AuthType.RSARNrds:
      return {
        libraryName: 'R.S.A.R.',
        licenseLabel: 'NRED License Number',
        authType: AuthType.RSARNrds,
        region: usStates.NV,
      };
    case AuthType.Ca0808Nrds:
      return {
        libraryName: 'S.F.A.R.',
        licenseLabel: '',
        authType: AuthType.Ca0808Nrds,
        region: usStates.CA,
      };
    case AuthType.TxNrds:
      return {
        libraryName: 'T.X.R.',
        licenseLabel: '',
        authType: AuthType.TxNrds,
        region: usStates.TX,
      };
    default:
      return undefined;
  }
};

const skippableAuthTypes = [AuthType.RSARNrds.toString(), AuthType.TxNrds.toString()];

/**
 * This determines which state auth needs to be prompted to the agent before they share access. Right now, states are 1:1 with
 * associations, so show them all auths based on the regions they selected.
 */
export const ChooseMyTeamAuthDialog: React.FC<EnterAuthCredsDialogProps> = (props) => {
  const { onCredsAreValid, onClose } = props;
  const { isLoading, areCredsValid, missingCreds, areSomeCredsValid } = useValidateAuths();
  const [wizardStepOptions, setWizardStepOptions] = useState<WizardDialogStepOptions>();

  const isSkippableAuthType = (authType: string): boolean => skippableAuthTypes.includes(authType);

  useEffect(() => {
    if (areCredsValid) {
      return onCredsAreValid();
    } else if (missingCreds) {
      const stepOptions: WizardDialogStepOptions = {
        steps: missingCreds.map(
          (authType, index) =>
            new WizardDialogStep({
              component: mapAuthTypeToComponent(authType)!,
              isSkippable: true,
              isCompleted: false,
              stepName: authType.toString(),
              stepNumber: index + 1,
              additionalProps: generateAdditionalNrdsAuthProps(authType),
            })
        ),
        customSkipHandler: function () {
          if (areSomeCredsValid) {
            this.steps.forEach((x) => x.setIsSkippable(true));
          } else if (this.steps.some((x) => x.isCompleted)) {
            this.steps.forEach((x) => x.setIsSkippable(true));
          } else if (isSkippableAuthType(this.steps[this.steps.length - 1].stepName)) {
            this.steps[this.steps.length - 1].setIsSkippable(true);
          }
        },
      };
      setWizardStepOptions(stepOptions);
    }
  }, [areCredsValid, missingCreds]);
  if (isLoading) return <CenteredLoader />;

  return (
    <>
      {missingCreds && wizardStepOptions && (
        <WizardDialog
          title="Authorization needed"
          onFinish={props.onCredsAreValid}
          onClose={onClose}
          open={!areCredsValid}
          stepOptions={wizardStepOptions}
        />
      )}
    </>
  );
};

import React from 'react';
import { BannerProps } from '@skyslope/mache/dist/src/components/Banner/types';
import { Banner } from '@skyslope/mache';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.colors.grey.offWhite1,
    border: `1px solid ${theme.colors.grey.offWhite3}`,
    borderRadius: '6px',
    padding: theme.spacing(1.5),
  },
  iconContainer: {
    backgroundColor: theme.colors.grey.offWhite1,
  },
  actionContainer: {
    '& div': {
      marginLeft: theme.spacing(2),
    },
  },
}));

export const PackageBanner: React.FC<BannerProps> = (bannerProps) => {
  const classes = useStyles();
  return <Banner classes={classes} {...bannerProps} />;
};

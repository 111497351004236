import React from 'react';
import { Credentials, WizardStepProps } from 'src/domain';
import { AuthType, getAuthTypeInfo } from 'src/lib';
import { AvidSteps } from '../../AvidCreation';
import { useAvidUserProfileBoundary } from '../useAvidUserProfileBoundary';

export const AvidCarCredentials = (props: WizardStepProps) => {
  const { boundedAction } = useAvidUserProfileBoundary({
    goToStep: props.goToStep,
    targetAction: () => props.goToStep(AvidSteps.review),
  });
  return (
    <Credentials
      onSuccess={boundedAction}
      onBack={() => props.goToStep(AvidSteps.peoplePresent)}
      authInfo={getAuthTypeInfo(AuthType.CaNrds)!}
      isFirst
      total={1}
    />
  );
};

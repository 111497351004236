import React, { useMemo } from 'react';
import { MenuItem } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { downloadTypes, defaultWizardRoutes, objToQueryString } from '../../../../lib';
import { useIsMobile } from '../../../../hooks';
import { useFilePackageItemActions } from './useFilePackageItemActions';
import { FilePackageItemActionsProps } from './FilePackageItemActions';

export interface UseDownloadDisclosuresMenuItemsArgs extends FilePackageItemActionsProps {
  onMenuItemClick: () => void;
}

export const useDownloadDisclosuresMenuItems = (props: UseDownloadDisclosuresMenuItemsArgs) => {
  const isMobile = useIsMobile();
  const history = useHistory();

  const { isAvidFlowCompleted, onMenuItemClick, packageId, packageStatus } = props;

  const {
    fileId,
    firstDocumentStep,
    hasTds,
    hasMhtds,
    isTdsSectionThreeEnabled,
    isTdsSectionThreeEnabledForDownload,
    isTdsTerminated,
    isMhtdsTerminated,
    onDownloadPackageMerged,
    onDownloadPackageZip,
  } = useFilePackageItemActions(props);

  return useMemo(() => {
    const handleClick = (downloadType: string, action: () => void) => {
      onMenuItemClick();

      if (
        !isAvidFlowCompleted &&
        isTdsSectionThreeEnabled &&
        isTdsSectionThreeEnabledForDownload &&
        ((hasTds && !isTdsTerminated) || (hasMhtds && !isMhtdsTerminated))
      ) {
        const query = objToQueryString({ fileId, packageId, default: firstDocumentStep, downloadType });
        const url = `${defaultWizardRoutes.disclosures}/${firstDocumentStep}?${query}`;
        history.push(url);
      } else {
        action();
      }
    };
    if (packageStatus?.status === 'Draft') {
      return [];
    }

    return [
      <MenuItem
        key="package-single"
        dense={!isMobile}
        onClick={() => {
          handleClick(downloadTypes.MERGED, onDownloadPackageMerged);
        }}
      >
        Download as Single PDF
      </MenuItem>,
      <MenuItem
        key="package-zip"
        dense={!isMobile}
        onClick={() => {
          handleClick(downloadTypes.ZIP, onDownloadPackageZip);
        }}
      >
        Download as ZIP File
      </MenuItem>,
    ];
  }, [
    isMobile,
    isAvidFlowCompleted,
    isTdsSectionThreeEnabled,
    isTdsSectionThreeEnabledForDownload,
    hasTds,
    hasMhtds,
    isTdsTerminated,
    isMhtdsTerminated,
    history,
    fileId,
    packageId,
    onDownloadPackageMerged,
    onDownloadPackageZip,
    firstDocumentStep,
    onMenuItemClick,
    packageStatus?.status,
  ]);
};

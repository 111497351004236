import type { MutableRefObject, RefObject } from 'react';
import { useEffect, useRef } from 'react';
import { useAuth } from '@skyslope/auth-react';
import initSignInWidget from '@skyslope/auth-sign-in-widget';
import { config } from 'src/lib';

const useOktaSignInWidget = (): MutableRefObject<HTMLDivElement> | RefObject<HTMLDivElement> => {
  const widgetAnchorRef = useRef<HTMLDivElement>(null);
  const {
    authState: { isAuthenticated },
    userManager,
  } = useAuth();

  useEffect(() => {
    if (widgetAnchorRef.current && !isAuthenticated) {
      const widget = initSignInWidget({
        anchorEl: widgetAnchorRef.current,
        viewOptions: {
          applicationNameOverride: 'SkySlope Breeze',
          logoChoice: 'Breeze',
          useOptionalLinks: true,
          showSignInWithSkySlopeBtn: true,
          startOnRegistrationView: false,
        },
        authConfigOptions: {
          userManager: userManager,
          idpDiscovery: {
            requestContext: config.auth.okta.requestContext,
          },
          redirectAfterPasswordResetUrl: window.location.origin,
        },
      });

      return () => widget.remove();
    }
    return;
  }, [isAuthenticated, userManager]);

  return widgetAnchorRef;
};

export default useOktaSignInWidget;

import React, { useState, useEffect, useRef } from 'react';
import { makeStyles, Button } from '@material-ui/core';
import { Step, Navigation } from 'src/common';
import { contactActions, contactTypes, useAuthStore } from 'src/lib';
import { getRegistrationForm, register as oktaRegister } from 'src/lib/auth/okta';
import { useGetUserProfile, useCreateUserMeta } from 'src/hooks';
import { LoginForm } from './login';
import { RegistrationForm, RegistrationFormInputs } from './RegistrationForm';

const useStyles = makeStyles(
  () => ({
    loginWrapper: {
      display: 'flex',
      justifyContent: 'center',
      margin: '40px 0 -60px 0',
    },
  }),
  { classNamePrefix: 'RegisterWithLibraryAuth' }
);

// this component currently assumes CAR forms, if we use this for other states/associations/etc we'll need a more generic or a new version of this component
export interface RegisterProps {
  onRegister: () => void;
  onBack: () => void;
  onFullyAuthorized: () => void;
  onAuthorizedWithoutLicenseNumber: () => void;
  onNotAuthorized: () => void;
  nextButtonText?: string;
  isAuthenticated?: boolean;
}

export const RegisterWithLibraryAuth = (props: RegisterProps) => {
  const classes = useStyles();

  const authStore = useAuthStore();
  const [isRegistering, setIsRegistering] = useState(false);
  const [apiError, setApiError] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formPromise = useRef<Promise<any> | null>(null);
  const [passwordPolicies, setPasswordPolicies] = useState<string[]>([]);
  const [showLoginButton, setShowLoginButton] = useState(false);
  const [showLoginView, setShowLoginView] = useState(false);

  const { data: userProfile } = useGetUserProfile();
  const { mutateAsync: createUserMeta } = useCreateUserMeta();

  useEffect(() => {
    formPromise.current = getRegistrationForm();
  }, []);

  const onSubmit = async (data: RegistrationFormInputs) => {
    setIsRegistering(true);
    try {
      const { policyId, passwordPolicies } = await formPromise.current;
      setPasswordPolicies(passwordPolicies);
      const user = await oktaRegister(policyId, {
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password,
        phoneNumber: data.phoneNumber,
        email: data.email,
      });
      await createUserMeta({ role: data.role });
      const agentInfo = {
        firstName: `${user!.firstName}`,
        lastName: `${user!.lastName}`,
        email: `${user!.email}`,
        phoneNumber: data.phoneNumber,
        type: contactTypes.BUYERAGENT,
        actionAttribute: contactActions.NEEDSTOSIGN,
      };
      authStore.update({
        loggedIn: true,
        userId: user!.id,
        userInfo: agentInfo,
      });
      props.onRegister();
    } catch (err) {
      setIsRegistering(false);
      // eslint-disable-next-line no-console
      console.log('error is', err);

      // Duplicate email logic
      if (err.message.toLocaleLowerCase().includes('email already exists')) {
        setShowLoginButton(true);
      }
      setApiError(err.message || 'Registration failed, please try again.');
    }
  };

  const handleLogin = (data: RegistrationFormInputs) => {
    // If the user decides to change the input of the email to become something else.
    if (data.email !== authStore.userInfo.email) {
      authStore.update({ userInfo: { ...authStore.userInfo, email: data.email } });
    }
    setShowLoginView(true);
  };

  const handleGoBack = () => {
    props.onBack();
  };

  const handleOnPreviousStep = () => {
    setShowLoginView(false);
    setShowLoginButton(false);
    setApiError('');
  };

  const handleNextStep = () => {
    if (props.isAuthenticated && userProfile?.licenseNumber) {
      props.onFullyAuthorized();
    } else if (props.isAuthenticated && !userProfile?.licenseNumber) {
      props.onAuthorizedWithoutLicenseNumber();
    } else {
      props.onNotAuthorized();
    }
  };

  return (
    <Step
      title={
        !showLoginView
          ? "Welcome! Just so you don’t lose any progress, let's save your account."
          : "Let's get you logged in so you can access your files."
      }
    >
      {!showLoginView ? (
        <RegistrationForm
          onSubmit={onSubmit}
          passwordErrors={apiError && apiError.toLowerCase().includes('password') ? passwordPolicies : undefined}
          defaultValues={{
            email: authStore.userInfo.email,
            phoneNumber: authStore.userInfo.phoneNumber,
          }}
          isSubmitting={isRegistering}
          pageError={apiError}
        >
          {({ errors, handleSubmit, termsChecked }) => (
            <>
              {showLoginButton && (
                <div className={classes.loginWrapper}>
                  <Button color="primary" variant="outlined" onClick={handleSubmit(handleLogin)}>
                    Login
                  </Button>
                </div>
              )}
              <Navigation
                isLoading={isRegistering}
                PrevButtonProps={{
                  onClick: handleGoBack,
                }}
                NextButtonProps={{
                  children: props.nextButtonText,
                  disabled: Object.keys(errors).length > 0 || !termsChecked,
                }}
              />
            </>
          )}
        </RegistrationForm>
      ) : (
        <LoginForm handleOnPreviousStep={handleOnPreviousStep} handleNextStep={handleNextStep} />
      )}
    </Step>
  );
};

import { useQuery } from 'react-query';
import { gql } from 'graphql-request';
import * as Sentry from '@sentry/react';
import { getGraphQLClient, getApiToken } from 'src/lib';
import { AvidPackage } from '../types';

const query = gql`
  query packageById($packageId: String!) {
    packageById(packageId: $packageId) {
      id
      name
      fileId
      status {
        status
        progress
        actions {
          sklosuresResend
          sklosuresCancel
          ds3Send
          ds3Resend
          ds3Cancel
        }
        type
      }
      createdAt
      lastUpdated
      workflowData {
        ... on AvidWorkflowData {
          rooms {
            roomType
            notes
            order
            name
          }
          propertyType
          representationType
          inspectionDateTime
          inspectionWeather
          peoplePresent
        }
      }
    }
  }
`;

export const getAvidPackageKey = 'get-avid-package';

export const useGetAvidPackage = (packageId: string | undefined) =>
  useQuery(
    [getAvidPackageKey, packageId],
    async () => {
      const client = getGraphQLClient(await getApiToken());
      const result = await client.request<{ packageById: AvidPackage }, { packageId: string }>(query, {
        packageId: packageId!,
      });
      return result.packageById;
    },
    {
      enabled: !!packageId,
      onError(e) {
        Sentry.captureException(e);
        console.error(`An error occurred while getting AVID package (packageId=${packageId})`);
      },
    }
  );

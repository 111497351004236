import React, { useEffect, useMemo, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { useHistory } from 'react-router';
import { WizardStepProps, CustomEmailForm } from 'src/domain';
import { Contact, formatAddress, RequestTitleReportPayload, formatPhoneNumber } from 'src/lib';
import { CenteredLoader, useComposeEmail } from 'src/common';
import { selectedAgentAtom } from 'src/state';
import { useGetActiveUserProfile, useGetUserProfile, EmailType, useCustomSnackbar, usePreOpenEscrow } from 'src/hooks';
import { SpqTdsDisclosureSteps } from '..';
import { useDisclosuresStore } from '../../store';
import { useChooseEscrowOfficer } from './EscrowChooseOfficerStep/useChooseEscrowOfficer';

export const EscrowComposeEmailStep = (props: WizardStepProps) => {
  const { goToStep } = props;
  const store = useDisclosuresStore();
  const storeRef = useRef(store);
  const selectedAgent = useRecoilValue(selectedAgentAtom);
  const history = useHistory();
  const { addSuccessSnackbar } = useCustomSnackbar();

  const { data: agentUserProfile, isFetched: hasFetchedAgentProfile } = useGetActiveUserProfile();
  const { data: userProfile, isFetched: hasFetchedUserProfile } = useGetUserProfile();
  const { mutate: saveEscrowDetails, isLoading: isSubmitting } = usePreOpenEscrow();

  // useChooseEscrowOfficer() below will set store.escrowOfficer if we came directly
  // to this page from the file packages page, so this must run before that.
  const didComeFromChooseEscrow = useMemo(() => storeRef.current.escrowOfficer, []);

  // Reuse this hook to set up the store in the same way that the choose escrow
  // officer page would, in case the user has a default escrow officer so they come
  // straight here, skipping the choose escrow officer page
  const { isStoreUpdated } = useChooseEscrowOfficer({ goToStep, doNotFetchEscrowOfficerList: true });

  const isLoading = !hasFetchedUserProfile || !hasFetchedAgentProfile || !isStoreUpdated;
  const emailState = useComposeEmail();

  useEffect(() => {
    if (isLoading) return;

    const sellers = store.sellers;
    const address = formatAddress(store.property);
    const state = store.property?.state;

    const { defaultSubject, defaultBody, defaultSignature } = generateDefaultEmailText({
      address,
      state,
      sellers,
      agent: agentUserProfile,
      agentDreLicenseNumber: agentUserProfile?.licenseNumber,
      escrowOfficer: store.escrowOfficer,
    });

    emailState.setSubject(defaultSubject);
    emailState.setBody(defaultBody);
    emailState.setSignature(defaultSignature);
    emailState.setRecipients(() => {
      const escrowEmail = store.escrowOfficer?.email;
      return escrowEmail ? [escrowEmail, ''] : [''];
    });
    emailState.setCcRecipients(() => {
      const agentEmail = selectedAgent?.email;
      const userEmail = userProfile?.email;

      const arr = [];
      if (agentEmail) arr.push(agentEmail);
      if (userEmail) arr.push(userEmail);
      arr.push('');

      return arr;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const onSubmit = async () => {
    const initialPayload: RequestTitleReportPayload = {
      subject: emailState.subject,
      body: emailState.body.replace(/\r\n|\r|\n/g, '<br />'), // convert line breaks to HTML breaks
      agentSignature: emailState.signature.replace(/\r\n|\r|\n/g, '<br />'), // convert line breaks to HTML breaks
      recipients: emailState.recipients.filter((x) => x) as string[],
      carbonCopies: emailState.ccRecipients.filter((x) => x) as string[],
      emailType: EmailType.REQUEST_TITLE,
      brokerageName: agentUserProfile!.brokerageName || '',
    };

    const payload: RequestTitleReportPayload = Object.assign(initialPayload, {
      sendingOnBehalfOf: selectedAgent !== null,
      fileId: store.existingFileId,
      userDetails: { firstName: userProfile!.firstName, email: userProfile!.email },
      ownerDetails: {
        firstName: agentUserProfile!.firstName,
        lastName: agentUserProfile!.lastName,
        email: agentUserProfile!.email,
      },
      sellerDetails: store.sellers.map((s) => ({
        fullName: `${s.firstName} ${s.lastName}`,
        firstName: s.firstName,
        lastName: s.lastName,
        email: s.email,
      })),
      propertyAddress: store.property?.streetAddress,
    });
    saveEscrowDetails(
      {
        fileId: store.existingFileId,
        property: {
          city: store.property!.city,
          county: store.property!.county,
          postalCode: store.property!.postalCode,
          state: store.property!.state,
          streetAddress: store.property!.streetAddress,
          unitNumber: store.property!.unitNumber,
        },
        escrowOfficer: store.escrowOfficer,
        ...(selectedAgent ? { onBehalfOf: selectedAgent.userId } : {}),
        emailPayload: payload,
      },
      {
        onSuccess: () => {
          goToFilePackagesPage();
          addSuccessSnackbar(`You have successfully opened an order for ${store.property?.streetAddress}`);
        },
      }
    );
  };

  function goToFilePackagesPage() {
    history.push(`/file/${store.existingFileId}/packages`);
  }

  function handleCancel() {
    if (didComeFromChooseEscrow) {
      goToStep(SpqTdsDisclosureSteps.escrowChooseOfficer);
    } else {
      goToFilePackagesPage();
    }
  }

  return isLoading ? (
    <CenteredLoader />
  ) : (
    <CustomEmailForm
      onBack={handleCancel}
      onSend={onSubmit}
      editRecipient={() => goToStep(SpqTdsDisclosureSteps.escrowChooseOfficer)}
      recipientToolTipTitle="Search Contacts"
      title="Prepare your email"
      emailState={emailState}
      NavigationProps={{
        isLoading: isSubmitting,
        PrevButtonProps: {
          disabled: isSubmitting,
          children: didComeFromChooseEscrow ? 'Back' : 'Cancel',
        },
        NextButtonProps: {
          children: 'Open Order',
          endIcon: null,
          style: { textTransform: 'none', padding: '12px 24px' },
        },
      }}
    />
  );
};

function s(str?: string | null) {
  return str || '';
}

function generateLicenseNumberLine(licenseNumber: string | undefined) {
  return licenseNumber ? `License #${licenseNumber}` : '';
}

function generateDefaultEmailText(args: {
  address: string | undefined;
  state: string | undefined;
  escrowOfficer: Contact | undefined;
  sellers: Contact[];
  agent:
    | { firstName?: string; lastName?: string; primaryPhoneNumber?: string; email?: string; brokerageName?: string }
    | undefined;
  agentDreLicenseNumber: string | undefined;
}) {
  const { address, state, escrowOfficer, sellers, agent, agentDreLicenseNumber } = args;
  const defaultSignature = generateAgentSignature({ state, agent, agentDreLicenseNumber });
  const defaultSellerInfo = generateSellerInfo(sellers);
  const defaultSellerAgentInfo = generateSellerAgentInfo(agent);
  const defaultBody = `Hi ${s(escrowOfficer?.firstName)},

Please open an order for my listing at ${s(address)}.

${defaultSellerInfo}

${defaultSellerAgentInfo}
`;

  return {
    defaultSubject: `${s(address)} - Open Order`, // Subject must be in sync with escrow-service
    defaultBody,
    defaultSignature,
  };
}

function generateAgentSignature(args: {
  state: string | undefined;
  agent:
    | { firstName?: string; lastName?: string; primaryPhoneNumber?: string; email?: string; brokerageName?: string }
    | undefined;
  agentDreLicenseNumber: string | undefined;
}) {
  const { agent, agentDreLicenseNumber } = args;
  const phoneNumber = agent?.primaryPhoneNumber ? `| ${formatPhoneNumber(agent?.primaryPhoneNumber)}` : '';
  const brokerageName = agent?.brokerageName ?? '';
  const agentLicenseNumber = generateLicenseNumberLine(agentDreLicenseNumber);
  const salutation = agentDreLicenseNumber
    ? `${agentLicenseNumber}
${s(agent?.email)} ${phoneNumber}
${brokerageName}`
    : `${s(agent?.email)} ${phoneNumber}
${brokerageName}`;

  const result = `${s(agent?.firstName)} ${s(agent?.lastName)}
${salutation}
`;

  return result;
}

function generateSellerInfo(sellers: Contact[]) {
  const sellerInfoHeading = "My Seller's Information";
  const sellerDetails = `${sellers[0].firstName} ${sellers[0].lastName}
${sellers[0].email}`;

  const secondSellerDetails =
    sellers.length > 1
      ? `${sellers[1].firstName} ${sellers[1].lastName}
${sellers[1].email}`
      : '';

  const result = secondSellerDetails
    ? `${sellerInfoHeading}
${sellerDetails}

${secondSellerDetails}`
    : `${sellerInfoHeading}
${sellerDetails}`;

  return result;
}

function generateSellerAgentInfo(
  agent:
    | { firstName?: string; lastName?: string; primaryPhoneNumber?: string; email?: string; brokerageName?: string }
    | undefined
) {
  const sellerAgentHeading = "Seller's Agent Information";
  const agentInformation = `${agent?.firstName} ${agent?.lastName}
${agent?.brokerageName}
${agent?.email}`;
  return `${sellerAgentHeading}
${agentInformation}`;
}

import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { LoaderButton, LoaderButtonProps } from 'src/common';

interface ActionButtonProps extends Omit<LoaderButtonProps, 'children'> {
  text: string;
}

export const ActionButton = (props: ActionButtonProps) => {
  const { text, isLoading, ...rest } = props;

  const buttonProps: ButtonProps = {
    ...rest,
    size: 'small',
    color: 'primary',
    children: text,
  };

  return isLoading === undefined ? (
    <Button {...buttonProps} />
  ) : (
    <LoaderButton {...buttonProps} isLoading={isLoading} />
  );
};

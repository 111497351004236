import React, { FocusEvent, ChangeEvent, ReactNode } from 'react';
import clsx from 'clsx';
import {
  Typography,
  makeStyles,
  Theme,
  TextField,
  BaseTextFieldProps,
  InputProps,
  InputBaseComponentProps,
} from '@material-ui/core';
import { FieldError } from 'react-hook-form';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    error: {
      marginTop: 4,
      fontSize: 16,
      fontWeight: 700,
      color: theme.colors.error,
    },
    singleField: {
      minWidth: 400,
      [theme.breakpoints.down('xs')]: {
        minWidth: '100%',
      },
    },
  }),
  { classNamePrefix: 'TextInput' }
);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export interface TextInputProps extends BaseTextFieldProps {
  error?: string | FieldError;
  singleField?: boolean;
  variant?: 'outlined' | 'standard' | 'filled';
  label?: ReactNode;
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (event: FocusEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  InputProps?: InputProps;
  inputProps?: InputBaseComponentProps;
  maxLength?: number;
  value?: string;
}

// TODO: Can we replace this with default props set in MUI theme and using TextFieldProps.helperText for error messages?
// eslint-disable-next-line prefer-arrow-callback
export const TextInput = React.forwardRef(function TextInput(props: TextInputProps, ref: React.Ref<HTMLDivElement>) {
  const classes = useStyles();
  const {
    error,
    singleField,
    variant,
    classes: userClasses = {},
    className,
    inputProps = {},
    maxLength,
    helperText,
    value,
    ...textFieldProps
  } = props;

  const errorMsg = typeof error === 'string' ? error : error?.message;

  return (
    <div ref={ref} className={clsx(className, userClasses.root, classes.root, { [classes.singleField]: singleField })}>
      <TextField
        error={!!errorMsg}
        autoComplete="off"
        variant={variant ?? 'outlined'}
        inputProps={{ ...inputProps, maxLength }}
        value={value}
        helperText={
          helperText ||
          (maxLength && typeof value === 'string' ? `${value?.length ?? 0}/${maxLength} characters` : undefined)
        }
        {...textFieldProps}
      />
      {errorMsg && (
        <Typography variant="body1" className={classes.error}>
          {errorMsg}
        </Typography>
      )}
    </div>
  );
});

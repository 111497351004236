import { makeStyles, Theme } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  buttonGroup: {
    justifyContent: 'center',
    borderRadius: '8px',
    margin: '0 auto',
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      padding: '8px 16px',
      fontSize: 16,
    },
    borderRadius: '8px',
    padding: '12px 36px',
    color: `${theme.colors.grey.bodyHeader} !important`,
    borderColor: theme.colors.grey.grey1,
    borderWidth: 2,
    fontSize: 18,
    fontWeight: 'bold',
  },
  buttonSelected: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: 'white !important',
    borderWidth: 0,
  },
}));

type Props<T> = {
  value: T;
  onChange?: (val: T) => void;
  options: Array<{ value: T; label: string }>;
};

export const Toggle = <T extends { toString: () => string }>(props: Props<T>) => {
  const classes = useStyles();
  return (
    <ToggleButtonGroup
      exclusive
      className={classes.buttonGroup}
      onChange={(_, val) => val !== null && props.onChange?.(val)}
      value={props.value}
    >
      {props.options.map((o) => (
        <ToggleButton
          key={o.value.toString()}
          classes={{ root: classes.button, selected: classes.buttonSelected }}
          value={o.value}
        >
          {o.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

import { useMemo } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import TrieSearch from 'trie-search';

export type UseFilterArgs<T> = {
  filterText: string;
  searchKeys: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: T[];
};

/**
 * Filter certain keys of an array of objects based on
 * filter text.  Note: search keys are not allowed to change
 * from one filter to the next.
 */
export const useFilter = <T>(args: UseFilterArgs<T>): T[] => {
  const { filterText, searchKeys, data } = args;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memodSearchKeys = useMemo(() => searchKeys, []);

  const trie = useMemo(() => {
    const trie = new TrieSearch(memodSearchKeys);
    trie.addAll(data);
    return trie;
  }, [data, memodSearchKeys]);

  const filteredData = useMemo(
    () => (filterText ? trie.search(filterText.split(' ')) : data),
    [trie, filterText, data]
  );

  return filteredData;
};

import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Grid, makeStyles } from '@material-ui/core';
import { Step } from './Step';

const useStyles = makeStyles(() => ({
  titleSkellie: {
    maxWidth: 400,
    margin: 'auto',
  },
}));

export const StepSkeletonGrid: React.FC = ({ children }) => {
  const classes = useStyles();
  return (
    <Step title={<Skeleton className={classes.titleSkellie} />}>
      <Grid container spacing={2}>
        {children}
      </Grid>
    </Step>
  );
};

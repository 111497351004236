import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { config } from '../constants';
import { postUserProfiles } from '../api/user';
import { heapUserDataInit } from '../heapInit';
import { AuthStore } from './store';
import { userManager } from './userManager';

export interface OktaPasswordPolicy {
  description: string;
  minLength?: number;
}

export const authClient = userManager._oktaAuth.oktaAuth;

export const buildAuthStoreFromAuthState = async (): Promise<AuthStore> => {
  const userContext = await userManager.getUser();
  const formsUserCheckComplete: boolean = !!sessionStorage.getItem('formsUserCheckComplete') ?? false;

  if (userContext) {
    heapUserDataInit(
      {
        userId: userContext.id,
        Email: userContext.email,
        SubscriberId: userContext.prime?.subscriberId,
        UserType: userContext.prime?.contactType,
      },
      userContext.oktaGroups?.includes('/forms/users/standalone') ?? false
    );
  }

  return {
    loggedIn: !!userContext,
    userId: userContext?.id ?? null,
    accountAction: null,
    shownPopup: false,
    userInfo: {
      firstName: userContext?.firstName ?? '',
      lastName: userContext?.lastName ?? '',
      email: userContext?.email ?? '',
    },
    formsUserCheckComplete: formsUserCheckComplete,
  };
};

export const login = async (username: string, password: string) => {
  localStorage.removeItem('com.skyslope.logout');
  const transaction = await authClient.signInWithCredentials({ username, password });
  if (transaction.status !== 'SUCCESS') {
    throw new Error('Invalid Login');
  }
  const res = await authClient.token.getWithoutPrompt({ sessionToken: transaction.sessionToken });
  authClient.tokenManager.setTokens(res.tokens);
  const userContext = await userManager.getUser();
  if (userContext) {
    heapUserDataInit(
      {
        userId: userContext.id,
        Email: userContext.email,
        SubscriberId: userContext.prime?.subscriberId,
        UserType: userContext.prime?.contactType,
      },
      userContext.oktaGroups?.includes('/forms/users/standalone') ?? false
    );
  }
  return userContext;
};

export const getRegistrationForm = async () => {
  const formInfo = await axios({ method: 'GET', url: `${config.auth.okta.baseUrl}/api/v1/registration/form` });
  const passwordPolicies = formInfo.data.profileSchema.properties.password.allOf
    .map((pp: OktaPasswordPolicy) => pp.description)
    .filter(Boolean);
  return { policyId: formInfo.data.policyId, passwordPolicies };
};

export interface OktaPayload {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phoneNumber: string;
}

export const register = async (policyId: string, oktaPayload: OktaPayload) => {
  // deconstruct phoneNumber out because the okta payload doesn't expect it.
  const { phoneNumber, ...data } = oktaPayload;
  await axios({
    method: 'POST',
    url: `${config.auth.okta.baseUrl}/api/v1/registration/${policyId}/register`,
    data: { userProfile: data },
  }).catch((e) => {
    throw new Error(e.response?.data?.errorCauses?.[0]?.errorSummary || 'Registration failed, please try again.');
  });

  const loginUser = await login(oktaPayload.email, oktaPayload.password);

  // Give Okta some time to update the tokens before trying to call to Forms to create a profile
  setTimeout(async () => {
    await postUserProfiles(oktaPayload.firstName, oktaPayload.lastName, oktaPayload.email, phoneNumber);
  }, 0);
  return loginUser;
};

export const loginWithRedirect = async (originalUri?: string, loginHint?: string) =>
  userManager.login({
    idp: userManager.idps.primeAuth,
    loginHint: loginHint,
    originalUri: originalUri,
  });

export const getApiToken = async (force = false): Promise<string> =>
  userManager.getAccessToken(undefined, undefined, force);

const getCognitoToken = async () => {
  const token = await getApiToken();
  const result = await axios.get(`${config.breezeServiceUrl}/auth/digisign/cognito`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return result.data;
};

export const getDS3ClientToken = async (data: { firstName: string; lastName: string; email: string }) => {
  const token = await getCognitoToken();
  const result = await axios({
    url: `${config.ds3UserServiceUrl}/client`,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
  return result.data.access_token;
};

/**
 * Handles ds3 tokens. First gets the ds3 token from storage. If the token does not have the
 * required user_id field for ds3, call their user service to provision a user, then refetch the token
 * from the server.
 * @returns the up to date ds3 token
 */
export const handleDs3Token = async () => {
  const token = await getApiToken(false);
  const decoded = jwt_decode(token) as unknown as any;
  if (!decoded.user_id) {
    await getDS3ClientToken({
      firstName: decoded.first_name,
      lastName: decoded.last_name,
      email: decoded.sub,
    });
    return await getApiToken(true);
  }
  return token;
};

import React from 'react';
import { Step, Navigation } from 'src/common';
import { loginWithRedirect, useAuthStore } from 'src/lib';

export interface LoginSkyslopeProps {
  originalUrl: string;
  onBack: () => void;
}

export const LoginSkySlope = (props: LoginSkyslopeProps) => {
  const authStore = useAuthStore();
  return (
    <Step title="We recognize that email. Sign in with your existing or SkySlope account.">
      <Navigation
        NextButtonProps={{
          children: 'Login',
          onClick: () => loginWithRedirect(props.originalUrl, authStore.userInfo.email),
          endIcon: null,
        }}
        PrevButtonProps={{
          onClick: props.onBack,
          startIcon: null,
        }}
      />
    </Step>
  );
};

import React from 'react';
import { RecoverPassword, WizardStepProps } from 'src/domain';
import { getBaseAddress } from 'src/lib/utils';
import { SpqTdsBaseRoute, SpqTdsDisclosureSteps } from './constants';

const baseAddress = getBaseAddress();
const relayUrl = `${baseAddress}/${SpqTdsBaseRoute}/${SpqTdsDisclosureSteps.email}`;

export const SpqTdsRecoverPassword = (props: WizardStepProps) => (
  <RecoverPassword
    relayUrl={relayUrl}
    onSuccess={() => props.goToStep(SpqTdsDisclosureSteps.recoverPasswordSuccess)}
    onBack={() => props.goToStep(SpqTdsDisclosureSteps.login)}
  />
);

export enum AvidSteps {
  email = 'email',
  login = 'login',
  register = 'register',
  recoverPassword = 'recover-password',
  recoverPasswordSuccess = 'recover-password-success',
  loginSkyslope = 'login-skyslope',
  selectOnBehalfOfAgent = 'on-behalf-of',
  addTeamMember = 'add-team-member',
  property = 'property',
  missingPropertyInfo = 'missing-property-info',
  editPropertyInfo = 'edit-property-info',
  propertyType = 'property-type',
  representationType = 'representation-type',
  contacts = 'contacts',
  rooms = 'rooms',
  chooseRooms = 'choose-rooms',
  inspectionDateTime = 'inspection-date-time',
  weather = 'weather',
  peoplePresent = 'people-present',
  car = 'car',
  licenseNumber = 'license-number',
  brokerageName = 'broker-name',
  review = 'review',
  editFileTeamMembers = 'team-for-file',
  addContactTeamMember = 'add-contact',
  teamMembersDeprecated = 'team-members',
}

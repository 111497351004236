import { useEffect, useState } from 'react';
import { useAuth } from '@skyslope/auth-react';
import { formatChameleonUserRole } from 'src/lib';
import { useGetBreezeRole, useGetPackageCountByStatus, useIsLoginProcessComplete } from 'src/hooks/domain/queries';
import { chameleon } from '../../lib/chameleon/chameleon';
import { config } from '../../lib/constants';

export const useSetupChameleon = (): void => {
  const [isChameleonInitialized, setIsChameleonInitialized] = useState(false);
  const isLoggedIn = useIsLoginProcessComplete();
  const { userContext } = useAuth();
  const { data: breezeRole } = useGetBreezeRole({ enabled: isLoggedIn });
  const { data: packageData } = useGetPackageCountByStatus({ enabled: isLoggedIn });
  const isBreezeUser = breezeRole ? 1 : 0;

  useEffect(() => {
    chameleon.initialize(config.chameleonToken);
    setIsChameleonInitialized(true);
  }, []);

  useEffect(() => {
    if (userContext && breezeRole && isChameleonInitialized) {
      chameleon.setupUser(
        userContext.id,
        userContext.email,
        formatChameleonUserRole(breezeRole),
        userContext.firstName,
        userContext.lastName,
        userContext.prime?.contactType,
        userContext.prime?.subscriberId,
        packageData?.disclosuresSentCount,
        packageData?.disclosuresCompletedCount,
        packageData?.avidCount,
        packageData?.avidCompletedCount,
        isBreezeUser
      );
    }
  }, [isChameleonInitialized, userContext, breezeRole, packageData, isBreezeUser]);
};

/* Chameleon script */

import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { WizardStepProps, EmailForm, EmailInputs } from 'src/domain';
import { useGetFileById, useSendForms } from 'src/hooks';
import { Contact } from 'src/lib/api/files';
import { CenteredLoader, useComposeEmail } from 'src/common';
import { contactTypes } from 'src/lib';
import { useGetAvidPackage } from '../../Avid/hooks/useGetAvidPackage';
import { SendFormsSteps } from '../types';

export const SendFormsEmail = ({ goToStep }: WizardStepProps) => {
  const history = useHistory();
  const { packageId } = useParams<{ packageId: string }>();
  const { data: breeze, isLoading: isPackageLoading } = useGetAvidPackage(packageId);
  const { data: file, isLoading: isFileLoading } = useGetFileById(breeze?.fileId);
  const { mutate: sendForms } = useSendForms(breeze?.fileId ?? -1);
  const onSubmit = useCallback(
    async (data: EmailInputs) => {
      sendForms({
        packageId,
        subject: data.subject,
        body: data.body,
      });

      goToStep(SendFormsSteps.sending);
    },
    [packageId, sendForms, goToStep]
  );

  const isLoading = isFileLoading || isPackageLoading;
  const recipients =
    file?.contacts.filter(
      (c) =>
        c.actionAttribute === 'NeedsToSign' &&
        ![contactTypes.BUYERAGENT, contactTypes.SELLERAGENT].includes(c.type! as contactTypes)
    ) ?? [];

  const emailState = useComposeEmail();

  useEffect(() => {
    if (isLoading) return;

    const { defaultSubject, defaultBody } = generateDefaultEmailText(
      recipients,
      file?.fileData.premisesAddress.streetAddress
    );

    emailState.setSubject(defaultSubject);
    emailState.setBody(defaultBody);
    emailState.setRecipients(recipients.map((r) => r.email));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return isLoading ? (
    <CenteredLoader />
  ) : (
    <EmailForm
      onBack={() => history.push(`/file/${file?.id}/packages`)}
      onSend={onSubmit}
      title="What would you like to say in your request for signatures?"
      emailState={emailState}
      hideCc
      disableEditRecipients
    />
  );
};

function generateDefaultEmailText(recipients: Contact[], address: string | undefined) {
  const salutation = buildSalutation(recipients);
  const defaultBody = `${salutation}

Please review and sign the Agent Visual Inspection Disclosure (AVID) for ${address}.

Be sure to review and confirm that all observations of your property are accurately captured.

Feel free to reach out if you have any questions. I'm always here to help.  
`;

  return {
    defaultSubject: `Request for signature on the Agent Visual Inspection Disclosure (AVID).`,
    defaultBody,
  };
}

function buildSalutation(recipients: Contact[]) {
  if (recipients.length === 0) return '';
  if (recipients.length === 1) return `Hi ${recipients[0].firstName},`;
  if (recipients.length === 2) return `Hi ${recipients.map((r) => r.firstName).join(' and ')},`;

  const allButLast = recipients.slice(0, recipients.length - 1);
  return `Hi ${allButLast.map((r) => r.firstName).join(', ')} and ${recipients[recipients.length - 1].firstName},`;
}

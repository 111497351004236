import { useQuery, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import { gql } from 'graphql-request';
import { useRecoilValue } from 'recoil';
import { selectedAgentAtom } from 'src/state';
import { getGraphQLClient, getApiToken } from '../../../lib';
import { DashboardFilePackage } from '../../../pages/Dashboard/types';

interface QueryResult {
  dashboardPackages: DashboardFilePackage[];
}

interface QueryVariables {
  fileId?: number;
  onBehalfOf?: string;
}

export const getDashboardPackagesByFileIdKey = 'get-dashboard-packages-by-file-id';

const query = gql`
  query dashboardPackagesForFile($fileId: Int, $onBehalfOf: String) {
    dashboardPackages(fileId: $fileId, onBehalfOf: $onBehalfOf) {
      id
      name
      fileId
      type
      forms {
        name
        interviewId
        ds3DocumentId
      }
      flagCount
    }
  }
`;

export const useGetDashboardPackages = <T = DashboardFilePackage[]>(
  fileId: number | undefined = undefined,
  options: UseQueryOptions<DashboardFilePackage[], AxiosError, T> = {}
) => {
  const selectedAgent = useRecoilValue(selectedAgentAtom);
  const onBehalfOf = selectedAgent?.userId;

  return useQuery<DashboardFilePackage[], AxiosError, T>(
    [getDashboardPackagesByFileIdKey, fileId],
    async () => {
      const client = getGraphQLClient(await getApiToken());
      const result = await client.request<QueryResult, QueryVariables>(query, { fileId, onBehalfOf });
      return result?.dashboardPackages || [];
    },
    options
  );
};

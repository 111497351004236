import React, { useEffect, useContext } from 'react';

type TayTipContextShape = {
  setTayTip(tayTip: React.ReactNode): void;
};

export const TayTipContext = React.createContext<TayTipContextShape>({
  setTayTip: () => {},
});

/**
 * Show a "Tay Tip" inside the Wizard.  A Tay (Taylor) tip is a blurb of
 * text that is displayed to the user on a particular wizard step to help
 * explain what they're doing or why they're doing it.
 *
 * **Note**: THIS HOOK WILL ONLY WORK FOR CHILDREN OF THE `<Wizard>` COMPONENT.
 *
 * @param tayTip A React Node (usually a string) or a function that
 * returns a React Node.
 * @param dependencies An array of dependencies, just like what you would
 * pass to `useEffect()`.
 */
export const useTayTip = (tayTip: React.ReactNode | (() => React.ReactNode), dependencies?: unknown[]) => {
  const context = useContext(TayTipContext);
  const tipText = typeof tayTip === 'function' ? tayTip() : tayTip;

  useEffect(() => {
    context.setTayTip?.(tipText || null);

    // Do not clear on unmount, `goToStep()` function will clear any
    // tips left over from the previous step

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipText, ...(dependencies || [])]);
};

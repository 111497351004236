import React from 'react';
import clsx from 'clsx';
import { Theme, makeStyles, Typography, Menu, MenuItem, IconButton } from '@material-ui/core';
import { Mail, MoreVertSharp, Phone, Send as SendIcon, MoreHoriz as MoreHorizIcon } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { ReactComponent as EscrowIllustration } from 'src/images/escrow/escrow-blue-circle-illustration.svg';
import { EscrowOfficer } from 'src/lib';
import { InitialsCircle, RevealButton } from 'src/common';
import { useAnchorEl, useIsMobile } from 'src/hooks';
import { ActionButton } from './FilePackageItemActions/ActionButton';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      minHeight: theme.spacing(15),
      border: `1px solid ${theme.colors.grey.disable}`,
      '--border-radius': `${theme.spacing(1)}px`,
      borderRadius: 'var(--border-radius)',
    },
    image: {
      flexShrink: 0,
      [theme.breakpoints.down('xs')]: {
        width: 72,
        height: 72,
      },
    },
    content: {
      padding: theme.spacing(3, 4),
      display: 'flex',
      gap: theme.spacing(2),
      borderRadius: 'calc(var(--border-radius) - 1px)',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        padding: theme.spacing(3),
        gap: theme.spacing(1),
      },
    },
    offWhite: {
      backgroundColor: theme.colors.grey.offWhite1,
    },
    title: {
      fontSize: '1.5rem',
      fontWeight: 400,
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.1875rem',
        fontWeight: 600,
        lineHeight: '1.5rem',
      },
    },
    text: {
      maxWidth: '75ch',
    },
    subtitle: {
      fontSize: '1rem',
      margin: theme.spacing(1, 0, 1, 0),
    },
    flexInherit: {
      display: 'flex',
      gap: 'inherit',
    },
    contentLeft: {
      gap: theme.spacing(4),
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        gap: theme.spacing(2),
      },
    },
    contentRight: {
      marginLeft: 'auto',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginLeft: 0,
      },
    },
    action: {
      padding: theme.spacing(3, 4),
      borderTop: 'inherit',
    },
    actionButton: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: '48px',
      },
    },
    mobileContextMenu: {
      marginLeft: 'auto',
      '& button': { padding: '0', margin: '-8px -8px 0 0' },
    },
    mobileOnly: {
      flexDirection: 'column',
    },
  }),
  { classNamePrefix: 'OpenEscrowCard' }
);

export interface OpenEscrowCardProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  escrowOfficer?: EscrowOfficer;
  onOpenEscrow?(): void;
  onChangeEscrowOfficer?(): void;
  onClearDefaultEscrowOfficer(): void;
  isLoading: boolean;
}

/**
 * A card on the file packages page prompting the user to pre-open escrow.  It has two
 * states, a generic one, and one with the information of a particular escrow officer
 * when the user has previously pre-opened escrow with that officer.
 */
export const OpenEscrowCard: React.FC<OpenEscrowCardProps> = (props) => {
  const classes = useStyles();
  const {
    className,
    escrowOfficer,
    onOpenEscrow,
    onChangeEscrowOfficer,
    onClearDefaultEscrowOfficer,
    isLoading,
    ...rest
  } = props;

  const { anchorEl, isOpen, close, handleClick } = useAnchorEl();
  const isMobile = useIsMobile();

  const image = escrowOfficer ? (
    <InitialsCircle
      size={isMobile ? 'medium' : 'large'}
      className={classes.image}
      firstName={escrowOfficer.firstName}
      lastName={escrowOfficer.lastName}
      image={escrowOfficer.image}
    />
  ) : (
    <EscrowIllustration viewBox="0 0 80 80" className={classes.image} />
  );

  const title = (
    <Typography variant="body2" className={clsx(classes.title, classes.text)}>
      {escrowOfficer ? `Open Order with ${escrowOfficer.fullName}` : 'Get escrow or title started now'}
    </Typography>
  );

  const subtitle = (
    <Typography variant="subtitle2" className={clsx(classes.subtitle, classes.text)}>
      {escrowOfficer
        ? escrowOfficer.brand
        : 'Request a preliminary title report or escrow number with your preferred escrow or title company.'}
    </Typography>
  );

  const LoadingState = () => (
    <div>
      <Typography variant="body2" className={classes.title}>
        <Skeleton width={300} />
      </Typography>
      <Typography variant="subtitle2" className={classes.subtitle}>
        <Skeleton width={250} />
      </Typography>
    </div>
  );

  return (
    <div {...rest} className={clsx(className, classes.root)}>
      <div className={clsx(classes.content, { [classes.offWhite]: !escrowOfficer })}>
        <div className={clsx(classes.flexInherit, classes.contentLeft)}>
          {isLoading ? (
            <LoadingState />
          ) : (
            <>
              {isMobile ? (
                <div className={clsx(classes.mobileOnly, classes.flexInherit)}>
                  <div className={classes.flexInherit}>
                    {image}
                    <div>
                      {title}
                      {subtitle}
                    </div>
                    {escrowOfficer && (
                      <div className={classes.mobileContextMenu}>
                        <IconButton
                          aria-label="Change default officer"
                          aria-haspopup="true"
                          aria-controls="context-menu"
                          onClick={handleClick}
                        >
                          <MoreHorizIcon />
                        </IconButton>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className={classes.flexInherit}>
                  {image}
                  <div>
                    {title}
                    {subtitle}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className={clsx(classes.flexInherit, classes.contentRight)}>
          {escrowOfficer?.email && (
            <RevealButton
              icon={<Mail />}
              href={`mailto:${escrowOfficer.email}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {escrowOfficer?.email}
            </RevealButton>
          )}
          {escrowOfficer?.phoneNumber && (
            <RevealButton icon={<Phone />} href={`tel:${escrowOfficer.phoneNumber}`}>
              {escrowOfficer?.phoneNumber}
            </RevealButton>
          )}
          {escrowOfficer && !isMobile && <RevealButton icon={<MoreVertSharp />} onClick={handleClick} />}
          <Menu open={isOpen} onClose={close} anchorEl={anchorEl} onClick={close} id="context-menu">
            <MenuItem onClick={onClearDefaultEscrowOfficer}>Remove default</MenuItem>
            <MenuItem onClick={onChangeEscrowOfficer}>Change</MenuItem>
          </Menu>
        </div>
      </div>
      <div className={classes.action}>
        <ActionButton
          isLoading={isLoading}
          startIcon={<SendIcon />}
          variant="contained"
          text="Open Order"
          onClick={onOpenEscrow}
          style={{ textTransform: 'none' }}
          className={classes.actionButton}
        />
      </div>
    </div>
  );
};

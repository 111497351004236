import React from 'react';
import { AccountActionNextRoute } from 'src/lib/api/account';
import { WizardStepProps, Email } from 'src/domain';
import { SpqTdsDisclosureSteps } from './constants';

export const SpqTdsEmail = ({ goToStep }: WizardStepProps) => {
  const nextRoutes: AccountActionNextRoute = {
    register: SpqTdsDisclosureSteps.property,
    none: 'login', // TODO
    legacy: 'login-skyslope', // TODO
  };

  return (
    <Email
      onSuccess={(action) => {
        goToStep(nextRoutes[action]);
      }}
      title={"We're excited to have you! Enter your email to get started."}
    />
  );
};

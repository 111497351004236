import React from 'react';
import { useParams } from 'react-router-dom';
import { useForm, useWatch } from 'react-hook-form';
import Grid from '@material-ui/core/Grid';
import { WizardStepProps } from 'src/domain';
import { CenteredLoader } from 'src/common/CenteredLoader';
import { Navigation, Step, TextInput } from 'src/common';
import { useGetAvidPackage, useUpdateAvidPackage } from '../../hooks';
import { AvidSteps } from '../../AvidCreation/steps';
import { useAvidUserProfileBoundary } from '../useAvidUserProfileBoundary';

interface PeoplePresentInputs {
  people: string;
}

export const AvidPeoplePresent = ({ goToStep }: WizardStepProps) => {
  const { isLoading: isBoundaryLoading, boundedAction } = useAvidUserProfileBoundary({
    goToStep,
    targetAction: () => goToStep(AvidSteps.review),
  });
  const { packageId } = useParams<{ packageId: string }>();
  const { data: avidPackage } = useGetAvidPackage(packageId);
  const { mutate: updateAvid, isLoading: isUpdating } = useUpdateAvidPackage();
  const { register, handleSubmit, control } = useForm<PeoplePresentInputs>({
    mode: 'onTouched',
  });

  const charCount =
    useWatch<string>({ control, name: 'people' })?.length ?? avidPackage?.workflowData.peoplePresent.length;

  const onSubmit = handleSubmit((data: PeoplePresentInputs) => {
    updateAvid(
      {
        packageId,
        peoplePresent: data.people.replace( /[\r\n]+/gm, ", "),
      },
      {
        onSuccess: boundedAction,
      }
    );
  });

  if (!avidPackage) {
    return <CenteredLoader />;
  }

  const maxLength = 140;
  return (
    <Step title="Was anybody else present during the inspection?">
      <form onSubmit={onSubmit}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={6}>
            <TextInput
              placeholder="Example: Seller, Buyer, Co-Agent, Inspector..."
              autoFocus
              variant="filled"
              InputProps={{ disableUnderline: true }}
              multiline
              rows={4}
              inputRef={register}
              name="people"
              inputProps={{ maxLength }}
              defaultValue={avidPackage.workflowData.peoplePresent}
              helperText={`${charCount ?? 0}/${maxLength} characters`}
              maxLength={maxLength}
            />
          </Grid>
        </Grid>
      </form>
      <Navigation
        isLoading={isUpdating}
        PrevButtonProps={{
          onClick: () => goToStep(AvidSteps.weather),
        }}
        NextButtonProps={{
          onClick: onSubmit,
          disabled: isBoundaryLoading || !avidPackage,
        }}
      />
    </Step>
  );
};

import React, { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { AgentProfile, selectedAgentAtom } from 'src/state';
import { useGetUserProfile, useTeamMembers } from 'src/hooks';
import { Contact, getUniqueListBy, TeamMember } from 'src/lib';
import { Navigation, Step } from 'src/common';
import { WizardStepProps } from '../../Wizard';
import { TeamMemberFilterList } from '../SelectOnBehalfOfAgent/TeamMemberFilterList';

export interface EditFileTeamMembersStepProps extends WizardStepProps {
  title: string;
  onNextClick(selectedTeamMembers: Array<AgentProfile>): void;
  previousStepName: string;
  onAddTeamMember(): void;
  contactsOnFile: Contact[];
  isSaving?: boolean;
}

/**
 * Step where you can choose from existing members of your team to
 * add as a CC on all file communications.
 */
export const EditFileTeamMembersStep: React.FC<EditFileTeamMembersStepProps> = (props) => {
  const { title, goToStep, previousStepName, onNextClick, onAddTeamMember, contactsOnFile, isSaving } = props;

  const contactTeamMembers: TeamMember[] = useMemo(
    () => contactsOnFile.map((c) => ({ ...c, userId: String(c.id), isApproved: true })),
    [contactsOnFile]
  );

  const { data: teamMembers, isLoading: isLoadingTeam } = useTeamMembers({ includePending: true });
  const { data: userProfile, isLoading: isLoadingUser } = useGetUserProfile();

  const selectedAgent = useRecoilValue(selectedAgentAtom);
  const isActingOnBehalfOfSomeone = selectedAgent;

  const allTeamMembers = useMemo(() => {
    const withDuplicates = [...contactTeamMembers, ...(teamMembers || [])];
    const unique = getUniqueListBy(withDuplicates, 'email');

    // If the user is acting on behalf of someone, they shouldn't be able to
    // remove that person from their team
    const noAgent = isActingOnBehalfOfSomeone ? unique.filter((c) => c.email !== selectedAgent!.email) : unique;
    return noAgent.filter((c) => c.email !== userProfile?.email);
  }, [contactTeamMembers, teamMembers, isActingOnBehalfOfSomeone, selectedAgent, userProfile]);

  const [selectedTeamMembers, setSelectedTeamMembers] = useState<TeamMember[]>(() =>
    allTeamMembers.filter((tm) => contactTeamMembers.find((c) => c.email === tm.email))
  );

  const hasUserMadeAChange = useMemo(() => {
    const originals = contactTeamMembers.map((tm) => tm.email);
    const currents = selectedTeamMembers.map((tm) => tm.email);

    const removed = originals.filter((x) => !currents.includes(x));
    const added = currents.filter((x) => !originals.includes(x));
    return !!(removed.length + added.length);
  }, [contactTeamMembers, selectedTeamMembers]);

  const previous = () => {
    goToStep(previousStepName);
  };

  return (
    <Step title={title}>
      <TeamMemberFilterList
        selectMultipleTeamMembers
        onAddTeamMember={onAddTeamMember}
        teamMembers={allTeamMembers}
        isLoading={isLoadingTeam || isLoadingUser}
        selectedTeamMembers={selectedTeamMembers}
        setSelectedTeamMembers={setSelectedTeamMembers}
        disablePending={false}
        pendingTooltip="Pending team members can still be added to your package as contacts."
      />
      <Navigation
        isLoading={isSaving}
        PrevButtonProps={{ onClick: previous, hidden: !previousStepName }}
        NextButtonProps={{
          children: 'Save',
          endIcon: null,
          disabled: !hasUserMadeAChange,
          onClick() {
            onNextClick(selectedTeamMembers.map((x) => x) as never);
          },
        }}
      />
    </Step>
  );
};

import { gql } from 'graphql-request';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { formatISO } from 'date-fns';
import * as Sentry from '@sentry/react';
import { useCustomSnackbar, useGetUserPermissionsRequestsKey, UserPermissionRequestData } from '..';
import {
  DelegationRequestOrGrantResponse,
  ErrorCodes,
  getGraphQLClient,
  getApiToken,
  UserDelegateWithDelegateId,
} from '../../lib';
import { useDelegationRequestOrAssignSnackbars } from './useDelegationRequestOrAssignSnackbars';

export interface AssignFullAccessDelegatesPayload {
  delegateEmails: string[];
}

const mutationQuery = gql`
  mutation assignFullAccessDelegates($delegateEmails: [String]!) {
    assignFullAccessDelegates(delegateEmails: $delegateEmails) {
      existingUserEmailsHandled
      newUserEmailsHandled
    }
  }
`;

interface MutationContext {
  previousInvites: UserPermissionRequestData;
}

export const assignFullAccessDelegatesKey = 'assign-full-access-delegates-mutation';

const createSuccessMessage = (email: string) => `Access shared with ${email}`;
const createInfoMessage = (email: string) => `Invite sent to ${email}`;

export const useAssignFullAccess = (
  options: UseMutationOptions<DelegationRequestOrGrantResponse, AxiosError, AssignFullAccessDelegatesPayload> = {}
) => {
  const queryClient = useQueryClient();
  const snackbarSuccess = useDelegationRequestOrAssignSnackbars(createSuccessMessage, createInfoMessage);
  const snackbarController = useCustomSnackbar();

  return useMutation<DelegationRequestOrGrantResponse, AxiosError, AssignFullAccessDelegatesPayload>(
    async (payload: AssignFullAccessDelegatesPayload) => {
      const client = getGraphQLClient(await getApiToken());
      return (
        await client.request<
          { assignFullAccessDelegates: DelegationRequestOrGrantResponse },
          AssignFullAccessDelegatesPayload
        >(mutationQuery, payload)
      ).assignFullAccessDelegates;
    },
    {
      mutationKey: assignFullAccessDelegatesKey,
      async onMutate(payload: AssignFullAccessDelegatesPayload): Promise<MutationContext> {
        await queryClient.cancelQueries(useGetUserPermissionsRequestsKey);

        const previousInvites: UserPermissionRequestData = queryClient.getQueryData(useGetUserPermissionsRequestsKey)!;

        queryClient.setQueryData(useGetUserPermissionsRequestsKey, (old: UserPermissionRequestData) => {
          const newUserRequests: UserDelegateWithDelegateId[] = payload.delegateEmails.map((email) => ({
            email,
            createdAt: formatISO(Date.now()),
            updatedAt: formatISO(Date.now()),
            delegateId: String(Math.random()),
            isApproved: true,
            isRevoked: false,
          }));

          return {
            ...old,
            delegatedToUserRequests: [...old.delegatedToUserRequests, ...newUserRequests],
          };
        });

        return { previousInvites };
      },
      onSuccess: (data, variables, context) => {
        if (options.onSuccess) {
          options.onSuccess(data, variables, context);
        } else {
          snackbarSuccess(data);
        }
      },
      onError(e, variables, context: MutationContext) {
        console.error('An error occurred while assigning full access');
        const eventId = Sentry.captureException(e);

        queryClient.setQueryData(useGetUserPermissionsRequestsKey, () => context.previousInvites);
        if (options.onError) {
          options.onError(e, variables, context);
        } else {
          snackbarController.addErrorSnackbar({
            message: 'Failed to send invite',
            errorCode: ErrorCodes.AssignFullAccess,
            eventId,
          });
        }
      },
      onSettled() {
        queryClient.invalidateQueries(useGetUserPermissionsRequestsKey);
      },
      ...options,
    }
  );
};

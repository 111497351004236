import React from 'react';
import { useParams } from 'react-router-dom';
import { Wizard, StepOptions } from 'src/domain';
import { defaultWizardRoutes } from 'src/lib';
import { useGetAvidPackage } from 'src/pages/Avid/hooks';
import { useGetFileById, useEnsureSelectedAgent } from 'src/hooks';
import { SendFormsSteps } from './types';
import { SendFormsEmail } from './steps/SendFormsEmail';
import { SendFormsSending } from './steps/SendFormsSending';

export const steps: StepOptions[] = [
  {
    key: SendFormsSteps.email,
    component: SendFormsEmail,
  },
  {
    key: SendFormsSteps.sending,
    component: SendFormsSending,
  },
];

export const SendFormsWizard: React.FC = () => {
  const { packageId } = useParams<{ packageId: string }>();
  const { data: breezePackage } = useGetAvidPackage(packageId);
  const { data: file } = useGetFileById(breezePackage?.fileId);
  useEnsureSelectedAgent(file?.ownedBy);

  return (
    <Wizard
      steps={steps}
      defaultStep={SendFormsSteps.email}
      prefix={defaultWizardRoutes.sendForms.slice(1)}
      progress={0}
    />
  );
};

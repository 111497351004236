import React from 'react';
import { Typography, makeStyles, Theme } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { useVerifyAndSaveCreds, VerifyCredsRequest } from 'src/hooks';
import { Navigation, Step } from 'src/common';
import { AuthTypeInfo, toWords } from 'src/lib';
import { CredentialInputs } from 'src/domain';
import { useTayTip } from '../../useTayTip';

const useStyles = makeStyles((theme: Theme) => ({
  error: {
    marginTop: theme.spacing(2),
    color: 'red',
  },
}));

export interface CredentialsProps {
  onSuccess: () => void;
  onBack: () => void;
  authInfo: AuthTypeInfo;
  isFirst: boolean;
  total: number;
}

export const Credentials = (props: CredentialsProps) => {
  const classes = useStyles();
  const { onSuccess, authInfo, isFirst, total } = props;
  const { register, handleSubmit, errors, reset } = useForm({ mode: 'onTouched' });

  const { mutate: verifyAndSaveCreds, isLoading: isAuthenticating, error } = useVerifyAndSaveCreds({ onSuccess });

  useTayTip(
    `You need authorization from ${toWords(total)} ${
      total > 1 ? 'libraries' : 'library'
    } to use these forms. This is a one-time thing.`
  );

  const onSubmit = async (request: VerifyCredsRequest) => verifyAndSaveCreds({ authType: authInfo.authType, request });

  return (
    <Step
      title={`We${isFirst ? ' ' : ' also '}need your ${authInfo.libraryName} credentials. We'll save it for next time.`}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <CredentialInputs register={register} errors={errors} reset={reset} authType={authInfo.authType} />
        {!isAuthenticating && error && <Typography className={classes.error}>{error}</Typography>}

        <Navigation
          isLoading={isAuthenticating}
          PrevButtonProps={{ onClick: () => props.onBack(), children: 'Back' }}
        />
      </form>
    </Step>
  );
};

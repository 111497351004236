import React, { useRef } from 'react';
import { Send as SendIcon } from '@material-ui/icons';
import { makeStyles, Theme } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Navigation, EmailStateProps, NavigationProps, Step } from 'src/common';
import { EmailMetaRecipientField } from 'src/common/ComposeEmail/EmailMetaRecipientField';
import { EmailLineLabel } from 'src/common/ComposeEmail/EmailLineLabel';
import { EmailMetaSubjectField } from 'src/common/ComposeEmail/EmailMetaSubjectField';
import { EmailToField } from './components/EmailToField';

export function composeEmailVisualInputStyles(theme: Theme): CSSProperties {
  return {
    fontFamily: 'inherit',
    fontSize: 'inherit',
    border: '1px solid',
    borderColor: theme.colors.grey.disable,
    borderRadius: 8,
    boxSizing: 'border-box',
    minHeight: 30,
    outline: 'none !important',
    '&:focus-within': {
      borderColor: theme.colors.mainColor.primary,
      borderLeft: `4px solid ${theme.colors.mainColor.primary}`,
    },
  };
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: theme.spacing(4),
      fontSize: '1.25rem',
      [theme.breakpoints.down('xs')]: {
        fontSize: '1rem',
      },
    },
    subjectInput: {
      ...composeEmailVisualInputStyles(theme),
      width: '100%',
      paddingRight: 0,
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    bodyTextArea: {
      ...composeEmailVisualInputStyles(theme),
      width: '100%',
      padding: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
  }),
  { classNamePrefix: 'ComposeEmail' }
);

export interface CustomEmailFormProps {
  onBack: () => void;
  onSend: () => Promise<void>;
  editRecipient: () => void;
  title: string;
  emailState: EmailStateProps;
  hideCc?: boolean;
  disableEditRecipients?: boolean;
  NavigationProps?: NavigationProps;
  recipientToolTipTitle?: string;
  bodyRowCount?: number;
}

export const CustomEmailForm = (props: CustomEmailFormProps) => {
  const {
    onBack,
    onSend,
    emailState,
    hideCc,
    disableEditRecipients,
    NavigationProps = {},
    editRecipient,
    recipientToolTipTitle = '',
    bodyRowCount = 10,
    ...rest
  } = props;
  const { PrevButtonProps = {}, NextButtonProps = {}, ...restNavProps } = NavigationProps;
  const { subject, recipients, setRecipients, ccRecipients, setCcRecipients, signature, setSignature } = emailState;
  const subjectFieldRef = useRef<HTMLTextAreaElement>(null);
  const classes = useStyles();

  return (
    <Step title={props.title}>
      <div {...rest} className={classes.root}>
        <EmailToField
          label="To"
          recipients={recipients}
          setRecipients={setRecipients}
          disabled={disableEditRecipients}
          onClick={editRecipient}
          toolTipTitle={recipientToolTipTitle}
        />

        {!hideCc && (
          <EmailMetaRecipientField
            label="Cc"
            recipients={ccRecipients}
            setRecipients={setCcRecipients}
            disabled={disableEditRecipients}
          />
        )}

        <EmailMetaSubjectField
          className={classes.subjectInput}
          label="Subject"
          value={subject}
          onChange={(e) => emailState.setSubject(e.target.value)}
        />

        <div>
          <EmailLineLabel inputRef={subjectFieldRef}>Message</EmailLineLabel>
          <textarea
            rows={bodyRowCount}
            className={classes.bodyTextArea}
            placeholder="Compose email message..."
            value={emailState.body}
            onChange={(e) => emailState.setBody(e.target.value)}
          />
        </div>

        <div>
          <EmailLineLabel inputRef={subjectFieldRef}>Signature</EmailLineLabel>
          <textarea
            rows={5}
            className={classes.bodyTextArea}
            placeholder="Agent Signature"
            value={signature}
            onChange={(e) => setSignature(e.target.value)}
          />
        </div>
      </div>
      <Navigation
        {...restNavProps}
        PrevButtonProps={{ children: 'Cancel', onClick: onBack, startIcon: null, ...PrevButtonProps }}
        NextButtonProps={{
          children: 'Send',
          endIcon: <SendIcon />,
          disabled: emailState.hasError,
          onClick: () => onSend(),
          ...NextButtonProps,
        }}
      />
    </Step>
  );
};

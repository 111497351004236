import { useEffect } from 'react';
import { useAuth } from '@skyslope/auth-react';
import { ENV } from 'src/lib';

export const useSetupClarity = (): void => {
  const { userContext } = useAuth();

  const setClarity = (attribute: string, value: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    clarity('set', attribute, value);
  };

  useEffect(() => {
    if (userContext?.id) {
      setClarity('environment', ENV);
      setClarity('email', userContext.email);
      setClarity('sub', String(userContext.id));
      setClarity('subscriber-id', String(userContext.prime?.subscriberId) ?? '');
    }
  }, [userContext]);
};

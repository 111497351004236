import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, Button } from '@material-ui/core';
import { AddCircleOutlineSharp } from '@material-ui/icons';
import { useIsMobile } from 'src/hooks';
import { Spacer } from 'src/common';
import { TeamMemberListContext, TeamMemberListContextShape } from './context';

const useStyles = makeStyles<Theme, TeamMemberListProps>(
  (theme: Theme) => ({
    root: {
      border: '2px solid',
      borderColor: theme.colors.grey.disable,
      display: 'block',
      flexDirection: 'column',
      borderRadius: 4,
    },
    mobileRoot: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    contentSection: {
      height: '325px',
      overflow: 'auto',
      flex: 1,
      padding: theme.spacing(4),
    },
    actionsSection: {
      borderTop: '2px solid',
      borderColor: theme.colors.grey.disable,
      padding: theme.spacing(3),
      background: theme.colors.grey.offWhite1,
      display: 'flex',
      justifyContent: 'center',
    },
  }),
  { classNamePrefix: 'TeamMemberList' }
);

export interface TeamMemberListProps {
  onAddTeamMember(): void;
  className?: string;
  allowMultiple?: boolean;
  value: TeamMemberListContextShape['value'];
  onValueChange(newValue: TeamMemberListContextShape['value']): void;
  id?: string;
  addNewTeamMemberBtnText?: string;
}

export const TeamMemberList: React.FC<TeamMemberListProps> = (props) => {
  const classes = useStyles(props);
  const { className, children, allowMultiple, value, onValueChange, id } = props;

  const isMobile = useIsMobile();

  const handleMemberSelected = (memberValue: string) => {
    if (allowMultiple) {
      if (value.includes(memberValue)) {
        // Checkbox was unchecked
        onValueChange(value.filter((v) => v !== memberValue));
      } else {
        // Checkbox was checked
        onValueChange([...value, memberValue]);
      }
    } else {
      // Radio button was selected (doesn't matter if already selected)
      onValueChange([memberValue]);
    }
  };

  const childrenWithContext = (
    <TeamMemberListContext.Provider value={{ allowMultiple, value, onMemberSelected: handleMemberSelected }}>
      {children}
    </TeamMemberListContext.Provider>
  );

  if (isMobile) {
    return (
      <div id={`${id}-team-member-list-content`} className={classes.mobileRoot}>
        {childrenWithContext}
        <Spacer axis="vertical" size={4} />
        <AddButton {...props} />
      </div>
    );
  }

  return (
    <div className={clsx(classes.root, className)}>
      <div id={`${id}-team-member-list-content`} className={classes.contentSection}>
        {childrenWithContext}
      </div>
      <div className={classes.actionsSection}>
        <AddButton {...props} />
      </div>
    </div>
  );
};

function AddButton(props: TeamMemberListProps) {
  const { onAddTeamMember, addNewTeamMemberBtnText = 'Add a new team member' } = props;
  const isMobile = useIsMobile();

  return (
    <Button
      variant="text"
      startIcon={<AddCircleOutlineSharp />}
      color="primary"
      onClick={onAddTeamMember}
      size={isMobile ? 'small' : 'medium'}
    >
      {addNewTeamMemberBtnText}
    </Button>
  );
}

import { ChangeEvent, useCallback, useMemo, useState, useEffect } from 'react';
import { useDebounce, useGetDisclosuresByRegion, DisclosureDefinition } from 'src/hooks';
import { TemplateStatus, usStates } from 'src/lib';
import { useDisclosuresStore } from '../../store';

export interface UsePickFormsOptionsApi {
  results: DisclosureDefinition[] | undefined;
  onSelect: (event: ChangeEvent<HTMLInputElement>) => void;
  selection: DisclosureDefinition[];
  onSearch: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const usePickFormsOptions = (): UsePickFormsOptionsApi => {
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchValue = useDebounce(searchQuery.toLowerCase(), 300);
  const store = useDisclosuresStore();
  const currentRegion = usStates[store.property?.state as any] as usStates | undefined;
  const { data: disclosureList } = useGetDisclosuresByRegion(currentRegion);

  const [selection, setSelection] = useState<DisclosureDefinition[]>([]);

  useEffect(() => {
    if (!disclosureList) return;

    // The stored forms may be from a different state/region
    // and are no longer valid, so filter them out.
    const validForms = store.forms?.filter((d) => disclosureList.includes(d));
    if (validForms?.length) {
      setSelection(validForms);
    } else {
      const defaultForms = disclosureList.filter((d) => d.defaulted && d.tags.status[0] === TemplateStatus.LIVE);
      setSelection(defaultForms);
    }
  }, [disclosureList, store.forms]);

  const onSelect = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const selectedDisclosure = disclosureList?.find((d) => d.interviewTemplateId === Number(event.target.value));
      if (!selectedDisclosure) return;

      if (event.target.checked) {
        setSelection((selection) => [...selection, selectedDisclosure]);
      } else {
        setSelection((selection) =>
          selection.filter((d) => d.interviewTemplateId !== selectedDisclosure.interviewTemplateId)
        );
      }
    },
    [disclosureList]
  );

  const results = useMemo(
    () =>
      debouncedSearchValue
        ? disclosureList?.filter((d) => d.name.toLowerCase().includes(debouncedSearchValue)) || []
        : currentRegion === usStates.AZ
        ? disclosureList?.sort((a, b) => a.name.localeCompare(b.name))
        : disclosureList,
    [debouncedSearchValue, disclosureList, currentRegion]
  );

  const onSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  }, []);

  return {
    onSelect,
    results,
    selection,
    onSearch,
  };
};

import React from 'react';
import {
  makeStyles,
  Theme,
  Select,
  FormControl,
  SelectProps,
  MenuItem,
  InputAdornment,
  InputBase,
} from '@material-ui/core';
import { LocationOn as LocationIcon } from '@material-ui/icons';
import { usStates } from 'src/lib';

const useStyles = makeStyles(
  (theme: Theme) => ({
    select: {
      color: theme.colors.grey.bodyHeader,
    },
    icon: {
      color: theme.colors.grey.disable,
    },
    placeholder: {
      padding: 0,
      fontWeight: 'bold',
      fontSize: '1.5rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.125rem',
      },
    },
  }),
  { classNamePrefix: 'SelectRegion' }
);

export interface SelectRegionProps extends Omit<SelectProps, 'value' | 'children' | 'onChange'> {
  region: string;
  regions: string[];
  onChange(region: usStates): void;
}

/**
 * A dropdown where the user can choose a `usStates`.
 * This is used by `SelectRegions` (note the "s") to allow the user to choose
 * all of their applicable regions.
 */
export const SelectRegion: React.FC<SelectRegionProps> = (props) => {
  const classes = useStyles();
  const { className, style, region, regions, onChange, ...rest } = props;

  return (
    <FormControl variant="outlined" className={className} style={style}>
      <Select
        {...rest}
        classes={{ root: classes.select, icon: classes.icon }}
        value={region}
        inputProps={{ 'aria-label': 'Region' }}
        onChange={(e) => onChange(e.target.value as usStates)}
        displayEmpty
        renderValue={
          region !== ''
            ? undefined
            : () => <InputBase classes={{ input: classes.placeholder }} placeholder="Select Region"></InputBase>
        }
        startAdornment={
          <InputAdornment position="start">
            <LocationIcon />
          </InputAdornment>
        }
      >
        {regions.map((region) => (
          <MenuItem key={region} value={region}>
            {region}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

import { gql } from 'graphql-request';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { formatISO } from 'date-fns';
import * as Sentry from '@sentry/react';
import {
  DelegationRequestOrGrantResponse,
  ErrorCodes,
  getGraphQLClient,
  getApiToken,
  UserDelegateWithOwnerId,
} from '../../lib';
import { useCustomSnackbar, useGetUserPermissionsRequestsKey, UserPermissionRequestData } from '..';
import { useDelegationRequestOrAssignSnackbars } from './useDelegationRequestOrAssignSnackbars';

export interface RequestFullAccessDelegatesPayload {
  delegateEmails: string[];
}

const mutationQuery = gql`
  mutation requestFullAccessDelegates($delegateEmails: [String]!) {
    requestFullAccessDelegates(delegateEmails: $delegateEmails) {
      existingUserEmailsHandled
      newUserEmailsHandled
    }
  }
`;

interface MutationContext {
  previousInvites: UserPermissionRequestData;
}

export const requestFullAccessDelegatesKey = 'request-full-access-delegates-mutation';

const createSuccessMessage = (email: string) => `Request sent to ${email}`;
const createInfoMessage = (email: string) => `Invite sent to ${email}`;

export const useRequestFullAccess = (
  options: UseMutationOptions<DelegationRequestOrGrantResponse, AxiosError, RequestFullAccessDelegatesPayload> = {}
) => {
  const queryClient = useQueryClient();
  const snackbarSuccess = useDelegationRequestOrAssignSnackbars(createSuccessMessage, createInfoMessage);
  const snackbarController = useCustomSnackbar();

  return useMutation<DelegationRequestOrGrantResponse, AxiosError, RequestFullAccessDelegatesPayload>(
    async (payload: RequestFullAccessDelegatesPayload) => {
      const client = getGraphQLClient(await getApiToken());
      return (
        await client.request<
          { requestFullAccessDelegates: DelegationRequestOrGrantResponse },
          RequestFullAccessDelegatesPayload
        >(mutationQuery, payload)
      ).requestFullAccessDelegates;
    },
    {
      mutationKey: requestFullAccessDelegatesKey,
      async onMutate(payload: RequestFullAccessDelegatesPayload): Promise<MutationContext> {
        await queryClient.cancelQueries(useGetUserPermissionsRequestsKey);

        const previousInvites: UserPermissionRequestData = queryClient.getQueryData(useGetUserPermissionsRequestsKey)!;

        queryClient.setQueryData(useGetUserPermissionsRequestsKey, (old: UserPermissionRequestData) => {
          const newUserRequests: UserDelegateWithOwnerId[] = payload.delegateEmails.map((email) => ({
            email,
            createdAt: formatISO(Date.now()),
            updatedAt: formatISO(Date.now()),
            ownerId: String(Math.random()),
            isApproved: false,
            isRevoked: false,
          }));

          return {
            ...old,
            delegatedFromUserRequests: [...old.delegatedFromUserRequests, ...newUserRequests],
          };
        });

        return { previousInvites };
      },
      onSuccess: (data, variables, context) => {
        if (options.onSuccess) {
          options.onSuccess(data, variables, context);
        } else {
          snackbarSuccess(data);
        }
      },
      onError(e, variables, context: MutationContext) {
        console.error('An error occurred while requesting full access');
        Sentry.captureException(e);

        queryClient.setQueryData(useGetUserPermissionsRequestsKey, () => context.previousInvites);
        if (options.onError) {
          options.onError(e, variables, context);
        } else {
          snackbarController.addErrorSnackbar({
            message: 'Failed to send request',
            errorCode: ErrorCodes.RequestFullAccess,
          });
        }
      },
      onSettled() {
        queryClient.invalidateQueries(useGetUserPermissionsRequestsKey);
      },
      ...options,
    }
  );
};

import React from 'react';
import { Grid } from '@material-ui/core';
import { Create as CreateIcon, Check as CheckIcon } from '@material-ui/icons';
import { Navigation, Step } from 'src/common';
import { useTayTip } from 'src/domain/wizard/useTayTip';
import { AvidRoom, RoomBuckets } from '../../../types';
import { AvidRoomOption } from '../../../hooks/useGetAvidRoomOptions';
import { RoomGroup } from './RoomGroup';
import { getRoomIcon } from './getRoomIcon';
import { NoRoomsState } from './NoRoomsState';

export interface HomeBaseMobileProps {
  options: AvidRoomOption[];
  buckets: RoomBuckets;
  onEdit(): void;
  onNext(): void;
  onAddNotes(room: AvidRoom): void;
  isLoading: boolean;
}

export const HomeBaseView: React.FC<HomeBaseMobileProps> = ({
  options,
  buckets,
  onEdit,
  onNext,
  onAddNotes,
  isLoading,
}) => {
  const rooms = options.filter((o) => buckets[o.roomType]);
  const showEmptyState = !isLoading && !rooms.length;
  const haveNotesBeenAdded = rooms.some((room) => buckets[room.roomType].filter((r) => r.notes).length);

  useTayTip(
    showEmptyState
      ? 'Click the button below to add rooms.'
      : 'We’ve added the following rooms based on your MLS listing.'
  );

  return (
    <Step title={!showEmptyState && 'Select a room to add your notes.'}>
      <Grid container spacing={3} justifyContent="center">
        {showEmptyState && <NoRoomsState onAddRooms={onEdit} />}
        {rooms.map((room) => (
          <RoomGroup
            key={room.roomType}
            display={room.displayName}
            rooms={buckets[room.roomType]}
            icon={getRoomIcon(room.roomType)}
            onAddNotes={onAddNotes}
          />
        ))}
      </Grid>
      <Navigation
        isLoading={isLoading}
        PrevButtonProps={{
          color: 'primary',
          variant: 'outlined',
          children: 'Edit Rooms',
          onClick: onEdit,
          disabled: showEmptyState,
          endIcon: <CreateIcon />,
          startIcon: null,
        }}
        NextButtonProps={{
          children: "I'm Done",
          onClick: onNext,
          disabled: showEmptyState || !haveNotesBeenAdded,
          endIcon: <CheckIcon />,
        }}
      />
    </Step>
  );
};

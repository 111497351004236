import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';

function parseSize(size: number | string, theme: Theme) {
  return typeof size === 'string' ? size : theme.spacing(size);
}

const useStyles = makeStyles<Theme, SpacerProps>(
  (t: Theme) => ({
    root: {
      display: 'block',

      width: (p) => (p.axis === 'horizontal' ? parseSize(p.size, t) : 1),
      minWidth: (p) => (p.axis === 'horizontal' ? parseSize(p.size, t) : 1),

      height: (p) => (p.axis === 'vertical' ? parseSize(p.size, t) : 1),
      minHeight: (p) => (p.axis === 'vertical' ? parseSize(p.size, t) : 1),
    },
  }),
  { classNamePrefix: 'spacer' }
);

export interface SpacerProps extends React.HtmlHTMLAttributes<HTMLElement> {
  size: number | string;
  axis?: 'horizontal' | 'vertical';
}

/**
 * An element to put space between other elements, replacing
 * margin.  Handling space in this way avoids many of the
 * gotchas that come along with using margins.
 *
 * See [here](https://www.joshwcomeau.com/react/modern-spacer-gif/)
 * for more explanation.
 */
export const Spacer = (props: SpacerProps) => {
  const classes = useStyles(props);
  return <span {...props} className={classes.root} />;
};

Spacer.defaultProps = {
  axis: 'horizontal',
};

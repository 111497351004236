import { config } from 'src/lib';
import { PackageInterview } from '../types';

export const useGetResolveSignerFlagUrl = (
  interviews: PackageInterview[] | undefined,
  currentInterviewId: number | undefined
) => {
  const interviewId = interviews?.find((i) => i.id === currentInterviewId)?.id || 0;
  const signerId = interviews?.find((i) => i.id === currentInterviewId)?.signers[0].id || 0;
  return {
    resolveSignerFlagUrl: `${config.sklosuresServiceUrl}/integrators/interviews/${interviewId}/signers/${signerId}/flags`,
  };
};

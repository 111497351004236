import { useMemo } from 'react';
import { useGetFileById } from '../../../hooks/domain/queries/useGetFileById';
import { Contact, RepresentationType } from '../../../lib/api/files';
import { contactTypes } from '../../../lib/constants';
import { convertRepTypeToContactType } from '../AvidDetails/steps/AvidContacts';

export interface UseGetFileContactsByRepTypeResult {
  contacts?: Contact[];
  contactType?: string;
  representationType?: RepresentationType;
}

export const useGetFileContactsByRepType = (fileId: number | undefined): UseGetFileContactsByRepTypeResult => {
  const { data: file } = useGetFileById(fileId);

  return useMemo(() => {
    const representationType = file?.representationType;
    const contactType = convertRepTypeToContactType(representationType ?? RepresentationType.BUYER);
    const contacts = contactType === contactTypes.SELLER ? file?.fileData.sellers : file?.fileData.buyers;
    return {
      representationType,
      contacts,
      contactType,
    };
  }, [file]);
};

import React from 'react';
import { StdDialog } from './StdDialog';
import { StdDialogProps } from '.';

export interface StdConfirmDialogProps extends Omit<StdDialogProps, 'title' | 'actions'> {
  onConfirm(): void;
}

/**
 * A dialog  with continue & cancel buttons to prompt the user to
 * confirm or cancel a particular action.
 */
export const StdConfirmDialog: React.FC<StdConfirmDialogProps> = (props) => {
  const { children, onClose, onConfirm, ...rest } = props;

  return (
    <StdDialog
      {...rest}
      title="Are you sure?"
      onClose={onClose}
      actions={
        <>
          <StdDialog.SecondaryActionButton onClick={onClose}>Cancel</StdDialog.SecondaryActionButton>
          <StdDialog.PrimaryActionButton onClick={onConfirm}>Yes, Continue</StdDialog.PrimaryActionButton>
        </>
      }
    >
      {children}
    </StdDialog>
  );
};

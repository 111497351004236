import React, { useEffect } from 'react';
import {
  makeStyles,
  Theme,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { ControlPointSharp as PlusIcon } from '@material-ui/icons';
import { Control, useFieldArray, useForm, Controller, useWatch } from 'react-hook-form';
import { Skeleton } from '@material-ui/lab';
import { contactTypes, Contact, emailRegex } from 'src/lib';
import { useIsMobile } from 'src/hooks';
import { Spacer, TextInput, Step, Navigation, NavigationProps } from 'src/common';
import { ContactRow } from './ContactRow';
import { ContainerWithGutters } from './ContainerWithGutters';

const useStyles = makeStyles(
  (theme: Theme) => ({
    row: {
      position: 'relative',
      marginBottom: 32,
    },
    form: {
      [theme.breakpoints.up('md')]: {
        marginLeft: -48,
        marginRight: -48,
      },
      [theme.breakpoints.up('lg')]: {
        marginLeft: -100,
        marginRight: -100,
      },
    },
    footer: {
      textAlign: 'left',
      marginTop: -16,
    },
    role: {
      marginBottom: 4,
    },
    emailCheckbox: {
      marginTop: 12,
      marginBottom: 8,
    },
    emailCheckboxLabel: {
      color: theme.colors.grey.label,
      fontWeight: 600,
      fontSize: 22,
    },
    addAnotherButton: {
      width: '100%',
      backgroundColor: theme.colors.grey.offWhite3,
      padding: theme.spacing(2),
      '&:focus': {
        backgroundColor: theme.colors.grey.offWhite3,
      },
    },
    errorMessage: {
      marginTop: theme.spacing(2),
      fontSize: '1rem',
      fontWeight: 700,
      color: theme.colors.error,
    },
  }),

  { classNamePrefix: 'ContactCollectionStep' }
);

export interface ContactsForm {
  contacts: Contact[];
}

export interface ContactTypeOption {
  value: string;
  label: string;
}

export interface ContactsProps {
  defaultContacts: Partial<Contact>[];
  isRoleEditable?: boolean;
  isPossibleEntity?: boolean;
  title?: string;
  onSubmit: (form: ContactsForm) => void;
  NavigationProps?: NavigationProps;
  getValidContactTypes?: (currentContacts: Contact[]) => ContactTypeOption[];
  getBlankContact?: () => Partial<Contact> & { type: string };
  isTeamMember?: boolean;
  isLoading?: boolean;
  requiredFields?: {
    firstName?: boolean;
    lastName?: boolean;
    email?: boolean;
  };
  validators?: {
    firstName?: (val: string) => boolean | string;
    lastName?: (val: string) => boolean | string;
    email?: (val: string) => boolean | string;
  };
  validationMessages?: {
    firstName?: string;
    lastName?: string;
    email?: string;
  };
  dontRequireFirstContact?: boolean;
}

const defaultGetValidContactTypes = () =>
  Object.values(contactTypes).map((contactType) => ({ label: contactType, value: contactType }));

/**
 * A generic wizard step for collecting first name, last name, email, and optionally organization name.
 */
export const ContactCollectionStep: React.FC<ContactsProps> = ({
  defaultContacts,
  isRoleEditable = false,
  isPossibleEntity = false,
  title = 'Which contacts would you like to add?',
  onSubmit,
  NavigationProps = {},
  getValidContactTypes,
  getBlankContact,
  isTeamMember,
  isLoading = false,
  requiredFields = {
    firstName: true,
    lastName: true,
    email: true,
  },
  validators = {},
  validationMessages = {},
  dontRequireFirstContact,
}) => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const { register, errors, control, handleSubmit, reset } = useForm<ContactsForm>({
    mode: 'onTouched',
    defaultValues: {
      contacts: defaultContacts,
    },
  });

  useEffect(() => {
    if (!isLoading) {
      reset({ contacts: defaultContacts });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const fieldArray = useFieldArray<Contact, 'key'>({
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    control: control as Control<Record<string, any>>,
    keyName: 'key',
    name: 'contacts',
  });
  const contacts = useWatch<Contact[]>({ control, name: 'contacts' }) ?? [];
  const titleOfContact = isTeamMember ? 'teammate' : '';

  const hasRequiredFields =
    contacts?.length > 0 &&
    contacts.every((contact, index) => {
      const hasCompanyName = !contact.isEntity || contact.companyName;
      const hasEmail = !requiredFields.email || contact.email;
      const hasFirstName = !requiredFields.firstName || contact.firstName;
      const hasLastName = !requiredFields.lastName || contact.lastName;

      // Allow form to be saved with the first contact being empty so that
      // the first contact can be deleted if the user desires.
      if (
        dontRequireFirstContact &&
        index === 0 &&
        contacts.length === 1 &&
        hasCompanyName &&
        !hasEmail &&
        !hasFirstName &&
        !hasLastName
      ) {
        return true;
      }

      return hasCompanyName && hasEmail && hasFirstName && hasLastName;
    });

  if (isLoading) {
    return (
      <Step title={<Skeleton style={{ margin: 'auto' }} width={500} height={60} />}>
        <ContainerWithGutters>
          <Grid container spacing={isMobile ? undefined : 2}>
            {Array.from({ length: 3 }).map((_, i) => (
              <>
                <Grid item xs={12} sm={4} key={i}>
                  <Skeleton height={60} />
                </Grid>
              </>
            ))}
            <Grid item xs={6} sm={4}>
              <Skeleton height={60} />
            </Grid>
          </Grid>
        </ContainerWithGutters>
      </Step>
    );
  }

  return (
    <Step title={title}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        {fieldArray.fields.map((field, i) => {
          const validContactTypes = getValidContactTypes
            ? getValidContactTypes(fieldArray.fields)
            : defaultGetValidContactTypes();

          const isOptionalFirstContact = dontRequireFirstContact && i === 0;

          return (
            <div key={field.key}>
              {!isRoleEditable && (
                <input name={`contacts[${i}].type`} value={field.type} hidden ref={register()} readOnly />
              )}
              {field.id && <input name={`contacts[${i}].id`} value={field.id} hidden ref={register()} readOnly />}
              <ContainerWithGutters>
                {isRoleEditable && (
                  <Controller
                    name={`contacts[${i}].type`}
                    control={control}
                    defaultValue={contactTypes.SELLER}
                    render={({ onChange, value, onBlur }) => (
                      <FormControl>
                        <Select
                          value={value}
                          className={classes.role}
                          label="Role"
                          disableUnderline
                          onChange={onChange}
                          onBlur={onBlur}
                        >
                          {validContactTypes.map((option: ContactTypeOption) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                )}
                {isPossibleEntity && (
                  <Controller
                    name={`contacts[${i}].isEntity`}
                    control={control}
                    defaultValue={field.isEntity ?? false}
                    render={({ onChange, value, onBlur }) => (
                      <FormControlLabel
                        classes={{ label: classes.emailCheckboxLabel, root: classes.emailCheckbox }}
                        control={
                          <Checkbox
                            color="primary"
                            onChange={(e) => onChange(e.target.checked)}
                            onBlur={onBlur}
                            checked={value}
                          />
                        }
                        label={
                          <Typography variant={isMobile ? 'subtitle1' : 'body1'}>
                            This client is an entity or organization
                          </Typography>
                        }
                      />
                    )}
                  />
                )}
              </ContainerWithGutters>

              <ContactRow
                className={classes.row}
                onRemove={() => fieldArray.remove(i)}
                allowRemove={i > 0}
                titleOfContact={titleOfContact}
              >
                <Grid container spacing={3}>
                  {contacts[i]?.isEntity && (
                    <Grid item xs={12} md={12}>
                      <TextInput
                        label="Organization Name"
                        name={`contacts[${i}].companyName`}
                        inputRef={register({ required: 'Please enter this field.' })}
                        error={errors.contacts?.[i]?.companyName}
                        defaultValue={field.companyName}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={4}>
                    <TextInput
                      label="First name"
                      name={`contacts[${i}].firstName`}
                      inputRef={register({
                        required:
                          requiredFields.firstName && !isOptionalFirstContact ? 'Please enter this field.' : undefined,
                        validate: (val) => (validators && validators.firstName ? validators.firstName(val) : true),
                      })}
                      error={errors.contacts?.[i]?.firstName}
                      defaultValue={field.firstName}
                      helperText={requiredFields.firstName ? null : '(optional)'}
                      required={requiredFields.firstName && !isOptionalFirstContact}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextInput
                      label="Last name"
                      name={`contacts[${i}].lastName`}
                      inputRef={register({
                        required:
                          requiredFields.lastName && !isOptionalFirstContact ? 'Please enter this field.' : undefined,
                        validate: (val) => (validators && validators.lastName ? validators.lastName(val) : true),
                      })}
                      error={errors.contacts?.[i]?.lastName}
                      defaultValue={field.lastName}
                      helperText={requiredFields.lastName ? null : '(optional)'}
                      required={requiredFields.lastName && !isOptionalFirstContact}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextInput
                      label="Email"
                      name={`contacts[${i}].email`}
                      type="email"
                      inputRef={register({
                        required:
                          requiredFields.email && !isOptionalFirstContact ? 'Please enter this field.' : undefined,
                        pattern: { value: emailRegex, message: 'Please enter a valid email.' },
                        validate: {
                          email: (val) => (validators && validators.email ? validators.email(val) : true),
                        },
                      })}
                      error={errors.contacts?.[i]?.email}
                      defaultValue={field.email}
                      helperText={requiredFields.email ? null : '(optional)'}
                      required={requiredFields.email && !isOptionalFirstContact}
                    />
                  </Grid>
                </Grid>
                <Grid xs={12}>
                  <Typography className={classes.errorMessage}>
                    {validationMessages.firstName}
                    {validationMessages.lastName}
                    {validationMessages.email}
                  </Typography>
                  {contacts.length > 1 && contacts[0].type === contactTypes.SELLER && (
                    <Typography variant="subtitle1">
                      {i === 0
                        ? 'This contact will fill out and sign disclosures'
                        : 'This contact will sign disclosures'}
                    </Typography>
                  )}
                </Grid>
              </ContactRow>
            </div>
          );
        })}
        {(fieldArray.fields.length == 1 || isTeamMember) && (
          <footer className={classes.footer}>
            <Spacer axis="vertical" size={3} />
            <ContainerWithGutters>
              <Button
                startIcon={<PlusIcon />}
                color="primary"
                className={classes.addAnotherButton}
                onClick={() => fieldArray.append(getBlankContact?.() ?? { type: contactTypes.OTHER })}
                size={isMobile ? 'small' : 'medium'}
              >
                Add another {titleOfContact}
              </Button>
            </ContainerWithGutters>
          </footer>
        )}

        <Navigation
          {...NavigationProps}
          NextButtonProps={{
            ...NavigationProps.NextButtonProps,
            disabled: NavigationProps.NextButtonProps?.disabled || fieldArray.fields.length === 0 || !hasRequiredFields,
          }}
        />
      </form>
    </Step>
  );
};

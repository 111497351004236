import { useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { useRecoilState } from 'recoil';
import {
  useFlags,
  useCancelEnvelopes,
  useResendEnvelopes,
  useFileId,
  useDownloadPackageZip,
  useDownloadPackageMerged,
  DownloadPackageResponse,
  useQueryParams,
  useGetUserProfile,
} from 'src/hooks';
import { defaultWizardRoutes, PackageTypes, config } from 'src/lib';
import { useFetchAgentSignerToken } from 'src/hooks/domain/mutations/useFetchAgentSignerToken';
import { useSendDisclosuresToFormsMutation } from 'src/hooks/domain/mutations/useSendDisclosuresToFormsMutation';
import { sendingDisclosuresToFormsAtom } from 'src/state';
import { useFileHasAvid } from '../../hooks/useFileHasAvid';
import { SpqTdsDisclosureSteps } from '../../../SpqTdsDisclosures/steps';
import { FilePackageItemActionsProps } from './FilePackageItemActions';

export const useFilePackageItemActions = (props: FilePackageItemActionsProps) => {
  const { interviews, forms, packageType, packageId, isAvidFlowCompleted = false, name, fileName, envelopeId } = props;

  const fileId = useFileId();
  const fileHasAvid = useFileHasAvid(fileId);

  const history = useHistory();

  const { data: user } = useGetUserProfile();

  const { mutate: cancelPackageEnvelopes } = useCancelEnvelopes(fileId);
  const { mutate: resendPackageEnvelopes, isLoading: isResendingEnvelopes } = useResendEnvelopes();
  const { mutate: downloadPackageMerged } = useDownloadPackageMerged();
  const { mutate: downloadPackageZip } = useDownloadPackageZip();
  const { mutate: getSignerToken, isLoading: isFetchingSignerToken } = useFetchAgentSignerToken();
  const { mutate: sendDisclosuresToForms, isLoading: isSendingDisclosuresToForms } =
    useSendDisclosuresToFormsMutation();

  const { isTdsSectionThreeEnabled, isTdsSectionThreeEnabledForDownload } = useFlags();
  const hasTds = useMemo(() => forms?.some((f) => f.name.includes('TDS')), [forms]);
  const hasMhtds = useMemo(() => forms?.some((f) => f.name.includes('MHTDS')), [forms]);
  const tdsInterviewId = forms?.find((f) => f.name.includes('TDS'))?.interviewId;
  const mhtdsInterviewId = forms?.find((f) => f.name.includes('MHTDS'))?.interviewId;
  const isTdsTerminated =
    interviews?.every((i) => ['CANCELLED', 'EXPIRED'].includes(i.status)) ||
    interviews?.some((i) => i.id === tdsInterviewId && ['CANCELLED', 'EXPIRED'].includes(i.status));
  const isMhtdsTerminated =
    interviews?.every((i) => ['CANCELLED', 'EXPIRED'].includes(i.status)) ||
    interviews?.some((i) => i.id === mhtdsInterviewId && ['CANCELLED', 'EXPIRED'].includes(i.status));

  const queryParams = useQueryParams();
  const downloadType = queryParams.get('downloadType') ?? '';
  const firstDocumentStep = fileHasAvid ? SpqTdsDisclosureSteps.isUsingExistingAvid : SpqTdsDisclosureSteps.tdsSection3;

  const [, setSendingDisclosuresToForms] = useRecoilState(sendingDisclosuresToFormsAtom);

  const onCancelEnvelopes = () => {
    cancelPackageEnvelopes({ packageId });
  };

  const onResendEnvelopes = () => {
    resendPackageEnvelopes({ packageId });
  };

  const onEditPackage = () => {
    if (packageType === PackageTypes.avid) {
      history.push(`${defaultWizardRoutes.avidDetails.replace(':packageId', packageId)}/review`);
    }
  };

  const onSendDisclosuresEnvelopes = () => {
    setSendingDisclosuresToForms(false);
    if (!isAvidFlowCompleted && isTdsSectionThreeEnabled && (hasTds || hasMhtds)) {
      history.push(
        `${defaultWizardRoutes.disclosures}/${firstDocumentStep}?fileId=${fileId}&packageId=${packageId}&default=${firstDocumentStep}`
      );
    } else {
      history.push(
        `${defaultWizardRoutes.disclosures}/${SpqTdsDisclosureSteps.requestSignaturesEmail}?fileId=${fileId}&packageId=${packageId}&default=${SpqTdsDisclosureSteps.requestSignaturesEmail}`
      );
    }
  };

  const onSendForms = () => {
    history.push(defaultWizardRoutes.sendForms.replace(':packageId', packageId));
  };

  const onResendPackage = () => {
    history.push(
      `${defaultWizardRoutes.resendDisclosures.replace(':fileId', String(fileId)).replace(':packageId', packageId)}`
    );
  };

  const onDownloadPackageMerged = useCallback(() => {
    downloadPackageMerged(
      { packageId },
      {
        onSuccess: (result: DownloadPackageResponse) => {
          const downloadName = `${fileName ?? ''} ${name ?? ''}`;
          saveAs(`data:application/octet-stream;base64,${result.content}`, `${downloadName ?? 'download'}.pdf`);
        },
      }
    );
  }, [downloadPackageMerged, fileName, name, packageId]);

  const onDownloadPackageZip = useCallback(() => {
    downloadPackageZip(
      { packageId },
      {
        onSuccess: (result: DownloadPackageResponse[]) => {
          const zippedPackage = new JSZip();
          const downloadName = `${fileName ?? ''} ${name ?? ''}`;
          zippedPackage.folder(downloadName);
          result.forEach((form) => {
            zippedPackage
              .folder(downloadName)
              ?.file(`${fileName} ${form.filename}.pdf`, `${form.content}`, { base64: true });
          });
          zippedPackage.generateAsync({ type: 'blob' }).then((content) => {
            saveAs(content, `${downloadName ?? 'download'}.zip`);
          });
        },
      }
    );
  }, [downloadPackageZip, fileName, name, packageId]);

  const onGetSignerToken = () => {
    getSignerToken(
      { envelopeId: envelopeId ?? '', email: user?.email ?? '' },
      {
        onSuccess: (token: string) => {
          window.open(`${config.ds3SignerUrl}/envelope#t=${token}`);
        },
      }
    );
  };

  const onSendDisclosuresToForms = () => {
    setSendingDisclosuresToForms(true);
    if (!isAvidFlowCompleted && isTdsSectionThreeEnabled && (hasTds || hasMhtds)) {
      history.push(
        `${defaultWizardRoutes.disclosures}/${firstDocumentStep}?fileId=${fileId}&packageId=${packageId}&default=${firstDocumentStep}`
      );
    } else {
      sendDisclosuresToForms(
        { packageId },
        {
          onSuccess: () => {
            window.open(`${config.formsApiUrl}/file-details/${fileId}/documents`, '_self');
          },
        }
      );
    }
  };

  return {
    downloadType,
    fileId,
    firstDocumentStep,
    hasTds,
    hasMhtds,
    isResendingEnvelopes,
    isTdsSectionThreeEnabled,
    isTdsSectionThreeEnabledForDownload,
    isTdsTerminated,
    isMhtdsTerminated,
    isFetchingSignerToken,
    isSendingDisclosuresToForms,
    onCancelEnvelopes,
    onDownloadPackageMerged,
    onDownloadPackageZip,
    onEditPackage,
    onResendEnvelopes,
    onSendDisclosuresEnvelopes,
    onSendForms,
    onGetSignerToken,
    onSendDisclosuresToForms,
    onResendPackage,
  };
};

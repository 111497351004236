import React, { lazy, useEffect, useMemo, useRef, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles';
import { useIsMutating } from 'react-query';
import Typography from '@material-ui/core/Typography';
import { useNetworkStatus } from 'react-adaptive-hooks';
import { useHistory } from 'react-router';
import { WizardStepProps } from 'src/domain';
import { sendDisclosuresEnvelopeMutationKey } from 'src/hooks';
import { Step } from 'src/common';
import { useDisclosuresStore } from '../store';

const LottieAnimation = lazy(() => import('src/common/LottieAnimation'));

const useStyles = makeStyles((theme: Theme) => ({
  statusContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 200,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 340,
    },
  },
}));

const loadingStatuses = [
  'Uploading your documents...',
  'Gathering your contact info...',
  'Sending your documents...',
  "Dotting i's and crossing t's...",
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const DisclosuresEnvelopeSending = (props: WizardStepProps) => {
  const classes = useStyles();
  const store = useDisclosuresStore();
  const fileId = store.existingFileId ?? -1;
  const history = useHistory();
  const isSending = useIsMutating({ mutationKey: sendDisclosuresEnvelopeMutationKey });
  const intervalRef = useRef<number>();
  const [currentStatusIndex, setCurrentStatusIndex] = useState(0);
  const { effectiveConnectionType } = useNetworkStatus('4g');
  const currentStatus = loadingStatuses[Math.min(currentStatusIndex, loadingStatuses.length - 1)];

  const statusInterval = useMemo(() => {
    switch (effectiveConnectionType) {
      case '2g':
        return 3000;
      case 'slow-2g':
        return 4000;
      case '3g':
        return 2500;
      default:
        return 2000;
    }
  }, [effectiveConnectionType]);

  const clearStatusInterval = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = undefined;
  };

  useEffect(() => {
    // set up status change interval
    intervalRef.current = window.setInterval(() => {
      setCurrentStatusIndex((current) => ++current);
    }, statusInterval);

    return clearStatusInterval;
  }, [statusInterval]);

  useEffect(() => {
    // clear status change interval on final status
    if (currentStatusIndex > loadingStatuses.length - 1) {
      clearStatusInterval();
    }
  }, [currentStatusIndex]);

  useEffect(() => {
    // redirect after api call finishes and we show all statuses
    if (!isSending && currentStatusIndex === loadingStatuses.length) {
      history.push(`/file/${fileId}/packages`);
    }
  }, [isSending, currentStatusIndex, fileId, history]);

  return (
    <Step title={"You're almost done!"}>
      <div className={classes.statusContainer}>
        <LottieAnimation animation="surferBoi" />
      </div>
      <Typography variant="body2" className={classes.statusContainer}>
        {currentStatus}
      </Typography>
    </Step>
  );
};

import React from 'react';
import { makeStyles, Theme, Grid, Typography, Container } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      minHeight: theme.spacing(17.5),
      [theme.breakpoints.down('sm')]: {
        minHeight: theme.spacing(10),
        marginBottom: theme.spacing(3),
      },
      marginBottom: theme.spacing(8),
      backgroundColor: theme.colors.grey.offWhite1,
      backgroundImage: `linear-gradient(
        135deg, 
        #FFFFFF 50%, 
        ${theme.colors.grey.offWhite1} 10%, 
        ${theme.colors.mainColor.backgroundGradient}
      )`,
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
    },
    grid: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    image: {
      display: 'none',
      width: 187,
      height: 112,
      marginBottom: -44,
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
    textWrapper: {
      paddingTop: theme.spacing(2), // Optical centering
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        paddingLeft: theme.spacing(4),
      },
    },
    title: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.5rem',
      },
    },
    subtitle: {
      color: theme.colors.grey.label,
    },
    actions: {
      marginLeft: 'auto',
    },
  }),
  { classNamePrefix: 'gradientTitleBanner' }
);

export interface FilePackagesViewProps {
  children?: React.ReactNode;
  image?: React.ReactNode;
  title?: string;
  subtitle?: string;
  classes?: {
    root?: string;
    image?: string;
    text?: string;
    actions?: string;
  };
}

export const GradientTitleBanner: React.FC<FilePackagesViewProps> = ({
  children,
  image,
  title,
  subtitle,
  classes: userClasses = {},
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(userClasses.root, classes.root)}>
      <Container>
        <Grid item container alignItems="center" xs={12} wrap="wrap" className={classes.grid}>
          <Grid item className={clsx(userClasses.image, classes.image)}>
            {image}
          </Grid>
          <Grid item className={clsx(userClasses.text, classes.textWrapper)}>
            <Typography variant="h1" className={classes.title}>
              {title}
            </Typography>
            {subtitle && (
              <Typography variant="h5" className={classes.subtitle}>
                {subtitle}
              </Typography>
            )}
          </Grid>
          {children && (
            <Grid item className={clsx(userClasses.actions, classes.actions)}>
              {children}
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  );
};

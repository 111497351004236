import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Skeleton } from '@material-ui/lab';
import Typography from '@material-ui/core/Typography';
import { LinearProgress } from '@material-ui/core';
import { PackageStatus, PackageStatusType } from '../types';

export interface FilePackageItemStatusProps {
  status?: PackageStatus;
}

const useStyles = makeStyles<Theme, FilePackageItemStatusProps>((theme: Theme) => {
  const defaultProgressStyles = {
    height: theme.spacing(1),
    borderRadius: 20,
    backgroundColor: theme.colors.grey.offWhite3,
  };
  return {
    progress: {
      maxWidth: '100%',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 250,
      },
    },
    statusLabel: {
      fontWeight: 700,
      paddingRight: theme.spacing(0.5),
    },
    progressBarSection: {
      paddingTop: theme.spacing(1),
    },
    progressRoot: defaultProgressStyles,
    bar: (props: FilePackageItemStatusProps) => {
      switch (props.status?.type) {
        case PackageStatusType.uxLoading:
        case PackageStatusType.waitingForClientEnvelope: {
          return {
            ...defaultProgressStyles,
            backgroundColor: '#00BACF',
          };
        }
        case PackageStatusType.waitingForClientPackage:
          return {
            ...defaultProgressStyles,
            backgroundColor: '#F78812',
          };
        case PackageStatusType.waitingForAgent:
          return {
            ...defaultProgressStyles,
            backgroundColor: '#FCCA4E',
          };
        case PackageStatusType.terminated:
          return {
            ...defaultProgressStyles,
            backgroundColor: '#B5C5D6',
          };
        case PackageStatusType.complete:
          return {
            ...defaultProgressStyles,
            backgroundColor: '#00C27A',
          };
        default:
          return defaultProgressStyles;
      }
    },
  };
});

export const FilePackageItemStatus: React.FC<FilePackageItemStatusProps> = (props) => {
  const { status } = props;
  const classes = useStyles(props);

  const mapStatusForDisplay = (status: string | undefined) => {
    switch (status?.toLowerCase()) {
      case 'sent':
        return 'Awaiting response';
      case 'in progress':
        return 'Sent for signature';
      default:
        return status;
    }
  };

  return (
    <>
      {!status && (
        <>
          <Grid item xs={12}>
            <Skeleton width={100} />
          </Grid>
          <Grid item xs={12}>
            <Skeleton className={classes.progress} />
          </Grid>
        </>
      )}
      {!!status && (
        <>
          <Grid item xs={12} component={Typography} variant="subtitle1">
            <span className={classes.statusLabel}>STATUS:</span> {mapStatusForDisplay(status?.status)}
          </Grid>
          <Grid item xs={12} className={classes.progressBarSection}>
            <LinearProgress
              value={status.progress}
              variant={status.type === PackageStatusType.uxLoading ? 'indeterminate' : 'determinate'}
              className={classes.progress}
              classes={{
                bar: classes.bar,
                root: classes.progressRoot,
              }}
            />
          </Grid>
        </>
      )}
    </>
  );
};

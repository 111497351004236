import React, { ReactNode, ReactNodeArray } from 'react';
import { Container, Grid } from '@material-ui/core';
import { TayHeadingWithWorkflows, FixedHeaderLayout, GradientTitleBanner } from 'src/common';
import { WizardHeader } from 'src/domain';
import { useFlags } from 'src/hooks';
import HouseIcon from 'src/images/breezeFileHouse.svg';
import { Contact, EscrowOfficer } from 'src/lib';
import { FilePackage } from '../types';
import { FilePackageItem } from './FilePackageItem';
import { OpenEscrowCard } from './OpenEscrowCard';
import { EscrowIsPreOpenCard } from './EscrowIsPreOpenCard';

export interface FilePackagesViewProps {
  fileName: ReactNode;
  fileRepresentationType: string;
  packages: Partial<FilePackage>[];
  fileId: number;
  streetAddress: string | undefined;
  sellers: Contact[] | undefined;
  banner?: ReactNode | ReactNodeArray;
  onOpenEscrow?(): void;
  onChangeEscrowOfficer?(): void;
  onClearDefaultEscrowOfficer(): void;
  defaultEscrowOfficer?: EscrowOfficer;
  isLoading: boolean;
}

export const FilePackagesView: React.FC<FilePackagesViewProps> = ({
  fileId,
  fileName,
  fileRepresentationType,
  packages,
  streetAddress,
  sellers,
  banner,
  onOpenEscrow,
  onChangeEscrowOfficer,
  onClearDefaultEscrowOfficer,
  defaultEscrowOfficer,
  isLoading,
}) => {
  const { isEscrowOfficerEnabled } = useFlags();
  const escrowOfficerForFile = packages[0]?.workflowData?.escrowOfficer;
  const isOnlyAvidBuyerPackage =
    packages?.filter((p) => p.type === 'avid' && fileRepresentationType === 'Buyer').length === packages?.length;

  return (
    <FixedHeaderLayout headerContent={<WizardHeader disableBreezeBranding navHeaderType="package" />}>
      <GradientTitleBanner
        image={<img src={HouseIcon} alt="House" />}
        title={sellers
          ?.map((seller) => (seller.isEntity ? `${seller.companyName}` : `${seller.firstName} ${seller.lastName}`))
          .join(', ')}
        subtitle={streetAddress}
      />
      <Container>
        <Grid container spacing={4}>
          {banner && (
            <Grid item xs={12}>
              {banner}
            </Grid>
          )}
          {packages.length > 0 &&
            packages.map((filePackage, index) => (
              <Grid key={filePackage.id || index} item xs={12}>
                <FilePackageItem fileName={fileName?.toString()} filePackage={filePackage} />
              </Grid>
            ))}
          {/** Need to add a conditional to determine if the user has ever pre-opened
           * escrow in Breeze, and if not, render the OpenEscrowCard
           * */}
          {isEscrowOfficerEnabled && packages.length > 0 && !isOnlyAvidBuyerPackage && (
            <Grid item xs={12}>
              {escrowOfficerForFile ? (
                <EscrowIsPreOpenCard escrowOfficer={escrowOfficerForFile} />
              ) : (
                <OpenEscrowCard
                  isLoading={isLoading}
                  escrowOfficer={defaultEscrowOfficer}
                  onOpenEscrow={onOpenEscrow}
                  onChangeEscrowOfficer={onChangeEscrowOfficer}
                  onClearDefaultEscrowOfficer={onClearDefaultEscrowOfficer}
                />
              )}
            </Grid>
          )}
          {packages.length === 0 && (
            <TayHeadingWithWorkflows
              hasNoPackages
              navigationQueryParams={`?fileId=${fileId}`}
              title="Nothing here yet! How would you like to get started?"
            />
          )}
        </Grid>
      </Container>
    </FixedHeaderLayout>
  );
};

import React, { ChangeEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Container } from '@material-ui/core';
import { Navigation, Step, TextInput } from 'src/common';
import { WizardStepProps } from 'src/domain';
import { useTayTip } from 'src/domain/wizard/useTayTip';
import { useParcelNumberLanguageForStateStep } from 'src/pages/SpqTdsDisclosures/hooks/useParcelNumberLanguageForStateStep';
import { useDisclosuresStore } from '../store';
import { useDisclosuresConditionalSteps } from '../hooks/useDisclosuresConditionalSteps';
import { SpqTdsDisclosureSteps } from './constants';

interface Form {
  apn: string;
}

export const Apn = (props: WizardStepProps) => {
  const { goToStep } = props;
  const store = useDisclosuresStore();
  const { prevStep, nextStep } = useDisclosuresConditionalSteps(SpqTdsDisclosureSteps.apn, store.forms! ?? []);
  const { parcelNumberTayTip, parcelNumberStepTitle, parcelNumberPlaceholder } = useParcelNumberLanguageForStateStep(
    store?.property?.state ?? ''
  );

  useTayTip(parcelNumberTayTip);

  const formProps = useForm<Form>({
    mode: 'onTouched',
  });

  const [apnValue, setApnValue] = useState('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setApnValue(event.target.value);
  };

  const hasRequiredFields = !!store.apn || !!apnValue;

  return (
    <Step title={parcelNumberStepTitle}>
      <form
        onSubmit={formProps.handleSubmit((form) => {
          store.update(form);
          goToStep(nextStep!);
        })}
      >
        <Container maxWidth="xs">
          <TextInput
            name="apn"
            inputRef={formProps.register({
              required: true,
            })}
            defaultValue={store.apn}
            placeholder={parcelNumberPlaceholder}
            onChange={handleChange}
          />
        </Container>
        <Navigation
          PrevButtonProps={{ onClick: () => goToStep(prevStep) }}
          NextButtonProps={{
            disabled: Object.keys(formProps.errors).length > 0 || !hasRequiredFields,
          }}
        />
      </form>
    </Step>
  );
};

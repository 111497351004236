import React from 'react';
import {
  ButtonBase,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles<Theme, FlowNavigationCardProps>(
  (theme: Theme) => ({
    cardRoot: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'stretch',
      boxShadow: 'unset',
      border: `2px solid ${theme.colors.grey.disable}`,
      boxSizing: 'border-box',
      '&:hover': {
        boxShadow:
          '0 9px 12px 1px rgba(0,32,64,0.07), 0 3px 16px 2px rgba(0,32,64,0.06), 0 5px 6px -3px rgba(0,32,64,0.07)',
        border: `1px solid ${theme.colors.grey.disable}`,
      },
      cursor: 'pointer',
      flex: '1 0 250px',
      outline: undefined,
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'column',
      },
    },
    cardContent: {
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: 0,
      paddingLeft: 10,
      paddingRight: 0,
      maxWidth: 150,
      background: (p) => `linear-gradient(180deg, ${p.gradientStartColor} 0%, ${p.gradientEndColor} 100%)`,
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        maxWidth: 'unset',
      },
    },
    cardActionArea: {
      width: (p) => (!p.isFullWidth ? '100%' : 'unset'),
      flexGrow: 1,
      outline: 0,
    },
    cardActions: {
      padding: theme.spacing(2),
      justifyContent: 'flex-start',
      textAlign: (p) => (p.isFullWidth ? 'left' : 'center'),
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'center',
        padding: theme.spacing(3),
      },
    },
    cardText: {
      [theme.breakpoints.up('sm')]: {
        fontSize: 19,
      },
    },
  }),
  { classNamePrefix: 'flow-nav-card' }
);

export interface FlowNavigationCardProps {
  isFullWidth: boolean;
  children: React.ReactNode;
  onClick(): void;
  svg: React.ReactNode;
  gradientStartColor: string;
  gradientEndColor: string;
  id?: string;
  'data-testid'?: string;
}

export const FlowNavigationCard: React.FC<FlowNavigationCardProps> = (props) => {
  const { isFullWidth, children, onClick, svg, id, 'data-testid': testId } = props;
  const classes = useStyles(props);

  return (
    <Card id={id} component={ButtonBase} className={classes.cardRoot} onClick={onClick} tabIndex={0}>
      <CardContent className={classes.cardContent}>{svg}</CardContent>
      <CardActionArea component="div" className={classes.cardActionArea} tabIndex={-1}>
        <CardActions className={classes.cardActions} data-testid={testId}>
          <Typography className={classes.cardText} variant={isFullWidth ? 'body2' : 'h3'}>
            {children}
          </Typography>
        </CardActions>
      </CardActionArea>
    </Card>
  );
};

import { UseQueryOptions } from 'react-query';
import { GraphQLError } from 'graphql';
import { BreezeUserMeta, useGetUserMeta } from 'src/hooks/domain/queries/useGetUserMeta';
import { UserMetaRole } from '../../../lib';

export const useGetBreezeRole = (
  options: UseQueryOptions<BreezeUserMeta | undefined, GraphQLError, UserMetaRole> = {}
) =>
  useGetUserMeta<UserMetaRole>({
    ...options,
    select: (data) => data?.role ?? UserMetaRole.AGENT,
  });

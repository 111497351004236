import { useRecoilState } from 'recoil';
import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { newUserInviteAtom } from 'src/state';
import { useCustomSnackbar, useGetUserPermissionRequests } from 'src/hooks';

const snackbarKey = 'syncing-collab-key';

export const useTeamPagePermissionRequests = () => {
  const [inviteContext, setInviteContext] = useRecoilState(newUserInviteAtom);
  const snackbarController = useCustomSnackbar();

  useEffect(() => {
    if (inviteContext == null) return;

    snackbarController.addInfoSnackbar('Adding collaborators…', {
      persist: true,
      key: snackbarKey,
      preventDuplicate: true,
    });

    return () => snackbarController.closeSnackbar(snackbarKey);
  }, [inviteContext, snackbarController]);

  return useGetUserPermissionRequests({
    refetchInterval: inviteContext == null ? undefined : 1000,
    onSuccess(data) {
      const hasCollaborators = data.delegatedToUserRequests.length > 0 || data.delegatedFromUserRequests.length > 0;
      if (hasCollaborators) {
        setInviteContext(null);
        snackbarController.closeSnackbar(snackbarKey);
      }
    },
    onError(e) {
      console.error('An error occurred while getting team page permission requests');
      Sentry.captureException(e);
      snackbarController.closeSnackbar(snackbarKey);
    },
  });
};

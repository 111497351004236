import { AuthType } from './constants';

export class AuthProfileStatus {
  state: string;
  isAuthenticated: boolean;
  authType: AuthType;

  public constructor(init?: Partial<AuthProfileStatus>) {
    Object.assign(this, init);
  }
  checkAuth(predicate: (authType: AuthType) => boolean) {
    this.isAuthenticated = predicate(this.authType);
  }
}

import React, { useEffect, useState } from 'react';
import { useAuth } from '@skyslope/auth-react';

import { CenteredLoader } from 'src/common';
import { routes, useAuthStore } from 'src/lib';

const Logout: React.FC = () => {
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const { userManager, authState } = useAuth();
  const authStore = useAuthStore();

  useEffect(() => {
    if (isLoggingOut) {
      return;
    }
    if (authState.isAuthenticated) {
      setIsLoggingOut(true);
      userManager?.startLogout();
      authStore.reset();
      return;
    }
    userManager?.endLogout();

    setTimeout(() => {
      window.location.assign(routes.landing);
      setIsLoggingOut(false);
    }, 2000);

    // eslint-disable-next-line
    }, [authState]);

  return <CenteredLoader showOverlay />;
};

export default Logout;

/* eslint-disable */
// @ts-nocheck
import React, { useState, useEffect, useCallback } from 'react';
import makeAsyncScriptLoader from 'react-async-script';
import { Address, GoogleSearchResult, ManualSearchResult, MlsSearchResult, MultiSearchResult } from '../lib/types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  FormControl,
  TextField,
  Grid,
  Typography,
  makeStyles,
  MenuItem,
  Select,
  InputLabel,
  CircularProgress,
} from '@material-ui/core';
import { removeEmpty, sanitizeAddress, GOOGLE_PLACES_API_KEY } from 'src/lib';
import HouseIcon from '@material-ui/icons/House';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import debounce from 'lodash/debounce';
import parse from 'autosuggest-highlight/parse';
import * as Sentry from '@sentry/react';
import { TextInput } from 'src/common';
import { mlsGetListings, DEFAULT_MLS_RESULT_SIZE, MLSListing, convertSearchTermToSearchRequest } from '../lib/api/mls';
import { Controller, useController, useFormContext, useWatch } from 'react-hook-form';
import { getCountiesByState, getPlacesAddressById, usStates } from '../lib';
import { useFlags, useGetLiveRegions } from 'src/hooks';

const GoogleMapsUrl =
  `https://maps.googleapis.com/maps/api/js?v=3&key=${GOOGLE_PLACES_API_KEY}&client=gme-cinc&channel=skyslope&libraries=places`;

export interface MultiSourceAddressSearchProps {
  onGoogleAddressUpdate?: (searchResult: GoogleSearchResult, address: Address) => void;
  onMlsAddressUpdate?: (listing: MLSListing) => void;
  onUpdateManualAddress?: (manualResult: ManualSearchResult, address: Address) => void;
  onUnitUpdate: (unit: string) => void;
  defaultValue?: MultiSearchResult;
  states?: Array<string>;
  mlsResultSize?: number;
}

const autocompleteService = { current: null };

const useStyles = makeStyles((theme) => ({
  option: {
    padding: '8px 16px',
    fontSize: 18,
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  secondaryText: {
    fontSize: 16,
  },
  listingInfo: {
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 400,
  },
  mlsNumber: {
    fontSize: 16,
  },
  mlsProperty: {
    fontWeight: 400,
    fontSize: 16,
  },
  field: {
    paddingBottom: '21px',
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(2),
  },
  selectField: {
    paddingBottom: '21px',
    minWidth: '100%',
  },
  selectFieldSub: {
    '&>div': {
      paddingBottom: '21px',
      color: 'inherit',
      fontWeight: 600,
    },
  },
  error: {
    color: theme.colors.error,
    fontSize: '16px',
    marginTop: '4px',
    fontWeight: 700,
  },
}));

const MultiSourceAddressSearch: React.FC<MultiSourceAddressSearchProps> = (props: MultiSourceAddressSearchProps) => {
  const classes = useStyles();
  const [searchResult, setSearchResult] = useState<MultiSearchResult | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [options, setOptions] = useState<Array<MultiSearchResult>>([]);
  const liveRegions = useGetLiveRegions();

  const { register, errors, control, watch, setValue, trigger, getValues } = useFormContext();

  const { states } = props;

  // Allow supported regions to be overwritten if states was passed in. e.g. Avid flow only allows searching addresses in CA
  let supportedRegions = states !== null && states?.length > 0 ? states : liveRegions;

  const defaultAddress: Partial<Address> = {
    streetAddress: '',
    city: '',
    unitNumber: '',
    state: '',
    postalCode: '',
    county: '',
  };
  const property = useWatch<Address>({ control, name: 'property', defaultValue: defaultAddress });
  const counties = getCountiesByState(property?.state);
  const countyController = useController({
    name: 'property.county',
    control,
    defaultValue: '',
    rules: { required: 'Please select a county.' },
  });
  const { isMongoDbForMlsEnabled } = useFlags();

  useEffect(() => {
    if (props.defaultValue) {
      setSearchResult(props.defaultValue ?? '');
      setSearchTerm(props.defaultValue.address.streetAddress ?? '');
    }
  }, []);

  const performSearchQueries = useCallback(
    debounce(async (searchTerm: string, active: { current: boolean }) => {
      const { states, mlsResultSize = DEFAULT_MLS_RESULT_SIZE } = props;
      setSearchTerm(searchTerm);
      const searchRequest = convertSearchTermToSearchRequest(searchTerm);

      supportedRegions = states !== null && states?.length > 0 ? states : liveRegions;

      searchRequest.states = supportedRegions;
      searchRequest.resultSize = mlsResultSize;

      const mlsResults = await mlsGetListings(searchRequest, isMongoDbForMlsEnabled);
      let googleResults: GoogleSearchResult[];

      // The search term has changed or the component has been dismounted,
      // these results are no longer applicable, discard them.
      if (!active.current) return;

      // Only request the google results if MLS doesn't return us enough results so that
      // we don't go over our Google API query limit (see: INNO-720)
      if (mlsResults.length < 3) {
        let rawGoogleResults;
        try {
          rawGoogleResults = await autocompleteService?.current?.getPlacePredictions({
            input: searchTerm,
            types: ['address'],
            componentRestrictions: { country: 'us' },
          });
        } catch {
          rawGoogleResults = { predictions: [] };
        }

        googleResults =
          supportedRegions?.length > 0
            ? rawGoogleResults.predictions.filter((address) => {
                const addrState = address.structured_formatting.secondary_text.split(', ')[1];
                return supportedRegions.includes(addrState?.toUpperCase());
              })
            : rawGoogleResults.predictions;

        googleResults.forEach((result) => {
          result.matchType = 'Google Address';
          result.groupName = 'Google';
        });
      }

      // The search term has changed or the component has been dismounted,
      // these results are no longer applicable, discard them (this is
      // useful after each promise returns to avoid doing extra work).
      if (!active.current) return;

      let newOptions: Array<MultiSearchResult> = [...(mlsResults || []), searchResult, ...(googleResults || [])].filter(
        (x) => x
      );
      setOptions(newOptions);
    }, 500),
    [liveRegions]
  );

  useEffect(() => {
    const active = { current: true };

    async function doSearch() {
      if (!autocompleteService.current && window.google) {
        autocompleteService.current = new window.google.maps.places.AutocompleteService();
      }

      if (!autocompleteService.current) return;
      if (searchTerm === '') {
        setOptions(searchResult ? [searchResult] : []);
        return;
      }

      await performSearchQueries(searchTerm, active);
    }

    doSearch();
    return () => {
      active.current = false;
    };
  }, [searchResult, searchTerm, performSearchQueries, liveRegions]);

  const boldSection = (string: string, start: number, length: number) => (
    <>
      {string.substring(0, start)}
      <b>{string.substring(start, start + length)}</b>
      {string.substring(start + length)}
    </>
  );

  const renderMlsOption = (listing: MlsSearchResult) => {
    const mlsSearchStart = listing.mlsNumber.toLowerCase().indexOf(searchTerm.toLowerCase());
    const addrSearchStart = listing.propertyAddress.toLowerCase().indexOf(searchTerm.toLowerCase());
    let { mlsNumber, propertyAddress }: any = listing;

    if (listing.matchType === 'MLS #' && mlsSearchStart !== -1) {
      mlsNumber = boldSection(mlsNumber, mlsSearchStart, searchTerm.length);
    }
    if (listing.matchType === 'MLS Address' && addrSearchStart !== -1) {
      propertyAddress = boldSection(propertyAddress, addrSearchStart, searchTerm.length);
    }
    return (
      <Grid container alignItems="center">
        <Grid item>
          <HouseIcon className={classes.icon} color="inherit" />
        </Grid>
        <Grid item xs>
          <div className={classes.listingInfo}>
            <Typography variant="body1" className={classes.mlsNumber} color="inherit">
              MLS: {mlsNumber}
            </Typography>
            <Typography variant="body2" color="inherit" className={classes.mlsProperty}>
              {propertyAddress}
            </Typography>
          </div>
        </Grid>
      </Grid>
    );
  };

  const renderGoogleOption = (option: any) => {
    try {
      const matches = option.structured_formatting.main_text_matched_substrings;
      const parts = parse(
        option.structured_formatting.main_text,
        matches.map((match: any) => [match.offset, match.offset + match.length])
      );

      return (
        <Grid container alignItems="center">
          <Grid item>
            <LocationOnIcon className={classes.icon} color="inherit" />
          </Grid>
          <Grid item xs>
            {parts.map((part: any, index: number) => (
              <span key={index} style={{ fontWeight: part.highlight ? 700 : 400, color: 'inherit' }}>
                {part.text}
              </span>
            ))}

            <Typography variant="body1" color="inherit" className={classes.secondaryText}>
              {option.structured_formatting.secondary_text}
            </Typography>
          </Grid>
        </Grid>
      );
    } catch (err) {
      Sentry.captureException(err, { option });
      return null;
    }
  };

  const renderManualOption = (listing: ManualSearchResult) => {
    let { propertyAddress }: any = listing;
    return (
      <Grid container alignItems="center">
        <Grid item>
          <HouseIcon className={classes.icon} color="inherit" />
        </Grid>
        <Grid item xs>
          <div className={classes.listingInfo}>
            <Typography variant="body2" color="inherit" className={classes.mlsProperty}>
              {propertyAddress}
            </Typography>
          </div>
        </Grid>
      </Grid>
    );
  };

  const renderCombinedOption = (option: any) => {
    if (option.groupName === 'Google') {
      return renderGoogleOption(option);
    } else {
      if (option.groupName === 'Mls') {
        return renderMlsOption(option);
      } else {
        return renderManualOption(option);
      }
    }
  };

  const onAutocompleteChange = async (e: any, newValue: any) => {
    if (newValue == null || newValue === '') {
      setSearchResult(null);
      setSearchTerm('');
      return;
    }

    if (newValue.groupName === 'Google') {
      if (newValue?.place_id) {
        const address = (await getPlacesAddressById(newValue?.place_id)) as Address;
        newValue.address = address;
        if (props.onGoogleAddressUpdate) {
          props.onGoogleAddressUpdate(newValue, sanitizeAddress(address));
        }
      }
    }
    if (newValue.groupName === 'MLS') {
      // Getting street address from propertyAddress field since (for some reason) MLS doeas not include street, dr. etc
      // in the address.streetAddress field.
      const correctStreetAddress = newValue.propertyAddress.split(',')[0];
      if (correctStreetAddress) {
        newValue.address.streetAddress = correctStreetAddress;
      }
      if (props.onMlsAddressUpdate) {
        newValue.address = sanitizeAddress(newValue.address);
        props.onMlsAddressUpdate(newValue);
      }
      setSearchResult(newValue);
      setValue('property.streetAddress', newValue.address.streetAddress);
    }
  };

  const handleManualUpdate = (overrides) => {
    const purgedObject = removeEmpty(property);
    const address = defaultAddress;
    Object.assign(address, purgedObject, overrides);
    const manualResult: ManualSearchResult = {
      propertyAddress: address.streetAddress,
      address,
      matchType: 'Manual',
      groupName: 'Manual',
    };

    if (props.onUpdateManualAddress) {
      props.onUpdateManualAddress(manualResult, address);
    }
  };

  return (
    <React.Fragment>
      <TextInput style={{ display: 'none' }} name="property.streetAddress" inputRef={register({ required: true })} />
      {liveRegions ? (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <Autocomplete
              id="google-address-search"
              groupBy={(option) => option.groupName}
              classes={{ option: classes.option }}
              getOptionLabel={(option) => {
                if (option.groupName === 'Google') {
                  return option.structured_formatting.main_text;
                }
                if (option.groupName === 'MLS') {
                  return `${option.address.streetAddress}`;
                } else {
                  return `${option.address.streetAddress}`;
                }
              }}
              getOptionSelected={(option, value) => {
                if (option.groupName === 'Google') {
                  return option.description === value.description;
                }
                if (option.groupName === 'MLS') {
                  return option.mlsNumber === value.mlsNumber;
                } else {
                  return option === value;
                }
              }}
              options={options}
              autoComplete
              freeSolo
              includeInputInList
              value={searchResult}
              noOptionsText="No results found"
              onChange={onAutocompleteChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Address or MLS #"
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setValue('property.streetAddress', e.target.value);
                    handleManualUpdate({ streetAddress: e.target.value });
                  }}
                />
              )}
              renderOption={renderCombinedOption}
              filterOptions={(x) => x} // Need this to disable internal filtering
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              name="property.unitNumber"
              control={control}
              defaultValue=""
              rules={{ required: false }}
              render={({ onChange, value, onBlur }, { invalid }) => {
                if (!value) {
                  value = '';
                }
                return (
                  <TextInput
                    inputProps={{ className: classes.field }}
                    label="Apt/Unit #"
                    value={value}
                    autoComplete="off"
                    onChange={(e) => {
                      onChange(e);
                      handleManualUpdate({ unitNumber: e.target.value });
                    }}
                    onBlur={onBlur}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="property.city"
              control={control}
              defaultValue=""
              rules={{ required: 'Please enter a city.' }}
              render={({ onChange, value, onBlur }, { invalid }) => (
                <TextInput
                  label="City"
                  inputProps={{ className: classes.field }}
                  value={value}
                  autoComplete="off"
                  onChange={(e) => {
                    onChange(e);
                    handleManualUpdate({ city: e.target.value });
                  }}
                  onBlur={onBlur}
                />
              )}
            />
            {errors.property?.city && (
              <Typography variant="body1" className={classes.error}>
                {errors.property!.city.message}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={2}>
            <Controller
              name="property.state"
              control={control}
              defaultValue=""
              rules={{ required: 'Please select a state.' }}
              render={({ onChange, value, onBlur }, { invalid }) => (
                <FormControl error={invalid} variant="outlined" className={classes.selectField}>
                  <InputLabel id="state-select">State</InputLabel>
                  <Select
                    className={classes.selectFieldSub}
                    labelId="state-select"
                    value={value}
                    autoComplete="off"
                    onChange={(e) => {
                      countyController.field.onChange('');
                      handleManualUpdate({ state: e.target.value, county: '' });
                      onChange(e);
                    }}
                    onBlur={onBlur}
                    label="State"
                  >
                    {supportedRegions.map((state) => (
                      <MenuItem key={state} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {errors.property?.state && (
              <Typography variant="body1" className={classes.error}>
                {errors.property!.state.message}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={3}>
            <Controller
              name="property.postalCode"
              control={control}
              defaultValue=""
              rules={{ required: 'Please enter a postal code.' }}
              render={({ onChange, value, onBlur }, { invalid }) => (
                <TextInput
                  inputProps={{ className: classes.field }}
                  label="Postal Code"
                  value={value}
                  autoComplete="off"
                  onChange={(e) => {
                    onChange(e);
                    handleManualUpdate({ postalCode: e.target.value });
                  }}
                  onBlur={onBlur}
                />
              )}
            />
            {errors.property?.postalCode && (
              <Typography variant="body1" className={classes.error}>
                {errors.property!.postalCode.message}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl error={countyController.meta.invalid} variant="outlined" className={classes.selectField}>
              <InputLabel id="county-select">County</InputLabel>
              <Select
                className={classes.selectFieldSub}
                labelId="county-select"
                value={countyController.field.value}
                onChange={(e) => {
                  countyController.field.onChange(e);
                  handleManualUpdate({ county: e.target.value });
                }}
                onBlur={countyController.field.onBlur}
                label="County"
              >
                {counties.map((county) => (
                  <MenuItem key={county} value={county}>
                    {county}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errors.property?.county && (
              <Typography variant="body1" className={classes.error}>
                {errors.property!.county.message}
              </Typography>
            )}
          </Grid>
        </Grid>
      ) : (
        <div className={classes.spinner}>
          <CircularProgress />
        </div>
      )}
    </React.Fragment>
  );
};

const asyncMultiSourceAddressSearch = makeAsyncScriptLoader(GoogleMapsUrl)(MultiSourceAddressSearch);
export { asyncMultiSourceAddressSearch as MultiSourceAddressSearch };

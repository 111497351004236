import React, { useMemo } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, Box, BoxProps, decomposeColor, useTheme } from '@material-ui/core';

const useStyles = makeStyles<Theme, PaperProps>(
  () => ({
    // Shadow palette generated with: https://www.joshwcomeau.com/shadow-palette/
    // Note: Color is converted from hsl -> rgb
    root: {
      '--shadow-elevation-low': `
        0.2px 0.5px 0.6px rgb(var(--shadow-color), 0.34),
        0.3px 0.8px 1px -1.3px rgb(var(--shadow-color), 0.34),
        0.8px 1.9px 2.3px -2.5px rgb(var(--shadow-color), 0.33)`,
      '--shadow-elevation-medium': `
        0.2px 0.5px 0.6px rgb(var(--shadow-color), 0.36),
        0.7px 1.6px 2px -0.8px rgb(var(--shadow-color), 0.35),
        1.6px 3.9px 4.7px -1.7px rgb(var(--shadow-color), 0.35),
        4px 9.5px 11.5px -2.5px rgb(var(--shadow-color), 0.35)`,
      '--shadow-elevation-high': `
        0.2px 0.5px 0.6px rgb(var(--shadow-color), 0.33),
        1.1px 2.7px 3.3px -0.4px rgb(var(--shadow-color), 0.33),
        2.1px 5px 6.1px -0.7px rgb(var(--shadow-color), 0.33),
        3.5px 8.3px 10.1px -1.1px rgb(var(--shadow-color), 0.33),
        5.6px 13.3px 16.1px -1.5px rgb(var(--shadow-color), 0.32),
        8.8px 20.8px 25.2px -1.8px rgb(var(--shadow-color), 0.32),
        13.4px 31.7px 38.5px -2.2px rgb(var(--shadow-color), 0.32),
        19.8px 46.8px 56.8px -2.5px rgb(var(--shadow-color), 0.32)`,
      transition: 'box-shadow 250ms ease',
    },
    constantShadow: {
      boxShadow: 'var(--shadow)',
    },
    hoverShadow: {
      '&:hover': {
        boxShadow: 'var(--hover-shadow)',
      },
    },
  }),
  { classNamePrefix: 'Paper' }
);

type Elevation = 'low' | 'medium' | 'high';
export interface PaperProps extends BoxProps {
  /** The color of the shadow */
  shadowColor?: string;
  /** The shadow elevation present when the element is not being interacted with */
  elevation?: null | Elevation;
  /** The shadow elevation present when the element is being hovered */
  hoverElevation?: null | Elevation;
}

export const Paper: React.FC<PaperProps> = (props) => {
  const classes = useStyles(props);
  const { children, className, style = {}, elevation, hoverElevation, shadowColor: color, ...rest } = props;
  const theme = useTheme();

  const decomposedColor = useMemo(() => decomposeColor(color || theme.colors.mainColor.primary).values, [color, theme]);

  return (
    <Box
      {...rest}
      style={
        {
          ...style,
          '--shadow-color': decomposedColor,
          '--shadow': `var(--shadow-elevation-${elevation})`,
          '--hover-shadow': `var(--shadow-elevation-${hoverElevation})`,
        } as React.CSSProperties
      }
      className={clsx(className, classes.root, {
        [classes.constantShadow]: elevation != null,
        [classes.hoverShadow]: hoverElevation != null,
      })}
    >
      {children}
    </Box>
  );
};

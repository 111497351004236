import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useRecoilState } from 'recoil';
import { InputAdornment } from '@material-ui/core';
import { SearchSharp } from '@material-ui/icons';
import { customBannerAtom } from 'src/state/customBannerAtom';
import { TextInput } from 'src/common';
import { useGetUserProfile, useTeamMembers, useCustomSnackbar, useFilter } from 'src/hooks';
import { selectedAgentAtom, AgentProfile } from 'src/state';
import { AuthType, capitalize, isUserAuthenticatedForType } from 'src/lib';
import { useGetUserProfileMeta } from 'src/hooks/domain/queries/useGetUserProfileMeta';
import { SelectAgentListItem } from './SelectAgentListItem';

export interface SelectAgentListProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  classes?: {
    root?: string;
    listItem?: string;
    selfListItem?: string;
    searchBar?: string;
  };
  onChooseAgent?(agent: AgentProfile | null): void;
}

export const SelectAgentList: React.FC<SelectAgentListProps> = (props) => {
  const { className, classes = {}, onChooseAgent, ...rest } = props;

  const { data: userProfile, isLoading: isUserLoading } = useGetUserProfile();
  const { data: teamMembers, isLoading: isTeamLoading } = useTeamMembers();
  const [selectedAgent, setSelectedAgent] = useRecoilState(selectedAgentAtom);
  const snackbarController = useCustomSnackbar();
  //TODO: make this more generic
  const { data: meta, refetch: refetchCarCreds } = useGetUserProfileMeta();
  const isCarAuthenticated = isUserAuthenticatedForType(meta, AuthType.CaNrds);
  const [showBanner, setShowBanner] = useRecoilState(customBannerAtom);
  const [filterText, setFilterText] = useState('');

  const handleChooseAgent = async (agent: AgentProfile | null) => {
    showBanner && setShowBanner(false);
    setSelectedAgent(agent);
    onChooseAgent?.(agent);

    if (agent) {
      snackbarController.addInfoSnackbar(
        `You are now viewing ${capitalize(agent.firstName)} ${capitalize(agent.lastName)}'s profile.`
      );
    } else {
      snackbarController.addInfoSnackbar('You are now viewing your profile.');
    }
  };

  useEffect(() => {
    if (selectedAgent) {
      refetchCarCreds();
      if (!isCarAuthenticated) {
        setShowBanner(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAgent, isCarAuthenticated]);

  const filteredTeamMembers = useFilter({
    filterText,
    searchKeys: ['email', 'firstName', 'lastName'],
    data: teamMembers,
  });

  if (!userProfile || !teamMembers) return null;

  return (
    <div {...rest} role="radiogroup" className={clsx(className, classes.root)}>
      <TextInput
        className={classes.searchBar}
        placeholder="Search Your Team"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchSharp />
            </InputAdornment>
          ),
          disableUnderline: true,
        }}
        variant="standard"
      />
      {!isUserLoading && (
        <SelectAgentListItem
          firstName={userProfile.firstName}
          lastName={userProfile.lastName}
          email={userProfile.email}
          checked={!selectedAgent}
          onChange={() => handleChooseAgent(null)}
          isCurrentUser
          value=""
          className={clsx(classes.listItem, classes.selfListItem)}
        />
      )}
      {!isTeamLoading &&
        filteredTeamMembers.map((tm) => (
          <SelectAgentListItem
            key={tm.email}
            firstName={tm.firstName!}
            lastName={tm.lastName!}
            email={tm.email!}
            checked={selectedAgent?.email === tm.email}
            onChange={() => handleChooseAgent(tm)}
            value={tm.email!}
            className={classes.listItem}
          />
        ))}
    </div>
  );
};

import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, Typography, Button, ButtonProps, IconButtonProps, IconButton } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import { HtmlToolTip } from 'src/common';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    sectionRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: 19,
      fontWeight: 600,
      padding: theme.spacing(3, 4),
      border: `1px solid ${theme.colors.grey.disable}`,
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      [theme.breakpoints.down('xs')]: {
        padding: '16px',
        alignItems: 'flex-start',
      },
    },
    labelColumn: {
      [theme.breakpoints.up('sm')]: {
        width: '100%',
        display: 'flex',
      },
      [theme.breakpoints.down('sm')]: {
        overflow: 'hidden',
      },
    },
    contentColumn: {
      display: 'block',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      width: '100%',
      padding: theme.spacing(0, 4),
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      [theme.breakpoints.down('xs')]: {
        padding: 0,
        marginBottom: theme.spacing(2),
      },
    },
    headerText: {
      color: theme.colors.grey.label,
      fontWeight: 'normal',
      display: 'flex',
      alignItems: 'center',
      width: 200,
      [theme.breakpoints.down('xs')]: {
        paddingBottom: theme.spacing(2),
      },
    },
    icon: {
      width: 150,
      paddingRight: theme.spacing(5),
    },
    labelText: {
      display: 'flex',
      alignItems: 'center',
      width: 150,
    },
    hideShowIconButton: {
      marginRight: theme.spacing(2),
      backgroundColor: theme.colors.grey.offWhite2,
      maxHeight: 30,
    },
    rotate180: {
      transform: 'rotateZ(180deg)',
    },
    infoIcon: {
      marginLeft: theme.spacing(2),
    },
  }),
  { classNamePrefix: 'ReviewRow' }
);

const ReviewRowButton: React.FC<ButtonProps> = (props) => {
  const { style = {}, ...rest } = props;
  return (
    <Button {...rest} variant="contained" size="small" disableElevation style={{ ...style, alignSelf: 'center' }} />
  );
};

const ReviewRowExpandButton: React.FC<IconButtonProps & { isExpanded?: boolean }> = (props) => {
  const classes = useStyles();
  const { isExpanded, ...rest } = props;

  return (
    <IconButton {...rest} className={classes.hideShowIconButton} size="small">
      <KeyboardArrowDown className={clsx({ [classes.rotate180]: isExpanded })} />
    </IconButton>
  );
};

export function ReviewRow(
  props: React.HtmlHTMLAttributes<HTMLDivElement> & {
    label: React.ReactNode;
    children: React.ReactNode;
    rightSideContent?: React.ReactNode;
    tooltipText?: string;
  }
) {
  const classes = useStyles();
  const { label, children, rightSideContent, tooltipText, className, ...rest } = props;

  return (
    <article {...rest} className={clsx(className, classes.sectionRow)}>
      <section className={classes.labelColumn}>
        <div className={classes.labelText}>
          <Typography variant="body2" className={classes.headerText}>
            {label}
            {tooltipText && <HtmlToolTip placement="top" title={tooltipText} className={classes.infoIcon} />}
          </Typography>
        </div>

        <div className={classes.contentColumn}>{children}</div>

        {rightSideContent}
      </section>
    </article>
  );
}

ReviewRow.Button = ReviewRowButton;
ReviewRow.ExpandButton = ReviewRowExpandButton;

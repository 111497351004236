import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import { useCustomSnackbar, useInviteTeamMember, useGetUserProfile } from 'src/hooks';
import { ContactCollectionStep, ContactsForm } from 'src/domain';
import { contactTypes, ErrorCodes } from 'src/lib';
import { WizardStepProps } from '../../Wizard';

export interface AddTeamMemberStepProps extends WizardStepProps {
  previousStepName: string;
  title?: string;
}

const getBlankContact = () => ({ type: contactTypes.OTHER });

/**
 * If the user the TC would like to work on behalf of is not currently part of
 * their team, on the work on behalf of page they can select "add team member"
 * and be brought to this page where they can add one or many team members.
 */
export const AddTeamMemberStep: React.FC<AddTeamMemberStepProps> = (props) => {
  const { goToStep, previousStepName, title, ...rest } = props;

  const { mutateAsync: inviteTeamMember, isLoading: isSubmitting } = useInviteTeamMember();
  const snackbarController = useCustomSnackbar();

  const { data: userProfile } = useGetUserProfile();
  const [emailValidationMessage, setEmailValidationMessage] = useState('');

  const handleSubmit = async (form: ContactsForm) => {
    try {
      // TODO: This is currently ignoring the first and last name that the user
      // enters, but our API does not take the data for first name and last
      // name currently.
      const delegateEmails = form.contacts.map((contact) => contact.email!);
      await inviteTeamMember({ delegateEmails });

      goToStep(previousStepName);
    } catch (e) {
      const plural = form.contacts.length > 1 ? 's' : '';
      console.error(e);
      const eventId = Sentry.captureException(e);
      snackbarController.addErrorSnackbar({
        message: `Failed to send your invite${plural}`,
        errorCode: ErrorCodes.SendInvite,
        eventId,
      });
    }
  };

  return (
    <ContactCollectionStep
      {...rest}
      title={title || 'Who would you like to invite to your team?'}
      defaultContacts={[getBlankContact()]}
      getBlankContact={getBlankContact}
      onSubmit={handleSubmit}
      isTeamMember
      requiredFields={{
        firstName: false,
        lastName: false,
        email: true,
      }}
      NavigationProps={{
        isLoading: isSubmitting,
        PrevButtonProps: { onClick: () => goToStep(previousStepName), children: 'Cancel', startIcon: null },
        NextButtonProps: { children: 'Save', endIcon: null },
      }}
      validators={{
        email: (val) => {
          if (val === userProfile?.email) {
            setEmailValidationMessage(
              'Looks like this contact is already a part of your team. Please enter a new contact.'
            );
            return '';
          } else {
            setEmailValidationMessage('');
            return true;
          }
        },
      }}
      validationMessages={{ email: emailValidationMessage }}
    />
  );
};

import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Navigation, Step, TextInput } from 'src/common';
import { useUpdateUserProfile, useGetUserProfile } from 'src/hooks';

const useStyles = makeStyles(() => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    marginTop: 24,
  },
}));

type Inputs = {
  dre: string;
};

export interface LicenseNumberProps {
  onNext: () => void;
}

export const LicenseNumber = (props: LicenseNumberProps) => {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm({ mode: 'onTouched' });
  const { mutate: updateUser, isError } = useUpdateUserProfile();

  const { data: userProfile } = useGetUserProfile();

  const onSubmit = async (data: Inputs) =>
    updateUser(
      { licenseNumber: data.dre },
      {
        onSuccess: () => {
          props.onNext();
        },
      }
    );

  return (
    <Step title="Please provide your license number. This will be part of your email signature.">
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <TextInput
          autoFocus
          label="License Number"
          name="dre"
          inputRef={register({
            required: 'Please enter this field.',
          })}
          error={errors.dre}
          className={classes.input}
          defaultValue={userProfile?.licenseNumber}
        />
        {isError && <Typography>{'Sorry, we were unable to update your user profile information.'}</Typography>}
        <Navigation
          PrevButtonProps={{ hidden: true }}
          NextButtonProps={{
            disabled: Object.keys(errors).length > 0,
          }}
        />
      </form>
    </Step>
  );
};

import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, CheckboxProps, Typography, Tooltip } from '@material-ui/core';
import { useId } from 'src/hooks';
import { SelectableButton, InitialsCircle, SrOnly } from 'src/common';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      borderBottom: `1px solid ${theme.colors.grey.disable}`,
      '&:first-of-type': {
        borderTop: `1px solid ${theme.colors.grey.disable}`,
      },
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
      '&:hover': {
        background: theme.colors.grey.offWhite1,
      },

      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'flex-start',
    },
    contents: {
      // display: 'flex',
      justifyContent: 'flex-start',
      textAlign: 'left',
      gap: theme.spacing(3),
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
    },
    textWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    text: {
      fontSize: '1rem',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    email: {
      color: theme.colors.grey.label,
    },
  }),
  { classNamePrefix: 'SelectAgentListItem' }
);

export interface SelectAgentListItemProps extends Omit<CheckboxProps, 'onChange'> {
  firstName: string | undefined;
  lastName: string | undefined;
  email: string | undefined;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  /** This "agent" is actually the current user */
  isCurrentUser?: boolean;
  value: string | undefined;
}

/** One of the Agents that are available for selection */
export const SelectAgentListItem: React.FC<SelectAgentListItemProps> = (props) => {
  const { checked, firstName, lastName, email, onChange, isCurrentUser, value, inputProps } = props;
  const classes = useStyles({ ...props, checked });
  const titleId = useId('SelectAgentListItem-title');

  const fullName = `${firstName} ${lastName} ${isCurrentUser ? '(You)' : ''}`;

  return (
    // When you put an `<input />` inside of a `<label>` the browser
    // will treat clicking on the label as a click on the input.  We take
    // advantage of that fact here by making the root element a label, and
    // allow the `checked` and `onChange` props to be handled in the same
    // way as they would be natively.
    <SelectableButton className={classes.root} aria-labelledby={titleId} component="label" checked={checked}>
      <SrOnly>
        <input {...inputProps} type="radio" checked={checked} onChange={onChange} name="select-agent" value={value} />
      </SrOnly>
      <div className={classes.contents}>
        <InitialsCircle
          firstName={firstName}
          lastName={lastName}
          checked={checked}
          size="small"
          style={{ alignSelf: 'center' }}
        />
        <div className={classes.textWrapper}>
          <Text bold id={titleId}>
            {fullName}
          </Text>
          <Text className={classes.email}>{email ?? ''}</Text>
        </div>
      </div>
    </SelectableButton>
  );
};

type DivProps = Omit<React.HtmlHTMLAttributes<HTMLDivElement>, 'color' | 'children'>;
type TextProps = DivProps & { bold?: boolean; children: string };

function Text(props: TextProps) {
  const classes = useStyles();
  const { children, bold, className, ...rest } = props;

  return (
    <Tooltip title={children.length >= 30 ? children : ''} enterDelay={1000}>
      <Typography {...rest} className={clsx(classes.text, className)} style={{ fontWeight: bold ? 'bold' : undefined }}>
        {children}
      </Typography>
    </Tooltip>
  );
}

import React from 'react';
import clsx from 'clsx';
import { CheckboxProps, makeStyles, Theme } from '@material-ui/core';
import { InitialsCircle, SrOnly } from 'src/common';
import { EscrowOfficer } from 'src/lib';
import { EscrowTitleCompanyLogo } from './EscrowTitleCompanyLogo';
import { EscrowOfficerCard, EscrowOfficerCardProps } from './EscrowOfficerCard';

const useStyles = makeStyles(
  (theme: Theme) => ({
    button: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',

      '[data-whatintent="keyboard"] &:focus-within': {
        outline: 'var(--default-outline)',
      },
    },
    content: {
      width: '100%',
      padding: theme.spacing(3),
      overflow: 'hidden',
      minHeight: theme.spacing(14),
      boxSizing: 'border-box',
      flexGrow: 1,
      display: 'flex',
      gap: theme.spacing(2),
      alignItems: 'center',
    },
    text: {
      textOverflow: 'ellipsis',
      overflowWrap: 'anywhere',
    },
    title: {
      fontSize: theme.typography.pxToRem(19),
      fontWeight: 'bold',
      color: theme.colors.grey.bodyHeader,
    },
    subtitle: {
      color: theme.colors.grey.label,
    },
    company: {
      borderTop: `1px solid ${theme.colors.grey.disable}`,
      backgroundColor: theme.colors.grey.offWhite2,
      padding: `${theme.spacing(3)}px ${theme.spacing(6)}px`,
      alignSelf: 'stretch',
      display: 'flex',
      justifyContent: 'center',
    },
  }),
  { classNamePrefix: 'EscrowOfficerListItem' }
);

interface EscrowOfficerListItemProps extends Omit<EscrowOfficerCardProps, 'children' | 'onChange'> {
  escrowOfficer?: EscrowOfficer;
  checked?: boolean;
  onChange?(checked?: boolean): void;
  value?: string | undefined;
  inputProps?: CheckboxProps['inputProps'];
  isLoading?: boolean;
}

export const EscrowOfficerListItem: React.FC<EscrowOfficerListItemProps> = (props) => {
  const classes = useStyles();
  const { checked, escrowOfficer, onChange, inputProps, value, isLoading, tabIndex, ...rest } = props;
  const { firstName, lastName, fullName, email, brand, image, city, state } = escrowOfficer || {};

  return (
    <EscrowOfficerCard
      {...rest}
      isLoading={isLoading}
      checked={checked}
      classes={{ button: classes.button }}
      onClick={() => onChange?.(true)}
      SelectableButtonProps={{ tabIndex: -1 }}
    >
      <div className={classes.content}>
        <SrOnly>
          <input
            {...inputProps}
            type="radio"
            checked={checked}
            onChange={(e) => onChange?.(e.target.checked)}
            name="select-escrow-officer"
            value={value}
            tabIndex={tabIndex}
          />
        </SrOnly>
        <InitialsCircle firstName={firstName} lastName={lastName} size="medium" image={image} />
        <div>
          <div className={clsx(classes.text, classes.title)}>{fullName}</div>
          <div className={clsx(classes.text, classes.subtitle)}>{email}</div>
          <div className={clsx(classes.text, classes.subtitle)}>
            {city && state ? `${city}, ${state}` : state ? state : ''}
          </div>
        </div>
      </div>
      <div className={classes.company}>
        <EscrowTitleCompanyLogo brand={brand} brandLogo={escrowOfficer?.brandLogo} />
      </div>
    </EscrowOfficerCard>
  );
};

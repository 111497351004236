import { useMutation, UseMutationOptions } from 'react-query';
import { gql } from 'graphql-request';
import { getGraphQLClient, EscrowOfficer, getApiToken } from '../../../lib';

const mutationQuery = gql`
  mutation addCustomEscrowOfficer($payload: CustomEscrowOfficer!) {
    addCustomEscrowOfficer(payload: $payload) {
      escrowOfficer {
        fullName
        firstName
        lastName
        email
        brand
        phoneNumber
      }
      isExisting
    }
  }
`;

export type AddCustomEscrowOfficerPayload = EscrowOfficer & {
  onBehalfOf?: string;
};

interface AddCustomEscrowOfficerResponse {
  escrowOfficer: EscrowOfficer;
  isExisting: boolean;
}

export const useAddCustomEscrowOfficer = (
  options: UseMutationOptions<AddCustomEscrowOfficerResponse, unknown, AddCustomEscrowOfficerPayload> = {}
) =>
  useMutation<AddCustomEscrowOfficerResponse, unknown, AddCustomEscrowOfficerPayload>(
    async (variables: AddCustomEscrowOfficerPayload) => {
      const client = getGraphQLClient(await getApiToken());
      return (
        await client.request<{
          addCustomEscrowOfficer: AddCustomEscrowOfficerResponse;
          payload: AddCustomEscrowOfficerPayload;
        }>(mutationQuery, { payload: variables })
      ).addCustomEscrowOfficer;
    },
    options
  );

import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core';
import { useIsMobile } from 'src/hooks';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      border: 'none',
      fontSize: 'inherit',
      fontFamily: 'inherit',
      position: 'relative',
      resize: 'none',
      overflowY: 'auto',
      outline: 'none !important',

      [theme.breakpoints.down('xs')]: {
        // Let the auto sizing onInput() take over
        overflowY: 'hidden',
      },
    },
  }),
  { classNamePrefix: 'EmailMetaInput' }
);

export interface EmailMetaInputProps extends Omit<React.InputHTMLAttributes<HTMLTextAreaElement>, 'onChange'> {
  gridArea?: string;
  inputRef?: React.Ref<HTMLTextAreaElement>;
  value?: string;
  onChange?(val: string): void;
}

export const EmailMetaInput: React.FC<EmailMetaInputProps> = (props: EmailMetaInputProps) => {
  const classes = useStyles();
  const { gridArea, inputRef, className, onChange, style = {}, ...rest } = props;
  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <textarea
        {...rest}
        rows={1}
        className={clsx(classes.root, className)}
        ref={inputRef}
        style={{ ...style, gridArea }}
        onChange={(e) => {
          const inputEl = e.target as HTMLTextAreaElement;
          onChange?.(inputEl.value);
        }}
        onInput={(e) => {
          if (!isMobile) return;
          const inputEl = e.target as HTMLTextAreaElement;
          inputEl.style.height = 'auto';
          inputEl.style.height = `${inputEl.scrollHeight}px`;
        }}
      />
    );
  }

  return (
    <input
      {...(rest as unknown)}
      type="text"
      className={clsx(classes.root, className)}
      ref={inputRef as never}
      style={{ ...style, gridArea }}
      onChange={(e) => {
        const inputEl = e.target as unknown as HTMLTextAreaElement;
        onChange?.(inputEl.value);
      }}
    />
  );
};

import React, { ReactNode, ReactNodeArray } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles<Theme, RoomItemProps>(
  (theme: Theme) => ({
    root: {
      position: 'relative',
    },
    room: (props: RoomItemProps) => ({
      borderRadius: `${theme.spacing(1)}px`,
      border: `solid 2px ${props.isSelected ? theme.colors.mainColor.primary : theme.colors.grey.disable}`,
      padding: theme.spacing(2),
      backgroundColor: 'white',
      zIndex: 500,
      cursor: props.onClick ? 'pointer' : undefined,
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(1),
        textAlign: 'center',
      },
    }),
    roomIcon: (props) => ({
      color: props.isSelected ? theme.colors.mainColor.primary : theme.colors.grey.bodyHeader,
      fill: props.isSelected ? theme.colors.mainColor.primary : theme.colors.grey.bodyHeader,
      width: theme.spacing(6),
      height: theme.spacing(6),
      marginRight: theme.spacing(3),
      backgroundColor: props.isSelected ? theme.colors.mainColor.hoverLight : theme.colors.grey.offWhite3,
      borderRadius: '50%',
      [theme.breakpoints.up('sm')]: {
        margin: 'auto',
      },
    }),
    iconContainer: {
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(3),
      },
    },
    collapsedItemRoot: {
      marginTop: -10,
    },
    collapsedItem: {
      height: 20,
      backgroundColor: theme.colors.grey.offWhite3,
      borderRadius: `${theme.spacing(1)}px`,
      border: `solid 2px ${theme.colors.grey.disable}`,
    },
    display: (props) => ({
      color: props.isSelected ? theme.colors.mainColor.primary : undefined,
    }),
    actions: {
      minHeight: theme.spacing(5),
    },
    completeIcon: {
      marginRight: 0,
      color: theme.colors.mainColor.primary,
      fill: theme.colors.mainColor.primary,
    },
    completeIconWrapper: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      borderRadius: '50%',
      border: `1px solid ${theme.colors.grey.disable}`,
      position: 'absolute',
      right: 2,
      top: 2,
      zIndex: 9999,
      backgroundColor: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        left: 2,
      },
    },
    arrowIcon: {
      height: 16,
      width: 16,
      color: theme.colors.grey.bodyHeader,
    },
  }),
  { classNamePrefix: 'RoomItem' }
);

interface RoomItemProps {
  display: string;
  onClick?: () => void;
  icon: ReactNode;
  numHidden?: number;
  actions?: ReactNode | ReactNodeArray;
  isSelected?: boolean;
  isComplete?: boolean;
  'data-testid'?: string;
}

export const RoomItem: React.FC<RoomItemProps> = (props) => {
  const { actions, display, onClick, icon, numHidden, isComplete, 'data-testid': testId } = props;
  const classes = useStyles(props);

  return (
    <Grid item container className={classes.root} xs={12} sm={3} onClick={onClick} data-testid={testId}>
      {isComplete && (
        <div className={classes.completeIconWrapper}>
          <CheckIcon className={classes.completeIcon} />
        </div>
      )}
      <Grid item container className={classes.room} alignItems="center">
        <Grid item sm={12} className={classes.iconContainer}>
          <Grid item container className={classes.roomIcon} justifyContent="center" alignItems="center">
            {icon}
          </Grid>
        </Grid>
        <Box flexShrink={1} flexBasis="auto" flexGrow={1}>
          <Typography className={classes.display} variant="body2">
            {display}
          </Typography>
        </Box>
        <Grid sm={12} item className={classes.actions}>
          {actions}
        </Grid>
      </Grid>
      {!!numHidden &&
        Array.from({ length: numHidden }).map((_, i) => (
          <Grid key={i} className={classes.collapsedItemRoot} xs={12} item container>
            <Grid item className={classes.collapsedItem} xs={12} style={{ zIndex: i * -10 }} />
          </Grid>
        ))}
    </Grid>
  );
};

import React from 'react';
import { Typography, Container, makeStyles, MenuItem, Theme, Divider, Menu } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import { FixedHeaderLayout, GradientTitleBanner, LoaderButton } from 'src/common';
import { WizardHeader } from 'src/domain';
import { ReactComponent as MyTeamImage } from 'src/images/myTeam.svg';
import { useAnchorEl, useIsMobile } from 'src/hooks';
import { EmailCollectionDialog } from './EmailCollectionDialog';
import { Collaborator } from './Collaborator';
import { ActionType } from './types';

const useStyles = makeStyles((theme: Theme) => ({
  // The -90px here pulls the image left so that the shadow that's part of
  // the image doesn't affect the image's alignment with the rest of the page.
  headerImage: {
    marginLeft: -90,
  },
  dropdown: {
    marginTop: theme.spacing(1),
    borderRadius: 6,
    boxShadow:
      '0 9px 12px 1px rgba(0,89,218,0.07), 0 3px 16px 2px rgba(0,89,218,0.06), 0 5px 6px -3px rgba(0,89,218,0.07)',
    [theme.breakpoints.down('xs')]: {
      zIndex: 3,
      display: 'flex',
      position: 'fixed',
      bottom: 0,
      left: '0 !important',
      right: 0,
      top: 'auto !important',
      flexDirection: 'column',
      marginBottom: 0,
      maxWidth: '100%',
      maxHeight: 140,
    },
  },
  menuItem: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  actionButtonFooter: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      position: 'fixed',
      bottom: 0,
      height: 50,
      zIndex: 2,
      left: 0,
      right: 0,
      borderTop: `1px solid ${theme.colors.grey.disable}`,
      backgroundColor: theme.colors.grey.offWhite3,
      padding: theme.spacing(2),
      marginBottom: 0,
    },
  },
  actionButton: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: theme.spacing(1.5),
    },
  },
  collaborators: {
    fontSize: '1.5rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.1875rem',
    },
  },
  divider: {
    backgroundColor: theme.colors.grey.disable,
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: 100,
    },
  },
}));

export interface TeamPageUIProps {
  /** Callback triggered when the user submits the email collection dialog */
  onSendInvitations(email: string[]): void;
  isLoading: boolean;
  /** A list of users who are on the current user's team */
  children: React.ReactNode;
  /**
   * Perform an action or check prior to the email collection dialog being shown.
   * If this returns `false`, the email collection dialog will not be shown.
   */
  onPrimaryCTAClick(actionType: ActionType): void;
  isPrimaryCTALoading?: boolean;
  isEmailCollectionDialogOpen: boolean;
  setIsEmailCollectionDialogOpen(open: boolean): void;
  isSendingEmails: boolean;
  showCTA: boolean;
  actionType: ActionType | null;
  collaboratorCount: number | null;
}

/**
 * Presentational component for displaying the My Team page.
 * Allows different list of team members to be passed in as children.
 */
export const TeamPageUI: React.FC<TeamPageUIProps> = (props) => {
  const classes = useStyles();
  const {
    onSendInvitations,
    isLoading,
    children,
    onPrimaryCTAClick,
    isEmailCollectionDialogOpen,
    setIsEmailCollectionDialogOpen,
    isSendingEmails,
    showCTA,
    actionType,
    collaboratorCount,
  } = props;

  const isMobile = useIsMobile();
  const { anchorEl, isOpen, close, handleClick } = useAnchorEl();

  const handleSendInvitations = (emails: string[]) => {
    onSendInvitations(emails);
  };

  const handleSelectItem = (actionType: ActionType) => {
    onPrimaryCTAClick(actionType);
    close();
  };

  return (
    <FixedHeaderLayout headerContent={<WizardHeader disableBreezeBranding navHeaderType="package" />}>
      <GradientTitleBanner title="My Team" image={<MyTeamImage />} classes={{ image: classes.headerImage }}>
        {!isLoading && showCTA && (
          <div className={classes.actionButtonFooter}>
            <LoaderButton
              className={classes.actionButton}
              color="primary"
              variant="contained"
              endIcon={<KeyboardArrowDown />}
              onClick={(e) => handleClick(e)}
              size={isMobile ? 'small' : 'medium'}
            >
              Share
            </LoaderButton>
            <Menu
              open={isOpen}
              className={classes.dropdown}
              classes={{ paper: classes.dropdown }}
              anchorEl={anchorEl}
              onClose={close}
              onClick={close}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <MenuItem className={classes.menuItem} onClick={() => handleSelectItem(ActionType.SHARE)}>
                Share Account
              </MenuItem>
              {isMobile && <Divider className={classes.divider} />}
              <MenuItem className={classes.menuItem} onClick={() => handleSelectItem(ActionType.REQUEST)}>
                Request Access
              </MenuItem>
            </Menu>
          </div>
        )}
      </GradientTitleBanner>

      <Container className={classes.container}>
        <Typography className={classes.collaborators}>
          Collaborators {isLoading ? '' : `(${collaboratorCount})`}
        </Typography>

        {isLoading &&
          Array(3)
            .fill(null)
            .map((_, index) => (
              <Collaborator key={index} onRemoveDelegate={() => {}} onCancelInvite={() => {}} isLoading />
            ))}

        {!isLoading && children}
      </Container>

      <EmailCollectionDialog
        onSend={handleSendInvitations}
        open={isEmailCollectionDialogOpen}
        onClose={() => setIsEmailCollectionDialogOpen(false)}
        actionType={actionType}
        isLoading={isSendingEmails}
      />
    </FixedHeaderLayout>
  );
};

import React, { lazy, useEffect, useMemo, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Divider, Grow, Hidden, IconButton } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import clsx from 'clsx';
import { useRecoilValue } from 'recoil';
import { SklosuresBlock, useIsMobile } from 'src/hooks';
import { selectedAgentAtom } from 'src/state';
import { AnswerReviewBlock } from 'src/pages/FilePackages/components/AnswerReviewBlock';
import { FlaggedQuestionsDrawerContextProvider } from '../FlaggedQuestionsDrawerContext';
import { DisclosuresWorkflowData, FilePackage, PackageForm, PackageStatusType, SklosuresFlag } from '../types';
import { useDocumentPreview } from '../hooks/useDocumentPreview';
import { useGetResolveSignerFlagUrl } from '../hooks/useGetResolveSignerFlagUrl';
import { FilePackageItemStatus } from './FilePackageItemStatus';
import { FilePackageItemActions } from './FilePackageItemActions';
import { FilePackageItemForms } from './FilePackageItemForms';

const FormPreview = lazy(() => import('src/domain/FormPreview'));

export const createAndFill2DArray = (length: number) => new Array(length).fill(0).map(() => []);

const generateBlockProps = (blocks: SklosuresBlock[] | undefined) => {
  if (blocks === undefined || blocks.length === 0) return undefined;
  const maxPage = Math.max(...blocks.map((b) => b.pageNumber));

  return blocks.reduce((accumulator: any[][], currentValue) => {
    accumulator[currentValue.pageNumber].push(currentValue);

    return accumulator;
  }, createAndFill2DArray(maxPage + 1));
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      minHeight: theme.spacing(15),
      border: `1px solid ${theme.colors.grey.disable}`,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(0),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(2, 0),
      },
    },
    divider: {
      marginTop: theme.spacing(-2),
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(-2),
      },
    },
    sectionSpacing: {
      marginTop: theme.spacing(0),
      padding: theme.spacing(3, 3, 3, 3),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(3, 2, 3, 0),
      },
    },
    subtitle: {
      fontSize: '1rem',
    },
    hideShowIconButton: {
      zIndex: 100,
      marginLeft: theme.spacing(2),
      backgroundColor: 'white',
      border: `1px solid ${theme.colors.grey.disable}`,
      '&:hover': {
        backgroundColor: `${theme.colors.grey.offWhite3}`,
      },
    },
    rotate180: {
      transform: 'rotateZ(180deg)',
    },
    desktopActions: {
      padding: theme.spacing(3, 4, 1, 4),
      justifyContent: 'flex-start',
      gap: theme.spacing(4),
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    },
    titleText: {
      fontSize: 24,
      fontWeight: 400,
    },
    titleSection: {
      padding: theme.spacing(2.5, 1, 0, 3),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(2, 4),
      },
    },
    formsSection: {
      backgroundColor: theme.colors.grey.offWhite1,
      borderRadius: '0 0 10px 10px',
    },
  }),
  { classNamePrefix: 'FilePackageItem' }
);

export interface FilePackageItemProps {
  fileName?: string;
  filePackage: Partial<FilePackage<Partial<DisclosuresWorkflowData>>>;
}

/** A single package can contain multiple documents */
export const FilePackageItem: React.FC<FilePackageItemProps> = ({ fileName, filePackage }) => {
  const { id, name, lastUpdated, interviews, forms, status, envelope, workflowData, type, fileId } = filePackage;

  const isLoading = name == null;
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const { selectPackageForm, documentPreview } = useDocumentPreview(fileId ?? -1, interviews, envelope);
  const { resolveSignerFlagUrl } = useGetResolveSignerFlagUrl(interviews, documentPreview?.interviewId);
  const selectedAgent = useRecoilValue(selectedAgentAtom);
  const isWorkingOnBehalfOf = Boolean(selectedAgent);
  const sellerEmails = workflowData?.sellers?.map((s) => s.email) || [];

  const notCancelledForms = useMemo<PackageForm[] | undefined>(
    () => forms?.filter((form) => interviews?.find((i) => i.id === form.interviewId)?.status !== 'CANCELLED'),
    [forms, interviews]
  );

  const isMobile = useIsMobile();
  const classes = useStyles(isExpanded);

  const isExpandable = !isMobile || (Array.isArray(forms) && forms!.length > 1);
  const userSigner = envelope?.signers.find((signer) => signer.email === envelope.userEmail);
  const hideSignNowOption = isWorkingOnBehalfOf || !userSigner || userSigner.signingStatus === 'Complete';
  const [blockInfo, setBlockInfo] = useState<SklosuresBlock[]>();

  const getFlagsFromSelectedPackageForm = () => {
    const flags: SklosuresFlag[] = [];
    if (interviews) {
      const selectedInterview = interviews.filter((x) => x.id === documentPreview?.interviewId);
      if (selectedInterview.length > 0) {
        flags.push(...selectedInterview[0].signers[0].flags);
      }
    }
    return flags;
  };

  const getFlagForAnswerBlock = (answerKey: string) =>
    getFlagsFromSelectedPackageForm().filter((x) => x.answer === answerKey)[0];

  // We need to merge skipped and flags blocks into one array to prevent generating duplicate AnswerReviewBlock components
  useEffect(() => {
    const tempBlockInfo: SklosuresBlock[] = [];

    if (documentPreview?.skippedBlockInfo && documentPreview.flaggedBlockInfo) {
      tempBlockInfo.push(...documentPreview?.skippedBlockInfo);
      tempBlockInfo.push(
        ...documentPreview?.flaggedBlockInfo?.filter((x) => !tempBlockInfo.some((y) => y.answerKey === x.answerKey))
      );
    }

    tempBlockInfo.forEach((block) => {
      if (documentPreview?.skippedBlockInfo?.some((skipped) => skipped.answerKey === block.answerKey)) {
        block.isSkipped = true;
      }
      if (documentPreview?.flaggedBlockInfo?.some((flagged) => flagged.answerKey === block.answerKey)) {
        block.isFlagged = true;
      }
    });

    setBlockInfo(tempBlockInfo);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentPreview]);

  const blocksByPage = generateBlockProps(blockInfo);

  const pageChildren = useMemo(() => {
    if (blocksByPage == null) {
      return undefined;
    }
    return blocksByPage
      .map((blocks: SklosuresBlock[]) =>
        blocks.map((block) => (
          <AnswerReviewBlock
            {...block}
            key={`${block.xCoordinate}-${block.yCoordinate}-${block.pageNumber}`}
            flag={getFlagForAnswerBlock(block.answerKey)}
          />
        ))
      )
      .filter((block: any) => !!block);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blocksByPage]);

  return (
    <>
      <Grid container className={classes.root} justifyContent="space-between">
        <Grid item container direction="column" xs={10} sm={8} md={9} className={classes.titleSection}>
          <Grid item component={Typography} variant="body2" className={classes.titleText}>
            {name ? name : <Skeleton width={200} />}
          </Grid>
          <Grid item component={Typography} variant="subtitle2" className={classes.subtitle}>
            {isLoading ? <Skeleton width={300} /> : `Last Change: ${lastUpdated}`}
          </Grid>
          {sellerEmails.length > 0 && (
            <Grid item container spacing={1}>
              <Grid item component={Typography} variant="subtitle2" className={classes.subtitle}>
                Sent To:
              </Grid>
              <Grid item xs zeroMinWidth>
                {sellerEmails.map((email) => (
                  <Typography key={email} noWrap variant="subtitle2" className={classes.subtitle}>
                    {email}
                  </Typography>
                ))}
              </Grid>
            </Grid>
          )}
        </Grid>

        {/* The mobile version of the actions is shown as a context menu */}
        <Hidden smUp>
          <Grid item xs={2} container>
            <FilePackageItemActions
              packageStatus={status}
              actions={status?.actions ?? {}}
              isAvidFlowCompleted={!!workflowData?.isAvidFlowCompleted}
              interviews={interviews}
              forms={notCancelledForms ?? []}
              isLoading={isLoading}
              packageId={id ?? ''}
              name={name}
              fileName={fileName}
              onSelectForm={selectPackageForm}
              packageType={type ?? ''}
              envelopeId={envelope?.envelopeId}
              hideSignNowOption={hideSignNowOption}
            />
          </Grid>
        </Hidden>

        <Grid
          item
          xs={12}
          sm={4}
          md={3}
          container
          alignItems={isMobile ? 'center' : undefined}
          className={classes.sectionSpacing}
        >
          <FilePackageItemStatus status={status} />
        </Grid>
        <Grid item xs={12}>
          {isExpandable && (
            <IconButton
              onClick={() => setIsExpanded((current) => !current)}
              className={classes.hideShowIconButton}
              size="small"
            >
              <KeyboardArrowDownIcon className={clsx({ [classes.rotate180]: isExpanded })} />
            </IconButton>
          )}
          <Divider className={classes.divider} />
        </Grid>
        <Grow
          in={isExpanded}
          mountOnEnter
          unmountOnExit
          timeout={{
            enter: 500,
            exit: 0,
          }}
        >
          <Grid item xs={12} className={classes.formsSection}>
            <FilePackageItemForms
              showIndividualFormStatus={envelope == null && status?.status !== PackageStatusType.uxLoading}
              forms={forms ?? []}
              onPreview={selectPackageForm}
              interviews={interviews}
            />
          </Grid>
        </Grow>

        {/* The desktop version of actions is shown as a row of buttons */}
        <Hidden xsDown>
          <Grid item xs={12} container className={clsx(classes.desktopActions)}>
            <FilePackageItemActions
              packageStatus={status}
              actions={status?.actions ?? {}}
              isAvidFlowCompleted={!!workflowData?.isAvidFlowCompleted}
              interviews={interviews}
              forms={notCancelledForms ?? []}
              isLoading={isLoading}
              packageId={id ?? ''}
              name={name}
              fileName={fileName}
              onSelectForm={selectPackageForm}
              packageType={type ?? ''}
              envelopeId={envelope?.envelopeId}
              hideSignNowOption={hideSignNowOption}
            />
          </Grid>
        </Hidden>
      </Grid>
      {documentPreview && (
        <FlaggedQuestionsDrawerContextProvider>
          <FormPreview
            url={documentPreview.url}
            onClose={() => selectPackageForm(undefined)}
            documentName={documentPreview.formName}
            pageChildren={pageChildren}
            flags={getFlagsFromSelectedPackageForm()}
            signerQuestionFlaggingEnabled={
              interviews?.find((i) => i.id === documentPreview.interviewId)?.packageConfig
                .signerQuestionFlaggingEnabled || false
            }
            resolveSignerFlagUrl={resolveSignerFlagUrl}
          />
        </FlaggedQuestionsDrawerContextProvider>
      )}
    </>
  );
};

import React from 'react';
import { useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import * as Sentry from '@sentry/react';
import { useHistory } from 'react-router';
import { ErrorCodes } from 'src/lib';
import { useCustomSnackbar, useGetUserProfile, useUpdateUserProfile } from 'src/hooks';
import { Step, Navigation, TextInput } from 'src/common';
import { useTayTip } from 'src/domain/wizard/useTayTip';

export interface BrokerageNameProps {
  onNext: () => void;
  onBack: () => void;
}

interface BrokerageInputs {
  brokerageName: string;
}

export const BrokerageName = (props: BrokerageNameProps) => {
  const { data: userProfile } = useGetUserProfile();
  const history = useHistory();
  const { mutate: updateUserProfile, isLoading: isLoading } = useUpdateUserProfile();
  const BrokerageInputsProps = useForm<BrokerageInputs>({
    mode: 'onTouched',
  });
  const snackbarController = useCustomSnackbar();

  useTayTip('Your profile is not quite complete');

  const onSubmit = BrokerageInputsProps.handleSubmit((form: BrokerageInputs) => {
    updateUserProfile(
      {
        brokerageName: form.brokerageName,
      },
      {
        onSuccess: () => {
          props.onNext();
        },
        onError(e) {
          console.error('An error occurred while updating user profile (in brokerage name)');
          const eventId = Sentry.captureException(e);

          snackbarController.addErrorSnackbar({
            message: 'Sorry, we experienced an error when trying to save your brokerage name',
            errorCode: ErrorCodes.UpdateUserProfile,
            eventId,
          });
        },
      }
    );
  });

  return (
    <Step title="What brokerage do you represent?">
      <form onSubmit={onSubmit}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={6}>
            <TextInput
              label="Brokerage Name"
              fullWidth
              variant="outlined"
              defaultValue={userProfile?.brokerageName}
              inputRef={BrokerageInputsProps.register({
                required: 'Please enter this field.',
              })}
              name="brokerageName"
            />
          </Grid>
        </Grid>
      </form>
      <Navigation
        isLoading={isLoading}
        PrevButtonProps={{ onClick: history.goBack }}
        NextButtonProps={{
          disabled: !userProfile,
          onClick: onSubmit,
        }}
      />
    </Step>
  );
};

import React, { MouseEventHandler } from 'react';
import clsx from 'clsx';
import { Button, ButtonProps, makeStyles, Theme } from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { useLocation } from 'react-router';
import { AvidSteps } from 'src/pages/Avid/AvidCreation/steps/constants';
import { SpqTdsDisclosureSteps } from 'src/pages/SpqTdsDisclosures/steps';
import { LoaderButton } from './LoaderButton';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      marginTop: 80,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      zIndex: 1000,
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        flexDirection: 'row-reverse',
        borderTop: `1px solid ${theme.colors.grey.disable}`,
        backgroundColor: theme.colors.grey.offWhite3,
        justifyContent: 'space-between',
        padding: theme.spacing(3),
        marginBottom: 0,
      },
    },
    button: {
      [theme.breakpoints.down('xs')]: {
        fontSize: '1rem',
        padding: '8px 24px',
        width: (p: NavigationProps) => (p.PrevButtonProps?.hidden || p.NextButtonProps?.hidden) && '100%',
      },
    },
    nextButton: {
      minWidth: 280,
      [theme.breakpoints.down('xs')]: {
        minWidth: 0,
        color: 'white',
      },
    },
    backButton: {
      minWidth: 280,
      marginTop: 24,
      [theme.breakpoints.down('xs')]: {
        minWidth: 0,
        margin: 0,
      },
    },
    bottomPadder: {
      height: 0,
      [theme.breakpoints.down('xs')]: {
        height: 100,
      },
    },
  }),
  { classNamePrefix: 'navigation' }
);

export interface NavigationProps {
  className?: string;
  isLoading?: boolean;
  mobileFooter?: boolean;
  NextButtonProps?: ButtonProps;
  PrevButtonProps?: ButtonProps;
}

export const Navigation = (props: NavigationProps) => {
  const { isLoading, PrevButtonProps, NextButtonProps } = props;
  const classes = useStyles(props);

  const location = useLocation<{ nextGoToStep?: AvidSteps | SpqTdsDisclosureSteps }>();
  const isGoingBackToReview =
    location.state?.nextGoToStep === AvidSteps.review || location.state?.nextGoToStep === SpqTdsDisclosureSteps.review;

  const back: MouseEventHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (PrevButtonProps?.onClick) {
      e.preventDefault();
      PrevButtonProps.onClick(e);
    }
  };

  const forward: MouseEventHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (NextButtonProps?.onClick) {
      e.preventDefault();
      NextButtonProps.onClick(e);
    }
  };

  return (
    <>
      <div className={classes.bottomPadder} />
      <div className={clsx(classes.root, props.className)}>
        {!NextButtonProps?.hidden && (
          <LoaderButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={NextButtonProps?.disabled || isLoading}
            className={clsx(classes.button, classes.nextButton)}
            endIcon={NextButtonProps?.endIcon === undefined ? <ArrowForward /> : NextButtonProps.endIcon}
            isLoading={isLoading}
            {...NextButtonProps}
            onClick={forward}
            data-testid="wizard-next-btn"
          >
            {NextButtonProps?.children || (isGoingBackToReview ? 'Save' : 'Next')}
          </LoaderButton>
        )}
        {!PrevButtonProps?.hidden && !isLoading && (
          <Button
            variant="text"
            color="secondary"
            className={clsx(classes.button, classes.backButton)}
            startIcon={PrevButtonProps?.startIcon === undefined ? <ArrowBack /> : PrevButtonProps.startIcon}
            {...PrevButtonProps}
            onClick={back}
            data-testid="wizard-back-btn"
          >
            {PrevButtonProps?.children || 'Back'}
          </Button>
        )}
      </div>
    </>
  );
};

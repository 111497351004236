import React, { useRef } from 'react';
import { Hidden, makeStyles, Theme } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import { Spacer } from '../Spacer';
import { EmailStateProps } from './useComposeEmail';
import { EmailLineLabel } from './EmailLineLabel';
import { EmailMetaInput } from './EmailMetaInput';
import { EmailMetaRecipientField } from './EmailMetaRecipientField';

export function composeEmailVisualInputStyles(theme: Theme): CSSProperties {
  return {
    fontFamily: 'inherit',
    fontSize: 'inherit',
    border: '1px solid',
    borderColor: theme.colors.grey.disable,
    borderRadius: 8,
    boxSizing: 'border-box',
    minHeight: 60,
    outline: 'none !important',

    '&:focus-within': {
      borderColor: theme.colors.mainColor.primary,
    },
  };
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: theme.spacing(4),
      fontSize: '1.25rem',

      [theme.breakpoints.down('xs')]: {
        fontSize: '1rem',
      },
    },
    subjectInput: {
      ...composeEmailVisualInputStyles(theme),
      width: '100%',
      padding: theme.spacing(2, 4),
      paddingRight: 0,
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    bodyTextArea: {
      ...composeEmailVisualInputStyles(theme),
      padding: theme.spacing(2),
      paddingTop: theme.spacing(4),
    },
  }),
  { classNamePrefix: 'ComposeEmail' }
);

export interface ComposeEmailProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  emailState: EmailStateProps;
  hideCc?: boolean;
  disableEditRecipients?: boolean;
}

export const ComposeEmail: React.FC<ComposeEmailProps> = (props) => {
  const classes = useStyles();
  const { emailState, hideCc, disableEditRecipients, ...rest } = props;
  const { subject, setSubject, recipients, setRecipients, ccRecipients, setCcRecipients } = emailState;

  const subjectFieldRef = useRef<HTMLTextAreaElement>(null);

  return (
    <div {...rest} className={classes.root}>
      <EmailMetaRecipientField
        label="To"
        recipients={recipients}
        setRecipients={setRecipients}
        disabled={disableEditRecipients}
      />

      {!hideCc && (
        <>
          <EmailMetaRecipientField
            label="Cc"
            recipients={ccRecipients}
            setRecipients={setCcRecipients}
            disabled={disableEditRecipients}
          />
        </>
      )}

      <div>
        <EmailLineLabel inputRef={subjectFieldRef}>Subject</EmailLineLabel>
        <Hidden xsDown>
          <Spacer axis="vertical" size={2} />
        </Hidden>
        <EmailMetaInput
          inputRef={subjectFieldRef}
          className={classes.subjectInput}
          value={subject}
          onChange={setSubject}
        />
      </div>

      <textarea
        rows={16}
        className={classes.bodyTextArea}
        placeholder="Compose email..."
        value={emailState.body}
        onChange={(e) => emailState.setBody(e.target.value)}
      />
    </div>
  );
};

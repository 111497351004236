import React, { useRef, useContext } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, Radio, Checkbox, RadioProps, Typography, Tooltip, alpha } from '@material-ui/core';
import { Spacer, SelectableButton, StatusChip } from 'src/common';
import { useId } from 'src/hooks';
import { mergeRefs } from 'src/lib';
import { TeamMemberListContext } from './context';

const useStyles = makeStyles<Theme, { isChecked: boolean; isPending: boolean; disablePending: boolean }>(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',
      boxSizing: 'border-box',
      background: 'white',
      border: '2px solid',
      borderColor: theme.colors.grey.offWhite3,
      transition: 'border-color 200ms ease',
      padding: theme.spacing(1),
      borderRadius: 4,
      cursor: (p) => (p.isPending && p.disablePending ? 'not-allowed' : 'pointer'),
      position: 'relative',

      '&:hover': {
        background: (p) => (p.isPending && p.disablePending ? undefined : theme.colors.grey.offWhite2),
      },

      '& + &': {
        marginTop: theme.spacing(2),
      },
    },
    inputWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    textWrapper: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
      overflow: 'hidden',
    },
    text: {
      overflowWrap: 'break-word',
    },
    title: {
      pointerEvents: 'none',
      fontWeight: 'bold',
    },
    subtitle: {
      color: theme.colors.grey.label,
    },
    tooltipWrapper: {
      filter: `drop-shadow(4px 4px 12px ${alpha(theme.colors.mainColor.primary, 0.4)})`,
    },
    tooltip: {
      background: 'white',
      color: theme.colors.grey.bodyHeader,
    },
    tooltipArrow: {
      color: 'white',
    },
  }),
  { classNamePrefix: 'TeamMemberListItem' }
);

export interface TeamMemberListItemProps extends Omit<RadioProps, 'onChange'> {
  title: string;
  subtitle?: string;
  variant?: 'checkbox' | 'radio';
  onChange?(e: React.ChangeEvent<HTMLInputElement>, isChecked: boolean): void;
  value: string | null | undefined;
  isPending?: boolean;
  /** Prevent pending team members from being selected */
  disablePending?: boolean;
  /** Tooltip text displayed on the chip for pending team members */
  pendingTooltip?: string;
}

/**
 * One of the team members that a TC can choose when selecting
 * who they're creating this document on behalf of.
 */
export const TeamMemberListItem: React.FC<TeamMemberListItemProps> = (props) => {
  const {
    title,
    subtitle,
    variant,
    checked,
    onChange,
    inputRef: userInputRef,
    value,
    isPending = false,
    disablePending = true,
    pendingTooltip = '',
    ...rest
  } = props;

  const id = useId('TeamMemberListItem');
  const inputRef = useRef<HTMLInputElement>();
  const context = useContext(TeamMemberListContext);

  const finalVariant = variant || context.allowMultiple ? 'checkbox' : 'radio';
  const Input = finalVariant === 'radio' ? Radio : Checkbox;
  const isChecked = typeof checked === 'boolean' ? checked : context.value.includes(value);
  const classes = useStyles({ isChecked, isPending, disablePending });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
    onChange?.(e, isChecked);
    context.onMemberSelected(value);
  };

  return (
    // When you put an `<input />` inside of a `<label>` the browser
    // will treat clicking on the label as a click on the input.  We take
    // advantage of that fact here by making the root element a label, and
    // allow the `checked` and `onChange` props to be handled in the same
    // way as they would be natively.
    <SelectableButton
      className={classes.root}
      selectBorderPosition={{ x: -2, y: -2 }}
      checked={checked}
      component="label"
      disableRipple={disablePending && isPending}
      disableTouchRipple={disablePending && isPending}
      data-testid={value}
    >
      <div className={classes.inputWrapper}>
        <Tooltip
          title={disablePending && isPending ? "You haven't been granted access to send on behalf of this user." : ''}
          arrow
          placement="top"
          classes={{ popper: classes.tooltipWrapper, tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
        >
          <span>
            <Input
              {...rest}
              id={id}
              checked={isChecked}
              onChange={handleChange}
              inputRef={mergeRefs(inputRef, userInputRef)}
              value={value}
              color="primary"
              name="team-member"
              disabled={disablePending && isPending}
            />
          </span>
        </Tooltip>
      </div>
      <Spacer axis="horizontal" size={2} />
      <div className={classes.textWrapper}>
        <Typography variant="body1" component="label" htmlFor={id} className={clsx(classes.text, classes.title)}>
          {title}
        </Typography>
        <Typography variant="body1" className={clsx(classes.text, classes.subtitle)}>
          {subtitle}
        </Typography>
      </div>
      {isPending && (
        <Tooltip title={pendingTooltip} style={{ fontSize: '2rem' }}>
          <div style={{ marginLeft: 'auto' }}>
            <StatusChip style={{ cursor: 'inherit' }}>Pending</StatusChip>
          </div>
        </Tooltip>
      )}
    </SelectableButton>
  );
};

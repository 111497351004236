import { useMemo, useState } from 'react';
import { DashboardItem } from '../types';

export type SortDirection = 'asc' | 'desc' | null;
type SortableColumnNames = keyof Pick<DashboardItem, 'address' | 'agentFullName'>;
export type SortableColumns = null | SortableColumnNames;

export interface Sortable {
  sort?: SortDirection;
}

const orderOfSortDirs: SortDirection[] = ['asc', 'desc', null];

function getNextSortDirection(dir: SortDirection) {
  const index = orderOfSortDirs.indexOf(dir);
  const nextIndex = (index + 1) % orderOfSortDirs.length;
  return orderOfSortDirs[nextIndex];
}

export const useDashboardColumnSort = (items: DashboardItem[] | undefined) => {
  const [sortColumn, setSortColumn] = useState<SortableColumns>(null);
  const [sortDirection, setSortDirection] = useState<SortDirection>(null);

  const handleSortClick = (clickedSortColumn: SortableColumns) => {
    if (sortColumn === clickedSortColumn) {
      const nextSortDir = getNextSortDirection(sortDirection);
      setSortDirection(nextSortDir);
      if (!nextSortDir) setSortColumn(null);
    } else {
      setSortColumn(clickedSortColumn);
      setSortDirection('asc');
    }
  };

  const getNextSortTooltip = (forColumn: SortableColumns) => {
    const nextSortDir = sortColumn === forColumn ? getNextSortDirection(sortDirection) : 'asc';

    switch (nextSortDir) {
      case 'asc':
        return 'Sort by A-Z';
      case 'desc':
        return 'Sort by Z-A';
      case null:
        return 'Stop sorting';
      default:
        return '';
    }
  };

  const sortedItems = useMemo(() => {
    if (items == null) return [];
    if (sortColumn == null) return items;

    const directionMultiplier = sortDirection === 'desc' ? -1 : 1;

    return [...items].sort((a, b) => {
      const aProp = a[sortColumn];
      const bProp = b[sortColumn];

      // If both strings being compared start with a number, compare
      // those numbers numerically instead of as strings
      // (address "25" should come before "199")
      const aStartNum = Number(aProp?.split(' ')[0]);
      const bStartNum = Number(bProp?.split(' ')[0]);
      if (!Number.isNaN(aStartNum) && !Number.isNaN(bStartNum)) {
        return directionMultiplier * (aStartNum - bStartNum);
      }

      return directionMultiplier * String(aProp || '').localeCompare(String(bProp || ''));
    });
  }, [items, sortColumn, sortDirection]);

  return {
    sortColumn,
    sortDirection,
    handleSortClick,
    sortedItems,
    getNextSortTooltip,
  };
};

import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, Drawer, IconButton, Tooltip } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useFlags, useGetUserProfile, useIsMedium, useTeamMembers } from 'src/hooks';
import { routes } from 'src/lib';
import breezeIconAgent from 'src/images/breezeLogoAgent.svg';
import breezeIcon from 'src/images/breezeIcon.svg';
import { Spacer } from 'src/common/Spacer';
import { SlideView } from 'src/common/SlideView';
import { isNavMenuOpenAtom } from 'src/state/navMenuAtom';
import { NavigationMenu } from './NavigationMenu';
import { SelectAgentNavRow } from './SelectAgentNavRow';
import { SelectAgentNavMenu } from './SelectAgentNavMenu';

const useStyles = makeStyles((theme: Theme) => ({
  drawer: {
    width: 298,
    cursor: 'pointer',
  },
  drawerOpen: {
    backgroundColor: theme.colors.grey.offWhite3,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),

    width: 298,
    // This extra width is required on mobile because of the
    // agent selection that gets added to the nav menu
    [theme.breakpoints.down('md')]: {
      width: 'min(375px, 100%)',
    },
  },
  // This is when the drawer is open only narrowly so that you can
  // only see the icons
  drawerClose: {
    width: theme.spacing(9),
    marginRight: theme.spacing(9),
    backgroundColor: theme.colors.grey.offWhite3,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  breezeLogo: {
    height: 35,
  },
  breezeLogoCollapsed: {
    height: theme.spacing(4),
    width: theme.spacing(3),
    margin: theme.spacing(0, 0.5),
  },
  logoButton: {
    '&:hover': {
      backgroundColor: '#E3E9EA',
      borderRadius: 3,
    },
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: 16,
  },
  closeIcon: {
    marginLeft: 'auto',
  },
}));

/**
 * The drawer that contains the primary navigation for the site.  This component
 * handles the expanding and collapsing of the navigation but allows
 * NavigationMenu and SelectAgentNavMenu to render the actual contents of the
 * navigation.
 */
export const NavigationDrawer: React.FC = () => {
  const classes = useStyles();
  const [isChoosingAgent, setIsChoosingAgent] = useState(false);
  const [navMenuOpen, setNavMenuOpen] = useRecoilState(isNavMenuOpenAtom);

  const handleClose = () => {
    setIsChoosingAgent(false);
    setNavMenuOpen(!navMenuOpen);
  };

  return (
    <Drawer
      onClick={() => {
        if (!navMenuOpen) setNavMenuOpen(!navMenuOpen);
      }}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: navMenuOpen,
        [classes.drawerClose]: !navMenuOpen,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: navMenuOpen,
          [classes.drawerClose]: !navMenuOpen,
        }),
      }}
      anchor="left"
      open={navMenuOpen}
      onClose={handleClose}
      variant="permanent"
    >
      {/* Keyboard users should us actual buttons, this click handler is a convenience */}
      {/* eslint-disable-next-line */}
      <div
        className={classes.drawerHeader}
        onClick={() => {
          if (!navMenuOpen) setNavMenuOpen(!navMenuOpen);
        }}
        role="navigation"
      >
        {navMenuOpen ? (
          <img className={classes.breezeLogo} src={breezeIconAgent} alt="Breeze" />
        ) : (
          <Tooltip title="Open Navigation Menu" placement="left-end">
            <IconButton className={classes.logoButton}>
              <img className={classes.breezeLogoCollapsed} src={breezeIcon} alt="Breeze" />
            </IconButton>
          </Tooltip>
        )}
        {navMenuOpen && (
          <IconButton onClick={() => setNavMenuOpen(!navMenuOpen)} className={classes.closeIcon}>
            <CloseIcon />
          </IconButton>
        )}
      </div>

      <TheNavMenu isChoosingAgent={isChoosingAgent} setIsChoosingAgent={setIsChoosingAgent} />
    </Drawer>
  );
};

function TheNavMenu(props: { isChoosingAgent: boolean; setIsChoosingAgent(isChoosing: boolean): void }) {
  const { isChoosingAgent, setIsChoosingAgent } = props;

  const isDesktop = !useIsMedium();
  const { tcAccess: isTcAccessEnabledFlag } = useFlags();

  const location = useLocation();
  const doAllowSwitchingAgentsOnThisPage = [routes.dashboard, routes.team].some((route) =>
    location.pathname.includes(route)
  );

  const { data: userProfile, isSuccess: isUserProfileAvailable } = useGetUserProfile();
  const { isSuccess: isTeamAvailable } = useTeamMembers();

  const noUserProfile = !isUserProfileAvailable || !userProfile; // User profile can be null when the wizard is being used for sign in

  if (!isTcAccessEnabledFlag || isDesktop || !doAllowSwitchingAgentsOnThisPage || !isTeamAvailable || noUserProfile) {
    // Standard navigation
    return <NavigationMenu />;
  }

  // Navigation with agent switching
  return (
    <SlideView index={isChoosingAgent ? 1 : 0}>
      <NavigationMenu style={{ paddingTop: 0 }}>
        <>
          <SelectAgentNavRow onClick={() => setIsChoosingAgent(true)} />
          <Spacer axis="vertical" size={3} />
        </>
      </NavigationMenu>
      <SelectAgentNavMenu onBack={() => setIsChoosingAgent(false)} />
    </SlideView>
  );
}

import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Divider, Grid, Link } from '@material-ui/core';
import skyslopeLogo from 'src/images/skyslope.svg';

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'start',
    letterSpacing: '0.16px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  poweredBy: {
    minWidth: '300px',
    marginLeft: '50px',
    marginTop: theme.spacing(3),
    whiteSpace: 'nowrap',
  },
  content: {
    marginLeft: '50px',
    marginTop: theme.spacing(3),
  },
  header: {
    color: theme.colors.grey.label,
    fontFamily: 'Avenir Next',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(17),
    lineHeight: '23px',
    paddingBottom: '10px',
  },
  link: {
    color: theme.colors.grey.bodyHeader,
    fontFamily: 'Avenir Next',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(17),
    lineHeight: '23px',
    padding: '10px 0px',
    textDecoration: 'none',
  },
  divider: {
    margin: `${theme.spacing(3)}px 50px`,
  },
  copyrightText: {
    color: theme.colors.grey.bodyHeader,
    fontFamily: 'Avenir Next',
    fontSize: theme.typography.pxToRem(13),
    lineHeight: '17px',
    letterSpacing: '0.16px',
    fontWeight: 500,
    marginLeft: `50px`,
    marginBottom: theme.spacing(3),
  },
  poweredByText: {
    color: theme.colors.grey.bodyHeader,
    fontFamily: 'Avenir Next',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(17),
    lineHeight: '27px',
    letterSpacing: '0.16px',
    marginRight: theme.spacing(1),
    display: 'inline-block',
  },
  skyslopeLogo: {
    height: '26px',
    verticalAlign: 'sub',
  },
}));

export const LandingFooter: React.FC = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Grid container className={classes.wrapper}>
        <Grid item className={classes.poweredBy} xs={12} md={3}>
          <div className={classes.poweredByText}>Powered by</div>
          <a href="https://skyslope.com" target="_blank" rel="noreferrer">
            <img src={skyslopeLogo} className={classes.skyslopeLogo} alt="SkySlope" />
          </a>
        </Grid>
        <Grid item className={classes.content} xs={12} md={3}>
          <div className={classes.header}>ABOUT US</div>
          <div className={classes.link}>
            <Link className={classes.link} href="https://skyslope.com/company/" target="_blank" rel="noreferrer">
              Company
            </Link>
          </div>
          <div className={classes.link}>
            {/* eslint-disable-next-line prettier/prettier */}
            <Link className={classes.link} href="https://skyslope.com/products-services/" target="_blank" rel="noreferrer">
              Products & Services
            </Link>
          </div>
          <div className={classes.link}>
            <Link className={classes.link} href="https://careers.skyslope.com/" target="_blank" rel="noreferrer">
              Careers
            </Link>
          </div>
          <div className={classes.link}>
            <Link className={classes.link} href="https://skyslope.com/blog/" target="_blank" rel="noreferrer">
              SkySlope Blog
            </Link>
          </div>
          <div className={classes.link}>
            <Link className={classes.link} href="https://askty.skyslope.com/" target="_blank" rel="noreferrer">
              AskTy
            </Link>
          </div>
          <div className={classes.link}>
            {/* eslint-disable-next-line prettier/prettier */}
            <Link className={classes.link} href="https://skyslope.com/about-us/contact-us/" target="_blank" rel="noreferrer">
              Contact Us
            </Link>
          </div>
        </Grid>
        <Grid item className={classes.content} xs={12} md={3}>
          <div className={classes.header}>RESOURCES</div>
          <div className={classes.link}>
            <Link className={classes.link} href="https://skyslope.com/request-demo/" target="_blank" rel="noreferrer">
              Request Demo
            </Link>
          </div>
          <div className={classes.link}>
            <Link className={classes.link} href="https://app.skyslope.com/login.aspx" target="_blank" rel="noreferrer">
              SkySlope Suite Login
            </Link>
          </div>
          <div className={classes.link}>
            <Link className={classes.link} href="https://skyslope.com/forms-login/" target="_blank" rel="noreferrer">
              SkySlope Forms Login
            </Link>
          </div>
          <div className={classes.link}>
            {/* eslint-disable-next-line prettier/prettier */}
            <Link className={classes.link} href="https://support.skyslope.com/hc/en-us" target="_blank" rel="noreferrer">
              Get Help
            </Link>
          </div>
          <div className={classes.link}>
            {/* eslint-disable-next-line prettier/prettier */}
            <Link className={classes.link} href="https://skyslope.com/products-services/skytc/" target="_blank" rel="noreferrer">
              SkyTC
            </Link>
          </div>
          <div className={classes.link}>
            {/* eslint-disable-next-line prettier/prettier */}
            <Link className={classes.link} href="https://skyslope.com/terms-conditions/" target="_blank" rel="noreferrer">
              Terms of Use
            </Link>
          </div>
          <div className={classes.link}>
            <Link className={classes.link} href="https://skyslope.com/privacy-policy/" target="_blank" rel="noreferrer">
              Privacy Policy
            </Link>
          </div>
          <div className={classes.link}>
            {/* eslint-disable-next-line prettier/prettier */}
            <Link className={classes.link} href="https://skyslope.com/California-privacy-notice-at-collection" target="_blank" rel="noreferrer">
              California Policy
            </Link>
          </div>
          <div className={classes.link}>
            <Link className={classes.link} href="https://skyslope.com/accessibility/" target="_blank" rel="noreferrer">
              Accessibility
            </Link>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item xs={12}>
          <div className={classes.copyrightText}>2017 - 2022 SkySlope, Inc. All Rights Reserved.</div>
        </Grid>
      </Grid>
    </footer>
  );
};

import React from 'react';
import { withStyles, Tooltip, TooltipProps, Theme } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';

const StyledTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    padding: 30,
    color: theme.colors.grey.bodyHeader,
    maxWidth: 190,
    fontFamily: 'inherit',
    fontSize: '16px',
    letterSpacing: 0,
    lineHeight: '24px',
    fontWeight: 600,
    backgroundColor: '#FFFFFF',
    boxShadow: `
      0 0   12px  1px rgba(247,119,33,0.07), 
      0 3px 16px  2px rgba(247,119,33,0.06), 
      0 5px  6px -3px rgba(247,119,33,0.07)`,
  },
  arrow: {
    color: '#fff',
    '&::before': {
      border: `1px solid rgba(247,119,33,0.07)`,
    },
  },
}))(Tooltip);

export interface HtmlToolTopProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  title: string;
  placement: TooltipProps['placement'];
}

export const HtmlToolTip = (props: HtmlToolTopProps) => {
  const { placement, title, ...rest } = props;
  return (
    <StyledTooltip {...rest} title={title} placement={placement} arrow>
      <InfoOutlined style={{ fill: '#7A8F99', fontSize: '20px' }} />
    </StyledTooltip>
  );
};

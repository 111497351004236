import { useMutation } from 'react-query';
import * as Sentry from '@sentry/react';
import { ErrorCodes, FormsUserProfile, getOrCreateUser } from 'src/lib';
import { useCustomSnackbar } from '../useCustomSnackbar';

type CreateUserProfilePayload = {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
};

export const useGetOrCreateUserProfile = () => {
  const { addErrorSnackbar } = useCustomSnackbar();

  return useMutation(
    async (payload: CreateUserProfilePayload): Promise<FormsUserProfile> =>
      await getOrCreateUser(payload.userId, payload.firstName, payload.lastName, payload.email),
    {
      onError(e) {
        console.error(e);
        const eventId = Sentry.captureException(e);
        addErrorSnackbar({ errorCode: ErrorCodes.CreateUserProfile, eventId });
      },
    }
  );
};

import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, Typography, GridProps } from '@material-ui/core';
import tay from '../images/tay128.png';
import { FlowNavigationCards } from './FlowNavigationCards';
import { Spacer } from '.';

const useStyles = makeStyles(
  (theme: Theme) => ({
    headerTypography: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(1),
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.5rem',
      },
    },
    tay: {
      marginRight: theme.spacing(2),
    },
    cardsWrapper: {
      margin: `${theme.spacing(4)}px 0`,
    },
  }),
  { classNamePrefix: 'TayHeadingWithWorkflows' }
);
export interface FilesHeaderProps extends GridProps {
  title: string;
  navigationQueryParams?: string;
  hasNoPackages?: boolean;
}

export const TayHeadingWithWorkflows: React.FC<FilesHeaderProps> = (props) => {
  const classes = useStyles();
  const { title, navigationQueryParams, className, hasNoPackages, ...rest } = props;

  return (
    <div {...rest} className={clsx(className)}>
      <Typography className={classes.headerTypography} variant="h4" component="h1">
        <img
          className={classes.tay}
          src={tay}
          style={{ display: 'inline-block' }}
          height={64}
          width={64}
          alt="Tay, your Breeze assistant"
        />
        {title}
      </Typography>
      <Spacer axis="vertical" size={4} />
      <FlowNavigationCards hasNoPackages={hasNoPackages} queryParams={navigationQueryParams} />
    </div>
  );
};

import React from 'react';
import { makeStyles, Typography, Theme } from '@material-ui/core';
import Clouds from 'src/images/clouds-grey.svg';
import { Spacer } from 'src/common';

const useStyles = makeStyles(
  (theme: Theme) => ({
    cloudBox: {
      boxSizing: 'border-box',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      minHeight: 250,
      border: `2px solid ${theme.colors.grey.disable}`,
      borderRadius: theme.spacing(0.5),
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
      backgroundImage: `url(${Clouds})`,
      backgroundRepeat: 'no-repeat',
      backgroundOrigin: 'content-box',
      backgroundPosition: 'left center',
      backgroundSize: 'cover',
    },
    title: {
      maxWidth: '60%',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '90%',
        padding: theme.spacing(4, 0),
      },
    },
  }),
  { classNamePrefix: 'NothingHereCard' }
);

export interface NothingHereCard {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  button?: React.ReactNode;
}

/**
 * A box filled with a cloud background intended to give the user some
 * information about why a particular page is empty.
 */
export const NothingHereCard: React.FC<NothingHereCard> = (props) => {
  const classes = useStyles();
  const { title, subtitle, button } = props;

  return (
    <div className={classes.cloudBox}>
      <Typography variant="h3" className={classes.title}>
        {title}
      </Typography>
      {subtitle && (
        <>
          <Spacer axis="vertical" size={2} />
          {typeof subtitle === 'string' ? <Typography variant="subtitle1">{subtitle}</Typography> : subtitle}
        </>
      )}
      {button && (
        <>
          <Spacer axis="vertical" size={2} /> {button}
        </>
      )}
    </div>
  );
};

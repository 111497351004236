import type {} from '@material-ui/lab/themeAugmentation';
import createTheme, { ThemeOptions, Theme } from '@material-ui/core/styles/createTheme';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { TypographyOptions } from '@material-ui/core/styles/createTypography';
import { Shadows } from '@material-ui/core/styles/shadows';
import { Property } from 'csstype';
import { alpha } from '@material-ui/core/styles/colorManipulator';

export interface CustomThemeColors {
  mainColor: {
    primary: Property.Color;
    hover: Property.Color;
    hoverLight: Property.Color;
    active: Property.Color;
    backgroundGradient: Property.Color;
  };
  grey: {
    bodyHeader: Property.Color;
    label: Property.Color;
    disable: Property.Color;
    grey1: Property.Color;
    offWhite1: Property.Color;
    offWhite2: Property.Color;
    offWhite3: Property.Color;
    offBlack: Property.Color;
    100: Property.Color;
  };
  blue50: Property.Color;
  blue100: Property.Color;
  blueForms: Property.Color;
  error: Property.Color;
  warn: Property.Color;
  success: Property.Color;
}

const defaultColors: CustomThemeColors = {
  mainColor: {
    primary: '#F77721',
    hover: '#CC631D',
    hoverLight: alpha('#F77721', 0.15),
    active: '#B25719',
    backgroundGradient: alpha('#F77721', 0.5),
  },
  grey: {
    bodyHeader: '#465766',
    label: '#7A8F99',
    disable: '#D5DFE2',
    grey1: '#B8C6CC',
    offWhite1: '#F9FBFB',
    offWhite2: '#F2F8F9',
    offWhite3: '#EDF3F4',
    offBlack: '#27303A',
    100: '#F4F8FC',
  },
  blue50: '#E1F1FF',
  blue100: '#B7DCFF',
  blueForms: '#0059DA', // Skyslope Forms primary color
  error: '#FA4515',
  success: '#308252',
  warn: '#FFAB00',
};

const sansSerif = [
  'Nunito Sans',
  'Montserrat',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  '"Helvetica Neue"',
  'Arial',
  'Roboto',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

const serif = ['Karma', 'serif'].join(',');

const breakpoints = createBreakpoints({});
const defaultTheme = createTheme({
  colors: defaultColors,
});

const createBreezeTheme = (options: ThemeOptions): Theme => {
  const typography: TypographyOptions = {
    fontFamily: sansSerif,
    h1: {
      fontFamily: serif,
      fontWeight: 600,
      fontSize: '3rem',
      lineHeight: 1.167,
      color: options.colors.grey.bodyHeader,
    },
    h2: {
      fontFamily: serif,
      fontWeight: 600,
      fontSize: '3rem',
      lineHeight: 1.333,
      color: options.colors.grey.bodyHeader,
    },
    h3: {
      fontFamily: sansSerif,
      fontWeight: 600,
      fontSize: '1.5rem',
      lineHeight: 1.375,
      color: options.colors.grey.bodyHeader,
    },
    h4: {
      fontFamily: sansSerif,
      fontWeight: 600,
      color: options.colors.grey.bodyHeader,
      // fontSize: '2.125rem', <-- The MUI default
    },
    h5: {
      fontFamily: sansSerif,
      fontWeight: 600,
      color: options.colors.grey.bodyHeader,
      // fontSize: '1.5rem', <-- The MUI default
    },
    h6: {
      fontFamily: sansSerif,
      fontWeight: 600,
      color: options.colors.grey.bodyHeader,
      // fontSize: '1.25rem', <-- The MUI default
    },
    body1: {
      color: options.colors.grey.bodyHeader,
      fontSize: defaultTheme.typography.pxToRem(18),
      fontWeight: 'normal',
    },
    body2: {
      color: options.colors.grey.bodyHeader,
      fontWeight: 'bold',
      fontSize: defaultTheme.typography.pxToRem(18),
    },
    subtitle1: {
      color: options.colors.grey.label,
      fontSize: '1rem',
      lineHeight: '1.5',
      letterSpacing: '0.13px',
    },
    subtitle2: {
      color: options.colors.grey.label,
      fontSize: defaultTheme.typography.pxToRem(22),
      lineHeight: '1.5',
      letterSpacing: '0.13px',
    },
  };

  const optionStyles = {
    fontSize: 22,
    minWidth: 200,
    padding: '16px 24px',
    '&:hover': {
      backgroundColor: alpha(options.colors.mainColor.primary, 0.29),
    },
    '&$selected, &$selected:hover, &[aria-selected="true"]': {
      backgroundColor: options.colors.mainColor.primary,
      color: 'white',
    },
  };

  return createTheme({
    typography,
    colors: options.colors,
    shadows: defaultTheme.shadows.map((s) =>
      s
        .replace('rgba(0,0,0,0.2)', alpha(options.colors.mainColor.primary, 0.2))
        .replace('rgba(0,0,0,0.14)', alpha(options.colors.mainColor.primary, 0.1))
        .replace('rgba(0,0,0,0.12)', alpha(options.colors.mainColor.primary, 0.05))
    ) as Shadows,
    palette: {
      primary: {
        main: options.colors.mainColor.primary,
        dark: options.colors.mainColor.hover,
        light: options.colors.mainColor.hoverLight,
      },
      text: {
        primary: options.colors.grey.bodyHeader,
        secondary: options.colors.grey.label,
        disabled: options.colors.grey.disable,
      },
      error: { main: options.colors.error },
    },
    overrides: {
      MuiPaper: {
        elevation0: {
          boxShadow: 'none',
        },
      },
      MuiButtonBase: {
        root: {
          outline: undefined,
        },
      },
      MuiIconButton: {
        root: {
          padding: 8,
          '&:hover': {
            backgroundColor: alpha(options.colors.mainColor.primary, 0.3),
            color: options.colors.mainColor.primary,
          },
        },
      },
      MuiToggleButton: {
        root: {
          textTransform: 'capitalize',
        },
      },
      MuiButton: {
        root: {
          textTransform: 'capitalize',
        },
        contained: {
          textTransform: 'capitalize',
          color: options.colors.grey.bodyHeader,
          padding: defaultTheme.spacing(2, 4),
          backgroundColor: defaultColors.grey.offWhite3,
          borderRadius: '5px',
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: options.colors.grey.disable,
            color: options.colors.grey.bodyHeader,
          },
        },
        sizeSmall: {
          lineHeight: 1.75,
          fontSize: defaultTheme.typography.pxToRem(16),
          padding: defaultTheme.spacing(0.5, 1.5),
        },
        outlinedPrimary: {
          padding: '16px 32px',
          fontSize: defaultTheme.typography.pxToRem(22),
          fontWeight: 'bold',
          lineHeight: 1,
          '&:hover': {
            backgroundColor: options.colors.mainColor.hoverLight,
            boxShadow: `5px 5px 20px 5px ${options.colors.mainColor.hoverLight}`,
          },
        },
        containedPrimary: {
          color: 'white',
          boxShadow: `5px 5px 20px 5px ${alpha(options.colors.mainColor.primary, 0.4)}`,
          padding: defaultTheme.spacing(2, 4),
          fontSize: defaultTheme.typography.pxToRem(22),
          fontWeight: 'bold',
          lineHeight: 1,
          '&:hover': {
            color: '#fff',
            boxShadow: `5px 5px 20px 5px ${alpha(options.colors.mainColor.primary, 0.4)}`,
          },
          '&:focus, &:active': {
            boxShadow: `5px 5px 20px 5px ${alpha(options.colors.mainColor.primary, 0.4)}`,
          },
          '&$disabled': {
            backgroundColor: options.colors.grey.disable,
            color: options.colors.grey.grey1,
            boxShadow: 'none',
          },
        },
        text: {
          color: options.colors.grey.label,
          fontWeight: 'bold',
          fontSize: defaultTheme.typography.pxToRem(22),
        },
        textPrimary: {
          fontWeight: 'bold',
          textTransform: 'capitalize',
        },
        textSecondary: {
          padding: defaultTheme.spacing(2, 4),
          color: options.colors.grey.label,
          lineHeight: 1,
          '&:hover': {
            backgroundColor: alpha(options.colors.grey.bodyHeader, 0.05),
          },
        },
        iconSizeMedium: {
          '& > :first-child': {
            fontSize: defaultTheme.typography.pxToRem(24),
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          fontWeight: 600,
          fontSize: defaultTheme.typography.pxToRem(24),
          '&:hover $notchedOutline': {
            borderColor: options.colors.mainColor.primary,
          },
          [breakpoints.down('sm')]: {
            fontSize: defaultTheme.typography.pxToRem(18),
          },
        },
        input: {
          padding: defaultTheme.spacing(2, 3),
        },
        notchedOutline: {
          borderWidth: 2,
          borderColor: options.colors.grey.grey1,
        },
      },
      MuiInputLabel: {
        root: {
          textTransform: 'capitalize',
          fontWeight: 600,
          fontSize: defaultTheme.typography.pxToRem(24),
          color: options.colors.grey.grey1,
          [breakpoints.down('sm')]: {
            fontSize: defaultTheme.typography.pxToRem(18),
          },
        },
        outlined: {
          transform: 'translate(24px, 18px) scale(1)',
        },
        shrink: {
          transform: 'translate(16px, -5px) scale(0.5833) !important',
        },
      },
      MuiFilledInput: {
        root: {
          backgroundColor: `${options.colors.grey.offWhite3} !important`,
          borderRadius: 8,
          border: `1px solid ${options.colors.grey.disable}`,
          borderLeft: `4px solid ${options.colors.grey.grey1}`,
          transition: 'border-left-color 0.25s',
          '&$focused, &:hover': {
            borderLeftColor: options.colors.mainColor.primary,
          },
        },
      },
      MuiInputAdornment: {
        root: {
          color: options.colors.grey.grey1,
        },
      },
      MuiSelect: {
        root: {
          color: options.colors.mainColor.primary,
          fontSize: defaultTheme.typography.pxToRem(24),
          fontWeight: 'bold',
          display: 'flex',
          alignItems: 'center',
          [breakpoints.down('sm')]: {
            fontSize: defaultTheme.typography.pxToRem(18),
          },
        },
        icon: {
          fill: options.colors.grey.bodyHeader,
        },
      },
      MuiMenuItem: {
        root: {
          ...optionStyles,
          fontSize: defaultTheme.typography.pxToRem(19),
        },
      },
      MuiAutocomplete: {
        option: optionStyles,
      },
      MuiChip: {
        root: {
          textTransform: 'uppercase',
          borderRadius: 4,
          backgroundColor: options.colors.grey.offWhite2,
        },
      },
      MuiPickersDay: {
        daySelected: {
          color: 'white',
          '&:hover': {
            backgroundColor: defaultColors.mainColor.hoverLight,
          },
        },
      },
      MuiPickerDTTabs: {
        tabs: {
          color: 'white',
          backgroundColor: defaultColors.mainColor.primary,
        },
      },
      MuiPickersToolbarText: {
        toolbarTxt: {
          color: defaultColors.grey.disable,
        },
        toolbarBtnSelected: {
          color: 'white',
        },
      },
      MuiFormHelperText: {
        root: {
          color: defaultColors.grey.bodyHeader,
          fontSize: defaultTheme.typography.pxToRem(14),
        },
      },
      MuiTooltip: {
        tooltip: {
          fontSize: '1rem',
        },
      },
    },
  });
};

export const muiTheme = createBreezeTheme({ colors: defaultColors });

//TODO Future, set up specific theme for specific flows and use them in nested theme providers, example:
// export const disclosuresTheme = createBreezeTheme({
//   colors: {
//     ...defaultColors,
//     mainColor: {
//       ...defaultColors.mainColor,
//       primary: colors.blue[800], // using mache colors here
//       hover: colors.blue[100],s
//       active: colors.blue[200]
//     }
//   }
// })

import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core';
import { HtmlProps } from 'src/lib';
import { SearchInput, SearchInputProps } from 'src/common';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      gap: theme.spacing(3),
      'flex-wrap': 'wrap',
    },
    mainContent: {
      flexGrow: 1,
    },
    searchInput: {
      borderRadius: 999,
      fontSize: '1rem',
      width: '100%',
    },
    threeCharError: {
      color: theme.colors.error,
    },
    rightEnd: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    openCloseBtn: {
      background: theme.colors.grey.offWhite3,
      width: 48,
      height: 48,
    },
  }),
  { classNamePrefix: 'EscrowSearchBar' }
);

export interface EscrowSearchBarProps extends HtmlProps {
  InputProps: SearchInputProps;
}

export const EscrowSearchBar: React.FC<EscrowSearchBarProps> = (props) => {
  const classes = useStyles();
  const { className, InputProps, ...rest } = props;

  return (
    <div {...rest} className={clsx(className, classes.root)}>
      <SearchInput
        {...InputProps}
        className={clsx(classes.mainContent, classes.searchInput, InputProps?.className)}
        minLength={3}
      />
    </div>
  );
};

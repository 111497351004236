import { formatPhoneNumber } from 'src/lib';

/**
 * A very loose check to determine if a string roughly matches the format
 * of an email.  This is not actual email validation, which is much more
 * complicated, but should never return false for a valid email.
 */
export function resemblesEmail(str: string | null | undefined) {
  return !!str?.match(/^.+@.+\..+$/);
}

export function s(str?: string | null) {
  return str || '';
}

export function generateLicenseNumberLine(licenseNumber: string | undefined) {
  return licenseNumber ? `License #${licenseNumber}` : '';
}

export function generateAgentSignature(args: {
  state: string | undefined;
  agent:
    | { firstName?: string; lastName?: string; primaryPhoneNumber?: string; email?: string; brokerageName?: string }
    | undefined;
  agentDreLicenseNumber: string | undefined;
}) {
  const { agent, agentDreLicenseNumber } = args;
  const phoneNumber = agent?.primaryPhoneNumber ? `| ${formatPhoneNumber(agent?.primaryPhoneNumber)}` : '';
  const brokerageName = agent?.brokerageName ?? '';
  const agentLicenseNumber = generateLicenseNumberLine(agentDreLicenseNumber);
  const salutation = agentDreLicenseNumber
    ? `${agentLicenseNumber}
${s(agent?.email)} ${phoneNumber}
${brokerageName}`
    : `${s(agent?.email)} ${phoneNumber}
${brokerageName}`;

  const result = `${s(agent?.firstName)} ${s(agent?.lastName)}
${salutation}
`;

  return result;
}

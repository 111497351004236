import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { isNavMenuOpenAtom } from 'src/state/navMenuAtom';
import { useIsMedium } from '.';

/*
 * This hook closes the mobile menu on first login to prevent it from blocking the screen
 */
export const useCloseMobileMenu = () => {
  const isMediumAndDown = useIsMedium();
  const [, setNavMenu] = useRecoilState(isNavMenuOpenAtom);
  useEffect(() => {
    if (isMediumAndDown) {
      setNavMenu(false);
    }
  }, [isMediumAndDown, setNavMenu]);
};

import React, { useRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { useWidth } from 'src/hooks';

const useStyles = makeStyles(
  () => ({
    widthContainer: {
      overflow: 'hidden',
      position: 'relative',
      transition: 'width 250ms',
    },
    childContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
    },
  }),
  { classNamePrefix: 'HorizontalCollapse' }
);

export interface HorizontalCollapseProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  /** Should the item currently be shown?  False will collapse the element. */
  in?: boolean;
  /** Allow passing class names to both of the elements that this component creates. */
  classes?: {
    outer?: string;
    inner?: string;
  };
}

/**
 * Similar to the MUI <Collapse /> component except it works in the horizontal direction
 * instead of the vertical direction. (Note: The v5 version of the MIU Collapse component
 * adds an `orientation` prop which would likely eliminate the need for this component).
 */
export const HorizontalCollapse: React.FC<HorizontalCollapseProps> = (props) => {
  const classes = useStyles();
  const { in: inProp, children, className, style = {}, classes: userClasses = {}, ...rest } = props;

  const childContainerRef = useRef<HTMLDivElement>(null);
  const width = useWidth(childContainerRef);

  return (
    <div
      {...rest}
      style={{ ...style, width: inProp ? width! : 0, height: childContainerRef.current?.offsetHeight }}
      className={clsx(className, userClasses.outer, classes.widthContainer)}
    >
      <div ref={childContainerRef} className={clsx(userClasses.inner, classes.childContainer)}>
        {children}
      </div>
    </div>
  );
};

import React, { useState } from 'react';
import { makeStyles, Theme, IconButton, Grow } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import tay from '../images/tay128.png';

const triangle: CSSProperties = {
  display: 'block',
  position: 'absolute',
  content: '" "',
  width: 0,
  height: 0,
  borderStyle: 'solid',
};

const useStyles = makeStyles((theme: Theme) => ({
  headerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '32px 0',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      margin: '16px 0',
    },
  },
  flexLeftCol: {
    flex: 1,
    display: 'flex',
    '& > div': {
      marginLeft: 'auto',
    },
  },
  flexLeftColCollapse: {
    [theme.breakpoints.down('xs')]: {
      flexGrow: 0,
      marginLeft: 16,
    },
  },
  flexMiddleCol: {
    flex: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  flexRightCol: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  logoWrapper: {
    position: 'relative',
  },
  logo: {
    height: 128,
    width: 128,
    border: `1px solid ${theme.colors.grey.grey1}`,
    borderRadius: '100%',
    [theme.breakpoints.down('xs')]: {
      height: 80,
      width: 80,
    },
  },
  tayQuote: {
    ...theme.typography.body2,
    position: 'relative',
    marginLeft: 24,
    marginRight: 24,
    maxWidth: 300,
    backgroundColor: theme.colors.grey.offWhite3,
    padding: '16px 32px',
    borderRadius: '0 16px 16px 16px',
    border: `1px solid ${theme.colors.grey.disable}`,
    '&::after': {
      ...triangle,
      top: 0,
      right: '100%',
      borderWidth: '0 8px 12px 0',
      borderColor: `transparent ${theme.colors.grey.offWhite3} transparent transparent`,
    },
    '&::before': {
      ...triangle,
      top: -1,
      right: 'calc(100% + 1px)',
      borderWidth: '0 9px 13px 0',
      borderColor: `transparent ${theme.colors.grey.disable} transparent transparent`,
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 8,
      marginLeft: 16,
      marginRight: 16,
      maxWidth: '100%',
      width: '100%',
    },
  },
  tayQuoteClose: {
    position: 'absolute',
    top: 4,
    right: 4,
    color: theme.colors.grey.bodyHeader,
  },
  tayQuoteToggle: {
    position: 'absolute',
    right: 0,
    top: '3%',
    background: theme.colors.mainColor.primary,
    borderRadius: '100%',
    border: 'none',
    color: 'white',
    height: 24,
    width: 24,
    fontSize: 17,
    fontWeight: 800,
    textAlign: 'center',
    cursor: 'pointer',
    userSelect: 'none',
  },
}));

type TayProps = {
  tayQuote?: React.ReactNode;
};

export const Tay: React.FunctionComponent<TayProps> = (props) => {
  const classes = useStyles();
  const { tayQuote } = props;

  const [isTayQuoteClosed, setIsTayQuoteClosed] = useState(false);

  return (
    <div className={classes.headerWrapper}>
      <div
        className={clsx(classes.flexLeftCol, props.tayQuote && !isTayQuoteClosed ? classes.flexLeftColCollapse : null)}
      >
        <div />
      </div>
      <div className={classes.flexMiddleCol}>
        <div className={classes.logoWrapper}>
          <img className={classes.logo} src={tay} alt="Tay, your PEAD assistant!" />
          {tayQuote && (
            <button className={classes.tayQuoteToggle} onClick={() => setIsTayQuoteClosed((closed) => !closed)}>
              i
            </button>
          )}
        </div>
      </div>
      <div className={classes.flexRightCol}>
        <Grow
          in={!!tayQuote && !isTayQuoteClosed}
          style={{ transformOrigin: 'top left' }}
          unmountOnExit
          timeout={{ enter: 200, exit: 0 }}
        >
          <div className={classes.tayQuote}>
            {tayQuote}
            <IconButton size="small" onClick={() => setIsTayQuoteClosed(true)} className={classes.tayQuoteClose}>
              <CloseIcon fontSize="inherit" color="inherit" />
            </IconButton>
          </div>
        </Grow>
      </div>
    </div>
  );
};

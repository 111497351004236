import React from 'react';
import { Address, LookupType, MultiSearchResult, WizardStoreApi } from '../../../lib/types';
import { appEnvironments, ENV } from '../../../lib/constants';

export interface AvidStore {
  // creation flow data
  property?: Address;
  propertyTypes: LookupType[];
  selectedProperty?: MultiSearchResult;
  propertyType?: string;

  // general data
  fileId?: number;
  packageId?: string;
  mlsUuid?: string;
}

const defaultStore: WizardStoreApi<AvidStore> = {
  propertyTypes: ['multi'],
  update: () => null,
  reset: () => null,
};

export const AvidStoreContext = React.createContext<WizardStoreApi<AvidStore>>(defaultStore);

export const useCreateAvidStore = (defaultOverride: Partial<AvidStore> = {}): WizardStoreApi<AvidStore> => {
  const [store, setStore] = React.useState<AvidStore>({
    ...defaultStore,
    ...defaultOverride,
    propertyTypes: defaultOverride.fileId ? ['existing'] : defaultStore.propertyTypes,
  });

  const update = (obj: Partial<AvidStore>) => {
    const newStore = { ...store, ...obj };
    setStore(newStore);
    if (ENV !== appEnvironments.PROD) {
      // eslint-disable-next-line no-console
      console.log('STORE UPDATED: ', newStore);
    }
  };

  const reset = () => {
    setStore({ ...defaultStore });
  };

  return { ...store, update, reset };
};

export const AvidStoreProvider = AvidStoreContext.Provider;

export const useAvidStore = () => React.useContext(AvidStoreContext);

import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { FormsFile, combineNames, RepresentationType } from 'src/lib';
import { useGetFiles, useGetDashboardPackages, useTeamMembers } from 'src/hooks';
import { useHideZendeskWidget } from 'src/common';
import { DashboardView } from './components/DashboardView';
import { DashboardProvider, useCreateDashboardApi } from './DashboardContext';
import { DashboardItem, DashboardFilePackage } from './types';

const convertItems = (
  files: FormsFile[],
  packages: FilePackageMap,
  teamMembers: ReturnType<typeof useTeamMembers>['data']
): DashboardItem[] => {
  const teamMemberIdToFullName = teamMembers.reduce((accum, teamMember) => {
    accum[teamMember.userId] = combineNames(teamMember?.firstName, teamMember?.lastName);
    return accum;
  }, {});

  return files.map((file) => ({
    address: file.name,
    representationType: file.representationType as RepresentationType,
    fileId: file.id,
    filePackages: packages[file.id],
    agentFullName: teamMemberIdToFullName[file.ownedBy],
    ownedBy: file.ownedBy,
  }));
};

type FilePackageMap = { [key: number]: DashboardFilePackage[] };

export const Dashboard: React.FC = () => {
  const dashboardApi = useCreateDashboardApi();
  const history = useHistory();
  useHideZendeskWidget();

  const { data: packages } = useGetDashboardPackages(undefined, {
    select: (packages) =>
      packages.reduce<FilePackageMap>((accumulator, current) => {
        if (!accumulator[current.fileId]) {
          accumulator[current.fileId] = [];
        }

        accumulator[current.fileId].push(current);

        return accumulator;
      }, {}),
  });

  const { data: teamMembers } = useTeamMembers();

  const buildItems = useCallback(
    (files: FormsFile[]) => convertItems(files, packages ?? {}, teamMembers),
    [packages, teamMembers]
  );
  const { data: dashboardItems } = useGetFiles('', {
    select: buildItems,
  });

  return (
    <DashboardProvider value={dashboardApi}>
      <DashboardView items={dashboardItems} onItemClick={(fileId) => history.push(`/file/${fileId}/packages`)} />
    </DashboardProvider>
  );
};

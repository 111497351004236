import React from 'react';
import { FormControl, Container, InputAdornment, MenuItem, Select, Theme } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { Home } from '@material-ui/icons';
import { WizardStepProps } from 'src/domain';
import { Navigation, Step } from 'src/common';
import { useAvidStore } from '../store';
import { AvidSteps } from './constants';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    display: 'flex',
    justifyContent: 'center',
  },
  formControl: {
    width: '50%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  select: {
    color: theme.colors.grey.bodyHeader,
    fontSize: 19,
    '&:focus': {
      backgroundColor: 'white',
    },
  },
  selectCarat: {
    fill: theme.colors.grey.bodyHeader,
  },
  homeIcon: {
    marginRight: 5,
  },
}));

const propertyTypeOptions = [
  { display: 'Single Family Home', value: 'single-family' },
  { display: 'Multi-Family Home', value: 'multi-family' },
  { display: 'Commercial', value: 'commercial' },
  { display: 'Land', value: 'land' },
  { display: 'Farm and Ranch', value: 'farm-and-ranch' },
  { display: 'Manufactured Home', value: 'manufactured' },
  { display: 'Condo', value: 'condo' },
  { display: 'Other', value: 'other' },
];

interface PropertyTypeForm {
  propertyType: string;
}

export const AvidPropertyType = ({ goToStep }: WizardStepProps) => {
  const classes = useStyles();
  const store = useAvidStore();
  const defaultPropertyType = store.propertyType ?? propertyTypeOptions[0].value; // todo default should come from mls if available
  const form = useForm<PropertyTypeForm>({
    mode: 'onTouched',
    defaultValues: {
      propertyType: defaultPropertyType,
    },
  });

  const onSubmit = (data: PropertyTypeForm) => {
    store.update({ propertyType: data.propertyType });
    goToStep(AvidSteps.representationType);
  };

  return (
    <Step title="What type of property is this?">
      <Container maxWidth="lg">
        <form className={classes.form} onSubmit={form.handleSubmit(onSubmit)}>
          <Controller
            name="propertyType"
            control={form.control}
            defaultValue={defaultPropertyType}
            rules={{ required: true }}
            render={({ onChange, value, onBlur }) => (
              <FormControl classes={{ root: classes.formControl }} variant="outlined">
                <Select
                  startAdornment={
                    <InputAdornment position="start">
                      <Home className={classes.homeIcon} />
                    </InputAdornment>
                  }
                  classes={{ root: classes.select, icon: classes.selectCarat }}
                  onChange={onChange}
                  value={value}
                  onBlur={onBlur}
                >
                  {propertyTypeOptions.map((option) => (
                    <MenuItem value={option.value} key={option.value}>
                      {option.display}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </form>
      </Container>
      <Navigation
        PrevButtonProps={{ onClick: () => goToStep(AvidSteps.property) }}
        NextButtonProps={{ onClick: form.handleSubmit(onSubmit) }}
      />
    </Step>
  );
};

import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { SecureRoute } from '@skyslope/auth-react';
import { PrimeAuthCallback } from 'src/pages/PrimeAuthCallback';
import Logout from 'src/pages/Logout';
import { landingRoutes, routes, stateLandingRoutes } from './lib';
import {
  Landing,
  Login,
  Register,
  About,
  Contact,
  AuthCallback,
  DisclosuresCreationWizard,
  Dashboard,
  FilePackages,
  SendFormsWizard,
  AvidCreationWizard,
  AvidDetailsWizard,
  TeamPage,
  Settings,
  ResendDisclosuresWizard,
} from './pages';
import { CenteredLoader } from './common';
const GraphiQlPage = lazy(() => import('./pages/GraphiqlPage'));

export const Routes = () => (
  <Suspense fallback={<CenteredLoader />}>
    <Switch>
      <Route path={Object.values(landingRoutes).concat(Object.values(stateLandingRoutes))} exact component={Landing} />
      <Route path={routes.login} component={Login} />
      <Route path={routes.register} component={Register} />
      <Route path={routes.logout} component={Logout} />
      <Route path={routes.loggedOut} component={Logout} />
      <Route path={routes.about} component={About} />
      <Route path={routes.primeAuthCallback} component={PrimeAuthCallback} exact />
      <Route path={routes.authCallback} component={AuthCallback} exact />
      <Route path={routes.contact} component={Contact} />
      <Route path={routes.avidDetails} component={AvidDetailsWizard} />
      <Route path={routes.avidCreation} component={AvidCreationWizard} />
      <Route path={routes.disclosures} component={DisclosuresCreationWizard} />
      <SecureRoute path={routes.sendForms} component={SendFormsWizard} />
      <SecureRoute path={routes.dashboard} component={Dashboard} />
      <SecureRoute path={routes.filePackages} component={FilePackages} />
      <SecureRoute path={routes.resendDisclosures} component={ResendDisclosuresWizard} />
      <SecureRoute path={routes.team} component={TeamPage} />
      <SecureRoute path={routes.settings} component={Settings} />
      <Route path="/gql" component={GraphiQlPage} />
    </Switch>
  </Suspense>
);

import React, { useMemo } from 'react';
import useOktaSignInWidget from 'src/hooks/auth/useOktaSignInWidget';

const LoginWidget = () => {
  const widgetAnchorRef = useOktaSignInWidget();

  return useMemo(() => <div id="login-widget" style={{ display: 'block' }} ref={widgetAnchorRef} />, [widgetAnchorRef]);
};

export default LoginWidget;

import React, { useState } from 'react';
import { WizardStepProps, ContactCollectionStep } from 'src/domain';
import { contactActions, contactTypes } from 'src/lib/constants';
import { useGetUserProfile } from 'src/hooks';
import { useDisclosuresStore } from '../store';
import { SpqTdsDisclosureSteps } from './constants';

/**
 * Once the TC access feature is released, this step will no longer be used.
 */
export const SpqTdsTeamMembersDeprecated = ({ goToStep }: WizardStepProps) => {
  const store = useDisclosuresStore();
  const teamMembers = store.teamMembers;
  const { data: userProfile } = useGetUserProfile();
  const [emailValidationMessage, setEmailValidationMessage] = useState('');

  return (
    <ContactCollectionStep
      title="Add your team members you want to keep informed."
      dontRequireFirstContact
      getBlankContact={() => ({ type: contactTypes.OTHER })}
      defaultContacts={teamMembers.length === 0 ? [{ type: contactTypes.OTHER }] : teamMembers}
      onSubmit={(form) => {
        store.update({
          teamMembers: form.contacts.map((contact) => ({
            ...contact,
            actionAttribute: contactActions.RECEIVESCOPY,
          })),
        });

        goToStep(SpqTdsDisclosureSteps.review);
      }}
      isTeamMember
      NavigationProps={{
        PrevButtonProps: {
          onClick: () => goToStep(SpqTdsDisclosureSteps.review),
          startIcon: null,
        },
        NextButtonProps: {
          endIcon: null,
        },
      }}
      validators={{
        email: (val) => {
          if (val === userProfile?.email) {
            setEmailValidationMessage(
              'Looks like this contact is already a part of your team. Please enter a new contact.'
            );
            return '';
          } else {
            setEmailValidationMessage('');
            return true;
          }
        },
      }}
      validationMessages={{ email: emailValidationMessage }}
    />
  );
};

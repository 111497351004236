import React from 'react';
import { useAuth } from '@skyslope/auth-react';
import { useRecoilValue } from 'recoil';
import { selectedAgentAtom } from 'src/state';
import { WizardStepProps, ContactCollectionStep } from 'src/domain';
import { contactActions, contactTypes } from 'src/lib';
import { useGetContacts, useGetActiveUserProfile } from 'src/hooks';
import { useTayTip } from 'src/domain/wizard/useTayTip';
import { useDisclosuresStore } from '../store';
import { useDisclosuresConditionalSteps } from '../hooks/useDisclosuresConditionalSteps';
import { SpqTdsDisclosureSteps } from './constants';

export const Sellers = (props: WizardStepProps) => {
  const { goToStep } = props;
  const store = useDisclosuresStore();
  const { authState } = useAuth();
  const { data: userProfile } = useGetActiveUserProfile();
  const { data: existingContacts, isLoading } = useGetContacts(store.existingFileId, {
    enabled: store.sellers.length === 0,
  });

  const selectedAgent = useRecoilValue(selectedAgentAtom);
  const { prevStep } = useDisclosuresConditionalSteps(SpqTdsDisclosureSteps.sellers, store.forms || []);

  useTayTip('The first seller entered will fill out the disclosure.');

  const sellers =
    store.sellers.length !== 0
      ? store.sellers
      : existingContacts?.filter((c) => c.type === contactTypes.SELLER).slice(0, 2) ?? [];

  if (isLoading) {
    // todo better loading state
    return null;
  }

  return (
    <ContactCollectionStep
      title="Who is the seller you're sending these forms to?"
      isPossibleEntity
      getBlankContact={() => ({ type: contactTypes.SELLER })}
      defaultContacts={sellers.length === 0 ? [{ type: contactTypes.SELLER }] : sellers}
      NavigationProps={{
        PrevButtonProps: {
          onClick: () => goToStep(prevStep),
          startIcon: null,
        },
        NextButtonProps: {
          endIcon: null,
        },
      }}
      onSubmit={(form) => {
        store.update({
          sellers: form.contacts.map((contact) => ({
            ...contact,
            actionAttribute: contactActions.NEEDSTOSIGN,
          })),
        });

        goToStep(
          !selectedAgent && authState.isAuthenticated && !userProfile?.brokerageName
            ? SpqTdsDisclosureSteps.brokerageName
            : SpqTdsDisclosureSteps.customEmail
        );
      }}
    />
  );
};

import { Box, Button, Container, Divider, Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { Send } from '@material-ui/icons';
import { ReactComponent as EscrowIllustration } from 'src/images/escrow/escrow-blue-circle-illustration.svg';
import { EscrowOfficerListItem } from 'src/pages/SpqTdsDisclosures/steps/Escrow/EscrowChooseOfficerStep/EscrowOfficerListItem';

const useStyles = makeStyles((theme: Theme) => ({
  subText: {
    margin: theme.spacing(2, 0),
    fontSize: theme.typography.pxToRem(24),
    color: theme.colors.grey.bodyHeader,
    letterSpacing: '0.16px',
    textAlign: 'left',
    lineHeight: `${theme.spacing(4.5)}px`,
    fontFamily: 'Avenir Next',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3),
      textAlign: 'left',
    },
  },
  container: {
    backgroundColor: theme.colors.grey.offWhite1,
  },
  textWrapper: {
    padding: theme.spacing(10),
    maxWidth: 700,
    fontFamily: 'inherit',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(4, 0),
    },
  },
  escrowCards: {
    backgroundColor: theme.colors.grey.offWhite1,
    pointerEvents: 'none',
    padding: theme.spacing(10, 0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 0),
      zoom: 0.7,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      zoom: 0.6,
    },
  },
  mainText: {
    fontSize: theme.typography.pxToRem(56),
    lineHeight: `${theme.spacing(9)}px`,
    color: theme.colors.grey.bodyHeader,
    fontFamily: 'inherit',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(48),
      lineHeight: `${theme.spacing(6)}px`,
      marginBottom: theme.spacing(4),
    },
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  openEscrowCard: {
    maxWidth: 359,
    border: `1px solid ${theme.colors.grey.disable}`,
    '--border-radius': `${theme.spacing(1)}px`,
    borderRadius: 'var(--border-radius)',
  },
  image: {
    flexShrink: 0,
  },
  openEscrowCardContent: {
    padding: theme.spacing(2, 3),
    display: 'flex',
    gap: theme.spacing(2),
    flexDirection: 'column',
  },
  title: {
    color: theme.colors.grey.bodyHeader,
    fontSize: theme.typography.pxToRem(19),
    fontWeight: 600,
  },
  subtitle: {
    fontSize: '1rem',
    color: theme.colors.grey.bodyHeader,
  },
  openEscrowCardContentInner: {
    alignItems: 'center',
    display: 'flex',
    gap: 'inherit',
    maxWidth: 525,
    backgroundColor: theme.colors.grey.offWhite1,
  },
  contentRight: {
    marginLeft: 'auto',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginLeft: 0,
    },
  },
  action: {
    padding: theme.spacing(3),
    borderTop: 'inherit',
    backgroundColor: 'white',
  },
  actionButton: {
    width: '100%',
    fontSize: '1rem',
  },
  text: {
    textOverflow: 'ellipsis',
    overflowWrap: 'anywhere',
  },
  escrowInfo: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
  },
  company: {
    borderTop: `1px solid ${theme.colors.grey.disable}`,
    backgroundColor: theme.colors.grey.offWhite2,
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    alignSelf: 'stretch',
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    position: 'relative',
    left: 225,
    bottom: 150,
    maxHeight: 200,
    maxWidth: 327,
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'white',
  },
  escrowCard: {
    maxWidth: '100%',
  },
  icon: {
    height: 30,
    marginLeft: 8,
  },
}));

interface LandingEscrowProps {
  brand: string | undefined;
  companyLogo: string | undefined;
}

export const LandingEscrow: React.FC<LandingEscrowProps> = ({ brand, companyLogo }) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.container}>
      <Container maxWidth="lg">
        <Grid container className={classes.cardContainer}>
          <Grid item md={6} className={classes.textWrapper}>
            <Box>
              <Typography className={classes.mainText} variant="h2">
                Pre-Open escrow
                <br />
                Quick and easy
              </Typography>
              <Typography className={classes.subText} variant="subtitle2" component="div">
                Save time and expedite your sale with pre-open escrow built right into your workflow.
              </Typography>
              <Divider style={{ width: '95%' }} />
              <Typography variant="body1" component="div" className={classes.escrowInfo}>
                In partnership with
                <img src={companyLogo} className={classes.icon} alt="coMarketingLogo" />
              </Typography>
            </Box>
          </Grid>
          <Grid item md={6} className={classes.escrowCards}>
            <div className={classes.openEscrowCard}>
              <div className={classes.openEscrowCardContent}>
                <div className={classes.openEscrowCardContentInner}>
                  <EscrowIllustration className={classes.image} />
                  <Typography variant="body2" className={classes.title}>
                    Pre-Open escrow to close deals faster
                  </Typography>
                </div>
                <Typography variant="subtitle2" className={classes.subtitle}>
                  Share your transaction information with your preferred contact to obtain a preliminary title and
                  escrow number.
                </Typography>
              </div>
              <div className={classes.action}>
                <Button className={classes.actionButton} color="primary" startIcon={<Send />} variant="contained">
                  Pre-Open escrow
                </Button>
              </div>
            </div>
            <Paper className={classes.paper} elevation={1}>
              <EscrowOfficerListItem
                className={classes.escrowCard}
                checked
                escrowOfficer={{
                  firstName: 'Jackie',
                  lastName: 'Lewis',
                  fullName: 'Jackie Lewis',
                  phoneNumber: '',
                  email: 'email@escrow.com',
                  address: '',
                  brand: brand ?? '',
                }}
                isLoading={false}
              ></EscrowOfficerListItem>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

import { UseQueryOptions, useQuery } from 'react-query';
import { getUserMeta, UserSettingsMeta } from 'src/lib';
import { useGetActiveUserProfile } from '.';

export const getUserProfileMetaKey = 'get-user-profile-meta';

/**
 * Gets User Profile Meta Data from Forms for Auth. Not to be confused with user-meta from Breeze db.
 */
export const useGetUserProfileMeta = (options: UseQueryOptions<UserSettingsMeta> = {}) => {
  // active user profile means we use either the current logged in agent
  // or the person they are working on behalf of
  const { data: userProfile } = useGetActiveUserProfile();
  return useQuery<UserSettingsMeta>(getUserProfileMetaKey, async () => await getUserMeta(userProfile!.id), {
    ...options,
    enabled: Boolean(userProfile?.id && (options.enabled == null || options.enabled)),
  });
};

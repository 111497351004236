import React, { useCallback, useEffect } from 'react';
import { Container, Theme, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import * as Sentry from '@sentry/react';
import { Navigation, Step } from 'src/common';
import { WizardStepProps } from 'src/domain';
import { useGetFileById, useCustomSnackbar } from 'src/hooks';
import { RepresentationType, defaultWizardRoutes, ErrorCodes } from 'src/lib';
import buyerDefault from 'src/images/buyerDefault.svg';
import buyerHover from 'src/images/buyerHover.svg';
import sellerDefault from 'src/images/sellerDefault.svg';
import sellerHover from 'src/images/sellerHover.svg';
import { SurferBoiLoading } from 'src/common/SurferBoiLoading';
import { useMlsImportToFile } from 'src/hooks/domain/mutations/useMlsImportToFile';
import { AvidPackageDTO } from '../../types';
import { createAvidPackageMutationKey, useCreateAvidPackage } from '../../hooks';
import { useAvidStore } from '../store';
import { AvidSteps } from './constants';
import { RepTypeButton } from './RepTypeButton';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
}));

export const AvidRepresentationType = ({ goToStep }: WizardStepProps) => {
  const classes = useStyles();
  const { fileId, property: propertyFromStore, propertyType, mlsUuid, update: updateStore } = useAvidStore();
  const { data: existingFile, isLoading: isLoadingFile } = useGetFileById(fileId);
  const property = existingFile && !isLoadingFile ? undefined : propertyFromStore;
  // todo default should come from mls if available
  const [representationType, setRepresentationType] = React.useState<RepresentationType | ''>('');
  const snackbarController = useCustomSnackbar();

  const repTypeOptions = [
    {
      value: RepresentationType.SELLER,
      hoverImage: sellerHover,
      defaultImage: sellerDefault,
    },
    {
      value: RepresentationType.BUYER,
      hoverImage: buyerHover,
      defaultImage: buyerDefault,
    },
  ];
  const { mutate: createPackage, isIdle, isLoading: isCreatingPackage } = useCreateAvidPackage();
  const { mutate: importMlsToFile } = useMlsImportToFile();

  const history = useHistory();

  const onSuccess = useCallback(
    (result: AvidPackageDTO) => {
      updateStore({
        fileId: result.fileId,
        packageId: result.packageId,
      });

      if (mlsUuid) {
        importMlsToFile({ uid: mlsUuid, fileId: result.fileId, shouldOverride: false });
      }

      snackbarController.addInfoSnackbar('File created! All of your AVID progress will be saved in this file.', {
        autoHideDuration: 6_000,
      });
      history.push(defaultWizardRoutes.avidDetails.replace(':packageId', result.packageId));
    },
    [history, updateStore, snackbarController, importMlsToFile, mlsUuid]
  );

  const handleNext = (data: RepresentationType) => {
    createPackage(
      {
        property,
        existingFileId: fileId,
        propertyType: propertyType ?? '',
        representationType: data,
      },
      {
        onSuccess,
        onError(e) {
          console.error('An error occurred while creating AVID (in rep type)');
          const eventId = Sentry.captureException(e);

          snackbarController.addErrorSnackbar({
            errorCode: ErrorCodes.CreateAvidPackage,
            eventId,
            message:
              "Sorry, we couldn't create your AVID at this time.  Please contact support if the problem persists.",
            options: {
              noAutoDismiss: true,
            },
          });
        },
      }
    );
  };

  useEffect(() => {
    if (existingFile && isIdle && !isLoadingFile) {
      createPackage(
        {
          property,
          existingFileId: fileId,
          propertyType: propertyType ?? '',
        },
        {
          onSuccess,
        }
      );
    }
  }, [existingFile, createPackage, property, fileId, isIdle, propertyType, onSuccess, isLoadingFile]);

  if (existingFile || isCreatingPackage) {
    return (
      <SurferBoiLoading
        loadingStatuses={['Creating AVID..']}
        mutationKey={createAvidPackageMutationKey}
        title="Saving your progress!"
      />
    );
  }

  return (
    <Step title="Who are you representing for this property?">
      <Container maxWidth="sm" className={classes.wrapper}>
        {repTypeOptions.map((repType, i) => (
          <RepTypeButton
            key={i}
            isActive={representationType === repType.value}
            onClick={() => setRepresentationType(repType.value)}
            defaultImage={repType.defaultImage}
            hoverImage={repType.hoverImage}
            data-testid={repType.value?.toLowerCase()}
          >
            {repType.value}
          </RepTypeButton>
        ))}
      </Container>
      <Navigation
        isLoading={isCreatingPackage}
        PrevButtonProps={{
          onClick: () => goToStep(AvidSteps.propertyType),
        }}
        NextButtonProps={{
          onClick: () => representationType && handleNext(representationType),
          disabled: isLoadingFile || !representationType,
        }}
      />
    </Step>
  );
};

import React from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import { CenteredLoader, Navigation, Step, TextInput } from 'src/common';
import { WizardStepProps } from 'src/domain';
import { useGetAvidPackage, useUpdateAvidPackage } from '../../hooks';
import { AvidSteps } from '../../AvidCreation/steps';
import { useGetWeatherConditions } from '../../hooks/useGetWeatherConditions';

interface WeatherInputs {
  weather: string;
}

export const AvidWeather = ({ goToStep }: WizardStepProps) => {
  const { packageId } = useParams<{ packageId: string }>();
  const { data: avidPackage } = useGetAvidPackage(packageId);
  const { mutate: updateAvid, isLoading: isUpdating } = useUpdateAvidPackage();
  const { data: weather, isLoading: isLoading } = useGetWeatherConditions(avidPackage?.fileId, {
    enabled: !avidPackage?.workflowData.inspectionWeather,
  });
  const temp = weather?.main.temp;
  const description = weather?.weather[0].description;

  const { register, handleSubmit } = useForm<WeatherInputs>({
    mode: 'onTouched',
  });

  const onSubmit = handleSubmit((data: WeatherInputs) => {
    updateAvid(
      {
        packageId,
        inspectionWeather: data.weather,
      },
      {
        onSuccess: () => goToStep(AvidSteps.peoplePresent),
      }
    );
  });

  const getWeather = () => {
    if (avidPackage?.workflowData.inspectionWeather) {
      return avidPackage?.workflowData.inspectionWeather;
    } else if (![temp, description].includes(undefined)) {
      return `${Math.floor(parseInt(temp!))} and ${description}`;
    } else {
      return '';
    }
  };

  if (!avidPackage || isLoading) {
    return <CenteredLoader />;
  }
  return (
    <Step title="What was the weather like on the day of the inspection?">
      <form onSubmit={onSubmit}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={6}>
            <TextInput
              label="Weather conditions"
              placeholder="example: 97 and sunny"
              fullWidth
              variant="outlined"
              defaultValue={getWeather()}
              inputRef={register}
              name="weather"
            />
          </Grid>
        </Grid>
      </form>
      <Navigation
        isLoading={isUpdating}
        PrevButtonProps={{ onClick: () => goToStep(AvidSteps.inspectionDateTime) }}
        NextButtonProps={{
          onClick: onSubmit,
          disabled: !avidPackage,
        }}
      />
    </Step>
  );
};

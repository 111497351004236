import React from 'react';
import { useSetRecoilState } from 'recoil';
import { WizardStepProps, SelectOnBehalfOfAgentStep } from 'src/domain';
import { AvidSteps } from 'src/pages';
import { selectedAgentAtom } from 'src/state';

export const AvidSelectOnBehalfOfAgent = (props: WizardStepProps) => {
  const { goToStep } = props;
  const setSelectedAgent = useSetRecoilState(selectedAgentAtom);

  return (
    <SelectOnBehalfOfAgentStep
      {...props}
      id="avid-select-on-behalf-of-agent-step"
      title="Who are you working on behalf of?"
      previousStepName=""
      onNextClick={(selectedTeamMember) => {
        setSelectedAgent(selectedTeamMember);
        goToStep(AvidSteps.property);
      }}
      onAddTeamMember={() => goToStep(AvidSteps.addTeamMember)}
    />
  );
};

import React, { ReactNode } from 'react';
import { Typography, Button } from '@material-ui/core';
import pluralize from 'pluralize';
import { useIsMobile } from 'src/hooks';
import { Step } from 'src/common';
import { Contact } from 'src/lib';
import { useStyles as useReviewRowStyles } from './ReviewRow';
import { RecipientText } from './RecipientText';
import { ReviewMyTeamRow } from './ReviewMyTeamRow';

export interface ReviewProps {
  icon: ReactNode;
  teamMembers: Contact[];
  recipients: Contact[];
  children?: ReactNode;
  navigation: ReactNode;
  onEditTeam: () => void;
  onEditRecipients: () => void;
}

export const ReviewStep = (props: ReviewProps) => {
  const rowClasses = useReviewRowStyles();

  const isMobile = useIsMobile();
  const { recipients, onEditRecipients } = props;
  const isMissingRecipients = !recipients.length;

  return (
    <Step title="Please review and make any necessary edits.">
      <section>
        <article className={rowClasses.sectionRow}>
          {!isMobile && <div className={rowClasses.icon}>{props.icon}</div>}
          <section className={rowClasses.contentColumn}>
            <Typography variant="body2" className={rowClasses.headerText}>
              {`${pluralize('Recipient', recipients?.length ?? 0)}:`}
            </Typography>
            <RecipientText hasError={isMissingRecipients}>
              {recipients.map((recipient, i) => (
                <React.Fragment key={i}>
                  {recipient.isEntity
                    ? `${recipient.companyName} (${recipient.email})`
                    : `${recipient.firstName} ${recipient.lastName} (${recipient.email})`}
                  <br />
                </React.Fragment>
              ))}
              {isMissingRecipients && <>Missing recipients</>}
            </RecipientText>
          </section>
          <Button
            variant="contained"
            size="small"
            disableElevation
            onClick={() => onEditRecipients()}
            color={isMissingRecipients ? 'primary' : undefined}
          >
            {isMissingRecipients ? 'Add' : 'Edit'}
          </Button>
        </article>

        <ReviewMyTeamRow {...props} />
        {props.children}
      </section>
      {props.navigation}
    </Step>
  );
};

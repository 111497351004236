import React, { useMemo } from 'react';
import { useAuth } from '@skyslope/auth-react';
import { useFlags, useInitialQueryParams, useTeamMembers } from 'src/hooks';
import { Wizard, StepOptions } from 'src/domain';
import { defaultWizardRoutes } from 'src/lib';
import {
  AvidEmail,
  AvidLogin,
  AvidMissingPropertyInfo,
  AvidPasswordSuccess,
  AvidProperty,
  AvidPropertyType,
  AvidRecoverPassword,
  AvidRegister,
  AvidRepresentationType,
  AvidSkyslope,
  AvidSteps,
  AvidSelectOnBehalfOfAgent,
  AvidAddTeamMember,
} from './steps';
import { AvidStoreProvider, useCreateAvidStore } from './store';
import { useAvidCreationProgress } from './useAvidCreationProgress';

export const avidCreationSteps: StepOptions[] = [
  {
    key: AvidSteps.email,
    component: AvidEmail,
  },
  {
    key: AvidSteps.login,
    component: AvidLogin,
  },
  {
    key: AvidSteps.register,
    component: AvidRegister,
  },
  {
    key: AvidSteps.recoverPassword,
    component: AvidRecoverPassword,
  },
  {
    key: AvidSteps.recoverPasswordSuccess,
    component: AvidPasswordSuccess,
  },
  {
    key: AvidSteps.loginSkyslope,
    component: AvidSkyslope,
  },
  {
    key: AvidSteps.selectOnBehalfOfAgent,
    component: AvidSelectOnBehalfOfAgent,
  },
  {
    key: AvidSteps.addTeamMember,
    component: AvidAddTeamMember,
  },
  {
    key: AvidSteps.property,
    component: AvidProperty,
  },
  {
    key: AvidSteps.missingPropertyInfo,
    component: AvidMissingPropertyInfo,
  },
  {
    key: AvidSteps.propertyType,
    component: AvidPropertyType,
  },
  {
    key: AvidSteps.representationType,
    component: AvidRepresentationType,
  },
];

export const AvidCreationWizard: React.FC = () => {
  // When the user clicks the "send for signature" button on the file packages
  // page, they are redirected to an initial wizard page with query parameters
  // that are immediately removed once the page renders.  We want to store the
  // original value of those query parameters so that they are used throughout
  // that wizard instance, despite no longer being in the URL.
  // This was the cause of the bug: https://skyslope.atlassian.net/browse/INNO2-858
  const queryParams = useInitialQueryParams();

  const fileId = parseInt(queryParams.get('fileId') ?? '');
  const packageId = queryParams.get('packageId') ?? undefined;
  const defaultStepOverride = queryParams.get('default');
  const avidStore = useCreateAvidStore({
    fileId: isNaN(fileId) ? undefined : fileId,
    packageId,
  });

  const { data: teamMembers } = useTeamMembers({ includePending: true });
  const hasTeamMembers = teamMembers.length > 0;

  const { authState } = useAuth();
  const { tcAccess: isTcAccessEnabledFlag } = useFlags();

  const defaultStep = useMemo(() => {
    if (authState.isAuthenticated && defaultStepOverride) {
      return defaultStepOverride as AvidSteps;
    } else if (!authState.isAuthenticated) {
      return AvidSteps.email;
    } else if (isTcAccessEnabledFlag && hasTeamMembers) {
      return AvidSteps.selectOnBehalfOfAgent;
    } else {
      return AvidSteps.property;
    }
  }, [authState, defaultStepOverride, isTcAccessEnabledFlag, hasTeamMembers]);

  const progress = useAvidCreationProgress();

  return (
    <AvidStoreProvider value={avidStore}>
      <Wizard
        steps={avidCreationSteps}
        defaultStep={defaultStep}
        prefix={defaultWizardRoutes.avidCreation.slice(1)}
        progress={progress}
      />
    </AvidStoreProvider>
  );
};

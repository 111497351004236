import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, Button, Popover, Typography, Tooltip } from '@material-ui/core';
import {
  AccountCircleSharp as AccountCircleIcon,
  ArrowDropDownSharp as DownArrowIcon,
  ControlPointSharp as PlusIcon,
} from '@material-ui/icons';
import { useRecoilValue } from 'recoil';
import { useHistory } from 'react-router';
import { useFlags, useAnchorEl, useGetUserProfile, useTeamMembers } from 'src/hooks';
import { selectedAgentAtom } from 'src/state';
import { routes } from 'src/lib';
import { SelectAgentList } from './SelectAgentList';

const useStyles = makeStyles<Theme, { isOpen: boolean }>(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      textTransform: 'none',
      fontSize: theme.typography.pxToRem(14),
      backgroundColor: (p) => (p.isOpen ? theme.colors.grey.offWhite3 : 'transparent'),
      color: theme.colors.grey.bodyHeader,
    },
    popupRoot: {
      transform: 'translateY(16px)',
    },
    popupPaper: {
      maxHeight: 'calc(100% - 90px)',
      width: '400px',
    },
    titleDiv: {
      position: 'sticky',
      top: 0,
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      background: 'white',
      zIndex: 100,
    },
    title: {
      fontSize: theme.typography.pxToRem(19),
      fontWeight: 'bold',
    },
    addTeamMemberButton: {
      background: 'white',
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      position: 'sticky',
      bottom: 0,
    },
    radioGroup: {
      margin: `0 ${-1 * theme.spacing(3)}px`,
      display: 'flex',
      flexDirection: 'column',
      padding: '24px',
      paddingTop: '0px',
    },
    tooltip: { fontSize: '1rem' },
    searchBar: {
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      borderTop: `1px solid ${theme.colors.grey.disable}`,
    },
  }),
  { classNamePrefix: 'SelectAgentDropdown' }
);

function formatName(person?: { firstName?: string; lastName?: string }) {
  return person ? `${person.firstName} ${person.lastName}` : person;
}

export interface SelectAgentProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  disabled?: boolean;
  disabledMessage?: string;
}

/**
 * A dropdown shown on desktop to allow TCs to switch the agent
 * they're acting on behalf of.
 */
export const SelectAgentDropdown: React.FC<SelectAgentProps> = (props) => {
  const { className, disabled, disabledMessage, ...rest } = props;

  const selectedAgent = useRecoilValue(selectedAgentAtom);
  const { tcAccess: isTcAccessEnabledFlag } = useFlags();
  const history = useHistory();

  const { anchorEl, isOpen, handleClick, close } = useAnchorEl();
  const classes = useStyles({ ...props, isOpen });

  const { data: userProfile, isSuccess: isUserProfileAvailable } = useGetUserProfile();
  const { isSuccess: isTeamAvailable } = useTeamMembers();

  const noUserProfile = !isUserProfileAvailable || !userProfile; // User profile can be null when the wizard is being used for sign in

  if (!isTcAccessEnabledFlag || !isTeamAvailable || noUserProfile) {
    return null;
  }

  return (
    <div {...rest} className={clsx(className, classes.root)}>
      {/* The MUI typing for title is wrong */}
      <Tooltip title={(disabledMessage ?? '') as React.ReactFragment} classes={{ tooltip: classes.tooltip }}>
        <span>
          <Button
            startIcon={<AccountCircleIcon />}
            endIcon={<DownArrowIcon />}
            className={classes.button}
            size="small"
            onClick={handleClick}
            disabled={disabled}
          >
            {selectedAgent != null && 'Viewing as '}
            {formatName(selectedAgent || userProfile)}
          </Button>
        </span>
      </Tooltip>
      <Popover
        id="select-agent"
        classes={{ root: classes.popupRoot, paper: classes.popupPaper }}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={close}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={classes.titleDiv}>
          <Typography className={classes.title} id="select-agent-title">
            Switch accounts:
          </Typography>
        </div>

        <SelectAgentList
          aria-labelledby="select-agent-title"
          className={classes.radioGroup}
          onChooseAgent={() => close()}
          classes={{
            searchBar: classes.searchBar,
          }}
        />

        <div className={classes.addTeamMemberButton}>
          <Button startIcon={<PlusIcon />} size="small" color="primary" onClick={() => history.push(routes.team)}>
            Add a teammate
          </Button>
        </div>
      </Popover>
    </div>
  );
};

import React from 'react';
import { ArrowForward } from '@material-ui/icons';
import { AccountActionNextRoute } from 'src/lib';
import { WizardStepProps, Email } from 'src/domain';
import { AvidSteps } from './constants';

export const AvidEmail = ({ goToStep }: WizardStepProps) => {
  const nextRoutes: AccountActionNextRoute = {
    register: AvidSteps.register,
    none: AvidSteps.login,
    legacy: AvidSteps.loginSkyslope,
  };

  return (
    <Email
      onSuccess={(action) => {
        goToStep(nextRoutes[action]);
      }}
      title={"We're excited to have you! Enter your email to get started."}
      NavigationProps={{ NextButtonProps: { endIcon: <ArrowForward /> } }}
    />
  );
};

import { useMutation, UseMutationOptions } from 'react-query';
import { gql } from 'graphql-request';
import { useRecoilValue } from 'recoil';
import * as Sentry from '@sentry/react';
import { selectedAgentAtom } from 'src/state';
import { ErrorCodes, getGraphQLClient, getApiToken } from '../../../lib';
import { useCustomSnackbar } from '../../useCustomSnackbar';

export interface SendDisclosuresToFormsPayload {
  packageId: string;
}

const mutationQuery = gql`
  mutation sendDisclosuresToForms($packageId: String!, $onBehalfOf: String) {
    sendDisclosuresToForms(packageId: $packageId, onBehalfOf: $onBehalfOf)
  }
`;

export const sendDisclosuresToFromsMutationKey = 'send-disclosures-to-forms-mutation';

export const useSendDisclosuresToFormsMutation = (
  options: UseMutationOptions<unknown, unknown, SendDisclosuresToFormsPayload> = {}
) => {
  const { addErrorSnackbar } = useCustomSnackbar();
  const selectedAgent = useRecoilValue(selectedAgentAtom);

  return useMutation(
    async (payload: SendDisclosuresToFormsPayload) => {
      const client = getGraphQLClient(await getApiToken());
      return (
        await client.request<{ sendDisclosuresToForms: boolean }, { packageId: string; onBehalfOf?: string }>(
          mutationQuery,
          {
            packageId: payload.packageId,
            onBehalfOf: selectedAgent?.userId,
          }
        )
      ).sendDisclosuresToForms;
    },
    {
      ...options,
      mutationKey: sendDisclosuresToFromsMutationKey,
      onError(e) {
        console.error('An error occurred while sending disclosures to SkySlope Forms');
        const eventId = Sentry.captureException(e);
        addErrorSnackbar({
          message: 'Your disclosures failed to export, please try again.',
          errorCode: ErrorCodes.SendDisclosuresToForms,
          eventId,
        });
      },
    }
  );
};

import { useState, useEffect, useMemo, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { useLocation } from 'react-router';
import {
  useDebounce,
  useGetEscrowOfficerList,
  useGetEscrowOfficerProfile,
  useGetFileById,
  useGetFilePackages,
  useIsMobile,
  useUpdateDefaultEscrowOfficer,
} from 'src/hooks';
import { EscrowOfficer, contactTypes, formatAddress } from 'src/lib';
import { useDisclosuresStore } from 'src/pages/SpqTdsDisclosures/store';
import { selectedAgentAtom } from 'src/state';
import { WizardStepProps } from 'src/domain';
import { SpqTdsDisclosureSteps } from '../../constants';

export type UseChooseEscrowOfficerArgs = {
  goToStep: WizardStepProps['goToStep'];
  doNotFetchEscrowOfficerList?: boolean;
};

/**
 * Hook to handle data fetching, caching, and manipulation for the
 * Escrow Choose Officer page.
 */
export function useChooseEscrowOfficer(args: UseChooseEscrowOfficerArgs) {
  const { goToStep } = args;
  const store = useDisclosuresStore();
  const storeRef = useRef(store);
  const isMobile = useIsMobile();

  const { data: file, isFetched: isExistingFileFetched } = useGetFileById(store.existingFileId);
  const { data: escrowOfficerProfile, isFetched: isEscrowOfficerProfileFetched } = useGetEscrowOfficerProfile({
    onBehalfOf: file?.ownedBy,
  });

  const { data: apn } = useGetFilePackages(file?.id, {
    enabled: !file?.id,
    select: (packages) => packages?.find((p) => p.type === 'disclosures')?.workflowData?.apn,
  });
  const location = useLocation<{ customEscrowOfficer?: EscrowOfficer | null }>();
  const [activeEscrowOfficer, setActiveEscrowOfficer] = useState<EscrowOfficer | undefined>(
    location.state?.customEscrowOfficer ??
      storeRef.current.escrowOfficer ??
      escrowOfficerProfile?.defaultOfficer ??
      undefined
  );
  const selectedAgent = useRecoilValue(selectedAgentAtom);

  const [saveAsDefaultEscrow, setSaveAsDefaultEscrow] = useState(false);
  const [hasUserChangedDefaultCheckbox, setHasUserChangedDefaultCheckbox] = useState(false);
  const [isStoreUpdated, setIsStoreUpdated] = useState(false);

  const FILTER_MIN_LENGTH = 3;
  const [filterText, setFilterText] = useState('');
  const debouncedFilterText = useDebounce(filterText);
  const isDebouncedFilterValid = debouncedFilterText.length >= FILTER_MIN_LENGTH;

  const {
    data: escrowOfficerSearchResults,
    isFetching,
    isLoading,
    hasNextPage,
    fetchNextPage,
  } = useGetEscrowOfficerList(
    {
      searchQuery: isDebouncedFilterValid ? debouncedFilterText : undefined,
      address: file ? formatAddress(file.fileData.premisesAddress) : '',
      ...(selectedAgent ? { onBehalfOf: selectedAgent.userId } : {}),
    },
    { staleTime: Infinity }
  );

  const activeEscrowOfficerRef = useRef(activeEscrowOfficer);
  activeEscrowOfficerRef.current = activeEscrowOfficer;

  const _recentlyUsedEscrowOfficers = escrowOfficerProfile?.recentlyUsedOfficers;

  const mobileRecentlyUsedEOs = activeEscrowOfficer ? [activeEscrowOfficer] : _recentlyUsedEscrowOfficers;

  const desktopRecentlyUsedEOs = useMemo(() => {
    const isActiveInRecent =
      activeEscrowOfficerRef.current &&
      _recentlyUsedEscrowOfficers?.some((officer) => officer.email === activeEscrowOfficerRef.current?.email);

    if (activeEscrowOfficerRef.current && !isActiveInRecent) {
      return [activeEscrowOfficerRef.current, ...(_recentlyUsedEscrowOfficers || [])];
    } else {
      return _recentlyUsedEscrowOfficers;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_recentlyUsedEscrowOfficers, debouncedFilterText]);

  // Fill the store with data from the existing file
  useEffect(() => {
    if (!isExistingFileFetched || !isEscrowOfficerProfileFetched) return;

    const defaultOfficer = escrowOfficerProfile?.defaultOfficer;

    storeRef.current.update({
      property: file?.fileData.premisesAddress,
      sellers: file?.contacts.filter((c) => c.type === contactTypes.SELLER),
      apn,
      escrowOfficer: storeRef.current.escrowOfficer || defaultOfficer,
    });
    setIsStoreUpdated(true);
  }, [
    isExistingFileFetched,
    file?.fileData.premisesAddress,
    file?.contacts,
    isEscrowOfficerProfileFetched,
    escrowOfficerProfile?.defaultOfficer,
    apn,
  ]);

  const escrowOfficers: EscrowOfficer[] | undefined = useMemo(
    () => (escrowOfficerSearchResults ? escrowOfficerSearchResults.pages.flatMap((x) => x.escrowOfficers) : undefined),
    [escrowOfficerSearchResults]
  );

  // Default active escrow officer to the user's default escrow officer
  useEffect(() => {
    if (!isEscrowOfficerProfileFetched || activeEscrowOfficer) return;
    const defaultOfficer = escrowOfficerProfile?.defaultOfficer;
    setActiveEscrowOfficer(defaultOfficer);
  }, [isEscrowOfficerProfileFetched, activeEscrowOfficer, setActiveEscrowOfficer, escrowOfficerProfile]);

  // Update the default checkbox to be active when the user picks their
  // default officer, until the user manually toggles the checkbox, then
  // don't change it anymore.
  useEffect(() => {
    if (hasUserChangedDefaultCheckbox) return;

    const defaultOfficer = escrowOfficerProfile?.defaultOfficer;
    const isDefaultOfficerActive =
      activeEscrowOfficer && defaultOfficer && activeEscrowOfficer.email === defaultOfficer.email;

    setSaveAsDefaultEscrow(!!isDefaultOfficerActive);
  }, [activeEscrowOfficer, escrowOfficerProfile, hasUserChangedDefaultCheckbox]);

  const { mutate: updateDefaultEscrowOfficer } = useUpdateDefaultEscrowOfficer();

  async function handleSubmit() {
    if (saveAsDefaultEscrow && activeEscrowOfficer) {
      updateDefaultEscrowOfficer({ escrowOfficer: activeEscrowOfficer });
    }
    if (!saveAsDefaultEscrow) {
      updateDefaultEscrowOfficer({ escrowOfficer: null });
    }
    store.update({ escrowOfficer: activeEscrowOfficer });
    goToStep(SpqTdsDisclosureSteps.escrowComposeEmail);
  }

  return {
    activeEscrowOfficer,
    setActiveEscrowOfficer,
    saveAsDefaultEscrow,
    setSaveAsDefaultEscrow(isChecked: boolean) {
      setHasUserChangedDefaultCheckbox(true);
      setSaveAsDefaultEscrow(isChecked);
    },
    filterText,
    setFilterText,
    debouncedFilterText,
    isDebouncedFilterValid,
    isFetching,
    isLoading,
    escrowOfficers,
    hasNextPage,
    fetchNextPage,
    handleSubmit,
    isStoreUpdated,
    recentlyUsedEscrowOfficers: isMobile ? mobileRecentlyUsedEOs : desktopRecentlyUsedEOs,
  };
}

import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, Divider, List, Box, ListProps } from '@material-ui/core';
import {
  Home as HomeIcon,
  Description as DescriptionIcon,
  ImageSearch as ImageSearchIcon,
  Help as HelpIcon,
  ExitToApp as StevieLeave,
  People as PeopleIcon,
  AppsSharp,
  Settings as SettingsIcon,
} from '@material-ui/icons';
import { useAuth } from '@skyslope/auth-react';
import { useHistory, useLocation } from 'react-router';
import { useRecoilState } from 'recoil';
import { useFlags, useIsMedium, useIsAvidAvailable } from 'src/hooks';
import { ReactComponent as FormsIcon } from 'src/images/formsIcon.svg';
import { ReactComponent as SkyslopeIcon } from 'src/images/skyslope-no-text.svg';
import { ReactComponent as BooksIcon } from 'src/images/booksIcon.svg';
import { config, defaultWizardRoutes, landingRoutes, routes } from 'src/lib';
import { registerDestinationAtom, selectedAgentAtom } from 'src/state';
import { isNavMenuOpenAtom } from 'src/state/navMenuAtom';
import { NavigationFolder } from './NavigationFolder';
import { NavigationMenuRow } from './NavigationMenuRow';

const Tabs = {
  HOME: landingRoutes.landing,
  DASHBOARD: routes.dashboard,
  DISCLOSURES: routes.disclosuresStart,
  AVID: `/${routes.avidDetails.split('/')[1]}`,
  AVID_CREATION: routes.avidCreationStart,
  TEAM: routes.team,
  SETTINGS: routes.settings,
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowX: 'hidden',
  },
  formsIcon: {
    height: 18,
    width: 18,
    color: 'inherit',
  },
  formsButtonLabel: {
    fontWeight: 'bold',
    color: theme.colors.grey.bodyHeader,
  },
  formsText: {
    marginLeft: 8,
    marginRight: 32,
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  logoutDivider: {
    marginTop: 'auto',
  },
}));

export const NavigationMenu: React.FC<ListProps> = (props) => {
  const classes = useStyles();
  const { children, className, ...rest } = props;
  const [navMenuOpen, setNavMenuOpen] = useRecoilState(isNavMenuOpenAtom);
  const isMediumAndDown = useIsMedium();

  const { userContext } = useAuth();
  const history = useHistory();
  const { tcAccess: isTcAccessEnabledFlag, enableBooksLink } = useFlags();
  const [activeTab, setActiveTab] = useState('');
  const location = useLocation();
  const [selectedAgent, setSelectedAgent] = useRecoilState(selectedAgentAtom);
  const { data: showAvid } = useIsAvidAvailable();
  const isSkyslopeUser = !!userContext?.prime?.userId;
  const [, setRegisterDestination] = useRecoilState(registerDestinationAtom);

  useEffect(() => {
    for (const tab of Object.keys(Tabs) as Array<keyof typeof Tabs>) {
      if (location.pathname.includes(Tabs[tab])) {
        setActiveTab(Tabs[tab]);
      }
    }
  }, [navMenuOpen, location.pathname]);

  const logout = () => {
    history.push(routes.logout);
  };

  return (
    <>
      <List {...rest} className={clsx(classes.root, className)} component="nav">
        {children}

        <NavigationMenuRow
          name="Home"
          icon={<HomeIcon />}
          isSelected={activeTab === Tabs.HOME || activeTab === Tabs.DASHBOARD}
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            if (isMediumAndDown) setNavMenuOpen(false);
          }}
          id="home-nav"
          isMenuOpen={navMenuOpen}
          to="/"
        />
        {userContext && (
          <NavigationMenuRow
            name="Start New Disclosures"
            icon={<DescriptionIcon />}
            isSelected={activeTab === Tabs.DISCLOSURES}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.stopPropagation();
              if (activeTab !== Tabs.DISCLOSURES) history.push(routes.disclosuresStart);
              if (isMediumAndDown) setNavMenuOpen(false);
            }}
            id="disclosures-nav"
            isMenuOpen={navMenuOpen}
          />
        )}
        {userContext && showAvid && (
          <NavigationMenuRow
            name="Start New AVID"
            icon={<ImageSearchIcon />}
            isSelected={activeTab === Tabs.AVID || activeTab === Tabs.AVID_CREATION}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              e.stopPropagation();
              if (activeTab !== Tabs.AVID) history.push(defaultWizardRoutes.avidCreation);
              if (isMediumAndDown) setNavMenuOpen(false);
            }}
            id="avid-nav"
            isMenuOpen={navMenuOpen}
          />
        )}
        <Box mb={2}>
          <Divider variant="middle" />
        </Box>
        {userContext && (
          <>
            {isTcAccessEnabledFlag && (
              <NavigationMenuRow
                name="My Team"
                icon={<PeopleIcon />}
                isSelected={activeTab === Tabs.TEAM}
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  e.stopPropagation();
                  if (activeTab !== Tabs.TEAM) history.push(routes.team);
                  if (selectedAgent != null) setSelectedAgent(null);
                  if (isMediumAndDown) setNavMenuOpen(false);
                }}
                id="forms-nav"
                isMenuOpen={navMenuOpen}
              />
            )}

            <NavigationFolder
              isMenuOpen={navMenuOpen}
              folderMenuItem={
                <NavigationMenuRow
                  name="Apps"
                  icon={<AppsSharp />}
                  isSelected={false}
                  id="apps-nav"
                  isMenuOpen={navMenuOpen}
                />
              }
            >
              {isSkyslopeUser && (
                <NavigationMenuRow
                  name="SkySlope"
                  icon={
                    <SkyslopeIcon
                      className={classes.formsIcon}
                      aria-label="Skyslope"
                      style={{ transform: 'translateX(4px)' }}
                      viewBox="0 0 83 83"
                    />
                  }
                  isSelected={false}
                  to={config.skyslopeAppUrl}
                  id="skyslope-app-nav"
                  isMenuOpen={navMenuOpen}
                />
              )}

              <NavigationMenuRow
                name="SkySlope Forms"
                icon={
                  <FormsIcon
                    viewBox="0 0 93 95"
                    className={classes.formsIcon}
                    aria-label="Forms"
                    style={{ transform: 'translateX(3px)' }}
                  />
                }
                isSelected={false}
                id="forms-nav"
                isMenuOpen={navMenuOpen}
                to={`${config.formsApiUrl}/login`}
                openNewTab
              />
              {isSkyslopeUser && enableBooksLink && (
                <NavigationMenuRow
                  name="SkySlope Books"
                  icon={<BooksIcon aria-label="Skyslope Books" style={{ transform: 'translateX(4px)' }} />}
                  isSelected={false}
                  to={config.skyslopeBooksUrl}
                  id="skyslope-books-nav"
                  isMenuOpen={navMenuOpen}
                />
              )}
            </NavigationFolder>
          </>
        )}
        <NavigationFolder
          isMenuOpen={navMenuOpen}
          folderMenuItem={
            <NavigationMenuRow name="Get Help" icon={<HelpIcon />} id="help-nav" isMenuOpen={navMenuOpen} />
          }
        >
          <NavigationMenuRow
            name="Training"
            isSelected={false}
            onClick={() => window.open('https://support.skyslope.com/hc/en-us/articles/5629020917019', '_blank')}
            id="training-nav"
            isMenuOpen={navMenuOpen}
          />
          <NavigationMenuRow
            name="Support"
            isSelected={false}
            onClick={() => window.open('https://support.skyslope.com/hc/en-us', '_blank')}
            id="support-nav"
            isMenuOpen={navMenuOpen}
          />
          <NavigationMenuRow
            name="Privacy Policy"
            isSelected={false}
            onClick={() => window.open('https://skyslope.com/privacy-policy/', '_blank')}
            id="privacy-nav"
            isMenuOpen={navMenuOpen}
          />
          <NavigationMenuRow
            name="Terms of Use"
            isSelected={false}
            onClick={() => window.open('https://skyslope.com/terms-conditions/', '_blank')}
            id="terms-nav"
            isMenuOpen={navMenuOpen}
          />
        </NavigationFolder>
        {userContext && (
          <div className={classes.logoutDivider}>
            <Box mb={2}>
              <Divider />
            </Box>
            <NavigationMenuRow
              name="Settings"
              icon={<SettingsIcon />}
              isSelected={activeTab === Tabs.SETTINGS}
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation();
                if (activeTab !== Tabs.SETTINGS) {
                  setRegisterDestination(routes.dashboard); // used when returning from SelectRegionsStep in settings wizard
                  history.push(routes.settings);
                }
                if (isMediumAndDown) setNavMenuOpen(false);
              }}
              id="settings-nav"
              isMenuOpen={navMenuOpen}
            />
            <NavigationMenuRow
              name="Logout"
              icon={<StevieLeave />}
              isSelected={false}
              onClick={logout}
              id="logout-nav"
              isMenuOpen={navMenuOpen}
            />
          </div>
        )}
      </List>
    </>
  );
};

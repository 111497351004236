import React from 'react';
import { Finished, WizardStepProps } from 'src/domain';
import { config } from 'src/lib/constants';
import { useDisclosuresStore } from '../store';
import { SpqTdsDisclosureSteps } from './constants';

export const SpqTdsFinished = (props: WizardStepProps) => {
  const store = useDisclosuresStore();

  const startOver = () => {
    store.reset();
    props.goToStep(SpqTdsDisclosureSteps.property);
  };

  return (
    <Finished
      startAgain={startOver}
      title="Lorem ipsum dolor"
      body="Lorem ipsum dolor sit amet, consectetur adipiscing eli."
      viewText="View Sent"
      onNext={() => window.open(`${config.formsApiUrl}/file-details/${store.existingFileId}/documents`, '_blank')}
    />
  );
};

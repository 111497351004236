import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core';
import { CheckSharp as CheckmarkIcon } from '@material-ui/icons';
import { getInitials } from 'src/lib';

const sizes = {
  small: 40,
  medium: 48,
  large: 64,
};

const fontSizes: typeof sizes = {
  small: 16,
  medium: 19,
  large: 22,
};

const useStyles = makeStyles<Theme, { size: keyof typeof sizes }>(
  (theme: Theme) => ({
    root: {
      background: theme.colors.grey.offWhite2,
      border: `1px solid ${theme.colors.grey.disable}`,
      color: theme.colors.grey.bodyHeader,
      borderRadius: '50%',
      width: (p) => sizes[p.size],
      height: (p) => sizes[p.size],
      fontSize: (p) => fontSizes[p.size],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: (p) => `0 0 ${sizes[p.size]}px`,
    },
    checkmarkIcon: {
      color: theme.colors.mainColor.primary,
    },
    profileImage: {
      width: (p) => sizes[p.size],
      height: (p) => sizes[p.size],
      borderRadius: '50%',
      objectFit: 'cover',
    },
  }),
  { classNamePrefix: 'initialsCircle' }
);

export interface InitialsCircleProps extends React.HtmlHTMLAttributes<HTMLElement> {
  children?: React.ReactNode;
  firstName?: string;
  lastName?: string;
  firstAndLastName?: string;
  checked?: boolean;
  size?: keyof typeof sizes;
  image?: string;
}

/**
 * A small circle with a border intended to contain someones initials.
 * Like what you might see as a default profile picture when no image has been
 * uploaded.
 */
export const InitialsCircle: React.FC<InitialsCircleProps> = (props) => {
  const {
    children,
    className,
    firstName,
    lastName,
    firstAndLastName,
    checked,
    size = 'medium',
    image,
    ...rest
  } = props;
  const classes = useStyles({ size });

  const initials =
    children ||
    getInitials({
      firstName,
      lastName,
      firstAndLastName,
    });

  return (
    <div {...rest} className={clsx(className, classes.root)}>
      {checked ? (
        <CheckmarkIcon className={classes.checkmarkIcon} />
      ) : image ? (
        <img src={image} className={classes.profileImage} alt={firstAndLastName || `${firstName} ${lastName}`} />
      ) : (
        initials
      )}
    </div>
  );
};

import React from 'react';
import { RecoverPassword, WizardStepProps } from 'src/domain';
import { getBaseAddress, defaultWizardRoutes } from 'src/lib';
import { AvidSteps } from './constants';

const baseAddress = getBaseAddress();
const relayUrl = `${baseAddress}${defaultWizardRoutes.avidCreation}/${AvidSteps.email}`;

export const AvidRecoverPassword = (props: WizardStepProps) => (
  <RecoverPassword
    relayUrl={relayUrl}
    onSuccess={() => props.goToStep(AvidSteps.recoverPasswordSuccess)}
    onBack={() => props.goToStep(AvidSteps.login)}
  />
);

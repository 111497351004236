import axios from 'axios';
import { config } from '../constants';
import { Address, PropertySearchGroupName, PropertySearchMatchType } from '../types';

type MLSServiceResponse = {
  uid: string;
  propertyId: string;
  photoUrl: string | null;
  displayAddress: string | null;
  yearBuilt: string;
  zoning: string;
  propertyAddress: {
    streetNumber: string;
    streetName: string;
    streetType?: string;
    streetDirection?: string;
    unitNumber?: string;
    city?: string;
    state?: string;
    zipCode?: string;
    county?: string;
  };
  listingAgentSummaryInfo: {
    email: string | null;
  };
  listingAgent: {
    firstName: string | null;
    lastName: string | null;
  };
  propertyAttributes: PropertyAttributes;
  propertyFeatures: PropertyFeatures;
  matchType: PropertySearchMatchType;
  groupName: PropertySearchGroupName;
};

export type MLSListingAgent = {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phone?: string | null;
};

export type PropertyAttributes = {
  parcelNumber: string | null;
  zoning: string | null;
};

export type PropertyFeatures = {
  isSingleFamily: string | null;
  isMultiFamily: string | null;
  isCommercial: string | null;
  isLot: string | null;
  isFarmHobby: string | null;
  isMobileHome: string | null;
  isCondo: string | null;
  isOther: string | null;
};

export type MLSListing = {
  mlsNumber: string;
  photoUrl: string | null;
  propertyAddress: string;
  listingAgent?: MLSListingAgent;
  parcelNumber: string | null;
  zoning: string | null;
  address: Partial<Address>;
  matchType: PropertySearchMatchType;
  groupName: PropertySearchGroupName;
  yearBuilt: string;
  propertyType?: string;
  uuid: string;
};

export interface MLSSearchRequest {
  searchTerm: string;
  city?: string;
  state?: string;
  zipCode?: string;
  resultSize?: number;
  states?: Array<string>;
}

export const DEFAULT_STATE = '';
export const DEFAULT_MLS_RESULT_SIZE = 10;

export const convertSearchTermToSearchRequest = (searchTerm: string): MLSSearchRequest => {
  const searchRequest: MLSSearchRequest = {
    searchTerm,
    city: '',
    state: '',
    zipCode: '',
    states: [],
  };
  const searchParts = searchTerm.split(',');

  searchRequest.searchTerm = searchParts[0]?.trim() ?? '';
  searchRequest.city = searchParts[1]?.trim() ?? '';

  if (searchParts.length > 3) {
    // Assume if there are more than 3 parts the format is:
    // "street, city, state, zipCode"
    searchRequest.state = searchParts[2]?.trim() ?? '';
    searchRequest.zipCode = searchParts[3]?.trim() ?? '';
  } else {
    // Assume if there are 3 parts or less the format is:
    // "street, city, state zipCode"
    const searchTermStateAndZipCode = searchParts[2]?.trim() ?? '';
    const separatorIndex = searchTermStateAndZipCode.lastIndexOf(' ');
    if (separatorIndex > -1) {
      searchRequest.state = searchTermStateAndZipCode.substring(0, separatorIndex);
      searchRequest.zipCode = searchTermStateAndZipCode.substring(separatorIndex + 1);
    } else {
      searchRequest.state = searchTermStateAndZipCode;
    }
  }

  return searchRequest;
};

const convertHomeType = (homeTypeResult: PropertyFeatures): string | undefined => {
  if (homeTypeResult.isSingleFamily) return 'single-family';
  if (homeTypeResult.isMultiFamily) return 'multi-family';
  if (homeTypeResult.isCommercial) return 'commercial';
  if (homeTypeResult.isLot) return 'land';
  if (homeTypeResult.isFarmHobby) return 'farm-and-ranch';
  if (homeTypeResult.isMobileHome) return 'manufactured-home';
  if (homeTypeResult.isCondo) return 'condo';
  if (homeTypeResult.isOther) return 'other';

  return undefined;
};

const buildAddress = (listing: MLSServiceResponse, includeCounty = false) => {
  const { streetNumber, streetDirection, streetName, streetType, city, state, zipCode, county } =
    listing.propertyAddress;
  const street = [streetNumber, streetDirection, streetName, streetType].filter(Boolean).join(' ');
  return includeCounty
    ? `${street}, ${city ?? ''}, ${state ?? ''} ${zipCode ?? ''}${county ? `, ${county}` : ''}`
    : `${street}, ${city ?? ''}, ${state ?? ''} ${zipCode ?? ''}`;
};

const resultFields = `
  uid
  propertyId
  photoUrl
  displayAddress
  yearBuilt
  propertyAddress {
    streetNumber
    streetName
    streetType
    streetDirection
    unitNumber
    city
    state
    zipCode
    county
  }
  listingAgentSummaryInfo {
    email
    phone
  }
  listingAgent {
    firstName
    lastName
  }
  propertyAttributes {
    parcelNumber
    zoning
  }
  propertyFeatures {
    isCondo
    isCommercial
    isSingleFamily
    isMultiFamily
    isFarmHobby
    isOther
    isMobileHome
    isLot
  }
`;

const buildSearchQuery = (
  resolverName: string,
  searchTermLabel: string,
  searchRequest: MLSSearchRequest,
  useMongoDB: boolean
): string => {
  let query = `${resolverName} ( ${searchTermLabel}: "${searchRequest.searchTerm}", 
  ${useMongoDB ? 'searchType: "MONGODB"' : ''}, resultSize: ${searchRequest.resultSize}`;

  const city = searchRequest.city?.trim();
  const state = searchRequest.state?.trim();
  const states = searchRequest.states;
  const zipCode = searchRequest.zipCode?.trim();

  if (city != null && city !== '') {
    query += `, city: "${city}"`;
  }
  if (state != null && state !== '') {
    query += `, state: "${state}"`;
  }
  if (states != null && states.length > 0) {
    query += `, states: ["${states.join('","')}"]`;
  }
  if (zipCode != null && zipCode !== '') {
    query += `, zipCode: "${zipCode}"`;
  }
  query += `) { ${resultFields} }`;

  return query;
};

export const mlsGetListings = async (searchRequest: MLSSearchRequest, useMongoDB: boolean): Promise<MLSListing[]> => {
  const query = `{
    ${buildSearchQuery('getListingsByAddress', 'searchString', searchRequest, useMongoDB)}
    ${buildSearchQuery('getListingsByMlsNumber', 'mlsNumber', searchRequest, useMongoDB)}
  }`;

  const postHeaders = {
    headers: {
      ApplicationName: 'BREEZE',
    },
  };

  const res = await axios.post(config.mlsServiceUrl, { query }, postHeaders);
  let listings: MLSServiceResponse[] = [];

  if (res?.data?.data != null) {
    const { getListingsByAddress, getListingsByMlsNumber } = res?.data?.data;
    const listingsByAddress = getListingsByAddress?.map((listing: MLSListing) => {
      listing.matchType = 'MLS Address';
      listing.groupName = 'MLS';
      return listing;
    });
    const listingsByMlsNumber = getListingsByMlsNumber?.map((listing: MLSListing) => {
      listing.matchType = 'MLS #';
      listing.groupName = 'MLS';
      return listing;
    });
    listings = listingsByAddress.concat(listingsByMlsNumber);
  }

  return (
    listings
      // Listings data is inconsistent and either contains displayAddress (string version) or propertyAddress (object version)
      .filter((item) => item.displayAddress || item.propertyAddress?.streetNumber)
      .map((item) => ({
        mlsNumber: item.propertyId,
        photoUrl: item.photoUrl,
        listingAgent: { ...item.listingAgent, ...item.listingAgentSummaryInfo },
        propertyAddress: item.propertyAddress?.streetNumber ? buildAddress(item) : item.displayAddress!,
        parcelNumber: item.propertyAttributes?.parcelNumber,
        zoning: item.propertyAttributes?.zoning,
        yearBuilt: item.yearBuilt,
        propertyType: convertHomeType(item.propertyFeatures),
        address: {
          streetAddress: `${item.propertyAddress.streetNumber} ${item.propertyAddress.streetName}`,
          city: item.propertyAddress.city,
          state: item.propertyAddress.state,
          postalCode: item.propertyAddress.zipCode,
          unitNumber: item.propertyAddress.unitNumber,
          county: item.propertyAddress.county,
        },
        matchType: item.matchType,
        groupName: item.groupName,
        uuid: item.uid,
      }))
  );
};

import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import * as Sentry from '@sentry/react';
import { CustomEmailForm, WizardStepProps } from 'src/domain';
import { CenteredLoader, useComposeEmail } from 'src/common';
import { Contact, ErrorCodes, formatAddress, usStates } from 'src/lib';
import { useCustomSnackbar, useGetActiveUserProfile, useResendInterviews } from 'src/hooks';
import { useResendDisclosuresStore } from 'src/pages/ResendDisclosures/store';
import { ResendDisclosuresSteps } from 'src/pages/ResendDisclosures/steps/constants';
import { PackageForm } from 'src/pages/FilePackages/types';
import { generateAgentSignature, s } from 'src/common/ComposeEmail/emailUtil';

export const CustomEmailStep = (props: WizardStepProps) => {
  const store = useResendDisclosuresStore();
  const history = useHistory();
  const snackbarController = useCustomSnackbar();
  const { mutate: resendPackageInterviews, isLoading: isResending } = useResendInterviews();
  const { data: agentUserProfile, isFetched: hasFetchedAgentProfile } = useGetActiveUserProfile();

  const isLoading = !hasFetchedAgentProfile || isResending;
  const emailState = useComposeEmail();
  const packageId = store.packageId;

  useEffect(() => {
    if (isLoading || !store.sellers) return;
    const sellers = store.sellers;
    const address = formatAddress(store.property);
    const state = store.property?.state;
    const resentForms = store.forms.filter((f) => store.selectedInterviewIds.includes(f.interviewId));

    const { defaultSubject, defaultBody, defaultSignature } = generateDefaultEmailText({
      address,
      state,
      sellers,
      agent: agentUserProfile,
      agentDreLicenseNumber: agentUserProfile?.licenseNumber,
      resentForms,
    });

    emailState.setSubject(defaultSubject);
    emailState.setBody(defaultBody);
    emailState.setSignature(defaultSignature);
    emailState.setRecipients(() => {
      const sellerEmail = sellers[0].email;
      return sellerEmail ? [sellerEmail, ''] : [''];
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, store]);

  const onSubmit = async () => {
    resendPackageInterviews(
      {
        packageId,
        specificInterviewIds: store.selectedInterviewIds,
        emailDetails: {
          subject: emailState?.subject?.toString(),
          to: emailState?.recipients[0]?.toString(),
          emailContents: {
            body: emailState?.body?.toString() ?? '',
            signature: emailState?.signature?.toString() ?? '',
          },
        },
      },
      {
        onSuccess() {
          goToFilePackagesPage();
          snackbarController.addSuccessSnackbar('Your disclosures have been resent.');
        },
        onError(e) {
          console.error('An error occurred while resending package interviews', e);
          const eventId = Sentry.captureException(e);

          snackbarController.addErrorSnackbar({
            message: 'Your disclosures failed to resend, please try again.',
            errorCode: ErrorCodes.ResendDisclosurePackageInterviews,
            eventId,
          });
        },
      }
    );
  };

  function goToFilePackagesPage() {
    history.push(`/file/${store.fileId}/packages`);
  }

  function handleCancel() {
    props.goToStep(ResendDisclosuresSteps.selectDisclosures);
  }

  return isLoading ? (
    <CenteredLoader />
  ) : (
    <CustomEmailForm
      onBack={handleCancel}
      onSend={onSubmit}
      editRecipient={() => {}}
      title="Would you like to customize your message?"
      hideCc
      bodyRowCount={16}
      emailState={emailState}
      NavigationProps={{
        isLoading: isLoading,
        PrevButtonProps: {
          disabled: isLoading,
          children: 'Back',
        },
        NextButtonProps: {
          children: 'Resend',
          endIcon: null,
          style: { textTransform: 'none', padding: '12px 24px' },
        },
      }}
    />
  );
};

function generateDefaultEmailText(args: {
  address: string | undefined;
  state: string | undefined;
  sellers: Contact[];
  agent:
    | { firstName?: string; lastName?: string; primaryPhoneNumber?: string; email?: string; brokerageName?: string }
    | undefined;
  agentDreLicenseNumber: string | undefined;
  resentForms: PackageForm[] | undefined;
}) {
  const { address, state, sellers, agent, agentDreLicenseNumber, resentForms } = args;
  const defaultSignature = generateAgentSignature({ state, agent, agentDreLicenseNumber });
  const formattedState = state !== undefined ? usStates[state] : 'your state';
  const formattedResentForms = resentForms?.map((f) => `- ${s(f.name)}`).join(`
`);
  const defaultBody = `Hi ${s(sellers[0].firstName)},

It’s time to finish your disclosures for ${s(address)}.

In ${formattedState}, home sellers are required to communicate all known issues that can potentially influence the worth or appeal of their property. Our simple step-by-step flow will walk you through each question to help you complete your disclosures easily and accurately.

In order to move forward, you must complete all of your disclosure forms. Here are the forms you are missing:

${s(formattedResentForms)}

Please reach out if you have any questions, I’m always here to help.
`;

  return {
    defaultSubject: `Finish your seller disclosure for ${s(address)}`,
    defaultBody,
    defaultSignature,
  };
}

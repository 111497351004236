import React, { createContext, useMemo, useState } from 'react';
import { SklosuresFlag } from './types';

export interface FlaggedQuestionsDrawerContext {
  isDrawerOpen: boolean;
  setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedFlag: SklosuresFlag | undefined;
  setSelectedFlag: React.Dispatch<React.SetStateAction<SklosuresFlag | undefined>>;
  openMessages: boolean;
  setOpenMessages: React.Dispatch<React.SetStateAction<boolean>>;
  onQuestionSelect: () => void;
  setOnQuestionSelect: React.Dispatch<React.SetStateAction<() => void>>;
}
export const FlaggedQuestionsDrawerContext = createContext<FlaggedQuestionsDrawerContext>({
  isDrawerOpen: false,
  setIsDrawerOpen: () => {},
  selectedFlag: undefined,
  setSelectedFlag: () => {},
  openMessages: false,
  setOpenMessages: () => {},
  onQuestionSelect: () => {},
  setOnQuestionSelect: () => {},
});

export const FlaggedQuestionsDrawerContextProvider: React.FC = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedFlag, setSelectedFlag] = useState<SklosuresFlag>();
  const [openMessages, setOpenMessages] = useState(false);

  const [onQuestionSelect, setOnQuestionSelect] = useState(() => () => {});
  const value = useMemo(
    () => ({
      isDrawerOpen,
      setIsDrawerOpen,
      onQuestionSelect,
      setOnQuestionSelect,
      selectedFlag,
      setSelectedFlag,
      openMessages,
      setOpenMessages,
    }),
    [isDrawerOpen, onQuestionSelect, selectedFlag, openMessages]
  );

  return <FlaggedQuestionsDrawerContext.Provider value={value}>{children}</FlaggedQuestionsDrawerContext.Provider>;
};

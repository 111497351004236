import React from 'react';
import { useRecoilValue } from 'recoil';
import { useGetUserProfile, useTeamMembers } from 'src/hooks';
import { selectedAgentAtom } from 'src/state';
import { ReviewRow } from './ReviewRow';
import { ReviewProps } from './ReviewStep';
import { RecipientText } from './RecipientText';

export const ReviewMyTeamRow: React.FC<ReviewProps> = (props) => {
  const { teamMembers: contacts, onEditTeam } = props;

  const selectedAgent = useRecoilValue(selectedAgentAtom);
  const { data: userProfile } = useGetUserProfile();
  const { data: teamMembers } = useTeamMembers({ includePending: true });

  const agentText = `${selectedAgent?.firstName} ${selectedAgent?.lastName} (Agent)`;

  // The user can get to this point in the disclosures flow by using buttons from
  // the landing page without ever creating an account to give us their name
  const weHaveUsersName = userProfile?.firstName && userProfile.lastName;
  const userText = weHaveUsersName ? `${userProfile?.firstName || ''} ${userProfile?.lastName || ''} (Me)` : '';

  return (
    <ReviewRow
      label="My Team"
      tooltipText="Add your Transaction Coordinator, Co-Agent, or any other team members to keep them in all email communications"
      rightSideContent={<ReviewRow.Button onClick={onEditTeam}>{contacts.length ? 'Edit' : 'Add'}</ReviewRow.Button>}
    >
      {selectedAgent && <RecipientText>{agentText}</RecipientText>}
      {userText && <RecipientText>{userText}</RecipientText>}

      {contacts
        ?.filter((c) => c.email !== userProfile?.email)
        .map((contact, i) => {
          const isNameAvailable = contact.firstName && contact.lastName;
          const isApproved = teamMembers.find((tm) => tm.email === contact.email)?.isApproved;
          const isPending = isApproved === false;

          return (
            <RecipientText key={i}>
              {isPending && isNameAvailable && (
                <>
                  {contact.firstName} {contact.lastName} <NotBold>(Pending)</NotBold>
                </>
              )}
              {isPending && !isNameAvailable && (
                <>
                  {contact.email} <NotBold>(Pending)</NotBold>
                </>
              )}
              {!isPending && !isNameAvailable && contact.email}
              {!isPending && isNameAvailable && `${contact.firstName} ${contact.lastName}`}
            </RecipientText>
          );
        })}
    </ReviewRow>
  );
};

function NotBold(props: { children: React.ReactNode }) {
  return <span style={{ fontWeight: 400 }}>{props.children}</span>;
}

import { useLocation } from 'react-router-dom';
import { GetEnumKeyByValue, landingRoutes, stateLandingRoutes, usStates } from 'src/lib';
import chicagoTitleLogo from 'src/images/escrow/title-companies/chicago-title-logo.png';
import fidelityTitleLogo from 'src/images/escrow/title-companies/fidelity-title-logo.png';
import humboldtTitleLogo from 'src/images/escrow/title-companies/humboldt-title-logo.png';
import lawyersTitleLogo from 'src/images/escrow/title-companies/lawyers-title-logo.png';
import ticorTitleLogo from 'src/images/escrow/title-companies/ticor-title-logo.png';
import grandcanyonTitleLogo from 'src/images/escrow/title-companies/grandcanyon-title-logo.png';
import securityTitleLogo from 'src/images/escrow/title-companies/security-title-logo.png';
import chicagofidelityTitleLogo from 'src/images/escrow/title-companies/chicagofidelity-title-logo.png';
import westernTitleLogo from 'src/images/escrow/title-companies/western-title-logo.png';
import naviTitleLogo from 'src/images/escrow/title-companies/navi-title-logo.png';
import alamoTitleLogo from 'src/images/escrow/title-companies/alamo-title-logo.png';
import austinTitleLogo from 'src/images/escrow/title-companies/austin-title-logo.png';
import charterTitleLogo from 'src/images/escrow/title-companies/charter-title-logo.png';
import startexTitleLogo from 'src/images/escrow/title-companies/startex-title-logo.png';
import westtexasTitleLogo from 'src/images/escrow/title-companies/west-texas-title-logo.png';
import cascadeTitleLogo from 'src/images/escrow/title-companies/cascade-title-logo.png';
import chicagoTitleFavIcon from 'src/images/escrow/title-companies/chicago_favicon.png';
import fidelityTitleFavIcon from 'src/images/escrow/title-companies/fidelity_favicon.png';
import humboldtTitleFavIcon from 'src/images/escrow/title-companies/humboldt_favicon.png';
import lawyersTitleFavIcon from 'src/images/escrow/title-companies/lawyers_favicon.png';
import ticorTitleFavIcon from 'src/images/escrow/title-companies/ticor_favicon.png';
import grandcanyonTitleFavIcon from 'src/images/escrow/title-companies/grandcanyon_favicon.png';
import securityTitleFavIcon from 'src/images/escrow/title-companies/security_favicon.png';
import chicagofidelityTitleFavIcon from 'src/images/escrow/title-companies/chicagofidelity_favicon.png';
import westernTitleFavIcon from 'src/images/escrow/title-companies/western_favicon.png';
import naviTitleFavIcon from 'src/images/escrow/title-companies/navi_favicon.png';
import alamoTitleFavIcon from 'src/images/escrow/title-companies/alamo_favicon.png';
import austinTitleFavIcon from 'src/images/escrow/title-companies/austin_favicon.png';
import charterTitleFavIcon from 'src/images/escrow/title-companies/charter_favicon.png';
import startexTitleFavIcon from 'src/images/escrow/title-companies/startex_favicon.png';
import westtexasTitleFavIcon from 'src/images/escrow/title-companies/west_texas_favicon.png';
import cascadeTitleFavIcon from 'src/images/escrow/title-companies/cascade_favicon.png';

import { useFlags } from 'src/hooks';

type CoMarketingContent = {
  isCoMarketing: boolean;
  brand: string | undefined;
  logo: string | undefined;
  favIcon: string | undefined;
};

export const useIsCoMarketing = (): CoMarketingContent => {
  const { isEscrowOfficerEnabled } = useFlags();
  const location = useLocation();
  if (!isEscrowOfficerEnabled) {
    return {
      brand: '',
      logo: '',
      favIcon: '',
      isCoMarketing: false,
    };
  }
  const pathName = location.pathname;
  switch (pathName) {
    case landingRoutes.chicago:
      return {
        brand: 'Chicago Title',
        logo: chicagoTitleLogo,
        favIcon: chicagoTitleFavIcon,
        isCoMarketing: true,
      };
    case landingRoutes.fidelity:
      return {
        brand: 'Fidelity National Title',
        logo: fidelityTitleLogo,
        favIcon: fidelityTitleFavIcon,
        isCoMarketing: true,
      };
    case landingRoutes.humboldt:
      return {
        brand: 'Humboldt Land Title',
        logo: humboldtTitleLogo,
        favIcon: humboldtTitleFavIcon,
        isCoMarketing: true,
      };
    case landingRoutes.landing:
      return {
        brand: '',
        logo: '',
        favIcon: '',
        isCoMarketing: false,
      };
    case landingRoutes.lawyers:
      return {
        brand: 'Lawyers Title',
        logo: lawyersTitleLogo,
        favIcon: lawyersTitleFavIcon,
        isCoMarketing: true,
      };
    case landingRoutes.ticor:
      return {
        brand: 'Ticor Title',
        logo: ticorTitleLogo,
        favIcon: ticorTitleFavIcon,
        isCoMarketing: true,
      };
    case landingRoutes.grandcanyon:
      return {
        brand: 'Grand Canyon Title',
        logo: grandcanyonTitleLogo,
        favIcon: grandcanyonTitleFavIcon,
        isCoMarketing: true,
      };
    case landingRoutes.security:
      return {
        brand: 'Security Title',
        logo: securityTitleLogo,
        favIcon: securityTitleFavIcon,
        isCoMarketing: true,
      };
    case landingRoutes.chicagofidelity:
      return {
        brand: '',
        logo: chicagofidelityTitleLogo,
        favIcon: chicagofidelityTitleFavIcon,
        isCoMarketing: true,
      };
    case landingRoutes.western:
      return {
        brand: 'Western Title & Escrow',
        logo: westernTitleLogo,
        favIcon: westernTitleFavIcon,
        isCoMarketing: true,
      };
    case landingRoutes.navi:
      return {
        brand: 'Navi Title Agency',
        logo: naviTitleLogo,
        favIcon: naviTitleFavIcon,
        isCoMarketing: true,
      };
    case landingRoutes.alamo:
      return {
        brand: 'Alamo Title',
        logo: alamoTitleLogo,
        favIcon: alamoTitleFavIcon,
        isCoMarketing: true,
      };
    case landingRoutes.austin:
      return {
        brand: 'Austin Title',
        logo: austinTitleLogo,
        favIcon: austinTitleFavIcon,
        isCoMarketing: true,
      };
    case landingRoutes.charter:
      return {
        brand: 'Charter Title',
        logo: charterTitleLogo,
        favIcon: charterTitleFavIcon,
        isCoMarketing: true,
      };
    case landingRoutes.startex:
      return {
        brand: 'StarTex Title',
        logo: startexTitleLogo,
        favIcon: startexTitleFavIcon,
        isCoMarketing: true,
      };
    case landingRoutes.westtexas:
      return {
        brand: 'West Texas Title',
        logo: westtexasTitleLogo,
        favIcon: westtexasTitleFavIcon,
        isCoMarketing: true,
      };
    case landingRoutes.cascade:
      return {
        brand: 'Cascade Title',
        logo: cascadeTitleLogo,
        favIcon: cascadeTitleFavIcon,
        isCoMarketing: true,
      };
    default:
      return {
        brand: '',
        logo: '',
        favIcon: '',
        isCoMarketing: false,
      };
  }
};

export const useDefaultSelectedRegion = (): usStates | null => {
  const location = useLocation();
  const pathName = location.pathname;
  if (pathName === landingRoutes.landing) {
    return null;
  }

  // handles special case where "of" is lowercase in District of Columbia
  if (pathName === stateLandingRoutes.districOfColumbia) {
    return usStates.DC;
  }
  return usStates[
    GetEnumKeyByValue(
      usStates,
      pathName
        .slice(1)
        .replace(/([A-Z])/g, ' $1')
        .trim()
    )
  ];
};

import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { AvidSteps } from './steps';
import { avidCreationSteps } from './AvidCreationWizard';

export const MAX_AVID_CREATION_PROGRESS = 30;

export const useAvidCreationProgress = (): number => {
  const { stepName } = useParams<{ stepName: string }>();
  const currentAvidStepIndex = avidCreationSteps.findIndex((step) => step.key === stepName);
  const firstAvidStepIndex = avidCreationSteps.findIndex((step) => step.key === AvidSteps.property);

  return useMemo(() => {
    if (currentAvidStepIndex === 0) {
      return 0;
    } else if (currentAvidStepIndex < firstAvidStepIndex) {
      return 10;
    } else if (stepName === AvidSteps.property) {
      return 20;
    } else if (stepName === AvidSteps.propertyType) {
      return 25;
    } else {
      return MAX_AVID_CREATION_PROGRESS;
    }
  }, [stepName, currentAvidStepIndex, firstAvidStepIndex]);
};

import React, { useEffect, useState } from 'react';
import { makeStyles, Grid, Theme } from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { useTayTip } from 'src/domain/wizard/useTayTip';
import { NumberControl, Step, Navigation } from 'src/common';
import { useIsMobile } from 'src/hooks';
import { AvidRoom, RoomBuckets } from '../../../types';
import { AvidRoomOption } from '../../../hooks/useGetAvidRoomOptions';
import { RoomItem } from './RoomItem';
import { getRoomIcon } from './getRoomIcon';

export interface HomeBaseEditProps {
  rooms: AvidRoom[];
  options: AvidRoomOption[];
  buckets: RoomBuckets;
  onSave: (roomCounts: RoomCounts) => void;
  onCancel: () => void;
  isInitialCreateFlow: boolean;
}

export interface RoomCounts {
  [roomType: string]: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  unselectedNumberControlTextColor: {
    color: theme.colors.grey.bodyHeader,
  },
  unselectedNumberControlBackground: {
    backgroundColor: theme.colors.grey.offWhite1,
  },
}));

export const HomeBaseEdit: React.FC<HomeBaseEditProps> = ({
  options,
  buckets,
  onSave,
  onCancel,
  isInitialCreateFlow,
}) => {
  const isMobile = useIsMobile();
  const [counts, setCounts] = useState<RoomCounts>(
    Object.assign({}, ...options.map((o) => ({ [o.roomType]: buckets[o.roomType]?.length ?? 0 })))
  );
  const classes = useStyles();
  const title = isInitialCreateFlow
    ? 'Does this account for all of the rooms in the house?'
    : 'What changes would you like to make to your selected rooms?';

  useTayTip(isInitialCreateFlow && 'We added room information based on your MLS listing.');

  useEffect(() => {
    setCounts(Object.assign({}, ...options.map((o) => ({ [o.roomType]: buckets[o.roomType]?.length ?? 0 }))));
  }, [options, buckets, setCounts]);

  return (
    <Step title={title}>
      <Grid container spacing={3} justifyContent="center">
        {options.map((option) => {
          const isSelected = counts[option.roomType] > 0;
          const onSetCount = (value: number) =>
            setCounts((cur) => ({
              ...cur,
              [option.roomType]: value,
            }));

          const onToggle = () => onSetCount(counts[option.roomType] === 0 ? 1 : 0);
          return (
            <RoomItem
              key={option.roomType}
              isSelected={isSelected}
              display={option.displayName}
              icon={getRoomIcon(option.roomType)}
              onClick={option.max < 2 ? onToggle : undefined}
              data-testid={`room-${option.roomType}`}
              actions={
                option.max > 1 ? (
                  <NumberControl
                    PaperProps={{
                      elevation: isMobile && isSelected ? 4 : 0,
                      className: isMobile && !isSelected ? classes.unselectedNumberControlBackground : undefined,
                    }}
                    ValueTypographyProps={
                      isSelected
                        ? undefined
                        : {
                            className: classes.unselectedNumberControlTextColor,
                          }
                    }
                    IncrementIconButtonProps={
                      isSelected
                        ? undefined
                        : {
                            className: classes.unselectedNumberControlTextColor,
                          }
                    }
                    value={counts[option.roomType]}
                    onChange={onSetCount}
                    min={0}
                    max={option.max}
                  />
                ) : undefined
              }
            />
          );
        })}
      </Grid>
      <Navigation
        PrevButtonProps={{
          children: isInitialCreateFlow ? 'Back' : 'Cancel',
          onClick: onCancel,
          startIcon: isInitialCreateFlow ? <ArrowBack /> : null,
        }}
        NextButtonProps={{
          onClick: () => onSave(counts),
          children: isInitialCreateFlow ? 'Next' : 'Save',
          endIcon: isInitialCreateFlow ? <ArrowForward /> : null,
        }}
      />
    </Step>
  );
};

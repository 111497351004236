// TODO: can we get rid of this completely?
/* eslint-disable */
import React from 'react';
import { useQueryClient } from 'react-query';
import { ENV, appEnvironments } from '../constants';
import { buildAuthStoreFromAuthState } from './okta';
import { checkFormsProfileForData } from '../utils';
import { FormsUserProfile } from '..';
import { useGetUserPermissionsRequestsKey } from '../../hooks/team/useGetUserPermissionRequests';
import { useGetOrCreateUserProfile } from 'src/hooks';

type UserInfo = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
};

export type AuthStore = {
  accountAction: null | 'none' | 'legacy' | 'register';
  loggedIn: boolean;
  userId: string | null;
  userInfo: UserInfo;
  shownPopup: boolean;
  formsUserCheckComplete: boolean;
};

const defaultStore: AuthStoreWithHelpers = {
  accountAction: null,
  loggedIn: false,
  userId: null,
  userInfo: {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
  },
  shownPopup: false,
  update: () => null,
  reset: () => null,
  formsUserCheckComplete: false,
};

interface AuthStoreWithHelpers extends AuthStore {
  update: (obj: Partial<AuthStore>) => void;
  reset: () => void;
}

export const AuthStoreContext = React.createContext<AuthStoreWithHelpers>(defaultStore);

export const AuthStoreContextProvider = (props: any) => {
  const [store, setStore] = React.useState<AuthStore>(defaultStore);
  const [init, setInit] = React.useState(false);
  const queryClient = useQueryClient();

  const update = (obj: Partial<AuthStore>) => {
    const newStore = { ...store, ...obj };
    setStore(newStore);
    if (ENV !== appEnvironments.PROD) {
      console.log('AUTH STORE UPDATED: ', newStore);
    }
  };

  const reset = () => {
    setStore({ ...defaultStore });
  };

  const { mutate, isIdle } = useGetOrCreateUserProfile();

  React.useEffect(() => {
    buildAuthStoreFromAuthState().then(async (authStore) => {
      if (authStore) {
        // making sure this is only done once per session
        if (authStore.loggedIn && !authStore.formsUserCheckComplete && isIdle) {
          // getUserProfile will add the profile info from auth state
          // if it doesn't exist in forms user data
          await mutate(
            {
              userId: authStore.userId!,
              firstName: authStore.userInfo.firstName,
              lastName: authStore.userInfo.lastName,
              email: authStore.userInfo.email,
            },
            {
              onSuccess: (result: FormsUserProfile | undefined) => {
                if (checkFormsProfileForData(result)) {
                  // next two lines are done to get the SelectAgentDropDown control
                  // to repopulate itself so it doesn't show "undefined undefined"
                  queryClient.invalidateQueries([useGetUserPermissionsRequestsKey]);
                  authStore.formsUserCheckComplete = true;
                  sessionStorage.setItem('formsUserCheckComplete', '1');
                }
              },
            }
          );
        }
        update(authStore);
      }

      setInit(true);
    });
  }, []);

  return (
    <AuthStoreContext.Provider value={{ ...store, update, reset }}>{init && props.children}</AuthStoreContext.Provider>
  );
};

export const useAuthStore = () => React.useContext(AuthStoreContext);

import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { FilePackage, PackageStatusType } from '../../../pages/FilePackages/types';
import { getPackagesByFileIdKey } from '../queries/useGetFilePackages';

export const useSetUxPackageStatus = (
  fileId: number
): ((packageId: string, status: PackageStatusType, statusText: string) => Promise<FilePackage[] | undefined>) => {
  const queryClient = useQueryClient();
  return useCallback(
    async (packageId: string, status: PackageStatusType, statusText: string): Promise<FilePackage[] | undefined> => {
      const packagesQueryKey = [getPackagesByFileIdKey, fileId];
      await queryClient.cancelQueries(packagesQueryKey);
      const previousPackages = await queryClient.getQueryData<FilePackage[]>(packagesQueryKey);

      if (previousPackages) {
        queryClient.setQueryData(packagesQueryKey, [
          ...previousPackages.map((p) => {
            if (p.id === packageId) {
              return {
                ...p,
                status: {
                  status: statusText,
                  progress: p.status.progress + 10,
                  actions: {},
                  type: status,
                },
              };
            }

            return p;
          }),
        ]);
      }

      return previousPackages;
    },
    [queryClient, fileId]
  );
};

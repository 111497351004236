import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';
import { Divider, makeStyles, Theme } from '@material-ui/core';
import { WizardStepProps, SelectRegions } from 'src/domain';
import { useGetUserPermissionRequests, useSelectRegion } from 'src/hooks';
import { Navigation, Step } from 'src/common';
import { defaultWizardRoutes, routes, usStates } from 'src/lib';
import { registerDestinationAtom } from 'src/state';

const useStyles = makeStyles((theme: Theme) => ({
  selectRegions: {
    marginTop: 0,
  },
  accountLabel: {
    color: theme.colors.grey.bodyHeader,
    fontWeight: 600,
  },
  link: {
    color: theme.colors.mainColor.primary,
    marginLeft: theme.spacing(0.5),
    cursor: 'pointer',
    fontWeight: 600,
  },
  divider: {
    margin: theme.spacing(3, 0),
  },
}));

/**
 * A wizard page that allows the user to select their regions(s).
 */
export const SelectRegionsStep: React.FC<WizardStepProps> = () => {
  const classes = useStyles();
  const history = useHistory();
  const { data: requests, isSuccess, isLoading: isUserRequestsLoading } = useGetUserPermissionRequests();

  const [registerDestination, setRegisterDestination] = useRecoilState(registerDestinationAtom);

  const [regions, setRegions] = useState<usStates[]>([]);
  const {
    regions: serverRegions,
    updateRegions: updateServerRegions,
    isFetched: areRegionsFetched,
  } = useSelectRegion();

  const serverRegionsRef = useRef(serverRegions);
  serverRegionsRef.current = serverRegions;
  useEffect(() => {
    if (!areRegionsFetched || !serverRegionsRef.current) return;
    setRegions(serverRegionsRef.current);
  }, [areRegionsFetched, serverRegionsRef]);

  function handleNext() {
    updateServerRegions(regions);

    // If user has pending shared access requests, redirect to team page
    if (isSuccess && requests!.delegatedToUserRequests.length > 0) {
      return history.push(routes.team);
    }

    const regionsContainsCA = regions.some((r) => r.valueOf() === usStates.CA);

    // CA users get redirected to the agent dashboard post registration, while other users go right into a disclosure
    let selectRegionsDestination = regionsContainsCA ? routes.dashboard : routes.disclosures;

    if (registerDestination != null) {
      // prevent the user from being redirected to the avid flow unless they have CA in their regions
      if (registerDestination !== defaultWizardRoutes.avidCreation || regionsContainsCA) {
        selectRegionsDestination = registerDestination;
      }
    }

    setRegisterDestination(null);
    history.push(selectRegionsDestination);
  }

  return (
    <Step title="Please specify your region(s).">
      <SelectRegions
        regions={regions}
        setRegions={setRegions}
        isLoading={!areRegionsFetched || isUserRequestsLoading}
      />

      <Divider className={classes.divider} />

      <Navigation
        className={classes.selectRegions}
        isLoading={!areRegionsFetched}
        PrevButtonProps={{ hidden: true }}
        NextButtonProps={{
          children: 'Continue',
          endIcon: null,
          onClick: handleNext,
          disabled: !areRegionsFetched || isUserRequestsLoading || regions.length === 0,
        }}
      />
    </Step>
  );
};

import React from 'react';
import { usePrimeAuthSession } from '@skyslope/auth-react';
import { useImportSkyslopeRole } from 'src/hooks/domain/useImportSkyslopeRole';
import { useHydrateNewUserInviteState } from 'src/hooks/useHydrateNewUserInviteState';
import { useSetupLaunchDarkly } from '../hooks';

export const ProviderDependentEffects: React.FC = () => {
  useSetupLaunchDarkly();
  useImportSkyslopeRole();
  useHydrateNewUserInviteState();
  usePrimeAuthSession();
  return null;
};

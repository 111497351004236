import pluralize from 'pluralize';
import { AvidRoom, AvidRoomType } from '../../../types';
import { AvidRoomOption } from '../../../hooks';

export const reconcileRoomType = (
  roomType: string,
  targetCount: number,
  rooms: AvidRoom[],
  optionLookup: { [roomType: string]: AvidRoomOption }
) => {
  const roomsOfType = rooms.filter((r) => r.roomType === roomType);

  if (roomsOfType.length === targetCount) {
    return rooms;
  }

  if (roomsOfType.length > targetCount) {
    return rooms.filter((r) => r.roomType != roomType || r.order <= targetCount);
  }

  return rooms.concat(
    ...Array.from({ length: targetCount - roomsOfType.length }).map((_, index) => {
      const optionConfig = optionLookup[roomType];
      const order = index + roomsOfType.length + 1;
      const roomName = pluralize(optionConfig.displayName, 1);
      const name = `${roomName}${optionConfig.max > 1 ? ` ${order}` : ''}`;
      return {
        name,
        order,
        roomType: roomType as AvidRoomType,
      };
    })
  );
};

import React, { useState } from 'react';
import clsx from 'clsx';
import { Button, IconButton, makeStyles, Theme, CircularProgress } from '@material-ui/core';
import { AddSharp as PlusIcon, Clear as ClearIcon } from '@material-ui/icons';
import { useGetLiveRegions } from 'src/hooks';
import { usStates } from 'src/lib';
import { SelectRegion } from './SelectRegion';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
    deleteButton: {
      marginLeft: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        marginLeft: theme.spacing(0.5),
      },
    },

    rowMiddle: {
      width: 'min(77%, 400px)',
      '& > *': {
        width: '100%',
      },
    },
    rowGutter: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
    },
  }),
  { classNamePrefix: 'SelectRegions' }
);

export interface SelectRegionsProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  regions: usStates[];
  setRegions(regions: usStates[]): void;
  isLoading?: boolean;
}

/**
 * A series of dropdowns where the user can select their region(s).
 * This can be reused in any location in the app, on a wizard page or in a
 * dialog, for example.
 */
export const SelectRegions: React.FC<SelectRegionsProps> = (props) => {
  const classes = useStyles();
  const { className, regions, setRegions, isLoading, ...rest } = props;

  const [emptyRow, setEmptyRow] = useState(false);
  const showEmptyRow = regions.length === 0 || emptyRow;
  const allRegions = useGetLiveRegions()?.map((state) => usStates[state]);

  const canAddAnother = !showEmptyRow && allRegions && regions.length < allRegions.length;

  function handleChange(index: number, region: usStates) {
    const copy = [...regions!];
    copy[index] = region;
    setRegions(copy);
  }

  function handleRemove(index: number) {
    const copy = [...regions!];
    copy.splice(index, 1);
    setRegions(copy);
  }

  if (isLoading || !allRegions) {
    return (
      <div
        {...rest}
        className={clsx(classes.root, className)}
        style={{ justifyContent: 'center', alignItems: 'center' }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      {regions?.map((region: string, index: number) => (
        <SelectRegionRow
          key={index}
          right={
            index > 0 && (
              <IconButton
                aria-label="delete"
                onClick={() => {
                  handleRemove(index);
                }}
                className={classes.deleteButton}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            )
          }
        >
          <SelectRegion
            region={region}
            onChange={(r) => {
              handleChange(index, r);
            }}
            regions={allRegions.filter((r) => r === region || !regions.includes(r))}
          />
        </SelectRegionRow>
      ))}
      {showEmptyRow && (
        <SelectRegionRow
          key={regions.length}
          right={
            regions.length > 0 && (
              <IconButton
                aria-label="delete"
                onClick={() => {
                  setEmptyRow(false);
                }}
                className={classes.deleteButton}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            )
          }
        >
          <SelectRegion
            region=""
            onChange={(r) => {
              setEmptyRow(false);
              handleChange(regions.length, r);
            }}
            regions={allRegions.filter((r) => !regions.includes(r))}
          />
        </SelectRegionRow>
      )}
      {canAddAnother && (
        <SelectRegionRow>
          <Button startIcon={<PlusIcon />} onClick={() => setEmptyRow(true)} color="primary">
            Add another region
          </Button>
        </SelectRegionRow>
      )}
    </div>
  );
};

type SelectRegionRowProps = {
  left?: React.ReactNode;
  children: React.ReactNode;
  right?: React.ReactNode;
};

function SelectRegionRow(props: SelectRegionRowProps) {
  const classes = useStyles();
  const { left, children, right } = props;

  return (
    <div style={{ display: 'flex' }}>
      <div className={classes.rowGutter}>{left}</div>
      <div className={classes.rowMiddle}>{children}</div>
      <div className={classes.rowGutter}>{right}</div>
    </div>
  );
}

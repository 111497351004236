import { Button, Divider, Grid, Link, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from 'react-query';
import { MobileSearchPopup, Spacer } from 'src/common';
import { GetEscrowOfficerListQueryResult, useIsMobile } from 'src/hooks';
import { ReactComponent as AddOfficerIcon } from 'src/images/escrow/add-escrow-officer.svg';
import { ReactComponent as ChooseEscrowIllustration } from 'src/images/escrow/choose-escrow-illustration.svg';
import { EscrowOfficer, HtmlButtonProps } from 'src/lib';
import { EscrowOfficerListItem } from './EscrowOfficerListItem';
import { EscrowSearchBar } from './EscrowSearchBar';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {},
    list: {
      maxHeight: '67vh',
      overflowY: 'auto',
      marginBottom: 0,
      isolation: 'isolate',

      [theme.breakpoints.down('xs')]: {
        maxHeight: 'unset',
        overflowY: 'unset',
      },
    },
    emptyRoot: {
      textAlign: 'center',
    },
    emptyIllustration: {
      width: 626,
      display: 'block',
      maxWidth: '100%',
      margin: 'auto',
    },
    moreResults: {
      color: '#0064a8',
      'font-weight': 'bold',
      cursor: 'pointer',
      '&:hover': {
        'text-decoration': 'none',
      },
    },
    endOfList: {
      color: '#666',
      'font-weight': 'bold',
      '&:hover': {
        'text-decoration': 'none',
      },
    },
    moreResultsContainer: {
      width: '100%',
      display: 'flex',
      'justify-content': 'center',
    },
    mobileListItem: {
      display: 'block',
      margin: 0,
      width: '100%',
      background: 'none',
      border: 'none',
      textAlign: 'left',
      borderBottom: `1px solid ${theme.colors.grey.disable}`,
      color: theme.colors.grey.label,
      padding: theme.spacing(2),

      '& > b': {
        color: theme.colors.grey.bodyHeader,
      },
    },
    mobileAddOfficer: {
      width: '100%',
      padding: theme.spacing(2),
      border: 'none',
      borderBottom: `1px solid ${theme.colors.grey.disable}`,
      background: theme.colors.grey.offWhite1,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: theme.spacing(2),

      '& > p': {
        color: theme.colors.blueForms,
        fontWeight: 700,
      },
    },
    stickMobileAdd: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      border: 'none',
      borderTop: `1px solid ${theme.colors.grey.disable}`,
    },
    mobileHelpText: {
      marginLeft: '24px',
      marginBottom: '12px',
      opacity: 0.8,
      fontSize: '1rem',
    },
    searchLabel: {
      flexGrow: 1,
      padding: `9px 0`,
    },
    addNewContactLabel: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
      },
    },
  }),
  { classNamePrefix: 'EscrowOfficerFilterList' }
);

export interface EscrowOfficerFilterListProps extends Omit<React.HtmlHTMLAttributes<HTMLDivElement>, 'onChange'> {
  searchResultOfficers: undefined | EscrowOfficer[];
  activeEscrowOfficer: undefined | EscrowOfficer;
  onChange(escrowOfficer: EscrowOfficer): void;
  filterText: string;
  debouncedFilterText: string;
  isDebouncedFilterValid: boolean;
  setFilterText(filterText: string): void;
  isFetching: boolean;
  isLoading: boolean;
  onAddEscrowOfficerClick(): void;
  recentlyUsedEscrowOfficers: undefined | EscrowOfficer[];
  hasNextPage: boolean;
  fetchNextPage(
    options?: FetchNextPageOptions | undefined
  ): Promise<InfiniteQueryObserverResult<GetEscrowOfficerListQueryResult, unknown>>;
}

export const EscrowOfficerFilterList: React.FC<EscrowOfficerFilterListProps> = (props) => {
  const classes = useStyles();
  const {
    className,
    searchResultOfficers,
    filterText,
    debouncedFilterText,
    isDebouncedFilterValid,
    setFilterText,
    activeEscrowOfficer,
    onChange,
    isFetching,
    isLoading,
    onAddEscrowOfficerClick,
    recentlyUsedEscrowOfficers,
    hasNextPage,
    fetchNextPage,
    ...rest
  } = props;

  const isMobile = useIsMobile();
  const [isMobilePopupOpen, setIsMobilePopupOpen] = useState(false);

  function getEmptyText(): [string, string] {
    if (isFetching) return ['', ''];

    // prettier-ignore
    return (debouncedFilterText && isDebouncedFilterValid)
      ? [
          'Looks like no escrow or title company contacts match your search.',
          'Try adjusting your search query or add your favorite contact.',
        ]
      : [
          'Looks like you don’t have any escrow or title company contacts yet.',
          'Search and add your favorite contact.',
        ];
  }

  function handleInputClick() {
    if (!isMobile) return;
    setIsMobilePopupOpen(true);
  }

  function handleFetchNextPage() {
    if (hasNextPage) fetchNextPage();
  }

  const isActuallyLoading = isLoading && (!searchResultOfficers || !recentlyUsedEscrowOfficers);
  const weHaveResults = searchResultOfficers?.length || recentlyUsedEscrowOfficers?.length;
  const officersToList = isMobile ? recentlyUsedEscrowOfficers : searchResultOfficers;

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <EscrowSearchBar
        InputProps={{ filterText, setFilterText, isFetching, onClick: handleInputClick, disabled: isMobile }}
      />
      <Spacer axis="vertical" size={2} />
      <Grid container spacing={4} className={clsx(classes.addNewContactLabel)}>
        <Grid item>
          <Typography variant="subtitle2" className={clsx(classes.searchLabel)}>
            {recentlyUsedEscrowOfficers?.length
              ? 'Your recently used escrow or title company contacts:'
              : 'Escrow Officers Nearby:'}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            id="add-new-escrow-contact-button"
            variant="text"
            color="primary"
            startIcon={<AddIcon />}
            onClick={onAddEscrowOfficerClick}
          >
            Add new contact
          </Button>
        </Grid>
      </Grid>
      <Spacer axis="vertical" size={4} />
      <Grid container spacing={4} justifyContent="flex-start" className={classes.list}>
        {isActuallyLoading &&
          Array.from({ length: 3 }).map((_, index) => <EscrowOfficerListItem key={index} isLoading />)}
        {!isActuallyLoading && (
          <>
            {officersToList?.map((escrowOfficer, index) => {
              const isChecked = escrowOfficer.email === activeEscrowOfficer?.email;
              const tabIndex = (activeEscrowOfficer && isChecked) || index === 0 ? 0 : -1;

              return (
                <EscrowOfficerListItem
                  key={escrowOfficer.email}
                  escrowOfficer={escrowOfficer}
                  checked={isChecked}
                  onChange={() => onChange(escrowOfficer)}
                  value={escrowOfficer.email}
                  tabIndex={tabIndex}
                />
              );
            })}
            <Spacer axis="vertical" size={7} />
            {searchResultOfficers && searchResultOfficers.length > 0 && (
              <div className={classes.moreResultsContainer}>
                <Link className={hasNextPage ? classes.moreResults : classes.endOfList} onClick={handleFetchNextPage}>
                  {hasNextPage ? 'Show More Results ∨' : 'You’ve reached the end of the list.'}
                </Link>
              </div>
            )}
          </>
        )}
      </Grid>
      {!isLoading && !weHaveResults && !activeEscrowOfficer && <NoEscrowContacts text={getEmptyText()} />}

      <MobileSearchPopup
        open={isMobilePopupOpen}
        onClose={() => setIsMobilePopupOpen(false)}
        filterText={filterText}
        setFilterText={setFilterText}
        isFetching={isFetching}
        minLength={3}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
      >
        {({ isKeyboardOpen }) => (
          <>
            {isDebouncedFilterValid &&
              searchResultOfficers?.map((eo) => (
                <MobileListItem
                  key={eo.email}
                  escrowOfficer={eo}
                  onClick={() => {
                    onChange(eo);
                    setIsMobilePopupOpen(false);
                  }}
                />
              ))}
            {searchResultOfficers && searchResultOfficers.length > 0 && (
              <MobileLoadMoreItem hasNextPage={hasNextPage} onClick={handleFetchNextPage} />
            )}
            {!searchResultOfficers?.length && (
              <Typography className={classes.mobileHelpText}>Start typing to search contacts</Typography>
            )}
            <button
              className={clsx(classes.mobileAddOfficer, !isKeyboardOpen && classes.stickMobileAdd)}
              onClick={onAddEscrowOfficerClick}
            >
              <AddOfficerIcon viewBox="0 0 73 73" width={40} height={40} />
              <Typography>Add a new contact</Typography>
            </button>
          </>
        )}
      </MobileSearchPopup>
    </div>
  );
};

function NoEscrowContacts(props: { text: [string, string] }) {
  const classes = useStyles();
  const { text } = props;
  const nbsp = '\u00a0';

  return (
    <div className={classes.emptyRoot}>
      <Divider />
      <Spacer axis="vertical" size={7} />
      <Typography variant="subtitle2">{text[0] || nbsp}</Typography>
      <Typography variant="subtitle2">{text[1] || nbsp}</Typography>
      <Spacer axis="vertical" size={8} />
      <ChooseEscrowIllustration className={classes.emptyIllustration} />
    </div>
  );
}

function MobileListItem(props: HtmlButtonProps & { escrowOfficer: EscrowOfficer }) {
  const classes = useStyles();
  const { escrowOfficer: eo, ...rest } = props;

  return (
    <button {...rest} className={classes.mobileListItem}>
      <Typography>
        <b>{eo.fullName}</b> — {eo.brand}
      </Typography>
      <Typography>{eo.email}</Typography>
    </button>
  );
}

function MobileLoadMoreItem(props: HtmlButtonProps & { hasNextPage: boolean }) {
  const classes = useStyles();
  const { hasNextPage, ...rest } = props;
  return (
    <button {...rest} className={classes.mobileListItem}>
      <Typography style={{ textAlign: 'center' }}>
        {hasNextPage ? 'Show More Results ∨' : 'You’ve reached the end of the list.'}
      </Typography>
    </button>
  );
}

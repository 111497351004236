import { makeStyles, Typography, Link, Theme } from '@material-ui/core';
import React, { lazy, useState } from 'react';
import { LoginCallback, useAuth } from '@skyslope/auth-react';
import { Redirect, useHistory } from 'react-router';
import { SessionStorageItem, handleDs3Token, routes } from 'src/lib';

const LottieAnimation = lazy(() => import('src/common/LottieAnimation'));

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
  animation: {
    maxWidth: '30%',
    zIndex: -1,
  },
  redirectMessage: {
    fontWeight: 600,
  },
  link: {
    color: theme.colors.mainColor.primary,
    marginLeft: theme.spacing(0.5),
    cursor: 'pointer',
    fontWeight: 'bold',
  },
}));

export const AuthCallback = () => {
  const classes = useStyles();
  const history = useHistory();
  const [showRedirectMessage, setShowRedirectMessage] = React.useState(false);
  const {
    authState: { isAuthenticated },
  } = useAuth();
  const [profileCreated, setProfileCreated] = useState(false);

  const authComplete = isAuthenticated && profileCreated;

  React.useEffect(() => {
    async function handleDs3() {
      await handleDs3Token();
      setProfileCreated(true);
    }

    if (isAuthenticated) handleDs3();

    setTimeout(() => {
      if (profileCreated) setShowRedirectMessage(true);
    }, 8000);
  }, [isAuthenticated]);

  if (authComplete) {
    return <Redirect to={sessionStorage.getItem(SessionStorageItem.LoginOGPath) ?? routes.dashboard} />;
  } else if (!isAuthenticated) {
    return <Redirect to={routes.landing} />;
  }

  return (
    <>
      <div className={classes.root}>
        <Typography variant="h3" align="center">
          Logging in...
        </Typography>
        <div className={classes.animation}>
          <LottieAnimation animation="surferBoi" />
        </div>
        {showRedirectMessage && (
          <Typography variant="body1" align="center" className={classes.redirectMessage}>
            If you are not automatically redirected,
            <Link className={classes.link} onClick={() => history.push('/dashboard')}>
              click here{' '}
            </Link>
            to go to your dashboard.
          </Typography>
        )}
      </div>
      <LoginCallback />
    </>
  );
};

import React, { useState } from 'react';
import { useParams } from 'react-router';
import { WizardStepProps, ContactCollectionStep } from 'src/domain';
import { contactActions, contactTypes } from 'src/lib';
import { useGetFileById, useUpdateContacts, useGetUserProfile } from 'src/hooks';
import { AvidSteps } from '../../AvidCreation/steps';
import { useGetAvidPackage } from '../../hooks';

/**
 * Once the long term solution described in INNO2-926 is implemented, this step will no longer be used.
 */
export const AvidTeamMembersDeprecated = ({ goToStep }: WizardStepProps) => {
  const { packageId } = useParams<{ packageId: string }>();
  const { data: avidPackage } = useGetAvidPackage(packageId);
  const { data: file } = useGetFileById(avidPackage?.fileId);
  const { mutateAsync: updateTeamMembers } = useUpdateContacts();
  const contacts =
    file?.contacts.filter(
      (contact) => contact.actionAttribute === contactActions.RECEIVESCOPY && contact.type === contactTypes.OTHER
    ) ?? [];

  const { data: userProfile } = useGetUserProfile();
  const [emailValidationMessage, setEmailValidationMessage] = useState('');

  return (
    <ContactCollectionStep
      title="Add your team members you want to keep informed."
      dontRequireFirstContact
      getBlankContact={() => ({ type: contactTypes.OTHER })}
      defaultContacts={contacts.length === 0 ? [{ type: contactTypes.OTHER }] : contacts}
      onSubmit={async (form) => {
        form.contacts.map((c) => (c.actionAttribute = contactActions.RECEIVESCOPY));

        const deletedContacts = contacts.filter(
          (c) => !form.contacts.some((formContact) => c.id?.toString() === formContact.id)
        );

        const editedContacts = form.contacts.filter(
          (formContact) =>
            formContact.id &&
            !contacts.some(
              (c) =>
                c.firstName === formContact.firstName &&
                c.lastName === formContact.lastName &&
                c.email === formContact.email
            )
        );
        await updateTeamMembers({
          fileId: file!.id,
          deletedContacts: deletedContacts,
          createdContacts: form.contacts.filter((c) => !c.id),
          editedContacts: editedContacts,
        });
        goToStep(AvidSteps.review);
      }}
      isLoading={!file}
      isTeamMember
      NavigationProps={{
        PrevButtonProps: {
          onClick: () => goToStep(AvidSteps.review),
          startIcon: null,
        },
      }}
      validators={{
        email: (val) => {
          if (val === userProfile?.email) {
            setEmailValidationMessage(
              'Looks like this contact is already a part of your team. Please enter a new contact.'
            );
            return '';
          } else {
            setEmailValidationMessage('');
            return true;
          }
        },
      }}
      validationMessages={{ email: emailValidationMessage }}
    />
  );
};

import React from 'react';
import { Address, BasicProperty } from './types';
import { getStreetName, getStreetNumber } from './splitAddress';
import {
  AuthType,
  Contact,
  contactActions,
  contactTypes,
  FormsUserProfile,
  getCountiesByState,
  RepresentationType,
  UserMetaRole,
  usStates,
} from '.';

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const arrayFromLength = (length: number) => Array.from({ length }, (_, i) => i);

export const addressWithUnit = (property: BasicProperty) => {
  if (property.unit) {
    const propStr = property.address.split(',');
    propStr.splice(1, 0, ` ${property.unit}`);
    return propStr.join(',');
  } else {
    return property.address;
  }
};

export const buildNameList = (names: string[], useAnd = false) => {
  const combiner = useAnd ? 'and' : 'or';

  if (names.length === 1) {
    return names[0];
  }
  if (names.length === 2) {
    return `${names[0]} ${combiner} ${names[1]}`;
  }
  return `${names.slice(0, -1).join(', ')} ${combiner} ${names.slice(-1)}`;
};

export const getBaseAddress = () => {
  const protocol = window.location.protocol;
  const hostname = window.location.hostname;
  let port = window.location.port;
  if (port === '80') {
    port = '';
  } else {
    port = `:${port}`;
  }
  return `${protocol}//${hostname}${port}`;
};

export const formatAddress = (address: Address | undefined, includeCounty = false): string => {
  if (!address) {
    return '';
  }

  const { streetAddress, city, state, postalCode, county } = address;

  return includeCounty
    ? `${streetAddress}, ${city ?? ''}, ${state ?? ''} ${postalCode ?? ''}${county ? `, ${county}` : ''}`
    : `${streetAddress}, ${city ?? ''}, ${state ?? ''} ${postalCode ?? ''}`;
};

export const formatPhoneNumber = (phoneNumber: string) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  return match ? '(' + match[1] + ') ' + match[2] + '-' + match[3] : null;
};

export const isCompleteAddress = (property: Address) =>
  ![property.streetAddress, property.city, property.state, property.postalCode, property.county].includes('');

export const noOp = () => {};

export const base64ToUrl: (base64string: string) => Promise<string> = (base64string: string) =>
  // eslint-disable-next-line no-undef
  new Promise((resolve, reject) => {
    try {
      // decode base64 string, remove space for IE compatibility
      const binary = atob(base64string.replace(/\s/g, ''));
      const len = binary.length;
      // eslint-disable-next-line no-undef
      const buffer = new ArrayBuffer(len);
      // eslint-disable-next-line no-undef
      const view = new Uint8Array(buffer);
      for (let i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
      }

      const blob = new Blob([view], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      resolve(url);
    } catch (error) {
      reject(error);
    }
  });

export const fileToUrl = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });

export const capitalize = (string: string | undefined) =>
  string ? string.charAt(0).toUpperCase() + string.slice(1) : '';

// https://github.com/gregberge/react-merge-refs/blob/master/src/index.js
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Merge multiple React refs together
 * @returns A single ref that will set the same value
 * for all passed refs.
 */
export const mergeRefs =
  (...refs: Array<React.Ref<any> | undefined>) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (value: any) => {
    refs.forEach((ref) => {
      if (!ref) {
        return;
      } else if (typeof ref === 'function') {
        ref(value);
      } else if (typeof ref === 'object') {
        const obj = ref as React.MutableRefObject<any>;
        obj.current = value;
      }
    });
  };

/**
 * Convert a JS object to a query string suitable for use in a URL
 */
export const objToQueryString = (queryObj: { [key: string]: string | number }) => {
  const searchParams = new URLSearchParams();
  Object.entries(queryObj).forEach(([name, value]) => searchParams.append(name, String(value)));
  return searchParams.toString();
};

export const removeEmpty = (obj: any) => Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));

// making sure we have full data coming back from Forms user profile
export const checkFormsProfileForData = (profile: FormsUserProfile | undefined) =>
  !!profile?.firstName && !!profile?.lastName && !!profile?.email;

// copy/pasta - https://bitbucket.org/skyslope/files-ui/src/e9c474332ed07470ad620557cd1d0ff18037a300/src/pages/FileCreate/FileCreate.tsx#lines-64
export const convertUserSettingsToContact = (
  userSettings: FormsUserProfile | undefined,
  representationType: string | undefined,
  actionAttribute: string = contactActions.NOACTION
): Contact | undefined => {
  if (userSettings == null || representationType == null) {
    return undefined;
  }

  const lowerCaseRepType = representationType.toLocaleLowerCase();
  const sellerTypes = [RepresentationType.SELLER.toLocaleLowerCase(), RepresentationType.LANDLORD.toLocaleLowerCase()];
  const isSellerOrLandlord = sellerTypes.some((sellerType) => sellerType === lowerCaseRepType);
  const contactType = isSellerOrLandlord ? contactTypes.SELLERAGENT : contactTypes.BUYERAGENT;

  return {
    type: contactType,
    lastName: userSettings.lastName,
    middleName: userSettings.middleName ?? '',
    firstName: userSettings.firstName,
    suffix: userSettings.suffix ?? '',
    primaryAddress: {
      streetName: getStreetName(userSettings.brokerageAddress?.streetAddress ?? ''),
      streetNumber: getStreetNumber(userSettings.brokerageAddress?.streetAddress ?? ''),
      unitNumber: userSettings.brokerageAddress?.unitNumber ?? '',
      city: userSettings.brokerageAddress?.city ?? '',
      state: userSettings.brokerageAddress?.state?.toUpperCase() ?? '',
      postalCode: userSettings.brokerageAddress?.postalCode ?? '',
      county: userSettings.brokerageAddress?.county ?? '',
    },
    email: userSettings.email,
    primaryPhoneNumber: userSettings.primaryPhoneNumber ?? '',
    companyName: userSettings.brokerageName,
    actionAttribute,
    isUser: true,
    agentLicenseNumber: userSettings.licenseNumber,
    agentMLSCode: userSettings.mlsCode,
    brokerageLicenseNumber: userSettings.brokerageLicenseNumber,
    brokerageMLSCode: userSettings.brokerageMLSCode,
  };
};

/**
 * Remove duplicate objects from an array based on the value at a particular
 * key of that object.  Entries earlier in the array are preferred over entries
 * later in the array.
 */
export const getUniqueListBy = <T>(arr: T[], key: keyof T) => {
  const uniqueMap = new Map(arr.map((item) => [item[key], item]));
  return Array.from(uniqueMap.values());
};

// Get a deduped array for simple primitive type arrays
export const getUniqueList = <T extends string | number | boolean>(arr: any[]): T[] =>
  arr.filter((n, i) => arr.indexOf(n) === i);

type Approvable = { isApproved?: boolean };
export const sortPendingFirst = (a: Approvable, b: Approvable) => {
  const bothApproved = a.isApproved && b.isApproved;
  const bothPending = !a.isApproved && !b.isApproved;

  if (bothApproved || bothPending) return 0;
  return a.isApproved ? 1 : -1;
};

export const sortPendingLast = (a: Approvable, b: Approvable) => -1 * sortPendingFirst(a, b);

export const pickKeysFromObject = <T>(obj: T, keys: Array<keyof T>) => {
  const newObj: Partial<T> = {};
  keys.forEach((key) => (newObj[key] = obj[key]));
  return newObj;
};

/**
 * A possessive name indicates that something belongs to that person.
 * e.g. "Zach's book" or "Lucas' computer".
 */
export const makeNamePossessive = (name: string) => {
  if (!name) return '';

  const lastChar = name[name.length - 1];
  return lastChar === 's' ? `${name}'` : `${name}'s`;
};

export const formatChameleonUserRole = (role: UserMetaRole): string => {
  if (role === undefined) {
    return '';
  } else {
    return role === UserMetaRole.TC ? 'Limited TC' : role.charAt(0).toUpperCase() + role.slice(1);
  }
};

/**
 * Determine if there is overlap between two arrays
 * At least one of the items in the first array must also be contained in the second array
 */
export function doArraysOverlap<T>(a: T[], b: T[]) {
  return a.some((itemA) => b.includes(itemA));
}

export const GetEnumKeyByValue = <T>(source: T, value: string): string =>
  Object.keys(source)[Object.values(source).indexOf(value)];

export const mapAddressToAuthTypes = (state: string): AuthType[] => {
  switch (state) {
    case 'CA':
      return [AuthType.CaNrds, AuthType.Ca0808Nrds];
    case 'AZ':
      return [AuthType.AzNrds];
    case 'WA':
      return [AuthType.NWMLS];
    case 'OR':
      return [AuthType.Oref];
    case 'NV':
      return [AuthType.RSARNrds];
    case 'TX':
      return [AuthType.TxNrds];
    default:
      return [AuthType.None];
  }
};

export const sanitizeAddress = (property: Partial<Address>) => {
  const result: Address = {
    streetAddress: property.streetAddress ?? '',
    city: property.city ?? '',
    unitNumber: property.unitNumber ?? '',
    state: property.state ?? '',
    postalCode: property.postalCode ?? '',
    county: property.county ?? '',
  };

  const state = property.state?.toUpperCase() ?? '';
  const validState = Object.keys(usStates).includes(state);
  const sanitizedCounty = property.county?.replace(/\s[Cc]ounty/g, '');
  const validCounty = validState && getCountiesByState(state).includes(sanitizedCounty ?? '');

  result.state = validState ? state : '';
  result.county = validCounty ? sanitizedCounty : '';

  return result;
};

export const toWords = (num: number) => {
  const lookup = [
    'zero',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen',
  ];
  return lookup[num] ?? '';
};

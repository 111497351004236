import React, { ChangeEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Container } from '@material-ui/core';
import { WizardStepProps } from 'src/domain';
import { Navigation, Step, TextInput } from 'src/common';
import { useDisclosuresStore } from '../store';
import { useDisclosuresConditionalSteps } from '../hooks/useDisclosuresConditionalSteps';
import { SpqTdsDisclosureSteps } from './constants';

interface Form {
  yearBuilt: string;
}

export const YearBuilt = (props: WizardStepProps) => {
  const store = useDisclosuresStore();
  const navigationSteps = useDisclosuresConditionalSteps(SpqTdsDisclosureSteps.yearBuilt, store.forms! ?? []);
  const { goToStep } = props;

  const { register, handleSubmit, errors } = useForm<Form>({
    mode: 'onTouched',
  });

  const [year, setYear] = useState(store.yearBuilt);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 4) return;
    setYear(e.target.value);
  };

  return (
    <Step title="When was the property built?">
      <form
        onSubmit={handleSubmit((form) => {
          store.update(form);
          goToStep(navigationSteps.nextStep!);
        })}
      >
        <Container maxWidth="xs">
          <TextInput
            name="yearBuilt"
            type="number"
            onChange={onChange}
            inputRef={register({
              required: true,
              pattern: { value: /^(17|18|19|20)\d{2}$/, message: 'Please enter a valid year' },
            })}
            error={errors.yearBuilt}
            value={year}
            inputProps={{ maxLength: 4 }}
            placeholder="Year Built"
          />
        </Container>
        <Navigation
          PrevButtonProps={{ onClick: () => goToStep(navigationSteps.prevStep) }}
          NextButtonProps={{
            disabled: Object.keys(errors).length > 0,
          }}
        />
      </form>
    </Step>
  );
};

import React from 'react';
import { Wizard, StepOptions, SelectRegionsStep } from 'src/domain';

const steps: StepOptions[] = [
  {
    key: 'select-regions',
    component: SelectRegionsStep,
  },
];

export const Settings: React.FC = () => <Wizard steps={steps} defaultStep="select-regions" prefix="settings" />;

import React from 'react';
import { makeStyles, Theme, ButtonBase, Typography, Button } from '@material-ui/core';
import { ArrowBackSharp as ArrowIcon, ControlPointSharp as PlusIcon } from '@material-ui/icons';
import { useHistory } from 'react-router';
import { Spacer } from 'src/common/Spacer';
import { SelectAgentList } from 'src/domain/SelectAgent';
import { routes } from 'src/lib';

const useStyles = makeStyles<Theme, SelectAgentNavMenuProps>(
  (theme: Theme) => ({
    root: {
      background: 'white',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'hidden',
    },
    title: {
      position: 'sticky',
      top: 0,
      zIndex: 100,
    },
    backButton: {
      width: '100%',
      boxSizing: 'border-box',
      padding: theme.spacing(3.5),
      background: 'white',
      color: theme.colors.grey.bodyHeader,
      border: 'none',
      borderTop: `1px solid ${theme.colors.grey.disable}`,
      display: 'flex',
      justifyContent: 'flex-start',

      '&:hover': {
        background: theme.colors.grey.offWhite1,
      },
    },
    addTeamMemberButton: {
      margin: 'auto',
      marginBottom: theme.spacing(3),
      display: 'flex',
      paddingTop: theme.spacing(2),
    },
    footer: {
      position: 'sticky',
      bottom: 0,
      background: 'white',
    },
    searchBar: {
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      borderTop: `1px solid ${theme.colors.grey.disable}`,
    },
  }),
  { classNamePrefix: 'SelectAgentNavMenu' }
);

export interface SelectAgentNavMenuProps {
  onBack(): void;
}

/**
 * A secondary view in the navigation.  When a TC (on their mobile phone)
 * wants clicks the SelectAgentNavRow in the primary navigation, this nav
 * menu replaces the NavigationMenu inside of the NavigationDrawer.
 */
export const SelectAgentNavMenu: React.FC<SelectAgentNavMenuProps> = (props) => {
  const { onBack, ...rest } = props;
  const classes = useStyles(props);
  const history = useHistory();

  return (
    <div {...rest} className={classes.root}>
      <div className={classes.title}>
        <ButtonBase onClick={onBack} className={classes.backButton}>
          <ArrowIcon style={{ fontSize: 20 }} />
          <Spacer axis="horizontal" size={2} />
          <Typography>Switch accounts:</Typography>
        </ButtonBase>
      </div>

      <SelectAgentList
        classes={{
          searchBar: classes.searchBar,
        }}
        onChooseAgent={() => onBack()}
      />

      <div className={classes.footer}>
        <Button
          className={classes.addTeamMemberButton}
          startIcon={<PlusIcon />}
          size="small"
          color="primary"
          onClick={() => history.push(routes.team)}
        >
          Add a teammate
        </Button>
      </div>
    </div>
  );
};

import React, { useState } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import ReactDOM from 'react-dom';
import 'react-app-polyfill/stable';
import { MuiThemeProvider } from '@material-ui/core';
import { Router } from 'react-router-dom';
import { SecurityProvider } from '@skyslope/auth-react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { RecoilRoot } from 'recoil';
import 'what-input';
import TagManager from 'react-gtm-module';
import { ZendeskWidget, SnackbarProvider, ProviderDependentEffects, ZendeskWidgetProvider } from './common';
import {
  muiTheme,
  sentryInit,
  userManager,
  AuthStoreContextProvider,
  config,
  history,
  GOOGLE_TAG_MANAGER_ID,
  GOOGLE_TAG_MANAGER_AUTH,
  GOOGLE_TAG_MANAGER_PREVIEW,
} from './lib';
import { Routes } from './routes';
import './index.css';
import { useSetupChameleon } from './hooks/chameleon';
import { useSetupClarity } from './hooks/clarity';

window.heap?.load?.(config.heapId);
sentryInit();

const tagManagerArgs = {
  gtmId: GOOGLE_TAG_MANAGER_ID,
  auth: GOOGLE_TAG_MANAGER_AUTH,
  preview: GOOGLE_TAG_MANAGER_PREVIEW,
};

TagManager.initialize(tagManagerArgs);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10_000, // if data has not been on the page for the past 10s refresh it the next time it's requested
    },
  },
});

const SetupCharmeleon = () => {
  useSetupChameleon();
  return null;
};

const SetupClarity = () => {
  useSetupClarity();
  return null;
};

const Providers = ({ children }: { children: React.ReactNode }) => {
  const [showZendeskWidget, setShowZendeskWidget] = useState(true);

  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        <MuiThemeProvider theme={muiTheme}>
          <SnackbarProvider>
            <SecurityProvider userManager={userManager}>
              <AuthStoreContextProvider>
                <SetupCharmeleon />
                <SetupClarity />
                <ZendeskWidgetProvider value={{ show: showZendeskWidget, setShow: setShowZendeskWidget }}>
                  {children}
                </ZendeskWidgetProvider>
              </AuthStoreContextProvider>
            </SecurityProvider>
          </SnackbarProvider>
        </MuiThemeProvider>
      </QueryClientProvider>
    </RecoilRoot>
  );
};

const App = () => (
  <Providers>
    <ProviderDependentEffects />
    <ZendeskWidget isFloating hideOnMobile />
    <Routes />
  </Providers>
);

const AppWithLdProvider = withLDProvider({
  clientSideID: config.launchDarklyId,
})(App);

ReactDOM.render(
  <Router history={history}>
    <AppWithLdProvider />
  </Router>,
  document.getElementById('root')
);

import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, Box, BoxProps } from '@material-ui/core';
import { useIsMobile } from 'src/hooks';
import { AddProps } from './AddProps';
import { HorizontalCollapse } from './HorizontalCollapse';
import { Spacer } from './Spacer';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      color: theme.colors.grey.grey1,
      border: `2px solid ${theme.colors.grey.disable}`,
      display: 'flex',
      alignItems: 'center',
      borderRadius: 999,
      padding: theme.spacing(2),
      transitionProperty: 'color, border-color, background-color',
      '--transition-duration': '100ms',
      transitionDuration: 'var(--transition-duration)',
      cursor: 'pointer',
      background: 'transparent',
      [theme.breakpoints.down('xs')]: {
        color: theme.colors.blueForms,
      },
    },
    rootHovered: {
      color: theme.colors.blueForms,
      backgroundColor: theme.colors.blue50,
      borderColor: theme.colors.blue100,
      [theme.breakpoints.down('xs')]: {
        background: 'transparent',
        borderColor: theme.colors.grey.disable,
      },
    },
    icon: {
      color: 'inherit',
      transition: 'color var(--transition-duration)',
    },
    collapse: {},
    collapseInner: {
      display: 'flex',
      width: 'max-content',
    },
  }),
  { classNamePrefix: 'RevealButton' }
);

export interface RevealButtonProps extends BoxProps {
  icon: React.ReactNode;
  // Allow this button to instead become an anchor
  href?: string;
  target?: string;
  rel?: string;
}

/**
 * A component that shows an icon by default, and then when it his hovered over expands
 * horizontally to reveal some text.  On mobile it will always be expanded.  If no
 * children are passed, it can also just work as a button (or whatever `component`)
 * you pass.
 */
// eslint-disable-next-line prefer-arrow-callback
export const RevealButton = React.forwardRef(function RevealButton(props: RevealButtonProps, ref: React.Ref<unknown>) {
  const classes = useStyles();
  const { children, className, component, href, icon, ...rest } = props;

  const isMobile = useIsMobile();
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const isExpanded = isHovered || isFocused || isMobile;

  return (
    <Box
      {...rest}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ref={ref}
      className={clsx(className, classes.root, isExpanded && classes.rootHovered)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      component={component || href ? 'a' : 'button'}
      href={href}
    >
      <AddProps to={icon} className={classes.icon} />
      <HorizontalCollapse in={isExpanded} classes={{ outer: classes.collapse, inner: classes.collapseInner }}>
        {children && (
          <>
            <Spacer axis="horizontal" size={1.5} />
            <span>{children}</span>
          </>
        )}
      </HorizontalCollapse>
    </Box>
  );
});

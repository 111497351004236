import React, { useMemo } from 'react';
import {
  makeStyles,
  Theme,
  CircularProgress,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { useGetFiles, useIsMobile, useGetActiveUserProfile } from '../hooks';

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    maxWidth: '90%',
    maxHeight: 440,
    marginTop: -32,
    borderBottom: `1px solid ${theme.colors.grey.disable}`,
    '& th': {
      backgroundColor: 'white',
      borderTop: `1px solid ${theme.colors.grey.disable}`,
      borderBottom: `1px solid ${theme.colors.grey.disable}`,
      color: theme.colors.grey.label,
      fontSize: 18,
    },
    '& td': {
      borderBottom: `1px solid ${theme.colors.grey.disable}`,
      color: theme.colors.grey.bodyHeader,
      fontSize: 22,
      userSelect: 'none',
    },
    '& tbody tr:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '75%',
    },
  },
  radioCell: {
    padding: 0,
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  mobileWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  mobileCard: {
    borderRadius: 8,
    background: 'none',
    border: `1px solid ${theme.colors.grey.disable}`,
    padding: 24,
    marginBottom: 16,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  mobileCardSelected: {
    borderLeft: `4px solid ${theme.colors.mainColor.primary}`,
    transition: 'border-left-color 0.25s',
    backgroundColor: theme.colors.grey.offWhite1,
  },
  mobileInfo: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 8,
  },
  mobileLoader: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255,255,255,0.5)',
  },
}));
const formatDate = (date: string) => {
  const d = new Date(date);
  return d.toLocaleDateString('en-US');
};
export interface FileSelectorProps {
  selectedFileId?: number | null;
  filters?: string;
  onSelect: (fileId: number) => void;
}
export const FileSelector: React.FC<FileSelectorProps> = (props: FileSelectorProps) => {
  const isMobile = useIsMobile();
  const classes = useStyles();
  const { data: files, isLoading: isLoadingFiles } = useGetFiles(props.filters);

  const { data: activeUserProfile } = useGetActiveUserProfile();
  const filteredItems = useMemo(
    () => files?.filter((file) => file.ownedBy === activeUserProfile?.id) ?? [],
    [activeUserProfile, files]
  );

  if (isMobile) {
    return (
      <>
        {isLoadingFiles && (
          <div className={classes.mobileLoader}>
            <CircularProgress />
          </div>
        )}
        <div className={classes.mobileWrapper}>
          {filteredItems?.map((file) => (
            <button
              key={file.id}
              onClick={() => props.onSelect(file.id)}
              className={clsx(classes.mobileCard, {
                [`${classes.mobileCardSelected}`]: file.id == props.selectedFileId,
              })}
            >
              <div className={classes.mobileInfo}>
                <Typography variant="body2">{file.name}</Typography>
                <Typography variant="body1">{formatDate(file.createdDate)}</Typography>
              </div>
            </button>
          ))}
        </div>
      </>
    );
  }
  return (
    <div className={classes.wrapper}>
      <TableContainer className={classes.table}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>File Name</TableCell>
              <TableCell>Created</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredItems?.map((file) => (
              <TableRow key={file.id} onClick={() => props.onSelect(file.id)}>
                <TableCell className={classes.radioCell}>
                  <Radio
                    color="primary"
                    checked={props.selectedFileId === file.id}
                    onChange={() => props.onSelect(file.id)}
                  />
                </TableCell>
                <TableCell>{file.name}</TableCell>
                <TableCell>{formatDate(file.createdDate)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

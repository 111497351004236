import React from 'react';
import { WizardStepProps, MissingPropertyInfo } from 'src/domain';
import { usePatchFileV2 } from 'src/hooks';
import { getStreetName, getStreetNumber } from 'src/lib/splitAddress';
import { useAvidStore } from '../store';
import { AvidSteps } from './constants';

export const AvidMissingPropertyInfo = (props: WizardStepProps) => {
  const store = useAvidStore();
  const { mutate: patchFile } = usePatchFileV2();

  return (
    <MissingPropertyInfo
      defaultProperty={store.property!}
      onSubmit={(form) => {
        if (store.fileId) {
          patchFile({
            fileId: store.fileId,
            payload: {
              fileData: {
                ['premisesAddress']: {
                  ...form.property,
                  streetNumber: getStreetNumber(form.property.streetAddress),
                  streetName: getStreetName(form.property.streetAddress),
                  state: 'CA',
                },
              },
            },
          });
        }
        store.update({
          property: { ...form.property, state: 'CA' },
        });
        props.goToStep(AvidSteps.propertyType);
      }}
    />
  );
};

import React from 'react';
import { WizardStepProps, PhoneNumber } from 'src/domain';
import { SpqTdsDisclosureSteps } from './constants';

export const SpqTdsPhoneNumber = (props: WizardStepProps) => (
  <PhoneNumber
    onNext={() => props.goToStep(SpqTdsDisclosureSteps.review)}
    onBack={() => props.goToStep(SpqTdsDisclosureSteps.sellers)}
  />
);

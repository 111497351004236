import { useEffect, useState } from 'react';
import { AuthType, isUserAuthenticatedForType, usStates } from 'src/lib';
import { AuthProfileStatus } from 'src/lib/authProfileStatus';
import { GetEnumKeyByValue, mapAddressToAuthTypes } from 'src/lib/utils';
import { useGetUserProfileMeta } from '../domain/queries/useGetUserProfileMeta';
import { useSelectRegion } from '../domain/useSelectRegion';

export const useValidateAuths = () => {
  const { regions: serverRegions, isFetched: areRegionsFetched } = useSelectRegion();
  const { data: meta, isLoading } = useGetUserProfileMeta();
  const [authStatusList, setAuthStatusList] = useState<AuthProfileStatus[]>();
  const [areCredsValid, setAreCredsValid] = useState(false);
  const [missingCreds, setMissingCreds] = useState<AuthType[]>();
  const [areSomeCredsValid, setAreSomeCredsValid] = useState(false);

  // Map user regions to auth types and validate each one
  useEffect(() => {
    if (!isLoading && serverRegions && meta) {
      setAuthStatusList(
        serverRegions
          .map((state) => ({
            state: state.toString(),
            authType: mapAddressToAuthTypes(GetEnumKeyByValue(usStates, state)),
          }))
          .flatMap(({ state, authType }) => authType.map((auth) => ({ state, authType: auth })))
          .map(
            ({ state, authType }) =>
              new AuthProfileStatus({ authType, isAuthenticated: isUserAuthenticatedForType(meta, authType), state })
          )
      );
    }
  }, [areRegionsFetched, serverRegions, meta, isLoading]);

  // Set areCredsValid to true if all auth creds are valid, otherwise set missingCreds
  useEffect(() => {
    if (authStatusList) {
      if (authStatusList.every((x) => x.isAuthenticated)) {
        setAreCredsValid(true);
      } else {
        setMissingCreds(authStatusList.filter((x) => !x.isAuthenticated).map((x) => x.authType));
      }
    }
  }, [authStatusList]);

  // Set areSomeCredsValid to true if at least one auth is authenticated
  useEffect(() => {
    if (authStatusList && authStatusList.some((x) => x.isAuthenticated)) {
      setAreSomeCredsValid(true);
    }
  }, [authStatusList]);

  return {
    areCredsValid,
    isLoading,
    missingCreds,
    areSomeCredsValid,
  };
};

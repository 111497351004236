export const heapUserDataInit = (
  userData: {
    userId: string;
    Email: string;
    SubscriberId?: number | undefined;
    UserType?: string | undefined;
  },
  isStandaloneUser: boolean
) => {
  if (window.heap) {
    const { userId, Email, SubscriberId, UserType } = userData;
    window.heap.identify(userId);
    window.heap.addUserProperties({
      okta_user_id: userId,
      email: Email,
      client_app: 'breeze',
      is_standalone_user: isStandaloneUser,
      UserType: UserType,
      SubscriberId: SubscriberId,
    });
  }
};

import React from 'react';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { Controller, useForm } from 'react-hook-form';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import { WizardStepProps } from 'src/domain';
import { CenteredLoader, Navigation, Step } from 'src/common';
import { useGetAvidPackage, useUpdateAvidPackage } from '../../hooks';
import { AvidSteps } from '../../AvidCreation/steps';

export interface AvidInspectionDateTimeInputs {
  dateTime: Date;
}

export const AvidInspectionDateTime = ({ goToStep }: WizardStepProps) => {
  const { mutate: updateAvid, isLoading: isUpdating } = useUpdateAvidPackage();
  const { packageId } = useParams<{ packageId: string }>();
  const { data: avidPackage } = useGetAvidPackage(packageId);
  const defaultDate = avidPackage ? new Date(avidPackage.workflowData.inspectionDateTime) : new Date();
  const { control, handleSubmit } = useForm<AvidInspectionDateTimeInputs>({
    mode: 'onTouched',
  });

  const onSubmit = handleSubmit((data: AvidInspectionDateTimeInputs) => {
    updateAvid(
      {
        packageId,
        inspectionDateTime: data.dateTime.toISOString(),
      },
      {
        onSuccess: () => goToStep(AvidSteps.weather),
      }
    );
  });

  if (!avidPackage) {
    return <CenteredLoader />;
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Step title="When did the inspection occur?">
        <form onSubmit={onSubmit}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                defaultValue={defaultDate}
                name="dateTime"
                render={({ onChange, value, onBlur }) => (
                  <DateTimePicker
                    showTodayButton
                    fullWidth
                    inputVariant="outlined"
                    format="MM/dd/yy hh:mm a"
                    label="Inspection Date"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </Grid>
          </Grid>
        </form>
        <Navigation
          isLoading={isUpdating}
          PrevButtonProps={{
            onClick: () => goToStep(AvidSteps.rooms),
          }}
          NextButtonProps={{
            onClick: onSubmit,
            disabled: !avidPackage,
          }}
        />
      </Step>
    </MuiPickersUtilsProvider>
  );
};

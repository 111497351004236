import { makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import { Info } from '@material-ui/icons';
import { useRecoilState, useRecoilValue } from 'recoil';
import { selectedAgentAtom } from 'src/state';
import { WIZARD_HEADER_HEIGHT } from 'src/domain';
import { customBannerAtom } from 'src/state/customBannerAtom';
import { DialogCloseButton } from './DialogCloseButton';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      width: '100%',
      height: WIZARD_HEADER_HEIGHT,
      backgroundColor: '#0A2642',
      zIndex: 1500,
      position: 'relative',
      top: theme.spacing(0),
    },
    content: {
      alignItems: 'center',
      display: 'flex',
      padding: theme.spacing(0, 3),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 1),
      },
      height: '100%',
    },
    message: {
      [theme.breakpoints.down('sm')]: {
        fontSize: 12,
      },
      color: 'white',
      fontSize: '1rem',
      fontWeight: 'bold',
      marginLeft: theme.spacing(1),
      flexGrow: 1,
    },
  }),
  { classNamePrefix: 'CustomBanner' }
);

export const CustomBanner: React.FC = () => {
  const classes = useStyles();
  const selectedAgent = useRecoilValue(selectedAgentAtom);
  const [showBanner, setShowBanner] = useRecoilState(customBannerAtom);
  const title = selectedAgent
    ? `${selectedAgent?.firstName} ${selectedAgent?.lastName}'s access to C.A.R. has expired. Please contact them to renew their credentials for continued access.`
    : 'Your access to C.A.R. has expired. Please renew for continued access.';

  if (!showBanner) return null;
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Info style={{ fill: 'white' }} />
        <Typography variant="body1" className={classes.message}>
          {title}
        </Typography>
        <DialogCloseButton absolutePosition={false} onClick={() => setShowBanner(false)} />
      </div>
    </div>
  );
};

import React from 'react';
import { ListItem, ListItemIcon, ListItemText, makeStyles, Theme } from '@material-ui/core';
import { AccountCircleSharp as UserIcon, ChevronRightSharp as ChevronRightIcon } from '@material-ui/icons';
import { useRecoilValue } from 'recoil';
import { useGetUserProfile } from 'src/hooks';
import { selectedAgentAtom } from 'src/state';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: theme.spacing(3),
      background: 'white',
      color: theme.colors.grey.bodyHeader,
      display: 'flex',
      borderTop: `1px solid ${theme.colors.grey.disable}`,
      borderBottom: `1px solid ${theme.colors.grey.disable}`,
    },
    icon: {
      fontSize: 20,
    },
  }),
  { classNamePrefix: 'SelectAgentNavRow' }
);

/**
 * A ListItem that goes in the primary navigation to allow a TC to switch which
 * agent they're acting on behalf of on mobile.
 */
export const SelectAgentNavRow: React.FC<React.HtmlHTMLAttributes<HTMLDivElement>> = (props) => {
  const classes = useStyles();

  const { data: userProfile, isLoading } = useGetUserProfile();
  const selectedAgent = useRecoilValue(selectedAgentAtom);
  const currentAgent = selectedAgent || userProfile;

  if (isLoading) return null;

  return (
    <ListItem {...props} button aria-label="Choose an agent to work on behalf of" className={classes.root}>
      <ListItemIcon className={classes.icon}>
        <UserIcon />
      </ListItemIcon>
      <ListItemText
        primaryTypographyProps={{
          variant: 'body1',
        }}
      >
        {selectedAgent != null && 'Viewing as '}
        <span style={{ fontWeight: 'bold' }}>
          {currentAgent?.firstName?.[0]}. {currentAgent?.lastName}
        </span>
      </ListItemText>
      <ChevronRightIcon />
    </ListItem>
  );
};

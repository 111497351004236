import { useQuery, UseQueryOptions } from 'react-query';
import { useRecoilValue } from 'recoil';
import { AxiosError } from 'axios';
import * as Sentry from '@sentry/react';
import { selectedAgentAtom } from 'src/state';
import { FormsUserProfile, getFormsUserProfile, useAuthStore } from 'src/lib';
import { getUserProfileKey } from 'src/hooks';

export const useGetActiveUserProfile = (
  options: UseQueryOptions<FormsUserProfile, AxiosError, FormsUserProfile> = {}
) => {
  const authStore = useAuthStore();
  const selectedAgent = useRecoilValue(selectedAgentAtom);
  const userId = selectedAgent?.userId || authStore?.userId;

  return useQuery([getUserProfileKey, userId], () => getFormsUserProfile(userId), {
    ...options,
    enabled: Boolean(userId && (options.enabled == null || options.enabled)),
    onError(e) {
      Sentry.captureException(e);
      options?.onError?.(e);
      console.error('An error occurred while getting active user profile');
    },
  });
};

import { chameleonInit } from './chameleonInit';

const adminKey = 'adminPreview';
const chameleonProperties = 'setup identify alias track clear set show on off custom help _data'.split(' ');

export interface ChameleonUserProperties {
  userId: string;
  Email: string;
  FirstName: string;
  LastName: string;
  role?: string;
  UserType?: string | undefined;
  SubscriberId?: number | undefined;
  BreezeDisclosuresSent: number | undefined;
  BreezeDisclosuresCompleted: number | undefined;
  BreezeAvidSaved: number | undefined;
  BreezeAvidCompleted: number | undefined;
  IsBreezeUser?: number | undefined;
}

export const chameleon = {
  initialize(key: string) {
    chameleonInit(key, adminKey, chameleonProperties);
  },
  setupUser(
    userId: string,
    Email: string,
    role: string | undefined,
    FirstName: string,
    LastName: string,
    UserType: string | undefined,
    SubscriberId: number | undefined,
    BreezeDisclosuresSent: number | undefined,
    BreezeDisclosuresCompleted: number | undefined,
    BreezeAvidSaved: number | undefined,
    BreezeAvidCompleted: number | undefined,
    IsBreezeUser: number | undefined
  ) {
    const userProperties: ChameleonUserProperties = {
      userId,
      Email,
      role,
      FirstName,
      LastName,
      UserType,
      SubscriberId,
      BreezeDisclosuresSent,
      BreezeDisclosuresCompleted,
      BreezeAvidSaved,
      BreezeAvidCompleted,
      IsBreezeUser,
    };

    window.chmln.identify(userId, userProperties);
  },
};

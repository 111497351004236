import React from 'react';

export interface TeamMemberListContextShape {
  allowMultiple?: boolean;
  value: Array<string | undefined | null>;
  onMemberSelected(memberValue: string | undefined | null): void;
}

export const TeamMemberListContext = React.createContext<TeamMemberListContextShape>({
  allowMultiple: false,
  value: [],
  onMemberSelected: () => {},
});

import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { InputAdornment, makeStyles, Theme, CircularProgress } from '@material-ui/core';
import { SearchSharp as SearchIcon } from '@material-ui/icons';
import { TextInput, TextInputProps } from 'src/common';
import { useIsMobile } from 'src/hooks';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
    },
    searchInputComponent: {
      borderRadius: 999,
      fontSize: '1rem',
    },
    searchInputEl: {
      '&:disabled': {
        color: theme.colors.grey.bodyHeader,
        /* Chrome/Opera/Safari */
        '&::-webkit-input-placeholder': {
          opacity: 1,
          color: theme.colors.grey.grey1,
        },
        /* Firefox 19+ */
        '&::-moz-placeholder': {
          opacity: 1,
          color: theme.colors.grey.grey1,
        },
        /* IE 10+ */
        '&:-ms-input-placeholder': {
          opacity: 1,
          color: theme.colors.grey.grey1,
        },
        /* Firefox 18- */
        '&:-moz-placeholder': {
          opacity: 1,
          color: theme.colors.grey.grey1,
        },
      },
    },
    threeCharError: {
      color: theme.colors.error,
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
    },
  }),
  { classNamePrefix: 'SearchInput' }
);

export interface SearchInputProps extends TextInputProps {
  filterText: string;
  setFilterText(filterText: string): void;
  defaultClosed?: boolean;
  isFetching?: boolean;
  className?: string;
  minLength?: number;
}

export const SearchInput: React.FC<SearchInputProps> = (props) => {
  const classes = useStyles();
  const { className, filterText, setFilterText, isFetching, InputProps = {}, inputProps, minLength, ...rest } = props;

  const [isWaitingForMoreChars, setIsForMoreChars] = useState(false);
  const isMobile = useIsMobile();
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (!isMobile) {
      inputRef?.current?.focus();
    }
  }, [isMobile, inputRef]);

  // If the user has typed less than minLength characters and is sitting
  // there waiting for the page to load, show them a message telling
  // them they need to type more
  useEffect(() => {
    if ((minLength && filterText.length >= minLength) || filterText.length === 0) {
      setIsForMoreChars(false);
    }

    const timeout = setTimeout(() => {
      const length = inputRef?.current?.value.length || 0;
      if (length > 0 && length < 3) setIsForMoreChars(true);
    }, 2000);

    return () => clearTimeout(timeout);
  }, [filterText, inputRef, minLength]);

  return (
    <div className={clsx(classes.root, className)}>
      <TextInput
        {...rest}
        placeholder="Search..."
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
        inputRef={inputRef}
        inputProps={{
          ...inputProps,
          className: clsx(classes.searchInputEl, inputProps?.className),
        }}
        InputProps={{
          ...InputProps,
          autoFocus: isMobile,
          className: clsx(classes.searchInputComponent, InputProps.className),
          endAdornment: (
            <InputAdornment position="end">
              {!isWaitingForMoreChars && (isFetching ? <CircularProgress size={24} /> : <SearchIcon />)}
            </InputAdornment>
          ),
        }}
      />
      {minLength && isWaitingForMoreChars && (
        <span className={classes.threeCharError}>Please enter at least {minLength} characters</span>
      )}
    </div>
  );
};

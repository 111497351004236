import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useGetFileById, useSendDisclosuresEnvelopeMutation } from 'src/hooks';
import { CenteredLoader, useComposeEmail } from 'src/common';
import { WizardStepProps, EmailForm } from 'src/domain';
import { Contact } from 'src/lib';
import { useDisclosuresStore } from '../store';
import { SpqTdsDisclosureSteps } from './constants';

export const SpqTdsRequestSignaturesEmail = ({ goToStep }: WizardStepProps) => {
  const history = useHistory();
  const store = useDisclosuresStore();
  const fileId = store.existingFileId ?? -1;

  const { data: existingFile, isLoading } = useGetFileById(fileId, {
    enabled: !!fileId,
  });

  const emailState = useComposeEmail({
    initialSubject: 'Request for signature on your completed seller disclosure',
  });

  const { mutate: sendForSignatures } = useSendDisclosuresEnvelopeMutation(fileId);

  useEffect(() => {
    if (isLoading) return;

    const targetSellers = existingFile?.fileData.sellers.filter((seller) => seller.actionAttribute === 'NeedsToSign');
    const address = existingFile?.fileData.premisesAddress.streetAddress;

    const defaultBody = generateDefaultEmailBody(targetSellers, address!);
    emailState.setBody(defaultBody);

    const sellerEmails = targetSellers?.map((seller) => seller.email).filter((x) => x) as string[];
    emailState.setRecipients(sellerEmails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const onSubmit = async () => {
    sendForSignatures({
      packageId: store.existingPackageId ?? '',
      subject: emailState.subject ?? '',
      body: emailState.body ?? '',
    });

    goToStep(SpqTdsDisclosureSteps.sendingEnvelope);
  };

  if (!existingFile) return <CenteredLoader />;
  return (
    <EmailForm
      onBack={() => history.push(`/file/${store.existingFileId}/packages`)}
      title="What would you like to say in your request for signatures?"
      onSend={onSubmit}
      emailState={emailState}
      hideCc
      disableEditRecipients
    />
  );
};

function generateDefaultEmailBody(targetSellers: Contact[] | undefined, address: string) {
  const targetSellersString =
    targetSellers?.length === 2
      ? `Hi ${targetSellers[0]?.firstName} & ${targetSellers[1]?.firstName},`
      : `Hi ${targetSellers?.[0]?.firstName},`;

  return `${targetSellersString}
  
Thanks for completing your seller disclosures for ${address}.
Please review and sign your disclosures.
Feel free to reach out if you have any questions. I'm always here to help.`;
}

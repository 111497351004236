import React, { useCallback, useState } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import { Skeleton as Skellie } from '@material-ui/lab';
import { useParams } from 'react-router-dom';
import format from 'date-fns/format';
import { useAuth } from '@skyslope/auth-react';
import pluralize from 'pluralize';
import { useHistory } from 'react-router';
import * as Sentry from '@sentry/react';
import { ReviewRow, ReviewStep, WizardStepProps } from 'src/domain';
import { useCustomSnackbar, useGetFileById, useIsMobile } from 'src/hooks';
import AvidIcon from 'src/images/avidIcon.svg';
import { Navigation, Step } from 'src/common';
import { contactActions, ErrorCodes, formatAddress } from 'src/lib';
import { AvidRoomNotes } from 'src/pages';
import { ExpandableReviewRow } from 'src/domain/wizard/generic-steps/ReviewStep/ExpandableReviewRow';
import { AvidSteps } from '../../AvidCreation';
import { useGetAvidPackage, useUpdateAvidPackage, useGetFileContactsByRepType } from '../../hooks';
import { AvidRoom } from '../../types';
import { useAvidUserProfileBoundary } from '../useAvidUserProfileBoundary';

const useStyles = makeStyles(() => ({
  address: {
    fontStyle: 'normal',
  },
}));

export const AvidReview = ({ goToStep, ...props }: WizardStepProps) => {
  const { packageId } = useParams<{ packageId: string }>();
  const classes = useStyles(props);
  const { data: avidPackage } = useGetAvidPackage(packageId);
  const isMobile = useIsMobile();
  const { authState } = useAuth();
  const history = useHistory();
  const [editRoom, setEditRoom] = useState<AvidRoom>();
  const { data: file } = useGetFileById(avidPackage?.fileId);
  const { contacts } = useGetFileContactsByRepType(avidPackage?.fileId);
  const { mutate: updatePackage, isLoading: isMutating } = useUpdateAvidPackage();
  const rooms = avidPackage?.workflowData.rooms;
  const snackbarController = useCustomSnackbar();
  const isMissingRecipients = !contacts?.length;

  const handleAddNotes = useCallback(
    (selectedRoom: AvidRoom) => {
      const roomsCopy = rooms?.slice() ?? [];
      const updatedRoomIndex = rooms?.findIndex(
        (room) => room.name === selectedRoom.name && room.order === selectedRoom.order
      );

      if (updatedRoomIndex == null || updatedRoomIndex < 0) {
        return;
      }

      roomsCopy[updatedRoomIndex] = selectedRoom;
      updatePackage(
        {
          packageId: packageId!,
          rooms: roomsCopy,
        },
        {
          onSuccess: () => {
            setEditRoom(undefined);
          },
        }
      );
    },
    [rooms, packageId, updatePackage]
  );

  const onEdit = (step: AvidSteps) => {
    goToStep(step, { nextGoToStep: AvidSteps.review });
  };

  // Go to AvidSteps.editFileTeamMembers once the long term solution described
  // in INNO2-926 is implemented
  const onEditTeam = () => {
    goToStep(AvidSteps.teamMembersDeprecated);
  };

  const handleNextClick = () => {
    if (!contacts?.length) {
      goToStep(AvidSteps.contacts, { nextGoToStep: AvidSteps.review });
      return;
    }

    updatePackage(
      {
        packageId,
        isReviewed: true,
      },
      {
        onSuccess() {
          if (authState.isAuthenticated) {
            history.push(`/file/${avidPackage?.fileId}/packages`);
          } else {
            goToStep(AvidSteps.login);
          }
        },
        onError(e) {
          console.error('An error occurred while updating AVID package (in review)');
          const eventId = Sentry.captureException(e);

          snackbarController.addErrorSnackbar({
            message: 'Sorry, we were unable to save your AVID at this time',
            errorCode: ErrorCodes.UpdateAvidPackage,
            eventId,
          });
        },
      }
    );
  };

  const { isLoading: isBoundaryLoading, boundedAction } = useAvidUserProfileBoundary({
    goToStep: goToStep,
    targetAction: handleNextClick,
  });

  const roomCount = avidPackage?.workflowData.rooms?.length ?? 0;

  if (!avidPackage || !file) {
    return (
      <Step title={<Skellie style={{ margin: 'auto' }} width={500} height={60} />}>
        <Grid container spacing={!isMobile ? 2 : undefined}>
          {Array.from({ length: !isMobile ? 15 : 6 }).map((_, i) => (
            <Grid item xs={12} sm={4} key={i}>
              <Skellie height={60} />
            </Grid>
          ))}
        </Grid>
      </Step>
    );
  }

  const AvidComponents = () => (
    <>
      <ReviewRow
        label="Property"
        rightSideContent={
          <ReviewRow.Button onClick={() => goToStep(AvidSteps.editPropertyInfo, { nextGoToStep: AvidSteps.review })}>
            Edit
          </ReviewRow.Button>
        }
      >
        <address className={classes.address}>{formatAddress(file.fileData.premisesAddress)}</address>
      </ReviewRow>

      <ExpandableReviewRow
        label="Observations"
        value={`${roomCount} ${pluralize('Room', roomCount)}`}
        onEdit={() => onEdit(AvidSteps.rooms)}
      >
        {rooms?.map((r, i) => (
          <ExpandableReviewRow.Item key={i} value={r.name} onEdit={() => setEditRoom(r)} />
        ))}
      </ExpandableReviewRow>

      <ExpandableReviewRow
        label="Inspection Details"
        value={`${format(new Date(avidPackage.workflowData.inspectionDateTime), 'MM/dd/yy hh:mm a')}
          ${avidPackage.workflowData.inspectionWeather && `, ${avidPackage.workflowData.inspectionWeather}`}`}
      >
        <ExpandableReviewRow.Item onEdit={() => onEdit(AvidSteps.inspectionDateTime)}>
          <div>Inspection Date:</div>
          <br />
          <div>{format(new Date(avidPackage.workflowData.inspectionDateTime), 'MM/dd/yy hh:mm a')}</div>
        </ExpandableReviewRow.Item>
        <ExpandableReviewRow.Item onEdit={() => onEdit(AvidSteps.weather)}>
          <div>Weather:</div>
          <br />
          <div>{avidPackage.workflowData.inspectionWeather}</div>
        </ExpandableReviewRow.Item>
      </ExpandableReviewRow>

      <ReviewRow
        label="Others Present"
        rightSideContent={<ReviewRow.Button onClick={() => onEdit(AvidSteps.peoplePresent)}>Edit</ReviewRow.Button>}
      >
        {avidPackage.workflowData.peoplePresent ? avidPackage.workflowData.peoplePresent : 'None'}
      </ReviewRow>
    </>
  );

  return (
    <>
      {editRoom ? (
        <AvidRoomNotes
          room={editRoom}
          onNext={handleAddNotes}
          onPrevious={() => setEditRoom(undefined)}
          isSaving={isMutating}
        />
      ) : (
        <ReviewStep
          icon={<img src={AvidIcon} alt="AVID" />}
          navigation={
            <Navigation
              isLoading={isMutating}
              PrevButtonProps={{ children: 'Cancel', hidden: true }}
              NextButtonProps={{
                children: 'Save',
                onClick: boundedAction,
                disabled: isBoundaryLoading || isMissingRecipients,
                endIcon: null,
              }}
            />
          }
          teamMembers={file.contacts.filter((contact) => contact.actionAttribute === contactActions.RECEIVESCOPY)}
          recipients={contacts!}
          onEditTeam={onEditTeam}
          onEditRecipients={() => onEdit(AvidSteps.contacts)}
        >
          <AvidComponents />
        </ReviewStep>
      )}
    </>
  );
};

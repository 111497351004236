import { useQuery, UseQueryOptions } from 'react-query';
import * as Sentry from '@sentry/react';
import { useGetFileById } from 'src/hooks';
import { Weather } from '../../../lib';
import { getWeatherConditions } from '../../../lib/api/openweather';

export const useGetWeatherConditions = (fileId: number | undefined, options: UseQueryOptions<Weather> = {}) => {
  const { data: file } = useGetFileById(fileId);
  const city = file?.fileData.premisesAddress.city;
  return useQuery('get-weather', async () => await getWeatherConditions(city!), {
    ...options,
    enabled: !!city,
    onError(e) {
      options?.onError?.(e);
      console.error('An error occurred while getting whether conditions');
      Sentry.captureException(e);
    },
  });
};

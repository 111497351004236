import React, { ChangeEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Container } from '@material-ui/core';
import { Navigation, Step, TextInput } from 'src/common';
import { WizardStepProps } from 'src/domain';
import { useDisclosuresStore } from '../store';
import { useDisclosuresConditionalSteps } from '../hooks/useDisclosuresConditionalSteps';
import { SpqTdsDisclosureSteps } from './constants';

interface Form {
  zoning: string;
}

export const Zoning = (props: WizardStepProps) => {
  const { goToStep } = props;
  const store = useDisclosuresStore();
  const { prevStep, nextStep } = useDisclosuresConditionalSteps(SpqTdsDisclosureSteps.zoning, store.forms! ?? []);

  const formProps = useForm<Form>({
    mode: 'onTouched',
  });

  const [zoningValue, setZoningValue] = useState('');

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setZoningValue(event.target.value);
  };

  const hasRequiredFields = !!store.zoning || !!zoningValue;

  return (
    <Step title="Please enter the zoning for this property.">
      <form
        onSubmit={formProps.handleSubmit((form) => {
          store.update(form);
          goToStep(nextStep!);
        })}
      >
        <Container maxWidth="xs">
          <TextInput
            name="zoning"
            inputRef={formProps.register({
              required: true,
            })}
            defaultValue={store.zoning}
            placeholder=""
            onChange={handleChange}
          />
        </Container>
        <Navigation
          PrevButtonProps={{ onClick: () => goToStep(prevStep) }}
          NextButtonProps={{
            disabled: Object.keys(formProps.errors).length > 0 || !hasRequiredFields,
          }}
        />
      </form>
    </Step>
  );
};

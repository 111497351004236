import React from 'react';
import { Address, appEnvironments, Contact, ENV, WizardStoreApi } from 'src/lib';
import { PackageForm, PackageStatus } from 'src/pages/FilePackages/types';
export interface ResendDisclosuresStore {
  packageStatus?: PackageStatus;
  property?: Address;
  sellers: Contact[];
  fileId?: number;
  packageId: string;
  forms: PackageForm[]; // The original forms that were sent with the package
  selectedInterviewIds: number[]; // The interviews we want to resend
}

const defaultStore: WizardStoreApi<ResendDisclosuresStore> = {
  packageId: '',
  selectedInterviewIds: [],
  sellers: [],
  forms: [],
  update: () => null,
  reset: () => null,
};

export const ResendDisclosuresStoreContext = React.createContext<WizardStoreApi<ResendDisclosuresStore>>(defaultStore);

export const useCreateResendDisclosuresStore = (
  defaultOverride: Partial<ResendDisclosuresStore> = {}
): WizardStoreApi<ResendDisclosuresStore> => {
  const [store, setStore] = React.useState<ResendDisclosuresStore>({
    ...defaultStore,
    ...defaultOverride,
  });

  const update = (obj: Partial<ResendDisclosuresStore>) => {
    const newStore = { ...store, ...obj };
    setStore(newStore);
    if (ENV !== appEnvironments.PROD) {
      // eslint-disable-next-line no-console
      console.info('STORE UPDATED: ', newStore);
    }
  };

  const reset = () => {
    setStore(defaultStore);
  };

  return { ...store, update, reset };
};

export const ResendDisclosuresStoreProvider = ResendDisclosuresStoreContext.Provider;

export const useResendDisclosuresStore = () => React.useContext(ResendDisclosuresStoreContext);

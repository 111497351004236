import { useMemo } from 'react';

export const useId = (prefix: string | undefined = undefined) => useMemo(() => uniqueId(prefix), [prefix]);

const idCounter = {};

// Mostly borrowed from:
// https://github.com/lodash/lodash/blob/2f79053d7bc7c9c9561a30dda202b3dcd2b72b90/uniqueId.js
function uniqueId(prefix = 'default') {
  if (!idCounter[prefix]) {
    idCounter[prefix] = 0;
  }

  const id = ++idCounter[prefix];
  return prefix === 'default' ? String(id) : prefix + id;
}

import React from 'react';
import { useHistory } from 'react-router-dom';
import { Theme } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { WizardStepProps } from 'src/domain';
import { ResendDisclosuresSteps } from 'src/pages/ResendDisclosures/steps/constants';
import { routes } from 'src/lib';
import { Navigation, Step } from 'src/common';
import { useResendDisclosuresStore } from 'src/pages/ResendDisclosures/store';
import { SelectDisclosuresList, useDisclosuresListSelection } from 'src/pages/ResendDisclosures/components';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: theme.spacing(90.5),
  },
}));

export const SelectDisclosuresStep = (props: WizardStepProps) => {
  const history = useHistory();
  const classes = useStyles();
  const store = useResendDisclosuresStore();
  const { onSelect, selection } = useDisclosuresListSelection(store.forms, store.selectedInterviewIds);

  const onClose = () => {
    history.push(routes.filePackages.replace(':fileId', String(store.fileId)));
  };
  const onSubmit = () => {
    const selectedInterviewIds = Object.keys(selection)
      .filter((key) => !!selection[key])
      .map((id) => parseInt(id));
    store.update({ selectedInterviewIds });
    props.goToStep(ResendDisclosuresSteps.customEmail!);
  };

  return (
    <Step title="Which disclosure forms would you like to resend?">
      <Container maxWidth={false} className={classes.root} disableGutters>
        <SelectDisclosuresList forms={store.forms} selection={selection} onSelect={onSelect} />
        <Navigation
          PrevButtonProps={{ onClick: onClose, children: 'Cancel' }}
          NextButtonProps={{
            onClick: onSubmit,
            disabled: Object.keys(selection).filter((i) => selection[i] === true).length < 1,
            children: 'Next',
          }}
        />
      </Container>
    </Step>
  );
};

import { useCallback } from 'react';
import { useCustomSnackbar } from '../useCustomSnackbar';
import { DEFAULT_SNACKBAR_DURATION } from '../../common';
import { DelegationRequestOrGrantResponse } from '../../lib';

export const useDelegationRequestOrAssignSnackbars = (
  createSuccessMessage: (email: string) => string,
  createInfoMessage: (email: string) => string
) => {
  const snackbarController = useCustomSnackbar({
    autoHideDuration: DEFAULT_SNACKBAR_DURATION * 2,
  });

  return useCallback(
    (response: DelegationRequestOrGrantResponse) => {
      response.existingUserEmailsHandled.forEach((email) => {
        snackbarController.addSuccessSnackbar(createSuccessMessage(email));
      });

      response.newUserEmailsHandled.forEach((email) => {
        snackbarController.addInfoSnackbar(createInfoMessage(email));
      });
    },
    [snackbarController, createInfoMessage, createSuccessMessage]
  );
};

import React, { useMemo } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles';
import { List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Skeleton } from '@material-ui/lab';
import { FlagButton } from 'src/common/FlagButton';
import { PackageForm, PackageInterview } from '../types';
import { DisclosuresAssociationName } from '../../../common/DisclosuresAssociationName';
import { FormInterviewStatus } from './FormInterviewStatus';

const useStyles = makeStyles((theme: Theme) => ({
  formRow: {
    padding: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4),
    },
    '&:hover': {
      backgroundColor: theme.colors.mainColor.hoverLight,
    },
  },
  listItemButton: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'inherit',
    },
  },
  listItemText: {
    display: 'flex',
    alignItems: 'center',
  },
  formName: {
    flex: '1 1 75%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'left',
    fontWeight: 600,
  },
}));

export interface FilePackageItemFormsProps {
  forms: PackageForm[];
  onPreview: (form: PackageForm) => void;
  interviews?: PackageInterview[];
  showIndividualFormStatus?: boolean;
}

export const FilePackageItemForms: React.FC<FilePackageItemFormsProps> = ({
  showIndividualFormStatus = false,
  forms,
  onPreview,
  interviews = [],
}) => {
  const classes = useStyles();
  const interviewLookup = useMemo(
    () =>
      interviews.reduce(
        (previous, current) => ({
          [current.id]: current,
          ...previous,
        }),
        {}
      ),
    [interviews]
  );
  const allFormsInTerminalStatus = interviews?.every((i) => ['CANCELLED', 'EXPIRED'].includes(i.status));
  const IfFlaggedQuestions = (interview: any) => {
    let displayIcon = false;
    // do not proceed if the form is in an AVID
    if (!interview.interview) return <></>;
    interview.interview.signers?.forEach((signer: any) => {
      if (signer.flags.length > 0) displayIcon = true;
    });
    return displayIcon ? <FlagButton /> : <></>;
  };
  return (
    <List disablePadding>
      {forms?.map((form, i, { length }) => {
        const formInterview = interviewLookup[form.interviewId];
        return (
          <ListItem
            key={form.interviewId}
            button
            classes={{
              button: classes.formRow,
            }}
            divider={i < length - 1}
            onClick={() => onPreview(form)}
          >
            <ListItemText className={classes.listItemText} disableTypography>
              <Typography component="span" variant="body1" className={classes.formName}>
                {form.name}
                <DisclosuresAssociationName associationName={form.associationName} />
              </Typography>
              <IfFlaggedQuestions interview={formInterview} />
              {showIndividualFormStatus && !allFormsInTerminalStatus && formInterview && (
                <FormInterviewStatus interview={formInterview} />
              )}
            </ListItemText>
            <Button
              onClick={() => onPreview(form)}
              size="small"
              color="primary"
              className={classes.listItemButton}
              endIcon={<ArrowForwardIcon />}
            >
              VIEW
            </Button>
          </ListItem>
        );
      }) ?? (
        <ListItem
          button
          classes={{
            button: classes.formRow,
          }}
          divider
        >
          <ListItemText>
            <Skeleton width={400} />
          </ListItemText>
          <ListItemSecondaryAction>
            <Skeleton width={75} />
          </ListItemSecondaryAction>
        </ListItem>
      )}
    </List>
  );
};

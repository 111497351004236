import { makeStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React, { lazy } from 'react';
import { noOp } from 'src/lib';
import { Step } from 'src/common';
import { useLoadingStatuses } from 'src/hooks';

const LottieAnimation = lazy(() => import('src/common/LottieAnimation'));

const useStyles = makeStyles((theme: Theme) => ({
  statusContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 200,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 340,
    },
  },
}));

export interface SurferBoiLoadingProps {
  loadingStatuses: string[];
  mutationKey: string;
  onDone?: () => void;
  title: string;
}

export const SurferBoiLoading: React.FC<SurferBoiLoadingProps> = ({
  loadingStatuses,
  onDone = noOp,
  mutationKey,
  title,
}) => {
  const classes = useStyles();

  const { currentStatus } = useLoadingStatuses({
    mutationKey,
    onDone,
    loadingStatuses,
  });

  return (
    <Step title={title}>
      <div className={classes.statusContainer}>
        <LottieAnimation animation="surferBoi" />
      </div>
      <Typography variant="body2" className={classes.statusContainer}>
        {currentStatus}
      </Typography>
    </Step>
  );
};

import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

const ANIMATION_TIME = 500;

export interface AnimatedRouteProps {
  shouldRender: boolean;
}

const useStyles = makeStyles(
  () => ({
    step: {
      maxWidth: 'inherit',
      position: 'absolute',
      width: '100%',
    },
    '@global': {
      '.wizard-forward-enter': {
        opacity: 0,
        transform: 'translate3d(100%, 0, 0)',
      },
      '.wizard-backward-enter': {
        opacity: 0,
        transform: 'translate3d(-100%, 0, 0)',
      },
      '.wizard-forward-enter-active, .wizard-backward-enter-active': {
        opacity: 1,
        transform: 'none',
        transition: `opacity ${ANIMATION_TIME}ms, transform ${ANIMATION_TIME}ms`,
      },
      '.wizard-forward-exit, .wizard-backward-exit': {
        opacity: 1,
        transform: 'none',
      },
      '.wizard-forward-exit-active': {
        opacity: 0,
        transform: 'translate3d(-100%, 0, 0)',
        transition: `opacity ${ANIMATION_TIME}ms, transform ${ANIMATION_TIME}ms`,
      },
      '.wizard-backward-exit-active': {
        opacity: 0,
        transform: 'translate3d(100%, 0, 0)',
        transition: `opacity ${ANIMATION_TIME}ms, transform ${ANIMATION_TIME}ms`,
      },
    },
  }),
  { classNamePrefix: 'StepTransition' }
);

export const StepTransition: React.FC<AnimatedRouteProps> = ({ children, shouldRender }) => {
  const location = useLocation<{ animateForward?: boolean }>();
  const classes = useStyles();
  const animateForward = location.state?.animateForward ?? true;
  return (
    <CSSTransition
      in={shouldRender}
      timeout={ANIMATION_TIME}
      classNames={animateForward ? 'wizard-forward' : 'wizard-backward'}
      mountOnEnter
      unmountOnExit
    >
      <div className={classes.step}>{children}</div>
    </CSSTransition>
  );
};

import { UseMutationOptions, useMutation } from 'react-query';
import { gql } from 'graphql-request';
import * as Sentry from '@sentry/react';
import { ErrorCodes, getGraphQLClient, getApiToken } from '../../../lib';
import { useCustomSnackbar } from '../../useCustomSnackbar';

interface GetTokenVariables {
  envelopeId: string;
  email: string;
}

export const agentSignerTokenKey = 'agent-sign-now';

const query = gql`
  query getToken($envelopeId: String!, $email: String!) {
    agentSignerToken(envelopeId: $envelopeId, email: $email)
  }
`;

export const useFetchAgentSignerToken = (options: UseMutationOptions<unknown, unknown, GetTokenVariables> = {}) => {
  const snackbarController = useCustomSnackbar();

  return useMutation(
    async (variables: GetTokenVariables) => {
      const client = getGraphQLClient(await getApiToken());
      return (await client.request<{ agentSignerToken: string }>(query, variables)).agentSignerToken;
    },
    {
      ...options,
      onError(e) {
        console.error(e);
        const eventId = Sentry.captureException(e);
        snackbarController.addErrorSnackbar({
          errorCode: ErrorCodes.FetchAgentSignerToken,
          eventId,
        });
      },
    }
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles, Theme } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useAuth } from '@skyslope/auth-react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import {
  Address,
  GoogleSearchResult,
  LookupType,
  MLSListing,
  isCompleteAddress,
  ManualSearchResult,
  sanitizeAddress,
} from 'src/lib';
import { Toggle, Step, FileSelector, Navigation } from 'src/common';
import { MultiSourceAddressSearch, WizardStepProps } from 'src/domain';
import { useTayTip } from 'src/domain/wizard/useTayTip';
import { useGetFileById } from 'src/hooks';
import { emailStateAtom, selectedAgentAtom } from 'src/state';
import { useDisclosuresStore } from '../store';
import { SpqTdsDisclosureSteps } from './constants';

const useStyles = makeStyles((theme: Theme) => ({
  toggleWrapper: {
    marginBottom: 24,
    textAlign: 'center',
  },
  property: {
    position: 'relative',
    '&:not(:first-child)': {
      marginTop: 64,
    },
  },
  error: {
    color: theme.colors.error,
  },
  form: {
    ['@media screen and (max-width: 1040px)']: {
      stoverflow: 'hidden',
    },
  },
  fileSelector: {
    paddingTop: 30,
  },
}));

type Inputs = {
  property: Address;
  apn: string;
  yearBuilt: string;
  zoning: string;
};

export const SpqTdsProperty = (props: WizardStepProps) => {
  const classes = useStyles();
  const { goToStep } = props;

  const store = useDisclosuresStore();
  const { authState } = useAuth();
  const selectedAgent = useRecoilValue(selectedAgentAtom);
  const setEmailState = useSetRecoilState(emailStateAtom);
  // Whenever the user lands on the property screen (when they start a new disclosure),
  // clear the email state so that this flow doesn't have any old custom email text.
  setEmailState(null);
  const methods = useForm<Inputs>({
    mode: 'all',
    defaultValues: {
      property: store.property,
      apn: store.apn,
      yearBuilt: store.yearBuilt,
      zoning: store.zoning,
    },
  });
  const { register, setValue, trigger, getValues } = methods;

  const { data: existingFile, isLoading } = useGetFileById(store.existingFileId, {
    enabled: !!store.existingFileId,
  });

  const isValidAddress = store.property && isCompleteAddress(store.property);
  const hasRequiredFields = store.existingFileId || (store.property && isValidAddress);

  useTayTip(
    selectedAgent &&
      `By the way, you’re creating this on behalf of ${selectedAgent.firstName} ${selectedAgent.lastName}`
  );

  const propertyTypes = store.propertyTypes;

  useEffect(() => {
    if (!getValues().property) {
      setValue('property', store.property);
    }
  }, []);

  useEffect(() => {
    if (store.existingFileId && existingFile != null) {
      const address = sanitizeAddress(existingFile.fileData.premisesAddress);
      store.update({ property: address });
    }
  }, [existingFile]);

  const previous = () => {
    goToStep(SpqTdsDisclosureSteps.email);
  };

  const defaultAddress: Address = {
    streetAddress: '',
    city: '',
    unitNumber: '',
    state: '',
    postalCode: '',
    county: '',
  };

  const updatePropertyTypes = (val: LookupType, i: number) => {
    const update = [...propertyTypes];
    update[i] = val;
    if (val === 'multi') {
      store.update({
        property: defaultAddress,
        selectedProperty: undefined,
        propertyTypes: update,
        mlsUuid: undefined,
        existingFileId: undefined,
      });
      setTimeout(() => {
        setValue('property', defaultAddress);
      }, 300);
    } else {
      store.update({
        property: defaultAddress,
        selectedProperty: undefined,
        propertyTypes: update,
        mlsUuid: undefined,
      });
    }
  };

  const selectedMlsAddress = (mlsListing: MLSListing) => {
    store.update({
      property: mlsListing.address as Address,
      existingFileId: undefined,
      selectedProperty: mlsListing,
      mlsUuid: mlsListing.uuid,
      apn: mlsListing.parcelNumber ?? '',
      yearBuilt: mlsListing.yearBuilt ? mlsListing.yearBuilt.toString() : '',
      zoning: mlsListing.zoning ?? '',
    });
    setValue('property', mlsListing.address);
    setValue('apn', mlsListing.parcelNumber);
    setValue('yearBuilt', mlsListing.yearBuilt);
    setValue('zoning', mlsListing.zoning);
    trigger();
  };

  const selectedGoogleSearchResult = (searchResult: GoogleSearchResult, address: Address) => {
    store.update({
      property: address,
      existingFileId: undefined,
      selectedProperty: searchResult,
      mlsUuid: '',
      apn: '',
      yearBuilt: '',
      zoning: '',
    });

    setValue('property', address);
    setValue('apn', '');
    setValue('yearBuilt', '');
    setValue('zoning', '');
    trigger();
  };

  const updateManualAddress = (manualResult: ManualSearchResult, address: Address) => {
    store.update({
      property: address,
      existingFileId: undefined,
      selectedProperty: manualResult,
      mlsUuid: '',
      apn: '',
      yearBuilt: '',
      zoning: '',
    });
  };

  const onUnitUpdate = (unit: string) => {
    setValue('property.unitNumber', unit, { shouldValidate: true });
  };

  const onExistingFileSelected = (fileId: number) => {
    store.update({ existingFileId: fileId });
  };

  const toggleOptions: Array<{ value: LookupType; label: string }> = [];

  const submitProperty = () => {
    if (isValidAddress) {
      goToStep(SpqTdsDisclosureSteps.pickForms);
    } else {
      goToStep(SpqTdsDisclosureSteps.missingPropertyInfo);
    }
  };

  if (authState.isAuthenticated) {
    toggleOptions.push({ value: 'multi', label: 'Create new' });
    toggleOptions.push({ value: 'existing', label: 'I already have a file' });
  }

  return (
    <Step
      title={
        authState.isAuthenticated
          ? 'Would you like to send disclosures to a new or existing property?'
          : 'Where is the property located?'
      }
    >
      <FormProvider {...methods}>
        <form className={classes.form}>
          {propertyTypes.map((lookupType, i) => (
            <div key={i} className={classes.property}>
              {authState.isAuthenticated && (
                <div className={classes.toggleWrapper}>
                  <Toggle<LookupType>
                    value={lookupType}
                    onChange={(val) => updatePropertyTypes(val, i)}
                    options={toggleOptions}
                  />
                </div>
              )}
              {lookupType === 'multi' && (
                <MultiSourceAddressSearch
                  onGoogleAddressUpdate={selectedGoogleSearchResult}
                  onMlsAddressUpdate={selectedMlsAddress}
                  onUpdateManualAddress={updateManualAddress}
                  onUnitUpdate={onUnitUpdate}
                  defaultValue={store.selectedProperty}
                  mlsResultSize={10}
                />
              )}
              {lookupType === 'existing' && (
                <div className={classes.fileSelector}>
                  <FileSelector onSelect={onExistingFileSelected} selectedFileId={store.existingFileId} />
                </div>
              )}
              <input type="hidden" name="apn" {...register()} />
              <input type="hidden" name="yearBuilt" {...register()} />
            </div>
          ))}
          <Navigation
            PrevButtonProps={{ onClick: previous, children: 'Back', hidden: authState.isAuthenticated }}
            NextButtonProps={{
              disabled: isLoading || !hasRequiredFields,
              //onClick needs to be undefined when there are no validation errors so that the onSubmit for the Form works as normal
              onClick: hasRequiredFields
                ? () => {
                    submitProperty();
                  }
                : undefined,
            }}
          />
        </form>
      </FormProvider>
    </Step>
  );
};

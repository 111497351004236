import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { WizardStepProps, CustomEmailForm } from 'src/domain';
import { useComposeEmail } from 'src/common';
import { emailStateAtom } from 'src/state';
import { generateAgentSignature } from 'src/common/ComposeEmail/emailUtil';
import { SpqTdsDisclosureSteps } from 'src/pages/SpqTdsDisclosures/steps';
import { useGetActiveUserProfile } from 'src/hooks';
import { formatAddress, usStates } from 'src/lib';
import { useDisclosuresStore } from '../store';

const LANDLORD_TEMPLATE_IDS = [51, 54];

export const CustomEmailStep = ({ goToStep }: WizardStepProps) => {
  const [customEmail, setCustomEmail] = useRecoilState(emailStateAtom);
  const emailState = useComposeEmail({
    initialRecipients: customEmail?.recipients.filter((r) => !!r).map((r) => r?.toString() ?? ''),
    initialCcRecipients: customEmail?.ccRecipients.filter((r) => !!r).map((r) => r?.toString() ?? ''),
    initialSubject: customEmail?.subject ?? '',
    initialBody: customEmail?.body ?? '',
    initialSignature: customEmail?.signature ?? '',
  });
  const { data: agentUserProfile, isFetched: hasFetchedAgentProfile } = useGetActiveUserProfile();

  const store = useDisclosuresStore();
  const isLoading = !hasFetchedAgentProfile;

  useEffect(() => {
    if (isLoading) return;

    const address = formatAddress(store.property);
    const seller = store.sellers[0];
    const state = usStates[store.property?.state || ''] || 'your state';
    const onlySendingLandlordDisclosures =
      store.forms?.length && store.forms.every((i) => LANDLORD_TEMPLATE_IDS.includes(i.interviewTemplateId));

    const body = !emailState.body
      ? `Hi ${seller && seller.firstName},

It’s time to complete your disclosures for ${store.property?.streetAddress || 'your home'}.
    
In ${state}, ${
          onlySendingLandlordDisclosures ? 'landlords' : 'home sellers'
        } are required to communicate all known issues that can potentially influence the worth or appeal of their property. Our simple step-by-step flow will walk you through each question to help you complete your disclosures easily and accurately.
    
Please reach out if you have any questions. I’m always here to help.
    `
      : emailState.body;
    const subject = !emailState.subject ? `${address} - Start your disclosure!` : emailState.subject;
    const agentSignature = !emailState.signature
      ? generateAgentSignature({
          state: store.property?.state,
          agent: agentUserProfile,
          agentDreLicenseNumber: agentUserProfile?.licenseNumber,
        })
      : emailState.signature;
    emailState.setSubject(subject);
    emailState.setBody(body);
    emailState.setSignature(agentSignature);
    emailState.setRecipients(() => store.sellers.map((s) => s.email));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <CustomEmailForm
      onBack={() => {
        goToStep(SpqTdsDisclosureSteps.sellers);
      }}
      onSend={async () => {
        goToStep(SpqTdsDisclosureSteps.review);
        setCustomEmail(emailState);
      }}
      editRecipient={() => goToStep(SpqTdsDisclosureSteps.sellers)}
      recipientToolTipTitle="Change Seller Contact"
      title="Prepare your email"
      hideCc
      emailState={emailState}
      NavigationProps={{
        isLoading: false,
        PrevButtonProps: {
          disabled: false,
          children: 'Back',
        },
        NextButtonProps: {
          children: 'Next',
          endIcon: null,
          style: { textTransform: 'none', padding: '12px 24px' },
        },
      }}
    />
  );
};

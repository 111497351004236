import { atom } from 'recoil';
import { createSessionStorageEffect } from 'src/state/utils';
import { Value } from 'src/common/ComposeEmail';

type AtomType = {
  recipients: Value[];
  ccRecipients: Value[];
  subject: Value;
  body: Value;
  signature: Value;
} | null;
const storageKey = 'custom-email-state';
const writeToSessionStorageEffect = createSessionStorageEffect<AtomType>(storageKey);

/**
 * This state stores the custom email state used to create a new disclosure package.
 */
export const emailStateAtom = atom<AtomType>({
  key: storageKey,
  default: null,
  effects_UNSTABLE: [writeToSessionStorageEffect],
});

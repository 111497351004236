import React from 'react';
import clsx from 'clsx';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(
  () => ({
    image: {
      maxWidth: '100%',
      height: '50px',
      display: 'block',
      objectFit: 'contain',
      objectPosition: 'center',
    },
    text: {
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
      fontSize: '1.25rem',
    },
  }),
  { classNamePrefix: 'EscrowTitleCompanyLogo' }
);

export interface EscrowTitleCompanyLogoProps extends Omit<React.HtmlHTMLAttributes<HTMLDivElement>, 'color'> {
  brand: string | undefined;
  brandLogo: string | undefined;
}

export const EscrowTitleCompanyLogo: React.FC<EscrowTitleCompanyLogoProps> = (props) => {
  const classes = useStyles();
  const { brand, brandLogo, className, style = {}, ...rest } = props;

  const desiredHeight = 50;

  return brandLogo ? (
    <img src={brandLogo} {...rest} alt={brand} style={style} className={clsx(className, classes.image)} />
  ) : (
    <Typography
      {...rest}
      variant="subtitle2"
      className={clsx(className, classes.text)}
      style={{ ...style, minHeight: desiredHeight }}
    >
      {brand}
    </Typography>
  );
};

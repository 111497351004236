import React from 'react';
import clsx from 'clsx';
import { CircularProgress, Portal, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme, CenteredLoaderProps>(() => ({
  root: {
    isolation: 'isolate',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.25)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  spinner: {
    position: (p) => (p.isFixed ? 'fixed' : 'absolute'),
    top: (p) => `calc(50% - ${p.size! / 2}px)`,
    left: (p) => `calc(50% - ${p.size! / 2}px)`,
  },
}));

export interface CenteredLoaderProps {
  isFixed?: boolean;
  className?: string;
  size?: number;
  showOverlay?: boolean;
}

export const CenteredLoader: React.FC<CenteredLoaderProps> = (props) => {
  const classes = useStyles(props);
  const { className, size, showOverlay } = props;

  return (
    <Portal>
      <div className={clsx(classes.root, { [classes.overlay]: showOverlay })}>
        <CircularProgress size={size} className={clsx(className, classes.spinner)} />
      </div>
    </Portal>
  );
};

CenteredLoader.defaultProps = {
  size: 40,
};

import React from 'react';
import { useSetRecoilState } from 'recoil';
import { WizardStepProps, SelectOnBehalfOfAgentStep } from 'src/domain';
import { selectedAgentAtom } from 'src/state';
import { SpqTdsDisclosureSteps } from './constants';

export const DisclosuresSelectOnBehalfOfAgent = (props: WizardStepProps) => {
  const { goToStep } = props;
  const setSelectedAgent = useSetRecoilState(selectedAgentAtom);

  return (
    <SelectOnBehalfOfAgentStep
      {...props}
      id="disclosure-select-on-behalf-of-agent-step"
      title="Who are you working on behalf of?"
      previousStepName=""
      onNextClick={(selectedTeamMember) => {
        setSelectedAgent(selectedTeamMember);
        goToStep(SpqTdsDisclosureSteps.property);
      }}
      onAddTeamMember={() => goToStep(SpqTdsDisclosureSteps.addTeamMember)}
    />
  );
};

import { AuthType, NRDS_AUTH_PROFILE_TYPES } from './constants';
import { UserSettingsMeta } from './types';

export enum UserMetaRole {
  AGENT = 'agent',
  ASSISTANT = 'assistant',
  AUDITOR = 'auditor',
  BROKER = 'broker',
  TC = 'tc',
}

/*
 * There are two user types we support:
 *
 * - Type 1: Users who are authenticated and create & send packages.
 * - Type 2: Users who are not authenticated and require an authenticated
 *           user to give them access so they can work on their behalf.
 */
export const doesRoleRequireAuth = (userRole: UserMetaRole | undefined) => {
  const carAuthenticatedRoles = [UserMetaRole.AGENT, UserMetaRole.BROKER];
  return userRole && carAuthenticatedRoles.includes(userRole);
};

export const isUserAuthenticatedForType = (userSettingsMeta?: UserSettingsMeta, authType?: AuthType) => {
  if (!userSettingsMeta || !authType) {
    return false;
  }

  if (authType === AuthType.None) {
    return true;
  }

  if (NRDS_AUTH_PROFILE_TYPES[authType]) {
    return userSettingsMeta?.memberInfo?.externalIdAuthorization[authType]?.length > 0;
  }

  return userSettingsMeta?.memberInfo?.authorizedTypes?.includes(authType);
};

import React from 'react';
import { Chip, makeStyles, Theme, Typography } from '@material-ui/core';
import LabelIcon from '@material-ui/icons/Label';
import { PackageInterview, PackageInterviewStatus } from '../types';
import { useIsMobile } from '../../../hooks';

enum BreezeInterviewStatus {
  notStarted = 'NOT STARTED',
  inProgress = 'IN PROGRESS',
  resent = 'RESENT',
  canceled = 'CANCELED',
  expired = 'EXPIRED',
  incomplete = 'INCOMPLETE',
  filledOut = 'FILLED OUT',
  none = 'NONE',
}

const useStyles = makeStyles<Theme, { breezeStatus: BreezeInterviewStatus }>((theme: Theme) => ({
  root: ({ breezeStatus }) => ({
    backgroundColor: theme.colors.grey.offWhite3,
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(3),
    },
    display: breezeStatus === BreezeInterviewStatus.none ? 'none' : undefined,
  }),
  icon: ({ breezeStatus }) => {
    switch (breezeStatus) {
      case BreezeInterviewStatus.notStarted: {
        return {
          color: theme.colors.grey.label,
        };
      }
      case BreezeInterviewStatus.inProgress: {
        return {
          color: theme.colors.mainColor.primary,
        };
      }
      case BreezeInterviewStatus.resent: {
        return {
          color: theme.colors.warn,
        };
      }
      case BreezeInterviewStatus.filledOut: {
        return {
          color: '#47B676',
        };
      }
      case BreezeInterviewStatus.canceled: {
        return {
          color: theme.colors.grey.label,
        };
      }
      case BreezeInterviewStatus.incomplete: {
        return {
          color: '#D20004',
        };
      }
      case BreezeInterviewStatus.expired:
      default: {
        return {
          color: theme.colors.grey.label,
        };
      }
    }
  },
}));

export interface FormInterviewStatusProps {
  interview: PackageInterview;
}

const getStatusText = (interview: PackageInterview): BreezeInterviewStatus => {
  switch (interview.status) {
    case PackageInterviewStatus.CREATED:
    case PackageInterviewStatus.SENT: {
      return BreezeInterviewStatus.notStarted;
    }
    case PackageInterviewStatus.RESENT:
    case PackageInterviewStatus.IN_PROGRESS: {
      if (interview.resendCount > 0) {
        return BreezeInterviewStatus.resent;
      }
      return BreezeInterviewStatus.inProgress;
    }
    case PackageInterviewStatus.REVIEWED:
    case PackageInterviewStatus.SENT_FOR_SIGNATURE: {
      return BreezeInterviewStatus.filledOut;
    }
    case PackageInterviewStatus.REVIEWED_INCOMPLETE: {
      return BreezeInterviewStatus.incomplete;
    }
    case PackageInterviewStatus.CANCELLED: {
      return BreezeInterviewStatus.canceled;
    }
    case PackageInterviewStatus.EXPIRED: {
      return BreezeInterviewStatus.expired;
    }
    case PackageInterviewStatus.SENT_TO_FORMS:
    default: {
      return BreezeInterviewStatus.none;
    }
  }
};

export const FormInterviewStatus: React.FC<FormInterviewStatusProps> = (props) => {
  const breezeStatus = getStatusText(props.interview);
  const classes = useStyles({ breezeStatus });
  const isMobile = useIsMobile();
  return (
    <Chip
      icon={<LabelIcon />}
      label={<Typography variant={isMobile ? 'caption' : 'body1'}>{breezeStatus}</Typography>}
      classes={{
        root: classes.root,
        icon: classes.icon,
      }}
    />
  );
};

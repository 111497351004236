import React, { useEffect, useState } from 'react';
import { InputAdornment, makeStyles } from '@material-ui/core';
import { MailOutline as MailIcon } from '@material-ui/icons';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';
import * as Sentry from '@sentry/react';
import { AccountAction, getAccountAction, emailRegex, useAuthStore } from 'src/lib';
import { Navigation, NavigationProps, Step, TextInput } from 'src/common';
import { newUserInviteAtom } from 'src/state';

const useStyles = makeStyles(() => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  loginText: {
    textAlign: 'center',
    marginBottom: 24,
  },
  input: {
    marginTop: 24,
  },
}));

type Inputs = {
  email: string;
};

export interface EmailProps {
  onSuccess: (accountAction: AccountAction) => void;
  title: string;
  NavigationProps?: NavigationProps;
}

export const Email = (props: EmailProps) => {
  const { title, onSuccess, NavigationProps = {} } = props;

  const classes = useStyles();
  const authStore = useAuthStore();
  const { update: updateAuthStore, userInfo } = authStore;
  const { inviteEmail } = useRecoilValue(newUserInviteAtom) ?? {};

  const { register, handleSubmit, errors, setValue } = useForm({
    mode: 'onTouched',
    defaultValues: { email: authStore.userInfo.email },
  });
  const [isNextClicked, setIsNextClicked] = useState(false);

  const { data: accountAction, isFetching } = useQuery(
    ['account-action', userInfo.email],
    () => getAccountAction(userInfo.email),
    {
      staleTime: 0,
      enabled: isNextClicked,
      onSuccess(accountAction) {
        onSuccess(accountAction);
      },
      onError(e) {
        console.error(e);
        Sentry.captureException(e);
      },
    }
  );

  const onSubmit = async (data: Inputs) => {
    updateAuthStore({ accountAction, userInfo: { ...userInfo, email: data.email } });
    setIsNextClicked(true);
  };

  useEffect(() => {
    if (inviteEmail && !authStore.userInfo.email) {
      setValue('email', inviteEmail, { shouldValidate: true });
    }
  }, [inviteEmail, authStore.userInfo.email, setValue]);

  return (
    <Step title={title}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <TextInput
          type="email"
          label="Email"
          name="email"
          inputRef={register({
            required: 'Please enter this field.',
            pattern: { value: emailRegex, message: 'Please enter a valid email.' },
          })}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MailIcon />
              </InputAdornment>
            ),
          }}
          defaultValue={authStore.userInfo.email}
          error={errors?.email}
          className={classes.input}
          singleField
          autoFocus
        />
        <Navigation
          {...NavigationProps}
          isLoading={isFetching}
          PrevButtonProps={{
            hidden: true,
            ...NavigationProps.PrevButtonProps,
          }}
          NextButtonProps={{
            endIcon: null,
            disabled: Object.keys(errors).length > 0,
            ...NavigationProps.NextButtonProps,
          }}
        />
      </form>
    </Step>
  );
};

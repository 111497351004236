import { GraphQLClient } from 'graphql-request';
import { config } from 'src/lib';

export const getGraphQLHeaders = (token?: string) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const getGraphQLClient = (token?: string): GraphQLClient => {
  const graphQLHeaders = getGraphQLHeaders(token);
  const graphQLUrl = `${config.breezeServiceUrl}/gql`;
  return new GraphQLClient(graphQLUrl, graphQLHeaders);
};

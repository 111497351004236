import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, Typography, ButtonBase } from '@material-ui/core';
import { Group as GroupIcon } from '@material-ui/icons';
import { useIsMedium } from 'src/hooks';
import { Spacer } from 'src/common';
import { DashboardItem } from '../types';
import { DashboardFileActivity } from './DashboardFileActivity';
import { DashboardColLayout } from './DashboardColLayout';

export interface DashboardFileProps {
  item: DashboardItem;
  onClick?: (fileId: number) => void;
  /**
   * This ID allows product to create a chameleon card that points to the
   * first agent name in the list.
   */
  chmlnAgentNameId?: string | undefined;
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      border: `1px solid ${theme.colors.grey.disable}`,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(2),
      backgroundColor: 'white',
      cursor: 'pointer',
      '&:hover': {
        boxShadow:
          '0 9px 12px 1px rgba(0,32,64,0.07), ' +
          '0 3px 16px 2px rgba(0,32,64,0.06), ' +
          '0 5px 6px -3px rgba(0,32,64,0.07)',
      },
      width: '100%',
    },
    text: {
      textAlign: 'left',
    },
    agentName: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
      color: theme.colors.grey.bodyHeader,
    },
  }),
  { classNamePrefix: 'DashboardFile' }
);

const DashboardFile: React.FC<DashboardFileProps> = (props) => {
  const classes = useStyles();
  const { item, onClick, chmlnAgentNameId } = props;
  const { address, representationType, fileId, filePackages } = item;
  const isMediumAndDown = useIsMedium();

  return (
    <DashboardColLayout component={ButtonBase} className={classes.root} onClick={() => onClick?.(fileId)}>
      <DashboardColLayout.LeftColumn>
        <Typography variant="body2" className={clsx(classes.text)} title={typeof address === 'string' ? address : ''}>
          {address}
        </Typography>
        <Spacer axis="vertical" size="4px" />
        <Typography variant="subtitle1" className={clsx(classes.text)}>
          {representationType}
        </Typography>
      </DashboardColLayout.LeftColumn>

      <DashboardColLayout.RightColumn
        id={chmlnAgentNameId}
        endAdornment={<DashboardFileActivity packages={filePackages} />}
      >
        {item.agentFullName ? (
          <div className={classes.agentName}>
            <GroupIcon />
            <Typography variant="body1" className={clsx(classes.text)}>
              {item.agentFullName}
            </Typography>
          </div>
        ) : (
          // This empty div ensures that the property name doesn't overflow
          // into the area reserved for "shared with"
          !isMediumAndDown && <div />
        )}
      </DashboardColLayout.RightColumn>
    </DashboardColLayout>
  );
};

const memoedDashboardFile = React.memo(DashboardFile);
export { memoedDashboardFile as DashboardFile };

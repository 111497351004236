import { gql } from 'graphql-request';
import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';
import { DelegatedUsersDto, getGraphQLClient, getApiToken } from '../../../lib';

export interface RevokeFullAccessDelegatesPayload {
  delegateIds: string[];
}

const mutationQuery = gql`
  mutation revokeFullAccessDelegates($delegateIds: [String]!) {
    revokeFullAccessDelegates(delegateIds: $delegateIds) {
      ownerId
    }
  }
`;

export const revokeFullAccessDelegatesKey = 'revoke-full-access-delegates-mutation';

export const useRevokeFullAccess = (
  options: UseMutationOptions<DelegatedUsersDto, AxiosError, RevokeFullAccessDelegatesPayload> = {}
) =>
  useMutation<DelegatedUsersDto, AxiosError, RevokeFullAccessDelegatesPayload>(
    async (payload: RevokeFullAccessDelegatesPayload) => {
      const client = await getGraphQLClient(await getApiToken());
      return (
        await client.request<{ revokeFullAccessDelegates: DelegatedUsersDto }, RevokeFullAccessDelegatesPayload>(
          mutationQuery,
          payload
        )
      ).revokeFullAccessDelegates;
    },
    {
      mutationKey: revokeFullAccessDelegatesKey,
      ...options,
    }
  );

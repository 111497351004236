import React from 'react';
import { Grid } from '@material-ui/core';
import { useForm, useWatch } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { Navigation, PhoneNumberInput, Step, TextInput } from 'src/common';
import { WizardStepProps } from 'src/domain';
import { emailRegex, EscrowOfficer } from 'src/lib';
import { useAddCustomEscrowOfficer } from 'src/hooks/domain/mutations/index';
import { selectedAgentAtom } from 'src/state';
import { SpqTdsDisclosureSteps } from '../constants';
import { useDisclosuresStore } from '../../store';

export type EscrowAddOfficerProps = WizardStepProps & React.HtmlHTMLAttributes<HTMLDivElement>;

export const EscrowAddOfficerStep: React.FC<EscrowAddOfficerProps> = (props) => {
  const { goToStep, ...rest } = props;
  const store = useDisclosuresStore();
  const selectedAgent = useRecoilValue(selectedAgentAtom);
  const { mutate: addCustomEscrowOfficer } = useAddCustomEscrowOfficer();
  const { register, handleSubmit: collectFormValues, errors, control } = useForm<EscrowOfficer>({ mode: 'onTouched' });
  const escrowOfficer = useWatch<EscrowOfficer>({ control, name: ['firstName', 'lastName', 'email', 'brand'] }) ?? {};

  function handleSubmit(data: EscrowOfficer) {
    addCustomEscrowOfficer(
      {
        ...data,
        fullName: `${data.firstName} ${data.lastName}`,
        ...(selectedAgent ? { onBehalfOf: selectedAgent.userId } : {}),
      },
      {
        onSuccess: (data) => {
          if (data.isExisting) {
            store.update({ escrowOfficer: data.escrowOfficer });
            //updating both the store and location state here, to avoid possible "async" condition
            goToStep(SpqTdsDisclosureSteps.escrowAddExistingOfficer, {
              state: { customEscrowOfficer: data.escrowOfficer },
            });
          } else {
            goToStep(SpqTdsDisclosureSteps.escrowChooseOfficer, { state: { customEscrowOfficer: data.escrowOfficer } });
          }
        },
      }
    );
  }

  return (
    <Step title="Add a new escrow or title company contact">
      <form onSubmit={collectFormValues(handleSubmit)}>
        <Grid container {...rest} spacing={2}>
          <Grid item sm={6} xs={12}>
            <TextInput
              label="First Name"
              autoComplete="off"
              name="firstName"
              inputRef={register({ required: 'Please enter this field' })}
              error={errors.firstName}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextInput
              label="Last Name"
              autoComplete="off"
              name="lastName"
              inputRef={register({ required: 'Please enter this field' })}
              error={errors.lastName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              label="Escrow or Title Company"
              autoComplete="off"
              name="brand"
              inputRef={register({ required: 'Please enter this field' })}
              error={errors.brand}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextInput
              label="Email"
              type="email"
              autoComplete="off"
              name="email"
              inputRef={register({
                required: 'Please enter this field',
                pattern: { value: emailRegex, message: 'Please enter a valid email' },
              })}
              error={errors.email}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <PhoneNumberInput required name="phoneNumber" errors={errors} autoComplete="off" control={control} />
          </Grid>
        </Grid>

        <Navigation
          PrevButtonProps={{
            children: 'Cancel',
            onClick: () => goToStep(SpqTdsDisclosureSteps.escrowChooseOfficer),
            startIcon: null,
          }}
          NextButtonProps={{
            children: 'Add Contact',
            endIcon: null,
            disabled: Object.keys(errors).length > 0 || Object.values(escrowOfficer).some((e) => !e),
          }}
        />
      </form>
    </Step>
  );
};

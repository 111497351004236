import React, { useEffect, useMemo } from 'react';
import { Grid, makeStyles, Theme, Link, Typography } from '@material-ui/core';
import { OpenInNew as OpenInNewIcon } from '@material-ui/icons';
import { UseFormMethods } from 'react-hook-form';
import { useGetUserProfile } from 'src/hooks';
import { TextInput } from 'src/common';
import { AuthType, NRDS_AUTH_PROFILE_TYPES } from 'src/lib';

const useStyles = makeStyles(
  (theme: Theme) => ({
    icon: {
      fontSize: `${17 / 16}rem`,
      marginRight: theme.spacing(0.5),
    },
    link: {
      display: 'inline-flex',
      alignItems: 'center',
      color: theme.colors.mainColor.primary,
      fontWeight: 'bold',
    },
  }),
  { classNamePrefix: 'CredentialInputs' }
);
export interface CredFormInputs {
  lastName: string;
  dreLicenseNumber: string;
  Id: string;
}

export interface CredentialInputsProps {
  register: UseFormMethods['register'];
  errors: UseFormMethods['errors'];
  reset: UseFormMethods['reset'];
  authType: AuthType;
  heading?: React.ReactNode;
  fullWidth?: boolean;
}

const getInputs = (authType: AuthType) => {
  switch (authType) {
    case AuthType.Oref:
      return {
        fields: ['accessId'],
        lookup: {
          href: 'https://orea.elicense.micropact.com/Lookup/LicenseLookup.aspx',
          label: 'Oregon Real Estate License # Lookup',
        },
      };
    case AuthType.NWMLS:
      return {
        fields: ['lagNumber'],
        lookup: {
          href: 'https://professions.dol.wa.gov/s/license-lookup',
          label: 'DOL Lookup',
        },
      };
    default:
      if (authType === AuthType.CaNrds || NRDS_AUTH_PROFILE_TYPES[authType]) {
        return {
          fields: ['lastName', 'nrdsId'],
          lookup: {
            href: 'https://login.connect.realtor/#!/forgotmember',
            label: 'NAR Lookup',
          },
        };
      }
      return;
  }
};

/**
 * Inputs for each of the  Credential fields, intended to be rendered inside
 * of a form that is controlled via `useForm()`
 */
export const CredentialInputs = (props: CredentialInputsProps) => {
  const classes = useStyles();
  const { register, errors, reset, authType, heading } = props;
  const { data: userProfile } = useGetUserProfile();
  const authProfile = useMemo(
    () => userProfile?.authProfiles?.find((profile) => profile.type === authType),
    [userProfile, authType]
  );

  useEffect(() => {
    reset({
      lastName: authProfile?.profile.lastName,
      nrdsId: authProfile?.profile.nrdsId,
      accessId: authProfile?.profile.accessId,
      lagNumber: authProfile?.profile.lagNumber,
      source: authType,
    });
  }, [reset, authType, authProfile]);

  const inputs = getInputs(authType);

  if (!inputs) {
    return (
      <Grid container spacing={3}>
        {heading}
        <Grid item xs={12}>
          <Typography variant="h3">Sorry, {authType} is not yet supported in Breeze.</Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={3}>
      {heading}
      <input type="hidden" ref={register} name="source" />
      {inputs.fields.includes('lastName') && (
        <Grid item xs={12} sm={props?.fullWidth ? 12 : 6}>
          <TextInput
            label="Last name on NAR records"
            inputRef={register({
              required: 'Please enter this field.',
            })}
            error={errors.lastName}
            name="lastName"
          />
        </Grid>
      )}
      {/*
       * This `overflow: hidden` is necessary here because the MUI text field has an
       * inner element that displays the label to screen reader users that is
       * taking up vertical space and causing an overflow
       */}
      {inputs.fields.includes('nrdsId') && (
        <Grid item xs={12} sm={props?.fullWidth ? 12 : 6} style={{ overflow: 'hidden' }}>
          <TextInput
            label="9-digit NRDS ID"
            inputRef={register({
              required: 'Please enter this field.',
              pattern: { value: /^[0-9]*$/, message: 'Please enter only numerical digits (0-9)' },
              minLength: { value: 9, message: ' ID must be exactly 9 digits' },
              maxLength: { value: 9, message: ' ID must be exactly 9 digits' },
            })}
            error={errors.nrdsId}
            name="nrdsId"
          />
        </Grid>
      )}
      {inputs.fields.includes('accessId') && (
        <Grid item xs={12} style={{ overflow: 'hidden' }}>
          <TextInput
            label="Oregon Real Estate License Number"
            inputRef={register({
              required: 'Please enter this field.',
            })}
            error={errors.accessId}
            name="accessId"
          />
        </Grid>
      )}
      {inputs.fields.includes('lagNumber') && (
        <Grid item xs={12} style={{ overflow: 'hidden' }}>
          <TextInput
            label="Northwest MLS LAG Number"
            inputRef={register({
              required: 'Please enter this field.',
            })}
            error={errors.lagNumber}
            name="lagNumber"
          />
        </Grid>
      )}
      {inputs.lookup && (
        <Grid item xs={12} style={{ marginTop: -8 }}>
          <Link className={classes.link} href={inputs.lookup.href} target="_blank" rel="noreferrer">
            <OpenInNewIcon className={classes.icon} /> <span>{inputs.lookup.label}</span>
          </Link>
        </Grid>
      )}
    </Grid>
  );
};

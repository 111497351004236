import { AxiosError } from 'axios';
import { gql } from 'graphql-request';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import * as Sentry from '@sentry/react';
import { DelegatedUsersDto, getGraphQLClient, getApiToken } from '../../lib';
import { UserPermissionRequestData, useGetUserPermissionsRequestsKey } from './useGetUserPermissionRequests';

export interface RevokeInvitePayload {
  delegateIds: string[];
}

export interface RevokeInviteResult {
  ownerId: string;
  fullAccessDelegateRequests: DelegatedUsersDto[];
  createdAt: string;
  updatedAt: string;
}

const mutationQuery = gql`
  mutation revokeFullAccessDelegates($delegateIds: [String]!) {
    revokeFullAccessDelegates(delegateIds: $delegateIds) {
      ownerId
    }
  }
`;

interface MutationContext {
  previousInvites: UserPermissionRequestData;
  revokedInvite: RevokeInvitePayload;
}

export const revokeInviteAccessKey = 'revoke-invite-mutation';

/**
 * This hook allows an agent to deny a request from a TC, AND to cancel a request
 * that the agent sent to the TC.  This hook is only used by Agents, never by TCs.
 */
export const useRevokeInvite = (
  options: UseMutationOptions<RevokeInviteResult, AxiosError, RevokeInvitePayload> = {}
) => {
  const queryClient = useQueryClient();

  return useMutation<RevokeInviteResult, AxiosError, RevokeInvitePayload>(
    async (payload: RevokeInvitePayload) => {
      const client = getGraphQLClient(await getApiToken());
      return (await client.request<{ res: RevokeInviteResult }, RevokeInvitePayload>(mutationQuery, payload)).res;
    },
    {
      mutationKey: revokeInviteAccessKey,
      async onMutate(payload: RevokeInvitePayload): Promise<MutationContext> {
        await queryClient.cancelQueries(useGetUserPermissionsRequestsKey);

        const previousInvites: UserPermissionRequestData = queryClient.getQueryData(useGetUserPermissionsRequestsKey)!;

        queryClient.setQueryData(useGetUserPermissionsRequestsKey, (old: UserPermissionRequestData) => {
          const filteredRequests = old.delegatedToUserRequests.filter(
            (invitedTc) => !payload.delegateIds.includes(invitedTc.delegateId)
          );

          return {
            ...old,
            delegatedToUserRequests: filteredRequests,
          };
        });

        return { previousInvites, revokedInvite: payload };
      },
      onError(e, variables, context: MutationContext) {
        console.error('An error occurred while revoking invite');
        Sentry.captureException(e);
        queryClient.setQueryData(useGetUserPermissionsRequestsKey, () => context.previousInvites);
        options?.onError?.(e, variables, context);
      },
      onSettled() {
        queryClient.invalidateQueries(useGetUserPermissionsRequestsKey);
      },
      ...options,
    }
  );
};

import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';
import * as Sentry from '@sentry/react';
import { useCustomSnackbar } from 'src/hooks/useCustomSnackbar';
import { getApiToken, config, Contact, ErrorCodes } from 'src/lib';
import { selectedAgentAtom } from 'src/state';
import { getFilesByIdQueryKey } from '../queries';

type CreateContactsPayload = {
  fileId: number;
  contacts: Contact[];
};

export const useCreateContacts = () => {
  const queryClient = useQueryClient();
  const selectedAgent = useRecoilValue(selectedAgentAtom);
  const { addErrorSnackbar } = useCustomSnackbar();

  return useMutation(
    async (payload: CreateContactsPayload) => {
      const token = await getApiToken();

      return await Promise.all(
        payload.contacts.map(async (contact) =>
          axios({
            method: 'POST',
            url: `${config.formsApiUrl}/api/files/${payload.fileId}/contacts`,
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            data: {
              ...contact,
              onBehalfOf: selectedAgent?.userId,
            },
          })
        )
      );
    },
    {
      onSuccess(result, variables) {
        queryClient.invalidateQueries([getFilesByIdQueryKey, variables.fileId]);
      },
      onError(e) {
        console.error(e);
        const eventId = Sentry.captureException(e);
        addErrorSnackbar({ errorCode: ErrorCodes.CreateContacts, eventId });
      },
    }
  );
};

import React from 'react';
import clsx from 'clsx';
import { Theme, makeStyles, Typography } from '@material-ui/core';
import { Mail, Phone } from '@material-ui/icons';
import { EscrowOfficer } from 'src/lib';
import { InitialsCircle, RevealButton } from 'src/common';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      border: `1px solid ${theme.colors.grey.disable}`,
      borderRadius: theme.spacing(1),
      padding: theme.spacing(4, 3),
      display: 'flex',
      gap: theme.spacing(2),

      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    image: {
      flexShrink: 0,
    },
    title: {
      fontWeight: 400,
    },
    subtitle: {
      fontSize: '1rem',
      [theme.breakpoints.up('md')]: {
        maxWidth: '65%',
      },
    },
    side: {
      display: 'flex',
      gap: 'inherit',
    },
    sideRight: {
      marginLeft: 'auto',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginLeft: 0,
      },
    },
  }),
  { classNamePrefix: 'EscrowIsPreOpenCard' }
);

export interface EscrowIsPreOpenCardProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  escrowOfficer: EscrowOfficer;
}

/**
 * A card on the file packages page telling the user that escrow has been
 * pre-opened for this file with this agent.
 */
export const EscrowIsPreOpenCard: React.FC<EscrowIsPreOpenCardProps> = (props) => {
  const classes = useStyles();
  const { className, escrowOfficer, ...rest } = props;

  return (
    <div {...rest} className={clsx(className, classes.root)}>
      <div className={classes.side}>
        <InitialsCircle
          className={classes.image}
          firstName={escrowOfficer.firstName}
          lastName={escrowOfficer.lastName}
          image={escrowOfficer.image}
        />
        <div>
          <Typography variant="body2" className={classes.title}>
            You opened an order with {escrowOfficer.fullName}
          </Typography>
          <Typography variant="subtitle2" className={classes.subtitle}>
            {escrowOfficer.brand}
          </Typography>
        </div>
      </div>
      <div className={clsx(classes.side, classes.sideRight)}>
        {escrowOfficer?.email && (
          <RevealButton
            icon={<Mail />}
            href={`mailto:${escrowOfficer.email}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {escrowOfficer?.email}
          </RevealButton>
        )}
        {escrowOfficer?.phoneNumber && (
          <RevealButton icon={<Phone />} href={`tel:${escrowOfficer.phoneNumber}`}>
            {escrowOfficer?.phoneNumber}
          </RevealButton>
        )}
      </div>
    </div>
  );
};

import React, { useMemo } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, Chip, ChipProps } from '@material-ui/core';
import { Description as DescriptionIcon, Home as HomeIcon, Group as GroupIcon } from '@material-ui/icons';
import { PackageTypes } from 'src/lib';
import { useTeamMembers } from 'src/hooks';
import { DashboardFilter } from './useDashboardFileFilters';
import { DashboardFilterTypes } from '.';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      backgroundColor: theme.colors.grey.offWhite3,
      borderColor: theme.colors.grey.grey1,
      color: theme.colors.grey.bodyHeader,
      borderRadius: 999,
      padding: `20px 11px`,
    },
    label: {
      fontFamily: theme.typography.fontFamily,
      fontSize: '1rem',
      fontWeight: 700,
      textTransform: 'none',
    },
  }),
  { classNamePrefix: 'DashboardFilterChip' }
);

export interface DashboardFilterChipProps extends Omit<ChipProps, 'label' | 'icon'> {
  filter: DashboardFilter;
}

export const DashboardFilterChip: React.FC<DashboardFilterChipProps> = (props) => {
  const classes = useStyles();
  const { className, filter, ...rest } = props;
  const { data: teamMembers } = useTeamMembers();

  const teamMemberIdToFullName = useMemo(
    () =>
      teamMembers?.reduce((accum, teamMember) => {
        accum[teamMember.userId] = `${teamMember.firstName} ${teamMember.lastName}`;
        return accum;
      }, {}),
    [teamMembers]
  );

  return (
    <Chip
      {...rest}
      {...filterToChipProps(filter, teamMemberIdToFullName)}
      className={clsx(className, classes.root)}
      variant="outlined"
      classes={{ label: classes.label }}
    />
  );
};

function filterToChipProps(
  filter: DashboardFilter,
  teamMemberIdToFullName: Record<string, string>
): Partial<ChipProps> {
  const [type, value] = filter;

  const icon =
    type === DashboardFilterTypes.Representation ? (
      <HomeIcon />
    ) : type === DashboardFilterTypes.SharedWithTeamMember ? (
      <GroupIcon />
    ) : (
      <DescriptionIcon />
    );

  const label =
    type === DashboardFilterTypes.PackageType
      ? value === PackageTypes.avid
        ? 'AVID'
        : 'Disclosures'
      : type === DashboardFilterTypes.SharedWithTeamMember
      ? teamMemberIdToFullName[value]
      : value;

  return { icon, label };
}

import { useSelectRegion } from 'src/hooks';
import { usStates } from 'src/lib';

/**
 * A hook to determine if the current user has Avid available to them
 * requires 'CA' region in profile
 */
export const useIsAvidAvailable = () => {
  const { regions: regions } = useSelectRegion();
  return {
    data: regions?.some((userRegion) => userRegion === usStates.CA),
  };
};

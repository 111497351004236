import React, { useEffect, useMemo } from 'react';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useFileId, useGetFileById, useGetFilePackages } from 'src/hooks';
import { Wizard, StepOptions } from 'src/domain';
import { ResendDisclosuresSteps } from 'src/pages/ResendDisclosures/steps/constants';
import { ResendDisclosuresStoreProvider, useCreateResendDisclosuresStore } from 'src/pages/ResendDisclosures/store';
import { FilePackage, PackageForm, PackageStatus } from 'src/pages/FilePackages/types';
import { defaultWizardRoutes, PackageTypes } from 'src/lib';
import { SelectDisclosuresStep, CustomEmailStep } from './steps';

export const resendDisclosuresSteps: StepOptions[] = [
  {
    key: ResendDisclosuresSteps.selectDisclosures,
    component: SelectDisclosuresStep,
  },
  {
    key: ResendDisclosuresSteps.customEmail,
    component: CustomEmailStep,
  },
];

export const ResendDisclosuresWizard: React.FC = () => {
  const fileId = useFileId(); // get fileId from URL
  const { packageId } = useParams<{ packageId: string }>() || ''; // get packageId from URL
  const { data: file } = useGetFileById(fileId, { staleTime: 5 * 60000 });

  // Get the packages for the file to populate the store
  const { data: packages } = useGetFilePackages<Partial<FilePackage>[]>(fileId, {
    select: (results) =>
      results.map((result) => ({
        ...result,
        lastUpdated: format(Date.parse(result.lastUpdated), 'M/d/yyyy h:mm a'),
      })),
    staleTime: 5 * 60000,
  });

  // Get info needed for the store from the packages
  let packageStatus: PackageStatus | undefined = undefined;
  let forms: PackageForm[] = useMemo(() => [], []);
  // convert packages (Partial<FilePackage[WorkFlowData]>) to PackageForm[] to get all the forms
  packages?.forEach((p) => {
    if (p && p.type === PackageTypes.disclosures && p.id === packageId && p.forms) {
      forms = forms.concat(p?.forms);
      packageStatus = p.status;
    }
  });

  const store = useCreateResendDisclosuresStore({
    fileId: isNaN(fileId) ? undefined : fileId,
    packageId,
    forms: forms,
    selectedInterviewIds: forms.map((f) => f.interviewId), // start with all the forms/interviews selected
    sellers: file?.fileData?.sellers,
    property: file?.fileData?.premisesAddress,
    packageStatus: packageStatus,
  });

  useEffect(() => {
    if (store.forms.length != forms.length) {
      store.update({
        forms,
        selectedInterviewIds: forms.map((f) => f.interviewId),
        packageStatus,
        sellers: file?.fileData?.sellers,
        property: file?.fileData?.premisesAddress,
      });
    }
  }, [forms, store, packageStatus, file]);

  // determine our wizard baseUrl/prefix to include the fileId and packageId!
  const wizardPrefix: string = defaultWizardRoutes.resendDisclosures
    .replace(':fileId', String(fileId))
    .replace(':packageId', packageId)
    .slice(1);

  return (
    <ResendDisclosuresStoreProvider value={store}>
      <Wizard
        steps={resendDisclosuresSteps}
        defaultStep={ResendDisclosuresSteps.selectDisclosures}
        prefix={wizardPrefix}
      />
    </ResendDisclosuresStoreProvider>
  );
};

import { alpha, Box, Container, Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import React from 'react';
import ReactPlayer from 'react-player';
import { PlayArrow } from '@material-ui/icons';
import agentThumbnail from 'src/images/agentThumbnail.png';
import clientThumbnail from 'src/images/clientThumbnail.jpg';

const useStyles = makeStyles((theme: Theme) => ({
  marketingContainer: {
    backgroundColor: theme.colors.grey[100],
    padding: theme.spacing(15, 0, 10, 0),
  },
  textWrapper: {
    padding: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5, 3),
      textAlign: 'center',
    },
  },
  mainText: {
    fontSize: theme.typography.pxToRem(56),
    color: theme.colors.grey.bodyHeader,
    fontFamily: 'inherit',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(48),
      lineHeight: `${theme.spacing(6)}px`,
      textAlign: 'left',
    },
  },
  playIcon: {
    fontSize: theme.spacing(5),
    borderRadius: '50%',
    padding: theme.spacing(3),
    backgroundColor: theme.colors.mainColor.primary,
    fill: '#fff',
  },
  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%' /* 720 / 1280 = 0.5625 */,
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  videoCard: {
    padding: theme.spacing(2),
    backgroundColor: '#fff',
    borderRadius: theme.spacing(1),
    boxShadow: `0 2px 2px 0 ${alpha(theme.colors.grey.offBlack, 0.07)}, 0 3px 1px -2px ${alpha(
      theme.colors.grey.offBlack,
      0.06
    )}, 0 1px 5px 0 ${alpha(theme.colors.grey.offBlack, 0.07)}`,
  },
}));

export const LandingVideoMarketing: React.FC = () => {
  const classes = useStyles();

  const videoBox = (url: string, thumbnail: string, header: string, body: string) => (
    <Grid item md={6}>
      <Grid container alignContent="flex-start" spacing={5}>
        <Grid item xs={12}>
          <Paper className={classes.videoCard}>
            <Box className={classes.playerWrapper}>
              <ReactPlayer
                className={classes.reactPlayer}
                width="100%"
                height="100%"
                url={url}
                playing
                controls
                light={thumbnail}
                playIcon={<PlayArrow className={classes.playIcon} />}
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Box mb={2}>
            <Typography variant="h5">{header}</Typography>
          </Box>
          <Typography variant="body1">{body}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Grid container className={classes.marketingContainer}>
      <Container maxWidth="lg">
        <Grid container spacing={6}>
          <Grid item xs={12} className={classes.textWrapper}>
            <Box>
              <Typography className={classes.mainText} align="center" variant="h2">
                Sit back and let the Breeze digital questionnaire guide your clients through disclosures.
              </Typography>
            </Box>
          </Grid>
          {videoBox(
            'https://vimeo.com/576973761/fa7d9e6be8',
            agentThumbnail,
            'For Agents',
            'Breeze transforms seller disclosures into a guided, digital questionnaire that agents can send their clients in minutes. The tool keeps everything organized until the disclosure is complete.'
          )}
          {videoBox(
            'https://vimeo.com/561103518/0c64cbbf7a',
            clientThumbnail,
            'For Clients',
            'Guided workflows, status updates, and automated emails help home sellers complete complicated disclosure forms with minimal effort.'
          )}
        </Grid>
      </Container>
    </Grid>
  );
};

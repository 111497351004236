import React from 'react';
import { Container, makeStyles } from '@material-ui/core';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Navigation, Step, RadioOptions } from 'src/common';
import { WizardStepProps } from 'src/domain';
import { useTayTip } from 'src/domain/wizard/useTayTip';
import { useTdsSectionThreeMutation } from 'src/hooks';
import { TdsSect3CheckboxMapping } from 'src/lib';
import { useDisclosuresStore } from '../store';
import { SpqTdsDisclosureSteps } from './constants';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 300,
  },
  form: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

interface UseExistingAvidInputs {
  selectedItem: 'yes' | 'no';
}

export const UseExistingAvid = ({ goToStep }: WizardStepProps) => {
  const store = useDisclosuresStore();
  const isFromDownload = !!store.downloadType;
  const history = useHistory();
  const { mutate: updateTdsSectionThree, isLoading: isUpdating } = useTdsSectionThreeMutation({
    fileId: store.existingFileId,
    packageId: store.existingPackageId,
  });
  const classes = useStyles();
  const { control, handleSubmit } = useForm<UseExistingAvidInputs>({
    mode: 'onTouched',
  });

  const selectedItem = useWatch({ control, name: 'selectedItem' });

  useTayTip(
    'This completes section 3 of the Transfer Disclosure Statement (TDS) ' +
      'or Manufactured Home Transfer Disclosure Statement (MHTDS).'
  );

  const onSubmit = handleSubmit((data: UseExistingAvidInputs) => {
    if (data.selectedItem === 'yes') {
      updateTdsSectionThree(
        {
          packageId: store.existingPackageId,
          fileId: store.existingFileId,
          checkboxMappingName: TdsSect3CheckboxMapping.ATTACH_AVID,
          text: '',
        },
        {
          onSuccess: () => {
            if (isFromDownload) {
              const downloadType = store.downloadType;
              store.update({ downloadType: '' });
              history.push(`/file/${store.existingFileId}/packages?downloadType=${downloadType}`);
            } else {
              goToStep(SpqTdsDisclosureSteps.requestSignaturesEmail);
            }
          },
        }
      );
    } else {
      goToStep(SpqTdsDisclosureSteps.tdsSection3);
    }
  });

  return (
    <Step title="Looks like you have an AVID in this file, is this how you plan to complete your visual inspection?">
      <Container maxWidth={false} className={classes.root} disableGutters>
        <form onSubmit={onSubmit} className={classes.form}>
          <Controller
            name="selectedItem"
            control={control}
            rules={{ required: true }}
            render={({ onChange, value }) => (
              <RadioOptions
                onChange={onChange}
                name="use-avid"
                options={[
                  {
                    checked: value === 'yes',
                    value: 'yes',
                    label: 'Yes',
                  },
                  {
                    checked: value === 'no',
                    value: 'no',
                    label: 'No',
                  },
                ]}
              />
            )}
          />
        </form>
      </Container>
      <Navigation
        isLoading={isUpdating}
        NextButtonProps={{ onClick: onSubmit, disabled: selectedItem == null }}
        PrevButtonProps={{ onClick: () => history.go(-2), children: 'Cancel' }}
      />
    </Step>
  );
};

import { useState, useMemo, useReducer } from 'react';
import { useRecoilValue } from 'recoil';
import { PackageTypes, RepresentationType } from 'src/lib';
import { AgentProfile, selectedAgentAtom } from 'src/state';
import { DashboardItem } from '../../types';

export enum DashboardFilterTypes {
  PackageType = 'form-type',
  Representation = 'representation',
  SharedWithTeamMember = 'shared-with-team-member',
}

export type DashboardFilter =
  | [DashboardFilterTypes.PackageType, PackageTypes]
  | [DashboardFilterTypes.Representation, RepresentationType]
  | [DashboardFilterTypes.SharedWithTeamMember, string];

type FiltersByType = {
  [DashboardFilterTypes.PackageType]: PackageTypes[];
  [DashboardFilterTypes.Representation]: RepresentationType[];
  [DashboardFilterTypes.SharedWithTeamMember]: string[];
};

export type FilterProps = ReturnType<typeof useDashboardFileFilters>;

export const useDashboardFileFilters = (items: DashboardItem[] | undefined) => {
  const [filters, setFilters] = useState<DashboardFilter[]>([]);
  const [search, setSearch] = useState('');
  const selectedAgent = useRecoilValue(selectedAgentAtom);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  function indexOfFilter(filterToFind: DashboardFilter) {
    for (let i = 0; i < filters.length; ++i) {
      const existingFilter = filters[i];
      if (existingFilter[0] === filterToFind[0] && existingFilter[1] === filterToFind[1]) return i;
    }
    return -1;
  }

  /** Add a new filter */
  function addFilter(newFilter: DashboardFilter) {
    const filterAlreadyExists = indexOfFilter(newFilter) >= 0;
    if (filterAlreadyExists) return;
    setFilters((existingFilters) => [...existingFilters, newFilter]);
  }

  /**
   * Remove one or multiple of the current filters.
   * Passing a filter type instead of a full filter will remove all filters of that type.
   */
  function removeFilter(deletedFilter: DashboardFilter | DashboardFilterTypes) {
    if (Array.isArray(deletedFilter)) {
      const index = indexOfFilter(deletedFilter);
      const filterDoesNotExist = index < 0;
      if (filterDoesNotExist) return;

      setFilters((existingFilters) => {
        const copy = [...existingFilters];
        copy.splice(index, 1);
        return copy;
      });
    } else {
      setFilters(filters.filter((existingFilter) => existingFilter[0] !== deletedFilter));
    }

    forceUpdate();
  }

  const filteredItems = useMemo(
    () => items?.filter((item) => itemMatchesFilters({ item, filters, search, selectedAgent })),
    [items, filters, search, selectedAgent]
  );

  return {
    filters,
    addFilter,
    removeFilter,
    filteredItems,
    search,
    setSearch,
  };
};

type ItemMatchesFilterArgs = {
  item: DashboardItem;
  filters: DashboardFilter[];
  search: string;
  selectedAgent: AgentProfile | null;
};

/**
 * Determine if a particular Dashboard Item matches ALL of the passed filters
 */
function itemMatchesFilters({ item, filters, search, selectedAgent }: ItemMatchesFilterArgs): boolean {
  if (!item) return true;

  const filtersByType: FiltersByType = filters.reduce((accum, curr) => {
    const type = curr[0];
    const data = curr[1];

    if (!accum[type]) accum[type] = [];

    accum[type].push(data as never);
    return accum;
  }, {} as FiltersByType);

  const _packageTypesForItem =
    item.filePackages
      ?.map((thePackage) => thePackage.type)
      .reduce((set, packageType) => {
        set.add(packageType);
        return set;
      }, new Set<string>()) ?? [];
  const packageTypesForItem = Array.from(_packageTypesForItem);

  const packageTypeFilters = filtersByType[DashboardFilterTypes.PackageType];
  const matchesAllPackageTypes =
    !packageTypeFilters || packageTypeFilters.every((packageType) => packageTypesForItem?.includes(packageType));

  const repTypeFilters = filtersByType[DashboardFilterTypes.Representation];
  const matchesRepType = !repTypeFilters || repTypeFilters.includes(item.representationType!);

  const sharedWithFilters = filtersByType[DashboardFilterTypes.SharedWithTeamMember];
  const matchesSelectedAgent = !selectedAgent || item.ownedBy === selectedAgent?.userId;
  const matchesSharedWith = !sharedWithFilters || sharedWithFilters.includes(item.ownedBy!);

  const address = item.address?.toLowerCase();
  const name = item.agentFullName?.toLowerCase();
  const matchesSearch = name?.includes(search.toLowerCase()) || address?.includes(search.toLowerCase());

  return matchesAllPackageTypes && matchesRepType && matchesSharedWith && matchesSelectedAgent && matchesSearch;
}

import { useQuery, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import { gql } from 'graphql-request';
import { useRecoilValue } from 'recoil';
import { selectedAgentAtom } from 'src/state';
import { getGraphQLClient, getApiToken } from '../../../lib';
import { FilePackage } from '../../../pages/FilePackages/types';

interface QueryResult {
  packages: FilePackage[];
}

interface QueryVariables {
  fileId?: number;
  onBehalfOf?: string;
}

export const getPackagesByFileIdKey = 'get-packages-by-file-id';

const query = gql`
  query packagesForFile($fileId: Int, $onBehalfOf: String) {
    packages(fileId: $fileId, onBehalfOf: $onBehalfOf) {
      id
      name
      lastUpdated
      fileId
      type
      status {
        status
        progress
        type
        actions {
          sklosuresResend
          sklosuresCancel
          formsSend
          ds3Send
          ds3Resend
          ds3Cancel
          packageEdit
          sendForms
        }
      }
      forms {
        name
        interviewId
        ds3DocumentId
        formsDocumentId
        associationName
      }
      interviews {
        id
        resendCount
        status
        signers {
          id
          flags {
            id
            answer
            questionText
            questionSection
            status
            updatedIdentityId
            updatedAt
            createdAt
            messages {
              id
              message
              createdIdentityId
              createdAt
              firstName
              lastName
            }
          }
        }
        packageConfig {
          signerQuestionFlaggingEnabled
          signerResolveFlagEnabled
        }
      }
      envelope {
        envelopeId
        userEmail
        signers {
          signerId
          email
          signingStatus
        }
        documents {
          url
          id: documentId
        }
      }
      workflowData {
        ... on DisclosuresWorkflowData {
          isAvidFlowCompleted
          isAttachingAvid
          apn
          escrowOfficer {
            firstName
            lastName
            email
            brand
            fullName
            phoneNumber
            image
          }
          sellers {
            email
          }
        }
        ... on AvidWorkflowData {
          escrowOfficer {
            firstName
            lastName
            email
            brand
            fullName
            phoneNumber
            image
          }
        }
      }
    }
  }
`;

export const useGetFilePackages = <T = FilePackage[]>(
  fileId: number | undefined = undefined,
  options: UseQueryOptions<FilePackage[], AxiosError, T> = {}
) => {
  const selectedAgent = useRecoilValue(selectedAgentAtom);
  const onBehalfOf = selectedAgent?.userId;

  return useQuery<FilePackage[], AxiosError, T>(
    [getPackagesByFileIdKey, fileId, onBehalfOf],
    async () => {
      const client = getGraphQLClient(await getApiToken());
      const result = await client.request<QueryResult, QueryVariables>(query, {
        fileId,
        onBehalfOf,
      });
      return result?.packages || [];
    },
    {
      staleTime: 0,
      ...options,
    }
  );
};

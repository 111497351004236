/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { InfoBlock } from 'src/common';
import { CredentialInputs } from 'src/domain';
import { useVerifyAndSaveCreds, VerifyCredsRequest } from 'src/hooks';
import { AuthType } from 'src/lib';
import { WizardDialogStepProps } from 'src/domain/wizard/WizardDialog';
import { CredsFormProps } from './Interfaces/CredsFormProps';
import { DialogFormProps } from './Interfaces/DialogFormProps';
import { SectionHeading } from './SectionHeading';

const useStyles = makeStyles<Theme>(
  (theme: Theme) => ({
    root: {
      display: 'block',
    },
    infoBlock: {
      marginBottom: theme.spacing(4),
    },
    error: {
      color: 'red',
      marginTop: 24,
    },
  }),
  { classNamePrefix: 'EnterCarCredsDialog' }
);

/**
 * A dialog to prompt the user to enter their Car credentials
 */
export const EnterCarCredsForm = (props: DialogFormProps & Partial<CredsFormProps & WizardDialogStepProps>) => {
  const classes = useStyles(props);
  const formRef = useRef<HTMLFormElement>(null);

  const onVerifiedCreds = () => {
    if (props.onValidCreds) {
      props.onValidCreds();
    } else if (props.onNext) {
      props.onNext();
    }
  };

  const {
    mutate: verifyAndSaveCarCreds,
    isLoading: isSavingCarCreds,
    error,
  } = useVerifyAndSaveCreds({ onSuccess: onVerifiedCreds });

  useEffect(() => {
    if (isSavingCarCreds) {
      props.setLoading(true);
    } else {
      props.setLoading(false);
    }
  }, [isSavingCarCreds]);

  const { register, errors, reset, handleSubmit } = useForm<VerifyCredsRequest>({
    mode: 'onTouched',
  });

  const submit = (request: VerifyCredsRequest) => {
    try {
      const saveCarPromise = verifyAndSaveCarCreds({
        authType: AuthType.CaNrds,
        request,
      });

      saveCarPromise;
    } catch (e) {
      throw 'Sorry, something went wrong on our end. Please try again or contact us.';
    }
  };

  useEffect(() => {
    if (props.submitEvent.readyToSubmit) {
      formRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }, [props.submitEvent]);

  return (
    <form ref={formRef} onSubmit={handleSubmit(submit)}>
      <InfoBlock className={classes.infoBlock}>
        C.A.R. credentials are required before inviting collaborators. Don’t worry, you’ll only need to do this once!
      </InfoBlock>

      <CredentialInputs
        authType={AuthType.CaNrds}
        register={register}
        errors={errors}
        heading={<SectionHeading>Authorization Required</SectionHeading>}
        reset={reset}
        fullWidth
      />

      {error && <Typography className={classes.error}>{error}</Typography>}
    </form>
  );
};

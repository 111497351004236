import { createAxiosInstance } from 'src/lib/auth/createAxiosInstance';
import { config } from 'src/lib/constants';
import { getApiToken } from 'src/lib/auth/okta';

export const filesApiClient = createAxiosInstance({
  baseUrl: `${config.formsApiUrl}/api`,
  getAsyncHeaders: async () => {
    const token = await getApiToken();
    return {
      Authorization: `Bearer ${token}`,
    };
  },
  defaultHeaders: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

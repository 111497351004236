import React from 'react';
import { makeStyles, Theme, Button } from '@material-ui/core';

export interface LinkButtonProps {
  id: string;
  onClick: () => void;
  text: string;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  linkButton: {
    fontSize: '16px',
    color: theme.palette.primary.main,
    '&:hover, &:active, &:visited': {
      backgroundColor: 'unset',
    },
  },
}));

export const LinkButton = (props: LinkButtonProps) => {
  const classes = useStyles();
  const className = props.className ? `${classes.linkButton} ${props.className}` : classes.linkButton;
  return (
    <Button id={props.id} className={className} onClick={() => props.onClick()}>
      {props.text}
    </Button>
  );
};

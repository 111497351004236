import React, { useEffect, useState, useContext } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { ChatBubbleOutlineSharp as ChatIcon } from '@material-ui/icons';
import clsx from 'clsx';
import { useIsMobile } from '../hooks';

interface ZendeskWidgetContextShape {
  show: boolean;
  setShow(show: boolean): void;
}

const zendeskWidgetContext = React.createContext<ZendeskWidgetContextShape>({
  show: true,
  setShow: () => {},
});

export const ZendeskWidgetProvider = zendeskWidgetContext.Provider;

export const useHideZendeskWidget = (hide = true) => {
  const { setShow } = useContext(zendeskWidgetContext);

  useEffect(() => {
    setShow(!hide);
    return () => setShow(true);
  }, [hide, setShow]);
};

const useStyles = makeStyles((theme: Theme) => ({
  floating: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    padding: 0,
    margin: '10px 20px',
    overflow: 'visible',
    zIndex: 999998,
  },
  button: {
    backgroundColor: 'hsl(204.4deg 100%	33%)',
    color: 'white',
    borderRadius: '999rem',
    padding: theme.spacing(1.5, 2.5),
    display: 'flex',
    alignItems: 'center',
    border: 0,
    outline: 0,
    cursor: 'pointer',
    transition: 'opacity ease 200ms',

    [theme.breakpoints.down('xs')]: {
      borderRadius: '50%',
      padding: '6px 10px',
    },

    '&:hover': {
      backgroundColor: 'hsl(204.4deg 100%	37%)',
    },
    '&:active, &:active&:hover': {
      backgroundColor: 'hsl(204.4deg 100%	40%)',
    },
  },
  label: {
    fontSize: '1rem',
    fontWeight: 700,
    letterSpacing: 0.6,
    marginLeft: theme.spacing(1),
  },
}));

export interface ZendeskWidgetProps {
  isFloating?: boolean;
  hideOnMobile?: boolean;
  hideOnDesktop?: boolean;
}

export const ZendeskWidget = (props: ZendeskWidgetProps) => {
  const { isFloating, hideOnMobile, hideOnDesktop } = props;
  const classes = useStyles();
  const isMobile = useIsMobile();
  const context = useContext(zendeskWidgetContext);

  const [isChatWindowOpen, setIsChatWindowOpen] = useState(false);

  useEffect(() => {
    showDefaultWidget(isChatWindowOpen);
  }, [isChatWindowOpen]);

  useEffect(() => {
    window.zE?.('webWidget:on', 'close', () => {
      setIsChatWindowOpen(false);
    });
  }, []);

  if (!context.show) return null;
  if (hideOnMobile && isMobile) return null;
  if (hideOnDesktop && !isMobile) return null;

  const handleClick = () => {
    setIsChatWindowOpen(true);
    window.zE?.('webWidget', 'open');
  };

  return (
    <button
      aria-label="Chat"
      className={clsx(classes.button, { [classes.floating]: isFloating })}
      onClick={handleClick}
      style={{ opacity: isChatWindowOpen ? 0 : 1 }}
    >
      <ChatIcon fontSize={isMobile ? 'small' : 'medium'} />
      {!isMobile && <span className={classes.label}>Chat</span>}
    </button>
  );
};

function showDefaultWidget(show: boolean) {
  window.zE?.('webWidget', show ? 'show' : 'hide');
}

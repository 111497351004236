import React, { useState } from 'react';
import { Button, CircularProgress, Container, makeStyles, Theme } from '@material-ui/core';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import { Navigation, Step, TextInput } from '../../../../common';
import { useSpeechRecognition } from '../../../../hooks';
import { AvidRoom } from '../../types';

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    margin: 'auto',
    width: '70%',
    textAlign: 'right',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

export interface AvidRoomNotesProps {
  room: AvidRoom;
  onNext: (room: AvidRoom) => void;
  onPrevious: () => void;
  isSaving: boolean;
}

export const AvidRoomNotes: React.FC<AvidRoomNotesProps> = ({ room, onNext, onPrevious, isSaving }) => {
  const classes = useStyles();
  const [roomState, setRoomState] = useState(room);
  const [defaultRoomName] = useState(room.name);

  const { isRecording, toggleRecording, isSpeechRecognitionSupported, stopRecording } = useSpeechRecognition({
    onTextUpdate(entireTranscription) {
      setRoomState((roomState) => ({
        ...roomState,
        notes: entireTranscription,
      }));
    },
  });

  const handleNavigateAway = (callback: () => void) => {
    stopRecording();
    setTimeout(() => {
      callback();
    }, 500);
  };

  return (
    <Step title={`What notes do you have about ${room.name}?`}>
      <Container maxWidth="lg">
        <div className={classes.input}>
          <TextInput
            placeholder=""
            value={roomState.name}
            onChange={(e) => setRoomState((current) => ({ ...current, name: e.target.value }))}
            label="Room Name (Optional)"
            InputProps={{ disableUnderline: true }}
            inputProps={{ maxLength: 50 }}
          />
          {isSpeechRecognitionSupported && (
            <Button
              startIcon={isRecording ? <CircularProgress variant="indeterminate" size={18} /> : <RecordVoiceOverIcon />}
              onClick={() => toggleRecording(roomState?.notes ?? '')}
              size="small"
              color="primary"
            >
              {isRecording ? 'Stop recording' : 'Dictate'}
            </Button>
          )}
          <TextInput
            placeholder=""
            value={roomState.notes}
            onChange={(e) => setRoomState((current) => ({ ...current, notes: e.target.value }))}
            autoFocus
            variant="filled"
            InputProps={{ disableUnderline: true }}
            multiline
            rows={8}
          />
        </div>
        <Navigation
          isLoading={isSaving}
          PrevButtonProps={{
            children: 'Cancel',
            onClick: () => handleNavigateAway(onPrevious),
          }}
          NextButtonProps={{
            children: 'Save',
            onClick: () => handleNavigateAway(() => onNext({ ...roomState, name: roomState.name || defaultRoomName })),
          }}
        />
      </Container>
    </Step>
  );
};

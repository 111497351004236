import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';

const useStyles = makeStyles<Theme, InfoBlockProps>(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      backgroundColor: theme.colors.grey.offWhite1,
      border: '1px solid',
      borderColor: theme.colors.grey.disable,
      padding: theme.spacing(3),
      borderRadius: 6,
    },
    icon: {
      marginRight: theme.spacing(2),
      marginTop: 4,
    },
  }),
  { classNamePrefix: 'InfoBlock' }
);

export interface InfoBlockProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

/**
 * A gray rectangle with an "i" icon intended to provide useful information to the user
 */
export const InfoBlock: React.FC<InfoBlockProps> = (props) => {
  const { children, className, ...rest } = props;
  const classes = useStyles(props);

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <InfoOutlined className={classes.icon} />
      <Typography variant="body1">{children}</Typography>
    </div>
  );
};

import { useMutation } from 'react-query';
import { ActionType } from 'src/pages/Team/types';
import { useAssignFullAccess, useRequestFullAccess } from '.';

export interface UseInviteTeamMemberPayload {
  delegateEmails: string[];
}

/*
 * Calls either assignFullAccess or requestFullAccess depending on the action type
 * May need to be updated when INNO2-926 is implemented
 */
export const useInviteTeamMember = (action: ActionType | null = ActionType.REQUEST) => {
  const { mutateAsync: assignFullAccess } = useAssignFullAccess();
  const { mutateAsync: requestFullAccess } = useRequestFullAccess();

  return useMutation(async (payload: UseInviteTeamMemberPayload) => {
    const { delegateEmails } = payload;
    if (action === ActionType.SHARE) {
      await assignFullAccess({ delegateEmails });
    } else {
      await requestFullAccess({ delegateEmails });
    }
  }, {});
};

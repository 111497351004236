import React from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { sendFormsMutationKey } from '../../../hooks';
import { useGetAvidPackage } from '../../Avid/hooks';
import { SurferBoiLoading } from '../../../common/SurferBoiLoading';

const loadingStatuses = [
  'Uploading your documents...',
  'Gathering your contact info...',
  'Sending your documents...',
  "Dotting i's and crossing t's...",
];

export const SendFormsSending = () => {
  const history = useHistory();
  const { packageId } = useParams<{ packageId: string }>();
  const { data: breeze } = useGetAvidPackage(packageId);

  return (
    <SurferBoiLoading
      loadingStatuses={loadingStatuses}
      mutationKey={sendFormsMutationKey}
      onDone={() => history.push(`/file/${breeze?.fileId}/packages`)}
      title="You're almost done!"
    />
  );
};

import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { gql } from 'graphql-request';
import * as Sentry from '@sentry/react';
import { ErrorCodes, getGraphQLClient, getApiToken } from '../../../lib';
import { getPackagesByFileIdKey } from '../queries/useGetFilePackages';
import { getDashboardPackagesByFileIdKey } from '../queries/useGetDashboardPackages';
import { useCustomSnackbar } from '../../useCustomSnackbar';
import { FilePackage, PackageStatusType } from '../../../pages/FilePackages/types';
import { useSetUxPackageStatus } from './useSetUxPackageStatus';

const mutationQuery = gql`
  mutation CancelPackageEnvelopes($packageId: String!) {
    cancelPackageEnvelopes(packageId: $packageId)
  }
`;

export interface CancelPackageEnvelopesVariables {
  packageId: string;
}

export const useCancelEnvelopes = (
  fileId: number,
  options: UseMutationOptions<unknown, unknown, CancelPackageEnvelopesVariables> = {}
) => {
  const snackbarController = useCustomSnackbar();
  const setPackageUxStatus = useSetUxPackageStatus(fileId);
  const queryClient = useQueryClient();

  return useMutation(
    async (variables: CancelPackageEnvelopesVariables) => {
      const client = getGraphQLClient(await getApiToken());
      await client.request(mutationQuery, variables);
    },
    {
      ...options,
      onMutate: async ({ packageId }) => {
        if (options.onMutate) {
          return options.onMutate({ packageId });
        }
        return {
          previousPackages: await setPackageUxStatus(packageId, PackageStatusType.uxLoading, `Cancelling...`),
        };
      },
      onSuccess: async (data, variables, context) => {
        if (options.onSuccess) {
          await options.onSuccess(data, variables, context);
        } else {
          snackbarController.addSuccessSnackbar('Your envelope has been canceled.');
        }
      },
      onError: async (err, data, context: { previousPackages?: FilePackage[] }) => {
        console.error(err);
        const eventId = Sentry.captureException(err);

        if (options.onError) {
          await options.onError(err, data, context);
        } else {
          snackbarController.addErrorSnackbar({
            message: 'Your envelope failed to cancel, please try again in a few seconds.',
            errorCode: ErrorCodes.CancelEnvelope,
            eventId,
          });
          const packagesQueryKey = [getPackagesByFileIdKey, fileId];
          if (context?.previousPackages) {
            queryClient.setQueryData(packagesQueryKey, context.previousPackages);
          }
        }
      },
      onSettled: async (data, error, variables, context) => {
        if (options.onSettled) {
          await options.onSettled(data, error, variables, context);
        } else {
          await queryClient.invalidateQueries([getPackagesByFileIdKey, fileId]);
          await queryClient.invalidateQueries([getDashboardPackagesByFileIdKey, fileId]);
        }
      },
    }
  );
};

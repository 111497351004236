import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Typography, makeStyles, Theme, TypographyProps } from '@material-ui/core';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      marginBottom: theme.spacing(5),
      textAlign: 'center',
      [theme.breakpoints.down('xs')]: {
        fontSize: '24px',
        lineHeight: 'initial',
        marginBottom: theme.spacing(3),
      },
    },
  }),
  { classNamePrefix: 'Step' }
);

export interface StepProps extends Omit<React.HtmlHTMLAttributes<HTMLDivElement>, 'title'> {
  title?: React.ReactNode;
  titleProps?: TypographyProps;
}

export const Step: React.FunctionComponent<StepProps> = (props) => {
  const classes = useStyles(props);
  const { children, className, title, titleProps = {}, ...rest } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      {typeof title === 'string' ? (
        <Typography
          {...titleProps}
          variant="h2"
          className={clsx(classes.title, titleProps.className)}
          data-testid="wizard-step-title"
        >
          {title}
        </Typography>
      ) : (
        title
      )}
      {children}
    </div>
  );
};

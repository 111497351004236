import { useMutation, useQueryClient } from 'react-query';
import { Contact } from 'src/lib';
import { getFilesByIdQueryKey } from '../queries';
import { useCreateContacts } from './useCreateContacts';
import { useDeleteContacts } from './useDeleteContacts';
import { useEditContacts } from './useEditContacts';

type UpdateContactsPayload = {
  fileId: number;
  deletedContacts?: Contact[];
  createdContacts?: Contact[];
  editedContacts?: Contact[];
};

export const useUpdateContacts = () => {
  const { mutateAsync: createContacts } = useCreateContacts();
  const { mutateAsync: deleteContacts } = useDeleteContacts();
  const { mutateAsync: editContacts } = useEditContacts();
  const queryClient = useQueryClient();

  return useMutation(
    (payload: UpdateContactsPayload) => {
      const { fileId, deletedContacts, createdContacts, editedContacts } = payload;

      const deletePromise = deletedContacts?.length
        ? deleteContacts({ fileId: fileId, contacts: deletedContacts })
        : null;

      const createPromise = createdContacts?.length
        ? createContacts({
            fileId: fileId,
            contacts: createdContacts,
          })
        : null;

      const editPromise = editedContacts?.length
        ? editContacts({
            fileId: fileId,
            contacts: editedContacts,
          })
        : null;

      return Promise.all([deletePromise, createPromise, editPromise]);
    },
    {
      onSuccess(result, variables) {
        queryClient.invalidateQueries([getFilesByIdQueryKey, variables.fileId]);
      },
    }
  );
};

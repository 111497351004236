import { Typography, Theme, makeStyles } from '@material-ui/core';
import React from 'react';
import { StdDialog } from '../../../common';

const useStyles = makeStyles((theme: Theme) => ({
  subtitle: {
    marginTop: -1 * theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
}));

export interface PackageActionDialogProps {
  titleText: string;
  subtitleText?: string;
  onClose: () => void;
  closeText: string;
  onSubmit: () => void;
  submitText: string;
  isSubmitting?: boolean;
}

export const PackageActionDialog: React.FC<PackageActionDialogProps> = ({
  children,
  titleText,
  subtitleText,
  onClose,
  closeText,
  onSubmit,
  submitText,
  isSubmitting = false,
}) => {
  const classes = useStyles();

  return (
    <StdDialog
      open
      onClose={onClose}
      subtitle={titleText}
      actions={
        <>
          <StdDialog.SecondaryActionButton disabled={isSubmitting} onClick={onClose}>
            {closeText}
          </StdDialog.SecondaryActionButton>
          <StdDialog.PrimaryActionButton isLoading={isSubmitting} onClick={onSubmit}>
            {submitText}
          </StdDialog.PrimaryActionButton>
        </>
      }
    >
      {subtitleText && (
        <Typography variant="subtitle2" className={classes.subtitle}>
          {subtitleText}
        </Typography>
      )}
      {children}
    </StdDialog>
  );
};

import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      fontSize: theme.typography.pxToRem(19),
      overflowWrap: 'anywhere',
    },
    error: {
      color: theme.colors.error,
      marginTop: theme.spacing(1),
    },
  }),
  { classNamePrefix: 'RecipientText' }
);

export interface RecipientTextProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  hasError?: boolean;
}

export const RecipientText: React.FC<RecipientTextProps> = (props) => {
  const classes = useStyles();
  const { hasError, ...rest } = props;

  return <div {...rest} className={clsx(classes.root, { [classes.error]: hasError })} />;
};

import { atom } from 'recoil';
import { UserDelegateWithMetadata } from 'src/lib';
import { createSessionStorageEffect } from 'src/state/utils';

export type AgentProfile = {
  userId: string;
  firstName?: UserDelegateWithMetadata['firstName'];
  middleName?: UserDelegateWithMetadata['middleName'];
  lastName?: UserDelegateWithMetadata['lastName'];
  email?: UserDelegateWithMetadata['email'];
};

const storageKey = 'breeze-selected-agent';

const writeToSessionStorageEffect = createSessionStorageEffect<AgentProfile | null>(storageKey);

export const selectedAgentAtom = atom<AgentProfile | null>({
  key: 'selected-agent-state',
  default: null,
  effects_UNSTABLE: [writeToSessionStorageEffect],
});

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { captureException, setExtra } from '@sentry/browser';
import { config } from './constants';

export const sentryInit = () => {
  Sentry.init({
    dsn: 'https://17b76ce7c73f4af2b4e914da8633abf9@o460680.ingest.sentry.io/5733767',
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    environment: config.env,
    tracesSampleRate: 0.5,
    normalizeDepth: 5,
    beforeSend(event) {
      if (event.request?.url) {
        event.request.url = event.request.url.split('#')[0];
      }
      return event;
    },
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      /fast\.trychameleon\.com/i,
    ],
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const trackError = (err: any, extraArgs: any = {}) => {
  setExtra('apiRequest', err.request);
  setExtra('apiResponseData', err.response?.data);
  captureException(err, { extra: extraArgs });
};

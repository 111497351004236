import { useMutation } from 'react-query';
import { gql } from 'graphql-request';
import { useRecoilValue } from 'recoil';
import * as Sentry from '@sentry/react';
import { selectedAgentAtom } from 'src/state';
import { useCustomSnackbar } from '../../../hooks';
import { ErrorCodes, getGraphQLClient, getApiToken, WithOnBehalfOf } from '../../../lib';
import { AddressInput } from '../../../pages/FilePackages/types';

export interface PackageDTO {
  name: string;
  fileId: number;
  interviewId?: number;
  envelopeId?: string;
  packageId: string;
}

export interface ContactInput {
  id?: number;
  type: string;
  firstName: string;
  lastName: string;
  email: string;
  actionAttribute: string;
  companyName?: string;
  isEntity?: boolean;
}

export interface SklosuresOnBehalfOfInput {
  firstName: string;
  lastName: string;
  email: string;
  organizationID: number;
  phone?: string;
  licenseNumber?: string;
  oktaUserId: string;
}

export interface DocumentInput {
  formName: string;
  formUrl: string;
  formId: number;
  thumbnailUrl?: string;
}

export interface SklosuresPackageConfig {
  signerQuestionFlaggingEnabled: boolean;
  signerResolveFlagEnabled: boolean;
}

export interface BrokerageInput {
  name: string;
}

export interface EmailDetailsInput {
  subject?: string;
  to?: string;
  cc?: string[];
  emailContents?: EmailContents;
}

export interface EmailContents {
  body: string;
  signature: string;
}

export interface CreateDisclosuresPackagePayload {
  interviewTemplateIds: number[];
  apn: string;
  yearBuilt: string;
  isFireHazard: boolean;
  zoning: string;
  property: AddressInput;
  sellers: ContactInput[];
  contacts: ContactInput[];
  existingFileId?: number;
  onBehalfOfInterview: SklosuresOnBehalfOfInput;
  brokerage?: BrokerageInput;
  emailDetails?: EmailDetailsInput;
  config?: SklosuresPackageConfig;
}

const mutationQuery = gql`
  mutation CreateDisclosuresPackage($payload: CreateDisclosuresPackagePayload) {
    createDisclosuresPackage(payload: $payload) {
      packageId
      fileId
    }
  }
`;

export const sendPackageMutationKey = 'send-package-mutation-key';

export const useCreateDisclosuresPackage = () => {
  const selectedAgent = useRecoilValue(selectedAgentAtom);
  const { addErrorSnackbar } = useCustomSnackbar();

  return useMutation(
    async (payload: CreateDisclosuresPackagePayload) => {
      const client = getGraphQLClient(await getApiToken());
      return (
        await client.request<
          { createDisclosuresPackage: PackageDTO },
          { payload: WithOnBehalfOf<CreateDisclosuresPackagePayload> }
        >(mutationQuery, { payload: { ...payload, onBehalfOf: selectedAgent?.userId } })
      ).createDisclosuresPackage;
    },
    {
      mutationKey: sendPackageMutationKey,
      onError(e) {
        console.error(e);
        const eventId = Sentry.captureException(e);
        addErrorSnackbar({ errorCode: ErrorCodes.CreateDisclosurePackage, eventId });
      },
    }
  );
};

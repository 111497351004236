import { useMutation } from 'react-query';
import { UserDelegate, ErrorCodes } from 'src/lib';
import { ActionType } from 'src/pages/Team/types';
import { useCancelDelayedUserAction } from 'src/pages/Avid/hooks/useCancelDelayedUserAction';
import { useCustomSnackbar } from '../useCustomSnackbar';
import { useCancelInvite } from './useCancelInvite';
import { useRevokeInvite } from './useRevokeInvite';

interface Payload {
  delegate: UserDelegate;
  action: ActionType;
  status: 'approved' | 'pending';
}

export const useCancelOrRevokeDelegateInvite = () => {
  const snackbarController = useCustomSnackbar();
  const { mutateAsync: revokeInvite } = useRevokeInvite();
  const { mutateAsync: cancelInvite } = useCancelInvite();
  const { mutateAsync: cancelDelayedUserAction } = useCancelDelayedUserAction();

  return useMutation(async (payload: Payload) => {
    const { delegate, action, status } = payload;

    function getSuccessMessage() {
      if (status === 'pending' && action === ActionType.REQUEST) {
        return 'Your invite has been cancelled';
      }
      if (status === 'pending' && action === ActionType.SHARE) {
        return `Invite from ${delegate.email} was declined`;
      }
      if (status === 'approved' && action === ActionType.REQUEST) {
        return `You have left ${delegate.firstName} ${delegate.lastName}'s team`;
      }
      if (status === 'approved' && action === ActionType.SHARE) {
        return `You removed ${delegate.firstName} ${delegate.lastName} from your team`;
      }
      return undefined;
    }

    const mutationOptions = {
      onSuccess: () => snackbarController.addSuccessSnackbar(getSuccessMessage()),
      onError: (e: unknown) => {
        console.error(e);
        snackbarController.addErrorSnackbar({
          message: status === 'pending' ? `Failed to decline invite` : `Failed to remove ${delegate.email}`,
          errorCode: status === 'pending' ? ErrorCodes.TeamPageCancelInvite : ErrorCodes.TeamPageRemoveCollaborator,
        });
      },
    };

    if (delegate.delayedActionId != null) {
      await cancelDelayedUserAction(delegate.delayedActionId, mutationOptions);
    } else if (action === ActionType.REQUEST) {
      await cancelInvite({ delegateFromIds: [delegate.ownerId!] }, mutationOptions);
    } else if (action === ActionType.SHARE) {
      await revokeInvite({ delegateIds: [delegate.delegateId!] }, mutationOptions);
    }
  }, {});
};

import React from 'react';
import { Box, Grid, makeStyles, Paper, Theme, Typography, Button, alpha } from '@material-ui/core';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Pagination } from 'swiper';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import 'swiper/swiper-bundle.min.css';
import TestimonialAgentImage1 from 'src/images/testimonialagent1.png';
import TestimonialAgentImage2 from 'src/images/testimonialagent2.png';
import TestimonialAgentImage3 from 'src/images/testimonialagent3.png';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: '100px',
    paddingBottom: '100px',
    backgroundColor: theme.colors.grey[100],
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
  },
  swiper: {
    paddingTop: '100px',
    paddingBottom: '100px',
    paddingLeft: '30px',
    '& .swiper-pagination-bullet-active': { background: '#f77721' },
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.spacing(1),
    alignItems: 'center',
    paddingBottom: '20px',
    boxShadow: `0 2px 2px 0 ${alpha(theme.colors.grey.offBlack, 0.2)}, 0 3px 1px -2px ${alpha(
      theme.colors.grey.offBlack,
      0.06
    )}, 0 1px 5px 0 ${alpha(theme.colors.grey.offBlack, 0.07)}`,
    position: 'relative',
  },
  quote: {
    padding: '20px',
    paddingTop: '70px',
    minHeight: '100px',
  },
  image: {
    borderRadius: '50%',
    width: '100px',
    height: '100px',
    position: 'absolute',
    transform: 'translate(0%, -50%) scale(1)',
    boxShadow: `0 3px 5px 0 ${alpha(theme.colors.grey.offBlack, 0.2)}, 0 3px 1px -2px ${alpha(
      theme.colors.grey.offBlack,
      0.06
    )}, 0 1px 5px 0 ${alpha(theme.colors.grey.offBlack, 0.07)}`,
  },
  agent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '10px',
  },
  tryItButton: {
    marginBottom: '50px',
  },
  buttonArrow: {
    paddingLeft: '5px',
  },
}));

interface TestimonialData {
  name: string;
  company: string;
  quote: string;
  src: string;
  stars: number;
}

const testimonialData: TestimonialData[] = [
  {
    name: 'Karen Graser',
    company: 'My Home Group',
    quote: 'I love it! It’s really going to add value to my services.',
    src: TestimonialAgentImage1,
    stars: 5,
  },
  {
    name: 'Diana Costas',
    company: 'Golden Gate Sotheby’s ',
    quote: 'Breeze is truly a one-stop shop for faster disclosures.',
    src: TestimonialAgentImage3,
    stars: 5,
  },
  {
    name: 'Colleen Cofield',
    company: 'Vista Sotheby’s',
    quote:
      'Breeze…is easier and cleaner for my clients to fill out disclosures online and much clearer for all parties to read.',
    src: TestimonialAgentImage2,
    stars: 5,
  },
];

export const LandingTestimonials: React.FC = () => {
  const classes = useStyles();

  const getCard = (data: TestimonialData, index: number) => (
    <SwiperSlide key={index}>
      <Paper className={classes.paper} elevation={6} key={index}>
        <img src={data.src} className={classes.image} alt={`testimonial-${index}`} />
        <Typography component="span" className={classes.quote}>
          <Box sx={{ fontSize: 16 }}>&quot;{data.quote}&quot;</Box>
        </Typography>
        <span>
          {[...Array(data.stars)].map((e, i) => (
            <StarRoundedIcon key={i} fontSize="large" color="primary" />
          ))}
        </span>
        <div className={classes.agent}>
          <Typography component="span">
            <Box sx={{ fontWeight: 'bold' }}>{data.name}</Box>
          </Typography>
          <Typography component="span">
            <Box sx={{ fontStyle: 'italic', fontSize: 15, color: 'grey' }}>{data.company}</Box>
          </Typography>
        </div>
      </Paper>
    </SwiperSlide>
  );
  return (
    <>
      <div className={classes.container}>
        <Button
          id="testimonial-try-today-button"
          color="primary"
          variant="contained"
          className={classes.tryItButton}
          onClick={(e) => {
            const loginWidget = document.getElementById('login-widget');
            e.preventDefault();
            loginWidget && loginWidget.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }}
        >
          Try It Today
          <ArrowForwardIcon className={classes.buttonArrow} />
        </Button>
        <Grid container justifyContent="center" alignItems="center">
          <Swiper
            breakpoints={{
              // will trigger when window width is >= breakpoint
              0: {
                width: 345,
                slidesPerView: 1,
                spaceBetween: 10,
              },
              640: {
                width: 610,
                slidesPerView: 2,
                spaceBetween: 30,
              },
              770: {
                width: 740,
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1030: {
                width: 1000,
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            slidesPerView={3}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className={classes.swiper}
          >
            {testimonialData.map((data, index) => getCard(data, index))}
          </Swiper>
        </Grid>
      </div>
    </>
  );
};

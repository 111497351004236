import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { WizardStepProps } from 'src/domain';
import { AvidRoom, RoomBuckets } from '../../../types';
import { AvidSteps } from '../../../AvidCreation';
import { useGetAvidPackage, useGetAvidRoomOptions, useUpdateAvidPackage } from '../../../hooks';
import { AvidRoomNotes } from '../AvidRoomNotes';
import { HomeBaseView } from './HomeBaseView';
import { HomeBaseEdit, RoomCounts } from './HomeBaseEdit';
import { reconcileRoomType } from './reconcileRoomType';

export const AvidHomeBase = (props: WizardStepProps) => {
  const { data: options } = useGetAvidRoomOptions();
  const { packageId } = useParams<{ packageId: string }>();
  const [isEditing, setIsEditing] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState<AvidRoom>();
  const { mutate, isLoading: isSaving } = useUpdateAvidPackage();
  const { data: avidPackage } = useGetAvidPackage(packageId);
  const rooms = avidPackage?.workflowData.rooms;

  const buckets = useMemo(
    () =>
      rooms?.reduce<RoomBuckets>((accumulator, currentRoom) => {
        if (!accumulator[currentRoom.roomType]) {
          accumulator[currentRoom.roomType] = [];
        }
        accumulator[currentRoom.roomType].push(currentRoom);

        return accumulator;
      }, {}) ?? {},
    [rooms]
  );

  const optionLookup = useMemo(
    () => Object.assign({}, ...(options?.map((o) => ({ [o.roomType]: o })) ?? [])),
    [options]
  );

  const handleRoomEdit = useCallback(
    (roomCounts: RoomCounts) => {
      let newRooms = rooms?.slice() ?? [];
      Object.entries(roomCounts).forEach(([roomType, count]) => {
        newRooms = reconcileRoomType(roomType, count, newRooms, optionLookup);
      });

      mutate(
        {
          packageId: packageId!,
          rooms: newRooms,
        },
        {
          onSuccess: () => {
            setIsEditing(false);
          },
        }
      );
    },
    [rooms, packageId, mutate, optionLookup]
  );

  const handleAddNotes = useCallback(
    (selectedRoom: AvidRoom) => {
      const roomsCopy = rooms?.slice() ?? [];
      const updatedRoomIndex = rooms?.findIndex(
        (room) => room.roomType === selectedRoom.roomType && room.order === selectedRoom.order
      );

      if (updatedRoomIndex == null || updatedRoomIndex < 0) {
        return;
      }

      roomsCopy[updatedRoomIndex] = selectedRoom;
      mutate(
        {
          packageId: packageId!,
          rooms: roomsCopy,
        },
        {
          onSuccess: () => {
            setSelectedRoom(undefined);
          },
        }
      );
    },
    [rooms, packageId, mutate]
  );

  const onNext = () => {
    props.goToStep(AvidSteps.inspectionDateTime);
  };

  return (
    <>
      {selectedRoom ? (
        <AvidRoomNotes
          room={selectedRoom}
          onNext={handleAddNotes}
          onPrevious={() => setSelectedRoom(undefined)}
          isSaving={isSaving}
        />
      ) : (
        <>
          {!isEditing && (
            <HomeBaseView
              onAddNotes={setSelectedRoom}
              onNext={onNext}
              onEdit={() => setIsEditing(true)}
              options={options ?? []}
              isLoading={!avidPackage}
              buckets={buckets}
            />
          )}
          {isEditing && (
            <HomeBaseEdit
              rooms={rooms ?? []}
              options={options ?? []}
              buckets={buckets}
              onSave={handleRoomEdit}
              onCancel={() => setIsEditing(false)}
              isInitialCreateFlow={false}
            />
          )}
        </>
      )}
    </>
  );
};

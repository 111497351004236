import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, IconButton, LinearProgress, Grid, Box, Typography, Button } from '@material-ui/core';
import { Menu as MenuIcon, ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router';
import { useRecoilState } from 'recoil';
import { useIsMedium } from 'src/hooks';
import breezeIconAgent from 'src/images/breezeLogoAgent.svg';
import { ZendeskWidget } from 'src/common/ZendeskWidget';
import { isNavMenuOpenAtom } from 'src/state/navMenuAtom';

export const WIZARD_HEADER_HEIGHT = 64;

const useStyles = makeStyles(
  (theme: Theme) => ({
    header: {
      width: '100%',
      height: WIZARD_HEADER_HEIGHT,
      display: 'flex',
      alignItems: 'center',
      color: theme.colors.grey.bodyHeader,
      zIndex: 2,
    },
    subHeader: {
      maxHeight: 40,
      backgroundColor: theme.colors.grey.offWhite1,
      boxShadow: `inset 0 -1px 0 0 ${theme.colors.grey.disable}`,
    },
    headerBg: {
      backgroundColor: 'white',
    },
    headerTopNavBg: {
      backgroundColor: theme.colors.grey.offWhite1,
    },
    menuButton: {
      marginLeft: 16,
    },
    formsIcon: {
      height: 18,
    },
    headerRight: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    formsButtonLabel: {
      fontWeight: 'bold',
      color: theme.colors.grey.bodyHeader,
    },
    formsText: {
      marginLeft: 8,
      marginRight: 32,
      [theme.breakpoints.down('xs')]: {
        display: 'none',
      },
    },
    progressBar: {
      backgroundColor: theme.colors.grey.grey1,
      [theme.breakpoints.down('md')]: {
        marginLeft: theme.spacing(-7),
      },
    },
    progressBarSection: {
      position: 'relative',
    },
    progressBarWrapper: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      height: '100%',
      [theme.breakpoints.down('md')]: {
        margin: '0px !important',
      },
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    progressBarLabel: {
      fontWeight: 'bold',
    },
    linkIcon: {
      padding: theme.spacing(0.5, 0.5),
      fontSize: theme.spacing(2.5),
      margin: theme.spacing(0, 2),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0.5),
        marginRight: theme.spacing(1),
        margin: theme.spacing(0, 0),
      },
      cursor: 'pointer',
      fill: theme.colors.mainColor.primary,
      '&:hover': {
        backgroundColor: theme.colors.mainColor.hoverLight,
        boxShadow: `5px 5px 20px 5px ${theme.colors.mainColor.hoverLight}`,
        padding: theme.spacing(0.5, 0.5),
      },
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      padding: 16,
      backgroundColor: theme.colors.grey.offWhite1,
    },
    breezeIcon: {
      height: 35,
    },
  }),
  { classNamePrefix: 'WizardHeader' }
);

export interface WizardHeaderProps {
  disableBreezeBranding?: boolean;
  leftActionOverride?: React.ReactNode | React.ReactNodeArray;
  className?: string;
  progress?: number;
  navMenuOpen?: boolean;
  navHeaderType?: 'progress' | 'package' | undefined;
  toggleNavMenu?: () => void;
  floatRight?: React.ReactNode;
}

export const WizardHeader: React.FC<WizardHeaderProps> = (props) => {
  const classes = useStyles();
  const { className, progress, navHeaderType, floatRight } = props;

  const isMediumAndDown = useIsMedium();
  const isDesktop = !isMediumAndDown;
  const location = useLocation();
  const showBreadCrumb =
    !location.pathname.includes('login') &&
    !location.pathname.includes('register') &&
    !location.pathname.includes('email');

  const [navMenuOpen, setNavMenuOpen] = useRecoilState(isNavMenuOpenAtom);

  return (
    <>
      <header
        className={clsx(
          classes.header,
          navHeaderType && (progress || showBreadCrumb) ? classes.headerTopNavBg : classes.headerBg,
          isDesktop && className
        )}
      >
        {isMediumAndDown && (
          <div className={classes.headerContainer}>
            <img src={breezeIconAgent} className={classes.breezeIcon} alt="Breeze Logo" />
            <div style={{ display: 'flex' }}>
              {floatRight}
              <ZendeskWidget hideOnDesktop />
              <IconButton
                color="inherit"
                aria-label="open drawer"
                className={classes.menuButton}
                edge="start"
                onClick={() => setNavMenuOpen(!navMenuOpen)}
              >
                <MenuIcon />
              </IconButton>
            </div>
          </div>
        )}
        {isDesktop &&
          (navHeaderType && (progress || showBreadCrumb) ? (
            <ProgressBarSection
              navMenuOpen={navMenuOpen}
              {...props}
              bar={<ProgressBar progress={progress} />}
              floatRight={floatRight}
            />
          ) : (
            <div style={{ marginLeft: 'auto' }}>{floatRight}</div>
          ))}
      </header>
      {isMediumAndDown && navHeaderType && showBreadCrumb && (
        <>
          {Boolean(progress) && <ProgressBar progress={progress} />}
          <header className={clsx(classes.header, classes.subHeader, className)}>
            <ProgressBarSection navMenuOpen={navMenuOpen} {...props} />
          </header>
        </>
      )}
    </>
  );
};

function ProgressBarSection(props: WizardHeaderProps & { bar?: React.ReactNode; floatRight?: React.ReactNode }) {
  const classes = useStyles();
  const { progress, navMenuOpen, navHeaderType, bar, floatRight } = props;
  const history = useHistory();

  const showProgress = !!progress && navHeaderType === 'progress';

  return (
    <Grid item xs={12} className={classes.progressBarSection}>
      <Box className={classes.progressBarWrapper} ml={navMenuOpen ? 37.25 : 9}>
        <Box mt={-2} mb={1} ml={2} mr={8} display="flex" alignItems="center">
          {showProgress && (
            <>
              <ArrowBackIcon className={classes.linkIcon} onClick={() => history.push('/')} />
              <Typography component="div" color="primary" className={classes.progressBarLabel}>
                Progress {progress ? progress.toFixed(0) : 0}%
              </Typography>
            </>
          )}
          {!showProgress && (
            <Button
              color="primary"
              size="small"
              onClick={() => history.push('/dashboard')}
              startIcon={<ArrowBackIcon />}
            >
              Back to Home
            </Button>
          )}
          {floatRight && <div style={{ marginLeft: 'auto' }}>{floatRight}</div>}
        </Box>
        {bar}
      </Box>
    </Grid>
  );
}

function ProgressBar(props: { progress: number | undefined }) {
  const classes = useStyles();
  const { progress } = props;

  if (location.pathname.includes('packages')) return null;

  return (
    <LinearProgress
      value={isNaN(progress!) ? 99 : progress}
      variant="determinate"
      color="primary"
      className={classes.progressBar}
    />
  );
}

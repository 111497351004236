import React from 'react';
import { useParams } from 'react-router';
import { WizardStepProps, MissingPropertyInfo } from 'src/domain';
import { usePatchFileV2, useGetFileById } from 'src/hooks';
import { getStreetName, getStreetNumber } from 'src/lib/splitAddress';
import { useGetAvidPackage } from '../hooks';
import { AvidSteps } from '..';

export const AvidEditPropertyInfo = (props: WizardStepProps) => {
  const { packageId } = useParams<{ packageId: string }>();
  const { data: avidPackage } = useGetAvidPackage(packageId);
  const { data: file } = useGetFileById(avidPackage?.fileId);
  const { mutate: patchFile } = usePatchFileV2();

  return (
    <MissingPropertyInfo
      defaultProperty={
        file?.fileData.premisesAddress ?? {
          streetAddress: '',
          city: '',
          state: '',
          postalCode: '',
        }
      }
      onSubmit={(form) => {
        if (file?.id) {
          patchFile({
            fileId: file?.id,
            payload: {
              fileData: {
                premisesAddress: {
                  ...form.property,
                  streetNumber: getStreetNumber(form.property.streetAddress),
                  streetName: getStreetName(form.property.streetAddress),
                  state: 'CA',
                },
              },
            },
          });
        }
        props.goToStep(AvidSteps.review);
      }}
    />
  );
};

import React from 'react';
import { WizardStepProps, BrokerageName } from 'src/domain';
import { SpqTdsDisclosureSteps } from './constants';

export const DisclosureBrokerageName = (props: WizardStepProps) => (
  <BrokerageName
    onNext={() => props.goToStep(SpqTdsDisclosureSteps.customEmail)}
    onBack={() => props.goToStep(SpqTdsDisclosureSteps.sellers)}
  />
);

import React, { useRef } from 'react';
import clsx from 'clsx';
import { Avatar, Chip, makeStyles, Theme, Tooltip } from '@material-ui/core';
import { AccountCircle as UserIcon } from '@material-ui/icons';
import { useId } from 'src/hooks';
import { EmailLineLabel } from 'src/common/ComposeEmail/EmailLineLabel';
import { Value } from 'src/common/ComposeEmail/useComposeEmail';
import { composeEmailVisualInputStyles } from 'src/domain';

const visualInputStyles = (theme: Theme) => ({
  ...composeEmailVisualInputStyles(theme),
  padding: theme.spacing(1, 3),
  paddingRight: 0,

  [theme.breakpoints.down('xs')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
});

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',

      // On desktop screens the root is used as the "input box"
      [theme.breakpoints.up('sm')]: {
        ...visualInputStyles(theme),
        flexDirection: 'row',
      },
    },
    chipInput: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      // On mobile screens, the inner chips input element is used as
      // the "input box" and the label is therefore rendered outside
      // the "input box"
      [theme.breakpoints.down('xs')]: {
        ...visualInputStyles(theme),
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },
    chipWrapper: {
      overflow: 'hidden',
      maxWidth: '100%',

      [theme.breakpoints.down('xs')]: {
        padding: `6px 0`,
      },
    },
    inputAndChip: {
      fontSize: 'inherit',
      '--space': `${theme.spacing(1)}px`,
    },
    chip: {
      textTransform: 'none',
      borderRadius: 999,
      marginRight: 'var(--space)',
      maxWidth: '100%',
      background: theme.colors.grey.offWhite2,
      borderColor: theme.colors.grey.offWhite2,

      '&&:hover': {
        backgroundColor: theme.colors.grey.disable, // #f3f3f3
      },
    },
    chipIcon: {
      // "precedence boost" to increase specificity
      '&&': {
        color: theme.colors.grey.grey1,
        background: 'transparent',
      },
    },
  }),
  { classNamePrefix: 'EmailMetaRecipientField' }
);

export interface EmailToFieldProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  recipients: Value[];
  setRecipients: React.Dispatch<React.SetStateAction<Value[]>>;
  disabled?: boolean;
  label: string;
  onClick: () => void;
  toolTipTitle?: string;
}

export const EmailToField: React.FC<EmailToFieldProps> = (props) => {
  const classes = useStyles();
  const { className, recipients, label, onClick, toolTipTitle = '' } = props;
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const labelId = useId(`email-recipient-${label}`);

  return (
    <div className={clsx(className, classes.root)}>
      <EmailLineLabel inputRef={inputRef} id={labelId}>
        {label}
      </EmailLineLabel>

      <div className={classes.chipInput}>
        <div className={classes.chipWrapper}>
          <Tooltip title={toolTipTitle}>
            <Chip
              onClick={onClick}
              className={clsx(classes.inputAndChip, classes.chip)}
              label={recipients[0]}
              variant="outlined"
              avatar={
                <Avatar className={classes.chipIcon}>
                  <UserIcon className={classes.chipIcon} />
                </Avatar>
              }
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

import React from 'react';
import { AddTeamMemberStep, WizardStepProps } from 'src/domain';
import { AvidSteps } from 'src/pages';

/**
 * Navigated to when the user clicks 'Add a team member' from the
 * edit contacts step.  This step is visually identical in all but
 * title to the standard add team member page.
 */
export const AvidAddContactTeamMember: React.FC<WizardStepProps> = (props) => (
  <AddTeamMemberStep
    {...props}
    title="Who would you like to add to your team?"
    previousStepName={AvidSteps.editFileTeamMembers}
  />
);

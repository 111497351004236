import { atom } from 'recoil';
import { UserMetaRole } from 'src/lib';
import { createSessionStorageEffect } from 'src/state/utils';

export type NewUserInviteContext = {
  inviteRole: UserMetaRole;
  inviteEmail: string;
};

type AtomType = NewUserInviteContext | null;

const atomKey = 'new-user-invite-context';

const sessionStorageEffect = createSessionStorageEffect<AtomType>(atomKey);

/**
 * This state stores the values of query parameters that are set via a link
 * in the email sent to the user inviting them to join Breeze.
 * The state is used for populating the users email, handling data re-fetching, and business logic around role
 * It is used in conjunction with `useHydrateNewUserInviteState`
 */
export const newUserInviteAtom = atom<AtomType>({
  key: atomKey,
  default: null,
  effects_UNSTABLE: [sessionStorageEffect],
});

import { useState, useEffect, useRef } from 'react';
import { capitalize } from '../lib';

export interface UseSpeechRecognitionConfig {
  /** The maximum allowed number of characters to be dictated */
  maxLength?: number;
  /**
   * Callback invoked as the person is talking with the latest of what
   * has been said.  The returned value will include the current text
   * passed to `startRecording()` or `toggleRecording()`.
   */
  onTextUpdate(entireTranscription: string): void;
}

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

/**
 * A hook to simplify the usage of the [SpeechRecognition](https://developer.mozilla.org/en-US/docs/Web/API/SpeechRecognition)
 * functionality built into the browser.  Provide it any text that the user
 * has already input via some other method, and it will invoke the
 * `onTextUpdate` callback as the user speaks.
 */
export const useSpeechRecognition = (config: UseSpeechRecognitionConfig) => {
  const { maxLength, onTextUpdate } = config;

  const [isRecording, setIsRecording] = useState(false);
  const recognitionRef = useRef(SpeechRecognition ? new SpeechRecognition() : undefined);

  // Use of refs to keep track of the text is more performant than state
  // so that the event listeners in the useEffect() below don't have to
  // be re-attached every time the value of either one of these updates.

  // Constantly updated as the user is speaking
  const currentSentenceRef = useRef('');
  // Updated once the user pauses speaking
  const persistedTextRef = useRef('');

  const startRecording = (currentText: string) => {
    recognitionRef.current?.start();
    setIsRecording(true);
    persistedTextRef.current = currentText;
  };

  const stopRecording = () => {
    recognitionRef.current?.stop();
    setIsRecording(false);
  };

  const toggleRecording = (currentText: string) => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording(currentText);
    }
  };

  window.onpopstate = () => stopRecording();

  /**
   * Capitalize each sentence, limit the transcript to the maxLength, and
   * add an ellipsis if it overflows
   */
  const getEntireTranscript = (newText: string) => {
    let transcript = [persistedTextRef.current, newText]
      .join(' ')
      .trim()
      .split('.')
      .map((s) => capitalize(s.trim()))
      .join('. ')
      .trim();

    if (maxLength && transcript.length >= maxLength) {
      transcript = transcript.slice(0, maxLength - 3);
      transcript += '...';
    }

    return transcript;
  };

  useEffect(() => {
    if (!recognitionRef.current) return;

    recognitionRef.current.interimResults = true;

    // This function will be called multiple times as the user continues to speak
    recognitionRef.current.onresult = (event) => {
      const newlySpokenText = capitalize(event.results[0][0].transcript);
      const entireTranscript = getEntireTranscript(newlySpokenText);

      if (maxLength && entireTranscript.length >= maxLength) {
        stopRecording();
      }

      currentSentenceRef.current = newlySpokenText;
      onTextUpdate(entireTranscript);
    };

    // This function will be called every time the speaker pauses
    recognitionRef.current.onend = () => {
      let entireTranscript = getEntireTranscript(currentSentenceRef.current);

      if (!entireTranscript.endsWith('.')) {
        entireTranscript += '. ';
      }

      persistedTextRef.current = entireTranscript;
      currentSentenceRef.current = '';
      onTextUpdate(entireTranscript);

      if (isRecording) {
        recognitionRef.current?.start();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxLength, isRecording]);

  return {
    startRecording,
    stopRecording,
    toggleRecording,
    isRecording,
    isSpeechRecognitionSupported: !!SpeechRecognition,
  };
};

import { useIsFetching, useIsMutating } from 'react-query';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useGetActiveUserProfile, useIsUserAuthenticatedForType } from 'src/hooks';
import { selectedAgentAtom } from 'src/state';
import { AuthType } from 'src/lib';
import { AvidSteps } from '../AvidCreation';

export interface UseAvidUserProfileBoundaryOptions {
  goToStep: (step: AvidSteps) => void;
  targetAction: () => void;
}

export interface UseAvidUserProfileBoundaryResult {
  boundedAction: () => void;
  isLoading: boolean;
}

export const useAvidUserProfileBoundary = ({
  goToStep,
  targetAction,
}: UseAvidUserProfileBoundaryOptions): UseAvidUserProfileBoundaryResult => {
  const isUpdating = useIsMutating();
  const isFetching = useIsFetching();
  const [actionState, setActionState] = useState<'initial' | 'queued' | 'executed'>('initial');
  const { data: userProfile, isLoading: isUserLoading } = useGetActiveUserProfile();
  const { isAuthenticated: isCarAuthenticated, isLoading: isCarAuthLoading } = useIsUserAuthenticatedForType(
    AuthType.CaNrds
  );
  const isLoading = isUserLoading || isCarAuthLoading;

  const selectedAgent = useRecoilValue(selectedAgentAtom);

  const action = useCallback(() => {
    if (!selectedAgent) {
      if (!isCarAuthenticated) {
        goToStep(AvidSteps.car);
        return;
      }

      if (!userProfile?.licenseNumber) {
        goToStep(AvidSteps.licenseNumber);
        return;
      }

      if (!userProfile?.brokerageName) {
        goToStep(AvidSteps.brokerageName);
        return;
      }
    }

    targetAction();
  }, [isCarAuthenticated, userProfile, targetAction, goToStep, selectedAgent]);

  useEffect(() => {
    if (actionState === 'queued' && !isUpdating && !isFetching) {
      action();
      setActionState('executed');
    }
  }, [action, actionState, isUpdating, isFetching]);

  return {
    isLoading,
    boundedAction: () => setActionState((curstate) => (curstate === 'executed' ? 'executed' : 'queued')),
  };
};

import React, { ReactNode, useState } from 'react';
import { Grow, Theme, makeStyles } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { ReviewRow } from 'src/domain';

export const useStyles = makeStyles((theme: Theme) => ({
  expandedItems: {
    backgroundColor: theme.colors.grey.offWhite1,
    border: `1px solid ${theme.colors.grey.disable}`,
  },
  expandedItem: {
    cursor: 'pointer',
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(19),
    padding: theme.spacing(3, 4),
    background: 'none',
    border: 'none',
    borderBottom: `1px solid ${theme.colors.grey.disable}`,
    '&:last-child': {
      borderBottom: 0,
    },
    '&:hover': {
      backgroundColor: theme.colors.mainColor.hoverLight,
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3, 4, 3, 2),
      marginLeft: 196,
    },
  },
  expandedItemContent: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface ExpandableReviewRowItemProps {
  children?: ReactNode;
  onEdit: () => void;
  value?: string;
}

const ExpandableReviewRowItem: React.FC<ExpandableReviewRowItemProps> = (props) => {
  const { onEdit, children, value } = props;
  const classes = useStyles();

  return (
    <button className={classes.expandedItem} onClick={() => onEdit()}>
      {value}
      {children ? <div className={classes.expandedItemContent}>{children}</div> : children}
      <ArrowForward />
    </button>
  );
};

interface ExpandableReviewRowProps {
  children: ReactNode;
  label: string;
  value: string;
  onEdit?: () => void;
}

export function ExpandableReviewRow(props: ExpandableReviewRowProps) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const { children, label, value, onEdit } = props;
  const classes = useStyles();

  return (
    <>
      <ReviewRow
        label={label}
        rightSideContent={
          <>
            <ReviewRow.ExpandButton isExpanded={isExpanded} onClick={() => setIsExpanded((current) => !current)} />
            {onEdit && <ReviewRow.Button onClick={() => onEdit()}>Edit</ReviewRow.Button>}
          </>
        }
      >
        {value}
      </ReviewRow>
      <Grow
        in={isExpanded}
        mountOnEnter
        unmountOnExit
        timeout={{
          enter: 500,
          exit: 0,
        }}
      >
        <section className={classes.expandedItems}>{children}</section>
      </Grow>
    </>
  );
}

ExpandableReviewRow.Item = ExpandableReviewRowItem;

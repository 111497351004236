import React, { useCallback, useEffect, useMemo, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles';
import { Box, Checkbox, Grid, List, ListItemIcon, ListItemText } from '@material-ui/core';
import clsx from 'clsx';
import { SelectableButton } from 'src/common';
import { DisclosuresAssociationName } from 'src/common/DisclosuresAssociationName';
import { PackageForm } from '../../FilePackages/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: theme.spacing(90.5),
  },
  formControlRoot: {
    width: '100%',
  },
  disclosureName: {
    fontWeight: 600,
    fontSize: '18px',
    color: '#465766',
  },
  checked: {
    '&:hover': {
      backgroundColor: 'inherit',
    },
    '& svg': {
      fill: `${theme.colors.mainColor.primary} !important`,
    },
    '&.Mui-focusVisible svg': {
      fill: `${theme.colors.grey.grey1} !important`,
    },
  },
  checkboxRoot: {
    marginLeft: 32,
    '& .MuiIconButton-label': {
      height: 24,
      width: 24,
      backgroundColor: 'white',
      [theme.breakpoints.up('sm')]: {
        width: 38,
        height: 38,
      },
    },
    '&:hover': {
      backgroundColor: 'inherit',
    },
    '&:hover svg': {
      fill: theme.colors.mainColor.primary,
    },
    '&.Mui-focusVisible svg': {
      fill: theme.colors.mainColor.primary,
    },
    '& svg': {
      fill: theme.colors.grey.grey1,
      width: 32,
      height: 32,
      [theme.breakpoints.up('sm')]: {
        width: 52,
        height: 52,
      },
    },
  },
  listRoot: {
    borderRadius: '6px',
    overflow: 'auto',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
      maxHeight: 420,
      border: `solid 2px ${theme.colors.grey.offWhite3}`,
    },
  },
  divider: {
    backgroundColor: theme.colors.grey.offWhite3,
    margin: theme.spacing(2, 0),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(4, 0),
    },
  },
  eyeIcon: {
    color: theme.colors.grey.grey1,
  },
  selectableButtonRoot: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    boxSizing: 'border-box',
    background: 'white',
    border: `solid 2px ${theme.colors.grey.offWhite3}`,
    borderRadius: 6,
    transition: 'border-color 200ms ease',
    padding: theme.spacing(1, 1, 1, 2),
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      background: theme.colors.grey.offWhite2,
    },
    '& + &': {
      marginTop: theme.spacing(1.5),
    },
  },
  selected: {
    border: `solid 2px transparent`,
    background: `${theme.colors.grey.offWhite2} !important`,
  },
}));

export type DisclosuresListSelection = { [key: number]: boolean };

export interface SelectDisclosuresListProps {
  forms: PackageForm[]; // we need the disclosure & association names from PackageForm
  selection: DisclosuresListSelection;
  onSelect: (form: PackageForm) => void;
}

const initializeSelectedIds = (forms: PackageForm[], selectedIds: number[]) =>
  Object.assign({}, ...forms.map((f) => ({ [f.interviewId]: selectedIds.includes(f.interviewId) })));

export const useDisclosuresListSelection = (
  forms: PackageForm[],
  selectedIds: number[]
): { selection: DisclosuresListSelection; onSelect: (form: PackageForm) => void } => {
  const [selectedInterviewIds, setSelectedInterviewIds] = useState<{ [key: number]: boolean }>(
    initializeSelectedIds(forms, selectedIds)
  );

  const onSelect = useCallback((form: PackageForm) => {
    setSelectedInterviewIds((current) => ({
      ...current,
      [form.interviewId]: !current[form.interviewId],
    }));
  }, []);

  useEffect(() => {
    if (selectedIds) {
      setSelectedInterviewIds(initializeSelectedIds(forms, selectedIds));
    }
  }, [selectedIds, forms]);

  return useMemo(
    () => ({
      selection: selectedInterviewIds,
      onSelect,
    }),
    [selectedInterviewIds, onSelect]
  );
};

export const SelectDisclosuresList: React.FC<SelectDisclosuresListProps> = ({ forms, selection, onSelect }) => {
  const classes = useStyles();
  return (
    <List classes={{ root: classes.listRoot }}>
      {forms.map((form) => {
        const isSelected = selection[form.interviewId] ?? false;
        return (
          <SelectableButton
            className={clsx(classes.selectableButtonRoot, isSelected && classes.selected)}
            selectBorderPosition={{ x: -2, y: -2 }}
            checked={isSelected}
            component="label"
            key={form.name}
          >
            <ListItemIcon>
              <Checkbox
                checked={isSelected}
                onChange={() => onSelect(form)}
                name={form.name}
                color="primary"
                value={form.interviewId}
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Grid container alignItems="center">
                  <Box flex="1 1 0px" className={classes.disclosureName}>
                    {form.name}
                    <DisclosuresAssociationName associationName={form.associationName} />
                  </Box>
                </Grid>
              }
            />
          </SelectableButton>
        );
      })}
    </List>
  );
};

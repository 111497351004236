import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, Collapse, List, alpha } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useRecoilState } from 'recoil';
import { AddProps } from 'src/common/AddProps';
import { isNavMenuOpenAtom } from 'src/state/navMenuAtom';

const useStyles = makeStyles<Theme, { isExpanded: boolean }>(
  (theme: Theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    background: {
      backgroundColor: (p) => (p.isExpanded ? alpha(theme.colors.grey.bodyHeader, 0.05) : 'transparent'),
      borderRadius: theme.spacing(0.5),
      overflow: 'hidden',
      transition: 'background-color 250ms',
    },
    childWrapper: {
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
    },
  }),
  { classNamePrefix: 'NavigationFolder' }
);

export interface NavigationFolderProps {
  isMenuOpen?: boolean;
  folderMenuItem?: React.ReactNode;
}

/**
 * A folder in the primary navigation where two or more nav items should be
 * grouped together.
 */
export const NavigationFolder: React.FC<NavigationFolderProps> = (props) => {
  const { children, isMenuOpen, folderMenuItem } = props;
  const [navMenuOpen, setNavMenuOpen] = useRecoilState(isNavMenuOpenAtom);

  const [isOpen, setIsOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(isOpen);
  const classes = useStyles({ isExpanded });

  useEffect(() => {
    if (!isMenuOpen) setIsOpen(false);
  }, [isMenuOpen]);

  const renderedChildCount = React.Children.toArray(children).filter((x) => x).length;
  if (renderedChildCount <= 1) {
    return children as never;
  }

  return (
    <div className={clsx(classes.root)}>
      <div className={classes.background}>
        <AddProps
          to={folderMenuItem}
          onClick={() => setIsOpen((open) => !open)}
          key={isOpen as never}
          endIcon={isOpen ? <ExpandLess /> : <ExpandMore />}
          className={classes.childWrapper}
          disableBottomMargin
        />

        <Collapse
          in={isOpen}
          onEntering={() => {
            setIsExpanded(true);
            if (!navMenuOpen) setNavMenuOpen(true);
          }}
          onExited={() => setIsExpanded(false)}
        >
          <List component="div" disablePadding className={classes.childWrapper} style={{ marginTop: 16 }}>
            {children}
          </List>
        </Collapse>
      </div>
    </div>
  );
};

import { makeStyles, Theme, Typography, Container, Divider, Box } from '@material-ui/core';
import React from 'react';
import breezeIconAgent from 'src/images/breezeLogoAgent.svg';
import breezeIcon from 'src/images/breezeIcon.svg';
import { LandingFooter } from 'src/pages/Landing/components';
import { useIsMobile } from '../hooks';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh',
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  header: {
    width: '100%',
    height: 70,
    borderBottom: `1px solid ${theme.colors.grey.disable}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#ffffff',
  },
  headerLeft: {
    display: 'flex',
    marginLeft: 32,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      width: '100%',
      // Bring this back if we bring back the contact/about links
      // justifyContent: 'space-around',
    },
  },
  headerButton: {
    marginRight: 32,
    fontWeight: 'bold',
    fontSize: 16,
    lineHeight: '22px',
    padding: '9px 31px',
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  headerButtonRight: {
    marginRight: 32,
    fontWeight: 'bold',
    color: theme.colors.grey.bodyHeader,
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  icon: {
    height: theme.spacing(5),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(3.75),
      marginRight: theme.spacing(1.5),
    },
  },
  companyLogo: {
    height: theme.spacing(5),
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(3.75),
      marginLeft: theme.spacing(1.5),
    },
  },
  companyFavIcon: {
    height: theme.spacing(3.5),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(1),
  },
  companyFavIconWrapper: {
    marginLeft: theme.spacing(3),
  },
  mobileCompanyText: {
    fontSize: `${19 / 16}rem`,
    fontFamily: 'Avenir Next Demi Bold',
    color: theme.colors.grey.bodyHeader,
    whiteSpace: 'nowrap',
  },
  formsText: {
    marginLeft: 8,
    color: theme.colors.grey.offBlack,
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  drawer: {
    minWidth: 280,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: 16,
    justifyContent: 'flex-end',
  },
  menuButton: {
    marginLeft: 16,
  },
}));

interface LandingFrameProps {
  isCoMarketing: boolean;
  companyLogo: string | undefined;
  companyFavIcon: string | undefined;
  children: React.ReactNode;
}

export const LandingFrame: React.FC<LandingFrameProps> = ({ isCoMarketing, companyLogo, companyFavIcon, children }) => {
  const classes = useStyles();
  const isMobile = useIsMobile();

  return (
    <div className={classes.root}>
      <header className={classes.header}>
        <Container maxWidth="xl" className={classes.headerWrapper}>
          <div className={classes.headerLeft}>
            {((isMobile && !isCoMarketing) || !isMobile) && (
              <img src={breezeIconAgent} className={classes.icon} alt="Forms" />
            )}
            {isCoMarketing && !isMobile && (
              <>
                <Box width="100%" display="flex">
                  <Divider orientation="vertical" />
                  <img src={companyLogo} className={classes.companyLogo} alt="coMarketingLogo" />
                </Box>
              </>
            )}
            {isCoMarketing && isMobile && (
              <>
                <Box display="flex" alignItems="center">
                  <img src={breezeIcon} className={classes.companyFavIcon} alt="coMarketingFavIcon" />
                  <Typography className={classes.mobileCompanyText}>Breeze</Typography>
                </Box>
                <Box display="flex" alignItems="center" className={classes.companyFavIconWrapper}>
                  <Divider orientation="vertical" />
                  <img src={companyFavIcon} className={classes.companyFavIcon} alt="coMarketingFavIcon" />
                </Box>
              </>
            )}
          </div>
        </Container>
      </header>
      {children}
      <LandingFooter />
    </div>
  );
};

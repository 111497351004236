import { useQuery, UseQueryOptions } from 'react-query';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { AuthType, config } from 'src/lib/constants';
import { TemplateStatus, usStates, GetEnumKeyByValue } from 'src/lib';

type ValueDefinition = {
  type: 'string' | 'object';
  value?: string | { [key: string]: ValueDefinition };
  validatorKey?: string;
};

export type DisclosureDefinition = {
  interviewTemplateId: number;
  name: string;
  associationName: string;
  formId: number;
  authType?: AuthType;
  additionalAnswers:
    | {
        [stepName: string]: ValueDefinition;
      }
    | string[];
  tags: {
    form: string[];
    role: string[];
    state: string[];
    status: string[];
    association: string[];
  };
  defaulted: boolean;
};

export const getSklosuresDisclosuresByRegionUrlKey = 'sklosures-disclosures-by-region';

const createDisclosureUrlGetter =
  (region: usStates | undefined): (() => Promise<DisclosureDefinition[]>) =>
  async () => {
    const url = `${config.sklosuresServiceUrl}/templates/summary`;
    const params = {};
    if (region) {
      params['_state'] = GetEnumKeyByValue(usStates, region);
    }
    return (
      await axios.get<DisclosureDefinition[]>(url, {
        params,
      })
    ).data
      ?.filter(
        (template) => template.tags.status[0] === TemplateStatus.LIVE || template.tags.status[0] === TemplateStatus.NEW
      )
      .map((template) => {
        // TODO: Can remove this when all sklosures templates are on config V2 format,
        // but for now convert the V1 array style to the V2 object style
        const v2Answers = Array.isArray(template.additionalAnswers)
          ? template.additionalAnswers.reduce((map, current) => {
              const type = current === 'propertyAddress' ? 'object' : 'string';
              map[current] = { type };
              return map;
            }, {})
          : template.additionalAnswers;
        return {
          ...template,
          additionalAnswers: v2Answers,
        };
      });
  };

export const useGetDisclosuresByRegion = (
  region: usStates | undefined = undefined,
  options: UseQueryOptions<DisclosureDefinition[]> = {}
) =>
  useQuery([getSklosuresDisclosuresByRegionUrlKey, region], createDisclosureUrlGetter(region), {
    ...options,
    onError(e) {
      console.error('An error occurred while getting sklosures templates');
      Sentry.captureException(e);
      options?.onError?.(e);
    },
  });

import { atom } from 'recoil';
import { createSessionStorageEffect } from 'src/state/utils';
import { usStates } from 'src/lib';

type AtomType = usStates | null;
const storageKey = 'breeze-selected-region';
const writeToSessionStorageEffect = createSessionStorageEffect<AtomType>(storageKey);

/**
 * This state stores the region selected on the landing page and is used to specify an initial
 * region when registering a new account from that flow.
 * The Registration wizard is a multistep process and this state retains this destination
 * until the wizard is completed.
 */
export const selectedRegionAtom = atom<AtomType>({
  key: storageKey,
  default: null,
  effects_UNSTABLE: [writeToSessionStorageEffect],
});

import { AuthType, isUserAuthenticatedForType } from 'src/lib';
import { useGetUserProfileMeta } from './useGetUserProfileMeta';

export interface isStateLibraryAuthenticated {
  isAuthenticated: boolean;
  isLoading: boolean;
}

/**
 * This will determine whether or not the user has a valid auth profile of the specified type
 */
export const useIsUserAuthenticatedForType = (authType: AuthType) => {
  const { data: meta, isLoading } = useGetUserProfileMeta();
  return {
    isAuthenticated: isUserAuthenticatedForType(meta, authType),
    isLoading,
  };
};

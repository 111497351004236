import React from 'react';
import { makeStyles, Theme, Box, Container, Grid, Button, withStyles } from '@material-ui/core';
import { Pagination, Skeleton } from '@material-ui/lab';
import { useHistory } from 'react-router';
import { TayHeadingWithWorkflows, FixedHeaderLayout, NothingHereCard, Spacer } from 'src/common';
import { WizardHeader, SelectAgentDropdown } from 'src/domain';
import { useGetBreezeRole, useIsMedium } from 'src/hooks';
import { doesRoleRequireAuth, PAGINATION_PACKAGES_PER_PAGE } from 'src/lib';
import { CustomBanner } from 'src/common/CustomBanner';
import { DashboardItem } from '../types';
import { DashboardFile } from './DashboardFile';
import { DashboardFilesGridHeader } from './DashboardFilesGridHeader';
import { useDashboardColumnSort } from './sort-util';
import { useDashboardFileFilters } from './filters';

const useStyles = makeStyles(
  (theme: Theme) => ({
    topSection: {
      marginTop: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(5),
      },
    },
    mainContent: {
      backgroundColor: theme.colors.grey.offWhite1,
      minHeight: '100%',
      '&::before': {
        backgroundColor: theme.colors.grey.offWhite1,
        content: '""',
        display: 'block',
        width: '100%',
        boxShadow: `0 -1px 0 0 ${theme.colors.grey.offWhite2}`,
        height: 80,
        marginTop: -80,
        [theme.breakpoints.up('sm')]: {
          height: 116,
          marginTop: -116,
        },
      },
    },
    fileWrapper: {
      margin: `${theme.spacing(1)}px 0`,
      [theme.breakpoints.up('sm')]: {
        margin: `${theme.spacing(2)}px 0`,
      },
    },
    pagination: {
      marginBottom: -116,
      display: 'block',
      width: 'fit-content',
      [theme.breakpoints.up('sm')]: {
        marginBottom: 64,
      },
      '&::after': {
        backgroundColor: theme.colors.grey.offWhite1,
      },
    },
    paginationCaption: {
      color: 'white',
      text: 'white',
    },
  }),
  { classNamePrefix: 'DashboardView' }
);

const StyledPagination = withStyles({
  root: {
    '& .Mui-selected': {
      color: 'white',
    },
  },
})(Pagination);

export interface DashboardViewProps {
  items?: DashboardItem[];
  onItemClick: (fileId: number) => void;
}

export const DashboardView: React.FC<DashboardViewProps> = ({ items, onItemClick }) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(1);

  const history = useHistory();
  const isDesktop = !useIsMedium();
  const { data: breezeRole } = useGetBreezeRole();

  const dashboardFiltering = useDashboardFileFilters(items);
  const {
    sortColumn,
    sortDirection,
    handleSortClick,
    sortedItems: sortedAndFilteredItems,
    getNextSortTooltip,
  } = useDashboardColumnSort(dashboardFiltering.filteredItems);
  const handlePagination = (_: any, value: any) => {
    setPage(value);
  };
  return (
    <>
      <CustomBanner />
      <FixedHeaderLayout
        headerContent={<WizardHeader floatRight={isDesktop && <SelectAgentDropdown style={{ marginRight: 32 }} />} />}
      >
        <Container maxWidth="md" className={classes.topSection}>
          <TayHeadingWithWorkflows title="How would you like to get started?" />
        </Container>
        <Spacer axis="vertical" size={4} />
        <div className={classes.mainContent}>
          <Container maxWidth="md">
            <DashboardFilesGridHeader
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              onSortClick={handleSortClick}
              getNextSortTooltip={getNextSortTooltip}
              propertyCount={sortedAndFilteredItems?.length}
              dashboardFiltering={dashboardFiltering}
            />

            <Grid item container xs={12}>
              {sortedAndFilteredItems
                ?.slice(
                  (page - 1) * PAGINATION_PACKAGES_PER_PAGE,
                  (page - 1) * PAGINATION_PACKAGES_PER_PAGE + PAGINATION_PACKAGES_PER_PAGE
                )
                .map((item, index) => (
                  <Grid item xs={12} key={item.fileId} className={classes.fileWrapper}>
                    <DashboardFile
                      item={item}
                      onClick={() => onItemClick(item.fileId)}
                      chmlnAgentNameId={index === 0 ? 'first-initials-in-list' : undefined}
                    />
                  </Grid>
                ))}
              {Number(items?.length) > 0 && sortedAndFilteredItems?.length === 0 && (
                <NothingHereCard
                  title="Oops!  No results found"
                  subtitle="Try changing your search terms (or who you're working on behalf of) and try again"
                />
              )}
              {items?.length === 0 && (
                <>
                  {doesRoleRequireAuth(breezeRole) ? (
                    <NothingHereCard
                      title="Looks like you don't have anything here!"
                      subtitle="Choose an option above to get started."
                    />
                  ) : (
                    <NothingHereCard
                      title="Invite your team to start sharing and creating files on behalf of others."
                      button={
                        <Button variant="contained" color="primary" onClick={() => history.push('/team')}>
                          Get Started
                        </Button>
                      }
                    />
                  )}
                </>
              )}
              {!items && (
                <Box width="100%">
                  <Skeleton variant="rect" height={700} />
                </Box>
              )}
              {sortedAndFilteredItems.length > 0 && (
                <Box mx="auto" className={classes.pagination}>
                  <StyledPagination
                    className={classes.paginationCaption}
                    count={Math.ceil(sortedAndFilteredItems.length / PAGINATION_PACKAGES_PER_PAGE)}
                    color="primary"
                    page={page}
                    onChange={handlePagination}
                    showFirstButton
                    showLastButton
                  />
                </Box>
              )}
            </Grid>
          </Container>
        </div>
      </FixedHeaderLayout>
    </>
  );
};

import { RefObject, useEffect, useRef, useState } from 'react';

export const useHover = <ElementType extends HTMLElement>(): [RefObject<ElementType>, boolean] => {
  const [value, setValue] = useState(false);

  const ref = useRef<ElementType>(null);

  const handleMouseOver = (): void => setValue(true);
  const handleMouseOut = (): void => setValue(false);

  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener('mouseover', handleMouseOver);
        node.addEventListener('mouseleave', handleMouseOut);
        // ADA
        node.addEventListener('focus', handleMouseOver);
        node.addEventListener('focusout', handleMouseOut);

        return () => {
          node.removeEventListener('mouseover', handleMouseOver);
          node.removeEventListener('mouseleave', handleMouseOut);
          // ADA
          node.removeEventListener('focus', handleMouseOver);
          node.removeEventListener('focusout', handleMouseOut);
        };
      } else {
        return;
      }
    },
    [] // Recall only if ref changes
  );

  return [ref, value];
};

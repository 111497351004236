import React, { ReactNode, useState } from 'react';
import pluralize from 'pluralize';
import { Grid, Typography, Button, Box, makeStyles, Theme } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { ArrowForwardIos } from '@material-ui/icons';
import { AvidRoom } from '../../../types';
import { useIsMobile } from '../../../../../hooks';
import { RoomItem } from './RoomItem';
import { getRoomIcon } from './getRoomIcon';

export interface RoomGroupProps {
  display: string;
  rooms: AvidRoom[];
  icon: ReactNode;
  onAddNotes: (room: AvidRoom) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  arrowIcon: {
    height: 16,
    width: 16,
    color: theme.colors.grey.bodyHeader,
  },
}));

export const RoomGroup: React.FC<RoomGroupProps> = ({ display, rooms, icon, onAddNotes }) => {
  const pluralizedDisplay = `${rooms.length > 1 ? `${rooms.length} ` : ''}${pluralize(display, rooms.length)}`;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const classes = useStyles();

  return (
    <>
      {isMobile && !isExpanded && (
        <RoomItem
          display={pluralizedDisplay}
          onClick={rooms.length > 1 ? () => setIsExpanded(true) : () => onAddNotes(rooms[0])}
          icon={icon}
          numHidden={rooms.length - 1}
          isComplete={rooms.every((r) => !!r.notes)}
          actions={
            rooms.length > 1 ? (
              ''
            ) : (
              <Box mt={1}>
                <ArrowForwardIos className={classes.arrowIcon} />
              </Box>
            )
          }
        />
      )}

      {isMobile && isExpanded && (
        <Grid item container xs={12} sm={4} alignItems="center">
          <Box flexShrink={1} flexBasis="auto" flexGrow={1}>
            <Typography variant="body2">{display}</Typography>
          </Box>
          <Button endIcon={<ExpandLess />} onClick={() => setIsExpanded(false)} color="primary" size="small">
            SHOW LESS
          </Button>
        </Grid>
      )}

      {isMobile &&
        isExpanded &&
        rooms.map((r) => (
          <RoomItem
            onClick={() => onAddNotes(r)}
            key={r.name}
            display={r.name}
            icon={getRoomIcon(r.roomType)}
            isComplete={!!r.notes}
            actions={
              <Box mt={1}>
                <ArrowForwardIos className={classes.arrowIcon} />
              </Box>
            }
          />
        ))}
      {!isMobile &&
        rooms.map((r) => (
          <RoomItem
            key={r.name}
            display={r.name}
            icon={getRoomIcon(r.roomType)}
            isComplete={!!r.notes}
            data-testid={`room-${r.roomType}`}
            actions={
              <Box mb={1} mt={1}>
                <Button
                  variant="contained"
                  size="small"
                  color={r.notes ? 'default' : 'primary'}
                  disableElevation
                  onClick={() => onAddNotes(r)}
                  data-testid="add-notes-btn"
                >
                  {r.notes ? 'Edit Notes' : 'Add Notes'}
                </Button>
              </Box>
            }
          />
        ))}
    </>
  );
};

export const SpqTdsBaseRoute = 'disclosures';

export enum SpqTdsDisclosureSteps {
  email = 'email',
  login = 'login',
  recoverPassword = 'recover-password',
  recoverPasswordSuccess = 'recover-password-success',
  loginSkyslope = 'login-skyslope',
  pickForms = 'pick-forms',
  selectOnBehalfOfAgent = 'select-on-behalf-of-agent',
  addTeamMember = 'add-team-member',
  property = 'property',
  missingPropertyInfo = 'missing-property-info',
  sellers = 'sellers',
  phone = 'phone',
  register = 'register',
  credentials = 'credentials',
  apn = 'apn',
  yearBuilt = 'year-built',
  fireHazard = 'fire-hazard',
  escrowChooseOfficer = 'escrow-officer',
  escrowComposeEmail = 'escrow-email',
  escrowAddOfficer = 'add-escrow-officer',
  escrowAddExistingOfficer = 'add-existing-escrow-officer',
  manufacturingDetails = 'manufacturing-details',
  done = 'done',
  review = 'review',
  editFileTeamMembers = 'team-for-file',
  addContactTeamMember = 'add-contact',
  teamMembersDeprecated = 'team-members',
  sending = 'sending',
  licenseNumber = 'license-number',
  isUsingExistingAvid = 'existing-avid',
  requestSignaturesEmail = 'request-signatures-email',
  tdsSection3 = 'send-envelope-options',
  uploadAvid = 'upload-avid',
  addNotes = 'add-notes',
  sendingEnvelope = 'sending-envelope',
  selectRegion = 'select-region',
  brokerageName = 'broker-name',
  zoning = 'zoning',
  customEmail = 'custom-email',
}

/* eslint-disable */
// @ts-nocheck

// TODO: can we make this stronger in terms of typing and remove the nocheck and eslint-disable?

export const chameleonInit = (key: string, adminKey: string, chameleonProperties: string[]): void => {
  if (
    window.chmln ||
    (window.chmln = {}) ||
    (window.chmln[adminKey] && (window.chmln[adminKey] = !1)) ||
    !window.chmln.root
  ) {
    window.chmln.accountToken = key;
    window.chmln.location = window.location.href.toString();
    window.chmln.now = new Date();
    for (let i = 0; i < chameleonProperties.length; i++) {
      const t = (window.chmln[`${chameleonProperties[i]}_a`] = []);
      window.chmln[chameleonProperties[i]] = function () {
        // eslint-disable-next-line prefer-rest-params
        t.push(arguments);
      };
      const script = document.createElement('script');
      script.src = `https://fast.trychameleon.com/messo/${key}/messo.min.js`;
      script.async = !0;
      document.head.appendChild(script);
    }
  }
};

import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { AvidSteps } from '../AvidCreation';
import { useGetAvidPackage } from '../hooks';
import { MAX_AVID_CREATION_PROGRESS } from '../AvidCreation/useAvidCreationProgress';
import { avidDetailsSteps } from './AvidDetailsWizard';

export const useAvidDetailsProgress = (): number => {
  const { stepName, packageId } = useParams<{ stepName: string; packageId: string }>();
  const currentAvidStepIndex = avidDetailsSteps.findIndex((step) => step.key === stepName);
  const { data: avidPackage } = useGetAvidPackage(packageId);
  const location = useLocation<{ nextGoToStep?: AvidSteps; animateForward?: boolean }>();
  const calculateRelativeProgress = (stepIndex: number) =>
    (100 - MAX_AVID_CREATION_PROGRESS) * (stepIndex / avidDetailsSteps.length) + MAX_AVID_CREATION_PROGRESS;

  return useMemo(() => {
    const reviewStepIndex = avidDetailsSteps.findIndex((step) => step.key === AvidSteps.review);
    if (!avidPackage) {
      return MAX_AVID_CREATION_PROGRESS;
    }

    if (avidPackage.workflowData.isReviewed) {
      return 100;
    }

    if (location.state?.nextGoToStep === AvidSteps.review) {
      return calculateRelativeProgress(reviewStepIndex);
    }

    return calculateRelativeProgress(currentAvidStepIndex);
  }, [currentAvidStepIndex, avidPackage, location]);
};

import { makeStyles, Theme } from '@material-ui/core';
import React, { lazy, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useAuthStore } from 'src/lib/auth/store';
import { Step } from 'src/common';
import { WizardStepProps } from 'src/domain';
import {
  useCreateDisclosuresPackage,
  ContactInput,
  sendPackageMutationKey,
  useLoadingStatuses,
  useGetUserProfile,
  BrokerageInput,
  useFlags,
  SklosuresPackageConfig,
} from 'src/hooks';
import { useGetActiveUserProfile } from 'src/hooks/domain/queries/useGetActiveUserProfile';
import { selectedAgentAtom, emailStateAtom } from 'src/state';
import { contactTypes, contactActions } from 'src/lib';
import { useMlsImportToFile } from 'src/hooks/domain/mutations/useMlsImportToFile';
import { AddressInput } from '../../FilePackages/types';
import { useDisclosuresStore } from '../store';

const LottieAnimation = lazy(() => import('src/common/LottieAnimation'));

const useStyles = makeStyles((theme: Theme) => ({
  statusContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 200,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 340,
    },
  },
}));

const loadingStatuses = [
  'Let us get that for you. Be right back!',
  'Creating your package...',
  'Sending your documents...',
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SpqTdsSending = (props: WizardStepProps) => {
  const classes = useStyles();
  const store = useDisclosuresStore();
  const authStore = useAuthStore();
  const history = useHistory();

  const carLink = `https://www.car.org/skyslopeexit?gid=${authStore.userId}`;
  const { data: activeUserProfile } = useGetActiveUserProfile();
  const { data: userProfile } = useGetUserProfile();
  const selectedAgent = useRecoilValue(selectedAgentAtom);
  const [emailState, setEmailState] = useRecoilState(emailStateAtom);

  const { currentStatus, isLoading } = useLoadingStatuses({
    mutationKey: sendPackageMutationKey,
    onDone: () => history.push(`/file/${store.existingFileId}/packages`),
    loadingStatuses,
  });

  const { mutate } = useCreateDisclosuresPackage();
  const { mutate: importMlsToFile } = useMlsImportToFile();
  const { isSignerQuestionFlaggingEnabled, isResolveFlagEnabled } = useFlags();

  useEffect(() => {
    if (activeUserProfile == null || store.property == null || isLoading) {
      return;
    }
    if (authStore.shownPopup) {
      setTimeout(() => {
        window.open(
          carLink,
          '_blank',
          'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400'
        );
      }, 1500);
    }
    const interviewTemplateIds = (store.forms ?? [])
      .filter((f) => f.interviewTemplateId != null)
      .map((f) => f.interviewTemplateId as number);

    const contacts = store.teamMembers as ContactInput[];
    if (selectedAgent) {
      contacts.push({
        type: contactTypes.OTHER,
        firstName: userProfile?.firstName ?? '',
        lastName: userProfile?.lastName ?? '',
        email: userProfile?.email ?? '',
        actionAttribute: contactActions.RECEIVESCOPY,
        isEntity: false,
        companyName: '',
      });
    }

    const packageConfig = {
      signerQuestionFlaggingEnabled: isSignerQuestionFlaggingEnabled,
      signerResolveFlagEnabled: isResolveFlagEnabled,
    } as SklosuresPackageConfig;

    // TODO: This definitely isn't the right solution.
    // Right now sklosures will stamp the brokerage name on the documents if its
    // provided, however, the real stamp value is set per association. I think the
    // correct solution is to always supply this value but update sklosures-api
    // to use the StampFormat value from forms to build the correct stamp on a
    // per-form basis automatically.
    const brokerage =
      store.property.state === 'AZ' && userProfile?.brokerageName
        ? ({ name: userProfile.brokerageName } as BrokerageInput)
        : undefined;

    mutate(
      {
        interviewTemplateIds,
        apn: store.apn,
        yearBuilt: store.yearBuilt,
        property: store.property as AddressInput,
        isFireHazard: store.isFireHazard,
        zoning: store.zoning,
        sellers: store.sellers.map((c) => {
          const copy = { ...c };
          // for whatever reason, even though the id is of type `number`
          // we have to coerce it to actually be passed as one. Shane
          // had mentioned this awhile back.
          if (copy.id) copy.id = parseInt(String(copy.id));
          return copy;
        }) as ContactInput[],
        contacts: contacts,
        existingFileId: store.existingFileId,
        onBehalfOfInterview: {
          firstName: activeUserProfile.firstName,
          lastName: activeUserProfile.lastName,
          email: activeUserProfile.email,
          organizationID: 1,
          phone: activeUserProfile.primaryPhoneNumber,
          licenseNumber: activeUserProfile.licenseNumber,
          oktaUserId: activeUserProfile.id,
        },
        brokerage,
        emailDetails: {
          subject: emailState?.subject?.toString(),
          to: emailState?.recipients[0]?.toString(),
          cc: emailState?.ccRecipients.filter((r) => !!r).map((r) => r?.toString() ?? ''),
          emailContents: {
            body: emailState?.body?.toString() ?? '',
            signature: emailState?.signature?.toString() ?? '',
          },
        },
        config: packageConfig,
      },
      {
        onSuccess: (result) => {
          store.update({ existingFileId: result.fileId });

          if (store.mlsUuid) {
            importMlsToFile({ uid: store.mlsUuid, fileId: result.fileId, shouldOverride: false });
          }
          history.push(`/file/${result.fileId}/packages`);
          // Clear email state after we submit because we don't want the custom message to reappear when starting new disclosures
          setEmailState(null);
        },
      }
    );

    // eslint-disable-next-line
  }, [userProfile]);

  return (
    <Step title={"You're almost done!"}>
      <div className={classes.statusContainer}>
        <LottieAnimation animation="surferBoi" />
      </div>
      <Typography variant="body2" className={classes.statusContainer}>
        {currentStatus}
      </Typography>
    </Step>
  );
};

import React, { useEffect, useState, useCallback } from 'react';
import { Grid, IconButton, Menu } from '@material-ui/core';
import { MoreHoriz as MoreHorizIcon } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { useHistory } from 'react-router';
import { downloadTypes } from 'src/lib';
import { useIsMobile, useAnchorEl, useQueryParams } from 'src/hooks';
import { useDisclosuresStore } from '../../../SpqTdsDisclosures/store';
import { PackageActionMap, PackageForm, PackageInterview, PackageStatus } from '../../types';
import { CancelInterviewsDialog } from '../CancelInterviewsDialog';
import { useFilePackageItemActions } from './useFilePackageItemActions';
import { MobilePopupMenu } from './MobilePopupMenu';
import { useDownloadDisclosuresMenuItems } from './useDownloadDisclosuresMenuItems';
import { DesktopActionButtons } from './DesktopActionButtons';

export interface FilePackageItemActionsProps {
  packageStatus?: PackageStatus;
  actions: PackageActionMap;
  interviews: PackageInterview[] | undefined;
  forms: PackageForm[];
  isLoading: boolean;
  packageId: string;
  name?: string;
  fileName?: string;
  isAvidFlowCompleted?: boolean;
  onSelectForm: (form: PackageForm) => void;
  packageType: string;
  envelopeId?: string;
  hideSignNowOption?: boolean;
}

export const FilePackageItemActions: React.FC<FilePackageItemActionsProps> = (props) => {
  const { forms, isLoading, packageId } = props;

  const { onDownloadPackageMerged, onDownloadPackageZip, downloadType } = useFilePackageItemActions(props);

  const [isCancelInterviewsOpen, setIsCancelInterviewsOpen] = useState(false);
  const isMobile = useIsMobile();

  const queryParams = useQueryParams();
  const history = useHistory();

  const {
    anchorEl: mobileAnchorEl,
    isOpen: isMobileMenuOpen,
    close: closeMobileMenu,
    handleClick: openMobileMenu,
  } = useAnchorEl();

  const {
    anchorEl: downloadMenuAnchorEl,
    isOpen: isDownloadMenuOpen,
    close: closeDownloadMenu,
    handleClick: openDownloadMenu,
  } = useAnchorEl();

  const spqTdsStore = useDisclosuresStore();

  const downloadDisclosuresMenuItems = useDownloadDisclosuresMenuItems({
    ...props,
    onMenuItemClick: useCallback(() => {
      closeMobileMenu();
      closeDownloadMenu();
    }, [closeMobileMenu, closeDownloadMenu]),
  });

  useEffect(() => {
    if (downloadType && packageId) {
      if (downloadType === downloadTypes.MERGED) {
        onDownloadPackageMerged();
        queryParams.delete('downloadType');
      } else if (downloadType === downloadTypes.ZIP) {
        onDownloadPackageZip();
        queryParams.delete('downloadType');
      }
      spqTdsStore.update({
        downloadType: '',
        uploadedDocuments: [],
      });
      history.replace({
        search: queryParams.toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadType]);

  if (isLoading) return <Grid component={Skeleton} width={150} item />;

  return (
    <>
      {!isMobile && (
        <DesktopActionButtons
          {...props}
          setIsCancelInterviewsOpen={setIsCancelInterviewsOpen}
          openDownloadMenu={openDownloadMenu}
        />
      )}
      {isMobile && (
        <>
          <div style={{ margin: '4px 4px 0 auto' }}>
            <IconButton
              aria-label="open menu"
              aria-controls="mobile-actions-menu"
              aria-haspopup="true"
              onClick={openMobileMenu}
              style={{ padding: '12px' }}
            >
              <MoreHorizIcon />
            </IconButton>
          </div>
          <MobilePopupMenu
            {...props}
            id="mobile-actions-menu"
            open={isMobileMenuOpen}
            onClose={closeMobileMenu}
            anchorEl={mobileAnchorEl}
            onMenuItemClick={closeMobileMenu}
            setIsCancelInterviewsOpen={setIsCancelInterviewsOpen}
          >
            {downloadDisclosuresMenuItems}
          </MobilePopupMenu>
        </>
      )}
      <Menu open={isDownloadMenuOpen} onClose={closeDownloadMenu} anchorEl={downloadMenuAnchorEl}>
        {downloadDisclosuresMenuItems}
      </Menu>
      {isCancelInterviewsOpen && (
        <CancelInterviewsDialog packageId={packageId} forms={forms} onClose={() => setIsCancelInterviewsOpen(false)} />
      )}
    </>
  );
};

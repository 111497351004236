import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { getApiToken, config, Contact } from 'src/lib';
import { getFilesByIdQueryKey } from '../queries';

type EditContactsPayload = {
  fileId: number;
  contacts: Contact[];
};

export const useEditContacts = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (payload: EditContactsPayload) => {
      const token = await getApiToken();

      return await Promise.all(
        payload.contacts.map((contact) =>
          axios({
            method: 'PUT',
            url: `${config.formsApiUrl}/api/contacts/${contact.id}`,
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
            data: contact,
          })
        )
      );
    },
    {
      onSuccess(result, variables) {
        queryClient.invalidateQueries([getFilesByIdQueryKey, variables.fileId]);
      },
    }
  );
};

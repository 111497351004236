import { useMutation, useQueryClient } from 'react-query';
import { gql } from 'graphql-request';
import { EmailDetailsInput } from '../../../hooks';
import { getGraphQLClient, getApiToken } from '../../../lib';
import { getPackagesByFileIdKey } from '../queries/useGetFilePackages';
import { getDashboardPackagesByFileIdKey } from '../queries/useGetDashboardPackages';

const mutationQuery = gql`
  mutation ResendPackageInterviews(
    $packageId: String!
    $specificInterviewIds: [Int]!
    $emailDetails: SklosuresEmailDetailModel
  ) {
    resendPackageInterviews(
      packageId: $packageId
      specificInterviewIds: $specificInterviewIds
      emailDetails: $emailDetails
    )
  }
`;

export interface ResendInterviewsVariables {
  packageId: string;
  specificInterviewIds?: number[];
  emailDetails?: EmailDetailsInput;
}

export const useResendInterviews = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (variables: ResendInterviewsVariables) => {
      const client = getGraphQLClient(await getApiToken());
      await client.request(mutationQuery, variables);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([getPackagesByFileIdKey]);
        queryClient.invalidateQueries([getDashboardPackagesByFileIdKey]);
      },
    }
  );
};

import React, { useRef, useState } from 'react';
import { makeStyles, Modal, ModalProps, Theme, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from 'react-query';
import { GetEscrowOfficerListQueryResult } from 'src/hooks';
import { SearchInput, SearchInputProps } from './SearchInput';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'fixed',
      top: 0,
      left: 0,
      bottom: 0,
      borderBottom: '3px solid blue',
      width: '100%',
      background: 'white',
      boxSizing: 'border-box',

      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    form: {
      flex: 0,
      display: 'flex',
      alignItems: 'flex-start',
      gap: theme.spacing(2),
      padding: theme.spacing(2),
    },
    input: {
      flex: 1,
    },
    cancelButton: {
      background: theme.colors.grey.offWhite3,
      width: 48,
      height: 48,
    },
    searchResults: {
      flex: 1,
      overflowY: 'scroll',
      paddingBottom: '50vh',
    },
  }),
  { classNamePrefix: 'MobileSearchPopup' }
);

export type RenderProps = {
  isKeyboardOpen: boolean;
  closeKeyboard(): void;
};

export interface MobileSearchPopupProps extends SearchInputProps {
  open: ModalProps['open'];
  onClose(): void;
  children: React.ReactNode | ((renderProps: RenderProps) => React.ReactNode);
  hasNextPage: boolean;
  fetchNextPage(
    options?: FetchNextPageOptions | undefined
  ): Promise<InfiniteQueryObserverResult<GetEscrowOfficerListQueryResult, unknown>>;
}

export function MobileSearchPopup(props: MobileSearchPopupProps) {
  const classes = useStyles();
  const { open, onClose, children, ...rest } = props;

  const rootRef = useRef<HTMLDivElement>(null);
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

  function closeKeyboard() {
    // Focusing an element that is not the input closes the keyboard
    rootRef.current?.focus();
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.root} ref={rootRef}>
        <form
          className={classes.form}
          onSubmit={(e) => {
            e.preventDefault();
            closeKeyboard();
          }}
        >
          <SearchInput
            {...rest}
            className={classes.input}
            inputProps={{
              onFocus: () => setIsKeyboardOpen(true),
              onBlur: () => {
                // For some reason I don't understand, without the timeout
                // this can cause button clicks that blur this input to be
                // ignored.
                setTimeout(() => setIsKeyboardOpen(false), 100);
              },
            }}
          />
          <IconButton className={classes.cancelButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </form>

        <div className={classes.searchResults}>
          {typeof children === 'function' ? children({ isKeyboardOpen, closeKeyboard }) : children}
        </div>
      </div>
    </Modal>
  );
}

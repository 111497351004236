const getFirstLetter = (str?: string) => str && str[0];

export const getInitials = (person: {
  firstName?: string;
  lastName?: string;
  email?: string;
  firstAndLastName?: string;
}) => {
  const { firstName, lastName, firstAndLastName, email } = person;

  if (firstName && lastName) {
    return `${getFirstLetter(firstName)}${getFirstLetter(lastName)}`;
  }

  if (firstAndLastName) {
    return firstAndLastName?.split(' ').map(getFirstLetter).join('');
  }

  return email ? email.substr(0, 2) : '??';
};

export const combineNames = (...names: Array<string | null | undefined>) => names.filter((name) => name).join(' ');

import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { WizardStepProps } from 'src/domain';
import { RoomBuckets } from '../../../types';
import { AvidSteps } from '../../../AvidCreation';
import { useGetAvidPackage, useGetAvidRoomOptions, useUpdateAvidPackage } from '../../../hooks';
import { HomeBaseEdit, RoomCounts } from './HomeBaseEdit';
import { reconcileRoomType } from './reconcileRoomType';

export const AvidHomeChoose = (props: WizardStepProps) => {
  const { data: options } = useGetAvidRoomOptions();
  const { packageId } = useParams<{ packageId: string }>();
  const { mutate } = useUpdateAvidPackage();
  const { data: avidPackage } = useGetAvidPackage(packageId);
  const rooms = avidPackage?.workflowData.rooms;
  const { goToStep } = props;

  const buckets = useMemo(
    () =>
      rooms?.reduce<RoomBuckets>((accumulator, currentRoom) => {
        if (!accumulator[currentRoom.roomType]) {
          accumulator[currentRoom.roomType] = [];
        }
        accumulator[currentRoom.roomType].push(currentRoom);

        return accumulator;
      }, {}) ?? {},
    [rooms]
  );

  const optionLookup = useMemo(
    () => Object.assign({}, ...(options?.map((o) => ({ [o.roomType]: o })) ?? [])),
    [options]
  );

  const handleRoomEdit = useCallback(
    (roomCounts: RoomCounts) => {
      let newRooms = rooms?.slice() ?? [];
      Object.entries(roomCounts).forEach(([roomType, count]) => {
        newRooms = reconcileRoomType(roomType, count, newRooms, optionLookup);
      });

      mutate(
        {
          packageId: packageId!,
          rooms: newRooms,
        },
        {
          onSuccess: () => {
            goToStep(AvidSteps.rooms);
          },
        }
      );
    },
    [rooms, packageId, mutate, optionLookup, goToStep]
  );

  return (
    <>
      <HomeBaseEdit
        rooms={rooms ?? []}
        options={options ?? []}
        buckets={buckets}
        onSave={handleRoomEdit}
        onCancel={() => goToStep(AvidSteps.contacts)}
        isInitialCreateFlow
      />
    </>
  );
};

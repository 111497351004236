import React from 'react';
import clsx from 'clsx';
import { Theme, makeStyles, ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core';

const useStyles = makeStyles<Theme, NavigationMenuRowProps>(
  (theme: Theme) => ({
    wrapper: {
      display: 'flex',
      height: theme.spacing(5),
      paddingLeft: 0,
      paddingRight: theme.spacing(2),
      marginBottom: (p) => (p.disableBottomMargin ? undefined : theme.spacing(2)),
      textDecoration: 'none',
      color: theme.colors.grey.bodyHeader,
      border: 'none',
      background: 'transparent',
    },
    selectedIndicator: {
      height: theme.spacing(5),
      width: 3,
      borderRadius: '0 3px 3px 0',
      backgroundColor: theme.colors.mainColor.primary,
      position: 'fixed',
    },
    hidden: {
      visibility: 'hidden',
    },
    listItemIcon: {
      fontSize: 20,
    },
    listItemIconSelected: {
      color: theme.colors.grey.bodyHeader,
    },
    listItemRoot: {
      marginLeft: theme.spacing(1.75),
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      borderRadius: 3,
    },
    listItemRootClosed: {
      maxWidth: theme.spacing(6),
    },
    listItemSelected: {
      backgroundColor: '#DDE5E8 !important',
    },
  }),
  { classNamePrefix: 'NavigationMenuRow' }
);

export interface NavigationMenuRowProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  name: string;
  icon?: JSX.Element;
  isSelected?: boolean;
  ariaLabel?: string;
  id: string;
  isMenuOpen?: boolean;
  to?: string;
  endIcon?: React.ReactNode;
  disableBottomMargin?: boolean;
  openNewTab?: boolean;
}

export const NavigationMenuRow: React.FC<NavigationMenuRowProps> = (props) => {
  const classes = useStyles(props);
  const {
    name,
    icon,
    isSelected,
    ariaLabel,
    onClick,
    id,
    isMenuOpen = false,
    to,
    endIcon,
    className,
    openNewTab,
  } = props;

  const Root = to ? 'a' : 'button';

  return (
    <Root
      className={clsx(classes.wrapper, className)}
      href={to}
      target={openNewTab ? '_blank' : undefined}
      rel={openNewTab ? 'noopener noreferrer' : undefined}
    >
      <span
        className={clsx(classes.selectedIndicator, {
          [classes.hidden]: !isSelected,
        })}
      />
      <Tooltip
        title={name}
        placement="left-end"
        open={isMenuOpen ? false : undefined}
        disableHoverListener={isMenuOpen}
      >
        <ListItem
          id={id}
          data-spec="list-item"
          button
          selected={isSelected}
          classes={{
            root: clsx(classes.listItemRoot, { [classes.listItemRootClosed]: !isMenuOpen }),
            selected: classes.listItemSelected,
          }}
          disableGutters
          onClick={onClick}
          aria-label={ariaLabel ? ariaLabel : `Navigate to ${name} sub menu`}
        >
          <ListItemIcon className={clsx(classes.listItemIcon, { [classes.listItemIconSelected]: isSelected })}>
            {icon}
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              variant: isSelected ? 'body2' : 'body1',
              color: isSelected ? 'textPrimary' : 'initial',
              noWrap: true,
            }}
          >
            {isMenuOpen && name}
          </ListItemText>
          {isMenuOpen && endIcon}
        </ListItem>
      </Tooltip>
    </Root>
  );
};

import axios from 'axios';
import { config } from '../constants';

export type AccountAction = 'legacy' | 'none' | 'register';
export type AccountActionNextRoute = { [action in AccountAction]: string };

export const getAccountAction = async (email: string): Promise<AccountAction> => {
  const res = await axios({
    method: 'POST',
    url: `${config.accountsUrl}/api/users/check`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: { username: email },
  });
  return res.data.action;
};

import { atom } from 'recoil';
import { createSessionStorageEffect } from 'src/state/utils';

type AtomType = boolean | null;
const storageKey = 'breeze-send-to-forms';
const writeToSessionStorageEffect = createSessionStorageEffect<AtomType>(storageKey);

export const sendingDisclosuresToFormsAtom = atom<AtomType>({
  key: storageKey,
  default: false,
  effects_UNSTABLE: [writeToSessionStorageEffect],
});

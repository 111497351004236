import React from 'react';
import { Button, Theme, makeStyles } from '@material-ui/core';
import { useHover } from 'src/hooks';
import { HtmlButtonProps } from 'src/lib';

const useStyles = makeStyles((theme: Theme) => ({
  boxButton: {
    padding: theme.spacing(2, 3),
    maxWidth: 180,
    border: '2px solid',
    borderColor: (p: RepTypeButtonProps) => (p.isActive ? theme.colors.mainColor.primary : theme.colors.grey.grey1),
    color: (p: RepTypeButtonProps) => (p.isActive ? theme.colors.mainColor.primary : theme.colors.grey.bodyHeader),
    backgroundColor: 'white',
    transition: 'none',
    fontSize: 16,
    textTransform: 'none',
    fontWeight: 'bold',
    boxShadow: 'none',
    '&:hover, &:focus': {
      backgroundColor: 'white',
      borderColor: theme.colors.mainColor.primary,
      color: theme.colors.mainColor.primary,
      boxShadow: 'none',
    },
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 140,
      maxHeight: 158,
      marginBottom: 0,
    },
  },
  buttonLabel: {
    display: 'flex',
    flexDirection: 'column',
  },
  icon: {
    width: 120,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      width: 100,
      marginBottom: theme.spacing(2),
    },
  },
}));

interface RepTypeButtonProps extends HtmlButtonProps {
  isActive: boolean;
  defaultImage: string;
  hoverImage: string;
  children: string;
}

export const RepTypeButton = (props: RepTypeButtonProps) => {
  const { isActive, defaultImage, hoverImage, children, ...rest } = props;

  const classes = useStyles(props);
  const [hoverRef, isHovered] = useHover<HTMLButtonElement>();

  return (
    <Button
      {...rest}
      ref={hoverRef}
      variant="contained"
      color="primary"
      classes={{ root: classes.boxButton, label: classes.buttonLabel }}
    >
      <img src={isActive || isHovered ? hoverImage : defaultImage} className={classes.icon} alt={children} />
      {children}
    </Button>
  );
};

import React, { useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles';
import { Controller, useController, useForm, useWatch } from 'react-hook-form';
import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import { Address, getCountiesByState } from 'src/lib';
import { TextInput, Step, Navigation } from 'src/common';
import { useGetLiveRegions } from 'src/hooks';

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    position: 'relative',
    marginBottom: 32,
  },
  county: {
    width: '100%',
  },
  error: {
    color: theme.colors.error,
    fontSize: '16px',
    marginTop: '4px',
    fontWeight: 700,
  },
  counties: {
    color: 'inherit',
    fill: 'inherit',
    fontWeight: 600,
  },
}));

export interface AddressForm {
  property: Address;
}

export interface AddressProps {
  defaultProperty: Address;
  onSubmit: (form: AddressForm) => void;
}

export const MissingPropertyInfo: React.FC<AddressProps> = ({ defaultProperty, onSubmit }) => {
  const classes = useStyles();
  const { register, errors, control, handleSubmit, trigger } = useForm<AddressForm>({
    mode: 'all',
    defaultValues: {
      property: defaultProperty,
    },
  });
  const property = useWatch<Address>({ control, name: 'property' });
  const counties = getCountiesByState(property!.state);
  const states = useGetLiveRegions();
  const countyController = useController({
    name: 'property.county',
    control,
    rules: { required: 'Please select a county.' },
  });

  useEffect(() => {
    trigger();
  }, [trigger]);

  return (
    <Step title="Looks like you’re missing some property details. Please fill out the following!">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} className={classes.row}>
          <Grid item md={8} sm={9} xs={12}>
            <TextInput
              label="Street Address"
              name="property.streetAddress"
              inputRef={register({ required: 'Please enter this field.' })}
              error={errors.property?.streetAddress}
            />
          </Grid>
          <Grid item md={4} sm={3} xs={12}>
            <TextInput label="Unit #" name="property.unitNumber" inputRef={register()} />
          </Grid>
          <Grid item md={3} sm={9} xs={12}>
            <TextInput
              label="City"
              name="property.city"
              inputRef={register({ required: 'Please enter this field.' })}
              error={errors.property?.city}
            />
          </Grid>
          <Grid item md={2} sm={3} xs={12}>
            <Controller
              name="property.state"
              control={control}
              rules={{ required: 'Please select a state.' }}
              render={({ onChange, value, onBlur }, { invalid }) => (
                <FormControl error={invalid} variant="outlined" className={classes.county}>
                  <InputLabel id="state-select">State</InputLabel>
                  <Select
                    classes={{ root: classes.counties, icon: classes.counties }}
                    labelId="state-select"
                    value={value}
                    onChange={(e) => {
                      countyController.field.onChange('');
                      onChange(e);
                    }}
                    onBlur={onBlur}
                    label="State"
                  >
                    {states?.map((state) => (
                      <MenuItem key={state} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            {errors.property?.state && (
              <Typography variant="body1" className={classes.error}>
                {errors.property!.state.message}
              </Typography>
            )}
          </Grid>
          <Grid item md={3} sm={4} xs={12}>
            <TextInput
              label="Postal Code"
              name="property.postalCode"
              inputRef={register({ required: 'Please enter this field.' })}
              error={errors.property?.postalCode}
            />
          </Grid>
          <Grid item md={4} sm={8} xs={12}>
            <FormControl error={countyController.meta.invalid} variant="outlined" className={classes.county}>
              <InputLabel id="county-select">County</InputLabel>
              <Select
                classes={{ root: classes.counties, icon: classes.counties }}
                labelId="county-select"
                value={countyController.field.value}
                onChange={countyController.field.onChange}
                onBlur={countyController.field.onBlur}
                label="County"
              >
                {counties.map((county) => (
                  <MenuItem key={county} value={county}>
                    {county}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {errors.property?.county && (
              <Typography variant="body1" className={classes.error}>
                {errors.property!.county.message}
              </Typography>
            )}
          </Grid>
        </Grid>
        <Navigation
          PrevButtonProps={{ hidden: true }}
          NextButtonProps={{
            disabled: Object.keys(errors).length > 0 || !property?.county,
          }}
        />
      </form>
    </Step>
  );
};

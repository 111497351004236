import React, { useState } from 'react';
import { InputAdornment } from '@material-ui/core';
import { SearchSharp } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';

import { useFilter } from 'src/hooks';
import { combineNames, sortPendingLast, TeamMember } from 'src/lib';
import { NothingHereCard, Spacer, TextInput } from 'src/common';
import { TeamMemberList } from './TeamMemberList';
import { TeamMemberListItem } from './TeamMemberListItem';
import { NoTeamMembers } from './NoTeamMembers';

export interface TeamMemberFilterListProps {
  selectMultipleTeamMembers?: boolean;
  onAddTeamMember(): void;
  teamMembers: Partial<TeamMember>[];
  isLoading?: boolean;
  selectedTeamMembers: TeamMember[];
  setSelectedTeamMembers(selected: TeamMember[]): void;
  id?: string;
  /** Prevent pending team members from being selected */
  disablePending?: boolean;
  /** Tooltip text displayed on the chip for pending team members */
  pendingTooltip?: string;
}

export const TeamMemberFilterList: React.FC<TeamMemberFilterListProps> = (props) => {
  const {
    selectMultipleTeamMembers,
    onAddTeamMember,
    teamMembers,
    isLoading,
    selectedTeamMembers,
    setSelectedTeamMembers,
    id,
    disablePending,
    pendingTooltip,
  } = props;

  const [filterText, setFilterText] = useState('');
  const filteredTeamMembers = useFilter({
    filterText,
    searchKeys: ['email', 'firstName', 'lastName'],
    data: teamMembers,
  });

  return (
    <div>
      {isLoading && <Skeleton height={300} style={{ transform: 'none' }} />}
      {!isLoading && teamMembers.length === 0 && <NoTeamMembers onAddTeamMember={onAddTeamMember} />}
      {!isLoading && teamMembers.length > 0 && (
        <>
          <TextInput
            placeholder="Search Your Team"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchSharp />
                </InputAdornment>
              ),
            }}
          />
          <Spacer axis="vertical" size={4} />

          {/* This list deals only with the emails of the team members */}
          <TeamMemberList
            id={id}
            onAddTeamMember={onAddTeamMember}
            value={selectedTeamMembers.map((tm) => (tm ? tm.email : tm))}
            onValueChange={(selectedEmails) => {
              const membersByEmail = teamMembers.reduce((accum, curr) => {
                if (curr.email) accum[curr.email] = curr;
                return accum;
              }, {});

              const chosenTeamMembers = selectedEmails.map((email) => (email ? membersByEmail[email] : email));
              setSelectedTeamMembers(chosenTeamMembers);
            }}
            allowMultiple={selectMultipleTeamMembers}
            addNewTeamMemberBtnText={teamMembers.length ? 'Add a new teammate' : 'Add a teammate'}
          >
            {!filteredTeamMembers.length && (
              <NothingHereCard title="Whoops, none of your team members match that search" />
            )}
            {filteredTeamMembers.sort(sortPendingLast).map((tm) => (
              <TeamMemberListItem
                key={tm.email}
                title={combineNames(tm.firstName, tm.lastName)}
                subtitle={tm.email}
                value={tm.email!}
                isPending={!tm.isApproved}
                disablePending={disablePending}
                pendingTooltip={pendingTooltip}
              />
            ))}
          </TeamMemberList>
        </>
      )}
    </div>
  );
};

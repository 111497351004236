import React from 'react';
import clsx from 'clsx';
import {
  Button,
  makeStyles,
  Theme,
  Dialog,
  DialogProps,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  ButtonProps,
  PaperProps,
} from '@material-ui/core';
import { useIsMobile } from '../hooks';
import { DialogCloseButton } from './DialogCloseButton';
import { LoaderButton, LoaderButtonProps } from './LoaderButton';

const useStyles = makeStyles((theme: Theme) => ({
  dialogTitleRoot: {
    padding: theme.spacing(5, 3, 1, 3),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(5, 6, 2, 6),
    },
  },
  dialogContentRoot: {
    padding: theme.spacing(1, 3, 2, 3),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2, 6, 4, 6),
    },
  },
  title: {
    textAlign: 'left',
    fontSize: '2rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
    },
  },
  subtitle: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  paperWidthMd: {
    maxWidth: 720,
  },
  actionsRoot: {
    border: `1px solid ${theme.colors.grey.disable}`,
    backgroundColor: theme.colors.grey.offWhite3,
    padding: theme.spacing(2, 6),
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      position: 'fixed',
      bottom: 0,
      zIndex: 2,
      left: 0,
      right: 0,
      flexDirection: 'column-reverse',
      marginBottom: 0,
      padding: theme.spacing(1, 3),
    },
  },
  actionButton: {
    fontSize: 16,
    lineHeight: '22px',
    letterSpacing: 'normal',
    textAlign: 'center',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    padding: theme.spacing(0.5, 1.5),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1, 3),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
      justifyContent: 'center',
      width: '100%',
      fontSize: '1.1875rem',
    },
    '&$disabled': {
      color: theme.colors.grey.label,
    },
  },
  secondaryActionButton: {
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(2),
    },
  },
}));

export interface StdDialogProps extends DialogProps {
  title?: string;
  subtitle?: string;
  onClose: () => void;
  actions?: React.ReactNode;
  /** Set the component used to render the body of the Dialog */
  component?: PaperProps['component'];
  classes?: DialogProps['classes'] & {
    titleRoot?: string;
    title?: string;
    subtitle?: string;
    content?: string;
    actions?: string;
  };
}

export const StdDialog = (props: StdDialogProps) => {
  const {
    children,
    title,
    subtitle,
    onClose,
    actions,
    component,
    PaperProps = {},
    classes: userClasses = {},
    ...rest
  } = props;
  const classes = useStyles();
  const isMobile = useIsMobile();

  return (
    <Dialog
      {...rest}
      onClose={onClose}
      classes={{ ...userClasses, paperWidthMd: clsx(classes.paperWidthMd, userClasses.paperWidthMd) }}
      fullWidth
      maxWidth="md"
      fullScreen={isMobile}
      PaperProps={{
        ...PaperProps,
        component: component || PaperProps.component,
      }}
    >
      <DialogCloseButton onClick={onClose} />
      <DialogTitle disableTypography classes={{ root: clsx(classes.dialogTitleRoot, userClasses.titleRoot) }}>
        {title && (
          <Typography variant="h1" className={clsx(classes.title, userClasses.title)}>
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography variant="h3" className={clsx(classes.subtitle, userClasses.subtitle)}>
            {subtitle}
          </Typography>
        )}
      </DialogTitle>
      <DialogContent classes={{ root: clsx(classes.dialogContentRoot, userClasses.content) }}>{children}</DialogContent>
      <DialogActions classes={{ root: clsx(classes.actionsRoot, userClasses.actions) }}>{actions}</DialogActions>
    </Dialog>
  );
};

const StdDialogPrimaryActionButton: React.FC<LoaderButtonProps> = ({ classes = {}, ...rest }) => {
  const styles = useStyles();

  return (
    <LoaderButton
      classes={{
        ...classes,
        root: clsx(styles.actionButton),
      }}
      color="primary"
      variant="contained"
      size="medium"
      {...rest}
    />
  );
};

StdDialog.PrimaryActionButton = StdDialogPrimaryActionButton;

const StdDialogSecondaryActionButton: React.FC<ButtonProps> = ({
  classes = {},
  variant = 'text',
  color = 'primary',
  ...rest
}) => {
  const styles = useStyles();

  return (
    <Button
      classes={{
        ...classes,
        root: clsx(styles.actionButton, styles.secondaryActionButton),
      }}
      color={color}
      size="medium"
      variant={variant}
      {...rest}
    />
  );
};

StdDialog.SecondaryActionButton = StdDialogSecondaryActionButton;

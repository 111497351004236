import { atom } from 'recoil';
import { createSessionStorageEffect } from 'src/state/utils';

type AtomType = string | null;
const storageKey = 'breeze-register-destination';
const writeToSessionStorageEffect = createSessionStorageEffect<AtomType>(storageKey);

/**
 * This state stores the intended destination when a new user register with the solution
 * The Registration wizard is a multistep process and this state retains this destination
 * until the wizard is completed.
 */
export const registerDestinationAtom = atom<AtomType>({
  key: storageKey,
  default: null,
  effects_UNSTABLE: [writeToSessionStorageEffect],
});

import React from 'react';
import { Button, makeStyles, Theme, Typography } from '@material-ui/core';
import { parseISO, lightFormat } from 'date-fns';
import { ListItemCard, StatusChip, InitialsCircle, ListItemCardProps } from '../../common';
import { useCustomSnackbar, EmailType, useSendAccessEmail, useIsMobile } from '../../hooks';
import { UserDelegate } from './TeamPage';
import { ActionType } from './types';

const formatDate = (isoDateStr: string) => {
  if (!isoDateStr) return '';
  return lightFormat(parseISO(isoDateStr), 'MM/dd/yy hh:mm a');
};

const useStyles = makeStyles<Theme, CollaboratorProps>(
  (theme: Theme) => ({
    root: {
      margin: `${theme.spacing(1)}px 0`,
      [theme.breakpoints.up('sm')]: {
        margin: `${theme.spacing(2)}px 0`,
      },
    },
    content: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        gap: theme.spacing(2),
        alignItems: 'normal',
        flexDirection: 'column',
      },
    },
    contentButtons: {
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        gap: theme.spacing(2),
        flexDirection: 'column',
        width: '100%',
      },
    },
    chip: {
      textTransform: 'none',
    },
  }),
  { classNamePrefix: 'Collaborator' }
);

const InitialsBadge: React.FC<CollaboratorProps> = ({ userDelegate }) => {
  const initials =
    userDelegate?.firstName && userDelegate?.lastName
      ? userDelegate.firstName.substr(0, 1) + userDelegate.lastName.substr(0, 1)
      : userDelegate?.email?.substr(0, 2);

  return <InitialsCircle>{initials}</InitialsCircle>;
};

export interface CollaboratorProps extends Omit<ListItemCardProps, 'title'> {
  userDelegate?: UserDelegate;
  onApproveInvite?(): void;
  onCancelInvite(actionType: ActionType): void;
  onRemoveDelegate(actionType: ActionType): void;
  shareVariant?: 'approval' | 'pending';
  requestVariant?: 'pending' | undefined;
}

export const Collaborator: React.FC<CollaboratorProps> = (props) => {
  const classes = useStyles(props);
  const { userDelegate, onApproveInvite, onCancelInvite, onRemoveDelegate, shareVariant, requestVariant, ...rest } =
    props;
  const { mutate: sendReminderEmail } = useSendAccessEmail();
  const snackbarController = useCustomSnackbar();
  const isMobile = useIsMobile();
  const isShareOrRequestApproved = userDelegate?.isRequestApproved || userDelegate?.isShareApproved;
  const showChip =
    userDelegate?.isShareApproved === false || (!userDelegate?.isRequestApproved && requestVariant === 'pending');

  const title = isShareOrRequestApproved
    ? `${userDelegate?.firstName || ''} ${userDelegate?.lastName || ''}`
    : userDelegate?.email;

  const subtitle = isShareOrRequestApproved
    ? userDelegate?.email
    : userDelegate
    ? `Date Sent: ${formatDate(userDelegate.createdAt || userDelegate.updatedAt)}`
    : '';

  const handleSendReminder = () => {
    if (userDelegate?.email) {
      sendReminderEmail({
        delegate: { email: userDelegate.email },
        emailType: EmailType.REMINDER,
      });
      snackbarController.addSuccessSnackbar(`Reminder sent to ${userDelegate.email}`);
    }
  };

  const createMenu = (actionType: ActionType) => {
    const isSharing = actionType === ActionType.SHARE;
    const isRequesting = actionType === ActionType.REQUEST;

    const isApproved = isSharing ? userDelegate?.isShareApproved : userDelegate?.isRequestApproved;
    if (isApproved) {
      return (
        <Button variant="outlined" color="primary" size="small" onClick={() => onRemoveDelegate(actionType)}>
          Remove
        </Button>
      );
    }

    if (isSharing && shareVariant === 'approval' && userDelegate?.isShareApproved === false) {
      return (
        <>
          <Button
            variant={isMobile ? 'outlined' : 'text'}
            color="primary"
            size="small"
            style={{ marginRight: isMobile ? 0 : 16 }}
            onClick={() => onCancelInvite(actionType)}
          >
            Decline
          </Button>
          <Button variant="outlined" color="primary" size="small" onClick={onApproveInvite}>
            Approve
          </Button>
        </>
      );
    }

    const isSharePending = isSharing && shareVariant === 'pending';
    const isRequestPending = isRequesting && requestVariant === 'pending';
    if (isSharePending || isRequestPending) {
      return (
        <>
          <Button
            variant={isMobile ? 'outlined' : 'text'}
            color="primary"
            size="small"
            style={{ marginRight: isMobile ? 0 : 16 }}
            onClick={handleSendReminder}
          >
            Resend
          </Button>
          <Button variant="outlined" color="primary" size="small" onClick={() => onCancelInvite(actionType)}>
            Cancel Request
          </Button>
        </>
      );
    }

    return undefined;
  };

  const shareMenuItems = createMenu(ActionType.SHARE);
  const requestMenuItems = createMenu(ActionType.REQUEST);

  return (
    <ListItemCard
      {...rest}
      title={title}
      subtitle={subtitle}
      activity={showChip && <StatusChip className={classes.chip}>Pending</StatusChip>}
      className={classes.root}
      profilePicture={isShareOrRequestApproved && !isMobile && <InitialsBadge {...props} />}
      hideActionButton={userDelegate?.isGroupUser}
      shareMenu={
        shareMenuItems && (
          <div className={classes.content}>
            <Typography>Sharing your files</Typography>
            <div className={classes.contentButtons}>{shareMenuItems}</div>
          </div>
        )
      }
      requestMenu={
        requestMenuItems && (
          <div className={classes.content}>
            <Typography>Viewing their files</Typography>
            <div className={classes.contentButtons}>{requestMenuItems}</div>
          </div>
        )
      }
    />
  );
};

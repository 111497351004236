import React, { lazy } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useIsMobile } from 'src/hooks';
import LoginWidget from 'src/domain/login/LoginWidget';
import TreeClouds from '../../../images/treeCloudsAlt.svg';

const LottieAnimation = lazy(() => import('src/common/LottieAnimation'));

const useStyles = makeStyles((theme: Theme) => ({
  rightContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    paddingBottom: theme.spacing(4.5),
    marginBottom: theme.spacing(-4.5),
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(4, 0),
    paddingRight: '100px',
    minHeight: '680px',
  },
  mainText: {
    fontSize: theme.typography.pxToRem(56),
    lineHeight: `${theme.spacing(9)}px`,
    color: theme.colors.grey.bodyHeader,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(48),
      lineHeight: `${theme.spacing(6)}px`,
      marginBottom: theme.spacing(4),
    },
  },
  subText: {
    margin: theme.spacing(2, 0),
    fontSize: `${24 / 16}rem`,
    color: theme.colors.grey.bodyHeader,
    letterSpacing: '0.16px',
    lineHeight: `${theme.spacing(4.5)}px`,
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3),
    },
  },
  cloudWrapper: {
    backgroundImage: `url(${TreeClouds})`,
    backgroundRepeat: 'no-repeat',
    backgroundOrigin: 'border-box',
    paddingTop: theme.spacing(20),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(4),
    },
  },
  animation: {
    width: '850px',
    position: 'absolute',
    margin: theme.spacing(5, 0, 0, -25),
  },
}));

export const LandingMainTextArea: React.FC = () => {
  const classes = useStyles();
  const isMobile = useIsMobile();

  return (
    <div id="treeWrapper">
      {!isMobile ? (
        <Grid container>
          <Grid item md={6} className={classes.leftContainer}>
            <Typography className={classes.mainText} variant="h2" component="div">
              Breeze simplifies seller disclosures
            </Typography>
            <Typography className={classes.subText} variant="subtitle2" component="div">
              You work around the clock to make sure your clients&rsquo; experience is the best it can be. Now, Breeze
              can help your clients through seller disclosures so you can get a little “me-time” back.
            </Typography>
            <div className={classes.animation}>
              <LottieAnimation animation="ladyChillinLeft" />
            </div>
          </Grid>
          <Grid item md={6} className={classes.rightContainer}>
            <LoginWidget />
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid item>
            <Typography className={classes.mainText} variant="h2" component="div">
              Breeze through Disclosures.
            </Typography>
            <LoginWidget />
            <Typography className={classes.subText} variant="subtitle2" component="div">
              You work around-the-clock making sure your clients’ experience is the best it can be. Now with Breeze,
              speed through disclosures and get a little “me-time” back.
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

import { InputAdornment, makeStyles } from '@material-ui/core';
import React, { useState, useMemo } from 'react';
import MailIcon from '@material-ui/icons/MailOutline';
import { useForm } from 'react-hook-form';
import { useAuth } from '@skyslope/auth-react';
import { ForgotPasswordOpts, RecoveryFactor } from '@skyslope/auth-js';
import { useAuthStore, emailRegex } from 'src/lib';
import { Step, TextInput, Navigation } from 'src/common';

const useStyles = makeStyles(() => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  loginText: {
    textAlign: 'center',
    marginBottom: 24,
  },
  input: {
    marginTop: 24,
  },
  resetPasswordError: {
    color: 'red',
    marginTop: 10,
    fontSize: '18px',
  },
}));

type Inputs = {
  email: string;
};

export interface RecoverProps {
  relayUrl: string;
  onSuccess: () => void;
  onBack: () => void;
}

export const RecoverPassword = (props: RecoverProps) => {
  const classes = useStyles();
  const [isFetching, setIsFetching] = useState(false);
  const authStore = useAuthStore();
  const { update: updateAuthStore, userInfo } = authStore;
  const { register, handleSubmit, errors } = useForm({ mode: 'onSubmit', defaultValues: authStore });
  const { userManager } = useAuth();
  const [resetError, setResetError] = useState('');

  const link = useMemo(
    () => <div className={classes.resetPasswordError}>{resetError}</div>,
    [classes.resetPasswordError, resetError]
  );

  const forgotPassword = async (email: string) => {
    const fpOpts: ForgotPasswordOpts = {
      username: email,
      factorType: RecoveryFactor.Email,
      relayState: props.relayUrl,
    };
    return userManager.forgotPassword(fpOpts);
  };

  const onSubmit = async (data: Inputs) => {
    setIsFetching(true);
    updateAuthStore({ userInfo: { ...userInfo, email: data.email } });
    await forgotPassword(data.email).then((succeeded: boolean) => {
      setIsFetching(false);
      if (succeeded) {
        setResetError('');
        props.onSuccess();
      } else {
        setResetError('An unexpected error occurred - please contact your administrator');
      }
    });
  };

  return (
    <Step title="Let's help you recover your password quickly!">
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <TextInput
          type="email"
          label="Email"
          name="email"
          inputRef={register({
            required: 'Please enter your email address.',
            pattern: { value: emailRegex, message: 'Please enter a valid email.' },
          })}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MailIcon />
              </InputAdornment>
            ),
          }}
          error={errors.userInfo?.email}
          className={classes.input}
          value={authStore.userInfo?.email}
          helperText={link}
          singleField
        />
        <Navigation
          isLoading={isFetching}
          PrevButtonProps={{ onClick: props.onBack }}
          NextButtonProps={{
            disabled: Object.keys(errors).length > 0,
          }}
        />
      </form>
    </Step>
  );
};

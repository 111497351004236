import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core';
import { useRecoilState } from 'recoil';
import { useIsMedium } from 'src/hooks';
import { NavigationDrawer } from 'src/common/NavigationDrawer';
import { isNavMenuOpenAtom } from 'src/state/navMenuAtom';
import { useCloseMobileMenu } from 'src/hooks/useCloseMobileMenu';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      height: '100vh',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    },
    headerSection: {
      flexShrink: 0,
    },
    contentHeader: {
      flex: '1 1 auto',
      overflow: 'auto',
      [theme.breakpoints.up('lg')]: {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
    contentHeaderClosed: {
      [theme.breakpoints.up('lg')]: {
        marginLeft: theme.spacing(9),
      },
      backgroundColor: theme.colors.grey.offWhite1,
    },
    contentHeaderOpen: {
      [theme.breakpoints.up('lg')]: {
        marginLeft: theme.spacing(37.25),
      },
    },
  }),
  { classNamePrefix: 'FixedHeaderLayout' }
);

export interface TwoHeaderLayoutProps {
  headerContent: React.ReactNode | React.ReactNodeArray;
}

export const FixedHeaderLayout: React.FC<TwoHeaderLayoutProps> = ({ headerContent, children }) => {
  const classes = useStyles();
  const isDesktop = !useIsMedium();
  const [nevMenuOpen] = useRecoilState(isNavMenuOpenAtom);
  useCloseMobileMenu();

  return (
    <div className={classes.root}>
      <div className={classes.headerSection}>{headerContent}</div>
      {(isDesktop || nevMenuOpen) && <NavigationDrawer />}
      <div
        className={clsx(classes.contentHeader, nevMenuOpen ? classes.contentHeaderOpen : classes.contentHeaderClosed)}
      >
        {children}
      </div>
    </div>
  );
};

import React from 'react';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import Flag from '@material-ui/icons/Flag';

const useStyles = makeStyles((theme: Theme) => ({
  flagContainer: {
    padding: 0,
    marginRight: theme.spacing(1),
  },
  flagNewButton: {
    marginRight: '0px',
    minWidth: theme.spacing(5),
    minHeight: theme.spacing(5),
    borderWidth: '1px',
    borderColor: `${theme.palette.grey[200]} !important`,
    borderStyle: 'solid',
  },
  flagNewCircleIcon: {
    borderWidth: '10px',
    borderColor: `black`,
    color: '#FF6702',
  },
}));

export const FlagButton = () => {
  const classes = useStyles();
  return (
    <div className={classNames(classes.flagContainer)}>
      <IconButton className={classes.flagNewButton}>
        <Flag className={classes.flagNewCircleIcon} />
      </IconButton>
    </div>
  );
};

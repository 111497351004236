import React from 'react';
import { Close as CloseIcon } from '@material-ui/icons';
import { IconButton, IconButtonProps, makeStyles, Theme } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
  absolute: {
    position: 'absolute',
    right: theme.spacing(1.5),
    top: theme.spacing(1.5),
  },
  closeButton: {
    color: theme.colors.grey.disable,
    padding: 0,
  },
}));

export interface DialogCloseButtonProps extends IconButtonProps {
  'aria-label'?: string;
  absolutePosition?: boolean;
}

export const DialogCloseButton: React.FC<DialogCloseButtonProps> = ({
  className,
  absolutePosition = true,
  ...otherProps
}) => {
  const classes = useStyles();

  return (
    <IconButton
      id="close-icon"
      aria-label="close the dialog"
      {...otherProps}
      className={classNames(
        {
          [classes.closeButton]: true,
          [classes.absolute]: absolutePosition,
        },
        className
      )}
    >
      <CloseIcon aria-hidden="true" />
    </IconButton>
  );
};

import React from 'react';
import { Login, WizardStepProps } from 'src/domain';
import { SpqTdsDisclosureSteps } from './constants';

export const SpqTdsLogin = (props: WizardStepProps) => (
  <Login
    onLogin={() => props.goToStep(SpqTdsDisclosureSteps.property)}
    onBack={() => props.goToStep(SpqTdsDisclosureSteps.email)}
    onForgotPassword={() => props.goToStep(SpqTdsDisclosureSteps.recoverPassword)}
  />
);

import React, { useState, useEffect, useRef, useMemo, RefObject } from 'react';

const DEFAULT_CONFIG: UseDimensionConfig = { on: true, causeReRenders: true };

/**
 * This hooks lets you monitor any dimension of a DOM element.
 *
 * You give it a ref do a DOM element, a function to extract
 * some value from that element, and some optional config. This hook
 * will then monitor the size of that element and spit out a
 * new value when the size of the DOM element changes.
 */
export function useDimension<E extends Element>(
  domRef: RefObject<E>,
  getDimension: (el: E) => number,
  config: UseDimensionConfig = {}
): [number | null, React.RefObject<number>] {
  const { on, causeReRenders } = { ...DEFAULT_CONFIG, ...config };

  const [length, setLength] = useState(0);
  const lengthRef = useRef(length);

  const ro = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      new ResizeObserver(() => {
        const el = domRef.current;
        if (!el) return;
        if (getDimension(el) === lengthRef.current) return;

        const currentLength = getDimension(el);
        lengthRef.current = currentLength;
        if (causeReRenders) setLength(currentLength);
      }),
    // Allow getDimension() to be passed as an inline function
    // without extra memoization
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [causeReRenders, domRef]
  );

  // Changed from useLayoutEffect for SSR
  useEffect(() => {
    const currentLength = getDimension(domRef.current!);
    lengthRef.current = currentLength;
    setLength(currentLength);

    if (!on) return;
    ro.observe(domRef.current!, {});
    return () => ro.disconnect();
    // Allow getDimension() to be passed as an inline function
    // without extra memoization
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [on, domRef, ro]);

  return [length, lengthRef];
}

export type UseDimensionConfig = { on?: boolean; causeReRenders?: boolean };

import React from 'react';
import * as Sentry from '@sentry/react';
import { ErrorCodes } from 'src/lib';
import { PackageForm } from '../types';
import { useCancelInterviews, useCustomSnackbar } from '../../../hooks';
import { SelectPackageFormList, usePackageInterviewSelection } from './SelectPackageFormList';
import { PackageActionDialog } from './PackageActionDialog';

export interface CancelDisclosuresDialogProps {
  forms: PackageForm[];
  packageId: string;
  onClose: () => void;
}

export const CancelInterviewsDialog: React.FC<CancelDisclosuresDialogProps> = ({ forms, packageId, onClose }) => {
  const { onSelect, selection } = usePackageInterviewSelection(forms);

  const snackbarController = useCustomSnackbar();
  const { mutate: cancelPackageInterviews } = useCancelInterviews();
  const onCancelInterviews = () =>
    cancelPackageInterviews(
      {
        packageId,
        specificInterviewIds: Object.keys(selection)
          .filter((key) => !!selection[key])
          .map((id) => parseInt(id)),
      },
      {
        onSuccess() {
          onClose();
          snackbarController.addSuccessSnackbar('Your disclosures have been canceled.');
        },
        onError(e) {
          console.error('An error occurred while cancelling package interviews (in cancel dialog)');
          const eventId = Sentry.captureException(e);

          snackbarController.addErrorSnackbar({
            message: 'Your disclosures failed to cancel, please try again.',
            errorCode: ErrorCodes.CancelDisclosurePackageInterviews,
            eventId,
          });
        },
      }
    );

  return (
    <PackageActionDialog
      titleText="Which disclosures would you like to cancel?"
      subtitleText="Cancelling this envelope will remove access for your client(s) to fill or sign."
      onClose={onClose}
      closeText="EXIT"
      onSubmit={onCancelInterviews}
      submitText="CANCEL"
    >
      <SelectPackageFormList forms={forms} selection={selection} onSelect={onSelect} />
    </PackageActionDialog>
  );
};

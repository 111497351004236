import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { Skeleton } from '@material-ui/lab';
import { AgentProfile, selectedAgentAtom } from 'src/state';
import { useGetUserProfile, useTeamMembers } from 'src/hooks';
import { TeamMember } from 'src/lib';
import { Navigation } from 'src/common/Navigation';
import { Step } from 'src/common/Step/Step';
import { WizardStepProps } from '../../Wizard';
import { TeamMemberFilterList } from './TeamMemberFilterList';

export interface SelectOnBehalfOfAgentStepProps extends WizardStepProps {
  title: string;
  onNextClick(selectedTeamMember: AgentProfile | null): void;
  previousStepName: string;
  onAddTeamMember(): void;
  id?: string;
}

export const SelectOnBehalfOfAgentStep: React.FC<SelectOnBehalfOfAgentStepProps> = (props) => {
  const { title, goToStep, previousStepName, onNextClick, onAddTeamMember, id } = props;

  // App global state
  const selectedAgent = useRecoilValue(selectedAgentAtom);
  // Page local state
  const [selectedTeamMembers, setSelectedTeamMembers] = useState<TeamMember[]>(selectedAgent ? [selectedAgent] : []);

  const { data: teamMembers, isLoading: isTeamLoading } = useTeamMembers({ includePending: true });
  const { data: userProfile, isLoading: isUserLoading } = useGetUserProfile();

  const isLoading = isTeamLoading || isUserLoading;

  const allTeamMembers = useMemo(
    () => (userProfile ? [{ userId: userProfile.id, isApproved: true, ...userProfile }, ...teamMembers] : teamMembers),
    [userProfile, teamMembers]
  );

  // Select the first valid team member in the list if no one is
  // currently selected
  useEffect(() => {
    if (!allTeamMembers || selectedTeamMembers.length) return;

    const firstApprovedTeamMember = allTeamMembers.filter((tm) => tm.isApproved)[0];
    if (firstApprovedTeamMember) {
      setSelectedTeamMembers([firstApprovedTeamMember]);
    }
  }, [isLoading, allTeamMembers, selectedTeamMembers]);

  const previous = () => {
    goToStep(previousStepName);
  };

  return (
    <Step title={isLoading ? <Skeleton style={{ width: '100%', height: '8rem' }} /> : title}>
      <TeamMemberFilterList
        onAddTeamMember={onAddTeamMember}
        teamMembers={allTeamMembers}
        isLoading={isLoading}
        selectedTeamMembers={selectedTeamMembers}
        setSelectedTeamMembers={setSelectedTeamMembers}
        id={id}
      />
      <Navigation
        PrevButtonProps={{ onClick: previous, hidden: !previousStepName }}
        NextButtonProps={{
          disabled: selectedTeamMembers.length === 0,
          onClick() {
            const selected = selectedTeamMembers[0];
            const pickedSelf = selected?.email === userProfile?.email;
            onNextClick(pickedSelf ? null : selected);
          },
        }}
      />
    </Step>
  );
};

import React from 'react';
import { AddTeamMemberStep, WizardStepProps } from 'src/domain';
import { AvidSteps } from 'src/pages';

/**
 * In AVID if the user the TC would like to work on behalf of is not currently
 * part of their team, on the work on behalf of page they can select "add team
 * member" and be brought to this page where they can add one or many team
 * members.
 */
export const AvidAddTeamMember: React.FC<WizardStepProps> = (props) => (
  <AddTeamMemberStep {...props} previousStepName={AvidSteps.selectOnBehalfOfAgent} />
);

import React from 'react';
import clsx from 'clsx';
import { Grid, GridProps, makeStyles, Theme, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { SelectableButton, Paper, SelectableButtonProps } from 'src/common';

const useStyles = makeStyles(
  (theme: Theme) => ({
    paper: {
      width: '100%',
      height: '100%',
      '--border': `${theme.spacing(1)}px`,
      borderRadius: 'var(--border)',
      border: `1px solid ${theme.colors.grey.disable}`,
    },
    button: {
      cursor: 'pointer',
      overflow: 'hidden',
      width: '100%',
      height: '100%',
      minHeight: 200,
      borderRadius: 'calc(var(--border) - 1px)',
    },
  }),
  { classNamePrefix: 'EscrowOfficerCard' }
);

export interface EscrowOfficerCardProps extends Omit<GridProps, 'onClick'> {
  isLoading?: boolean;
  SelectableButtonProps?: SelectableButtonProps;
  checked?: SelectableButtonProps['checked'];
  onClick?: SelectableButtonProps['onClick'];
  classes?: {
    root?: string;
    paper?: string;
    button?: string;
    childWrapper?: string;
  };
}

export const EscrowOfficerCard: React.FC<EscrowOfficerCardProps> = (props) => {
  const classes = useStyles();
  const {
    children,
    className,
    isLoading,
    SelectableButtonProps = {},
    checked,
    onClick,
    classes: userClasses = {},
    ...rest
  } = props;
  const theme = useTheme();

  const gridProps: GridProps = {
    item: true,
    xs: 12,
    sm: 6,
    md: 4,
    ...rest,
  };

  if (isLoading) {
    return (
      <Grid {...gridProps} style={{ width: '100%' }}>
        <Skeleton width="100%" style={{ transform: 'none', height: 215, boxSizing: 'border-box' }} />
      </Grid>
    );
  }

  return (
    <Grid {...gridProps} className={clsx(className, userClasses.root)}>
      <Paper
        className={clsx(userClasses.paper, classes.paper)}
        shadowColor={theme.colors.blueForms}
        hoverElevation="medium"
        elevation={checked ? 'medium' : undefined}
      >
        <SelectableButton
          fakeSelectionBorderColor={theme.colors.blueForms}
          className={clsx(
            userClasses.button,
            SelectableButtonProps.className,
            classes.button,
            userClasses.childWrapper
          )}
          component="label"
          checked={checked}
          onClick={onClick}
          {...SelectableButtonProps}
        >
          {children}
        </SelectableButton>
      </Paper>
    </Grid>
  );
};

import { useQuery, UseQueryOptions } from 'react-query';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { getApiToken } from 'src/lib';
import { useQueriesTyped } from '../../useQueriesTyped';

const getDs3DocumentPdfKey = (url: string) => ['ds3-document-pdf', url];

const getDs3DocumentPdf = async (url: string, token: string) => {
  const binary = (
    await axios.get(url!, {
      responseType: 'arraybuffer',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).data;

  return window.URL.createObjectURL(new Blob([binary], { type: 'application/pdf' }));
};

export const useGetDs3DocumentPdfs = (urls: string[] | undefined, options: UseQueryOptions<string> = {}) =>
  useQueriesTyped(
    urls?.map(
      (url): UseQueryOptions<string> => ({
        queryKey: getDs3DocumentPdfKey(url),
        queryFn: async () => {
          const token = await getApiToken();
          return getDs3DocumentPdf(url, token);
        },
        ...options,
      })
    ) ?? []
  );

export const useGetDs3DocumentPdf = (url: string | undefined, options: UseQueryOptions<string | undefined> = {}) =>
  useQuery(
    getDs3DocumentPdfKey(url!),
    async () => {
      const token = await getApiToken();
      return getDs3DocumentPdf(url!, token);
    },
    {
      ...options,
      enabled: !!url && (options.enabled == null || options.enabled),
      onError(e) {
        Sentry.captureException(e);
        console.error('An error occurred while getting DS3 Document PDFs');
        options?.onError?.(e);
      },
    }
  );

import axios from 'axios';

export interface InstanceOptions {
  getAsyncHeaders?: () => Promise<Record<string, string>>;
  defaultHeaders?: Record<string, string>;
  baseUrl?: string;
}

export const createAxiosInstance = ({
  getAsyncHeaders = () => Promise.resolve({}),
  defaultHeaders = {},
  baseUrl,
}: InstanceOptions = {}) => {
  const instance = axios.create({ baseURL: baseUrl, headers: defaultHeaders });

  instance.interceptors.request.use(async (config) => ({
    ...config,
    headers: {
      ...(await getAsyncHeaders()),
      ...config.headers,
    },
  }));

  return instance;
};

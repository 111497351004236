import { TokenDomain, createUserManager, Environment } from '@skyslope/auth-js';
import { history } from 'src/lib';
import { routes, config, ENV, SessionStorageItem } from '../constants';

const OnAuthRequiredCallback = async () => {
  localStorage.removeItem('com.skyslope.logout');
  sessionStorage.setItem(SessionStorageItem.LoginOGPath, window.location.pathname);
  history.push(routes.landing);
};

const userManager = createUserManager({
  environment: ENV as Environment,
  tokenDomain: TokenDomain.Global,
  onAuthRequired: OnAuthRequiredCallback,
  providers: {
    okta: {
      clientId: config.auth.okta.clientId,
      redirectPath: routes.authCallback,
      postLogoutRedirectPath: routes.loggedOut,
      scopes: [
        'openid',
        'email',
        'profile',
        'com.skyslope.prime.subscriber',
        'com.skyslope.groups',
        'com.skyslope.forms.api',
        'com.skyslope.forms.accountid',
        'com.skyslope.digisign.userid',
        'com.skyslope.marketplace.api',
      ],
    },
    primeAuth: {
      clientId: config.auth.primeAuth.clientId,
      redirectPath: routes.primeAuthCallback,
      postLogoutRedirectPath: routes.loggedOut,
    },
  },
  features: {
    disablePageRefresh: false,
  },
});

export { userManager };

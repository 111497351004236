import { useMutation, UseMutationOptions } from 'react-query';
import { gql } from 'graphql-request';
import * as Sentry from '@sentry/react';
import { ErrorCodes, getGraphQLClient, getApiToken } from '../../../lib';
import { useCustomSnackbar } from '../../useCustomSnackbar';

const LOADING_SNACK_KEY = 'loading-snackbar-key';

const mutationQuery = gql`
  mutation ResendPackageEnvelopes($packageId: String!) {
    resendPackageEnvelopes(packageId: $packageId)
  }
`;

export interface ResendEnvelopesVariables {
  packageId: string;
}

export const useResendEnvelopes = (options: UseMutationOptions<unknown, unknown, ResendEnvelopesVariables> = {}) => {
  const snackbarController = useCustomSnackbar();

  return useMutation(
    async (variables: ResendEnvelopesVariables) => {
      const client = getGraphQLClient(await getApiToken());
      await client.request(mutationQuery, variables);
    },
    {
      ...options,
      onMutate: (vars) => {
        if (options.onMutate) {
          return options.onMutate(vars);
        } else {
          snackbarController.addInfoSnackbar('Resending your envelope...', {
            persist: true,
            key: LOADING_SNACK_KEY,
          });
        }
      },
      onSuccess: (data, variables, context) => {
        if (options.onSuccess) {
          options.onSuccess(data, variables, context);
        } else {
          snackbarController.addSuccessSnackbar('Your envelope has been resent.');
        }
      },
      onError: (e, variables, context) => {
        console.error('An error occurred while resending envelopes');
        const eventId = Sentry.captureException(e);

        if (options.onError) {
          options.onError(e, variables, context);
        } else {
          snackbarController.addErrorSnackbar({
            message: 'Your envelope failed to resend, please try again.',
            errorCode: ErrorCodes.ResendEnvelope,
            eventId,
          });
        }
      },
      onSettled: (data, error, variables, context) => {
        if (options.onSettled) {
          options.onSettled(data, error, variables, context);
        } else {
          snackbarController.closeSnackbar(LOADING_SNACK_KEY);
        }
      },
    }
  );
};

import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';
import * as Sentry from '@sentry/react';
import { MlsImportToFileRequest, mlsImportToFile } from '../../../lib/api/files';
import { FileServiceResult } from '../../../lib/types';

/**
 * used to import mls data to the file after creation.
 * does not currently need to invalidate cache since we do not use this data on the File inside Breeze.
 */
export const useMlsImportToFile = (
  options: UseMutationOptions<FileServiceResult<number>, AxiosError, MlsImportToFileRequest> = {}
) =>
  useMutation<FileServiceResult<number>, AxiosError, MlsImportToFileRequest>(
    (request: MlsImportToFileRequest) => mlsImportToFile(request),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        if (options.onSuccess) {
          options.onSuccess(data, variables, context);
        }
      },
      onError(e) {
        console.error('An error occurred while importing MLS to file');
        Sentry.captureException(e);
      },
    }
  );

import { useMutation, UseMutationOptions } from 'react-query';
import axios, { AxiosError } from 'axios';
import { config } from 'src/lib/constants';

export interface CreateMarketingContactPayload {
  email: string;
  firstName: string;
  lastName: string;
  region: string;
}

export const useCreateMarketingContact = (
  options: UseMutationOptions<boolean, AxiosError, CreateMarketingContactPayload> = {}
) =>
  useMutation<boolean, AxiosError, CreateMarketingContactPayload>(async (payload: CreateMarketingContactPayload) => {
    try {
      const result = await axios.post<boolean>(`${config.breezeServiceUrl}/marketingContact`, payload, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      });
      return result.data;
    } catch (err) {
      const message = (err.response && (err.response.data || err.response.statusText)) || err.message;
      throw message;
    }
  }, options);

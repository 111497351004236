import React from 'react';
import { makeStyles, Theme, Typography, TypographyProps } from '@material-ui/core';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      paddingRight: theme.spacing(4),
      minWidth: '60px',
      color: theme.colors.grey.label,
      alignSelf: 'stretch',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        fontSize: '1rem',
      },
    },
  }),
  { classNamePrefix: 'EmailLineLabel' }
);

export interface EmailLineLabelProps extends TypographyProps {
  gridArea?: string;
  inputRef: React.RefObject<HTMLElement>;
}

export const EmailLineLabel: React.FC<EmailLineLabelProps> = (props) => {
  const classes = useStyles();
  const { children, gridArea, inputRef, ...rest } = props;

  return (
    <Typography {...rest} className={classes.root} style={{ gridArea }} onClick={() => inputRef.current?.focus()}>
      {children}
    </Typography>
  );
};

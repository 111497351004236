import { useMemo, useState } from 'react';
import * as Sentry from '@sentry/react';
import { ErrorCodes } from 'src/lib';
import { Ds3Envelope, PackageForm, PackageInterview } from '../types';
import {
  useGetSklosuresSignerPdfForReviewUrls,
  useGetSklosuresSignerPdfForReviewUrl,
  useCustomSnackbar,
  useGetDs3DocumentPdf,
  useGetDs3DocumentPdfs,
  useGetFormsDocumentPdf,
  SklosuresBlock,
} from '../../../hooks';

export interface DocumentPreview {
  interviewId: number;
  url: string;
  formName: string;
  skippedBlockInfo?: SklosuresBlock[];
  flaggedBlockInfo?: SklosuresBlock[];
}

export interface DocumentPreviewApi {
  documentPreview?: DocumentPreview;
  selectPackageForm: (form: PackageForm | undefined) => void;
  isLoading: boolean;
}

export const useDocumentPreview = (
  fileId: number,
  interviews: PackageInterview[] | undefined,
  envelope: Ds3Envelope | undefined
) => {
  const [selectedForm, setSelectedForm] = useState<PackageForm>();
  const { addErrorSnackbar } = useCustomSnackbar();

  const createErrorHandler = (errorCode: ErrorCodes) => (e: Error) => {
    console.error('An error occurred while getting document preview');
    const eventId = Sentry.captureException(e);

    setSelectedForm(undefined);
    addErrorSnackbar({
      message: 'Failed to load the pdf, please refresh and try again.',
      errorCode,
      eventId,
    });
  };

  // This pre-fetches each pdf when the page loads
  useGetSklosuresSignerPdfForReviewUrls(
    interviews?.map((interview) => ({ interviewId: interview.id, signerId: interview.signers[0].id })) ?? [],
    { enabled: !!interviews && !envelope }
  );

  const { data: interviewPreviewUrl, isLoading: isSklosuresLoading } = useGetSklosuresSignerPdfForReviewUrl(
    {
      interviewId: selectedForm?.interviewId ?? 0,
      signerId: interviews?.find((i) => i.id === selectedForm?.interviewId)?.signers[0].id ?? 0,
    },
    {
      enabled: !!selectedForm?.interviewId && !!interviews && !envelope,
      onError: createErrorHandler(ErrorCodes.DocumentPreviewGetSklosuresSignerPdfUrl),
    }
  );

  // This pre-fetches each pdf when the page loads
  useGetDs3DocumentPdfs(
    envelope?.documents.map((d) => d.url),
    { enabled: !!envelope }
  );

  const { data: ds3Url } = useGetDs3DocumentPdf(
    envelope?.documents.find((document) => document.id === selectedForm?.ds3DocumentId)?.url,
    {
      enabled: !!envelope && !!selectedForm?.ds3DocumentId,
      onError: createErrorHandler(ErrorCodes.DocumentPreviewGetDs3DocumentPdfs),
    }
  );

  // This prefetches the pdf
  useGetFormsDocumentPdf(selectedForm?.formsDocumentId ?? 0, fileId, {
    enabled: !!selectedForm?.formsDocumentId,
  });

  const { data: formsUrl } = useGetFormsDocumentPdf(selectedForm?.formsDocumentId ?? 0, fileId, {
    enabled: !!selectedForm?.formsDocumentId,
    onError: createErrorHandler(ErrorCodes.DocumentPreviewGetFormsDocumentPdfs),
  });

  const url = ds3Url ?? interviewPreviewUrl?.base64Content ?? formsUrl;
  const skipped = interviewPreviewUrl?.skippedBlockInfo;
  const flagged = interviewPreviewUrl?.flaggedBlockInfo;

  return useMemo<DocumentPreviewApi>(
    () => ({
      selectPackageForm: setSelectedForm,
      isLoading: isSklosuresLoading || interviews == null,
      documentPreview:
        selectedForm && url
          ? {
              interviewId: selectedForm.interviewId,
              formName: selectedForm.name,
              url: url,
              skippedBlockInfo: skipped,
              flaggedBlockInfo: flagged,
            }
          : undefined,
    }),
    [isSklosuresLoading, interviews, selectedForm, url, skipped, flagged]
  );
};

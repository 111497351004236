import axios from 'axios';
import { config } from '../constants';
import { getApiToken } from '../auth/okta';
import { FileServiceResult, FormsUserProfile } from '../types';
import { AuthType } from '..';

export interface VerifyNrdsRequest {
  nrdsId: string;
  lastName: string;
  source: AuthType;
}

export interface VerifyNrdsResponse {
  isVerified: boolean;
  memberDataRaw: never;
  memberData: any;
}

export interface VerifyNwmlsRequest {
  lagNumber: string;
}

export interface VerifyNwmlsResponse {
  isVerified: boolean;
  isAlreadyUsed: boolean;
  fullName: string;
}

export interface VerifyCarRequest {
  nrdsId: string;
  lastName: string;
}

export interface VerifyCarResponse {
  isVerified: boolean;
  isAlreadyUsed: boolean;
  exitUrl: string;
  memberDataRaw: string;
  memberData: string;
}

export interface VerifyOrefRequest {
  accessId: string;
}

export interface VerifyOrefResponse {
  isVerified: boolean;
  isAlreadyUsed: boolean;
  isExpired: boolean;
  expiration: unknown;
}

export const getUserMeta = async (userId: string) => {
  const token = await getApiToken();
  const res = await axios({
    method: 'GET',
    url: `${config.formsApiUrl}/users/api/user-profiles/${userId}/meta`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return res.data.result;
};

export const postUserProfiles = async (
  firstName: string,
  lastName: string,
  email: string,
  primaryPhoneNumber?: string
) => {
  const token = await getApiToken();
  const res = await axios.post(
    `${config.formsApiUrl}/users/api/user-profiles`,
    { firstName, lastName, email, regions: [], primaryPhoneNumber, areTermsAccepted: true },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  );
  return res.data.result;
};

export const getFormsUserProfile = async (userId: string | null) => {
  if (!userId) {
    return;
  }
  const token = await getApiToken();
  const res = await axios({
    method: 'GET',
    url: `${config.formsApiUrl}/users/api/user-profiles/${userId}`,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return res.data.result;
};

export const getOrCreateUser = async (
  userId: string,
  firstName: string,
  lastName: string,
  email: string
): Promise<FormsUserProfile> => {
  try {
    return await getFormsUserProfile(userId);
  } catch (err) {
    if (err.response?.status === 404) {
      return await postUserProfiles(firstName, lastName, email);
    }
    throw err;
  }
};

export const getUserCarVerify = async (request: VerifyCarRequest) => {
  const token = await getApiToken();
  return (
    await axios.get<FileServiceResult<VerifyCarResponse>>(`${config.formsApiUrl}/users/api/verify/car`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      params: request,
    })
  ).data.result;
};

export const getUserNrdsVerify = async (request: VerifyNrdsRequest) => {
  const token = await getApiToken();
  return (
    await axios.get<FileServiceResult<VerifyNrdsResponse>>(`${config.formsApiUrl}/users/api/nrds`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      params: request,
    })
  ).data.result;
};

export const getUserNwmlsVerify = async (request: VerifyNwmlsRequest) => {
  const token = await getApiToken();
  return (
    await axios.get<FileServiceResult<VerifyNwmlsResponse>>(`${config.formsApiUrl}/users/api/nwmls`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      params: request,
    })
  ).data.result;
};

export const getUserOrefVerify = async (request: VerifyOrefRequest) => {
  const token = await getApiToken();
  return (
    await axios.get<FileServiceResult<VerifyOrefResponse>>(`${config.formsApiUrl}/users/api/oref`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      params: request,
    })
  ).data.result;
};

export const updateUserAuthProfiles = async (authData: any, userId: string) => {
  const token = await getApiToken();
  const res = await axios.patch(`${config.formsApiUrl}/users/api/user-profiles/${userId}/auth-profiles`, authData, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return res.data.result;
};

export const updateUserLibraries = async (userId: string, libraries: number[]) => {
  const token = await getApiToken();
  const payload = {
    addedLibraryIds: libraries,
  };
  const res = await axios.patch(`${config.formsApiUrl}/users/api/user-profiles/${userId}/libraries`, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return res.data.result;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateUserProfile = async (userData: any, userId: string) => {
  const token = await getApiToken();
  const res = await axios.put(`${config.formsApiUrl}/users/api/user-profiles/${userId}`, userData, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
  return res.data.result;
};

import { useLocation } from 'react-router';
import { useAuth } from '@skyslope/auth-react';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SpqTdsDisclosureSteps } from '../pages/SpqTdsDisclosures/steps';
import { disclosuresSteps } from '../pages';

const hiddenSteps: string[] = [
  SpqTdsDisclosureSteps.email,
  SpqTdsDisclosureSteps.login,
  SpqTdsDisclosureSteps.recoverPassword,
  SpqTdsDisclosureSteps.recoverPasswordSuccess,
  SpqTdsDisclosureSteps.loginSkyslope,
  SpqTdsDisclosureSteps.uploadAvid,
  SpqTdsDisclosureSteps.addNotes,
  SpqTdsDisclosureSteps.tdsSection3,
];

export const useDisclosuresStepProgress = (): number => {
  const { pathname } = useLocation();
  const {
    authState: { isAuthenticated },
  } = useAuth();
  const [progressComplete, setProgressComplete] = useState(false);
  const { stepName } = useParams<{ stepName: string }>();
  return useMemo(() => {
    if (!pathname.includes('disclosures')) return 0;
    if (hiddenSteps.includes(stepName)) return 0;
    if (stepName === SpqTdsDisclosureSteps.selectOnBehalfOfAgent) return 0;
    if (stepName === SpqTdsDisclosureSteps.property && isAuthenticated) return 0.1;
    if (progressComplete && stepName !== SpqTdsDisclosureSteps.sending) return 99;
    if (stepName === SpqTdsDisclosureSteps.review) {
      setProgressComplete(true);
      return 99;
    }
    if (stepName === SpqTdsDisclosureSteps.sending) return 100;

    const stepIndex = disclosuresSteps.findIndex((step) => step.key === stepName);
    const totalStepCount = disclosuresSteps.length;
    const progress = stepIndex / totalStepCount;
    if (isNaN(progress) || progress < 0) return stepIndex;
    return progress * 100;
  }, [pathname, isAuthenticated, progressComplete, stepName]);
};

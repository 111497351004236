import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useInitialQueryParams } from 'src/hooks/useInitialQueryParams';
import { newUserInviteAtom } from 'src/state';
import { UserMetaRole } from 'src/lib';

/**
 * This hook will hydrate the newUserInviteAtom from query parameters when the app loads
 * see newUserInviteAtom for more details
 */
export const useHydrateNewUserInviteState = () => {
  const params = useInitialQueryParams();
  const [, setNewUserInviteContext] = useRecoilState(newUserInviteAtom);
  const [hasConsumedQueryParams, setHasConsumedQueryParams] = useState(false);

  useEffect(() => {
    const hasTargetQueryParams = params.has('inviteRole') || params.has('inviteEmail');
    if (hasTargetQueryParams && !hasConsumedQueryParams) {
      setNewUserInviteContext({
        inviteEmail: params.get('inviteEmail') as string,
        inviteRole: params.get('inviteRole') as UserMetaRole,
      });
      setHasConsumedQueryParams(true);
    }
  }, [params, hasConsumedQueryParams, setNewUserInviteContext]);
};

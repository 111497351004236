import React from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  useGetFileById,
  usePatchFileV2,
  useGetActiveUserProfile,
  useGetUserProfile,
  useCreateContacts,
} from 'src/hooks';
import { WizardStepProps, ContactCollectionStep } from 'src/domain';
import { contactActions, contactTypes, convertUserSettingsToContact, RepresentationType } from 'src/lib';
import { selectedAgentAtom } from 'src/state';
import { AvidSteps } from '../../AvidCreation/steps';
import { useGetAvidPackage } from '../../hooks';

export const convertRepTypeToContactType = (repType: RepresentationType): string => {
  if (
    repType.toLowerCase() === RepresentationType.TENANT.toLowerCase() ||
    repType.toLowerCase() === RepresentationType.BUYER.toLowerCase()
  )
    return 'Buyer';
  else return 'Seller';
};

export const AvidContacts = ({ goToStep }: WizardStepProps) => {
  const { packageId } = useParams<{ packageId: string }>();
  const { data: avidPackage } = useGetAvidPackage(packageId);
  const { data: file } = useGetFileById(avidPackage?.fileId);
  const { data: activeUserProfile } = useGetActiveUserProfile();
  const { data: userProfile } = useGetUserProfile();
  const selectedAgent = useRecoilValue(selectedAgentAtom);
  const representationType = file?.representationType;
  const contactType = convertRepTypeToContactType(representationType ?? RepresentationType.BUYER);
  const contacts = (contactType === contactTypes.SELLER ? file?.fileData.sellers : file?.fileData.buyers) ?? [];
  const { mutate: patchFile } = usePatchFileV2();
  const { mutateAsync: createContacts } = useCreateContacts();
  return (
    <ContactCollectionStep
      isLoading={!file}
      title="Who are you performing the inspection for?"
      isPossibleEntity
      getBlankContact={() => ({ type: contactType })}
      defaultContacts={contacts.length === 0 ? [{ type: contactType }] : contacts}
      NavigationProps={{ PrevButtonProps: { hidden: true }, NextButtonProps: { disabled: !userProfile } }}
      onSubmit={(form) => {
        const agentType = `${contactType}Agent`;
        const agent =
          file?.contacts.find(
            (c) =>
              c.type === agentType &&
              c.firstName === activeUserProfile?.firstName &&
              c.lastName === activeUserProfile?.lastName &&
              c.email === activeUserProfile?.email
          ) ?? convertUserSettingsToContact(activeUserProfile, representationType, contactActions.NEEDSTOSIGN);
        if (selectedAgent && file) {
          createContacts({
            fileId: file?.id,
            contacts: [
              {
                type: contactTypes.OTHER,
                firstName: userProfile?.firstName ?? '',
                lastName: userProfile?.lastName ?? '',
                email: userProfile?.email ?? '',
                actionAttribute: contactActions.RECEIVESCOPY,
                isEntity: false,
                companyName: '',
              },
            ],
          });
        }
        patchFile(
          {
            fileId: file!.id,
            payload: {
              fileData: {
                [`${contactType.toLowerCase()}s`]: form.contacts.map((c) => ({
                  ...(contacts.find((fileContact) => fileContact.id === c.id) ?? {}),
                  ...c,
                  actionAttribute: 'NeedsToSign',
                })),
                ...(agent ? { [`${contactType.toLowerCase()}sAgents`]: [agent] } : {}),
              },
            },
          },
          {
            onSuccess: () => {
              goToStep(AvidSteps.chooseRooms);
            },
          }
        );
      }}
    />
  );
};

import React from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';

interface ContainerWithGuttersProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  leftGutterContent?: React.ReactNode;
  children?: React.ReactNode;
  rightGutterContent?: React.ReactNode;
  classes?: {
    root?: string;
    left?: string;
    children?: string;
    right?: string;
  };
}

/**
 * A container with gutters each taking up 1/12 of the width on the left and
 * right sides.  This is useful for ensuring the primary content remains
 * centered when you have a small element (like say an "X" button) on one
 * side but not the other.
 */
export const ContainerWithGutters = (props: ContainerWithGuttersProps) => {
  const { className, leftGutterContent, children, rightGutterContent, classes = {}, ...rest } = props;

  return (
    <Grid {...rest} container className={clsx(className, classes.root)}>
      {/*
       * This left column matches the column taken up by the remove button
       * to center the contents of the row
       */}
      <Grid item xs={12} md={1} className={classes.left}>
        {leftGutterContent}
      </Grid>

      <Grid item xs={12} md={10} className={classes.children}>
        {children}
      </Grid>

      <Grid item xs={12} md={1} className={classes.right}>
        {rightGutterContent}
      </Grid>
    </Grid>
  );
};

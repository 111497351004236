import React, { useState, useCallback, useEffect } from 'react';
import { makeStyles, Theme, Grid } from '@material-ui/core';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { useParams } from 'react-router-dom';
import { useIsMedium } from 'src/hooks';
import { Tay, FixedHeaderLayout, StepTransition } from 'src/common';
import { SelectAgentDropdown } from '../SelectAgent/SelectAgentDropdown';
import { WizardHeader } from './WizardHeader';
import { TayTipContext } from './useTayTip';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: 960,
      margin: '0 auto',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
      },
    },
    stepRouteWrapper: {
      position: 'relative',
    },
    noTayHeader: {
      marginBottom: theme.spacing(4),
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(8),
      },
    },
  }),
  { classNamePrefix: 'Wizard' }
);

export type WizardStepProps = {
  goToStep: (
    stepName: string,
    options?: { nextGoToStep?: string; replace?: boolean; state?: Record<string, unknown> }
  ) => void;
};

export interface StepOptions {
  key: string;
  component: (props: WizardStepProps) => JSX.Element | null;
  isTayDisabled?: boolean;
}

export type Steps = StepOptions[];

type Props = {
  steps: Steps;
  prefix: string;
  defaultStep: string;
  progress?: number;
  skipRedirect?: boolean;
};

export const Wizard: React.FC<Props> = ({ steps, prefix, defaultStep, progress = 0, skipRedirect = false }) => {
  const { path: routePath, isExact: isRoutPathExact } = useRouteMatch();
  const { stepName, packageId } = useParams<{ packageId?: string; stepName?: string }>();
  const classes = useStyles();
  const history = useHistory();
  const [tayQuote, setTayQuote] = useState<React.ReactNode>(null);
  const location = useLocation<{ nextGoToStep?: string; animateForward?: boolean }>();
  const isDesktop = !useIsMedium();

  const currentStepIndex = steps.findIndex(
    (s) => s.key === stepName || (stepName == null && s.key.includes(defaultStep))
  );
  const currentNextGoToStep = location.state?.nextGoToStep;
  const currentStep = steps[currentStepIndex];

  const fullPrefix = packageId ? prefix.replace(':packageId', packageId) : prefix;

  const goToStep = useCallback(
    (stepName: string, { nextGoToStep, replace, state = {} } = {}) => {
      const nextStepName = currentNextGoToStep ?? stepName;

      const nextStepIndex = steps.findIndex((s) => s.key === nextStepName);
      if (nextStepIndex === null) {
        throw `Invalid step '${nextStepName}'`;
      }
      const nextState = {
        animateForward: nextStepIndex > currentStepIndex,
        nextGoToStep,
        ...state,
      };
      setTayQuote(null);

      if (replace) {
        history.replace(`/${fullPrefix}/${nextStepName}`, nextState);
      } else {
        history.push(`/${fullPrefix}/${nextStepName}`, nextState);
      }
    },
    [steps, currentStepIndex, setTayQuote, history, fullPrefix, currentNextGoToStep]
  );

  // handle redirects on refresh, if there is a packageId, data has been persisted, so we don't have to go to the default route
  useEffect(() => {
    if (skipRedirect) {
      return;
    }
    const isPrefixRoute = routePath.slice(1) === prefix && isRoutPathExact;
    const isNonDefaultRouteWithoutPackageId = packageId == null && !routePath.includes(defaultStep);
    if (isPrefixRoute || isNonDefaultRouteWithoutPackageId) {
      history.push(`/${fullPrefix}/${defaultStep}`);
    }
  }, [routePath, isRoutPathExact, history, prefix, defaultStep, fullPrefix, packageId, skipRedirect]);

  const isTayEnabled = currentStep && !currentStep.isTayDisabled;

  return (
    <TayTipContext.Provider value={{ setTayTip: setTayQuote }}>
      <FixedHeaderLayout
        headerContent={
          <WizardHeader
            className={isTayEnabled ? undefined : classes.noTayHeader}
            progress={progress}
            navHeaderType="progress"
            floatRight={
              isDesktop && (
                <SelectAgentDropdown
                  style={{ marginRight: 28 }}
                  disabled
                  disabledMessage="The agent you're working on behalf of can't be changed while you're creating a document"
                />
              )
            }
          />
        }
      >
        <div className={classes.root}>
          <Grid container spacing={0}>
            {isTayEnabled && (
              <Grid item xs={12}>
                <Tay tayQuote={tayQuote} />
              </Grid>
            )}
            {steps.map((step, i) => {
              const StepComponent = step.component;
              return (
                <Grid item xs={12} key={step.key} className={classes.stepRouteWrapper}>
                  <StepTransition shouldRender={i === currentStepIndex}>
                    <StepComponent goToStep={goToStep} />
                  </StepTransition>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </FixedHeaderLayout>
    </TayTipContext.Provider>
  );
};

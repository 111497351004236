import React from 'react';
import { Grid, Typography } from '@material-ui/core';

export function SectionHeading(props: React.HtmlHTMLAttributes<HTMLDivElement>) {
  const { children, ...rest } = props;

  return (
    <Grid {...rest} item xs={12} style={{ marginBottom: -8 }}>
      <Typography variant="h5" style={{ fontSize: '1rem' }}>
        {children}
      </Typography>
    </Grid>
  );
}

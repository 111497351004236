import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core/styles';
import {
  Checkbox,
  Chip,
  Container,
  Divider,
  Grid,
  Box,
  List,
  ListItemIcon,
  ListItemText,
  InputAdornment,
  Typography,
  Button,
} from '@material-ui/core';
import { Search as SearchSharp } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import { useRecoilValue } from 'recoil';
import { DialogCloseButton, Navigation, SelectableButton, Spacer, Step, TextInput } from 'src/common';
import { WizardStepProps } from 'src/domain';
import { AuthTypeInfo, TemplateStatus } from 'src/lib';
import { selectedAgentAtom } from 'src/state';
import { DisclosureDefinition } from 'src/hooks';
import { useDisclosuresConditionalSteps } from '../../hooks/useDisclosuresConditionalSteps';
import { useDisclosuresStore } from '../../store';
import { SpqTdsDisclosureSteps } from '../constants';
import { DisclosuresAssociationName } from '../../../../common/DisclosuresAssociationName';
import { useGetMissingAuthInfos } from '../../hooks/useGetMissingAuthInfos';
import { usePickFormsOptions } from './usePickFormsOptions';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: theme.spacing(90.5),
  },
  formControlRoot: {
    width: '100%',
  },
  disclosureName: {
    fontWeight: 600,
    fontSize: '18px',
    color: '#465766',
  },
  checked: {
    '&:hover': {
      backgroundColor: 'inherit',
    },
    '& svg': {
      fill: `${theme.colors.mainColor.primary} !important`,
    },
    '&.Mui-focusVisible svg': {
      fill: `${theme.colors.grey.grey1} !important`,
    },
  },
  checkboxRoot: {
    marginLeft: 32,
    '& .MuiIconButton-label': {
      height: 24,
      width: 24,
      backgroundColor: 'white',
      [theme.breakpoints.up('sm')]: {
        width: 38,
        height: 38,
      },
    },
    '&:hover': {
      backgroundColor: 'inherit',
    },
    '&:hover svg': {
      fill: theme.colors.mainColor.primary,
    },
    '&.Mui-focusVisible svg': {
      fill: theme.colors.mainColor.primary,
    },
    '& svg': {
      fill: theme.colors.grey.grey1,
      width: 32,
      height: 32,
      [theme.breakpoints.up('sm')]: {
        width: 52,
        height: 52,
      },
    },
  },
  listRoot: {
    borderRadius: '6px',
    overflow: 'auto',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
      maxHeight: 420,
      border: `solid 2px ${theme.colors.grey.offWhite3}`,
    },
  },
  divider: {
    backgroundColor: theme.colors.grey.offWhite3,
    margin: theme.spacing(2, 0),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(4, 0),
    },
  },
  eyeIcon: {
    color: theme.colors.grey.grey1,
  },
  selectableButtonRoot: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    boxSizing: 'border-box',
    background: 'white',
    border: `solid 2px ${theme.colors.grey.offWhite3}`,
    borderRadius: 6,
    transition: 'border-color 200ms ease',
    padding: theme.spacing(1, 1, 1, 2),
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      background: theme.colors.grey.offWhite2,
    },
    '& + &': {
      marginTop: theme.spacing(1.5),
    },
  },
  selected: {
    border: `solid 2px transparent`,
    background: `${theme.colors.grey.offWhite2} !important`,
  },
  accessError: {
    borderRadius: '6px',
    overflow: 'auto',
    [theme.breakpoints.up('sm')]: {
      height: 280,
      padding: theme.spacing(3),
      border: `solid 2px ${theme.colors.grey.offWhite3}`,
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  bodyText: {
    fontSize: theme.typography.pxToRem(20),
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
      padding: '4px 12px',
      width: '100%',
    },
  },
  backButton: {
    fontSize: '1rem',
    padding: '12px 32px',
    marginTop: 24,
    [theme.breakpoints.down('xs')]: {
      minWidth: 0,
      margin: 0,
    },
  },
  closeButton: {
    color: `${theme.colors.grey.bodyHeader} !important`,
  },
}));

// TODO: FOR INNO2-618 Read the following comment
// I had already built that functionality, but then realized that it didn't work for new users cause of car auth.
// look at the git history for this file, SpqTdsDisclosuresSending, SpqTdsDisclosuresReview and usePickFormsOptions.ts
// there was also logic removed that made it so non-sklosures forms worked too and code in this file for giving a form preview

export const PickForms = (props: WizardStepProps) => {
  const store = useDisclosuresStore();
  const classes = useStyles();
  const { results: disclosureListForRegion, onSelect: handleChange, onSearch, selection } = usePickFormsOptions();
  const { data: missingAuthInfos } = useGetMissingAuthInfos(selection);
  const { nextStep } = useDisclosuresConditionalSteps(SpqTdsDisclosureSteps.pickForms, selection);
  const [isMissingAssociateCredentials, setIsMissingAssociationCredentials] = useState(false);
  const [missingAuthTypes, setMissingAuthTypes] = useState('');
  const selectedAgent = useRecoilValue(selectedAgentAtom);

  // Need to support multiple auth types in a single state, so build a comma separated list of missing auth types
  const missingAuthTypeInfosToString = (missingAuthInfos: AuthTypeInfo[]) => {
    if (missingAuthInfos.length === 1) return missingAuthInfos[0].libraryName;
    const last = missingAuthInfos.pop();
    return missingAuthInfos!.map((authInfo) => authInfo.libraryName).join(', ') + ' and ' + last!.libraryName;
  };

  const handleNextClick = (forms: DisclosureDefinition[]) => {
    const isLibraryAuthenticated = missingAuthInfos!.length === 0;
    if (!isLibraryAuthenticated && selectedAgent) {
      setIsMissingAssociationCredentials(true);
      setMissingAuthTypes(missingAuthTypeInfosToString(missingAuthInfos!));
    } else {
      store.update({ forms });
      props.goToStep(nextStep!);
    }
  };

  return (
    <Step title="Which disclosure forms would you like to send?">
      {!disclosureListForRegion && (
        <Container maxWidth={false} className={classes.root} disableGutters>
          <Skeleton variant="rect" height={50} />
          <Divider className={classes.divider} />
          <Skeleton variant="rect" height={420} />
        </Container>
      )}
      {disclosureListForRegion && (
        <Container maxWidth={false} className={classes.root} disableGutters>
          <TextInput
            placeholder="Search disclosures"
            onChange={onSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchSharp />
                </InputAdornment>
              ),
            }}
          />
          <Spacer axis="vertical" size={4} />
          {disclosureListForRegion && !isMissingAssociateCredentials ? (
            <List classes={{ root: classes.listRoot }}>
              {disclosureListForRegion.map((disclosure) => {
                const isSelected = !!selection.some((d) => d.interviewTemplateId === disclosure.interviewTemplateId);
                const isDisabled = disclosure.tags.status.includes(TemplateStatus.NEW) || disclosure.formId < 0;
                const chipText = isDisabled && 'Coming Soon';
                return (
                  <SelectableButton
                    className={clsx(classes.selectableButtonRoot, isSelected && classes.selected)}
                    disabled={isDisabled}
                    selectBorderPosition={{ x: -2, y: -2 }}
                    checked={isSelected}
                    component="label"
                    key={disclosure.name}
                  >
                    <ListItemIcon>
                      <Checkbox
                        disabled={isDisabled}
                        checked={isSelected}
                        onChange={handleChange}
                        name={disclosure.name}
                        color="primary"
                        value={disclosure.interviewTemplateId}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Grid container alignItems="center">
                          <Box flex="1 1 0px" className={classes.disclosureName}>
                            {disclosure.name}
                            <DisclosuresAssociationName associationName={disclosure.associationName} />
                          </Box>
                          {chipText && (
                            <Box flex="0 1 auto">
                              <Chip label={chipText} />
                            </Box>
                          )}
                        </Grid>
                      }
                    />
                  </SelectableButton>
                );
              })}
            </List>
          ) : (
            <Container classes={{ root: classes.accessError }}>
              <DialogCloseButton
                classes={{ root: classes.closeButton }}
                onClick={() => setIsMissingAssociationCredentials(false)}
              />
              <Typography variant="h5" classes={{ root: classes.title }}>
                Access Denied
              </Typography>
              <Typography variant="subtitle2" classes={{ root: classes.bodyText }}>
                {selectedAgent!.firstName} {selectedAgent!.lastName} needs to update their {missingAuthTypes}{' '}
                credentials.
              </Typography>
              <Typography variant="subtitle2" classes={{ root: classes.bodyText }}>
                Please contact them to renew their credentials.
              </Typography>
              <Button
                variant="contained"
                color="primary"
                className={clsx(classes.button, classes.backButton)}
                onClick={() => props.goToStep(SpqTdsDisclosureSteps.property)}
                data-testid="wizard-back-btn"
              >
                {'Go Back'}
              </Button>
            </Container>
          )}
        </Container>
      )}
      {!isMissingAssociateCredentials && (
        <Navigation
          PrevButtonProps={{ onClick: () => props.goToStep(SpqTdsDisclosureSteps.property), children: 'Back' }}
          NextButtonProps={{
            onClick: () => {
              handleNextClick(selection);
            },
            disabled: selection.length < 1,
            children: `Choose (${selection.length})`,
          }}
        />
      )}
    </Step>
  );
};

import React from 'react';
import { makeStyles, Theme, Typography, Button } from '@material-ui/core';
import { AddCircleOutlineSharp } from '@material-ui/icons';
import { ReactComponent as MyTeamImage } from 'src/images/myTeam.svg';

const useStyles = makeStyles<Theme, NoTeamMembersProps>(
  (theme: Theme) => ({
    root: {
      border: '2px solid',
      borderColor: theme.colors.grey.offWhite3,
      borderRadius: 6,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(4),
      flex: 1,
    },
    imageCircle: {
      borderRadius: '50%',
      background: theme.colors.grey.disable,
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: '0 0 100px',
      width: 100,
      height: 100,
    },
    myTeamImage: {
      width: 180,
      transform: 'translate3d(-27px, 21px, 0) scale(1.2);',
    },
    title: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(1),
    },
    button: {
      marginTop: theme.spacing(2.5),
      marginBottom: theme.spacing(2.5),
    },
  }),
  { classNamePrefix: 'NoTeamMembers' }
);

export interface NoTeamMembersProps {
  onAddTeamMember(): void;
}

export const NoTeamMembers: React.FC<NoTeamMembersProps> = (props) => {
  const { onAddTeamMember } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.root}>
      <div className={classes.imageCircle}>
        <MyTeamImage viewBox="0 0 185 135" className={classes.myTeamImage} />
      </div>
      <Typography component="h2" variant="h5" className={classes.title}>
        Add a teammate to get started
      </Typography>
      <Typography>This teammate will receive an email invitation to work on your behalf.</Typography>

      <Button
        color="primary"
        variant="text"
        startIcon={<AddCircleOutlineSharp />}
        className={classes.button}
        onClick={onAddTeamMember}
      >
        Add Teammate
      </Button>
    </div>
  );
};

import React, { useCallback } from 'react';
import { useLocation } from 'react-router';
import { CenteredLoader } from 'src/common';
import { Credentials, WizardStepProps } from 'src/domain';
import { AuthTypeInfo } from 'src/lib';
import { SpqTdsDisclosureSteps } from './constants';

export const DisclosuresCredentials = ({ goToStep }: WizardStepProps) => {
  const location = useLocation<{ authInfos?: AuthTypeInfo[]; currentIndex?: number }>();
  const { authInfos, currentIndex } = location.state;

  const onSuccess = useCallback(() => {
    if (currentIndex === authInfos!.length - 1) {
      goToStep(SpqTdsDisclosureSteps.sending);
    } else {
      goToStep(SpqTdsDisclosureSteps.credentials, {
        state: {
          authInfos: authInfos,
          currentIndex: currentIndex! + 1,
        },
      });
    }
  }, [authInfos, currentIndex, goToStep]);

  return authInfos ? (
    <Credentials
      onSuccess={onSuccess}
      onBack={() => goToStep(SpqTdsDisclosureSteps.review)}
      // authInfos are the auths that are needed to send the selected disclosures
      authInfo={authInfos[currentIndex!]}
      isFirst={currentIndex === 0}
      total={authInfos.length}
    />
  ) : (
    <CenteredLoader />
  );
};

import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { patchFile, PatchFileV2Request } from '../../../lib/api/files';
import { FileServiceResult } from '../../../lib/types';
import { getFilesByIdQueryKey } from '../queries/useGetFileById';

export const usePatchFileV2 = (
  options: UseMutationOptions<FileServiceResult<number>, AxiosError, PatchFileV2Request> = {}
) => {
  const queryClient = useQueryClient();
  return useMutation<FileServiceResult<number>, AxiosError, PatchFileV2Request>(
    (request: PatchFileV2Request) => patchFile(request),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        if (options.onSuccess) {
          options.onSuccess(data, variables, context);
        }
        queryClient.invalidateQueries([getFilesByIdQueryKey, variables.fileId]);
      },
    }
  );
};

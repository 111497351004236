import { Button, makeStyles, Typography } from '@material-ui/core';
import React, { lazy } from 'react';
import { Step } from 'src/common';

const LottieAnimation = lazy(() => import('src/common/LottieAnimation'));

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  h2: {
    marginTop: 56,
    marginBottom: 16,
  },
  animation: {
    marginTop: -72,
    maxWidth: '100%',
    zIndex: -1,
  },
  button: {
    marginTop: 40,
    alignSelf: 'center',
    padding: '16px 32px',
    fontSize: 24,
    fontWeight: 'bold',
  },
}));

export interface FinishedProps {
  startAgain: () => void;
  onNext: () => void;
  title: string;
  body: string;
  viewText: string;
}

export const Finished = (props: FinishedProps) => {
  const classes = useStyles();

  return (
    <Step
      title={
        <Typography variant="h2" align="center" className={classes.h2}>
          {props.title}
        </Typography>
      }
    >
      <Typography variant="h3" align="center">
        {props.body}
      </Typography>
      <div className={classes.animation}>
        <LottieAnimation animation="ladyChillinPalmTrees" />
      </div>

      <Button className={classes.button} variant="contained" color="primary" onClick={props.onNext}>
        {props.viewText}
      </Button>
      <Button className={classes.button} variant="outlined" color="primary" onClick={props.startAgain}>
        Start Again
      </Button>
    </Step>
  );
};

import React from 'react';
import { Button, ButtonProps, CircularProgress, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme>(() => ({
  spinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, calc(-50% + 4px), 0)',
  },
}));
export interface LoaderButtonProps extends ButtonProps {
  isLoading?: boolean;
}

export const LoaderButton: React.FC<LoaderButtonProps> = (props) => {
  const { isLoading = false, children, disabled, ...rest } = props;
  const classes = useStyles();

  return (
    <Button {...rest} disabled={disabled || isLoading}>
      {children}
      {isLoading && (
        <span className={classes.spinner}>
          <CircularProgress size={24} color="primary" />
        </span>
      )}
    </Button>
  );
};

import React from 'react';
import { useParams } from 'react-router-dom';
import { StepOptions, Wizard } from 'src/domain';
import { defaultWizardRoutes } from 'src/lib';
import { useGetAvidPackage } from 'src/pages/Avid/hooks';
import { useGetFileById } from 'src/hooks';
import { useEnsureSelectedAgent } from 'src/hooks/team/useEnsureSelectedAgent';
import { AvidSteps, AvidSelectOnBehalfOfAgent } from '../AvidCreation/steps';
import {
  AvidCarCredentials,
  AvidContacts,
  AvidHomeBase,
  AvidLicenseNumber,
  AvidPeoplePresent,
  AvidReview,
  AvidWeather,
  AvidInspectionDateTime,
  AvidAddContactTeamMember,
  AvidBrokerageName,
  AvidHomeChoose,
  AvidTeamMembersDeprecated,
} from './steps';
import { useAvidDetailsProgress } from './useAvidDetailsProgress';
import { AvidEditFileTeamMembers } from './steps/AvidEditFileTeamMembers';
import { AvidEditPropertyInfo } from './AvidEditPropertyInfo';

export const avidDetailsSteps: StepOptions[] = [
  {
    key: AvidSteps.selectOnBehalfOfAgent,
    component: AvidSelectOnBehalfOfAgent,
  },
  {
    key: AvidSteps.editPropertyInfo,
    component: AvidEditPropertyInfo,
  },
  {
    key: AvidSteps.contacts,
    component: AvidContacts,
  },
  {
    key: AvidSteps.chooseRooms,
    component: AvidHomeChoose,
  },
  {
    key: AvidSteps.rooms,
    component: AvidHomeBase,
  },
  {
    key: AvidSteps.inspectionDateTime,
    component: AvidInspectionDateTime,
  },
  {
    key: AvidSteps.weather,
    component: AvidWeather,
  },
  {
    key: AvidSteps.peoplePresent,
    component: AvidPeoplePresent,
  },
  {
    key: AvidSteps.car,
    component: AvidCarCredentials,
  },
  {
    key: AvidSteps.licenseNumber,
    component: AvidLicenseNumber,
  },
  {
    key: AvidSteps.brokerageName,
    component: AvidBrokerageName,
  },
  {
    key: AvidSteps.review,
    component: AvidReview,
  },
  {
    key: AvidSteps.editFileTeamMembers,
    component: AvidEditFileTeamMembers,
  },
  {
    key: AvidSteps.addContactTeamMember,
    component: AvidAddContactTeamMember,
  },
  {
    key: AvidSteps.teamMembersDeprecated,
    component: AvidTeamMembersDeprecated,
  },
];

export const AvidDetailsWizard: React.FC = () => {
  const progress = useAvidDetailsProgress();
  const { packageId } = useParams<{ packageId: string }>();
  const { data: avidPackage } = useGetAvidPackage(packageId);
  const { data: file } = useGetFileById(avidPackage?.fileId);

  useEnsureSelectedAgent(file?.ownedBy);

  return (
    <Wizard
      steps={avidDetailsSteps}
      defaultStep={AvidSteps.contacts}
      prefix={defaultWizardRoutes.avidDetails.slice(1)}
      progress={progress}
    />
  );
};

import React, { useEffect, useState } from 'react';
import {
  Button,
  Typography,
  DialogProps,
  makeStyles,
  Theme,
  IconButton,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import { Clear as ClearIcon, AddOutlined as PlusIcon } from '@material-ui/icons';
import { useForm, useFieldArray, useWatch } from 'react-hook-form';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { AgentProfileFormInputs, AgentProfileInputs } from 'src/domain';
import { StdDialog, TextInput } from 'src/common';
import { FormsUserProfile, getAccountAction } from 'src/lib';
import { useUpdateUserProfile } from 'src/hooks';
import { SectionHeading } from './AuthDialog/SectionHeading';
import { ActionType } from './types';

const useStyles = makeStyles(
  (theme: Theme) => ({
    emailInputRoot: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    emailInput: {
      marginTop: theme.spacing(2),
      flex: 1,
    },
    deleteEmailButton: {
      marginTop: theme.spacing(2), // Optical centering
      marginLeft: theme.spacing(1),
    },
    addEmailButton: {
      marginTop: theme.spacing(2),
      fontSize: theme.typography.pxToRem(19),
    },
    endAdornmentWarn: {
      color: theme.colors.warn,
    },
    endAdornmentSuccess: {
      color: theme.colors.success,
    },
  }),
  { classNamePrefix: 'request-access-dialog' }
);

export interface EmailCollectionDialogProps extends DialogProps {
  onClose: () => void;
  onSend: (emails: string[]) => void;
  isLoading?: boolean;
  actionType: ActionType | null;
}

interface Recipient {
  email: string;
}

interface RecipientsForm {
  recipients: Recipient[];
}

const INVITE_COPY = 'This user does not exist in our system. We will send them an invitation for you.';

type UserLookupStatus = 'loading' | 'existing' | 'non-existing' | undefined;

interface ExistingUserLookup {
  [fieldName: string]: UserLookupStatus;
}

const EndAdornment: React.FC<{ userLookupStatus: UserLookupStatus }> = ({ userLookupStatus }) => {
  const classes = useStyles();
  switch (userLookupStatus) {
    case 'existing': {
      return (
        <Tooltip title="This is an existing breeze user">
          <CheckCircleOutlineOutlinedIcon className={classes.endAdornmentSuccess} />
        </Tooltip>
      );
    }
    case 'non-existing': {
      return (
        <Tooltip title={INVITE_COPY}>
          <ErrorOutlineOutlinedIcon className={classes.endAdornmentWarn} />
        </Tooltip>
      );
    }
    case 'loading': {
      return <CircularProgress size={24} />;
    }
    default:
    case undefined:
      return null;
  }
};

export const EmailCollectionDialog: React.FC<EmailCollectionDialogProps> = ({
  open,
  onClose,
  isLoading,
  onSend,
  actionType,
  ...rest
}) => {
  const classes = useStyles();
  const [isExistingUserLookup, setIsExistingUserLookup] = useState<ExistingUserLookup>({});
  const {
    register,
    control,
    reset,
    errors,
    handleSubmit: getFormValues,
  } = useForm<RecipientsForm & AgentProfileFormInputs>({
    mode: 'onTouched',
    defaultValues: {
      recipients: [{ email: '' }],
    },
  });
  const fieldArray = useFieldArray<Recipient>({
    control,
    name: 'recipients',
  });
  const recipients = useWatch<Recipient[]>({ control, name: 'recipients' }) ?? [];

  const handleCancel = () => {
    onClose();
    setIsExistingUserLookup({});
    reset();
  };

  const setUserStatus = (fieldName: string, status: UserLookupStatus) => {
    setIsExistingUserLookup((cur) => ({
      ...cur,
      [fieldName]: status,
    }));
  };

  const { mutateAsync: updateUser } = useUpdateUserProfile();

  useEffect(() => {
    if (!open) {
      setIsExistingUserLookup({});
      reset();
    }
  }, [open, reset]);

  const handleSubmit = async (request: RecipientsForm & AgentProfileFormInputs) => {
    let saveUserPromise = null;
    try {
      if (request.brokerageName || request.phoneNumber) {
        const profile: Partial<FormsUserProfile> = {};
        if (request.brokerageName) profile.brokerageName = request.brokerageName;
        if (request.phoneNumber) profile.primaryPhoneNumber = request.phoneNumber;
        saveUserPromise = updateUser(profile);
      }

      await saveUserPromise;
      onSend?.(request.recipients.map((recipient) => recipient.email));
    } catch (e) {
      throw 'Sorry, something went wrong on our end. Please try again or contact us.';
    }
  };

  return (
    <StdDialog
      {...rest}
      onClose={onClose}
      open={open}
      component="form"
      onSubmit={getFormValues(handleSubmit)}
      title={actionType === ActionType.SHARE ? 'Share Access' : 'Request Access'}
      actions={
        <>
          <StdDialog.SecondaryActionButton disabled={isLoading} onClick={handleCancel}>
            Cancel
          </StdDialog.SecondaryActionButton>
          <StdDialog.PrimaryActionButton
            type="submit"
            isLoading={isLoading}
            disabled={!recipients.every((recipient) => recipient.email)}
          >
            {actionType === ActionType.SHARE ? 'Send Invite' : 'Send Request'}
          </StdDialog.PrimaryActionButton>
        </>
      }
    >
      <Typography>
        {actionType === ActionType.SHARE
          ? 'Invite others to view and edit your files.'
          : 'Ask to create and manage disclosures for people on your team.'}
      </Typography>

      {fieldArray.fields.map((field, index) => {
        const fieldName = `recipients[${index}].email`;
        const helperText = isExistingUserLookup[fieldName] === 'non-existing' ? INVITE_COPY : undefined;

        return (
          <div key={field.id} className={classes.emailInputRoot}>
            <TextInput
              classes={{ root: classes.emailInput }}
              label="Email Address"
              autoComplete="off"
              type="email"
              name={fieldName}
              inputRef={register({ required: 'Please enter this field.' })}
              helperText={helperText}
              InputProps={{ endAdornment: <EndAdornment userLookupStatus={isExistingUserLookup[fieldName]} /> }}
              onBlur={async (e) => {
                if (!e.target.value) {
                  setUserStatus(fieldName, undefined);
                  return;
                }
                setUserStatus(fieldName, 'loading');
                try {
                  const result = await getAccountAction(e.target.value);
                  setUserStatus(fieldName, result !== 'register' ? 'existing' : 'non-existing');
                } catch {
                  setUserStatus(fieldName, undefined);
                }
              }}
            />
            {index > 0 ? (
              <IconButton
                aria-label="delete"
                className={classes.deleteEmailButton}
                onClick={() => fieldArray.remove(index)}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ) : (
              // Take up the same width that the button would have
              <span style={{ width: 44 }} />
            )}
          </div>
        );
      })}

      <Button
        size="small"
        color="primary"
        className={classes.addEmailButton}
        onClick={() => fieldArray.append({ email: '' })}
        startIcon={<PlusIcon />}
      >
        Add Email
      </Button>

      <AgentProfileInputs
        register={register}
        errors={errors}
        heading={<SectionHeading>Agent Information</SectionHeading>}
        style={{ marginTop: 24 }}
        control={control}
      />
    </StdDialog>
  );
};

import React from 'react';
import { WizardStepProps, LicenseNumber } from 'src/domain';
import { AvidSteps } from '../../AvidCreation';
import { useAvidUserProfileBoundary } from '../useAvidUserProfileBoundary';

export const AvidLicenseNumber = (props: WizardStepProps) => {
  const { boundedAction } = useAvidUserProfileBoundary({
    goToStep: props.goToStep,
    targetAction: () => props.goToStep(AvidSteps.review),
  });
  return <LicenseNumber onNext={boundedAction} />;
};

import React, { useState } from 'react';

/**
 * MUI's suggested pattern for handling the open/close state of popups is to
 * store the anchorEl ref inside of a piece of state instead of inside a ref
 * object.
 *
 * This hook makes doing so simpler by providing a click handler to store the
 * ref, a close function, an isOpen boolean, and storing the element in a
 * strongly typed fashion.
 */
export const useAnchorEl = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isOpen = Boolean(anchorEl);
  const close = () => setAnchorEl(null);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget as HTMLButtonElement);
  };

  return {
    /** Pass to the `anchorEl` prop of the popup */
    anchorEl,
    /** Pass to the `open` prop of the popup */
    isOpen,
    /** Pass to the `onClose` handler of the popup */
    close,
    /** Pass to the `onClick` handler of the button that should open the popup */
    handleClick,
  };
};

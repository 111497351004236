import { useMemo } from 'react';
import { useFlags as useLaunchDarklyFlags } from 'launchdarkly-react-client-sdk';

export type BreezeFlags = {
  testFlag: boolean;
  isTdsSectionThreeEnabled: boolean;
  isTdsSectionThreeEnabledForDownload: boolean;
  isMarketingVideoEnabled: boolean;
  isYearBuiltPageEnabled: boolean;
  isFireHazardEnabled: boolean;
  isNonSklosuresDisclosuresEnabled: boolean;
  tcAccess: boolean;
  isVlqEnabled: boolean;
  isMhtdsEnabled: boolean;
  isEscrowOfficerEnabled: boolean;
  isMongoDbForMlsEnabled: boolean;
  introductionButtonLabel: string;
  introductionButtonLabelAlternate: string;
  preOpenEscrowLocation: boolean;
  isContinueInFormsEnabled: boolean;
  isAlternateLanding: boolean;
  isEmailOnLanding: boolean;
  isTestimonialEnabled: boolean;
  isEmailAttachmentsEnabled: boolean;
  isSignerQuestionFlaggingEnabled: boolean;
  isResolveFlagEnabled: boolean;
  enableBooksLink: boolean;
};

// Add your debug flags here
const debugFlags: Partial<BreezeFlags> = {};

/**
 * A thin wrapper around launch darkly's useFlags() hook that provides strong
 * typing and the ability to easily change the value of flags throughout the
 * app when running locally.
 */
export const useFlags = (): BreezeFlags => {
  const launchDarklyFlags = useLaunchDarklyFlags() as BreezeFlags;

  return useMemo(() => {
    const isDevelopment = process.env.NODE_ENV === 'development';
    return isDevelopment ? { ...launchDarklyFlags, ...debugFlags } : launchDarklyFlags;
  }, [launchDarklyFlags]);
};

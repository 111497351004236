import { useRecoilState } from 'recoil';
import { useEffect } from 'react';
import { AgentProfile, selectedAgentAtom } from 'src/state';
import { useCustomSnackbar, useTeamMembers } from 'src/hooks';
import { capitalize } from 'src/lib';

/**
 * This hook ensures that the correct agent is selected for any given context.
 * The hook also uses a snackbar to notify the user if it changes the selected user.
 *
 * @param {string | undefined} ownerId the okta userId of the agent that should be selected
 *
 * @returns void
 */
export const useEnsureSelectedAgent = (ownerId: string | undefined): void => {
  const { data: team, isLoading: isLoadingTeam } = useTeamMembers();
  const [selectedAgent, setSelectedAgent] = useRecoilState(selectedAgentAtom);
  const snackbarController = useCustomSnackbar();

  useEffect(() => {
    const targetAgent = team.find((member) => member.userId === ownerId) as AgentProfile;

    if (ownerId == null || isLoadingTeam || selectedAgent?.userId === targetAgent?.userId) {
      return;
    }

    if (targetAgent) {
      setSelectedAgent(targetAgent);
      snackbarController.addInfoSnackbar(
        `You are now viewing ${capitalize(targetAgent.firstName)} ${capitalize(targetAgent.lastName)}'s profile.`
      );
    } else {
      setSelectedAgent(null);
      snackbarController.addInfoSnackbar('You are now viewing your profile.');
    }
  }, [team, selectedAgent, ownerId, setSelectedAgent, isLoadingTeam, snackbarController]);
};

import React from 'react';
import { Button, makeStyles, Menu, MenuProps, Theme } from '@material-ui/core';
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons';
import { useAnchorEl } from 'src/hooks';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      fontSize: '1rem',
      color: theme.colors.grey.bodyHeader,

      [theme.breakpoints.down('xs')]: {
        padding: 6,
      },
    },
    downArrowIcon: {
      color: theme.colors.grey.grey1,
    },
  }),
  { classNamePrefix: 'DashboardFileFilter' }
);

export interface DashboardFileFilterProps {
  icon: React.ReactNode;
  label: string;
  children: MenuProps['children'];
}

export const DashboardFileFilter: React.FC<DashboardFileFilterProps> = (props) => {
  const classes = useStyles();
  const { children, icon, label, ...rest } = props;
  const { anchorEl, isOpen, close, handleClick } = useAnchorEl();

  return (
    <>
      <Button
        {...rest}
        className={classes.root}
        onClick={handleClick}
        startIcon={icon}
        endIcon={<ArrowDropDownIcon className={classes.downArrowIcon} />}
      >
        {label}
      </Button>
      <Menu
        open={isOpen}
        onClose={close}
        onClick={close}
        anchorEl={anchorEl}
        getContentAnchorEl={null} // See: https://github.com/mui-org/material-ui/issues/7961
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {children}
      </Menu>
    </>
  );
};

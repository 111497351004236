import React from 'react';
import { Box, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router';
import { Navigation, Step } from 'src/common';
import { WizardStepProps } from 'src/domain';
import { EscrowOfficer } from 'src/lib';
import { SpqTdsDisclosureSteps } from '../constants';
import { EscrowOfficerListItem } from './EscrowChooseOfficerStep/EscrowOfficerListItem';

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    fontSize: theme.typography.pxToRem(22),
  },
  card: {
    pointerEvents: 'none',
  },
  buttonText: {
    textTransform: 'none',
  },
}));

export const EscrowAddExistingOfficerStep: React.FC<WizardStepProps> = (props) => {
  const { goToStep } = props;
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<{ customEscrowOfficer?: EscrowOfficer }>();

  function handleSubmit() {
    goToStep(SpqTdsDisclosureSteps.escrowComposeEmail);
  }

  return (
    <Step title="Add a new escrow or title company contact">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" textAlign="center" mt={-1}>
            <Typography variant="subtitle1" className={classes.text}>
              A contact with this email already exists. <br /> Would you like to select this contact?
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" mt={2}>
            <EscrowOfficerListItem
              className={classes.card}
              tabIndex={-1}
              checked
              escrowOfficer={location.state.customEscrowOfficer}
              isLoading={false}
            />
          </Box>
        </Grid>
      </Grid>
      <Navigation
        PrevButtonProps={{
          onClick: () => history.go(-1),
          children: <div className={classes.buttonText}>Add a different contact</div>,
          startIcon: null,
        }}
        NextButtonProps={{
          onClick: handleSubmit,
          children: <div className={classes.buttonText}>Yes, choose contact</div>,
          endIcon: null,
        }}
      />
    </Step>
  );
};

import React, { useState } from 'react';
import { InputAdornment, makeStyles, IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useForm } from 'react-hook-form';
import { TextInput, Navigation } from 'src/common';
import { buildAuthStoreFromAuthState, useAuthStore } from 'src/lib';
import { Step } from '../../common/Step/Step';

const useStyles = makeStyles(() => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  loginText: {
    textAlign: 'center',
    marginBottom: 24,
  },
  input: {
    marginTop: 24,
  },
  forgotPassword: {
    marginLeft: -22,
  },
}));

type Inputs = {
  password: string;
};

export interface LoginProps {
  onLogin: () => void;
  onBack: () => void;
  onForgotPassword: () => void;
}

export const Login = (props: LoginProps) => {
  const classes = useStyles();
  const authStore = useAuthStore();
  const { register, handleSubmit, errors } = useForm({ mode: 'onSubmit' });
  const [loggingIn, setLoggingIn] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = async (data: Inputs) => {
    setLoggingIn(true);
    try {
      authStore.update(await buildAuthStoreFromAuthState());
      // Slight delay to give time for okta auth to recognize the user is logged in before we redirect them
      setTimeout(() => {
        props.onLogin();
      }, 0);
    } catch (err) {
      setLoggingIn(false);
      setLoginError(true);
    }
  };

  return (
    <Step title="Let's get you logged in so you can access your files.">
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <TextInput
          autoFocus
          label="Password"
          name="password"
          inputRef={register({
            required: 'Please enter this field.',
          })}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          error={loginError ? 'Invalid login.' : errors.password}
          type={showPassword ? 'text' : 'password'}
          className={classes.input}
          disabled={loggingIn}
          singleField
        />
        <Navigation
          NextButtonProps={{
            disabled: Object.keys(errors).length > 0,
            children: 'Login',
            endIcon: null,
          }}
          isLoading={loggingIn}
          PrevButtonProps={{
            onClick: props.onBack,
            startIcon: null,
          }}
        />
      </form>
    </Step>
  );
};

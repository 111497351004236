import React, { useState } from 'react';
import {
  BoxProps,
  Collapse,
  Fade,
  Button,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  Divider,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Search as SearchIcon } from '@material-ui/icons';
import { useIsMobile } from 'src/hooks';
import { DashboardColLayout } from './DashboardColLayout';
import { SortableColTitle } from './SortableColTitle';
import { SortableColumns, SortDirection } from './sort-util';
import { DashboardFileFilters } from './filters/DashboardFileFilters';
import { FilterProps } from './filters';

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    filterSection: {
      padding: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
    },
    colHeadersDesktopRoot: {
      overflow: 'hidden',
      padding: 0,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    searchRoot: {
      display: 'flex',
      flexDirection: 'row-reverse',
      textAlign: 'right',
    },
    mobileSearch: {
      padding: theme.spacing(0, 1, 1),
    },
    mobileColTitles: {
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-between',
      },
    },
  }),
  { classNamePrefix: 'DashboardFilesGridHeader' }
);

export interface DashboardFilesGridHeaderProps extends Omit<BoxProps, 'children'> {
  sortColumn: SortableColumns;
  sortDirection: SortDirection;
  onSortClick(sortColumn: SortableColumns): void;
  getNextSortTooltip(col: SortableColumns): string;
  propertyCount: number;
  dashboardFiltering: FilterProps;
}

export const DashboardFilesGridHeader: React.FC<DashboardFilesGridHeaderProps> = (props) => {
  const classes = useStyles();
  const { sortColumn, sortDirection, onSortClick, getNextSortTooltip, propertyCount, dashboardFiltering, ...rest } =
    props;
  const [areFiltersOpen, setAreFiltersOpen] = useState(false);

  const filterCount = dashboardFiltering.filters.length;

  const clientAndPropertyColTitle = (
    // This div is just to optically align the "C" in "Clients & Properties"
    <div style={{ transform: 'translateX(2px)' }}>
      <SortableColTitle
        label={propertyCount ? `Clients & Properties (${propertyCount})` : 'Clients & Properties'}
        sort={sortColumn === 'address' ? sortDirection : null}
        onClick={() => onSortClick?.('address')}
        title={getNextSortTooltip('address')}
      />
    </div>
  );

  const searchButton = (
    <Fade in={!areFiltersOpen}>
      <span>
        <SearchButton setAreFiltersOpen={setAreFiltersOpen} filterCount={filterCount} />
      </span>
    </Fade>
  );

  return (
    <div className={classes.root}>
      <Collapse in={areFiltersOpen}>
        <DashboardFileFilters
          isOpen={areFiltersOpen}
          onClose={() => setAreFiltersOpen(false)}
          dashboardFiltering={dashboardFiltering}
          className={classes.filterSection}
        />
      </Collapse>

      <div className={classes.mobileColTitles}>
        {clientAndPropertyColTitle}
        {searchButton}
      </div>

      <DashboardColLayout {...rest} component={Toolbar} className={classes.colHeadersDesktopRoot}>
        <DashboardColLayout.LeftColumn overflow="show">{clientAndPropertyColTitle}</DashboardColLayout.LeftColumn>

        <DashboardColLayout.RightColumn endAdornment={searchButton} overflow="show">
          <SortableColTitle
            label="Shared With"
            sort={sortColumn === 'agentFullName' ? sortDirection : null}
            onClick={() => onSortClick?.('agentFullName')}
            title={getNextSortTooltip('agentFullName')}
          />
        </DashboardColLayout.RightColumn>
      </DashboardColLayout>
    </div>
  );
};

type SearchButtonProps = {
  setAreFiltersOpen: React.Dispatch<React.SetStateAction<boolean>>;
  filterCount: number;
};

function SearchButton(props: SearchButtonProps) {
  const { setAreFiltersOpen, filterCount } = props;
  const theme = useTheme();
  const isMobile = useIsMobile();

  if (!filterCount) {
    return (
      <IconButton onClick={() => setAreFiltersOpen((open) => !open)} color={filterCount ? 'primary' : undefined}>
        <SearchIcon />
      </IconButton>
    );
  }

  return (
    <Button
      onClick={() => setAreFiltersOpen((open) => !open)}
      style={{
        color: filterCount ? theme.colors.mainColor.primary : undefined,
        borderRadius: 999,
        border: `1px solid ${theme.colors.grey.disable}`,
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      }}
    >
      {isMobile && (
        <>
          <SearchIcon />
          <Typography color="inherit" style={{ fontWeight: 700 }}>
            ({filterCount})
          </Typography>
        </>
      )}
      {!isMobile && (
        <>
          <Typography color="inherit" style={{ fontWeight: 700 }}>
            Filter ({filterCount})
          </Typography>
          <Divider orientation="vertical" style={{ margin: `0 8px`, height: 20 }} />
          <SearchIcon />
        </>
      )}
    </Button>
  );
}

import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { format } from 'date-fns';
import { Button, Grid } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useEnsureSelectedAgent } from 'src/hooks/team/useEnsureSelectedAgent';
import { useGetEscrowOfficerProfile, useCreateEscrowOfficerProfile, useUpdateDefaultEscrowOfficer } from 'src/hooks/';
import { useGetFileById, useFileId, useGetFilePackages } from '../../hooks';
import { defaultWizardRoutes, PackageTypes } from '../../lib';
import { AvidSteps } from '../Avid';
import { useHideZendeskWidget } from '../../common';
import { SpqTdsDisclosureSteps } from '../SpqTdsDisclosures/steps';
import { DisclosuresWorkflowData, FilePackage } from './types';
import { FilePackagesView } from './components';
import { PackageBanner } from './components/PackageBanner';

const placeholderPackages: Partial<FilePackage>[] = [{}, {}];

export const FilePackages: React.FC = () => {
  const history = useHistory();
  const fileId = useFileId();
  const { data: file } = useGetFileById(fileId);
  const { data: packages, isFetched } = useGetFilePackages<Partial<FilePackage>[]>(fileId, {
    select: (results) =>
      results.map((result) => ({
        ...result,
        lastUpdated: format(Date.parse(result.lastUpdated), 'M/d/yyyy h:mm a'),
      })),
  });
  useHideZendeskWidget();
  useEnsureSelectedAgent(file?.ownedBy);
  const { mutate: updateDefaultEscrowOfficer } = useUpdateDefaultEscrowOfficer();

  const { data: escrowOfficerProfile } = useGetEscrowOfficerProfile({ onBehalfOf: file?.ownedBy });
  const { mutate: createEscrowOfficerProfile } = useCreateEscrowOfficerProfile();

  function goToAvidPropertyType() {
    history.push(`${defaultWizardRoutes.avidCreation}?fileId=${fileId}&default=${AvidSteps.propertyType}`);
  }

  function goToDisclosureStep(destinationPage: SpqTdsDisclosureSteps) {
    history.push(`${defaultWizardRoutes.disclosures}?fileId=${fileId}&default=${destinationPage}`);
  }

  function handleOpenEscrow() {
    if (!escrowOfficerProfile) {
      createEscrowOfficerProfile({ onBehalfOf: file?.ownedBy });
    }

    let destinationPage = SpqTdsDisclosureSteps.escrowChooseOfficer;
    if (escrowOfficerProfile?.defaultOfficer) {
      destinationPage = SpqTdsDisclosureSteps.escrowComposeEmail;
    }

    goToDisclosureStep(destinationPage);
  }

  function handleChangeEscrowOfficer() {
    goToDisclosureStep(SpqTdsDisclosureSteps.escrowChooseOfficer);
  }

  function handleClearDefaultEscrowOfficer() {
    updateDefaultEscrowOfficer({ escrowOfficer: null });
  }

  const hasDisclosuresThatNeedsAvid = packages?.some((p) => {
    const isDisclosuresPackage = p.type === PackageTypes.disclosures;
    if (!isDisclosuresPackage) return false;

    const pkg = p as FilePackage<DisclosuresWorkflowData>;
    return pkg.workflowData?.isAttachingAvid;
  });
  const hasAvid = packages?.some((p) => p.type === PackageTypes.avid);

  const shouldShowAvidBanner = hasDisclosuresThatNeedsAvid && !hasAvid;

  return (
    <FilePackagesView
      fileId={fileId}
      fileName={file?.name ?? <Skeleton />}
      fileRepresentationType={file?.representationType ?? ''}
      streetAddress={file?.fileData?.premisesAddress?.streetAddress}
      sellers={file?.fileData?.sellers}
      packages={packages ?? placeholderPackages}
      defaultEscrowOfficer={escrowOfficerProfile?.defaultOfficer}
      onOpenEscrow={handleOpenEscrow}
      onClearDefaultEscrowOfficer={handleClearDefaultEscrowOfficer}
      onChangeEscrowOfficer={handleChangeEscrowOfficer}
      isLoading={!isFetched}
      banner={
        shouldShowAvidBanner && (
          <PackageBanner
            message="Start an AVID to complete the visual inspection for your TDS/MHTDS"
            action={
              <Grid item>
                <Button
                  onClick={goToAvidPropertyType}
                  key="create"
                  disableElevation
                  variant="contained"
                  color="primary"
                  size="small"
                >
                  Start
                </Button>
              </Grid>
            }
          />
        )
      }
    />
  );
};

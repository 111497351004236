import { useState, useMemo } from 'react';

export type EmailStateProps = ReturnType<typeof useComposeEmail>;

type UseComposeEmailArgs = {
  initialRecipients?: string[] | (() => string[]);
  initialCcRecipients?: string[] | (() => string[]);
  initialSubject?: string | (() => string);
  initialBody?: string | (() => string);
  initialSignature?: string | (() => string);
};

export type Value = string | null | undefined;
export type Setter = React.Dispatch<React.SetStateAction<Value[]>>;

export function useComposeEmail(args?: UseComposeEmailArgs) {
  const [recipients, setRecipients] = useState<Value[]>(args?.initialRecipients || ['']);
  const [ccRecipients, setCcRecipients] = useState<Value[]>(args?.initialCcRecipients || ['']);
  const [subject, setSubject] = useState(args?.initialSubject || '');
  const [body, setBody] = useState(args?.initialBody || '');
  const [signature, setSignature] = useState(args?.initialSignature || '');
  const hasError = recipients.length === 0 || !subject || !body;

  return useMemo(
    () => ({
      recipients,
      setRecipients,
      ccRecipients,
      setCcRecipients,
      subject,
      setSubject,
      body,
      setBody,
      signature,
      setSignature,
      hasError,
    }),
    [recipients, ccRecipients, subject, body, setBody, signature, hasError]
  );
}

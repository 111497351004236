import React from 'react';
import { useParams } from 'react-router';
import { EditFileTeamMembersStep, WizardStepProps } from 'src/domain';
import { useGetFileById, useUpdateContacts } from 'src/hooks';
import { contactTypes, Contact, contactActions, pickKeysFromObject } from 'src/lib';
import { AvidSteps } from 'src/pages';
import { useGetAvidPackage } from '../../hooks';

/**
 * The "edit my team" step.  Here, the user can add RECEIVESCOPY contacts to a
 * file by choosing from a list of the user's current team members.
 */
export const AvidEditFileTeamMembers: React.FC<WizardStepProps> = (props) => {
  const { goToStep } = props;

  const { packageId } = useParams<{ packageId: string }>();
  const { data: avidPackage } = useGetAvidPackage(packageId);
  const { data: file, refetch: refetchFile, isLoading: isLoadingFile } = useGetFileById(avidPackage?.fileId);
  const { mutateAsync: updateContacts, isLoading: isSaving } = useUpdateContacts();

  const contactsOnFile = file?.contacts || [];

  return (
    <EditFileTeamMembersStep
      title="Who would you like to include on your team for this disclosure package?"
      isSaving={isSaving || isLoadingFile}
      onNextClick={async (selectedTeamMembers) => {
        if (!file) return;

        const deletedContacts = contactsOnFile.filter(
          (contact) =>
            contact.actionAttribute === contactActions.RECEIVESCOPY &&
            !selectedTeamMembers.find((selected) => contact.email === selected.email)
        );

        const addedContacts = selectedTeamMembers
          .filter((selected) => !contactsOnFile.find((contact) => contact.email === selected.email))
          .map((c) => pickKeysFromObject(c, ['email', 'firstName', 'lastName']) as Contact);

        addedContacts.forEach((c) => {
          c.actionAttribute = contactActions.RECEIVESCOPY;
          c.type = contactTypes.OTHER;
        });

        await updateContacts({
          fileId: file.id,
          deletedContacts,
          createdContacts: addedContacts,
        });
        await refetchFile(); // TODO: Optimistic updates would be better
        goToStep(AvidSteps.review);
      }}
      previousStepName={AvidSteps.review}
      onAddTeamMember={() => goToStep(AvidSteps.addContactTeamMember)}
      contactsOnFile={contactsOnFile.filter((c) => c.actionAttribute === contactActions.RECEIVESCOPY)}
      goToStep={goToStep}
    />
  );
};

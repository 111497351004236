import React, { useMemo } from 'react';
import { useAuth } from '@skyslope/auth-react';
import { useFlags, useDisclosuresStepProgress, useInitialQueryParams, useTeamMembers } from 'src/hooks';
import { Wizard, StepOptions } from 'src/domain';
import { SpqTdsStoreProvider, useCreateDisclosuresStore } from './store';
import {
  SpqTdsBaseRoute,
  SpqTdsEmail,
  PickForms,
  SpqTdsLogin,
  SpqTdsLoginSkyslope,
  SpqTdsRegister,
  SpqTdsRecoverPassword,
  SpqTdsRecoverPasswordSuccess,
  SpqTdsProperty,
  SpqTdsPhoneNumber,
  SpqTdsReview,
  Apn,
  Sellers,
  SpqTdsLicenseNumber,
  SpqTdsFinished,
  SpqTdsSending,
  SpqTdsMissingPropertyInfo,
  SpqTdsRequestSignaturesEmail,
  DisclosuresEnvelopeSending,
  TdsSection3,
  SpqTdsAddContactTeamMember,
  YearBuilt,
  FireHazard,
  SpqTdsDisclosureSteps,
  DisclosuresSelectOnBehalfOfAgent,
  SpqTdsAddTeamMember,
  SpqTdsTeamMembersDeprecated,
  EscrowChooseOfficerStep,
  EscrowComposeEmailStep,
  EscrowAddOfficerStep,
  EscrowAddExistingOfficerStep,
  DisclosuresSelectRegionStep,
  DisclosuresCredentials,
  DisclosureBrokerageName,
  Zoning,
  CustomEmailStep,
} from './steps';
import { UseExistingAvid } from './steps/UseExistingAvid';
import { SpqTdsEditFileTeamMembers } from './steps/SpqTdsEditFileTeamMembers';

export const disclosuresSteps: StepOptions[] = [
  {
    key: SpqTdsDisclosureSteps.email,
    component: SpqTdsEmail,
  },
  {
    key: SpqTdsDisclosureSteps.login,
    component: SpqTdsLogin,
  },
  {
    key: SpqTdsDisclosureSteps.recoverPassword,
    component: SpqTdsRecoverPassword,
  },
  {
    key: SpqTdsDisclosureSteps.recoverPasswordSuccess,
    component: SpqTdsRecoverPasswordSuccess,
  },
  {
    key: SpqTdsDisclosureSteps.loginSkyslope,
    component: SpqTdsLoginSkyslope,
  },
  {
    key: SpqTdsDisclosureSteps.selectOnBehalfOfAgent,
    component: DisclosuresSelectOnBehalfOfAgent,
  },
  {
    key: SpqTdsDisclosureSteps.addTeamMember,
    component: SpqTdsAddTeamMember,
  },
  {
    key: SpqTdsDisclosureSteps.property,
    component: SpqTdsProperty,
  },
  {
    key: SpqTdsDisclosureSteps.missingPropertyInfo,
    component: SpqTdsMissingPropertyInfo,
  },
  {
    key: SpqTdsDisclosureSteps.pickForms,
    component: PickForms,
  },
  // Start conditional steps
  // NOTE: These must be in alphabetic order based on the key
  // in order to animate in the proper direction when the user
  // is going through the wizard
  {
    key: SpqTdsDisclosureSteps.apn,
    component: Apn,
  },
  {
    key: SpqTdsDisclosureSteps.fireHazard,
    component: FireHazard,
  },
  {
    key: SpqTdsDisclosureSteps.yearBuilt,
    component: YearBuilt,
  },
  {
    key: SpqTdsDisclosureSteps.zoning,
    component: Zoning,
  },
  // End conditional steps
  {
    key: SpqTdsDisclosureSteps.sellers,
    component: Sellers,
  },
  {
    key: SpqTdsDisclosureSteps.customEmail,
    component: CustomEmailStep,
  },
  {
    key: SpqTdsDisclosureSteps.brokerageName,
    component: DisclosureBrokerageName,
  },
  // Start Escrow Officer screens
  {
    key: SpqTdsDisclosureSteps.escrowChooseOfficer,
    component: EscrowChooseOfficerStep,
  },
  {
    key: SpqTdsDisclosureSteps.escrowComposeEmail,
    component: EscrowComposeEmailStep,
  },
  {
    key: SpqTdsDisclosureSteps.escrowAddOfficer,
    component: EscrowAddOfficerStep,
  },
  {
    key: SpqTdsDisclosureSteps.escrowAddExistingOfficer,
    component: EscrowAddExistingOfficerStep,
  },
  // End Escrow Officer screens
  {
    key: SpqTdsDisclosureSteps.phone,
    component: SpqTdsPhoneNumber,
  },
  {
    key: SpqTdsDisclosureSteps.register,
    component: SpqTdsRegister,
  },
  {
    key: SpqTdsDisclosureSteps.review,
    component: SpqTdsReview,
  },
  {
    key: SpqTdsDisclosureSteps.teamMembersDeprecated,
    component: SpqTdsTeamMembersDeprecated,
  },
  {
    key: SpqTdsDisclosureSteps.editFileTeamMembers,
    component: SpqTdsEditFileTeamMembers,
  },
  {
    key: SpqTdsDisclosureSteps.addContactTeamMember,
    component: SpqTdsAddContactTeamMember,
  },
  {
    key: SpqTdsDisclosureSteps.credentials,
    component: DisclosuresCredentials,
  },
  {
    key: SpqTdsDisclosureSteps.licenseNumber,
    component: SpqTdsLicenseNumber,
  },
  {
    key: SpqTdsDisclosureSteps.sending,
    component: SpqTdsSending,
    isTayDisabled: true,
  },
  {
    key: SpqTdsDisclosureSteps.done,
    component: SpqTdsFinished,
    isTayDisabled: true,
  },
  {
    key: SpqTdsDisclosureSteps.isUsingExistingAvid,
    component: UseExistingAvid,
  },
  {
    key: SpqTdsDisclosureSteps.tdsSection3,
    component: TdsSection3,
  },
  {
    key: SpqTdsDisclosureSteps.requestSignaturesEmail,
    component: SpqTdsRequestSignaturesEmail,
  },
  {
    key: SpqTdsDisclosureSteps.sendingEnvelope,
    component: DisclosuresEnvelopeSending,
    isTayDisabled: true,
  },
  {
    key: SpqTdsDisclosureSteps.selectRegion,
    component: DisclosuresSelectRegionStep,
  },
];

export const DisclosuresCreationWizard: React.FC = () => {
  // When the user clicks the "start" button on the AVID banner on the file packages
  // page, they are redirected to an initial wizard page with query parameters
  // that are immediately removed once the page renders.  We want to store the
  // original value of those query parameters so that they are used throughout
  // that wizard instance, despite no longer being in the URL.
  // This was the cause of a nearly identical bug for the Disclosures flow:
  // https://skyslope.atlassian.net/browse/INNO2-858
  const queryParams = useInitialQueryParams();

  const fileId = parseInt(queryParams.get('fileId') ?? '');
  const packageId = queryParams.get('packageId') ?? undefined;
  const downloadType = queryParams.get('downloadType') ?? undefined;
  const hasMhtds = queryParams.get('hasMhtds') ?? undefined;
  const defaultStepOverride = queryParams.get('default');
  const spqTdsStore = useCreateDisclosuresStore({
    existingFileId: isNaN(fileId) ? undefined : fileId,
    existingPackageId: packageId,
    downloadType: downloadType,
    hasMhtds: hasMhtds === 'true',
  });

  const { data: teamMembers } = useTeamMembers({ includePending: true });
  const hasTeamMembers = teamMembers.length > 0;

  const { authState } = useAuth();
  const { tcAccess: isTcAccessEnabledFlag } = useFlags();

  const progress = useDisclosuresStepProgress();

  const defaultStep = useMemo(() => {
    if (authState.isAuthenticated && defaultStepOverride) {
      return defaultStepOverride as SpqTdsDisclosureSteps;
    } else if (!authState.isAuthenticated) {
      return SpqTdsDisclosureSteps.email;
    } else if (isTcAccessEnabledFlag && hasTeamMembers) {
      return SpqTdsDisclosureSteps.selectOnBehalfOfAgent;
    } else {
      return SpqTdsDisclosureSteps.property;
    }
  }, [authState, isTcAccessEnabledFlag, defaultStepOverride, hasTeamMembers]);

  return (
    <SpqTdsStoreProvider value={spqTdsStore}>
      <Wizard steps={disclosuresSteps} defaultStep={defaultStep} prefix={SpqTdsBaseRoute} progress={progress} />
    </SpqTdsStoreProvider>
  );
};

import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useAuthStore } from 'src/lib';
import { Navigation, Step } from 'src/common';

const useStyles = makeStyles(() => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  loginText: {
    textAlign: 'center',
    marginBottom: 24,
  },
  input: {
    marginTop: 24,
  },
}));

export interface RecoverSuccessProps {
  onExit: () => void;
}

export const RecoverPasswordSuccess = (props: RecoverSuccessProps) => {
  const classes = useStyles();
  const authStore = useAuthStore();
  const successTitle = `We sent you an email at ${authStore.userInfo?.email} with instructions to help reset your password.`;
  const { handleSubmit } = useForm({ mode: 'onSubmit' });

  const onSubmit = async () => {
    props.onExit();
  };

  return (
    <Step title={successTitle}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <Navigation
          PrevButtonProps={{ hidden: true }}
          NextButtonProps={{
            children: 'Exit',
          }}
        />
      </form>
    </Step>
  );
};

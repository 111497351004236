import React, { ReactNode } from 'react';
import Kitchen from '@material-ui/icons/KitchenSharp';
import Weekend from '@material-ui/icons/WeekendSharp';
import MeetingRoom from '@material-ui/icons/MeetingRoom';
import Restaurant from '@material-ui/icons/RestaurantSharp';
import Home from '@material-ui/icons/Home';
import Bathtub from '@material-ui/icons/BathtubSharp';
import Hotel from '@material-ui/icons/HotelSharp';
import DriveEta from '@material-ui/icons/DriveEta';
import Deck from '@material-ui/icons/Deck';
import { ReactComponent as ObservationsIcon } from '../../../../../images/Observations.svg';
import { ReactComponent as Stairs } from '../../../../../images/Stairs.svg';
import { ReactComponent as OtherHouses } from '../../../../../images/OtherHouses.svg';

const roomIcons: { [roomType: string]: ReactNode } = {
  entry: <MeetingRoom />,
  livingRoom: <Weekend />,
  diningRoom: <Restaurant />,
  kitchen: <Kitchen />,
  hallStairs: <Stairs />,
  otherRoom: <Home />,
  bathroom: <Bathtub />,
  bedroom: <Hotel />,
  garageParking: <DriveEta />,
  exteriorYard: <Deck />,
  other: <OtherHouses />,
  observations: <ObservationsIcon />,
};

export const getRoomIcon = (roomType: string): ReactNode => roomIcons[roomType];

import React from 'react';
import { useForm } from 'react-hook-form';
import { Container } from '@material-ui/core';
import { useRecoilValue } from 'recoil';
import { useAuthStore, makeNamePossessive } from 'src/lib';
import { useGetUserProfile, useUpdateUserProfile } from 'src/hooks';
import { Step, Navigation, PhoneNumberInput } from 'src/common';
import { useTayTip } from 'src/domain/wizard/useTayTip';
import { selectedAgentAtom } from 'src/state';

export interface PhoneNumberProps {
  onNext: () => void;
  onBack: () => void;
}

interface Form {
  primaryPhoneNumber: string;
}

export const PhoneNumber = (props: PhoneNumberProps) => {
  const authStore = useAuthStore();
  const { data: userProfile } = useGetUserProfile();
  const selectedAgent = useRecoilValue(selectedAgentAtom);

  useTayTip("We'll surface this to your client so they can stay in touch with you if they have any questions.");

  const { mutateAsync: updateUser, isLoading } = useUpdateUserProfile({
    onSuccess: () => {
      props.onNext();
    },
  });

  const formProps = useForm<Form>({
    mode: 'onTouched',
  });

  if (userProfile == null) {
    return null;
  }

  return (
    <Step
      title={
        selectedAgent
          ? `What is ${selectedAgent.firstName} ${makeNamePossessive(selectedAgent.lastName!)} phone number?`
          : "What's your phone number?"
      }
    >
      <form
        onSubmit={formProps.handleSubmit((form: Form) => {
          authStore.update({ userInfo: { ...authStore.userInfo, phoneNumber: form.primaryPhoneNumber } });
          updateUser(form);
        })}
      >
        <Container maxWidth="xs">
          <PhoneNumberInput
            required
            name="primaryPhoneNumber"
            errors={formProps.errors}
            autoComplete="off"
            control={formProps.control}
            defaultValue={authStore.userInfo.phoneNumber ?? ''}
            disabled={isLoading}
          />
        </Container>
        <Navigation
          isLoading={isLoading}
          PrevButtonProps={{ onClick: props.onBack }}
          NextButtonProps={{
            disabled: Object.keys(formProps.errors).length > 0,
          }}
        />
      </form>
    </Step>
  );
};

import { QueryObserverResult, useQuery, UseQueryOptions } from 'react-query';
import { AxiosError } from 'axios';
import { FormsFile, getFiles } from 'src/lib';

export const getFilesQueryKey = 'get-files';

export const useGetFiles = <T = FormsFile[]>(
  filters = '',
  options: UseQueryOptions<FormsFile[], AxiosError, T> = {}
): QueryObserverResult<T, AxiosError> =>
  useQuery<FormsFile[], AxiosError, T>(
    [getFilesQueryKey + filters],
    () =>
      getFiles({
        type: 'File',
        includeSharedFiles: true,
        filters,
      }),
    {
      ...options,
      enabled: options.enabled == null || options.enabled,
    }
  );

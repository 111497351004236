import axios from 'axios';
import { config } from '../constants';
import { Weather } from '../types';

export const getWeatherConditions = async (city: string): Promise<Weather> =>
  (
    await axios({
      method: 'GET',
      url: `${config.openWeatherApiUrl}?q=${city}&units=imperial&appid=${config.openWeatherAppId}`,
    })
  ).data;

import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { gql } from 'graphql-request';
import { useRecoilValue } from 'recoil';
import { getGraphQLClient, EscrowOfficer, getApiToken } from 'src/lib';
import { selectedAgentAtom } from 'src/state';
import { getEscrowOfficerProfileKey } from '../queries/useGetEscrowOfficerProfile';

const mutationQuery = gql`
  mutation UpdateDefaultEscrowOfficer($payload: UpdateDefaultEscrowOfficerPayload) {
    updateDefaultEscrowOfficer(payload: $payload) {
      fullName
      firstName
      lastName
      email
      brand
      phoneNumber
    }
  }
`;

export interface UpdateDefaultEscrowOfficerPayload {
  escrowOfficer: EscrowOfficer | null;
  onBehalfOf?: string;
}

export const useUpdateDefaultEscrowOfficer = (
  options: UseMutationOptions<unknown, unknown, UpdateDefaultEscrowOfficerPayload> = {}
) => {
  const queryClient = useQueryClient();
  const selectedAgent = useRecoilValue(selectedAgentAtom);

  return useMutation(
    async (variables: UpdateDefaultEscrowOfficerPayload) => {
      variables.onBehalfOf = selectedAgent ? selectedAgent.userId : undefined;
      const client = getGraphQLClient(await getApiToken());
      await client.request(mutationQuery, {
        payload: { escrowOfficer: variables.escrowOfficer, onBehalfOf: variables.onBehalfOf },
      });
    },
    {
      ...options,
      async onSettled(data, error, variables, context) {
        await options?.onSettled?.(data, error, variables, context);
        queryClient.invalidateQueries([getEscrowOfficerProfileKey]);
      },
    }
  );
};

import React, { useState } from 'react';
import { makeStyles, Theme, Grid, Typography, ButtonBase, Hidden, IconButton, Grow } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import { Spacer } from './Spacer';

const useStyles = makeStyles<Theme, { props: ListItemCardProps; isExpanded: boolean }>(
  (theme: Theme) => ({
    grid: {
      border: `1px solid ${theme.colors.grey.disable}`,
      borderRadius: (p) => (p.isExpanded ? theme.spacing(1, 1, 0, 0) : theme.spacing(1)),
      padding: theme.spacing(3, 4),
      backgroundColor: 'white',
      justifyContent: 'unset',
      cursor: (p) => (p.props.onClick ? 'pointer' : 'default'),
      '&:hover': {
        boxShadow: (p) =>
          p.props.onClick &&
          '0 9px 12px 1px rgba(0,32,64,0.07), 0 3px 16px 2px rgba(0,32,64,0.06), 0 5px 6px -3px rgba(0,32,64,0.07)',
      },
      position: 'relative',
      flexWrap: 'nowrap',
      gap: theme.spacing(2),
    },
    loadingSkeleton: {
      borderRadius: theme.spacing(1),
      height: 84,
      transform: 'scaleY(1)',
    },
    leftHandSide: {
      display: 'flex',
      alignItems: 'center',
      flex: '1 1 0',
      width: 0,
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    rightHandSide: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      flex: '0 0',
    },
    text: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'left',
      whiteSpace: 'nowrap',
    },
    title: {
      paddingRight: theme.spacing(3),
    },
    pinIcon: {
      height: theme.spacing(2),
      width: theme.spacing(2),
      marginRight: theme.spacing(0.5),
    },
    hideShowIconButton: {
      zIndex: 100,
      marginLeft: theme.spacing(2),
      backgroundColor: '#FFFFFF',
      border: `1px solid ${theme.colors.grey.disable}`,
      '&:hover': {
        backgroundColor: `${theme.colors.grey.offWhite3}`,
      },
      [theme.breakpoints.down('xs')]: {
        position: 'absolute',
        top: theme.spacing(0.5),
        right: theme.spacing(0.5),
        background: 'white',
      },
    },
    rotate180: {
      transform: 'rotateZ(180deg)',
    },
    expandableItem: {
      backgroundColor: theme.colors.grey.offWhite1,
      padding: theme.spacing(3, 4),
      borderBottom: `1px solid ${theme.colors.grey.disable}`,
    },
    itemContainer: {
      boxShadow: `inset 0 -1px 0 0 ${theme.colors.grey.offWhite3}`,
      borderRight: `1px solid ${theme.colors.grey.disable}`,
      borderLeft: `1px solid ${theme.colors.grey.disable}`,
    },
  }),
  { classNamePrefix: 'listItemCard' }
);

export interface ListItemCardProps extends Omit<React.HtmlHTMLAttributes<HTMLElement>, 'title'> {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  activity?: React.ReactNode;
  requestMenu?: React.ReactElement;
  shareMenu?: React.ReactElement;
  profilePicture?: React.ReactNode;
  isLoading?: boolean;
  hideActionButton?: boolean;
}

/**
 * A horizontal card with that can display text a title and subtitle on the left side
 * and "activity" (usually buttons or status chips) on the right hand side.  It also
 * optionally supports a small "profilePicture" image to the left of the title, and a
 * menu to the right of the activity if "menuItems" are passed.  "menuItems" should be
 * a React fragment containing MUI <MenuItem> components.
 */
const ListItemCard: React.FC<ListItemCardProps> = (props) => {
  const {
    title,
    subtitle,
    activity,
    className,
    onClick,
    requestMenu,
    shareMenu,
    profilePicture,
    isLoading,
    hideActionButton,
    ...rest
  } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const classes = useStyles({ props, isExpanded });

  if (isLoading) {
    return <Skeleton className={clsx(className, classes.loadingSkeleton)} />;
  }

  return (
    <div {...rest} className={className}>
      <Grid container className={classes.grid} onClick={onClick} component={onClick ? ButtonBase : 'div'}>
        <div className={classes.leftHandSide}>
          {profilePicture && (
            <>
              {profilePicture}
              <Spacer axis="horizontal" size={2} />
            </>
          )}
          <div style={{ overflow: 'hidden' }}>
            <Typography
              variant="body2"
              className={clsx(classes.text, classes.title)}
              title={typeof title === 'string' ? title : ''}
            >
              {title}
            </Typography>
            {subtitle && (
              <Typography
                variant="subtitle1"
                className={classes.text}
                title={typeof subtitle === 'string' ? subtitle : ''}
              >
                {subtitle}
              </Typography>
            )}
          </div>
        </div>
        <div className={classes.rightHandSide}>
          {activity && <Hidden xsDown>{activity}</Hidden>}
          {!hideActionButton && (
            <IconButton
              aria-label="open menu"
              aria-controls="list-item-card-menu"
              aria-haspopup="true"
              onClick={() => setIsExpanded((current) => !current)}
              className={classes.hideShowIconButton}
              size="small"
            >
              <KeyboardArrowDownIcon className={clsx({ [classes.rotate180]: isExpanded })} />
            </IconButton>
          )}
        </div>
      </Grid>
      <Grow
        in={isExpanded}
        mountOnEnter
        unmountOnExit
        timeout={{
          enter: 500,
          exit: 0,
        }}
      >
        <div className={classes.itemContainer}>
          {shareMenu && (
            <Grid item xs={12} className={classes.expandableItem}>
              {shareMenu}
            </Grid>
          )}
          {requestMenu && (
            <Grid item xs={12} className={classes.expandableItem}>
              {requestMenu}
            </Grid>
          )}
        </div>
      </Grow>
    </div>
  );
};

const memoedListItemCard = React.memo(ListItemCard);
export { memoedListItemCard as ListItemCard };

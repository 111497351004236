import React, { useEffect, useRef, useState } from 'react';
import { WizardStepProps, SelectRegions } from 'src/domain';
import { Navigation, NavigationProps, Step } from 'src/common';
import { useSelectRegion } from 'src/hooks';
import { usStates } from 'src/lib';

export interface DisclosuresSelectRegionStepProps extends WizardStepProps {
  NavigationProps?: NavigationProps;
  className?: string;
}

/**
 * A wizard page that allows the user to select their regions(s).
 */
export const DisclosuresSelectRegionStep: React.FC<DisclosuresSelectRegionStepProps> = (props) => {
  const { NavigationProps = {}, ...rest } = props;

  const [regions, setRegions] = useState<usStates[]>([]);
  const {
    regions: serverRegions,
    updateRegions: updateServerRegions,
    isFetched: areRegionsFetched,
  } = useSelectRegion();

  const serverRegionsRef = useRef(serverRegions);
  serverRegionsRef.current = serverRegions;
  useEffect(() => {
    if (!areRegionsFetched || !serverRegionsRef.current) return;
    setRegions(serverRegionsRef.current);
  }, [areRegionsFetched, serverRegionsRef]);

  function handleNext() {
    updateServerRegions(regions);
    // TODO: Use goToStep to do something here!
  }

  return (
    <Step {...rest} title="Please confirm your region.">
      <SelectRegions regions={regions} setRegions={setRegions} isLoading={!areRegionsFetched} />

      <Navigation
        {...NavigationProps}
        PrevButtonProps={{
          hidden: true,
          ...(NavigationProps.PrevButtonProps || {}),
        }}
        NextButtonProps={{
          endIcon: null,
          ...(NavigationProps.NextButtonProps || {}),
          onClick: handleNext,
          disabled: !areRegionsFetched || regions.length === 0,
        }}
      />
    </Step>
  );
};

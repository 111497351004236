import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import { Check as CheckIcon } from '@material-ui/icons';
import { SelectableButton, Spacer, StdDialog } from 'src/common';
import { ActionType } from './types';

const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      display: 'flex',
      gap: 20,
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    box: {
      border: `2px solid ${theme.colors.grey.offWhite3}`,
      borderRadius: 6,
    },
    content: {
      margin: theme.spacing(4),
      display: 'flex',
    },
    title: {
      fontSize: theme.typography.pxToRem(19),
      fontWeight: 'bold',
      color: theme.colors.grey.bodyHeader,
    },
    subtitle: {
      color: theme.colors.grey.label,
    },
    checkIcon: {
      fontSize: '1.8rem',
      marginRight: theme.spacing(2),
      padding: theme.spacing(0.5),
      fill: theme.colors.grey.disable,
      borderRadius: 999,
      backgroundColor: 'white',
      border: `2px solid ${theme.colors.grey.disable}`,
    },
    checkIconSelected: {
      fill: 'white',
      backgroundColor: theme.colors.mainColor.primary,
      border: `2px solid transparent`,
    },
  }),
  { classNamePrefix: 'AddCollaboratorsDialog' }
);

export interface AddCollaboratorsModalProps {
  open: boolean;
  onNext(action: ActionType | null): void;
  onClose(): void;
}

export const AddCollaboratorsModal: React.FC<AddCollaboratorsModalProps> = (props) => {
  const { open, onClose, onNext } = props;
  const [permissionType, setPermissionType] = useState<ActionType | null>(null);
  const classes = useStyles();

  const handleSelect = () => {
    onNext(permissionType);
    onClose();
    setPermissionType(null);
  };

  return (
    <StdDialog
      onClose={onClose}
      open={open}
      title="Add collaborators"
      actions={
        <>
          <StdDialog.SecondaryActionButton onClick={onClose}>Cancel</StdDialog.SecondaryActionButton>
          <StdDialog.PrimaryActionButton disabled={!permissionType} onClick={handleSelect}>
            Next
          </StdDialog.PrimaryActionButton>
        </>
      }
    >
      <Typography>There are two ways you can work with others:</Typography>
      <br />
      <div className={classes.container}>
        <RequestButton
          isChecked={permissionType === ActionType.SHARE}
          onClick={() => setPermissionType(ActionType.SHARE)}
          title="Share Account"
          subtitle="Invite people to view and edit your files"
        />
        <RequestButton
          isChecked={permissionType === ActionType.REQUEST}
          onClick={() => setPermissionType(ActionType.REQUEST)}
          title="Request Account"
          subtitle="Ask to create and manage files for others"
        />
      </div>
    </StdDialog>
  );
};

function RequestButton(props: { isChecked: boolean; onClick(): void; title: string; subtitle: string }) {
  const classes = useStyles();
  const { isChecked, onClick, title, subtitle } = props;

  return (
    <SelectableButton className={classes.box} checked={isChecked} component="label" onClick={onClick}>
      <div className={classes.content}>
        <CheckIcon
          classes={{
            root: clsx(classes.checkIcon, isChecked && classes.checkIconSelected),
          }}
        />
        <div>
          <div className={classes.title}>{title}</div>
          <Spacer axis="vertical" size={1} />
          <div className={classes.subtitle}>{subtitle}</div>
        </div>
      </div>
    </SelectableButton>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Grid, makeStyles, Theme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { useForm, FormProvider } from 'react-hook-form';
import { useAuth } from '@skyslope/auth-react';
import { useRecoilValue } from 'recoil';
import * as Sentry from '@sentry/react';
import { selectedAgentAtom } from 'src/state';
import {
  MLSListing,
  isCompleteAddress,
  Address,
  GoogleSearchResult,
  LookupType,
  ManualSearchResult,
  sanitizeAddress,
} from 'src/lib';
import { Navigation, Toggle, Step, FileSelector, StepSkeletonGrid } from 'src/common';
import { WizardStepProps, MultiSourceAddressSearch } from 'src/domain';
import { useTayTip } from 'src/domain/wizard/useTayTip';
import { useGetFileById, useGetFiles } from 'src/hooks';
import { useAvidStore } from '../store';
import { AvidSteps } from './constants';

const useStyles = makeStyles((theme: Theme) => ({
  toggleWrapper: {
    marginBottom: 24,
    textAlign: 'center',
  },
  property: {
    position: 'relative',
    '&:not(:first-child)': {
      marginTop: 64,
    },
  },
  error: {
    color: theme.colors.error,
  },
  form: {
    ['@media screen and (max-width: 1040px)']: {
      stoverflow: 'hidden',
    },
  },
  fileSelector: {
    paddingTop: 30,
  },
}));

type Inputs = {
  property: Address;
};

const filesFilters = 'Address@=CA';

// TODO: break the code that can be generic out from the domain specific code and use here and in disclosures

export const AvidProperty = (props: WizardStepProps) => {
  const classes = useStyles();
  const { goToStep } = props;

  const { data: files } = useGetFiles(filesFilters);
  const store = useAvidStore();
  const { authState } = useAuth();
  const selectedAgent = useRecoilValue(selectedAgentAtom);
  const methods = useForm<Inputs>({
    mode: 'onTouched',
    defaultValues: {
      property: store.property,
    },
  });

  const { register, setValue, trigger } = methods;

  const { data: existingFile, isLoading } = useGetFileById(store.fileId, {
    enabled: !!store.fileId,
  });

  const isValidAddress = store.property && isCompleteAddress(store.property);
  const hasRequiredFields = store.fileId || (store.property && isValidAddress);

  useTayTip(
    selectedAgent &&
      `By the way, you’re creating this on behalf of ${selectedAgent.firstName} ${selectedAgent.lastName}`
  );

  const propertyTypes = store.propertyTypes;

  useEffect(() => {
    if (store.fileId && existingFile != null) {
      const address = sanitizeAddress(existingFile.fileData.premisesAddress);
      store.update({ property: address });
    }
  }, [existingFile]);

  const previous = () => {
    goToStep(AvidSteps.email);
  };

  const defaultAddress: Address = {
    streetAddress: '',
    city: '',
    unitNumber: '',
    state: '',
    postalCode: '',
    county: '',
  };

  const updatePropertyTypes = (val: LookupType, i: number) => {
    const update = [...propertyTypes];
    update[i] = val;
    if (val === 'multi') {
      store.update({
        property: defaultAddress,
        selectedProperty: undefined,
        propertyTypes: update,
        mlsUuid: undefined,
        fileId: undefined,
      });
      setTimeout(() => {
        setValue('property', defaultAddress);
      }, 300);
    } else {
      store.update({
        property: defaultAddress,
        selectedProperty: undefined,
        propertyTypes: update,
        mlsUuid: undefined,
      });
    }
  };

  const selectedMlsAddress = (mlsListing: MLSListing) => {
    store.update({
      property: mlsListing.address as Address,
      selectedProperty: mlsListing,
      mlsUuid: mlsListing.uuid,
    });
    setValue('property', mlsListing.address);
    setValue('apn', mlsListing.parcelNumber);
    setValue('yearBuilt', mlsListing.yearBuilt);
    trigger();
  };

  const selectedGoogleSearchResult = (searchResult: GoogleSearchResult, address: Address) => {
    store.update({
      property: address,
      selectedProperty: searchResult,
      mlsUuid: '',
    });

    setValue('property', address);
    setValue('apn', '');
    setValue('yearBuilt', '');
    trigger();
  };

  const updateManualAddress = (manualResult: ManualSearchResult, address: Address) => {
    store.update({
      property: address,
      selectedProperty: manualResult,
      mlsUuid: '',
    });
  };

  const onUnitUpdate = (unit: string) => {
    setValue('property.unitNumber', unit, { shouldValidate: true });
  };

  const onExistingFileSelected = (fileId: number) => {
    store.update({ fileId: fileId });
  };

  const toggleOptions: Array<{ value: LookupType; label: string }> = [];

  const submitProperty = () => {
    try {
      if (isValidAddress) {
        goToStep(AvidSteps.propertyType);
      } else {
        goToStep(AvidSteps.missingPropertyInfo);
      }
    } catch (e) {
      Sentry.captureException(e);
      console.error(
        'An error occurred while trying to determine property info',
        JSON.stringify({ existingFile, store })
      );
      goToStep(AvidSteps.missingPropertyInfo);
    }
  };

  if (authState.isAuthenticated) {
    toggleOptions.push({ value: 'multi', label: 'Create new' });
    toggleOptions.push({ value: 'existing', label: 'I already have a file' });
  }

  if (!files) {
    return (
      <StepSkeletonGrid>
        <Grid item xs={12} sm={8}>
          <Skeleton height={100} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Skeleton height={100} />
        </Grid>
      </StepSkeletonGrid>
    );
  }

  return (
    <Step
      title={
        authState.isAuthenticated && files && files.length > 0
          ? 'Would you like to complete your inspection for a new or existing property?'
          : 'Where is the property located?'
      }
    >
      {files && (
        <FormProvider {...methods}>
          <form className={classes.form}>
            {propertyTypes.map((lookupType, i) => (
              <div key={i} className={classes.property}>
                {authState.isAuthenticated && (
                  <div className={classes.toggleWrapper}>
                    {files.length > 0 && (
                      <Toggle<LookupType>
                        value={lookupType}
                        onChange={(val) => updatePropertyTypes(val, i)}
                        options={toggleOptions}
                      />
                    )}
                  </div>
                )}
                {lookupType === 'multi' && (
                  <MultiSourceAddressSearch
                    onGoogleAddressUpdate={selectedGoogleSearchResult}
                    onMlsAddressUpdate={selectedMlsAddress}
                    onUpdateManualAddress={updateManualAddress}
                    onUnitUpdate={onUnitUpdate}
                    defaultValue={store.selectedProperty}
                    states={['CA']}
                    mlsResultSize={10}
                  />
                )}
                {lookupType === 'existing' && (
                  <div className={classes.fileSelector}>
                    <FileSelector
                      filters={filesFilters}
                      onSelect={onExistingFileSelected}
                      selectedFileId={store.fileId}
                    />
                  </div>
                )}
                <input type="hidden" name="apn" ref={register()} />
              </div>
            ))}
            <Navigation
              PrevButtonProps={{
                hidden: authState.isAuthenticated,
                onClick: previous,
              }}
              NextButtonProps={{
                disabled: isLoading || !hasRequiredFields,
                //onClick needs to be undefined when there are no validation errors so that the onSubmit for the Form works as normal
                onClick: hasRequiredFields
                  ? () => {
                      submitProperty();
                    }
                  : undefined,
              }}
            />
          </form>
        </FormProvider>
      )}
    </Step>
  );
};

import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { LocationOn as LocationIcon } from '@material-ui/icons';
import { useRecoilState } from 'recoil';
import { DialogCloseButton, TextInput, LoaderButton } from 'src/common';
import { defaultWizardRoutes, GetEnumKeyByValue, TemplateStatus, usStates } from 'src/lib';
import {
  useCreateMarketingContact,
  CreateMarketingContactPayload,
  useGetDisclosuresByRegion,
  DisclosureDefinition,
} from 'src/hooks';
import { registerDestinationAtom, selectedRegionAtom } from 'src/state';
import { useDefaultSelectedRegion } from 'src/pages/Landing/utils';

const useStyles = makeStyles((theme: Theme) => ({
  marketingContainer: {
    padding: theme.spacing(15, 0),
  },
  mainText: {
    fontSize: theme.typography.pxToRem(56),
    lineHeight: `${theme.spacing(9)}px`,
    color: theme.colors.grey.bodyHeader,
    fontFamily: 'inherit',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(48),
      lineHeight: `${theme.spacing(6)}px`,
    },
  },
  subText: {
    fontSize: theme.typography.pxToRem(24),
    color: theme.colors.grey.bodyHeader,
    letterSpacing: '0.16px',
    lineHeight: `${theme.spacing(4.5)}px`,
    fontFamily: 'Avenir Next',
  },
  bodyText: {
    fontSize: theme.typography.pxToRem(20),
    color: theme.colors.grey.bodyHeader,
    fontFamily: 'Avenir Next',
  },
  headerText: {
    fontSize: theme.typography.pxToRem(20),
    fontFamily: 'Avenir Next',
  },
  associationText: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
    fontFamily: 'Avenir Next',
    letterSpacing: '0.11px',
  },
  selectRegion: {
    width: '100%',
    maxWidth: '400px',
  },
  locationIcon: {
    marginLeft: theme.spacing(0.625),
  },
  regionCard: {
    backgroundColor: theme.colors.grey[100],
    padding: theme.spacing(6),
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(4, 2, 2, 2),
    },
  },
  textInput: {
    backgroundColor: 'white',
  },
}));

interface SupportedRegionCardProps {
  header: string;
  body: string;
  avidButton: boolean;
  stateAssociation: string;
  forms: string[];
}

const supportedRegionCardPropsFactory = (
  region: usStates,
  disclosureList: DisclosureDefinition[]
): SupportedRegionCardProps | null => {
  const shortState = GetEnumKeyByValue(usStates, region);
  if (!disclosureList.some((x) => x.tags.state[0] === shortState)) return null;

  const copy = {
    header: region as string,
    body: 'Get your disclosures completed with ease.',
    avidButton: false,
    stateAssociation: '',
    forms: [] as string[],
  };

  const populateForms = (disclosureList: DisclosureDefinition[] | undefined) => {
    if (disclosureList && disclosureList.length > 0) {
      return disclosureList
        .filter((x) => x.tags.state[0] === shortState)
        .filter((x) => x.tags.status[0] === TemplateStatus.LIVE || x.tags.status[0] === TemplateStatus.NEW)
        .map((x) => (x.tags.status[0] === TemplateStatus.LIVE ? x.name : x.name + ' - Coming Soon'));
    } else {
      return [];
    }
  };

  switch (region) {
    case usStates.CA:
      copy.body = 'Guided disclosures and a simple mobile experience allows visual inspections on-the-go.';
      copy.avidButton = true;
      copy.stateAssociation = 'C.A.R';
      copy.forms = populateForms(disclosureList);
      return copy;
    case usStates.AZ:
      copy.stateAssociation = 'A.A.R';
      copy.forms = populateForms(disclosureList);
      return copy;
    case usStates.WA:
      copy.stateAssociation = 'N.W.M.L.S';
      copy.forms = populateForms(disclosureList);
      return copy;
    case usStates.OR:
      copy.stateAssociation = 'O.R.E.F';
      copy.forms = populateForms(disclosureList);
      return copy;
    case usStates.NV:
      copy.stateAssociation = 'R.S.A.R & L.V.R.';
      copy.forms = populateForms(disclosureList);
      return copy;
    case usStates.TX:
      copy.stateAssociation = 'T.R.E.C & T.X.R.';
      copy.forms = populateForms(disclosureList);
      return copy;
    default:
      return null;
  }
};

const SupportedRegionCard: React.FC<SupportedRegionCardProps> = (props) => {
  const classes = useStyles();
  const [, setRegisterDestination] = useRecoilState(registerDestinationAtom);

  return (
    <Grid container spacing={2}>
      <Grid item xs md={6}>
        <Typography variant="h4">{props.header}</Typography>
        <Box mt={2}>
          <Typography variant="subtitle2" className={classes.bodyText}>
            {props.body}
          </Typography>
        </Box>
        <Box my={4}>
          <Grid container spacing={3}>
            {props.avidButton && (
              <Grid item>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={(e) => {
                    setRegisterDestination(defaultWizardRoutes.avidCreation);
                    const loginWidget = document.getElementById('login-widget');
                    e.preventDefault();
                    loginWidget && loginWidget.scrollIntoView({ behavior: 'smooth', block: 'start' });
                  }}
                >
                  Start Avid
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={(e) => {
                  setRegisterDestination(defaultWizardRoutes.disclosures);
                  const loginWidget = document.getElementById('login-widget');
                  e.preventDefault();
                  loginWidget && loginWidget.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }}
              >
                Start Disclosures
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography color="primary" variant="subtitle2" className={classes.headerText}>
          Supported Forms
        </Typography>
      </Grid>
      {props.forms.map((form) => (
        <Grid key={form} item xs={12} md={6}>
          <Typography variant="subtitle2" className={classes.bodyText}>
            {form}
          </Typography>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Box mt={2}>
          <Typography className={classes.associationText}>Forms provided by {props.stateAssociation}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

interface UnsupportedRegionCardProps {
  region: string;
}

const UnsupportedRegionCard: React.FC<UnsupportedRegionCardProps> = (props) => {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm({ mode: 'onTouched' });
  const { mutate: createMarketingContact, isLoading, error, isSuccess, reset } = useCreateMarketingContact();
  const onSubmit = async (data: CreateMarketingContactPayload) => createMarketingContact(data);

  // Reset the mutation any time region changes to allow users
  // to submit their contact info for multiple states
  useEffect(() => {
    reset();
  }, [reset, props.region]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs md={9}>
          <Box textAlign="center" mb={isSuccess ? undefined : 4}>
            <Typography className={classes.subText} variant="subtitle2">
              {isSuccess
                ? 'Thanks! We’ll let you know when we have more information about your region.'
                : 'Unfortunately, we don’t support your region yet. Enter your info below and we’ll notify you as soon as we do!'}
            </Typography>
          </Box>
        </Grid>
        {!isSuccess && (
          <>
            <Grid item xs={12} md={6}>
              <TextInput
                className={classes.textInput}
                required
                label="First Name"
                inputRef={register({
                  required: 'Please enter this field.',
                })}
                name="firstName"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                className={classes.textInput}
                required
                label="Last Name"
                inputRef={register({
                  required: 'Please enter this field.',
                })}
                name="lastName"
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                className={classes.textInput}
                required
                label="Email"
                inputRef={register({
                  required: 'Please enter this field.',
                  pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: 'Invalid email address.' },
                })}
                error={errors.email}
                name="email"
              />
            </Grid>
            <Grid item xs={12}>
              <Box textAlign="center">
                <LoaderButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSuccess || isLoading}
                  isLoading={isLoading}
                >
                  Notify Me
                </LoaderButton>
                {error && (
                  <Box mt={2}>
                    <Typography color="error">{error}</Typography>
                  </Box>
                )}
              </Box>
            </Grid>
          </>
        )}
      </Grid>
      <input type="hidden" name="region" ref={register()} value={props.region} />
    </form>
  );
};

export const LandingRegion: React.FC = () => {
  const classes = useStyles();
  const [selectedRegion, setSelectedRegion] = useRecoilState(selectedRegionAtom);
  const [landingRegion, setLandingRegion] = useState(useDefaultSelectedRegion());

  useEffect(() => {
    if (landingRegion) {
      setSelectedRegion(landingRegion);

      // Clear the landing region so existing page behavior takes over
      setLandingRegion(null);
    }
  }, [landingRegion, setSelectedRegion]);

  const { data: disclosureList } = useGetDisclosuresByRegion();
  const supportedRegionCardProps = disclosureList
    ? supportedRegionCardPropsFactory(selectedRegion as usStates, disclosureList)
    : null;

  return (
    <Grid container className={classes.marketingContainer}>
      <Container maxWidth="lg">
        <Grid container spacing={6}>
          <Grid container item xs={12} justifyContent="center">
            <Box maxWidth={625} textAlign="center">
              <Typography className={classes.mainText} variant="h2">
                Work from anywhere
              </Typography>
              <Box mt={2} mb={8}>
                <Typography className={classes.subText} variant="subtitle2">
                  Breeze supports disclosure forms in these regions, with more coming soon. Select your region to learn
                  more.
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <Autocomplete
                  className={classes.selectRegion}
                  options={Object.values(usStates)}
                  value={selectedRegion}
                  onChange={(_, newValue) => {
                    setSelectedRegion(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select Region"
                      InputProps={{
                        ...params.InputProps,
                        style: { fontWeight: 'bold' },
                        startAdornment: (
                          <InputAdornment className={classes.locationIcon} position="start">
                            <LocationIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
          </Grid>
          {selectedRegion && (
            <Grid container item xs={12} justifyContent="center">
              <Box maxWidth={1000}>
                <Paper elevation={0} className={classes.regionCard}>
                  <DialogCloseButton onClick={() => setSelectedRegion(null)} />
                  {supportedRegionCardProps ? (
                    <SupportedRegionCard {...supportedRegionCardProps} />
                  ) : (
                    <UnsupportedRegionCard region={selectedRegion} />
                  )}
                </Paper>
              </Box>
            </Grid>
          )}
        </Grid>
      </Container>
    </Grid>
  );
};

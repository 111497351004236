import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  libraryName: {
    fontWeight: 'normal',
    fontSize: '16px',
    color: '#465766',
    whiteSpace: 'break-spaces',
  },
}));

export interface Props {
  associationName: string | undefined;
}

export const DisclosuresAssociationName = (props: Props) => {
  const classes = useStyles();
  return (
    <Box flex="1 1 0px" className={classes.libraryName}>
      {props.associationName || ''}
    </Box>
  );
};
